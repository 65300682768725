/* eslint-disable no-eval */
import React, { Component } from 'react';
import _ from 'lodash';


import {
  Row,
  Col,
  Icon,
  Button,
  Form,
  Input,
  Radio,
  Select,
  AutoComplete,
  DatePicker,
  InputNumber,
  Switch,
  Slider,
  Upload,
  Rate,
  Steps,
  Checkbox,
  TimePicker
} from 'antd';


import moment from 'moment';

//require('moment/locale/it');
import 'moment/locale/it';
moment.locale('it');


const { MonthPicker, RangePicker } = DatePicker;

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Dragger = Upload.Dragger;
const Step = Steps.Step;
const { TextArea } = Input


const notFieldTypes = ['text', 'link', 'button', 'button_addAnagrafica', 'submit', 'title', 'divider', 'child', 'label'];
const dateFields = [
  'datepicker',
  'timepicker',
  'datetimepicker',
  'rangepicker'
];


/*
const currency_formatter = new Intl.NumberFormat('it-IT', {
  style: 'decimal',
});


var component = null;
const onValuesChange = (a, b, c) => {

  try {
    if (component && component.props.onValuesChange) component.props.onValuesChange(a, b, c)
  } catch (e) {
    console.error(e);
  }
  //if(cb) cb(a,b,c);
}
*/

class FormComponent extends Component {
  state = {
    current_step: 0,
    readOnly: false,
    cb: null
  };

  static propTypes = {};

  /*
  setComponent() {
    component = this
  }
  */

  componentDidMount() {

    /*
      Non è necessario, basta non usare lo state ma usare le props
       if (this.props.readOnly) {
        this.setState({ readOnly: this.props.readOnly })
      }
    */
  }


  /**
   *
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  //componentWillReceiveProps(newProps) {
    //if(newProps.readOnly != this.props.readOnly) this.setState({ readOnly: newProps.readOnly })
  //}


  formatFieldRules(field) {
    let return_rules = [];
    var me = this;
    if (!field.validations || field.validations.length <= 0) return null;

    field.validations.forEach(rule => {
      let value_rule = {};

      if (rule.type === 'requiredIf') {
        if (rule.func) {
          value_rule = {
            required: rule.func(),
            message: rule.error_message
          };
        } else {
          value_rule = {
            required: eval(me.props.form.getFieldValue(rule.field) + ` ${rule.operator} ${rule.value}`),
            message: rule.error_message
          };
        }
      } else if (rule.type === 'required') {
        value_rule = {
          required: true,
          message: rule.error_message
        };
      } else if (rule.type === 'email') {
        value_rule = {
          type: 'email',
          message: rule.error_message
        };
      } else if (rule.type === 'check') {
        value_rule = {
          validator: (rulename, value, cb) => {
            if (this.props.form.getFieldValue(rule.check_field) !== value) {
              cb(rule.error_message);
            } else {
              cb();
            }
          }
        };
      } else if (rule.type === 'required_if_func') {
        value_rule = {
          required: rule.func(),
          message: rule.error_message
        };
      } else if (rule.validator) {
        value_rule = {
          validator: this.props[rule.validator]
        };
      } else {
        value_rule = rule;
      }
      return_rules.push(value_rule);
    });

    return return_rules;
  }

  resolve(path, obj = this, separator = '.') {
    var properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
  }

  /**
   * Ritorna campo iniziale in base alla chiave definita nella prop values
   *
   * @param  {object} field Campo definito in props.form_model
   * @return {string}       Stringa con valore del campo o null
   *
   * @public
   */
  returnFieldInitialValue(field) {
    let return_value = '';

    let key = field.name;
    if (field.name === undefined) {
      console.error('field name cannot be null');
      console.error(field);
    }
    let value = this.resolve(key, this.props.values);

    if (value && dateFields.includes(field.type)) {
      switch (field.type) {
        case 'datepicker':
          return_value = moment(
            value,
            field.format || 'YYYY/MM/DD', "it-IT"
          );
          break;
        case 'monthpicker':
          return_value = moment(
            value,
            field.format || 'MM/YYYY', "it-IT"
          );
          break;
        case 'datetimepicker':
          return_value = moment(
            value,
            field.format || 'DD/MM/YYYY HH:mm'
          );
          break;
        case 'timepicker':
          return_value = moment(
            value,
            field.format || 'HH:mm'
          );
          break;
        case 'rangepicker':
          return_value = [
            moment(value, field.format || 'DD/MM/YYYY HH:mm'),
            moment(value, field.format || 'DD/MM/YYYY HH:mm')
          ];
          break;
        default: break;
      }
    } else {
      switch (field.type) {
        case 'select':
          return_value = value ? value : (field.mode === 'multiple' ? [] : null);
          break;
        default:
          return_value = value ? value : null;
          break;
      }
    }

    return return_value || null;
  }

  /**
   * Ritorna le opzioni del campo
   * possono essere definite come array statico, come array passato nelle props o come funzione
   *
   * In caso di funzione viene inviato il campo di riferimento
   *
   * Ogni opzione ritornata ha un parametro value e uno label
   * {
   * 	value: val, label: label
   * }
   *
   * @param  {object} field Campo definito nel form model
   * @return {array}        Array con le opzioni
   *
   * @public
   */
  returnSelectOptions(field) {
    let field_options = null;

    if (field.options && field.options.length > 0)
      field_options = field.options;

    if (field.optionsFunc) field_options = this.props[field.optionsFunc](field);

    if (field.options_props) field_options = this.props[field.options_props];

    return (field_options || []).map((option, i) => (
      <Option key={'option_' + field.name + '_' + i} value={this.returnDefOptVal(field, option.value)}>
        {option.label}
      </Option>
    ));
  }

  /**
   * se il mode == 'tags' usa una stringa
   * @param  {[type]} field [description]
   * @param  {[type]} value [description]
   * @return {[type]}       [description]
   */
  returnDefOptVal(field, value) {
    return (field.mode === 'tags' || field.mode === 'multiple') ? "" + value : value
  }

  /**
   * Ritorna le opzioni del campo
   * possono essere definite come array statico, come array passato nelle props o come funzione
   *
   * In caso di funzione viene inviato il campo di riferimento
   *
   * Ogni opzione ritornata ha un parametro value e uno label
   * {
   * 	value: val, label: label
   * }
   *
   * @param  {object} field Campo definito nel form model
   * @return {array}        Array con le opzioni
   *
   * @public
   */
  returnRadioGroupOptions(field) {
    let field_options = [];

    if (field.options && field.options.length > 0)
      field_options = field.options;

    if (field.optionsFunc) field_options = this.props[field.optionsFunc](field);

    if (field.options_props) field_options = this.props[field.options_props];

    return field_options.map((option, i) => {
      if (field.option_type === 'button') {

        return (
          <RadioButton
            key={'radiooption_' + field.name + '_' + i}
            value={option.value}
            disabled={option.disabled}

          >
            {option.label}
          </RadioButton>
        );
      } else {
        return (
          <Radio
            key={'radiooption_' + field.name + '_' + i}
            value={option.value}
          >
            {option.label}
          </Radio>
        );
      }
    });
  }

  /**
   * Ritorna il componente in base alla definizione del type in form_model.fields
   *
   * @param  {object} field Campo definito nel json
   * @return {element}      Elemento
   */
  returnFieldType(field, form) {
    switch (field.type) {
      case 'input':
        return (
          <Input
            autoComplete="off"
            disabled={this.props.readOnly}
            placeholder={
              field.placeholder ? field.placeholder : 'Inserisci il testo'
            }
            type={field.type_string ? field.type_string : 'text'}
            {...field.props}
          />
        );

        case 'decimal':
          return (
            <Input
              autoComplete="off"
              disabled={this.props.readOnly}
              placeholder={
                field.placeholder ? field.placeholder : '0.00'
              }
              type={'number'}
              min={0}
              value={0}
              step={0.01}
              {...field.props}
            />
          );

      case 'email':
        return (
          <Input
            type="email"
            title='Inserisci una e-mail valida'
            autoComplete="off"
            disabled={this.props.readOnly}
            placeholder={
              field.placeholder ? field.placeholder : 'Inserisci una e-mail valida'
            }
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            {...field.props}
          />
        );

      case 'currency':
        return (
          <Input
            autoComplete="off"
            disabled={this.props.readOnly}
            placeholder={
              field.placeholder ? field.placeholder : 'Inserisci il testo'
            }
            type={field.type_string ? field.type_string : 'text'}
            {...field.props}
            onChange={e => {
              //console.log('modifica campo', e, this.props.form, this.props, e)
              let v = e.target.value.replace(/[^0-9.]+/g, "");
              if (v[0] === '0') {
                let r = v.split('');
                v = ''
                for (let a = 0; a < r.length; a++) {
                  if (a > 0) v += r[a];
                }
              }
              if (!v.match(/\./)) {
                //v = v + ".00";
              } else if ((v.match(/\./g) || []).length > 1) {
                v = v.replace(/./g, "");
                let v_l = v.length;
                let point_i = v_l - 3;
                v = v.split("");
                let n_v = "";
                for (let i = 0; i < v_l; i++) {
                  if (i === point_i) n_v += ".";
                  n_v += v[i]
                }
                v = n_v;
              } else {
                let els = v.split(".");
                if (els[1].length > 2) {
                  v = els[0] + "." + els[1][0] + els[1][1]
                } else if (els[1].length === 0) {
                  v = els[0] + ".00"
                } else if (els[1].length === 1) {
                  v = els[0] + "." + els[1][0] + "0"
                }
              }
              let new_val = v;
              //console.log('nuovo valore', e.target.value, new_val)
              setTimeout(() => {
                this.props.form.setFieldsValue(
                  {
                    [field.name]: new_val
                  }
                );
              }, 0.1);
            }}
          />
        );

      case 'integer':
        return (
          <InputNumber
            autoComplete="off"
            disabled={this.props.readOnly}
            placeholder={
              field.placeholder ? field.placeholder : 'Num.'
            }

            {...field.props}

          />
        );


      case 'integerwithzero':
        return (
          <Input
            type="number"
            autoComplete="off"
            disabled={this.props.readOnly}
            placeholder={
              field.placeholder ? field.placeholder : 'Num.'
            }

            {...field.props}
            onChange={(e) => {
              if (parseInt(e.target.value) !== 0) {
                //console.log('modifica campo', e, this.props.form, this.props, e)
                let new_val = e.target.value.replace(/^(0)|[^0-9]+/g, "");
                //console.log('nuovo valore', e.target.value, new_val)
                setTimeout(() => {
                  this.props.form.setFieldsValue(
                    {
                      [field.name]: new_val
                    });
                }, 0.1);
              }
            }}
            onKeyUp={this.props[field.onKeyUp] ? (value) => this.props[field.onKeyUp](value) : null}
          />
        );


      case 'inputpwd':
        return (
          <Input.Password

            disabled={this.props.readOnly}
            placeholder={
              field.placeholder ? field.placeholder : 'Inserisci il testo'
            }
            type={field.type_string ? field.type_string : 'text'}
            {...field.props}
          />
        );

      case 'textarea':
        return (
          <TextArea
            autoComplete="off"
            disabled={this.props.readOnly}
            placeholder={
              field.placeholder ? field.placeholder : 'Inserisci il testo'
            }
            type={field.type_string ? field.type_string : 'text'}
            {...field.props}
          />
        );

      case 'select':
        return (
          <Select
            disabled={this.props.readOnly}
            onChange={this.props[field.onChange] ? (value) => this.props[field.onChange](value) : null}
            mode={field.mode || null}
            {...field.props}
          >
            {this.returnSelectOptions(field)}

          </Select>
        );

      case 'radio':
        return (
          <RadioGroup
            buttonStyle="solid"
            disabled={this.props.readOnly}
            {...field.props}
            onChange={this.props[field.onChange] ? (value) => this.props[field.onChange](field.name, value) : null}
          >
            {this.returnRadioGroupOptions(field)}
          </RadioGroup>
        );

      case 'autocomplete':
        return (
          <AutoComplete

            disabled={this.props.readOnly}

            dataSource={this.props[field.dataFunc]()}
            style={{}}
            onSelect={this.props[field.onSelectFunc]}
            onSearch={this.props[field.onSearch] ? (value) => this.props[field.onSearch](value) : null}
            /*filterOption={
              field.onSearch
                ? (inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                : this.props[field.onSearch]()
            }*/
            placeholder={
              field.placeholder ? field.placeholder : 'Inserisci il testo'
            }
            {...field.props}
          >
            {(field.withIcon) ? <Input suffix={<Icon type="search" className="certain-category-icon" />} /> : null}
          </AutoComplete>
        );

      case 'datepicker':

        return <DatePicker
          disabled={this.props.readOnly}

          placeholder={
            field.placeholder
              ? field.placeholder
              : "Data"
          } format={field.format || 'DD/MM/YYYY'}
          {...field.props}
        />;

      case 'timepicker':
        return <TimePicker
          disabled={this.props.readOnly}
          placeholder={field.placeholder ? field.placeholder : "Ora"}
          showTime
          format={field.format || 'HH:mm'}
          {...field.props}
        />;

      case 'datetimepicker':
        return (
          <DatePicker

            disabled={this.props.readOnly}

            placeholder={
              field.placeholder
                ? field.placeholder
                : "Data e ora"
            } showTime format={field.format || 'DD/MM/YYYY HH:mm'}
            {...field.props}
          />
        );

      case 'monthpicker':
        return (
          <MonthPicker

            disabled={this.props.readOnly}

            format={field.format || 'MM-YYYY'}
            placeholder={
              field.placeholder
                ? field.placeholder
                : ['Mese inizio', 'Mese fine']
            }

            {...field.props}
          />
        );

      case 'rangepicker':
        switch (field.range_type) {
          case 'month':
            return (
              <MonthPicker

                disabled={this.props.readOnly}

                mode={['month', 'month']}
                format={field.format || 'MM-YYYY'}
                placeholder={
                  field.placeholder
                    ? field.placeholder
                    : ['Mese inizio', 'Mese fine']
                }

                {...field.props}
              />
            );
          default:
            const format = field.showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
            return (
              <RangePicker

                disabled={this.props.readOnly}

                showTime={field.showTime || false}
                format={field.format || format}
                placeholder={
                  field.placeholder
                    ? field.placeholder
                    : ['Data inizio', 'Data fine']
                }

                {...field.props}
              />
            );
        }

      case 'checkbox':
        return <Checkbox
          disabled={this.props.readOnly} {...field.props}
        >{field.text}</Checkbox>;

      case 'numberinput':
        return <InputNumber disabled={this.props.readOnly} {...field.props}
          min={field.min || 0} max={field.max || 9999999} />;

      case 'switch':
        return <Switch {...field.props} />;

      case 'rate':
        return <Rate {...field.props} />;

      case 'slider':
        return (
          <Slider
            vertical={field.vertical || false}
            step={field.step || 1}
            dots={field.dots || false}
            min={field.min || 1}
            max={field.max || 9999999}
            marks={field.marks || {}}
            {...field.props}
          />
        );

      case 'upload':
        return (
          <Dragger
            accept={field.accept || '*'}
            action={field.action}
            listType={field.listType}
            multiple={field.multiple}
            fileList={this.props[field.fileList] ? this.props[field.fileList]() : []}
            customRequest={this.props[field.customRequest]}
            onRemove={this.props[field.onRemove]}
            {...field.props}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Clicca qui o trascina file per caricarli
            </p>
            <p className="ant-upload-hint">
              Inserisci uno più file
            </p>
          </Dragger>
        );

      case 'square_upload':
        return (
          <Upload
            accept={field.accept || '*'}
            action={field.action}
            listType={field.listType}
            multiple={field.multiple}
            fileList={this.props[field.fileList]()}
            customRequest={this.props[field.customRequest]}
            onRemove={this.props[field.onRemove]}
            {...field.props}
          >
            <div>
              <Icon type='plus' style={{ fontSize: 32 }} />
              <div className="ant-upload-text">{(field.text) ? field.text : "Carica una immagine"}</div>
            </div>
          </Upload>
        );

      case 'simple_upload':
        return (
          <>
            <Upload
              accept={field.accept || '*'}
              action={field.action}
              listType={field.listType}
              multiple={false}
              fileList={null}
              customRequest={this.props[field.customRequest]}
              onRemove={this.props[field.onRemove]}
              {...field.props}
            >
              {
                field.downloadTemplateEnabled ?
                <>
                  <Button type={"primary"} loading={this.props.loading || false} onClick={field.downloadTemplateOnClick}>
                    <Icon type="file-word" />{"Scarica formato Odt"}
                  </Button>
                  <br/>
                </>
                : null
              }
              <Button
                loading={this.props.loading || false}
              >
                <Icon type="upload" />{(field.text) ? field.text : "Carica"}
              </Button>
            </Upload>
            {this.props[field.filename] &&
              <div>
                <Icon type="paper-clip" />
                <Button type="link" style={{cursor: 'default'}}>{this.props[field.filename]}</Button>
              </div>
            }
          </>
        );

      case 'simple_label':
        return <></>;
        
      default:
        return <Input disabled={this.props.readOnly} {...field.props}
        />;
    }
  }

  /**
   * Sottoscrizione del form
   *
   * Richiama la prop definita nel form_model 'onSubmit'
   * @param  {object} e Event
   * @return {void}
   *
   * @public
   */
  handleSubmit(e) {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values = this.formatValues(values);
        if (this.props.form_model.onSubmit) {
          let f = this.props[this.props.form_model.onSubmit];
          if (typeof f === "function") {
            f(values);
          } else {
            console.error(this.props.form_model.onSubmit + ' is not a function');
          }
        }
      } else {
        console.error(err)
      }
    });
  }

  formatValues(values) {
    _.forEach(values, (val, name) => {
      if (name === 'rangeMonth' && _.isArray(val)) {
        values[name] = {
          start: val[0].toISOString(),
          end: val[1].toISOString()
        };
      }
    });
    return values;
  }
  /**
   * Torna un elemento che non è un vero e proprio campo
   * @param  {object} field Campo
   * @return {element}
   */
  renderNotFieldType(field) {
    switch (field.type) {
      case 'submit':
        return (
          <FormItem key={'form_not_item_' + field.name}>
            <Button
              loading={this.props.loading || false}
              type={field.btn_type || 'primary'}
              htmlType="submit"
              disabled={this.props.readOnly}
              {...field.props || null}
            >
              {field.text}
            </Button>
          </FormItem>
        );
      case 'button':
        return (
          <FormItem key={'form_not_item_' + field.name}>
            <Button
              loading={this.props.loading || false}
              type={field.btn_type || 'primary'}
              onClick={() => this.props[field.onClick](this.props.form)}
              disabled={this.props.readOnly}
              {...field.props || null}
            >
              {field.text}
            </Button>
          </FormItem>
        );
      case 'button_addAnagrafica':
        return (
          <FormItem key={'form_not_item_' + field.name}>
            <Button
              {...field.props || null}
              type={field.btn_type || 'primary'}
              onClick={() => this.props[field.onClick](field.props)}
              disabled={this.props.readOnly}
            >
              {field.text}
            </Button>
          </FormItem>
        );
      case 'text':
        return <p key={'form_not_item_' + field.name}>{field.text}</p>;
      case 'label':
        return <div key={'form_not_item_' + field.name} className="ant-form-item-label"><label>{field.text}</label></div>;
      case 'child':
        return <div key={'form_not_item_' + field.name}>{field.child}</div>;
      case 'link':
        return (
          <p {...field.props || null} key={'form_not_item_' + field.name}>
            <a
              href={field.link || null}
              onClick={() => {
                if (this.props[field.onClick])
                  this.props[field.onClick](this.props.form);
              }}
              disabled={this.props.readOnly}
            >
              {field.text}
            </a>
          </p>
        );
      case 'divider':
        return (
          <hr {...field.props || null} />
        );
      case 'title':
        switch (field.element) {
          case 'h1':
            return <h1 key={'form_not_item_' + field.name} {...field.props || null}>{field.text}</h1>;
          case 'h2':
            return <h2 key={'form_not_item_' + field.name} {...field.props || null}>{field.text}</h2>;
          case 'h3':
            return <h3 key={'form_not_item_' + field.name} {...field.props || null}>{field.text}</h3>;
          case 'h4':
            return <h4 key={'form_not_item_' + field.name} {...field.props || null}>{field.text}</h4>;
          case 'h5':
            return <h5 key={'form_not_item_' + field.name} {...field.props || null}>{field.text}</h5>;
          default: break;
        }
        break;
      default: break;
    }
  }

  /**
   * Valida se mostrare un campo in base alle condizioni
   * @param  {object} field Campo definito
   * @return {bool}
   */
  validateField(field) {

    if (!field.showIf) return true;
    // per ogni condizione validala
    let show_field = true;
    var me = this;

    field.showIf.forEach(obj => {
      // se deve validare un campo fai un eval

      if (obj.type === 'field_validation') {
        if (
          !eval(
            me.props.form.getFieldValue(obj.field) + ` ${obj.operator} ${obj.value}`
          )
        )
          show_field = false;
      } else if (obj.type === 'prop_func') {

        if (
          !me.props[obj.func](
            me.props.form.getFieldValue(field.name),
            me.props.form,
            field.name
          )
        )
          show_field = false;

      } else if ((!obj.type || obj.type === 'custom_func') && obj.func) {
        if (!obj.func()) {
          show_field = false;
        }
      }

    });
    return show_field;
  }

  passFunction(func) {
    return this.refs.common_form[func]()
  }

  renderContent() {
    const {
      getFieldDecorator,
    } = this.props.form;

    return <>
      {this.props.form_model.multistep && (
      <Steps current={this.props.current_step}>
        {this.props.form_model.steps.map(item => (
          <Step key={'form_step_' + item.title} title={item.title} />
        ))}
      </Steps>
    )}
    {this.props.form_model.rows.map((row, row_i) => {
      return row && (!row.step || this.props.current_step + 1 === row.step) && (
        <Row
          gutter={row.gutter || 8}
          key={'row_' + row_i}
          {...row.props}>
          {row.cols.map((col, col_i) => {
            return col && (
              <Col
                //TODO: Implementare responsive props
                // span={col.span || parseInt(24 / row.cols.length)}
                lg={col.span || parseInt(24 / row.cols.length)}
                md={col.md || 24}
                sm={col.sm || 24}
                xs={col.xs || 24}
                key={'col_' + col_i}
                offset={col.offset || 0}
                {...col.props}
              >
                {col.items && col.items.map(field => {

                  if (this.validateField(field)) {
                    if (notFieldTypes.indexOf(field.type) !== -1) {
                      return this.renderNotFieldType(field);
                    } else {

                      return (
                        <FormItem
                          hasFeedback={field.hasFeedback || false}
                          key={'form_item_' + field.name}
                          label={field.label}
                        >
                          {getFieldDecorator(field.name, this._getFieldDecorator(field))(this.returnFieldType(field, this.props.form))}
                        </FormItem>
                      );
                    }
                  }
                  return null;
                })}
              </Col>
            );
          })}
        </Row>
      );
    })}
    </>
  }

  render() {
    if (this.props.subform === true) {
      return this.renderContent();
    }
    return (
      <Form
        layout={this.props.form_model.layout || 'vertical'}
        onSubmit={this.handleSubmit.bind(this)}
      >
        {this.renderContent()}
      </Form>
    );
  }

  _getFieldDecorator(field) {
    let obj = {
      rules: this.formatFieldRules(field),
      initialValue: this.returnFieldInitialValue(
        field
      )
    }
    if (field.type === 'checkbox') obj.valuePropName = 'checked';
    return obj
  }
}


const WrappedForm = Form.create({
  //onValuesChange: onValuesChange
})(FormComponent);

export default WrappedForm;
