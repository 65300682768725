import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'antd';

import ModelServices from '@accreditamento/services/Nomina';
import FileSaver from 'file-saver';

import moment from 'moment';

import CommonForm from '@common/components/form';
import nominaStatic from '@common/utils/static/nomina';
import DocSpan from '@common/components/DocSpan';

import checkPermission from '@common/utils/check/permissions';

const getStato = (record, component) => {

  let stato = 'ente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';


  return record.stato_flusso_formatted['stato_'+stato] == "inviata" || record.stato_flusso_formatted['stato_'+stato] == "acquisita" || record.stato_flusso_formatted['stato_'+stato] == "accolta" || record.stato_flusso_formatted['stato_'+stato] == "accolta_e_confermata" || record.stato_flusso_formatted['stato_'+stato] == "valutazione_in_corso"  ? "Accreditamento Provvisorio" : record.stato_flusso_formatted['stato_'+stato]  ;
}

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              name: 'titolo_profilo',
              type: 'text',
              text: 'Carica il documento di nomina per confermare l\'assegnazione',
              props: {
                style: { textAlign: 'left' }
              },
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica pdf",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

class SingleNominaOk extends Component {

  state = {
    loaded: false,
    loading: false,
    downloading: false
  }



  canDownload() {
    return this.props.UserStore.user.id === this.props.nomina.id_user && (
      this.props.nomina.stato === nominaStatic.stato.nomina_ispettore ||
      this.props.nomina.stato === nominaStatic.stato.download_dirigente
      ) ? true : false
  }

  canUpload() {
    return (this.props.UserStore.user.id === this.props.nomina.id_user &&
      this.props.nomina.stato === nominaStatic.stato.download_dirigente
      ) ? true : false
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `nomina_${date}${month}${year}_${hour}${minu}.pdf`;
    FileSaver.saveAs(_blob, nome_file);

    this.setState({
      loading: false, downloading: false
    });

    if(this.props.nomina.stato === nominaStatic.stato.nomina_ispettore) {
      this.props.refreshInline({
        ...this.props.ispezione,
        nomine: [
          ...this.props.ispezione.nomine.filter(n => n.id !== this.props.nomina.id),
          {
            ...this.props.nomina,
            stato: nominaStatic.stato.download_dirigente
          }
        ]
      })
    }

  }

  saveBlobWord(blob) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `disposizioni_di_sicurezza_${date}${month}${year}_${hour}${minu}.odt`;
    FileSaver.saveAs(_blob, nome_file);

    this.setState({
      loading: false, downloading: false
    });

    if(this.props.nomina.stato === nominaStatic.stato.nomina_ispettore) {
      this.props.refreshInline({
        ...this.props.ispezione,
        nomine: [
          ...this.props.ispezione.nomine.filter(n => n.id !== this.props.nomina.id),
          {
            ...this.props.nomina,
            stato: nominaStatic.stato.download_dirigente
          }
        ]
      })
    }

  }


  upload(file) {
    if(!this.state.loading) {

      this.setState({
        loading: true
      }, () => {
        ModelServices.upload(this.props.accreditamento.id, this.props.nomina.id, {
          filename: 'file'
        }, { file: file, name: file.name, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.setState({ open_modal_doc: false, loading: false });

        }, () => this.setState({ open_modal_doc: false, loading: false }))
      })

    }
  }



  render() {
    return <div>
        <p>Stato: <strong>{getStato( this.props.nomina, this)}</strong><br />
        {this.props.nomina.ispettore.username} - {this.props.nomina.ispettore.anagrafica.nome} {this.props.nomina.ispettore.anagrafica.cognome}<br />
        {(this.props.nomina.data_assegnazione) ? moment(this.props.nomina.data_assegnazione, 'YYYY-MM-DD').format('DD/MM/YYYY') + " (Data assegnazione)" : ''}<br />
        {this.props.nomina.note_ispettore && this.props.nomina.note_ispettore !== '' ? <span><strong>Note:</strong> {this.props.nomina.note_ispettore}</span> : ''}
        </p>
        {
          this.props.nomina.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_feedback_ispettore).map(l => {
            return <div key={l.id}><p style={{ display: 'inline-block' }}>Documento firmato dall&apos;ispettore: </p><DocSpan key={l.id} file={l} /></div>
          })
        }

        {
      /*    this.canDownload()
          ?
          <Button
            loading={this.state.downloading}
            size="small"
            disabled={this.state.loading}
            className="m5h" type="primary" icon="file-pdf" onClick={() => {

              if(!this.state.loading) {
                this.setState({
                  loading: true,
                  downloading: true
                }, () => {

                  ModelServices.download(
                    this.props.accreditamento.id,
                    this.props.nomina.id,
                    (res) => this.saveBlob(res.data),
                    () => this.setState({loading: false, downloading: false})
                  )

                })
              }

            }}>Scarica Pdf</Button>
          : null */
        }
        {
        this.canDownload()
          ?
          <Button
            loading={this.state.downloading}
            size="small"
            disabled={this.state.loading}
            className="m5h m5w" type="secondary" icon="file-word" onClick={() => {

              if (!this.state.loading) {
                this.setState({
                  loading: true,
                  downloading: true
                }, () => {

                  ModelServices.download_word(
                    this.props.accreditamento.id,
                    this.props.nomina.id,
                    (res) => this.saveBlobWord(res.data),
                    () => this.setState({ loading: false, downloading: false })
                  )

                })
              }

            }}>Scarica</Button>
          : null
        }
        {
          this.canUpload()
          ? <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={{
            }}
            fileListProp={() => []}
            customUploadRequest={obj => {
              this.upload(obj.file);
            }}
          />
          : null
        }
      </div>
    }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SingleNominaOk);