import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Table } from 'antd';


import TableFormattersUtils from '../utils/formatters/_';

/**
 * Componente per la visualizzazione di dati in tabella, si occupa di prendere i model e formattare i dati in modo che si adattino ai requisiti dei componenti di ant
 *
 * @component
 */
export default class TableComponent extends Component {

  static propTypes = {
    model: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    /** @type {bool} Ha bisogno del form coi filtri */
    with_filters: PropTypes.bool,
    /** @type {object} Model relativo ai filtri */
    filter_form_model: PropTypes.object,
    /** @type {bool} Richiede paginazione (lato server) */
    with_server_pagination: PropTypes.bool,
    onPageClick: PropTypes.func,
    /** @type {bool} Righe selezionabili */
    selectable_row: PropTypes.bool,
    /** @type {integer} Numero totale di risultati restituiti dal servizio */
    total_data: PropTypes.number,
    /** @type {function} Funzione per aggiornamento dei dati */
    loadData: PropTypes.func,

    loading: PropTypes.bool,
    /** @type {func} callback a cambio di pagina */
    updatePage: PropTypes.func,
    /** @type {func} callback al cambio di ordinamento */
    updateSort: PropTypes.func
  }

  state = {
    datas: [],
    columns: [],
    expandable: false,
    onRowSelection: false,
    selectable: false,
    selected_rows: [],
    children_selected_rows: [],

    with_server_pagination: false,

    pagination: {
      current: 1,
      total: 1,
    },

    sorter: {
      columnKey: "",
      order: "ascend"
    }
  }

  children_tables = [];

	/**
	 * Cambiamento di dati nella tabella
	 * @param  {object} pagination "{current: integer, total: integer}"
	 * @param  {object} filters
	 * @param  {object} sorter
	 * @return {void}
	 *
	 * @public
	 */
  handleTableChange(pagination, filters, sorter) {

    if (this.state.pagination.current !== pagination.current && this.props.updatePage) this.props.updatePage(pagination.current);

    if ((this.state.sorter.columnKey !== sorter.columnKey ||
      this.state.sorter.order !== sorter.order)
      && sorter.columnKey && this.props.updateSort) this.props.updateSort(sorter.columnKey, sorter.order);

  }


	/**
	 * Prendiamo i dati inviati e li passiamo a mount()
	 *
	 * @return {void}
	 * @public
	 */
  componentDidMount() {
    this.mount(this.props)
  }

	/**
	 * Inserisce nel model il renderer della colonna in base alle proprietà passate
	 *
	 * @param  {object} col Oggetto colonna del model
	 * @return {string|func}
	 *
	 * @public
	 */
  returnColRenderer(col, props) {
    if (col.render) return col.render;
    /** se usa un custom renderer dalle props utilizza la funzione come formattatore */
    if (col.customRendererProp) return (element) => props[col.customRendererProp](element, props.data, props, ...col.customRendererProps);
    /** se usa un renderer di default presente nei formatters della tabella */
    if (col.defaultRenderer) return (element) => TableFormattersUtils[col.defaultRenderer](element, props.data, props, ...col.defaultRendererProps)
  }

	/**
	 * Formatta la singola colonna
	 *
	 * Funzione ricorsiva necessaria per l'inserimento di colonne figlie
	 *
	 * @param  {object} col Colonna base
	 * @return {object}     Colonna formattata
	 *
	 * @public
	 */
  returnSingleColObject(col, props) {

    let single_obj = {
      title: col.title,
      dataIndex: col.dataIndex,
      sorter: col.sorter || false,
      sortOrder: (this.state.sorter.columnKey === col.key) ? this.state.sorter.order : "",
      key: col.key,
      render: this.returnColRenderer(col, props)
    }

    if (col.children) {
      let col_childrens = [];
      col.children.forEach(child => {
        col_childrens.push(this.returnSingleColObject(child, props));
      })
      single_obj.children = col_childrens;
    }

    return single_obj;
  }

	/**
	 * A partire dal model definito formatta le colonne in modo che non ci siano oggetti nestati da inserire
	 * @return {array} Array col model adatto a tabella ant
	 *
	 * @public
	 */
  formatColumns(props) {
    let cols = [];
    props.model.columns.forEach(col => {
      /** Uso una funzione ricorsiva per formattare tutte le colonne figlie e figlie di figlie */
      cols.push(this.returnSingleColObject(col, props));
    });

    this.setState({
      columns: cols,
      expandable: props.model.expandable || false,
      selectable: props.model.selectable || false,
      with_server_pagination: props.model.with_server_pagination || false,
    })
  }

	/**
	 * Ritorna se stesso per la tabella figlia nestata
	 * @return {element}
	 *
	 * @public
	 */
  returnExpandedRows = (record) => {

		/**
		 * Se espande la singola riga mostra solo un elemento
		 * @param  {func} this.props.model.expandSingleRow
		 * @return {element}
		 */
    if (this.props.model.expandSingleRow) {
      return this.props[this.props.model.expandSingleRow](record, this.props);
    } else {
			/**
			 * Altrimenti crea un nuovo componente tabella
			 * @param  {object} col
			 * @return {element}
			 */
      let expanded_cols = this.props.model.expandable_columns.map(col => {
        /** Uso una funzione ricorsiva per formattare tutte le colonne figlie e figlie di figlie */
        return this.returnSingleColObject(col, this.props);
      });

      let expanded_data = this.props[this.props.model.onExpand](record, this.props);

      return <TableComponent data={expanded_data} model={{ columns: expanded_cols, ...this.props.model.expanded_props }}
        size="medium"
        ref={r => { this.children_tables[expanded_data.key || record.key || record.id] = r }}
        changeRowSelection={(rows) => this.setState({ children_selected_rows: rows })}
        loading={false}
      />

    }

  }

	/**
	 * Formatta i dati in modo che corrispondano a quanto definito nel model
	 *
	 * Inserisce nello state i dati formattati
	 *
	 * @return {void}
	 *
	 * @public
	 */
  formatDatas(props) {

    this.setState({
      data: props.data.data,
      pagination: {
        current: props.data.current,
        total: props.data.total
      }
    });
  }

	/**
	 * Se riceve nuovi dati ricarica il componente
	 *
	 * @param  {object} newProps Nuove proprietà
	 * @return {void}
	 *
	 * @public
	 */
  //componentWillReceiveProps(newProps) {
    //if(newProps.data != this.props.data || newProps.model != this.props.model) this.mount(newProps);
  //}

	/**
	 * Ritorna gli item selezionati
	 * @return {array} Record selezionati
	 *
	 * @public
	 */
  getSelectedRows() {
    //console.log('righe selezionate', this.state.selected_rows, this.state.children_selected_rows);

    return [...this.state.selected_rows, ...this.state.children_selected_rows];
  }

	/**
	 * Espone l'aggiornamento all'esterno richiamandolo
	 *
	 * Richiama updateRowSortCols()
	 *
	 * @param  {array} data         Nuovi dati
	 * @param  {Number} page        Pagina corrente
	 * @param  {Number} total 		Numero totale di risultati
	 * @return {void}
	 *
	 * @public
	 */
  updateTableData(data = [], page = 1, total = 1, sort = {}) {
    //console.log('ricevo nuovo sort', sort)
    this.setState({
      data: data,
      pagination: {
        current: page,
        total: total,
      },
      sorter: {
        columnKey: sort.key,
        order: sort.order
      }
    }, () => this.formatColumns(this.props))
  }

  updateChildrenTableData(id, data = [], page = 1, total = 1, sort = {}) {
    if (id && this.children_tables[id])
      this.children_tables[id].updateTableData(data, page, total, sort);
  }

	/**
	 * Monta o ricarica il componente
	 * Richiama formatColumns()
	 *
	 * @param  {object} props Proprietà
	 * @return {void}
	 *
	 * @public
	 */
  mount(props) {
    this.formatColumns(props);
    this.formatDatas(props);
  }

  render() {
    return <Table
      size={this.props.size || 'small'}
      ref="table"
      rowSelection={(this.state.selectable) ? {
        onChange: (selectedRowKeys, selected_rows) => {
          //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selected_rows: ', selected_rows);
          this.setState({
            selected_rows: selected_rows
          })
          if (this.props.changeRowSelection) {
            this.props.changeRowSelection(selected_rows)
          }
        }
      } : null}
      expandedRowRender={(this.state.expandable) ? this.returnExpandedRows.bind(this) : null}
      bordered={this.props.bordered || false}
      dataSource={this.state.data}
      columns={this.state.columns}
      pagination={(this.state.with_server_pagination) ? { ...this.state.pagination, pageSize: 20 } : false}
      loading={this.props.loading}
      onChange={this.handleTableChange.bind(this)}
      {...this.props.table_props || null}

    />
  }
}

//scroll={{ y: '80vh' }}