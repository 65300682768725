import BaseRequest from "@common/services/_baseRequest";


export default {

	downloadFiles: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/complete/' + id + '/download',
			cb,
			ecb || null,
			null
		);
	},

	confirm: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/complete/' + id + '/confirm',
			cb,
			ecb || null,
			null
		);
	},

	rollback: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/complete/' + id + '/rollback',
			cb,
			ecb || null,
			null
		);
	},

	upload: (id, data, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/complete/' + id + '/upload',
			cb,
			ecb || null,
			null
		);
	},

	send: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/complete/' + id + '/send',
			cb,
			ecb || null,
			null
		);
	},

	acquire: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/complete/' + id + '/acquire',
			cb,
			ecb || null,
			null
		);
	},

	changeStato: (id, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/' + id,
			cb,
			ecb || null,
			null
		);
	},

}
