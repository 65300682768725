import React, { Component } from 'react';
import CommonForm from '@common/components/form';

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: 'Titolo unità formativa',
                                name: 'moduleTitle',
                                type_string: 'text',
                                props: {
                                    disabled:true
                                },
                              }
                        ],
                    },
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: 'Denominazione',
                                name: 'unitName',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 4,
                        items: [
                            {
                                type: 'integer',
                                label: 'Durata (in ore)',
                                name: 'duration',
                                props : {
                                    min : 0
                                },
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                    {
                        span: 10,
                        items: [
                            {
                                type: 'select',
                                label: 'Competenze',
                                name: 'unitSkills',
                                options_props: '_competenze',
                              }
                        ],
                    },
                    {
                        span: 10,
                        items: [
                            {
                                type: 'select',
                                label: 'Caratteristiche',
                                name: 'unitFeatures',
                                options_props: '_caratteristiche',
                              }
                        ],
                    },

                ]
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Contenuti formativi',
                                name: 'trainingContent',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Abilità',
                                name: 'unitAbility',
                                type_string: 'text',
                              }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Conoscenze',
                                name: 'unitKnowledge',
                                type_string: 'text',
                              }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'btn',
                                type: 'submit',
                                text: 'Salva',
                                btn_type: 'primary'
                            },
                        ],
                    },
                ],
            }

        ],
        onSubmit: 'submittedUnita',
    }
}

class EditUnitaDidattica extends Component {

    state = {
        loading: true,
        unitaDidattica: {}
    };

    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loading: false,
            unitaDidattica: props.unitaDidattica
        });
    }

     submit(data) {
        if (!this.state.loading) {
            this.setState({
                loading: true,
                unitaDidattica : data });
            if(this.props.createUnita){
                this.props.onCreateUnita(data);
            }else{
                this.props.onUpdateUnita(data);
            }
        }
    }

    render() {
        return (
            <div>
                <CommonForm
                    loading={this.state.loading || false}
                    form_model={formLayout(this)}
                    values={this.state.unitaDidattica}
                    readOnly={this.props.readOnly}
                    submittedUnita={(data) => this.submit(data)}
                    _caratteristiche={this.props.GlobalStore.stubs.caratteristiche_unita_didattica}
                    _competenze={this.props.GlobalStore.stubs.competenze_unita_didattica}
                />
            </div>
        )
    }
}

export default EditUnitaDidattica;