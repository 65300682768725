import React, { Component } from 'react';

import { Button } from 'antd';

import ModelServices from '../../services/Accreditamento';


import FileSaver from 'file-saver';




class DownloadCasellarioGiudiziale extends Component {

  state = {
    loading: false,
    downloading: false
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `autocertificazione_${date}${month}${year}_${hour}${minu}.pdf`;
    FileSaver.saveAs(_blob, nome_file);

    this.setState({loading: false, downloading: false})

  }

  saveBlobWord(blob) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `autodichiarazione_casellario_${date}${month}${year}_${hour}${minu}.odt`;
    FileSaver.saveAs(_blob, nome_file);

    this.setState({loading: false, downloading: false})

  }

  render() {
    return <div>
      <Button size="small" disabled={this.state.loading} loading={this.state.downloading} className="m5h" type="primary" icon="file-word" onClick={() => {
        this.setState({ loading: true, downloading: true }, () => {
          ModelServices.download_casellario_word(
            this.props.idAccreditamento,
            (res) => {
              this.setState({ loading: false, downloading: false });
              this.saveBlobWord(res.data)
            },
            () => this.setState({ loading: false, downloading: false })
          )
        });

      }}>Scarica formato Odt</Button>
    </div>
  }
}

export default DownloadCasellarioGiudiziale;