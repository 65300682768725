import React, { Component } from 'react';

import { Card, Row, Col, Divider, Button } from 'antd';

import Wrapper from '@common/decorators/Wrapper';

import ChangeForm from '../forms/ChangePassword';

import UserServices from '@common/services/User';
import checkPermissions from '@common/utils/check/permissions';
import moment from 'moment';





@Wrapper({ route: 'Account', hasSidebar: true, hasHeader: true, hasFooter: true })
class ChangeTempPassword extends Component {

  state = {
    loading: false,
    changed: false,
  }



  tryChange(data) {

    this.setState({ loading: true });
    UserServices.changePassword({
      new_password: data.new_password,
      old_password: data.old_password,
      repeat_password: data.repeat_password
    }, () => {

      //console.log('responso password cambiata', response_change.data);

      this
        .props
        .dispatch({
          type: 'USER_STORE_SET_USER',
          user: {
            ...this.props.UserStore.user,
            is_temp_password: 0
          }
        });

      this.props.history.push('/dashboard');



    }, () => {

      this.setState({ loading: false });
    })
  }

  getDataIscrizione(dt) {
    try{
      return moment(dt, 'YYYY-MM-DD').format('DD-MM-YYYY');
    } catch (e) {
      return ''
    }
  }

  render() {

    return (
      <div>
        {
          this.props.UserStore && this.props.UserStore.user && this.props.UserStore.user.id
          ?
          <React.Fragment>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
            >
              <h3 className="sectionTitle">Utente: {this.props.UserStore.user.username}</h3>
            </Card>
            <Row gutter={24}>
              {

                //checkPermissions.hasPermission(this.props.UserStore.user, ['entePermissions']) &&
                checkPermissions.hasPermission(this.props.UserStore.user, ['entePermissions']) ?
                <Col span={12}>
                  <Card
                    className="shadow"
                    bordered={false}
                  >
                    <h3>RAPPRENSENTANTE LEGALE</h3>
                    <div>
                      <p><strong>Nome: </strong> {this.props.UserStore.user.anagrafica?.nome || ""} </p>
                      <p><strong>Cognome: </strong> {this.props.UserStore.user.anagrafica?.cognome || ""}</p>
                      <p><strong>Codice Fiscale: </strong> {this.props.UserStore.user.anagrafica?.cf || ""}</p>
                      <p><strong>Email: </strong> {this.props.UserStore.user.email || ""}</p>
                      <Button disabled={this.state.loading} style={{ marginRight: 12 }} type="primary" size="small" onClick={() => { this.props.history.push("/account/" + this.props.UserStore.user.id); }} ghost>Modifica profilo</Button>
                    </div>
                    <Divider />
                    <h3 className="m10h">DATI ENTE</h3>
                    <div>
                      <p><strong>Denominazione: </strong> {this.props.UserStore.user.ente?.denominazione || ""} </p>
                      <p><strong>Codice Fiscale: </strong> {this.props.UserStore.user.ente?.cfente || ""}</p>
                      <p><strong>Partita Iva: </strong> {this.props.UserStore.user.ente?.partita_iva || ""}</p>
                      <p><strong>Data Iscrizione: </strong> {this.getDataIscrizione(this.props.UserStore.user.ente?.data_iscrizione)}</p>
                    </div>
                  </Card>
                </Col>
                :
                    <Col span={12}>
                      <Card
                        className="shadow"
                        bordered={false}
                      >
                        <h3>DATI ANAGRAFICI</h3>
                        <div>
                          <p><strong>Nome: </strong> {this.props.UserStore.user.anagrafica?.nome || ""} </p>
                          <p><strong>Cognome: </strong> {this.props.UserStore.user.anagrafica?.cognome || ""}</p>
                          <p><strong>Codice Fiscale: </strong> {this.props.UserStore.user.anagrafica?.cf || ""}</p>
                          <p><strong>Email: </strong> {this.props.UserStore.user.email || ""}</p>
                          {this.props.UserStore.user.anagrafica?.matricola ? <p><strong>Matricola: </strong> {this.props.UserStore.user.anagrafica?.matricola || ""}</p> : null}
                          <Button disabled={this.state.loading} style={{ marginRight: 12 }} type="primary" size="small" onClick={() => { this.props.history.push("/account/" + this.props.UserStore.user.id); }} ghost>Modifica profilo</Button>
                        </div>
                      </Card>
                     
                      {checkPermissions.hasPermission(this.props.UserStore.user, ['addRichiestaRuolo']) &&
                        <Card
                          className="shadow, m10h"
                          bordered={false}
                        >
                          <h3>RUOLI</h3>
                          <div>
                            <p><strong>Ruoli: </strong>  {Array.isArray(this.props.UserStore.user?.role) && <span>{this.props.UserStore.user.role.map(r => r.name).join(', ')}</span>}</p>
                              <Button disabled={this.state.loading} style={{ marginRight: 12 }} type="primary" size="small" onClick={() => { this.props.history.push("/ruoli/richieste"); }} ghost>Richieste di ruolo</Button>
                          </div>
                        </Card>
                      }

                    </Col>
                }
            </Row>
            
          </React.Fragment>
          : null}

      </div>
    );
  }
}

export default ChangeTempPassword;
