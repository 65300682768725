import React, {Component} from 'react';

import {Button, Popconfirm, List, Modal} from 'antd';

import moment from 'moment';

import AnagraficaServices from '../services/Anagrafica';
import ModelForm from '../forms/EsperienzaFormativa';
import checkPermissions from '@common/utils/check/permissions';
export default class EsperienzeFormative extends Component {


  state = {
    loading: false,
    element_values: {}, // valori elemento da modificare
    open_drawer: false,
    drawer_title: "Aggiungi una esperienza formativa",
    eliminating: []
  }

  update() {
    //this.props.onUpdate({documenti})
  }

  async create() {
    this.setState(
      {
        element_values: {},
        drawer_title: 'Aggiungi una esperienza formativa',
        open_drawer: true
      }
    );
  }

  addEsperienzaFormativa(data, cb) {
    this.setState({ loading: true });
    AnagraficaServices.addEsperienzaFormativa( data, this.props.model.id, (res) => {
      this.props.onUpdate({
        ...this.props.model,
        ...res.data.data
      })
      this.setState({open_drawer: false, loading: false}, () => this.form.reset());
      cb();
    }, () => this.setState({ loading: false }, () => cb()));
  }

  deleteEsperienzaFormativa(id) {
    if(this.state.eliminating.indexOf(id) !== -1) return;

    this.setState({eliminating: [...this.state.eliminating, id]}, ()=>{
      AnagraficaServices.deleteEsperienzaFormativa( this.props.model.id, id, (res) => {
        this.setState({eliminating: this.state.eliminating.filter(el => el !== id)});
        this.props.onUpdate({
            ...this.props.model,
            esperienzeFormative: this.props.model.esperienzeFormative.filter(els => els.id !== id)
          })
      }, () => this.setState({eliminating: this.state.eliminating.filter(el => el !== id)}) )
    })
  }

  getSource() {
    try {
      return this.props.model.esperienzeFormative
    } catch(e) {
      return []
    }
  }

  getItemActions(item) {
    return (checkPermissions.hasPermission(this.props.UserStore.user, ['EditAnagrafica','adminPermissions']) && !this.props.readOnly)
    ? [
      <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere questo elemento" onConfirm={()=>this.deleteEsperienzaFormativa(item.id)} okText="Si" cancelText="No">
        <Button loading={this.state.eliminating.indexOf(item.id) !== -1} type="danger" size="small" ghost>Elimina</Button>
      </Popconfirm>
    ]
    : null
  }


  render () {
    return (
      <div>
        {
          (checkPermissions.hasPermission(this.props.UserStore.user, ['EditAnagrafica','adminPermissions']))
          ?
          <Button disabled={this.props.readOnly} type="primary" ghost size="small" style={{marginBottom: 12}} onClick={()=>this.create()}>Aggiungi</Button>
          : null
        }
        <List
          dataSource={this.getSource()}
          renderItem={item => (
              <List.Item
              actions={this.getItemActions(item)}
            >
            <p>
              <strong>Periodo: </strong> {moment(item.dal).format('DD/MM/YYYY') + " - " + moment(item.al).format('DD/MM/YYYY') + " (" + item.durata + " anni)"}<br />
              <strong>Istituzione Formativa/Soggetto erogatore: </strong> {item.erogatore}<br />
              <strong>Denominazione del percorso: </strong> {item.denominazione}<br />
              <strong>Attestazione in esito: </strong> {item.attestazione}<br />
              <strong>Obiettivi/contenuti: </strong> {item.obiettivi}<br />
            </p>
            </List.Item>
          )}
        />
        <Modal
          title={this.state.drawer_title}
          visible={this.state.open_drawer}
          okText={null}
          width={800}
          okButtonProps={{ style: {display: 'none' }} }
          onCancel={() => this.setState({ open_drawer: false }, () => this.form.reset())}
        >
        <ModelForm
          ref={(ref) => this.form = ref}
          values={this.state.element_values}
          model={this.props.model}
          loading={this.state.loading}
          GlobalStore={this.props.GlobalStore}
          submit={(data, cb)=>this.addEsperienzaFormativa(data, cb)} />
      </Modal>
    </div>
    );
  }
}

