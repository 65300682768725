import React, { Component } from 'react';
import { Button, Drawer, Modal, Popconfirm, Table, notification } from 'antd';
import { connect } from "react-redux";
import ModelServices from '@accreditamento/services/Nomina';

const ispettori_table = (component) => {

  return [
      {
        title: 'Username',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'username',
        render: element => <div>{element.username}</div>,
      },
      {
        title: 'Nome',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: '',
        render: element => <div>{element.anagrafica.nome + " " + element.anagrafica.cognome}</div>,
      },
      {
        title: 'Email',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'email',
        render: element => <div>{element.email}</div>,
      },
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'created_at',
        render: element => <div style={{ width: 120 }}>
          <Popconfirm disabled={component.state.assigning} placement="top" title="Confermi di voler assegnare la pratica a questo Addetto al controllo?"
            onConfirm={() => component.assign(element.id)}
            okText="Si" cancelText="No">
            <Button disabled={component.state.assigning} className="m5h" type="primary" icon="save" size="small">Assegna la pratica</Button>
          </Popconfirm>
        </div>,
      }
    ]

}





class SceltaIspettoreSingolo extends Component {

  state = {
    loaded: false,
    loading: false,
    selected_id: null,
    open_list: false,
    ispettori: [],
    open_modal_ispettore_random: false,
    random_ispettore: {}
  }





  async assign(ispettore) {
    if(!this.state.assigning) {
      await this.setState({ assigning: true });
      ModelServices.assign(this.props.accreditamento.id, { id_ispettore: ispettore, id_ispezione: this.props.ispezione.id }, (res) => {
        this.props.refreshInline(res.data.data);
        this.setState({ open_modal_ispettori: false, assigning: false })
      }, () => this.setState({ assigning: false }));
    }
  }

  assignrandom() {
    if(!this.state.loading) {
      this.setState({
        loading: true,
        assigning: true
      }, () => {
        ModelServices.randomassign(this.props.accreditamento.id, (res) => {
          if(res.data === null){
            notification.warning({
              placement: 'topLeft',
              duration: 5,
              message: "Ricerca Addetto al controllo",
              description: 'Al momento non ci sono ispettori disponibili',
            });
            this.setState({loading: false, assigning: false});
          } else {
            this.setState({loading: false, assigning: false, random_ispettore: res.data.data, open_modal_ispettore_random: true});
          }

        }, () => {
          this.setState({loading: false, assigning: false});
        } );
      })
    }
  }

  async loadIspettori() {
    if(!this.state.loading_data) {
      await this.setState({loading_data: true});
      ModelServices.listIspettori({}, (res) => {
        this.setState({loading_data: false, ispettori: res.data.data });
      }, () => this.setState({loading_data: false}));
    }
  }

  getIspettori() {
    let excluded_ids = [];

    this.props.ispezione.nomine.map(n => {
      excluded_ids.push(n.id_ispettore);
      return n;
    });

    return this.state.ispettori.filter(e => excluded_ids.indexOf(e.id) === -1 )
  }


  render() {
    return <div>
      <Button
        loading={this.state.assigning}
        disabled={this.state.loading}
        className="m5h" type="primary" icon="search" onClick={() => this.assignrandom()}>Nomina Addetto al controllo
      </Button>
      <Modal
          title="Addetto al controllo selezionato"
          visible={this.state.open_modal_ispettore_random}
          confirmLoading={this.state.assigning}
          onOk={async () => {
            this.setState({open_modal_ispettore_random: false});
            this.assign(this.state.random_ispettore.id);
          }}
          destroyOnClose={true}
          okText="Conferma selezione automatica"
          onCancel={() => this.setState({ open_modal_ispettore_random: false })}
        >{
          this.state.random_ispettore.id
          ?
          <React.Fragment>
            <h4>{this.state.random_ispettore.anagrafica.nome} {this.state.random_ispettore.anagrafica.cognome}</h4>
            <p>{this.state.random_ispettore.username}</p>
            <Button
            onClick={()=> {
              this.loadIspettori();
              this.setState({open_modal_ispettore_random: false, open_modal_ispettori: true, random_ispettore: {}})
            }}
            disabled={this.state.loading} className="m5h" type="primary" ghost style={{ marginRight: 8 }}>
            Seleziona manualmente
          </Button>
          </React.Fragment>
          : null
        }

        </Modal>
      <Drawer
        title="Cerca un Addetto al controllo"
        placement="right"
        closable={true}
        onClose={() => this.setState({ open_modal_ispettori: false })}
        visible={this.state.open_modal_ispettori}
        width={1024}
      >

          <Table
          loading={this.state.loading_data || this.state.assigning}
          columns={ispettori_table(this)}
          rowKey={(record)=>"isp_"+record.id}
          pagination={false}
          dataSource={this.getIspettori(this.state.ispettori)} />

      </Drawer>
      </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SceltaIspettoreSingolo);