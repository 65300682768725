import React, { Component } from 'react';
import moment from 'moment';

import { Icon, message } from 'antd';
import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';

import formatter from '@common/utils/formatters/_';


const formLayout = (component) => {
  // ATTENZIONE! ho frainteso il bug https://cb.schema31.it/cb/issue/420880
  // Credevo che i dati dovessero essere non editabili solo se provenienti da SPID.
  // In realtà non devono essere MAI editabili (da cui l'apparentemente inutile "|| true")
  // Per scongiurare che si cambi idea lascio anche "component.props.accreditamento.ente.rappresentanteLegale.from_spid"
  let from_spid = component.props.accreditamento.ente.rappresentanteLegale.from_spid || true;
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'titolo_profilo',
                type: 'title',
                element: 'h4',
                text: 'Rappresentante legale',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Nome',
                type_string: 'text', // field type
                name: 'nome',
                props: {
                  //size: 'large',
                  disabled: component.props.readOnly || from_spid,
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: 'input',
                label: 'Cognome',
                type_string: 'text', // field type
                name: 'cognome',
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly || from_spid,
                  prefix: (
                    <Icon type="solution" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'datepicker',
                label: 'Data di nascita',
                name: 'nato_il',
                format: 'DD/MM/YYYY',
                props: {
                  // size: 'large',
                  style: { width: '100%' },
                  disabled: component.props.readOnly || from_spid,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'age18Validator'
                  }
                ]
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Stato di nascita',
                name: 'stato_nascita',
                props: {
                  //size: 'large',
                  showSearch: true,
                  disabled: component.props.readOnly || from_spid,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'nazioni',
                onChange: 'selectedStato',
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Provincia di nascita',
                name: 'provincia_nascita',
                props: {
                  showSearch: true,
                  disabled: component.props.readOnly || from_spid,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'province',
                onChange: 'selectedNascitaProvincia',
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Comune di nascita',
                name: 'comune_nascita',
                props: {
                  showSearch: true,
                  disabled: component.props.readOnly || from_spid,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'provincia_nascita',
                    operator: '!=',
                    value: null,
                  },
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options_props: 'comuni'
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Codice fiscale',
                type_string: 'text', // field type
                name: 'cf',
                props: {
                  // size: 'large',
                  prefix: <Icon type="copy" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  disabled: component.props.readOnly || from_spid,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^[A-z0-9]{16}$',
                    message: 'Codice fiscale non valido'
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Telefono',
                type_string: 'text', // field type
                name: 'telefono',
                props: {
                  // size: 'large',
                  prefix: <Icon type="copy" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                    message: 'Telefono non valido'
                  },
                ],
              },
            ],
          }
        ],
      }

    ],
    onSubmit: 'submitted',
  }
}

class DatiRappresentanteLegaleForm extends Component {
  state = {
    loading: true,
    loaded: false,
    comuni: [],
    files: {}
  };



  async componentDidMount() {

    //if (this.props.values) this.setFileInState(this.props);
    this.loadComuni(this.props)
  }



  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id !== this.props.accreditamento.id) this.loadComuni(newProps)
  }

  loadComuni(props) {
    if (props.accreditamento.ente.rappresentanteLegale.provincia_nascita) {
      this.selectedProvincia(props.accreditamento.ente.rappresentanteLegale.provincia_nascita)
    } else {
      this.setState({ loading: false });

      if (this.props.loadingComuni) {
        this.props.loadingComuni(false);
      }
    }
  }

  selectedProvincia(provincia) {

    this.setState({ loading: true });


    ComuniService.all(
      {
        id_provincia: provincia
      },
      res => {

        this.setState({
          loading: false,
          comuni: res.data.data || [],
          selected_nascita_provincia: provincia
        });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }


  async validate() {
    return new Promise((resolve) => {
      this.formRefDatiRappr.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }


  getFormData() {
    return formatter.replaceFormDatesOut(['nato_il'], this.formRefDatiRappr.props.form.getFieldsValue());
  }



  getValues() {
    try {
      return formatter.replaceFormDatesIn(['nato_il'], {
        ...this.props.accreditamento.ente.rappresentanteLegale,
        telefono: this.props.accreditamento.ente.rappresentanteLegale.contatti && this.props.accreditamento.ente.rappresentanteLegale.contatti.length > 0 ? this.props.accreditamento.ente.rappresentanteLegale.contatti[0]['telefono'] : '',
      })
    } catch (e) {
      console.warn('TEST POINT 3: OK', e);
      return formatter.replaceIntegeresWithString(['comune_nascita'], this.props.accreditamento.ente.rappresentanteLegale);
    }
  }

  render() {

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRefDatiRappr = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={
            this.getValues()
          }

          age18Validator={(rule, value, cb) => {
            var today = moment();
            if (today.diff(value, 'years') < 18) cb("Il rappresentante legale deve essere maggiorenne");
            else cb()
          }}

          onlyPastDate={(rule, value, cb) => {
            if (moment() > value) {
              cb()
            } else {
              cb("Data non valida");
            }
          }}

          nazioni={this.props.GlobalStore.stubs.nazione}
          province={this.props.GlobalStore.stubs.provincia}

          readOnly={this.props.readOnly}

          selectedStato={() => {
            //console.log (this.formRefDatiRappr.props.form);
            this.setState({
              comuni: [],
            }, () => {
              this.formRefDatiRappr.props.form.setFieldsValue({ comune_nascita: null, provincia_nascita: null });
            });
          }}

          selectedNascitaProvincia={data => {
            //console.log (this.formRefDatiRappr.props.form);

            this.setState({
              selected_nascita_provincia: data,
              comuni: [],
              loading: true,
            }, () => {
              this.formRefDatiRappr.props.form.setFieldsValue({ comune_nascita: null })
              this.selectedProvincia(data);
            });
          }}

          comuni={this.state.comuni.map(e => {
            return {
              key: 'residenza_comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}
        />
      </div>
    );
  }
}

export default DatiRappresentanteLegaleForm;