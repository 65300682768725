import React, { Component } from 'react';

import { Button, Card, Drawer, message, Popconfirm, Row, Tooltip } from 'antd';
import TableComponent from '@common/components/table';

import ModelServices from '../../services/Settore';
import ModelUpdate from '../../forms/settore/Update';
import Create from './Create';

import checkPermissions from '@common/utils/check/permissions';

import _ from '@common/utils/formatters/_';


const model = (component) => {
  return {
    with_server_pagination: true, // metti paginazione lato server
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'action',
        render: element => <div style={{ width: 60 }}>
          {
            checkPermissions.hasPermission(component.props.UserStore.user, ['EditSettore']) &&
            <Tooltip placement="top" title="Modifica">
              <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.updateRecord(element.id)} />
            </Tooltip>
          }
          {
            checkPermissions.hasPermission(component.props.UserStore.user, ['DeleteSettore']) &&
            <Tooltip placement="top" title="Disattiva">
              <Popconfirm placement="leftTop" title="Sicuro di voler disattivare questo elemento" onConfirm={() => component.changeStatus(element.id, 0)} okText="Si" cancelText="No">
                <Button className="m2w" size="small" shape="circle" icon="delete" />
              </Popconfirm>
            </Tooltip>
          }
        </div>,
      },
      /*
      {
        title: 'ID',
        dataIndex: '',
        sorter: true,
        key: 'id',
        render: element => <div style={{ width: 50 }}>{element.id}</div>,
      },
      */
      {
        title: 'Nome',
        dataIndex: '',
        sorter: true,
        key: 'nome_settore',
        render: element => <div>{element.nome_settore}</div>,
      },
      /*
      {
        title: 'Descrizione',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'descrizione',
        render: element => <div style={{ width: 300 }}>{element.descrizione}</div>,
      },
      {
        title: 'Valido dal',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'valido_dal',
        render: element => <div >{element.valido_dal}</div>,
      },
      {
        title: 'Valido al',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'valido_al',
        render: element => <div >{element.valido_al}</div>,
      },
      */
      {
        title: 'Numero qualifiche professionali',
        dataIndex: '',
        sorter: false,
        key: 'countQualificheProfessionali',
        render: element => <div>{element.countQualificheProfessionali}</div>,
      },
    ]
  }
}

class ListModel extends Component {
  base_url = '/settore'
  default_order = 'nome_settore'

  state = {
    loading: false,
    open_drawer: false,
    editing_id: null,
    data: {
      total: 0,
      current: 1,
      data: []
    }
  }

  componentDidMount() {
    this.callAndUpdate(this.props)
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  callAndUpdate(props) {
    let { params, pager } = _.getFilterParam(props, this.default_order);
    this.setState({ loading: true });

    params.expand = [
      'countQualificheProfessionali',
    ].join(',');

    ModelServices.list(params, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, () => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.callAndUpdate(this.props.location.search)
    }
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.callAndUpdate(this.props)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  updateRecord(id) {
    this.setState({ editing_id: id });
    this.setState({ open_drawer: true });
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  changeStatus(id, status) {
    this.setState({ loading: true });
    ModelServices.changeStatus(id, status, () =>
    message.success("Operazione effettuata con successo"),
    this.refresh(), () => this.setState({ loading: false }))
  }

  /**
   * Record aggiornato in form
   * @return {[type]} [description]
   */
  updatedRecord() {
    this.setState({ open_drawer: false, editing_id: false });
    this.callAndUpdate(this.props);
  }

  render() {
    return (
      <div>
        <Card
          className="shadow no-pad"
          bordered={false}
        >

          <Row type="flex" justify="start" className="m10h">
            <div className="m5h m5w">
              {
                checkPermissions.hasPermission(this.props.UserStore.user, ['AddSettore']) &&
                <Create
                  roles={this.state.roles}
                  oncreate={() => this.refresh()}
                  {...this.props} />
              }
            </div>
          </Row>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
        <Drawer
          title="Aggiorna record"
          width={720}
          placement="right"
          onClose={() => this.setState({ open_drawer: false })}
          maskClosable={true}
          visible={this.state.open_drawer}
          destroyOnClose={true}
          style={{
            overflow: 'auto',
          }}
        >
          <ModelUpdate id={this.state.editing_id} updateRecord={() => this.updatedRecord()} />
        </Drawer>
      </div>
    );
  }
}
export default ListModel;
