import React, { Component } from 'react';
import { message, Row, Input } from 'antd';

class Estremi extends Component {
  state = {
    loading: false,
    loaded: false,
    parents: [],
    value: {}
  };



  getVal() {
    try {
      return JSON.parse(this.props.initialValue)
    } catch(e) {
      return {}
    }
  }

  async componentDidMount() {
    this.setState({value: this.getVal()})

  }

  getFormData() {
    let state = {};
    this.props.parents.map(el => state[el] = this.state.value[el]);
    try {
      return JSON.stringify(state);
    } catch(e) {
      console.error(e);
      return ""
    }
  }

  validate() {
    let valid = true;

    this.props.parents.forEach( el => {
      if(!this.state.value[el] || this.state.value[el] === '') valid = false;
    })

    if(!valid) message.error('Inserisci gli estremi per ogni provvedimento');
    return valid;
  }


  getName(id) {
    try {
      return this.props.stubs.find(el => parseInt(el.value) === parseInt(id)).label
    } catch(e) {
      console.error(e)
      return "errore"
    }
  }

  getInputValue(index) {
    try{
      return this.state.value[index]
    } catch(e) {
      return ""
    }
  }

  changeInput(e, index) {
    this.setState({value: {
      ...this.state.value,
      [index]: e.target.value
    }})
  }

  render() {

    return (
      <div>
        <Row gutter={8} style={{marginTop: 5, paddingLeft: 8}}>
          {
            this.props.parents && Array.isArray(this.props.parents) && this.props.parents.map( (el,i) => {
              return <div key={i+"___"+el}>
                <label style={{marginBottom: 0}}>Estremi provvedimento: {this.getName(el)}</label>
                <Input
                disabled={this.props.readOnly}
                onChange={(value)=>this.changeInput(value, el)}
                value={this.getInputValue(el)} />
              </div>
            })
          }
        </Row>
      </div>
    );
  }
}

export default Estremi;