import BaseRequest from '@common/services/_baseRequest';

export default {

    list: (params = {}, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            params,
            {},
            null,
            'get',
            'media/tipomedia',
            cb,
            ecb
        );
    },

    create: (cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"post",
			'media/tipomedia',
			cb,
			ecb || null,
			null
		);
	},

    detail: (params = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            params,
            {},
            null,
            'get',
            'media/tipomedia/' + id,
            cb,
            ecb
        );
    },

    update: (params = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            params,
            null,
            'put',
            'media/tipomedia/' + id,
            cb,
            ecb
        );
    },
}