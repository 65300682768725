import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import eventBroker from '@common/utils/services/EventBroker';
import { Spin } from 'antd';
import affidamentoStatic from '@common/utils/static/affidamento';
import checkPermission from '@common/utils/check/permissions';
import PropTypes from 'prop-types';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'coerenza_progettuale_A',
                type: 'title',
                element: 'h4',
                text: 'Finalità e coerenza progettuale A',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceAFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'coerenza_progettuale_sub_A',
                type: 'title',
                element: 'h5',
                text: 'Coerenza esterna con gli obiettivi esplicitati nell’avviso',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h subtitle'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceAFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione degli obiettivi progettuali (Descrivere l’impatto potenziale della proposta progettuale sugli obiettivi esplicitati nell’avviso, nonché sull’obiettivo specifico pertinente e sulle finalità generali perseguite con il POR Campania FESR FSE 2014-2020)',
                name: 'AfcProjectPurposeAndCoherenceAFormSection.projectAims',
                type_string: 'text',
                props: {
                  maxLength: "2000"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'coerena_progettuale_b',
                type: 'title',
                element: 'h4',
                text: 'Finalità e coerenza progettuale B',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'chiarezza_espositiva',
                type: 'title',
                element: 'h5',
                text: 'Chiarezza espositiva',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h subtitle'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione del progetto formativo (Descrivere il progetto formativo includendo una esposizione dei costi dettagliata in ogni sua componente e rispondente alle indicazioni dell’avviso)',
                name: 'AfcProjectPurposeAndCoherenceBFormSection.exhibitionClarity',
                type_string: 'text',
                props: {
                  maxLength: "5000"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'coerenza_interna',
                type: 'title',
                element: 'h5',
                text: 'Coerenza interna',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h subtitle'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione del contesto territoriale di riferimento  (descrivere il contesto nell’ambito del quale si realizzerà il progetto in relazione anche ai fabbisogni occupazionali e formativi)',
                name: 'AfcProjectPurposeAndCoherenceBFormSection.territorialContext',
                type_string: 'text',
                props: {
                  maxLength: "2000"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },

      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Attività di selezione e orientamento (descrivere la modalità di selezione ed orientamento)',
                name: 'AfcProjectPurposeAndCoherenceBFormSection.selectionOrientationActivities',
                type_string: 'text',
                props: {
                  maxLength: "1000"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Procedure di riconoscimento dei crediti formativi in ingresso al percorso (descrivere le modalità attraverso le quali si garantisce il riconoscimento di crediti formativi in ingresso al percorso)',
                name: 'AfcProjectPurposeAndCoherenceBFormSection.recognitionCredits',
                type_string: 'text',
                props: {
                  maxLength: "500"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Azioni per favorire l’inserimento di eventuali allievi in situazioni di disabilità (descrivere le modalità con cui si intende supportare l’inserimento attivo di allievi con disabilità nel percorso formativo)',
                name: 'AfcProjectPurposeAndCoherenceBFormSection.disabledStudentsInsertion',
                type_string: 'text',
                props: {
                  maxLength: "500"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Modalità di pubblicizzazione dell’intervento (descrivere la modalità adottata per pubblicizzare l’intervento formativo)',
                name: 'AfcProjectPurposeAndCoherenceBFormSection.advertising',
                type_string: 'text',
                props: {
                  maxLength: "500"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Monitoraggio dell’intervento (descrivere la modalità di monitoraggio inserendo specifici indicatori di realizzazione ed efficacia delle azioni formative individuando gli indici di successo atteso)',
                name: 'AfcProjectPurposeAndCoherenceBFormSection.monitoring',
                type_string: 'text',
                props: {
                  maxLength: "2500"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcProjectPurposeAndCoherenceBFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'coerenza_territorio',
                type: 'title',
                element: 'h4',
                text: 'Ricaduta sul territorio',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcTerritoryImpactFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'coerenza_territorio_sub',
                type: 'title',
                element: 'h5',
                text: 'Coerenza con le esigenze specifiche del territorio',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h subtitle'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcTerritoryImpactFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Collegamento con il tessuto imprenditoriale (descrivere l’impatto potenziale della proposta in termini di collegamento con le imprese del territorio e con le filiere collegate alla specifica qualifica professionale formata)',
                name: 'AfcTerritoryImpactFormSection.territoryCoherence',
                type_string: 'text',
                props: {
                  maxLength: "2000"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcTerritoryImpactFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'incidenza_proposta_sub',
                type: 'title',
                element: 'h5',
                text: 'Incidenza della proposta rispetto alle opportunità occupazionali',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h subtitle'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcTerritoryImpactFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Ricaduta occupazionale attesa (descrivere l’impatto potenziale della proposta progettuale in termini di ricaduta occupazionale individuando gli obiettivi attesi al termine del percorso progettuale e a distanza di 6 mesi dal termine del progetto)',
                name: 'AfcTerritoryImpactFormSection.employmentOpportunities',
                type_string: 'text',
                props: {
                  maxLength: "2000"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcTerritoryImpactFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'strategia_specializzazione',
                type: 'title',
                element: 'h4',
                text: 'Contributo all’attuazione della Strategia di specializzazione intelligente',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcSmartSpecializationStrategyFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'coerenza_progettuale_sub_A',
                type: 'title',
                element: 'h5',
                text: 'Capacità della proposta di contribuire all’attuazione della strategia regionale di specializzazione intelligente',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h subtitle'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcSmartSpecializationStrategyFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Correlazione con la Strategia regionale di specializzazione intelligente (descrivere la capacità della proposta di contribuire all’attuazione della strategia regionale di specializzazione intelligente)',
                name: 'AfcSmartSpecializationStrategyFormSection.SpecializationStrategyContribution',
                type_string: 'text',
                props: {
                  maxLength: "1500"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.nonTrainingElements.AfcSmartSpecializationStrategyFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit_elementi_non_formativi',
                text: 'Salva i dati',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.anteprima && !component.props.readOnly; }
                  }
                ],
                //onClick: 'showDrawer',
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'submit',
  }
};

class ElementiNonFormativiForm extends Component {

  constructor(props) {
    super(props);
    this.getBacknonTrainingElements = this.getBacknonTrainingElements.bind(this);
    eventBroker.on('getBacknonTrainingElements', this.getBacknonTrainingElements);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  }

  state = {
    loaded: false,
    loading: true,
    nonTrainingElements: {}
  };

  componentDidMount() {
    this.load(this.props)
  }

  load(props) {
    this.setState({
      loaded: true,
      loading: false,
      loaded_source: true,
      nonTrainingElements: props.nonTrainingElements
    });
  }

  getBacknonTrainingElements() {
    return this.formSelRef?.props.form.getFieldsValue();
  }

  async submit(data) {
    let domanda = this.props.domanda_json;
    domanda.nonTrainingElements = data;

    /* this.setState({
       loading: true,
       loaded: false
     });*/

    this.props.saveJson(domanda);

    /*ModelServices.save_json(domanda, this.props.match.params.id,
      (res) => {
        if (this.props.updateProgress) {
          this.props.updateProgress(res.data.data.progress);
          this.props.refresh(false);
          message.success('Dati salvati con successo');
        }
        this.setState({
          loading: false,
          loaded: true,
          nonTrainingElements:  res.data.data.domanda_json.nonTrainingElements
        });
      },
      () => {
        this.setState({
          loading: false,
          loaded: true
        });
      }
    );*/
  }


  render() {
    return <div style={{ opacity: !this.props.saved ? 0.5 : 1 }}>
      {
        !this.props.saved ? <Spin /> : null
      }
      {checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
        <ValidazioneScheda
          canGiveFeedback={this.props.canGiveSectionFeedback && this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
          loadingFeedback={this.props.loadingFeedback}
          saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'nonTrainingElements')}
          feedback={this.props.section_feedback ? this.props.section_feedback['nonTrainingElements'] : null}
        />
      }
      <CommonForm
        loading={this.props.saving || false}
        wrappedComponentRef={inst => (this.formSelRef = inst)}
        form_model={formLayout(this)}
        values={this.state.nonTrainingElements}
        readOnly={this.props.readOnly}
        submit={(data) => this.submit(data)}
      />
    </div>

  }
}

export default ElementiNonFormativiForm;
