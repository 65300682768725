import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Card } from 'antd';
import nominaStatic from '@common/utils/static/nomina';
import DownloadUpload from '@common/components/DownloadUpload';
import DocSpan from '@common/components/DocSpan';

class IspezioneVerbale extends Component {

    state = {
        loaded: false,
        loading: false
    }

    renderVerbaleFirmato(v) {

        return <div style={{marginTop: 18}}>
          {
            v.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_verbale_firmato).map(l => {
              return <div key={l.id}><p style={{display: 'inline-block', marginBottom: 0}}>Verbale firmato: </p><DocSpan key={l.id} file={l} /></div>
            })
          }

        </div>
      }



    render() {
        return <Row gutter={16}>
            <Col span={24}>
                <h4>Verbale</h4>
            </Col>
            {this.props.ispezione.verbali.map(v => {
                return <Col span={12}>
                    <div style={{ marginBottom: 16 }}>
                    <Card className="shadow no-pad" bordered={false}>
                    <p style={{marginBottom: 0}}>Sede: <strong>{v.sede?.indirizzo?.full_address}</strong></p>
                        {
                            v.stato === nominaStatic.stato_verbale_ispezione.in_compilazione && this.props.canEditVerbale &&
                            <div style={{ marginBottom: 16 }}>
                                <p>Scarica il verbale della visita ispettiva</p>
                                <DownloadUpload
                                    accept={'.pdf'}
                                    downloading={this.props.downloading || false}
                                    uploading={this.props.uploading || false}
                                    download={() => this.props.download(v.id)}
                                    upload={(file) => this.props.upload(file, v.id)}
                                >
                                </DownloadUpload>

                            </div>
                        }
                        <div style={{ marginTop: 16, marginBottom: 8 }}>{this.renderVerbaleFirmato(v)}</div>
                        </Card>
                    </div>
                </Col>
            })
            }
        </Row>
    }

}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(IspezioneVerbale);
