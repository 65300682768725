import React, { Component } from 'react';
import { Button, Card, Popconfirm, message, Tooltip, Row } from 'antd';

import queryString from 'query-string';
import moment from 'moment';

import checkPermission from '@common/utils/check/permissions';

import FilterForm from '@common/components/filterForm';
import TableComponent from '@common/components/table';
import affidamentoStatic from '@common/utils/static/affidamento';

import ModelServices from '../services/Procedura';
import DomandaServices from '../services/Domanda';

import FileSaver from 'file-saver';

import _ from '@common/utils/formatters/_';


const renderActions = (element, component) => {
  if (checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions'])) {

    let domanda = element.domande?.length > 0 ? element.domande[0] : null;

    return <div style={{ display: 'inline-block' }}>

      {domanda &&
        <Tooltip placement="top" title={element.stato === affidamentoStatic.stato_procedura.aperto && domanda.stato === 0 ? "Modifica formulario compilato" : "Visualizza formulario compilato"}>
          <Button className="m2w" size="small" shape="circle" icon={element.stato === affidamentoStatic.stato_procedura.aperto && domanda.stato === 0 ? "edit" : "eye"} onClick={() => component.props.history.push("/affidamento/formulari/" + element.id + "/compilati/" + domanda.id)} />
        </Tooltip>
      }

      {!domanda && element.stato === affidamentoStatic.stato_procedura.aperto &&
        <Tooltip placement="top" title="Compila formulario compilato">
          <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.createDomanda(element.id)} />
        </Tooltip>
      }

      {domanda &&
        <Tooltip placement="top" title="Scarica formulario compilato">
          <Button disabled={component.state.loading || !domanda.data_pdf} className="m2w" size="small" shape="circle" icon="solution" onClick={() => component.printPdf(domanda.id)} />
        </Tooltip>
      }

      {domanda &&
        <Tooltip placement="top" title="Elimina formulario compilato">
          <Popconfirm disabled={component.state.loading || element.stato === affidamentoStatic.stato_procedura.chiuso || domanda.stato >= affidamentoStatic.stato_progetto.presentato} placement="leftTop" title="Sicuro di voler rimuovere il formulario compilato?" onConfirm={() => component.deleteDomanda(domanda.id)} okText="Si" cancelText="No">
            <Button disabled={component.state.loading || element.stato === affidamentoStatic.stato_procedura.chiuso || domanda.stato >= affidamentoStatic.stato_progetto.presentato} className="m2w" size="small" shape="circle" icon="delete" />
          </Popconfirm>
        </Tooltip>
      }

    </div>
  } else {
    return <div style={{ display: 'inline-block' }}>

      <Tooltip placement="top" title="Visualizza formulario">
        <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/affidamento/formulari/" + element.id)} />
      </Tooltip>

      <Tooltip placement="top" title="Personalizza formulario">
        <Button disabled={component.state.loading} className={"m2w" + (element.formulario_personalizzato ? ' btn-green' : '')} size="small" shape="circle" icon="file-text" onClick={() => component.props.history.push({
          pathname: '/affidamento/formulari/' + element.id,
          //search: '?tab=3',
          state: { tab: 3 }
        })} />
      </Tooltip>

      <Tooltip placement="top" title="Visualizza formulari compilati">
        <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="solution" onClick={() => component.props.history.push("/affidamento/formulari/" + element.id + "/compilati/")} />
      </Tooltip>

      <Tooltip placement="top" title="Elimina formulario">
        <Popconfirm disabled={component.state.loading || element.stato !== affidamentoStatic.stato_procedura.attivo} placement="leftTop" title="Sicuro di voler rimuovere questo formulario?" onConfirm={() => component.delete(element.id)} okText="Si" cancelText="No">
          <Button disabled={component.state.loading || element.stato !== affidamentoStatic.stato_procedura.attivo} className="m2w" size="small" shape="circle" icon="delete" />
        </Popconfirm>
      </Tooltip>

    </div>
  }
}

const renderStatusIndicator = (record, component) => {

  /*
  let user = 'ente';

  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) user = 'ispettore';
  */

  let defined_c = record.stato === affidamentoStatic.stato_procedura.aperto ? 'green' : record.stato === affidamentoStatic.stato_procedura.chiuso ? 'red' : 'grey';
  let description = record.stato === affidamentoStatic.stato_procedura.aperto ? 'Aperto' : record.stato === affidamentoStatic.stato_procedura.chiuso ? 'Chiuso' : 'Attivo';

  return (defined_c !== '') ?
    <Tooltip placement="topRight" title={description}>
      <span className={"pallino " + defined_c}></span>
    </Tooltip>
  : <span className={"pallino invisible"}></span>;
}

const model = (component) => {
  if (checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions'])) {
    return {
      with_server_pagination: true,
      columns: [
        {
          title: '',
          dataIndex: '',
          sorter: false,
          key: 'actions',
          //width: "300px",
          render: element => <div style={{ width: 140 }}>
            {
              renderStatusIndicator(element, component)
            }
            {
              renderActions(element, component)
            }
          </div>,
        },
        {
          title: 'Titolo',
          dataIndex: '',
          sorter: true,
          key: 'titolo_bando',
          render: element => <div>
            {element.bando?.titolo_bando || ''}
          </div>
        },
        {
          title: 'Candidature aperte fino ad esaurimento',
          dataIndex: '',
          sorter: true,
          key: 'fino_ad_esaurimento',
          render: element => <div style={{ width: 100 }}>
            {element.fino_ad_esaurimento ? 'Si' : 'No'}
          </div>
        },
        {
          title: 'Data apertura',
          dataIndex: '',
          sorter: true,
          key: 'data_apertura',
          render: element => <div style={{ width: 140 }}>
            {(element.data_apertura) ? moment(element.data_apertura, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
          </div>,
        },
        {
          title: 'Data chiusura',
          dataIndex: '',
          sorter: true,
          key: 'data_chiusura',
          render: element => <div style={{ width: 140 }}>
            {(element.data_chiusura) ? moment(element.data_chiusura, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
          </div>,
        }
      ]
    };
  } else {
    return {
      with_server_pagination: true,
      columns: [
        {
          title: '',
          dataIndex: '',
          sorter: false,
          key: 'actions',
          //width: "300px",
          render: element => <div style={{ width: 140 }}>
            {
              renderStatusIndicator(element, component)
            }
            {
              renderActions(element, component)
            }
          </div>,
        },
        {
          title: 'Id. PDA',
          dataIndex: '',
          sorter: true,
          key: 'bando.id_univoco_pda',
          render: element => <div style={{ width: 60 }}>
            {<strong>{element.bando?.id_univoco_pda || ''}</strong>}
          </div>
        },
        {
          title: 'Titolo',
          dataIndex: '',
          sorter: true,
          key: 'titolo_bando',
          render: element => <div>
            {element.bando?.titolo_bando || ''}
          </div>
        },
        {
          title: 'Finanz.',
          dataIndex: '',
          sorter: true,
          key: 'bando.estremi_finanziamento',
          render: element => <div>
            {element.bando?.estremi_finanziamento || ''}
          </div>
        },
        {
          title: 'Asse',
          dataIndex: '',
          sorter: true,
          key: 'bando.asse',
          render: element => <div>
            {element.bando?.asse || ''}
          </div>
        },
        {
          title: 'Obiettivo Specifico',
          dataIndex: '',
          sorter: true,
          key: 'bando.obiettivo_specifico',
          render: element => <div>
            {element.bando?.obiettivo_specifico || ''}
          </div>
        },
        {
          title: 'Candidature aperte fino ad esaurimento',
          dataIndex: '',
          sorter: true,
          key: 'fino_ad_esaurimento',
          render: element => <div style={{ width: 100 }}>
            {element.fino_ad_esaurimento ? 'Si' : 'No'}
          </div>
        },
        {
          title: 'Data apertura',
          dataIndex: '',
          sorter: true,
          key: 'data_apertura',
          render: element => <div style={{ width: 140 }}>
            {(element.data_apertura) ? moment(element.data_apertura, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
          </div>,
        },
        {
          title: 'Data chiusura',
          dataIndex: '',
          sorter: true,
          key: 'data_chiusura',
          render: element => <div style={{ width: 140 }}>
            {(element.data_chiusura) ? moment(element.data_chiusura, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
          </div>,
        }
      ]
    }
  }
}

const filterFormModel = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 11,
            items: [
              {
                type: 'string',
                label: 'Titolo',
                name: 'titolo_bando',
              }
            ]
          },
          {
            span: 3,
            items: [
              { // TODO
                type: 'select',
                label: 'Stato',
                name: 'stato',
                options: [
                  {
                    value: "-1",
                    label: "Tutte"
                  },
                  {
                    value: "0",
                    label: "Attivo"
                  },
                  {
                    value: "1",
                    label: "Aperto"
                  },
                  {
                    value: "2",
                    label: "Chiuso"
                  },
                  {
                    value: "1,2",
                    label: "Aperto e chiuso"
                  }
                ],
              }
            ]
          },
          {
            span: 5,
            items: [
              {
                type: 'datepicker',
                label: 'Data apertura',
                name: 'data_apertura',
                format: 'DD/MM/YYYY',
                props: {
                  style: { width: '100%' },
                },
              }]
          },
          {
            span: 5,
            items: [{
              type: 'datepicker',
              label: 'Data chiusura',
              name: 'data_chiusura',
              format: 'DD/MM/YYYY',
              props: {
                style: { width: '100%' },
              },
            }]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  };
}

export default class TableProcedure extends Component {
  base_url = '/affidamento/formulari'
  default_order = '-created_at';

  state = {
    loading: false,
    params:{},
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search)
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);


    let filterParams = queryString.parse(query);
    if (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])) {
      if(!params.stato){
        filterParams = {...filterParams, stato:'1'};
        params = {...params, stato:'1'};
      }
    }
    this.setState({params: filterParams});

    // l'ente richiede anche le proprie domande associate ai bandi
    if (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])) {
      //params.expand = 'domande';
      params.with = 'domande';
    }

    params.fields = [
      'id',
      'bando.titolo_bando',
      'fino_ad_esaurimento',
      'data_apertura',
      'data_chiusura',
      'id_univoco_pda',
      'bando.estremi_finanziamento',
      'bando.asse',
      'bando.obiettivo_specifico',
      'stato',
      'formulario_personalizzato',
      'domande.id',
      'domande.stato',
    ].join(',');

    this.setState({ loading: true });
    ModelServices.list(params, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  createDomanda(id_procedura) {
    this.setState({ loading: true }, () => {

      ModelServices.createDomanda(
        id_procedura,
        (res) => {
          //console.log('RESR', res);
          message.success("Nuovo formulario compilato creato con successo, procedere con la compilazione dei dati!");
          this.setState({ loading: false })
          this.props.history.push("/affidamento/formulari/" + id_procedura + "/compilati/" + res.data.data.id);
        },
        () => this.setState({ loading: false }))

      // }
    })
  }

  deleteDomanda(id) {
    this.setState({ loading: true }, () => {
      DomandaServices.delete(
        id,
        res => {
          message.success("Formulario compilato eliminato con successo!");
          this.setState({ loading: false })
          this.refresh();
        },
        () => this.setState({ loading: false }))
    });
  }

  printPdf(id) {
    DomandaServices.printPdf(id, res => {
      const _blob = new Blob([res.data], { type: 'application/pdf' });
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      const date = today.getDate();
      const hour = ('0' + today.getHours()).slice(-2);
      const minu = ('0' + today.getMinutes()).slice(-2);
      let nome_file = `formulario_${date}${month}${year}_${hour}${minu}.pdf`;
      FileSaver.saveAs(_blob, nome_file);
    });
  }

  addNew() {
    this.setState({ loading: true }, () => {
      ModelServices.create(
        null,
        res => {
          message.success("Nuovo formulario creato con successo, procedere con la compilazione dei dati!");
          this.setState({ loading: false })
          this.props.history.push("/affidamento/formulari/" + res.data.data.id);
        },
        () => this.setState({ loading: false }))
    })
  }

  delete(id) {
    this.setState({ loading: true }, () => {
      ModelServices.delete(
        id,
        res => {
          message.success("Formulario eliminato con successo!");
          this.setState({ loading: false })
          this.refresh();
        },
        () => this.setState({ loading: false }))
    });
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.state.params}
          skipParse={true}
          history={this.props.history}
          date_fields={['data_apertura', 'data_chiusura']}
        />
        <Card
          className="shadow no-pad"
          bordered={false}>
          <Row type="flex" justify="start" className="m10h">
            {checkPermission.hasPermission(this.props.UserStore.user, ['addBando']) &&
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addNew()} loading={this.state.loading}>Nuovo bando della Formazione</Button>
              </div>
            }
          </Row>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}
