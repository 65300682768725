/*
const STATO_IN_CORSO = 0;
const STATO_TERMINATO_CON_SUCCESSO = 1;
const STATO_RICHIESTA_INTEGRAZIONE = 2;
const STATO_TERMINATO_CON_INSUCCESSO = 3; // la domanda è da rigettare
const STATO_SCADUTO = 9;

const STATO_IN_CORSO = 0; // ente modifica i documenti
const STATO_DA_FIRMARE = 1; // conferma le modifiche
const STATO_FIRMATA = 2; // carica file con firma
const STATO_INVIATA = 3; // invia tutto
 */
export default {
  stato: {
    in_corso: 0,
    success: 1,
    integration: 2,
    refused: 3,
    ended_integration: 4,
    expired: 9
  },
  level: {
    istruttore: 0,
    ispettore: 1
  },
  stato_end_audit: [1,3,9,4],
  getStatoLabel: (audit) => {
    let label = 'In corso';
    switch(audit.stato) {
      case 0: break;
      case 1: label = 'Terminato con successo'; break;
      case 2: label = 'Richiesta integrazione'; break;
      case 3: label = 'Rigettata'; break;
      case 4: label = 'Terminato con richiesta di integrazioni'; break;
      case 9: label = 'Scaduto'; break;
      default: break;
    }
    return label;
  },
  stato_integrazione: {
    in_corso: 0,
    da_firmare: 1,
    firmata: 2,
    inviata: 3,
    confermata: 4,
    rifiutata: 5,
    integrazione_confermata: 6
  },
  tipo_media: {
    sottodominio_integrazione_firmata: 'richiesta_integrazione_firmata'
  }
}