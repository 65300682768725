import React, { Component } from 'react';
import CommonForm from '@common/components/form';

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'integer',
                                label: 'Annualità',
                                name: 'annuity',
                                props: {
                                    min: 0,
                                    disabled: true,
                                },

                            }
                        ],
                    },



                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'integer',
                                label: 'Ore in aula',
                                name: 'classroomHours',
                                props: {
                                    min: 0,
                                },
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'integer',
                                label: 'Ore in laboratorio',
                                name: 'labHours',
                                props: {
                                    min: 0,
                                },
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'integer',
                                label: 'Ore di stage',
                                name: 'stageHours',
                                props: {
                                    min: 0,
                                },
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },



                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'integer',
                                label: 'Ore totali',
                                name: 'totalHours',
                                props: {
                                    min: 0,
                                    disabled: true,
                                },
                            }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'btn',
                                type: 'submit',
                                text: 'Salva',
                                btn_type: 'primary'
                            },
                        ],
                    },
                ],
            }

        ],
        onSubmit: 'submitted',
    }
}

class EditArticolazionePercorso extends Component {

    state = {
        loaded: false,
        loading: false,
        articolazionePercorso: {}
    };

    componentDidMount() {
        this.load(this.props)
    }



    load(props) {
        this.setState({
            loaded: true,
            articolazionePercorso: props.articolazionePercorso
        });
    }

    submit(data) {
        data.totalHours = data.stageHours + data.labHours + data.classroomHours;
        if (!this.state.loading) {
             this.setState({
                loading: true,
                articolazionePercorso : data});
            this.props.onUpdate(data);
        }
    }

    render() {
        return (
            <div>
                <CommonForm
                    loading={this.props.loading || false}
                    form_model={formLayout(this)}
                    values={this.state.articolazionePercorso}
                    readOnly={this.props.readOnly}
                    submitted={(data) => this.submit(data)}
                />
            </div>
        )
    }
}

export default EditArticolazionePercorso;