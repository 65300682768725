import React, { Component } from "react";
import CommonForm from '@common/components/form';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              name: 'impianti_fissi_title',
              type: 'title',
              element: 'h4',
              text: 'Impianti fissi e installati',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'checkbox',
              label: 'Illuminazione',
              name: 'impianti_illuminazione'
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'impianti_illuminazione_note',
              showIf: [
                {
                  type: 'field_validation',
                  field: 'impianti_illuminazione',
                  operator: '!=',
                  value: true
                }
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'checkbox',
              label: 'Forza Motrice',
              name: 'impianti_forza_motrice'
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'impianti_forza_motrice_note',
              showIf: [
                {
                  type: 'field_validation',
                  field: 'impianti_forza_motrice',
                  operator: '!=',
                  value: true
                }
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'checkbox',
              label: 'Distribuzione gas climatizzazione',
              name: 'impianti_gas_climatizzazione'
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'impianti_gas_climatizzazione_note',
              showIf: [
                {
                  type: 'field_validation',
                  field: 'impianti_gas_climatizzazione',
                  operator: '!=',
                  value: true
                }
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'checkbox',
              label: 'Climatizzazione',
              name: 'impianti_climatizzazione'
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'impianti_climatizzazione_note',
              showIf: [
                {
                  type: 'field_validation',
                  field: 'impianti_climatizzazione',
                  operator: '!=',
                  value: true
                }
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Altro',
              name: 'impianti_altro',
              type_string: 'text',
              validations: [],
            }
          ],
        },
      ],
    },
  ],
  onSubmit: 'onSubmit',
};


class AttrezzatureImpianti extends Component {
  state = {
      mounted: false,
      loading: false
    };

  async validate() {
    return new Promise((resolve) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getValues() {
    let obj = {
      ...this.formSelRef.props.form.getFieldsValue()
    };
    return obj;
  }

  render() {
    return <div style={{marginTop: 16}}>
        <CommonForm
            readOnly={this.props.readOnly}
            wrappedComponentRef={inst => (this.formSelRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={this.props.values}
            onSubmit={() => null}
            tipicorsi={[]}
        />
        </div>
  }
}


export default AttrezzatureImpianti;