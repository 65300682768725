import React, { Component } from 'react';

import { Table, Tooltip, Button, Popconfirm, Modal } from 'antd';

import EditUnitaDidattica from './EditUnitaDidattica'


const columns = (component) => {
    return [
        {
            title: 'Titolo UF',
            dataIndex: 'moduleTitle',
            key: 'moduleTitle',
            render: (text, record) => (<div style={{ width: 120 }}>{record.moduleTitle}</div>),
        }, {
            title: 'Denominazione UD',
            dataIndex: 'unitName',
            key: 'unitName',
            render: (text, record) => (<div style={{ width: 200 }}>{record.unitName}</div>)
        },
        {
            title: 'Durata (in ore)',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (<div style={{ width: 200 }}>{record.duration}</div>)
        },

        {
            title: '',
            dataIndex: 'azione',
            key: 'azione',
            render: (text, record) => (
                <div>
                    {!component.props.loading_data ?
                        <React.Fragment>
                            <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                                <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record, record.id, 'edit_unita')} />
                            </Tooltip>
                            <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questa unità didattica?"
                                onConfirm={() => component.deleteUnita(record.id)} okText="Si" cancelText="No">
                                <Button  disabled={component.props.readOnly} className="m2w" size="small" shape="circle" icon="delete" />
                            </Popconfirm>
                        </React.Fragment>
                        : null}
                </div>
            ),
        }
    ];
}

class UnitaDidatticheTable extends Component {

    state = {
        open_modal: false,
        element_opened: {},
        unitaDidatticaList: [],
        moduloDidattico : {},
        loading: true
    };



    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loading: false,
            unitaDidatticaList: props.unitList,
            moduloDidattico: props.modulo
        });
    }

    open(element, id, action) {
        this.setState({
            create_unita: false,
            element_opened: element,
            id_element_opened: id,
        }, () => this.setState({ open_modal: true }))
    }

    deleteUnita(id) {
        this.setState({
            unitaDidatticaList: this.state.unitaDidatticaList.filter(value => { return value.id !== id })
        });
    }

    updateUnita(data) {
        let list = this.state.unitaDidatticaList;
        var index = this.state.unitaDidatticaList.map(el => el.id).indexOf(this.state.id_element_opened);
        data.id = this.state.id_element_opened;
        list[index] = data;
        this.setState(
            {
                open_modal: false,
                unitaDidatticaList: list,
                element_opened: {},
                id_element_opened: null
            }
        );
    }

    createUnita(data) {
        let id = 0;
        if (this.state.unitaDidatticaList?.length > 0) {
            id = Math.max.apply(Math, this.state.unitaDidatticaList.map(function (o) { return o.id; })) + 1
        }
        let list = this.state.unitaDidatticaList ? this.state.unitaDidatticaList : [];
        data.id = id;
        list.push(data);
        this.setState(
            {
                open_modal: false,
                unitaDidatticaList: list,
                element_opened: {},
                id_element_opened: null
            });
    }


    submit(){
        let list = this.state.unitaDidatticaList
        let total = 0;
        list.forEach(element => {
            total = total + element.duration;
        });

        let moduloDidattico = this.state.moduloDidattico;
        moduloDidattico.moduleDuration = total;
        moduloDidattico.teachingUnits = list.length;
        moduloDidattico.unitList = list;

        this.setState({
            loading: true,
            moduloDidattico : moduloDidattico
        }, () => this.props.saveUnitaDidatticaList(moduloDidattico))

    }

    render() {
        return (
            <div>
                {!this.props.readOnly && <Button disabled={this.props.readOnly} type="primary" className="m5h" icon="plus" onClick={() => this.setState({ open_modal: true, create_unita: true })}>Aggiungi Unità Didattica</Button>}
                <Table
                    loading={this.state.loading}
                    columns={columns(this)}
                    dataSource={this.state.unitaDidatticaList}
                    rowKey="id" />
                    {//TODO : far funzionare i bottoni ok e annulla del modale
                    }
                <Modal
                    visible={this.state.open_modal}
                   // onOk={() => this.setState({ open_modal: false })}
                   okButtonProps={{style: {display: 'none'}}}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ open_modal: false })}
                >    <h4 className="m20h">Unità didattica</h4>
                    <EditUnitaDidattica
                        {...this.props}
                        close={() => this.setState({ open_modal: false })}
                        unitaDidattica={this.state.create_unita? {
                            moduleTitle: this.state.moduloDidattico.moduleTitle
                        } : this.state.element_opened}
                        idunitaDidattica={this.state.id_element_opened}
                        createUnita={this.state.create_unita}
                        onUpdateUnita={(data) => {
                            this.updateUnita(data);
                        }}
                        onCreateUnita={(data) => {
                            this.createUnita(data);
                        }}
                        readOnly={this.props.readOnly}
                    />
                </Modal>
                <Button disabled={this.props.readOnly} type="primary" className="m5h" onClick={() => this.submit()}>Salva</Button>
            </div>

        );
    }
}
export default UnitaDidatticheTable;