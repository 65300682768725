const initialState = {
  loggedIn: false,
  notifications: {
    count: 0,
    list: []
  },
  user: {
    role: {}
  },
  jwt: ''
};


export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_USER_STORE':
      return {
        ...state,
        ...action.obj
      }
    case 'USER_STORE_LOGIN':
      return {
        ...state,
        loggedIn: true,
        jwt: action.token
      }
    case 'USER_STORE_SET_USER':
      return {
        ...state,
        user: action.user
      }
    case 'LOGOUT':
      return {
        ...state,
        loggedIn: false,
        user: {},
        notifications: {
          count: 0,
          list: []
        },
        jwt: '',
      }
    case 'ADD_LAST_USER_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          list: action.notifications,
          count: state.notifications.count
        }
      }
    case 'SET_USER_NOTIFICATIONS':
      let _new = action.notifications.filter(n => n.read === 0).length;
      //let _new = 0;
      //action.notifications.map(n => {
      //  if (!state.notifications.list.find(nn => nn.id === n.id)) _new++
      //  return n;
      //})
      return {
        ...state,
        notifications: {
          list: (_new > 0) ? action.notifications : state.notifications.list,
          count: _new
        }
      }
    case 'SET_NOTIFICATION_READ':
      return {
        ...state,
        notifications: {
          list: state.notifications.list.map(n => {
            if (action.ids.indexOf(n.id) !== -1) {
              return {
                ...n,
                read: 1
              }
            } else {
              return n;
            }
          }),
          count: state.notifications.count - 1
        }
      }
    case 'CLEAR_USER_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          count: 0,
          list: state.notifications.list
        }
      }
    default:
      return state;
  }
}
