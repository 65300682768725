import React, { Component } from 'react';
import { Button, Card, message, Popconfirm, Row, Tooltip } from 'antd';
import moment from 'moment';

import checkPermission from '@common/utils/check/permissions';

import FilterForm from '@components/filterForm';
import TableComponent from '@components/table';

//import ModelServices from '../services/MockData';

import ModelServices from '../services/RichiesteQualifiche';
import repertorioStatic from '@common/utils/static/repertorio';

import _ from '@common/utils/formatters/_';

const getStato = (richiesta, component) => {

  if (!richiesta.stato_flusso_description) return '';
  let stato = 'ente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  //if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';
  return <div>
    {richiesta.stato_flusso_description['stato_' + stato]}
    {checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions']) ? getScadenza(richiesta, component) : null}
    {checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions']) ? getScadenzaAudit(richiesta, component) : null}
    {checkPermission.hasPermission(component.props.UserStore.user, ['gtrPermissions']) ? getScadenza(richiesta, component) : null}
  </div>

}

const renderActions = (element, component) => {
  return <div style={{ display: 'inline-block' }}>
    <Tooltip placement="top" title="Visualizza richiesta">
      <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/repertorio/richieste/" + element.id)} />
    </Tooltip>
    {checkPermission.hasPermission(component.props.UserStore.user, ['addRichiesteRepertorio']) &&
      <Tooltip placement="top" title={"Duplica"}>
        <Button className="m2w" size="small" shape="circle" icon="copy" onClick={() => component.duplica(element.id)} />
      </Tooltip>
    }

    {element.stato < repertorioStatic.stato.acquisita &&
      <Tooltip placement="top" title="Elimina richiesta">
        <Popconfirm disabled={component.state.loading} placement="leftTop" title="Sicuro di voler rimuovere la richiesta?" onConfirm={() => { component.deleteRequest(element.id) }} okText="Si" cancelText="No">
          <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="delete" />
        </Popconfirm>
      </Tooltip>
    }
  </div>
}

const getScadenza = (record, component) => {
  return record.blocked_timer? <div> <strong>Timer bloccato</strong></div> : (record.scadenza && record.stato_flusso !== repertorioStatic.stato_flusso.rejected && record.stato_flusso !== repertorioStatic.stato_flusso.confirm_accepted) ? <div>
    <Tooltip placement="topRight" title="Giorni alla scadenza">
      <strong>{record.scadenza}</strong>
    </Tooltip>
  </div> : ''
}

const getScadenzaAudit = (record, component) => {
  return record.blocked_timer? <div> <strong>Timer bloccato</strong></div> : (record.scadenza_audit && record.stato_flusso !== repertorioStatic.stato_flusso.rejected && record.stato_flusso !== repertorioStatic.stato_flusso.confirm_accepted) ? <div>
    <Tooltip placement="topRight" title="Giorni alla scadenza dell'Istruttoria Amministrativa">
      <strong>{ record.scadenza_audit}</strong>
    </Tooltip>
  </div> : ''
}

const renderStatusIndicator = (record, component) => {

  let user = 'ente';

  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['tecnicoGtrPermissions'])) user = 'tecnico_gtr';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['coordinatoreGtrPermissions'])) user = 'coordinatore_gtr';

  let defined_c = '';
  let description = record.stato_flusso_description['descrizione_' + user];
  let colors = ['green', 'red'];
  colors.forEach(c => {
    if (repertorioStatic.getPallino(user, c, parseInt(record.stato_flusso)) !== -1) defined_c = c;
  })

  return (defined_c !== '') ?
    <Tooltip placement="topRight" title={description}>
      <span className={"pallino " + defined_c}></span>
    </Tooltip>
    : <span className={"pallino invisible"}></span>;
}

const model = (component) => {

  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        render: element => <div style={{ width: 120 }}>
          {
            renderStatusIndicator(element, component)
          }
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Numero di protocollo',
        dataIndex: '',
        sorter: true,
        key: 'numero_protocollo',
        render: element => <div style={{ width: 60 }}>
          {element.numero_protocollo || ''}
        </div>
      },
      {
        title: 'Soggetto proponente',
        dataIndex: '',
        sorter: true,
        key: 'soggetto',
        render: element => <div style={{ width: 60 }}>
          {element.json.denominazione || ''}
        </div>
      },
      
      {
        title: 'Descrizione della richiesta',
        dataIndex: '',
        sorter: true,
        key: 'descrizione',
        render: element => <div>
          {element.json.descrizione_richiesta || ''}
        </div>
      },
      {
        title: 'Tipo di richiesta',
        dataIndex: '',
        sorter: true,
        key: 'tipo',
        render: element => <div style={{ width: 140 }}>
          {repertorioStatic.getTipoRichiestaLabel(element.tipo_richiesta)}
        </div>
      },
      {
        title: 'Stato',
        dataIndex: '',
        sorter: true,
        key: 'stato',
        render: element => <div style={{ width: 140 }}>
          {getStato(element, component)}
        </div>,
      },
      {
        title: 'Responsabile di procedimento',
        dataIndex: '',
        sorter: false,
        key: 'responsabile',
        render: element => <div style={{ width: 140 }}>
          {element.responsabile ? element.responsabile.anagrafica.cognome + ' ' + element.responsabile.anagrafica.nome : ''}
        </div>
      },
      {
        title: 'Data presentazione',
        dataIndex: '',
        sorter: true,
        key: 'data_presentazione',
        render: element => <div style={{ width: 140 }}>
          {element.data_presentazione ? moment(element.data_presentazione, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
        </div>,
      },
      {
        title: 'Data inizio predisposizione',
        dataIndex: '',
        sorter: true,
        key: 'created_at',
        render: element => <div style={{ width: 140 }}>
          {element.created_at ? moment.unix(element.created_at).format('DD/MM/YYYY') : ''}
        </div>,
      },
    ]
  };
}

const filterFormModel = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'string',
                label: 'Soggetto proponente',
                name: 'soggetto',
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Settore di riferimento',
                name: 'id_settore',
                options_props: 'settori',
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Tipo di richiesta',
                name: 'tipo',
                options: [
                  {
                    value: "-1",
                    label: "Tutti"
                  },
                  {
                    value: "1",
                    label: "Nuova Figura professionale"
                  },
                  {
                    value: "2",
                    label: "Modifica Figura professionale"
                  },
                  /*
                  {
                    value: "3",
                    label: "Integrazione Documentale"
                  },
                  */
                ],
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'string',
                label: 'Denominazione figura',
                name: 'denominazione_figura',
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'string',
                label: 'Descrizione della richiesta',
                name: 'descrizione',
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'datepicker',
                label: 'Data presentazione',
                format: 'DD/MM/YYYY',
                name: 'data_presentazione',
                props: {
                  style: { width: '100%' },
                },
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'string',
                label: 'Numero di protocollo',
                name: 'numero_protocollo',
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Stato',
                name: 'stato',
                options: [
                  {
                    value: "-1",
                    label: "Tutti"
                  },
                  {
                    value: "0",
                    label: "Predisposizione"
                  },
                  {
                    value: "1",
                    label: "Compilata"
                  },
                  {
                    value: "2",
                    label: "Da firmare"
                  },
                  {
                    value: "3",
                    label: "Pronta all'invio"
                  },
                  {
                    value: "4",
                    label: "Acquisita"
                  },
                  {
                    value: "5",
                    label: "Assegnata"
                  },
                  {
                    value: "6",
                    label: "In istruttoria"
                  },
                  {
                    value: "7",
                    label: "Sospesa in attesa d'integrazione"
                  },
                  {
                    value: "13",
                    label: "Integrazioni da firmare"
                  },
                  {
                    value: "14",
                    label: "Integrazioni firmate"
                  },
                  {
                    value: "8",
                    label: "Sospesa in attesa di controdeduzioni"
                  },
                  {
                    value: "9",
                    label: "Validazione"
                  },
                  {
                    value: "10",
                    label: "Valutazione"
                  },
                  {
                    value: "11",
                    label: "Rifiutata"
                  },
                  {
                    value: "12",
                    label: "Accolta"
                  },
                  {
                    value: "15",
                    label: "Completamento"
                  },
                  {
                    value: "16",
                    label: "Sospesa"
                  },
                ],
              }
            ]
          },

        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  };
}

class TabellaRichiesteRepertorio extends Component {
  base_url = '/repertorio/richieste/';
  default_order = 'id';

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search)
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);

    if (params.tipo === "-1") {
      delete params.tipo;
    }

    if (params.stato === "-1") {
      delete params.stato;
    }

    if (params.id_settore === "-1") {
      delete params.id_settore;
    }

    params.with = [
      'responsabile'
    ].join(',')

    this.setState({ loading: true });
    ModelServices.listRepertorio(params, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  addRequest(tipo) {
    this.setState({ loading: true }, () => {
      ModelServices.create({ tipo_richiesta: tipo },
        res => {
          message.success("Nuova richiesta creata con successo, procedere con la compilazione dei dati");
          this.setState({ loading: false })
          this.props.history.push("/repertorio/richieste/" + res.data.data.id);
        },
        () => this.setState({ loading: false }))
    })
  }

  deleteRequest(id) {
    this.setState({ loading: true });
    ModelServices.delete(id,
      res => {
        this.setState({
          open_drawer: false,
        }, () => {
          message.success('Richiesta di repertorio rimossa');
          this.fetch();
        })
      }, () => { this.setState({ loading: false }) });
  }


  duplica(id) {
    this.setState({ loading: true });
    ModelServices.duplica(id,
      res => {
        message.success("Nuova richiesta creata con successo, procedere con la compilazione dei dati");
        this.setState({ loading: false })
        this.props.history.push("/repertorio/richieste/" + res.data.data.id);

      }, () => { this.setState({ loading: false }) });
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          form_elements={{
            settori: [{
              key: null,
              label: "Tutti",
              value: null,
            }, ...this.props.GlobalStore.stubs.settori]
          }}
          base_url={this.base_url}
          search={this.state.params}
          history={this.props.history}
          date_fields={['data_apertura', 'data_chiusura', 'data_presentazione']}
        />
        <Card
          className="shadow no-pad"
          bordered={false}>

          <Row type="flex" justify="start" className="m10h">
            {checkPermission.hasPermission(this.props.UserStore.user, ['addRichiesteRepertorio']) &&
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addRequest(repertorioStatic.tipo_richiesta.nuova)} loading={this.state.loading}>Nuova Figura professionale</Button>
              </div>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['addRichiesteRepertorio']) &&
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addRequest(repertorioStatic.tipo_richiesta.modifica)} loading={this.state.loading}>Modifica Figura professionale</Button>
              </div>
            }
            {/*checkPermission.hasPermission(this.props.UserStore.user, ['addRichiesteRepertorio']) &&
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addRequest(repertorioStatic.tipo_richiesta.integrazione)} loading={this.state.loading}>Integrazione Documentale</Button>
              </div>
            */}
          </Row>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

export default TabellaRichiesteRepertorio;
