import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Popconfirm } from 'antd';

class SceltaIspezione extends Component {

  state = {
  }

  render() {
    return <div>
        <Popconfirm placement="top" title="Confermi la scelta?"
          onConfirm={() => {this.props.needIspezione()
          }}
          okText="Si" cancelText="No">
         <Button
          disabled={this.props.loading} loading={this.props.loading} className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>
          Richiedi verifica ispettiva
        </Button>
        </Popconfirm>
      </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SceltaIspezione);