import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import TabellaStatiEsteri from '../components/TabellaStatiEsteri'


@Wrapper({ route: 'StatiEsteri', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['manageStatiEsteri'])
class StatiEsteriRoute extends Component {

  state = {
    loading: false,
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          <h3 className="sectionTitle">Lista degli stati esteri</h3>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/stati-esteri">Lista degli stati esteri</Link></Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <TabellaStatiEsteri ref="listTable" {...this.props} />


      </div>
    );
  }
}

export default StatiEsteriRoute;
