import React, { Component } from 'react';
import { message } from 'antd';
import CommonForm from '@common/components/form';

import format from '@common/utils/formatters/_';

const formLayout = () => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'possesso_requisiti_title',
                type: 'title',
                element: 'h4',
                text: 'Dichiarare la continuità del possesso dei requisiti di accreditamento',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'checkIsDefinitivoOrRinnovo'
                  },
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Tipologie di accreditamento',
                name: 'tipo_accreditamento',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'checkIsDefinitivoOrRinnovo'
                  },
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Affidabilità economica e finanziaria',
                name: 'affidabilita_economica',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'checkIsDefinitivoOrRinnovo'
                  },
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Capacità gestionali e risorse professionali',
                name: 'gestione_risorse_professionali',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'checkIsDefinitivoOrRinnovo'
                  },
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Risorse infrastrutturali e disponibilità logistiche',
                name: 'risorse_infrastrutturali',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'checkIsDefinitivoOrRinnovo'
                  },
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Efficacia ed efficienza nelle attività precedentemente realizzate',
                name: 'efficienza_attivita_precedenti',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'checkIsDefinitivoOrRinnovo'
                  },
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Interrelazioni maturate con il territorio',
                name: 'interrelazioni_maturate_territorio',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'checkIsDefinitivoOrRinnovo'
                  },
                ],
              }
            ],
          }
        ],
      }
    ],
    onSubmit: 'onSubmit',
  }
};

class ContinuitaForm extends Component {
  state = {
    loading: false,
    files: {}
  };



  componentDidMount() {
  }





  refresh() {
    return this.props.refresh();
  }

  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  getValues() {
    return format.convertBoolean([
      'tipo_accreditamento',
      'affidabilita_economica',
      'gestione_risorse_professionali',
      'risorse_infrastrutturali',
      'efficienza_attivita_precedenti',
      'interrelazioni_maturate_territorio'
    ], this.props.values )
  }

  initDataForm() {

  }

  getFormData() {
    return this.formRef.props.form.getFieldsValue();
  }

  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          onSubmit={() => null}
          form_model={formLayout()}
          values={this.getValues()}
          readOnly={this.props.readOnly}
          checkIsDefinitivoOrRinnovo={()=>this.props.isDefinitivoOrRinnovo}
        />
      </div>
    );
  }
}

export default ContinuitaForm;
