import React, { Component } from 'react';

import { Button, Drawer, Table, Tooltip, Popconfirm } from 'antd';

import format from '@common/utils/formatters/_';

import Create from '@accreditamento/forms/completeRequest/risorse-capacita/organigramma/Create';
import Update from '@accreditamento/forms/completeRequest/risorse-capacita/organigramma/Update';
import TitoliCompetenze from '@accreditamento/forms/completeRequest/risorse-capacita/TitoliCompetenze';
import Documenti from '@accreditamento/forms/completeRequest/risorse-capacita/Documenti';
import allegatiStatic from '@common/utils/static/allegati'

import CapacitaGestionaliServices from '@accreditamento/services/CapacitaGestionali';


const columns = (component) => {
  return [
      {
        title: 'Funzione',
        dataIndex: 'funzione',
        key: 'funzione',
        render: (text, record) => (<div style={{width: 120}}>{ format.replaceStub( record.id_tipo_funzione, 'tipo_funzioni', component.props.GlobalStore )}</div>),
      }, {
        title: 'Dati anagrafici',
        dataIndex: 'nome',
        key: 'nome',
        render: (text, record) => (<div style={{width: 150}}>{record.anagrafica.nome + " " + record.anagrafica.cognome}<br />{record.anagrafica.cf}</div>)
      }, {
        title: 'Sedi',
        dataIndex: 'sedi',
        key: 'sedi',
        render: (text, record) => (<div style={{width: 150}}>{
            record.sedi.map(sede=>{
              return <p key={"organigramma_sede_"+record.id+"_"+sede.id}>{sede.indirizzo.via + " " + sede.indirizzo.ncivico + " " + sede.indirizzo.cap}</p>
            })
        }</div>)
      },
      {
        title: 'Rapporto Lavoro',
        dataIndex: 'rapporto_lavoro',
        key: 'rapporto_lavoro',
        render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).id_tipo_rapporto_lavoro, 'tipo_rapporto_lavoro', component.props.GlobalStore ) }</div>)
      },
      {
        title: 'Durata',
        dataIndex: 'rapporto_lavoro_durata',
        key: 'rapporto_lavoro_durata',
        render: (text, record) => (<div style={{width: 50}}>{ component.getRapportoLavoro( record ).durata_rapporto_lavoro }</div>)
      },
      {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
          <div style={{width: 120}}>
            <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Aggiorna"}>
              <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.update(record.id)} />
            </Tooltip>
            <Tooltip placement="top" title="Titoli e competenze">
              <Button className="m2w" size="small" shape="circle" icon="book" onClick={() => component.titoliCompetenze(record.id)} />
            </Tooltip>
            <Tooltip placement="top" title="Documenti">
              <Button
              type={component.validRecordMedia(record) ? 'default' : 'danger'}
              className="m2w" size="small" shape="circle" icon="folder" onClick={() => component.documenti(record.id)} />
            </Tooltip>
            <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questa scheda?"
            onConfirm={() => component.delete(record.id)} okText="Si" cancelText="No">
              <Button disabled={component.props.readOnly} loading={component.state.deleting.indexOf(record.id) !== -1} className="m2w" size="small" shape="circle" icon="delete" />
            </Popconfirm>

          </div>
        ),
      }
    ];
  }

class Organigramma extends Component {

  state = {
    loading: false,
    open_drawer: false,
    open_titoli_competenze: false,
    open_edit_drawer: false,
    open_documenti: false,
    updating_model: {},
    deleting: []
  };

  componentDidMount() {
    this.setState({

    })
  }

  //componentWillReceiveProps(newProps) {
  //  console.log('newProps',newProps.accreditamento)
  //}

  update(id) {
    this.setState({
      open_edit_drawer: true,
      updating_model: this.props.funzioni.find(el => el.id === id)
    })
  }

  titoliCompetenze(id) {
    this.setState({
      open_titoli_competenze: true,
      updating_model: this.props.funzioni.find(el => el.id === id)
    })
  }

  documenti(id) {
    this.setState({
      open_documenti: true,
      updating_model: this.props.funzioni.find(el => el.id === id)
    })
  }

  delete(id) {
    if(this.state.deleting.indexOf(id) === -1) {
      this.setState({deleting: [...this.state.deleting, id]}, ()=>{
        CapacitaGestionaliServices.deleteOrganigramma( id, this.props.accreditamento.id, ()=>{

          this.setState({deleting: this.state.deleting.filter(el => el !== id)});
          if(this.props.onUpdate) this.props.onUpdate();

        }, () => this.setState({deleting: this.state.deleting.filter(el => el !== id)}) )

      })

    }
  }

  getRapportoLavoro(record) {
    try {
      let r = record.anagrafica.rapportoLavoro.find(el => el.id_ente === this.props.accreditamento.id_ente)
      return (r) ? r : {}
    } catch(e) {
      return {}
    }
  }

  /**
   * File obbligatori per le anagrafiche
   * @type {Array}
   */
  //required_files = ['Autorizzazione al trattamento dei dati personali secondo i termini di legge', 'Dichiarazione della veridicità dei dati riportati, in base al D.P.R. 445 del 28/12/2000'];
  //ora li prendiamo dinamicamente dal db
  getRequiredMedia() {
    let capacita_gestionali = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'rapporto_lavoro', 'capacita_gestionali');
    let documenti_capacita_gestionali = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'rapporto_lavoro', 'documenti_capacita_gestionali');
    let required = [...capacita_gestionali, ...documenti_capacita_gestionali]

    return this.props.GlobalStore.stubs.tipo_media.filter(el => required.indexOf(el.tipo_media) !== -1)
  }

  validRecordMedia(record) {
    try {
      let valid = true;
      this.getRequiredMedia().forEach( r => {
        if(!this.getRapportoLavoro(record).media.find( el => el.tipo_media === r.id && el.deleted !== 1 )) {
          valid = false;
        }
          
      })
      return valid;
    } catch(e) {
      return false
    }
  }

  refresh() {
    return this.props.refresh();
  }

  render() {
    return (
      <div>
        <h4>Organigramma aziendale</h4>
        <Button
          type="primary"
          icon="plus"
          disabled={this.props.readOnly}
          onClick={()=>this.setState({open_drawer: true})}>
          Inserisci Personale
        </Button>
        <Drawer
          title="ANAGRAFICA FUNZIONE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false })}
          visible={this.state.open_drawer}
          width={1024}
          destroyOnClose={true}
        >
          <Create compilazioneAccreditamento={true} funzioni={this.props.funzioni} {...this.props} onUpdate={()=>{ this.setState({open_drawer: false}); this.props.onUpdate(); }} />
        </Drawer>
        <Drawer
          title="ANAGRAFICA FUNZIONE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_edit_drawer: false })}
          visible={this.state.open_edit_drawer}
          width={1024}
          destroyOnClose={true}
        >
          <Update funzioni={this.props.funzioni} readOnly={this.props.readOnly} {...this.props} model={this.state.updating_model} onUpdate={()=>{ this.setState({open_edit_drawer: false}); this.props.onUpdate(); }} />
        </Drawer>
        <Drawer
          title="TITOLI E COMPETENZE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_titoli_competenze: false })}
          visible={this.state.open_titoli_competenze}
          width={1024}
          destroyOnClose={true}
        >
          <TitoliCompetenze readOnly={this.props.readOnly} {...this.props} anagrafica={this.state.updating_model.anagrafica} onUpdate={()=>{ this.setState({open_titoli_competenze: false}); }} />
        </Drawer>
        <Drawer
          title="DOCUMENTI"
          placement="right"
          closable={true}
          onClose={() => {
            this.props.onUpdate();
            this.setState({ open_documenti: false })
          }}
          visible={this.state.open_documenti}
          width={1024}
          destroyOnClose={true}
        >
          <Documenti
            {...this.props}
            accreditation_id={this.props.accreditamento.id}
            readOnly={this.props.readOnly}
            anagrafica={this.state.updating_model.anagrafica}
            nUpdate={()=>{ this.setState({open_documenti: false}); }}
          />
        </Drawer>
        <Table
          pagination={false}
          loading={this.props.loading_data || this.props.loading_organigramma} columns={columns(this)} dataSource={this.props.funzioni} rowKey="id"/>
      </div>
    );
  }
}
export default Organigramma;