import React, {Component} from 'react';
import { message} from 'antd';


import CommonForm from '@common/components/form';

import format from '@common/utils/formatters/_';

import CapacitaGestionaliServices from '@accreditamento/services/CapacitaGestionali';


const formLayout =  (component) => {
  return {
  layout: 'vertical',
  multistep: false,
  rows: [/*
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Macrotipologia attività formativa',
              name: 'tipo_formazione',
              options_props: 'tipo_accreditamento',
              mode: 'multiple',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 16,
          items: [
            {
              type: 'select',
              label: 'Tipo rapporto di lavoro',
              type_string: 'text', // field type
              name: 'id_tipo_rapporto_lavoro',
              options_props: 'tipo_rapporto_lavoro',
              onChange: 'setRapportoLavoro',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Altro:',
              name: 'altro_rapporto_lavoro',
              props: {
                size: 'large'
              },
              showIf: [
                {
                  type: 'prop_func',
                  func: 'needToAddAltroRapporto'
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 8,
          items: component.state.tipo_rapporto_lavoro == 2 || component.state.tipo_rapporto_lavoro == null ? [
            {
              type: 'integerwithzero',
              label: 'Durata (anni)',
              type_string: 'number', // field type
              name: 'durata_rapporto_lavoro',
              props: {
                size: 'large'
              },
            }
          ] : [ {
            type: 'integerwithzero',
            label: 'Durata (anni)',
            type_string: 'number', // field type
            name: 'durata_rapporto_lavoro',
            min: 1,
            props: {
              size: 'large'
            },
            validations: [
              {
                type: 'required',
                error_message: 'Campo obbligatorio'
              },
              {
                validator: 'minUno'
              }
            ],
          } ]
        }
      ]
    },
    
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Titolo di studio',
              type_string: 'text', // field type
              name: 'titolo_studio',
              options_props: 'tipo_titolo_studio',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    */
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Sedi',
              name: 'sedi',
              options_props: 'sedi',
              mode: 'multiple',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },/*
    {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Autorizzazione al trattamento dei dati personali secondo i termini di legge',
                name: 'autorizz_tratt_dati',
                option_type: 'button', // normal|button
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ],
          }
        ],
    },*/
    {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Deve possedere i requisiti minimi professionali previsti dagli standard professionali e formativi e/o dagli specifici provvedimenti dei competenti uffici della Giunta Regionale. Inoltre deve aver proceduto alla pubblicazione del Cv sul sistema regionale https://cliclavoro.lavorocampania.it/nella sezione "Il mio CV"',
                name: 'requisiti_minimi',
                option_type: 'button', // normal|button
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    pattern: "1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              }
            ],
          }
        ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'submit',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            }
          ]
        }
      ]
    },
  ],
  onSubmit: 'submitted',
}};

class UpdateModel extends Component {

  state = {
    loading: false,
    tipo_rapporto_lavoro: null
  }



  getFormLayout() {

  }


  componentDidMount () {

    this.setState({
      altro_rapporto_selected: this.getAnaRapportoLavoro(this.props.model.anagrafica).id_tipo_rapporto_lavoro == 10 ? true : false
    });
  }




  updateQueryStr() {

  }




  async confirm(data) {



    if(!this.state.loading) {
      await this.setState({loading: true});

      CapacitaGestionaliServices.updateDocente( data, this.props.model.id, this.props.accreditamento.id, ()=>{
        this.setState({loading: false});
        if(this.props.onUpdate) this.props.onUpdate();
      }, () => this.setState({loading: false}) )

    }
  }


  getAnaRapportoLavoro(ana) {
    try{
      return ana.rapportoLavoro.find(el => el.id_ente === this.props.accreditamento.id_ente)
    } catch(e) {
      return {}
    }
  }


  selectedRapportoLavoro(value) {
    this.setState({tipo_rapporto_lavoro: value});

    if(value == 10) {
      this.setState({altro_rapporto_selected: true});
    } else {
      this.setState({altro_rapporto_selected: false});
      this.formRef.props.form.setFields(
        {
          altro_rapporto_selected: {
            value: ''
          }
        });
    }
  }

  getSedi() {
    return this.props.accreditamento.sedi
  }

  getValues() {
    let vals = Object.assign({}, this.props.model);

    try {
      vals.tipo_formazione = JSON.parse(vals.tipo_formazione)
      vals.sedi = vals.sedi.map(el => ""+el.id)
    } catch(e) {
      vals.tipo_formazione = [];
      vals.sedi = [];
    }
    vals = {
      ...vals,
      ...this.getAnaRapportoLavoro(this.props.model.anagrafica)
    }


    return format.convertBoolean(['requisiti_minimi'], vals);
  }

  

  render () {
    return <div>
      <h4>Dettagli funzione</h4>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          readOnly={this.props.readOnly}
          submitted={(dati) => this.confirm(dati)}
          tipo_accreditamento={this.props.GlobalStore.stubs.tipo_accreditamento.filter(el => this.props.accreditamento.accreditamentotipologie.some(e => e.id_tipologia === el.value))}
          
          tipo_titolo_studio={this.props.GlobalStore.stubs.tipo_titolo_studio}

          needToAddAltroRapporto={()=>this.state.altro_rapporto_selected}

          minUno={(rule, value, cb) => {
            if (parseInt(value) < 1) cb("Minimo 1");
            else cb()
          }}

          sedi={this.props.sedi.map(el => {
            return {
              key: 'sede_' + el.enteSede.id,
              label: format.getIndirizzo( el.enteSede.indirizzo, this.props.GlobalStore),//el.enteSede.indirizzo.via + " " + el.enteSede.indirizzo.ncivico + " (" + el.enteSede.indirizzo.locComune.comune + ")",
              value: el.enteSede.id
            }
          })}

        />
    </div>
  }

}
export default UpdateModel;