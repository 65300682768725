import BaseRequest from '@common/services/_baseRequest';


export default {

  list: (vars = {}, id_corso = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      { id_progetto: id_corso, ...vars },
      {},
      null,
      'get',
      'affidamento/lezione',
      cb,
      ecb || null,
      null
    );
  },

  detail: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/lezione/' + id,
      cb,
      ecb || null,
      null
    );
  },

  create: (data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/lezione',
      cb,
      ecb || null,
      null
    );
  },

  update: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "put",
      'affidamento/lezione/' + id,
      cb,
      ecb || null,
      null
    );
  },

  editDescrizioneLezione: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "post",
      'affidamento/lezione/' + id +'/edit-descrizione',
      cb,
      ecb || null,
      null
    );
  },

  editDescrizioneStage: (params = {}, id_cil = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "post",
      'affidamento/lezione/' + id_cil +'/edit-descrizione-stage',
      cb,
      ecb || null,
      null
    );
  },
      


  delete: (id_lezione, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'affidamento/lezione/' + id_lezione,
      cb,
      ecb || null,
      null
    );
  },

  setPresenza(param, id_lezione, cb, ecb = null) {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      param,
      null,
      'post',
      'affidamento/lezione/' + id_lezione + '/set-presenza',
      cb,
      ecb || null,
      null
    );
  },

  setPresenzaDocente(param, id_lezione, cb, ecb = null) {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      param,
      null,
      'post',
      'affidamento/lezione/' + id_lezione + '/set-presenza-docente',
      cb,
      ecb || null,
      null
    );
  },

}
