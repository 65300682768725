import React, { Component } from 'react';
import { Button, Tooltip, Drawer, Popconfirm, Row, message } from 'antd';

import TableComponent from '@components/table';
import affidamentoStatic from '@common/utils/static/affidamento';
import eventBroker from '@common/utils/services/EventBroker';

import ModelServices from '@affidamento/services/Corsi';

import checkPermission from '@common/utils/check/permissions';

import moment from 'moment';
import DocentiAccreditati from './DocentiAccreditati';

import MediaServices from '@common/services/media/Media';
import FileSaver from 'file-saver';

import _ from '@common/utils/formatters/_'

const renderActions = (element, component) => {

  let cv = component.hasCurriculum(element);

  return <div style={{ display: 'inline-block' }}>
    {checkPermission.hasPermission(component.props.UserStore.user, ['viewDocenti']) &&  //TODO se ha accesso alla pagina soggetti correlati
      <Tooltip placement="top" title="Visualizza docente">
        <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/affidamento/docenti/" + element.id)} />
      </Tooltip>
    }

    <Tooltip placement="top" title="Visualizza curriculum">
      <Button className="m2w" size="small" shape="circle" icon="audit" onClick={() => { component.downloadFile(cv) }} disabled={!cv} />
    </Tooltip>

    {checkPermission.hasPermission(component.props.UserStore.user, ['editDocentiPerProgetto']) && element.stato === affidamentoStatic.stato_docente.attivo && component.props.isEditableState &&
      <Tooltip placement="top" title="Sostituisci docente">
        <Button className="m2w" size="small" shape="circle" icon="retweet" disabled={!component.canAssign()} onClick={() => component.replaceTeacher(element)} />
      </Tooltip>
    }

  </div>
}

const model = (component) => {

  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        render: element => <div style={{ width: 60 }}>
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Cognome',
        dataIndex: '',
        sorter: true,
        key: 'anagrafica.cognome',
        render: element => <div>
          {element.anagrafica?.cognome || ''}
        </div>
      },
      {
        title: 'Nome',
        dataIndex: '',
        sorter: true,
        key: 'anagrafica.nome',
        render: element => <div>
          {element.anagrafica?.nome || ''}
        </div>
      },
      {
        title: 'Data Inizio',
        dataIndex: '',
        sorter: true,
        key: 'data',
        render: element => <div>
          {element.data ? moment(element.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        title: 'Stato',
        dataIndex: '',
        sorter: true,
        key: 'stato',
        render: element => <div>
          {affidamentoStatic.getStatoDocenteLabel(element.stato || 0) }
        </div>
      },
    ]
  };
}

class TabellaDocenti extends Component {

  default_order = 'id'

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
    open_drawer: false,
  }

  componentDidMount() {
    this.fetch();
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(this.state.params, this.default_order);

    params.expand = 'media'

    this.setState({ loading: true });
    ModelServices.listDocenti(params, this.props.corso.id, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  replaceTeacher(teacher) {
    this.setState({ open_drawer: true, replace: teacher });
  }

  addTeacher() {
    this.setState({ open_drawer: true, replace: null });
  }

  assign(teacher) {
    let {replace} = this.state;
    let req = {
      /*id_docente_personale_ente: teacher.id*/
      id_docente_anagrafica: teacher.id,
    }
    if (replace) {
      req.replace_with_id_docente = replace.id;
    }
    this.setState({ loading: true });
    ModelServices.addDocente(this.props.corso.id, req, res => {
      message.success(replace ? 'Docente sostituito' : 'Docente assegnato al corso');
      this.fetch();
      eventBroker.trigger('teacherReplaced');
      this.setState({ open_drawer: false, loading: false });
    }, () => {
      this.setState({ open_drawer: false, loading: false });
    });
  }

  updateQueryStr(obj) {
    if (obj.order && obj.sort) {
      obj = {
        sort: (obj.order === 'descend' ? '-' : '') + obj.sort
      }
    }
    this.setState({
      params: {
        ...this.state.params,
        ...obj
      }
    }, () => {
      this.fetch();
    });
  }

  downloadFile(file) {
    MediaServices.download(file.id, async (res) => {
      const blob = new Blob([res.data], { type: file.mime_type });
      FileSaver.saveAs(blob, file.nome);
    }, () => null)
  }

  hasCurriculum(record) {
    try {
      return record.media.find(m => m.tipo.tipo_media.toUpperCase() === 'CURRICULUM')
    } catch(e) {
      return null
    }
  }

  canAssign(){
    if(
      (this.props.corso.stato < affidamentoStatic.stato_progetto.avvio_progetto && this.props.corso.stato_flusso < affidamentoStatic.stato_flusso_progetto.corso_in_preparazione) ||
      (this.props.corso.stato === affidamentoStatic.stato_progetto.completato && this.props.corso.stato_flusso === affidamentoStatic.stato_flusso_progetto.progetto_completato) ||
      (this.props.corso.stato === affidamentoStatic.stato_progetto.revoca_corso && this.props.corso.stato_flusso === affidamentoStatic.stato_flusso_progetto.revoca_corso)||
      (this.props.corso.stato === affidamentoStatic.stato_progetto.revoca_corso && this.props.corso.stato_flusso === affidamentoStatic.stato_flusso_progetto.conferma_revoca)
      )
      return false;
      
      
      return true;
  }

  renderActions() {
    return [{
      title: '',
      dataIndex: '',
      sorter: false,
      render: element => {
        let cv = this.hasCurriculum(element);
        return <div style={{ width: 120 }}>
          <Tooltip placement="top" title="Visualizza curriculum">
            <Button className="m2w" size="small" shape="circle" icon="audit" onClick={() => { this.downloadFile(cv) }} disabled={!cv} />
          </Tooltip>
        </div>
      },
    }];
  }

  extraActions() {
    return [{
    title: '',
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ width: 120 }}>
      <Popconfirm placement="top" title="Confermi di voler nominare il docente?"
        onConfirm={() => this.assign(element)}
        okText="Si" cancelText="No">
        <Button
          disabled={this.state.loading || !this.canAssign()}
          className="m5h" type="primary" icon="save" size="small">Nomina docente</Button>
      </Popconfirm>
    </div>,
    }];
  }

  render() {
    return (
      <div>
        <Row type="flex" justify="start" className="m10h">
          {checkPermission.hasPermission(this.props.UserStore.user, ['editDocentiPerProgetto']) && this.props.isEditableState &&
            <div className="m5h m5w">
              <Button className="m5h bg-green" icon="plus" onClick={() => this.addTeacher()} loading={this.state.loading}>Aggiungi docente</Button>
            </div>
          }
        </Row>

        <TableComponent
          data={this.state.data}
          model={model(this)}
          table_props={{ rowKey: 'id' }}
          size="medium"
          ref="table_component"
          loading={this.state.loading}
          with_server_pagination={true}
          updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
          updateSort={(sort, order) => this.updateQueryStr({ sort: sort, order: order })}
        />

        <Drawer
          title={this.state.replace ? "Sostituisci docente" : "Aggiungi docente"}
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false })}
          visible={this.state.open_drawer}
          width={1024}
          destroyOnClose={true}
        >

          <p>
            {this.state.replace ? <span>Seleziona il docente che sostituirà {this.state.replace.cognome}</span> : <span>Seleziona un docente</span>}
          </p>
          <DocentiAccreditati
            {...this.props}
            id_ente={this.props.corso.id_ente}
            base_url={'/affidamento/registro/' + this.props.match.params.id}
            extraActions={this.extraActions()}
            renderActions={this.renderActions()}
            setDefaultSearchParams={params => {
              params.fields = 'id,anagrafica.nome,anagrafica.cognome,anagrafica.cf,media';
              params.expand = 'media';
              return params;
            }}
          />

        </Drawer>
      </div>
    );
  }
}

export default TabellaDocenti;
