import React, { Component } from 'react';
import { Card, Row, Col } from 'antd';
import {
  Link
} from 'react-router-dom'
import Wrapper from '@common/decorators/Wrapper';

import AskResetForm from '../forms/AskReset';

import AuthServices from '../services/Auth';



@Wrapper({ route: 'AskReset', hasSidebar: false, hasHeader: true, hasFooter: true })
class AskResetFormRoute extends Component {

  state = {
    loading: false,
    asked: false,
  }

  componentDidMount() {
    //console.log('prop ask reset', this.props)


  }



  tryAskReset(data) {
    this.setState({ loading: true });
    AuthServices.askreset({ email: data.email, base_url: (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_BASE_URL : process.env.REACT_APP_BASE_URL) }, () => {
      // login andato a buon fine, posso impostare utente
      //console.log('responso ask reset', response_ask_reset.data);

      this.setState({ loading: false, asked: true });
      //this.setMainMenu();
    }, () => {
      //console.log(e)
      this.setState({ loading: false });
    })
  }

  render() {

    return (
      <Row type="flex" justify="center">
        <Col lg={8} md={24} xs={24} sm={24} style={{ margin: '40px auto', textAlign: 'center' }}>
          {/* <LogoForm /> */}
          <h2>RECUPERA PASSWORD</h2>
          <p style={{ textAlign: 'center' }}>
            {this.state.asked
              ?
              ''
              :
              'Inserisci il tuo indirizzo email, se presente nel sistema ti verrà inviato un link per impostare la nuova password'
            }</p>
          <Card
            className="shadow"
            bordered={false}
          >

            {(!this.state.asked) ? <AskResetForm loading={this.state.loading} onSubmit={(data) => this.tryAskReset(data)} /> : <p>Richiesta inviata, controlla la tua mail</p>}
            <Link to="/login-user-pwd" className="login-form-forgot" style={{ float: 'right' }}>Annulla</Link>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AskResetFormRoute;

//export default connect(({UserStore, GlobalStore}) => ({UserStore, GlobalStore}))(FormConnected);
//export default Form.create()(AskResetForm)