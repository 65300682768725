import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'antd';

import ModelServices from '@accreditamento/services/Ispezione';

import moment from 'moment';
import FileSaver from 'file-saver';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';

import nominaStatic from '@common/utils/static/nomina';


const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
        {
          cols: [
            {
              items: [
                {
                  type: 'simple_upload',
                  name: 'dragger',
                  accept: '.p7m,.pdf',
                  action: '/', // url upload
                  listType: 'text', // text,picture,picture-card
                  multiple: false,
                  fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
                  customRequest: 'customUploadRequest', // sovrascrive upload
                  onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
                  text: "Carica file p7m o pdf firmato",
                  props: {
                    disabled: component.state.loading,
                    showUploadList: false
                  }
                },
              ],
            },
          ],
        },
    ],
    onSubmit: 'submitted',
  }
}

class IspezioneControdeduzioni extends Component {

  state = {
    loaded: false,
    loading: false
  }

  isEnte() {
    return this.props.accreditamento.id_user === this.props.UserStore.user.id ? true : false;
  }

  saveBlob( blob ) {
    const _blob = new Blob([blob], {type: 'application/pdf'});
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `controdeduzioni_${date}${month}${year}_${hour}${minu}.pdf`;
    FileSaver.saveAs(_blob, nome_file);

  }

  upload(file) {

    if(!this.state.loading) {
      this.setState({loading: true}, () => {
        ModelServices.uploadControdeduzioni(this.props.accreditamento.id, this.props.ispezione.id, {
          filename: 'file'
        }, { file: file, chiave: 'file' }, () => {

          this.props.refreshAccreditamento();

        }, () => this.setState({ open_modal_com: false, loading: false }))
      })
    }
  }


  render() {
    return <div style={{marginBottom: 16}}>
      <strong>Controdeduzioni dell&apos;ente</strong><br />
      {moment.unix(this.props.controdeduzioni.created_at).format('DD/MM/YYYY')}<br />
      {this.props.controdeduzioni.note}<br />
      {
        this.props.controdeduzioni.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_controdeduzioni_firmata).map(l => {
          return <div key={l.id}><p style={{display: 'inline-block'}}>Controdeduzioni firmate: </p><DocSpan key={l.id} file={l} /></div>
        })
      }
      {
        this.isEnte() &&
        (
          this.props.controdeduzioni.stato === nominaStatic.stato_controdeduzioni.in_corso ||
          this.props.controdeduzioni.stato === nominaStatic.stato_controdeduzioni.da_firmare
        )
        ?
        <React.Fragment>
          <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            className="m5h" type="primary" icon="file" onClick={()=> {
              if(!this.state.loading) {
                this.setState({loading: true}, () => {

                  ModelServices.getControdeduzioni(
                    this.props.accreditamento.id,
                    this.props.ispezione.id,
                    (res)=> {
                      this.setState({loading: false});
                      this.saveBlob(res.data);
                    },
                    () => this.setState({loading: false})
                  )

                })

              }
            }}>Scarica file</Button>
          <CommonForm
              wrappedComponentRef={inst => (this.formRef = inst)}
              loading={this.state.loading || false}
              form_model={formLayout(this)}
              values={{
              }}
              fileListProp={() => []}
              customUploadRequest={obj => {
                this.upload(obj.file);
              }}
           />
        </React.Fragment>
        : null
      }
    </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(IspezioneControdeduzioni);
/**
 * Nasce quando terminano le compilazioni dei verbali, max giorni da inizio specificato nel file accreditamento-properties.php, counter_arguement_days (default 30 giorni)
 */