import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import ModelServices from '@accreditamento/services/Accreditamento';

const replaceText = (str) => {
  if (str.match(/Domanda Consegnata/)) return "Esito invio";
  if (str.match(/Pratica acquisita Dirigente/)) return "Nuova pratica";
  if (str.match(/Pratica acquisita/)) return "Esito acquisizione";
  if (str.match(/Richiesta integrazioni/)) return "Richiesta integrazioni";
  if (str.match(/Pratica assegnata/)) return "Assegnazione";
  if (str.match(/Feedback ispettore/)) return "Risposta Addetto al controllo";
  return str;
}

const cols = (component) => {
  return [
    {
      title: "Data Invio",
      dataIndex: 'created_at',
      key: 'created_at',
      render: element => <span>
        {
          moment.unix(element).format('DD/MM/YYYY')
        }
      </span>
    },
    {
      title: "Mittente",
      dataIndex: 'from',
      sorter: false,

    },
    {
      title: "Destinatario",
      dataIndex: 'to',
      sorter: false,

    }
    ,
    {
      title: "Oggetto",
      dataIndex: 'subject',
      sorter: false,
      render: element => <span>
        {
          replaceText(element)
        }
      </span>
    }
  ];
}

const expandedRowRender = (record, index, indent, expanded, component) => {
  return <Table
    columns={child_columns(component)}
    dataSource={[JSON.parse(record.message)]}
    rowKey={(r) => "record_" + r.conf.use_layout}
    pagination={false} />;
}

const child_columns = (component) => {
  return [{
    title: 'Testo',
    dataIndex: 'content',
    render: (text, record) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
  }]
}

class ComunicazioniPecList extends Component {
  state = {
    data: [],
    loading: false,
  }
  model = [
    {
      title: "Data Invio",
      dataIndex: 'created_at',
      key: 'created_at',
      render: element => <span>
        {
          moment.unix(element).format('DD/MM/YYYY')
        }
      </span>
    },
    {
      title: "Mittente",
      dataIndex: 'from',
      sorter: false,

    },
    {
      title: "Destinatario",
      dataIndex: 'to',
      sorter: false,
    },
    {
      title: "Oggetto",
      dataIndex: 'subject',
      sorter: false,
      render: element => <span>
        {
          replaceText(element)
        }
      </span>
    }
  ];

  componentDidMount() {
    this.setState({ loading: true }, () =>
      ModelServices.getPecLog(this.props.id_accreditamento, (res) => {
        this.setState({
          data: res.data.data,
          loading: false
        });
      }, () => this.setState({ loading: true }))
    );
  }

  render() {
    return <div>
      <Table
        rowKey={(record) => "pec_log_" + record.id}
        expandedRowRender={(record, index, indent, expanded) => expandedRowRender(record, index, indent, expanded, this)}
        columns={cols(this)}
        dataSource={this.state.data}
        loading={this.state.loading}
        />
    </div>
  }

}
export default ComunicazioniPecList;

