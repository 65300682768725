import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import TableProgetti from '../components/TableProgetti';

import '../less/Affidamento.less';

@Wrapper({ route: 'ProgettiFormativi', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewProgetto'])
class ProgettiFormativiRoute extends Component {

  state = {
    loading: false,
    asked: false,
  }

  renderTitle() {
    return 'Progetti formativi'
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          <h3 className="sectionTitle">{this.renderTitle()}</h3>
          <p>Progetti formativi</p>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Progetti Formativi</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <TableProgetti ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default ProgettiFormativiRoute;
