import React, {Component} from 'react';
import {Spin} from 'antd';
import allegatiStatic from '@common/utils/static/allegati'
import DocumentiForm from '@common/components/Documenti';

class Documenti extends Component {

  state = {
    loading: false,
    searched_anagrafica: false,
    model_anagrafica: {},
    open_drawer: false,
    selected_funzione: null
  }

  getFormLayout() {

  }

  componentDidMount () {
    this.setState({model_anagrafica: this.props.anagrafica});
    /*AnagraficaServices.view({expand: 'rapportoLavoro,rapportoLavoro.media'}, this.props.anagrafica.id, (res)=>{
      this.setState({model_anagrafica: res.data.data})
    })*/

  }

  getRapportoLavoro() {
    try {
      return this.state.model_anagrafica.rapportoLavoro.find(el => el.id_ente === this.props.accreditamento.id_ente)
    } catch(e) {
      return {}
    }
  }

  getRapportoLavoroMedia() {
    try {
      return this.getRapportoLavoro().media
    } catch(e) {
      return {}
    }
  }

  /**
   * File obbligatori per le anagrafiche
   * @type {Array}
   */
 /* required_files = ['Autorizzazione al trattamento dei dati personali secondo i termini di legge', 'Dichiarazione della veridicità dei dati riportati, in base al D.P.R. 445 del 28/12/2000'];

  getRequiredMedia() {
    return this.required_files;
  }  */
  // ora li prendiamo dinamicamente dal db



  render () {
    return <div>
      <p>I File contrassegnati da asterisco sono obbligatori</p>
      {
        this.state.model_anagrafica.id
        ?
          <DocumentiForm
            accreditation_id={this.props.accreditation_id}
            canDelete={!this.props.readOnly && this.props.accreditamento.stato == 1}
            canAddNote={false}
            readOnly={this.props.readOnly || this.props.accreditamento.stato != 1}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.getRapportoLavoroMedia()}
            current_domain="rapporto_lavoro"
            current_subdomain="capacita_gestionali"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'rapporto_lavoro', 'capacita_gestionali')}
            ref_id={this.getRapportoLavoro().id}
            onUpload={(medias) => null}
        />
        : <Spin size="large" />
      }
      {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'rapporto_lavoro', 'documenti_capacita_gestionali', this.props.accreditamento.tipo_accreditamento) &&
        <div>
          {
            this.state.model_anagrafica.id
              ?
              <DocumentiForm
                accreditation_id={this.props.accreditation_id}
                canDelete={this.props.readOnly ? false : true}
                canAddNote={false}
                readOnly={this.props.readOnly}
                unique={true}
                media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
                medias={this.getRapportoLavoroMedia()}
                current_domain="rapporto_lavoro"
                current_subdomain="documenti_capacita_gestionali"
                required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'rapporto_lavoro', 'documenti_capacita_gestionali')}
                ref_id={this.getRapportoLavoro().id}
                onUpload={(medias) => null}
              /> : <Spin size="large" />
          }</div>}

    </div>
  }

}
export default Documenti;