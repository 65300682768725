import React, { Component } from 'react';
import { connect } from "react-redux";

import { Button, Popconfirm, message, Modal } from 'antd';

import ModelServices from '@accreditamento/services/Audit';

import moment from 'moment';

import FileSaver from 'file-saver';

import auditStatic from '@common/utils/static/audit';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';

import checkPermission from '@common/utils/check/permissions';
import CaricamentoDocumentiIntegrazione from './CaricamentoDocumentiIntegrazione';
import DocumentiCaricati from './DocumentiCaricati';

import AccreditamentoService from '@accreditamento/services/Accreditamento';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.p7m,.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica file p7m o pdf firmato",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};



class SingleIntegrazione extends Component {

  state = {
    loaded: false,
    open_modal_integrate_file: false,
    accreditamento: {
      id: null
    },
    audit: {},
    open_modal_audit_refuse: false,
    open_modal_audit_integration: false
  }

  componentDidMount() {
    if (this.props.accreditamento.id && this.props.audit.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id && this.props.audit.id &&
      (newProps.accreditamento !== this.props.accreditamento || newProps.audit !== this.props.audit)) this.loaded(newProps)
  }

  loaded(props) {
    this.setState({ accreditamento: props.accreditamento, audit: props.audit, loaded: true });
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `domanda_accreditamento_${date}${month}${year}_${hour}${minu}.pdf`;
    FileSaver.saveAs(_blob, nome_file);

    //this.props.refresh();
  }

  /**
   * Carica il file
   * @return {[type]} [description]
   */
  upload(file) {
    if(!this.state.loading) {
      this.setState({loading: true}, () => {
        //console.log('ff', this.state.file)
        ModelServices.integrazione_file_upload(this.state.accreditamento.id, this.props.integrazione.id, {
          filename: 'file'/*,
          note: this.state.note || "",
          valido_dal: this.state.valido_dal || "",
          valido_al: this.state.valido_al || "",*/
        }, { file: file, name: file.name, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.setState({ open_modal_doc: false, loading: false });

        }, (err) => this.setState({open_modal_doc: false, loading: false}))
      })

    }
  }

  async uploadFile(file) {
    await this.setState({ file: { file: file, name: file.name }, open_modal_doc: true });

    return;

  }

  renderRequestFileFirmato() {
    return <div style={{ marginTop: 18 }}>
      {
        this.props.integrazione.media.filter(el => el.tipo.sottodominio === auditStatic.tipo_media.sottodominio_integrazione_firmata).map(l => {
          //console.warn('TP.1', l)
          return <div><p style={{ display: 'inline-block' }}>Domanda di accreditamento integrata e firmata (data caricamento <b>{moment.unix(l.created_at).format('DD/MM/YYYY')}</b>): </p><DocSpan key={l.id} file={l} /></div>
        })
      }
    </div>
  }

  isIstruttore() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) &&
      this.props.accreditamento.istruttore && this.props.accreditamento.istruttore[0].id === this.props.UserStore.user.id
      ? true : false
  }

  canView() {

    return (
      this.props.audit.id_user === this.props.UserStore.user.id ||
      checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ||
      this.isIstruttore() ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) &&
        this.props.accreditamento.id_user === this.props.UserStore.user.id)
    ) ? true : false;
  }

  /**
   * chi ha creato l'audit
   */
  canGiveFeedback() {
    return (this.props.audit.id_user === this.props.UserStore.user.id && this.props.integrazione.stato === auditStatic.stato_integrazione.inviata
    ) ? true : false;
  }



  /**
   * l'ente
   */
  canGiveIntegrazione() {
    return this.props.accreditamento.id_user === this.props.UserStore.user.id ? true : false;
  }

  canDownloadIntegrazione() {
    if(!this.hasIntegratedEnd()) return false;

    return this.props.integrazione.stato === auditStatic.stato_integrazione.integrazione_confermata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.da_firmare;
  }

  canUploadIntegrazione() {
    // https://cb.schema31.it/cb/issue/447475
    return false;
    
    return this.props.integrazione.stato === auditStatic.stato_integrazione.da_firmare;
  }

  canConfirmIntegrazione() {
    return this.props.integrazione.stato === auditStatic.stato_integrazione.firmata;
  }

  canConfirm() {
    //console.warn('TP.1', this.props.integrazione.stato, auditStatic.stato_integrazione.in_corso, this.hasIntegratedEnd())
    if(checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])) {
      return parseInt(this.props.integrazione.stato) === parseInt(auditStatic.stato_integrazione.in_corso) && this.hasIntegratedEnd();
    }
    return false;
  }

  hasConfirmed() {
    //console.warn('TP.1', this.props.integrazione.stato)
    return this.props.integrazione.stato === auditStatic.stato_integrazione.in_corso;
    return this.props.integrazione.stato === auditStatic.stato_integrazione.integrazione_confermata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.da_firmare ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.firmata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.inviata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.confermata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.rifiutata;

  }

  canIntegrate() {
    return this.canGiveIntegrazione() && !this.hasConfirmed()
  }

  hasIntegratedEnd() {
    if(this.props.accreditamento.stato_flusso==14){
      return false;
    }
    return this.props.audit && (this.props.audit.stato != 0);
    /* task 413939
    let i = true;
    this.props.integrazione.requested_media.map(r => {
      if(!r.new_id) i = false;
      return r;
    });
    return i;
    */
  }

  renderActions() {
    return <div>
      {
        this.canIntegrate() && !this.expiredIntegration() && (this.props.audit.stato == 2)
        ?
        <div>
            {
              // 11/08/2024: storicizzazione! SOLO UNO PUò AVERE LA RICHIESTA DOCUMENTALE
              this.props.integrazione.stato == 6 ? 
                <Button
                disabled={this.state.loading}
                className="m5h" type="primary" icon="upload" onClick={() => {
                  this.setState({open_modal_integrate_file: true})
                }}>
                  Integra la documentazione richiesta
                </Button>
              :
              null//<p>Data risposta integrazione documentale: <strong>{moment.unix(this.props.integrazione.updated_at).format('DD/MM/YYYY')} </strong></p>

            }
            <br/><br/><br/><br/>
            <Modal
              title="Documentazione richiesta"
              width="90%"
              destroyOnClose={true}
              visible={this.state.open_modal_integrate_file}
              onOk={async () => {
                this.setState({
                  open_modal_integrate_file: false
                })
              }}
              onCancel={() => this.setState({ open_modal_integrate_file: false })}
            >
              <CaricamentoDocumentiIntegrazione
                accreditamento={this.props.accreditamento}
                integrazione={this.props.integrazione}
                refreshInline={this.props.refreshInline}
                documents={this.props.integrazione.requested_media}
              />
            </Modal>
        </div>
        : null
      }
      {
        this.canConfirm() && (this.props.audit.stato == 2)
        ?
        <React.Fragment>

            <p><b>ATTENZIONE !!! SI PREGA DI PROCEDERE PRIMA ALL'INTEGRAZIONE DEI DATI RICHIESTI E A SEGUIRE DI CONFERMARE LE MODIFICHE MEDIANTE IL PULSANTE 'CONFERMA I DATI INTEGRATI'.</b></p>

            <p><b>SI POTRA' PROCEDERE ALL'EVENTUALE INTEGRAZIONE DEI DOCUMENTI RICHIESTI DOPO LA CONFERMA DEI DATI INTEGRAT.</b></p>

            <Popconfirm placement="leftTop" title="Attenzione! Proseguendo e confermando le note a corredo dei dati integrati, non sarà più possibile modificare questi ultimi. Proseguire?"
              onConfirm={() => this.setState({open_modal_confirm_integrazione: true})} okText="Si" cancelText="No">
              <Button
                loading={this.state.loading}
                disabled={this.state.loading}
                ghost
                className="m5h" type="primary" icon="check" onClick={() => {                  
                }}>{/*Conferma i documenti caricati*/}Conferma i dati integrati
              </Button>
            </Popconfirm>

            <Modal
              title="Inserisci note"
              visible={this.state.open_modal_confirm_integrazione}
              confirmLoading={this.state.loading}
              onOk={async () => {
                let note = this.formSelRef.props.form.getFieldsValue()['note'];
                if(note === '') {
                  message.error('Inserisci delle note');
                }
                if(!this.state.loading) {
                  await this.setState({loading: true});
                  ModelServices.confirmIntegrazione(this.props.accreditamento.id, this.props.integrazione.id, {note:note}, (res) => {
                    this.props.refreshInline(res.data.data);
                    this.setState({open_modal_confirm_integrazione: false, loading: false})
                  })
                }
              }}
              onCancel={() => this.setState({ open_modal_confirm_integrazione: false, loading: false })}
            >
              <CommonForm
                wrappedComponentRef={inst => (this.formSelRef = inst)}
                loading={this.state.loading || false}
                form_model={formSelLayout}
                values={{

                }}

              />
            </Modal>
        </React.Fragment>
        : null
      }
      {
        (this.canGiveIntegrazione())
          ?
          <div>
            {this.canDownloadIntegrazione() ?

            /* task 413935 non va più scaricato il doc di integrazione ma tutta la domanda
              <div>
                <Button
                loading={this.state.loading}
                disabled={this.state.loading}
                className="m5h" type="primary" icon="download" onClick={() => {
                  if(!this.state.loading) {
                    this.setState({loading: true}, () => {
                      ModelServices.integrazione_file(
                        this.state.accreditamento.id,
                        this.props.integrazione.id,
                        (res) => {

                          this.props.refreshInline({
                              ...this.props.accreditamento,
                              audit: [
                                ...this.props.accreditamento.audit.filter(a => a.id !== this.props.audit.id),
                                {
                                  ...this.props.audit,
                                  integrazione: [
                                    ...this.props.audit.integrazione.filter(i => i.id !== this.props.integrazione.id),
                                    {
                                      ...this.props.integrazione,
                                      stato: auditStatic.stato_integrazione.da_firmare
                                    }
                                  ]
                                }
                              ]
                            });

                          this.saveBlob(res.data);
                          this.setState({loading: false});
                        },
                        (err) => console.error(err)
                      )
                    })
                  }
                }}>Scarica documento di riepilogo delle integrazioni</Button>
              </div> 
              */

              /*
              //https://cb.schema31.it/cb/issue/447475

              <div>
                <Button
                loading={this.state.loading}
                disabled={this.state.loading}
                className="m5h" type="primary" icon="download" onClick={() => {
                  if(!this.state.loading) {
                    this.setState({loading: true}, () => {

                      AccreditamentoService.printPdf(this.props.accreditamento.id, (res) => {

                        this.props.refreshInline({
                          ...this.props.accreditamento,
                          audit: [
                            ...this.props.accreditamento.audit.filter(a => a.id !== this.props.audit.id),
                            {
                              ...this.props.audit,
                              integrazione: [
                                ...this.props.audit.integrazione.filter(i => i.id !== this.props.integrazione.id),
                                {
                                  ...this.props.integrazione,
                                  stato: auditStatic.stato_integrazione.da_firmare
                                }
                              ]
                            }
                          ]
                        });
                        
                        console.warn(res)
                        this.saveBlob(res.data);
                        this.setState({ loading: false })
                      }, (err) => {
                        this.setState({ loading: false })
                      })
                                            
                    })
                  }
                }}>Scarica domanda di accreditamento con le rettifiche richieste in integrazione</Button>
              </div> 
              */

              //https://cb.schema31.it/cb/issue/447475
              <Popconfirm placement="leftTop" title="Confermi di voler concludere la risposta all'integrazione?"
                onConfirm={() => {
                  if(!this.state.loading) {
                    this.setState({loading: true}, () => {
                      //console.log('ff', this.state.file)
                      ModelServices.integrazione_completata_447475(this.state.accreditamento.id, this.props.integrazione.id, {
                        filename: 'file'/*,
                        note: this.state.note || "",
                        valido_dal: this.state.valido_dal || "",
                        valido_al: this.state.valido_al || "",*/
                      }, (res) => {
                        console.warn('447475 success: ', res);
                        this.props.refreshInline(res.data.data)
                        this.setState({ open_modal_doc: false, loading: false });
                      }, (err) => {
                        console.warn('447475 error: ', err);
                        this.setState({open_modal_doc: false, loading: false})
                        })
                    })
                  }
                }}
                
                okText="Si"
                cancelText="No">
                <Button
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  ghost
                  className="m5h" type="primary" icon="check" onClick={() => {                  
                  }}>{/*Conferma i documenti caricati*/}Conferma e concludi l'integrazione
                </Button>
              </Popconfirm>

              : 
              
              null
              
              }
            {this.canUploadIntegrazione() ?
              <div>
                <p>Carica il file contenente la firma</p>
                <CommonForm
                  wrappedComponentRef={inst => (this.formRef = inst)}
                  loading={this.state.loading || false}
                  form_model={formLayout}
                  values={{
                  }}
                  fileListProp={() => []}
                  customUploadRequest={obj => {
                    this.upload(obj.file);
                  }}
                />{/*
                <Modal
                  title="Inserisci note facoltative"
                  visible={this.state.open_modal_doc}
                  onOk={async () => {
                    this.setState({
                      filename: 'file',
                      valido_dal: this.formSelRef.props.form.getFieldsValue()['valido_dal'] || "",
                      valido_al: this.formSelRef.props.form.getFieldsValue()['valido_al'] || "",
                      note: this.formSelRef.props.form.getFieldsValue()['note'] || ""
                    }, () => this.upload())
                  }}
                  onCancel={() => this.setState({ open_modal_doc: false })}
                >
                  <CommonForm
                    wrappedComponentRef={inst => (this.formSelRef = inst)}
                    loading={this.state.loading || false}
                    form_model={formSelLayout}
                    values={{

                    }}

                  />
                </Modal>*/}
              </div> : null}
            {/*this.canConfirmIntegrazione() ?
              <div>
                <p>Conferma il file e inoltra l'integrazione</p>
                <Popconfirm placement="top" title="Confermi l'invio dell'integrazione?"
                  onConfirm={() => {
                    if(!this.state.loading) {
                      this.setState({loading: true}, () => {
                        ModelServices.integrazione_send(this.state.accreditamento.id, this.props.integrazione.id, {},
                          (res) => {
                            this.props.refreshInline(res.data.data);
                            this.setState({loading: false});
                          },
                          (err) => this.setState({loading: false})
                        )
                      })
                    }
                  }}
                  okText="Si" cancelText="No">
                  <Button
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  className="m5h" type="primary" icon="mail">Invia all'amministrazione</Button>
                </Popconfirm>
              </div> : null*/}
          </div>
          : null
      }
      {
        (this.canGiveFeedback() && false /* questo && false è conseguenza del fatto che aboliamo l'esito dell'istruttoria documentale 11/08/2024 */)
          ?
          <div>
            <p style={{marginBottom: 0}}><strong>Esito integrazione documentale</strong></p>
            <Popconfirm placement="top" title="L'integrazione è valida?"
              onConfirm={() => {
                if(!this.state.loading) {
                  this.setState({
                    loading: true,
                    accepting: true
                  }, () => {
                    ModelServices.confirm_integrazione(this.state.accreditamento.id, this.props.integrazione.id, {},
                      (res) => {
                        this.props.refreshInline(res.data.data);
                        this.setState({loading: false, accepting: false})
                      },
                      (err) => this.setState({loading: false, accepting: false}))
                  });
                }
              }}
              okText="Si" cancelText="No">
              <Button
              loading={this.state.accepting}
              disabled={this.state.loading}
              style={{ marginRight: 8 }} className="m5h" type="primary" icon="check">Positivo</Button>
            </Popconfirm>
            <Popconfirm placement="top" title="Sicuro di voler rigettare la domanda?"
              onConfirm={() => {
                if(!this.state.loading) {
                  this.setState({
                    loading: true,
                    refusing: true
                  }, () => {
                    ModelServices.reject_integrazione(this.state.accreditamento.id, this.props.integrazione.id, {},
                      (res) => {
                        this.props.refreshInline(res.data.data);
                        this.setState({loading: false, refusing: false})
                      },
                      (err) => this.setState({loading: false, refusing: false})
                    )
                  });
                }
              }}
              okText="Si" cancelText="No">
              <Button
              loading={this.state.refusing}
              disabled={this.state.loading}
              className="m5h" type="danger" ghost icon="close">Negativo</Button>
            </Popconfirm>
          </div>
          : null
      }
    </div>
  }

  getDaysRemaining() {

    let cit_decreto = false && (moment() >= moment('2024-08-01') && moment() <= moment('2024-08-31')) ? ' - Timer bloccato dallo 01/08/2024 al 31/08/2024 in virtù del decreto N.13 del 25/07/2024' : '';

    return (this.props.integrazione.stato < auditStatic.stato_integrazione.inviata ) && !this.expiredIntegration() ?
    '(' + this.computeRemainingDay() + ' giorni alla scadenza' + cit_decreto + ')'
    : (this.props.integrazione.stato < auditStatic.stato_integrazione.inviata) && this.expiredIntegration() ? '(tempo scaduto per le integrazioni)': ''
  }

  expiredIntegration(){
   return this.computeRemainingDay() < 0;
  }

  computeRemainingDay() {

    // in virtù di un recente decreto dal primo agosto 2024 a primo settembre 2024 il timer va freezato

    let integ_days = this.props.GlobalStore.stubs.days_integrazione - this.props.accreditamento.integration_added_days;

    if(
      // se prima del 1 agosto 2024 ci sono integrazione che scadono
      ( moment() < moment('2024-08-01') && (integ_days - moment().diff(moment.unix(this.props.integrazione.created_at), 'days')) < 0 )
      // OPPURE l'integrazione è stata creata DOPO fine agosto...
      ||
      (moment.unix(this.props.integrazione.created_at) > moment('2024-08-31'))
    ) {
      // ... allora tutto resta com'era
      console.warn("INT-DAY TP.1");
      return integ_days - moment().diff(moment.unix(this.props.integrazione.created_at), 'days');
    }

    // Se la data di creazione è compresa del periodo di agosto, allora devo sommare la differenza
    // tra la data attuale e la data di creazione, finchè la data attuale è inferiore al 31 agosto
    if (moment.unix(this.props.integrazione.created_at) >= moment('2024-08-01') && moment.unix(this.props.integrazione.created_at) <= moment('2024-08-31')){
        if(moment() < moment('2024-08-31')) {
          console.warn("INT-DAY TP.2");
          return integ_days;
        } else {
          console.warn("INT-DAY TP.3");
          return integ_days - moment().diff(moment('2024-08-31'), 'days');
        }
    }

    // RESTA IL CASO DI RICHIESTE CREATE PRIMA DEL 1 AGOSTO 2024

    // se siamo dentro agosto: residio
    if (moment() >= moment('2024-08-01') && moment() <= moment('2024-08-31')){
      console.warn("INT-DAY TP.4");
      return integ_days - moment('2024-08-01').diff(moment.unix(this.props.integrazione.created_at), 'days');
    } 
    
    // se siamo prima di agosto:
    if (moment() < moment('2024-08-01')) {
      console.warn("INT-DAY TP.5");
      return integ_days - moment().diff(moment.unix(this.props.integrazione.created_at), 'days');
    } 
    
    // se siamo dopo agosto
    if (moment() > moment('2024-08-31')){
      console.warn("INT-DAY TP.6");
      return integ_days - moment('2024-08-01').diff(moment.unix(this.props.integrazione.created_at), 'days') - moment().diff(moment('2024-08-31'), 'days');
    }
  


  }

  hasIntegrated() {
    console.warn('this.props.integrazione.stato >= auditStatic.stato_integrazione.inviata', this.props.integrazione.stato, auditStatic.stato_integrazione.inviata)
    //return true;
    return this.props.integrazione.stato >= auditStatic.stato_integrazione.inviata ? true : false
  }

  render() {
    return (this.state.loaded && this.canView()) ?
      <div>
        <p>Data richiesta integrazione documentale: <strong>{moment.unix(this.props.integrazione.created_at).format('DD/MM/YYYY')} </strong>{this.getDaysRemaining()}</p><br />
        {this.props.integrazione.note ? this.props.integrazione.note : ''}
        {
          this.isIstruttore() && this.hasIntegrated()
          ?
          <div>
            <Button
            disabled={this.state.loading}
            size="small"
            className="m5h" type="primary" icon="search" onClick={() => {
              this.setState({open_modal_integrate_file: true})
            }}>
            Vedi documenti caricati</Button>
            <Modal
              title="Documenti caricati"
              width="90%"
              destroyOnClose={true}
              visible={this.state.open_modal_integrate_file}
              onOk={async () => {
                this.setState({
                  open_modal_integrate_file: false
                })
              }}
              onCancel={() => this.setState({ open_modal_integrate_file: false })}
            >
              <DocumentiCaricati
                accreditamento={this.props.accreditamento}
                integrazione={this.props.integrazione}
                refreshInline={this.props.refreshInline}
                documents={this.props.integrazione.requested_media}
              />
            </Modal>
          </div>
          : null
        }
        {/*this.props.integrazione.note && this.props.integrazione.note.map(n => {
          return <div key={n.id}>
            <strong>{(n.user) ? n.user.username : " - "}</strong>: {n.note}
          </div>
        })*/}
        <div style={{ marginTop: 16, marginBottom: 8 }}>{this.renderRequestFileFirmato()}</div>
        {this.renderActions()}
      </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SingleIntegrazione);
