import BaseRequest from "../_baseRequest";


export default {

    list: (get_vars = {}, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            get_vars,
            {},
            null,
            "get",
            'locations/stati-esteri',
            cb,
            ecb || null,
            null,
            true
        );
    },
}
