import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';

import ModelServices from '../services/RichiestaRuolo';
import SpinnerLoading from '@components/SpinnerLoading';
import PannelloStatoRichiestaRuolo from '../components/PannelloStatoRichiestaRuolo';
import SchedaRichiestaRuolo from '../components/SchedaRichiestaRuolo';


@Wrapper({ route: 'RichiestaRuoloRoute', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewRichiestaRuolo', 'adminPermissions'])
class RichiestaRuoloRoute extends Component {

  state = {
    loading: false,
    data: null,
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    this.setState({ loading: true }, () => {
      this.loadDetail();
    });
  }

  loadDetail() {
    ModelServices.detail({
      expand: [
        'media', 
        'responsabile', 
        'comunicazioneOstativa',
        'comunicazioneOstativa.controdeduzioni',
        'comunicazioneOstativa.controdeduzioni.media'
      ].join(',')
    }, this.props.match.params.id_ruolo,
      res => {
        this.setState(
          {
            data: res.data.data,
            loading: false
          },
        );
      }, () => {
        this.setState({ loading: false });
      }
    )
  }

  renderTitle() {
    return 'Nuova richiesta di ruolo';
  }

  refresh() {
    this.loadDetail();
  }

  refreshInline(data) {

    this.setState({
      data: { ...this.state.data, ...data }
    });
  }


  render() {

    return (

      <div className="scheda">
        {this.state.loading || !this.state.data ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{this.renderTitle()}</h3>
              <p>Richiesta di ruolo</p>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ruoli/richieste">Richieste di ruolo</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{this.renderTitle()}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <PannelloStatoRichiestaRuolo {...this.props} richiesta={this.state.data} refresh={() => this.refresh()} refreshInline={(data) => this.refreshInline(data)} />
            <SchedaRichiestaRuolo {...this.props} refresh={() => this.refresh()} refreshInline={(data) => this.refreshInline(data)} richiesta={this.state.data} progress_obj={this.state.data?.progress || { progress: 0, valid_sections: [] }} />

          </>
        }
      </div>
    );
  }
}

export default RichiestaRuoloRoute;