import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import SpinnerLoading from '@common/components/SpinnerLoading';
import format from '@common/utils/formatters/_';
import DatiRappresentante from '../../affidamento/components/soggettiCorrelati/DatiRappresentante';
import DatiIndirizzo from '../../affidamento/components/soggettiCorrelati/DatiIndirizzo';
import { Icon } from 'antd';
import Documenti from '@common/components/Documenti';
import ModelService from '../services/RichiestaRuolo'
import formatter from '@common/utils/formatters/_';
import { message } from 'antd';
import ruoloStatic from '@common/utils/static/ruolo';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'dati_generali',
                type: 'child',
                child: <div style={{ marginBottom: 16 }}>
                  <React.Fragment>
                    <div
                      ref={(ref) => component.dati_generali_container = ref}
                    >
                      <DatiRappresentante
                        {...component.props}
                        readOnly={true}
                        dati={component.state.data || {}}
                        ref={(ref) => component.dati_generali = ref}
                        subform={true}
                      ></DatiRappresentante>
                    </div>
                  </React.Fragment>
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'residenza',
                type: 'title',
                element: 'h4',
                text: 'Residenza',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'residenza_dati',
                type: 'child',
                child: <div style={{ marginBottom: 16 }}>
                  <React.Fragment>
                    <div
                      ref={(ref) => component.dati_indirizzo_container = ref}
                    >
                      <DatiIndirizzo
                        {...component.props}
                        readOnly={true}
                        dati={component.state.data || {}}
                        ref={(ref) => component.dati_indirizzo = ref}
                        subform={true}
                      ></DatiIndirizzo>
                    </div>
                  </React.Fragment>
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'contatti',
                type: 'title',
                element: 'h4',
                text: 'Contatti',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Telefono',
                name: 'telefono',
                props: {
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  disabled: true,
                },
                validations: [
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,20})$',
                    message: 'Telefono non valido'
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Cellulare',
                name: 'cellulare',
                props: {
                  disabled: true,
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    //type: 'required',
                    //error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,20})$',
                    message: 'Telefono non valido'
                  },
                ],
              },
            ],
          },
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Email',
                type_string: 'email', // field type
                name: 'email',
                props: {
                  disabled: true,
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                },
                validations: [
                  {
                    type: 'email',
                    error_message: 'Email non valida'
                  }
                ],
              },
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'PEC',
                type_string: 'email', // field type
                name: 'pec',
                props: {
                  disabled: true,
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    type: 'email',
                    error_message: 'Pec non valida'
                  }
                ],
              },
            ]
          }
        ],
      },

      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'Esterno all\'amministrazione:',
                name: 'esterno_amministrazione',
                option_type: 'button',
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly || component.props.richiesta.tipo_ruolo === ruoloStatic.tipo_ruolo.valutatore,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Dipartimento',
                name: 'dipartimento',
                options_props: 'dipartimenti',
                onChange: 'selectedDipartimento',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                props: {
                  disabled: component.props.readOnly,
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: 0,
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Settore',
                name: 'settore',
                options_props: 'settori',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                props: {
                  disabled: component.props.readOnly,
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: 0,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                name: 'sede_lavoro_title',
                type: 'title',
                element: 'h4',
                text: 'Sede di lavoro',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: 0,
                  },
                ],
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },
      {
        cols: [
          { 
            span: 24,
            items: [
              {
                name: 'sede_lavoro_dati',
                type: 'child',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: 0,
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <React.Fragment>
                    <div
                      ref={(ref) => component.indirizzo_sede_lavoro_container = ref}
                    >
                      <DatiIndirizzo
                        {...component.props}
                        readOnly={component.props.readOnly}
                        dati={component.state.data.sede_lavoro || {}}
                        ref={(ref) => component.indirizzo_sede_lavoro = ref}
                        subform={true}
                      ></DatiIndirizzo>
                    </div>
                  </React.Fragment>
                </div>
              }
            ],
          },
        ],
      },
      /*{
        cols: [
          {
            items: [
              {
                name: 'curriculum',
                type: 'title',
                element: 'h4',
                text: 'Curriculum',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: 0,
                  },
                ],
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },*/

      {
        cols: [
          {
            items: [
              {
                name: 'curriculum_upload',
                type: 'child',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: 0,
                  },
                ],
                child: <Documenti
                  readOnly={component.props.readOnly}
                  root={'ruolo'}
                  canDelete={true}
                  required_dates={false}
                  accept={'.pdf'}
                  hideTitle={false}
                  uploadButtonText={'Carica il documento'}
                  accreditation_id={component.props.richiesta.id}
                  unique={true}
                  tempFile={false}
                  media_types={component.props.GlobalStore.stubs.tipo_media}
                  medias={component.props.richiesta.media}
                  current_domain="richieste_ruolo"
                  current_subdomain="curriculum_dipendente_regionale"
                  canAddNote={false}
                  required_types={component.props.GlobalStore.stubs.tipo_media
                    .filter(el => el.sottodominio === 'curriculum_dipendente_regionale' && el.dominio === 'richieste_ruolo')
                    .map(e => e.tipo_media)
                  }
                  ref_id={component.props.richiesta.id}
                  onUpload={() => component.props.refresh()}
                  onDelete={() => component.props.refresh()}
                />
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            offset: 22,
            span: 2,
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.readOnly; }
                  }
                ],
              }
            ],
          },
        ],
      },


    ],
    onSubmit: 'onSubmit',
  }
};

export default class DatiIdentificativiForm extends Component {

  state = {
    data: null,
    loading: false,
    open_drawer: false,
  };

  componentDidMount() {
    if (this.props.richiesta) {
      let data = format.replaceFormDatesIn([
        'nato_il',
      ], this.props.richiesta.json, 'YYYY-MM-DD');

      this.setState({ data: data,
        settori_dipartimento: this.props.GlobalStore.stubs.settori_regionali?.filter(el => el.id_dipartimento_regionale === parseInt(this.props.richiesta.json.dipartimento))
       });
    }
  }

  prepareData(data) {
   
    let datiGenerali = formatter.replaceFormDatesOut(['nato_il'], this.dati_generali.formRefDatiRappr.props.form.getFieldsValue(), 'YYYY-MM-DD')
    let datiResidenza = this.dati_indirizzo.formRef.props.form.getFieldsValue()
    let datiSedeLavoro = this.indirizzo_sede_lavoro.formRef.props.form.getFieldsValue()
    data = format.replaceStringWithIntegeres([
      'esterno_amministrazione'
    ], data);
    data = {...data, ...datiGenerali, ...datiResidenza, sede_lavoro: datiSedeLavoro}

    return data;
  }

  async onSubmit(data) {

    let valid = await this.dati_indirizzo.formRef.props.form.validateFields();
    let valid_sede = await this.indirizzo_sede_lavoro.formRef.props.form.validateFields();
 
    if (!valid || !valid_sede) {
      return;
    }

    data = this.prepareData(data)

    if (!this.state.loading) {
      this.setState({ loading: true }, () => {

        //let save = this.state.data.id ? ModelServices.update(data, this.state.data.id) : ModelServices.create(data);
        let save = ModelService.saveJSON(data, this.props.richiesta.id);
        save.then(res => {
          message.success("Richiesta salvata correttamente");
          this.setState({ loading: false },
            () => { this.props.refreshInline(res.data.data); });
        }).catch(() => this.setState({ loading: false }));

      });
    }
  }
  getValues(data) {
    let values = format.replaceStringWithIntegeres([
      'dipartimento', 'settore'
    ], data);

    return format.replaceIntegeresWithString([
      'esterno_amministrazione'
    ], values);
  }
  render() {
    return (!this.state.data ? <SpinnerLoading message='Caricamento dati...' /> :
      <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          dipartimenti={this.props.GlobalStore.stubs.dipartimenti_regionali}
          settori={this.state.settori_dipartimento || []}
          selectedDipartimento={(data) => {
            this.formRef.props.form.setFieldsValue({ 'settore': null })
            this.setState({ settori_dipartimento: this.props.GlobalStore.stubs.settori_regionali?.filter(el => el.id_dipartimento_regionale === data) })
          }}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={this.getValues(this.state.data)}
          onSubmit={this.onSubmit.bind(this)}
        />
      </div>
    )
  }
}
