import BaseRequest from "../_baseRequest";


export default {
  view: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'media/media/view-media/' + id,
      cb,
      ecb || null,
      null
    );
  },

  preview: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'media/upload/' + id + '/view',
      cb,
      ecb || null,
      null
    );
  },

  upload: (id, data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      file,
      "post",
      'media/upload/' + id,
      cb,
      ecb || null,
      null
    );
  },

  uploadTemp: (data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      file,
      "post",
      'media/upload/temp',
      cb,
      ecb || null,
      null
    );
  },

  addNote: (id, data, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      null,
      "post",
      'media/upload/' + id + '/add-note',
      cb,
      ecb || null,
      null
    );
  },

  download: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'media/upload/' + id + '/view',
      cb,
      ecb || null,
      null
    );
  },

  uploadDati: (data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      file,
      "post",
      'media/upload/dati',
      cb,
      ecb || null,
      null
    );
  },

  uploadAffidabilitaEconomica: (data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      file,
      "post",
      'media/upload/affidabilita-economica',
      cb,
      ecb || null,
      null
    );
  },

  delete: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "delete",
      'media/upload/' + id,
      cb,
      ecb || null,
      null
    );
  },

  deleteConnection: (id, data, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      null,
      "post",
      'media/upload/delete-connection/' + id,
      cb,
      ecb || null,
      null
    );
  },

  deleteTemp: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "delete",
      'media/upload/temp-delete/' + id,
      cb,
      ecb || null,
      null
    );
  }

}
