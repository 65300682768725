import React, {Component} from 'react';
import {connect} from "react-redux";

import CommonForm from '@common/components/form';
import Documenti from '@common/components/Documenti';

import checkPermission from '@common/utils/check/permissions';

import { Button ,Upload, Icon } from 'antd';

import FileSaver from 'file-saver';
import EnteServices from '@accreditamento/services/Ente';
import MediaServices from '../../../../common/services/media/Media';

const formLayout = (component) => {
  const dirigentePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions']);
  const istruttorePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions']);
  const entePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']);
  
  let mediasAttivazione = [];
  if( component.props.values !== undefined && component.props.values.id_media_attivazione ) {
    mediasAttivazione = [component.props.values.id_media_attivazione];
  } else {
    mediasAttivazione = component.props.values && component.props.values.medias || [];
  }

  let mediasControdeduzione = [];
  if( component.props.values !== undefined && component.props.values.id_media_controdeduzioni ) {
    mediasControdeduzione = [component.props.values.id_media_controdeduzioni];
  } else {
    mediasControdeduzione = component.props.values && component.props.values.medias || [];
  }

  //console.warn('component.props.values', component.props.values)
  //console.warn('mediasAttivazione', mediasAttivazione)
  //console.warn('mediasControdeduzione', mediasControdeduzione)

  return {
    layout: 'vertical',
    multistep: false,
    rows: [

      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'radio',
                label: 'Tipologia provvedimento',
                name: 'tipologia_provvedimento',
                option_type: 'button',
                options: [
                  {
                    value: "0",
                    label: "Sospensione"
                  },
                  {
                    value: "1",
                    label: "Revoca"
                  },
                  {
                    value: "2",
                    label: "Nessuna sanzione"
                  }
                ],
                props: {
                  readOnly: component.props.readOnly
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          }
        ]
      },
      
      {
        cols: [
          {
            span: 24,
            items: 
            [
              {
                type: 'input',
                input_type: 'text',
                label: 'Numero del provvedimento',
                name: 'numero_provvedimento',
                props: {
                  readOnly: component.props.readOnly
                },
                validations: [{
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }],               
              }
            ]
          }
        ]
      },      

      {
        cols: [
          {
            span: 24,
            items: 
            [
              {
                type: 'datepicker',
                label: 'Data del provvedimento',
                type_string: 'text', // field type
                name: 'data_provvedimento',
                props: {
                  readOnly: component.props.readOnly
                },
                validations: [{
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }],                
              }
            ]
          }
        ]
      },  
       
      /*
      {
        cols: [
          {
            span: 24,
            items: 
            [
              {
                type: 'input',
                input_type: 'text',
                label: 'Link di casa di vetro ',
                name: 'link_casa_vetro',
                props: {
                  readOnly: component.props.readOnly
                },
                validations: [{
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }],                 
              }
            ]
          }
        ]
      },     
      */

      {
        // Le note interne sono solo per gli operatori regionali
        cols: entePermissions ? [] : [
          {
            span: 24,
            items: 
            [
              {
                type: 'textarea',
                label: 'Note interne',
                type_string: 'text', // field type
                name: 'note_interne_conclusione',
                props: {
                  size: 'large',
                }
              }
            ]
          }
        ]
      },  
            
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Conferma',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ],
    onSubmit: 'submitted',
  };
};

class ConclusioneProvvedimentoForm extends Component {

  state = {
    loading: false,
    medias: [],
  };

  confirm(data) {
    this.props.submit(data);
  }

  getValues() {
    return this.props.values || {};
  }

  updateMedias(media) {
    let just = localStorage.getItem('document_form_provvedimento');
    if(just) {
      just = JSON.parse(just)
    } else {
      just = { medias: [] }
    }

    just.medias = [...just.medias, media];
    localStorage.setItem('document_form_provvedimento', JSON.stringify(just));

    if (this.props.onUpload) this.props.onUpload(media)
    this.setState({ medias: [...this.state.medias, media] })
  }

  /*
  async validate() {
    return new Promise((resolve, reject) => {

        this.formRef.props.form.validateFields((errors, values) => {

            if (this.state.medias.filter(e => e.tipo.sottodominio === 'sospensione_attivazione' && e.tipo.dominio === 'provvedimento' && e.deleted !== 1).length === 0) {
              message.error('Inserire documentio');
              resolve(false)
            } else {
              resolve(true);
            }

        });
    });
  }
  */

  deleteMedia(id) {
    try{
      if (this.props.onDeleteMedia) this.props.onDeleteMedia(id)
    } catch(e) {
      console.error(e)
    }

  }

  render () {
    
    let max_termine_presentazione_controdeduzioni = 0;

    switch(this.props.provvedimentoType){
      case 'COMUNICAZIONE_SOSPENSIONE':
        max_termine_presentazione_controdeduzioni = 45;
          break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        max_termine_presentazione_controdeduzioni = 180;
          break;
      case 'COMUNICAZIONE_REVOCA':
        max_termine_presentazione_controdeduzioni = 10;
          break;
      case 'PROVVEDIMENTO_REVOCA':
        max_termine_presentazione_controdeduzioni = 0;
          break;
    }   

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          provvedimentoType={this.props.provvedimentoType}
          readOnly={this.props.readOnly}
          submitted={(dati) => this.confirm(dati)}
          range={(rule, value, cb) => {
            if(value > max_termine_presentazione_controdeduzioni) {
              cb("Il termine presentazione controdeduzioni non può andare oltre i " + max_termine_presentazione_controdeduzioni + " giorni");
            } else {
              cb();
            }
          }}          
        />

                    <span><b>Per accedere al decreto cliccare sulla</b> </span>
                    <br /><br />
                    <a
                      href="http://www.regione.campania.it/regione/it/la-tua-campania/regione-casa-di-vetro"
                      title="Sezione relativa agli adempimenti della Regione Campania (Regione Casa di Vetro)"
                      target="_blank"
                    >
                      <b>
                        Sezione relativa agli adempimenti della Regione Campania
                        (Regione Casa di Vetro)
                      </b>
                    </a>

      </div>
    );
  }
}

export default connect(({UserStore, GlobalStore})=>({UserStore, GlobalStore}), null, null, { withRef: true })(ConclusioneProvvedimentoForm);