import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import checkPermission from '@common/utils/check/permissions';
import affidamentoStatic from '@common/utils/static/affidamento';
import eventBroker from '@common/utils/services/EventBroker';
import EnteService from '@accreditamento/services/Ente';

import formatter from '@common/utils/formatters/_';
import moment from 'moment';
import AccreditamentoService from '@accreditamento/services/Accreditamento'
import QualificaProfessionaliService from '../../../repertorio/services/QualificaProfessionale'

import { Icon, Spin, Tooltip } from 'antd';

import PropTypes from 'prop-types';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'dati_generali_progetto',
                type: 'title',
                element: 'h4',
                text: 'Dati generali del progetto',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'Tipologia di corso',
                name: 'AfcProjectInfoFormSection.courseType',
                option_type: 'button',
                onChange: 'changedTipologia',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ],
                options: [
                  {
                    value: 1, //TODO controllare i valori dello stub
                    label: "Finanziato"
                  },
                  {
                    value: 2, //TODO controllare i valori dello stub
                    label: "Autofinanziato"
                  }
                ]
              }
            ],
          },
          {
            span: 16,
            items: [
              {
                type: 'input',
                label: 'Titolo del corso',
                type_string: 'text', // field type
                name: 'AfcProjectInfoFormSection.courseTitle',
                //props: {
                //  disabled: component.props.readOnly,
                //},
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'dati_finanziamento',
                type: 'title',
                element: 'h4',
                text: 'Dati finanziamento',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcFinancingDataFormSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Finanziamento',
                type_string: 'text',
                name: 'AfcFinancingDataFormSection.financing',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcFinancingDataFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Asse',
                type_string: 'text',
                name: 'AfcFinancingDataFormSection.axis',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcFinancingDataFormSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Obiettivo specifico',
                type_string: 'text',
                name: 'AfcFinancingDataFormSection.specificObjective',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcFinancingDataFormSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'riferimenti_legge_regionale',
                type: 'title',
                element: 'h4',
                text: 'Riferimenti Legge regionale 18/85',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLegalReferenceSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'Riferimenti Legge regionale 18/85',
                name: 'AfcLegalReferenceSection.article',
                option_type: 'button',
                onChange: 'changedRiferimentiLegge',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLegalReferenceSection ? true : false; }
                  }
                ],
                options: [
                  {
                    value: 1, //TODO controllare i valori dello stub
                    label: "Art. 40"
                  },
                  {
                    value: 2, //TODO controllare i valori dello stub
                    label: "Art. 41"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'Riferimenti_SIURP',
                type: 'title',
                element: 'h4',
                text: 'Riferimenti SIURP',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSiurpReferenceSection && !component.isEnte() ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Codice identificativo del progetto',
                type_string: 'text',
                name: 'AfcSiurpReferenceSection.projectId',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSiurpReferenceSection && !component.isEnte() ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Codice corso',
                type_string: 'text',
                name: 'AfcSiurpReferenceSection.courseId',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSiurpReferenceSection && !component.isEnte() ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'edizione',
                type: 'title',
                element: 'h4',
                text: 'Edizione',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEditionSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            //span: 8,
            items: [
              {
                type: 'integer',
                label: 'Numero edizione',
                name: 'AfcEditionSection.editionNumber',
                props: {
                  min: 1
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEditionSection ? true : false; }
                  }
                  //TODO Deve essere inserito in fase di attivazione e poi negli anni successivi(Se  successiva alla prima prevedere la possibilità di selezionare e copiare i dati da una selezione precedente e consentirne la modifica)
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'macrotipologia',
                type: 'title',
                element: 'h4',
                text: 'Macrotipologia',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcMacroTypologySection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Macrotipologia',
                name: 'AfcMacroTypologySection.macroTypology',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_macrotipologie',
                onChange: 'selectedMacrotipologia',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcMacroTypologySection ? true : false; }
                  }
                ]
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'periodo_svolgimento_corso',
                type: 'title',
                element: 'h4',
                text: 'Periodo svolgimento corso',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di avvio del corso',
                format: 'DD/MM/YYYY',
                name: 'AfcCourseTimeSection.startDate',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: [
                  {
                    validator: 'min_date'
                  }],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di conclusione del corso',
                format: 'DD/MM/YYYY',
                name: 'AfcCourseTimeSection.endDate',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: [
                  {
                    validator: 'max_date'
                  }],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'modalita_formativa',
                type: 'title',
                element: 'h4',
                text: 'Modalità formativa',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalModalitySection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Classificazione CLA istat a 3 digit',
                name: 'AfcEducationalModalitySection.claClassification',
                options_props: '_modalita_formativa',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalModalitySection ? true : false; }
                  }
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'contenuto_formativo',
                type: 'title',
                element: 'h4',
                text: 'Contenuto formativo',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalContentSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Classificazione dei contenuti formativi/educativi',
                name: 'AfcEducationalContentSection.educationalContent',
                options_props: '_contenuto_formativo',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalContentSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'modalita_svolgimento',
                type: 'title',
                element: 'h4',
                text: 'Modalità di svolgimento',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExecutionModalitySection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Modalità di svolgimento',
                name: 'AfcExecutionModalitySection.modality',
                options_props: '_modalita_svolgimento',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExecutionModalitySection ? true : false; }
                  }
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'oneri_imposti_allievi',
                type: 'title',
                element: 'h4',
                text: 'Oneri imposti agli allievi',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLearnerPaymentsSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Tassa di iscrizione (in €)',
                name: 'AfcLearnerPaymentsSection.entryFee',
                props: {
                  min: 0,
                  onBlur: () => { return component.totalOneriImpostiAgliAllievi() }
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLearnerPaymentsSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Costo intero corso comprensivo di IVA (in €) (Per anno nel caso di corsi pluriennali)',
                name: 'AfcLearnerPaymentsSection.courseCost',
                props: {
                  min: 0,
                  onBlur: () => { return component.totalOneriImpostiAgliAllievi() }
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLearnerPaymentsSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Retta mensile di frequenza (in €) ',
                name: 'AfcLearnerPaymentsSection.monthlyFee',
                props: {
                  min: 0,
                  onBlur: () => { return component.totalOneriImpostiAgliAllievi() }
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLearnerPaymentsSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Altro gravame economico (in €) ',
                name: 'AfcLearnerPaymentsSection.otherPayment',
                props: {
                  min: 0,
                  onBlur: () => { return component.totalOneriImpostiAgliAllievi() }
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLearnerPaymentsSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Totale',
                name: 'AfcLearnerPaymentsSection.total',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLearnerPaymentsSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Descrizione modalità e tempi di versamento della retta e modalità di atto di rinuncia',
                type_string: 'text',
                name: 'AfcLearnerPaymentsSection.paymentsDescription',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLearnerPaymentsSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'livello_offerta_formativa',
                type: 'title',
                element: 'h4',
                text: 'Livello offerta formativa',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationProvidedSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Livello offerta formativa',
                name: 'AfcEducationProvidedSection.level',
                options_props: '_livello_offerta_formativa',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationProvidedSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'tipologia_attivita',
                type: 'title',
                element: 'h4',
                text: 'Tipologia attività',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcActivityTypeSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Tipologia attività',
                name: 'AfcActivityTypeSection.activityType',
                options_props: '_tipologia_attivita',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcActivityTypeSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'esame_attestazione_finale',
                type: 'title',
                element: 'h4',
                text: 'Esame e attestazione finale',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExamSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: 'Previsto Esame finale ',
                name: 'AfcExamSection.examRequired',
                option_type: 'button',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExamSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options: [
                  {
                    value: 1,
                    label: "No"
                  },
                  {
                    value: 2,
                    label: "Si"
                  }
                ]
              }
            ],
          },
          {
            span: 18,
            items: [
              {
                type: 'select',
                label: 'Tipologia dell’attestazione finale rilasciata alla fine del corso',
                options_props: '_attestazione_finale',
                name: 'AfcExamSection.certificateType',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExamSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'settore',
                type: 'title',
                element: 'h4',
                text: 'Settore',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSectorSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Settore',
                name: 'AfcSectorSection.sectorType',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_settori',
                onChange: 'selectedSettore',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSectorSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'info_base_sul_percorso',
                type: 'title',
                element: 'h4',
                text: 'Informazioni di base sul percorso',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcTrainingBasicInfoSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: <>Informazioni di base sul percorso
                  { component.props.showNote &&
                    <Tooltip placement="top" title="I dati e le informazioni inserite nel presente paragrafo saranno utilizzati per valutare la coerenza interna del progetto formativo (area di valutazione B, criterio B.2)"><Icon className="m5w" type="info-circle" /></Tooltip>
                  }
                </>,
                type_string: 'text', // field type
                name: 'AfcTrainingBasicInfoSection.basicInfo',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcTrainingBasicInfoSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'repertorio',
                text: <>Repertorio
                  {component.props.showNote &&
                    <Tooltip placement="top" title="Per le Figure professionali (e i relativi indirizzi) del Repertorio nazionale di IeFP si rinvia a quanto previsto nel Decreto Ministeriale 11 novembre 2011, così come integrato dal Decreto Ministeriale 23 aprile 2012."><Icon className="m5w" type="info-circle" /></Tooltip>
                  }</>,
                type: 'title',
                element: 'h4',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Qualifica professionale',
                name: 'AfcIndexSection.title',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_qualifiche',
                validations: [
                  {
                    type: 'required_if_func',
                    error_message: 'Campo obbligatorio',
                    func: () => { return component.validationQualificaProfessionale() }
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection ? true : false; }
                  }
                ]
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'criteri_selezione_ammissione',
                type: 'title',
                element: 'h4',
                text: 'Criteri di selezione per l’ammissione al corso',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Requisiti di ammissione in termini di età',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.ageAdmission',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Titolo di studio',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.educationalQualification',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Esperienza professionale',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.professionalExperience',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Competenze maturate',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.accruedSkills',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Posizione nella professione e modalità di accertamento del possesso degli stessi',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.professionalPosition',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },

        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Modalità con cui l’organismo intende accertare che i partecipanti siano effettivamente in Possesso del livello di competenza necessario per partecipare con successo all’attività formativa',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.prerequisitesVerification',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Modalità di selezione dei partecipanti in caso di numero soggetti idonei superiore al massimo previsto.',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.candidateSelection',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit_dati_generali',
                text: 'Salva i dati',
                //onClick: 'showDrawer',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.anteprima && !component.props.readOnly; }
                  }
                ],
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'submit',
  }
};

export default class DatiGeneraliForm extends Component {

  constructor(props) {
    super(props);
    this.getBackGeneralData = this.getBackGeneralData.bind(this);
    eventBroker.on('getBackGeneralData', this.getBackGeneralData);
  }

  static propTypes = {
    domanda_json: PropTypes.object,
    generalData: PropTypes.object,
    updateProgress: PropTypes.func,
  }

  state = {
    loaded: false,
    loading: false,
    generalData: {},
    settori: [],
    qualifiche: []
  };

  componentDidMount() {
    this.load(this.props)
  }

  load(props) {

    this.setState({ loading: true })
    let req = [];
    if (props.generalData.AfcMacroTypologySection?.macroTypology) {
      req.push(EnteService.settoriAccreditatiByMacrotipologia(props.generalData.AfcMacroTypologySection.macroTypology, this.props.id_ente))
    } else {
      req.push(Promise.resolve(null));
    }

    if (props.generalData.AfcSectorSection?.sectorType) {
      req.push(QualificaProfessionaliService.getBySettore(props.generalData.AfcSectorSection.sectorType))
    } else {
      req.push(Promise.resolve(null));
    }

    Promise.all(req).then(res => {

      let [res1, res2] = res;

      let settori = res1.data.data || [];
      if (props.generalData.AfcSectorSection?.sectorType && !settori.find(el => el.value === props.generalData.AfcSectorSection?.sectorType)) {
        settori.push( this.props.GlobalStore.stubs.settori.find(el => el.value === props.generalData.AfcSectorSection?.sectorType))}

      this.setState(
        {
          settori: settori || [],
          qualifiche: res2.data.data || [],
          loading: false,
        }
      );
    },
    ).catch(() => {
      this.setState({ loading: false });
    });

    this.setState({
      loaded: true,
      loaded_source: true,
      generalData: props.generalData,
      teachingProgramming : props.teachingProgramming,
    });
  }

  getBackGeneralData() {
    return this.formSelRef?.props.form.getFieldsValue();
  }

  validationQualificaProfessionale() {
    let cert_type = this.formSelRef?.props.form?.getFieldValue('AfcExamSection.certificateType');
    return cert_type !== 1 && cert_type !== 2 && cert_type !== 3 && cert_type !== 14;

  }

  validationFiguraProfessionale() {
    return this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']);
  }

  getValues() {
    let generalData = this.state.generalData;
    if (generalData.AfcCourseTimeSection) {
      generalData.AfcCourseTimeSection = formatter.replaceFormDatesIn(['startDate', 'endDate'], generalData.AfcCourseTimeSection)
    }
    return generalData
  }

  prepareData(data) {
    if (this.props.generalData.AfcCourseTimeSection) {
      let AfcCourseTimeSection = formatter.replaceFormDatesOut(['startDate', 'endDate'], data.AfcCourseTimeSection, 'YYYY-MM-DD');
      data.AfcCourseTimeSection = AfcCourseTimeSection;
    }
    return data;
  }

  totalOneriImpostiAgliAllievi() {
    let data = this.formSelRef.props.form.getFieldsValue()['AfcLearnerPaymentsSection']
    data['total'] = data['entryFee'] + data['courseCost'] + data['monthlyFee'] + data['otherPayment'];
    this.formSelRef.props.form.setFieldsValue({ AfcLearnerPaymentsSection: data });
  }

  submit(data) {
    let domanda = this.props.domanda_json;
    domanda.generalData = this.prepareData(data);
    if (this.state.decreto) {
      if (domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection?.entityPersonalData) {
        domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection.entityPersonalData.decreto = this.state.decreto;
      }
      if (domanda.relatedSubjects?.AfcProjectStaffSection?.staffPersonalDataList) {
        domanda.relatedSubjects.AfcProjectStaffSection.staffPersonalDataList = [];
        domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative = null;
        domanda.relatedSubjects.AfcProjectStaffSection.numberOfTeachers = 0;
      }

    }

    domanda.teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList = this.props.methodList;
    /*this.setState({
   loading: true,
   loaded: false,
 });*/

    this.props.saveJson(domanda);

    /*ModelServices.save_json(domanda, this.props.match.params.id,
      res => {
        if (this.props.updateProgress) {
          this.props.updateProgress(res.data.data.progress);
          this.props.refresh(false);
          message.success('Dati salvati con successo');
        }
        this.setState({
          loading: false,
          loaded: true,
          generalData: res.data.data.domanda_json.generalData
        });
      },
      () => {
        this.setState({
          loading: false,
          loaded: true
        });
      }
    );*/
  }

  getSettoreByMacrotipologia(data) {
    let sectorSection = this.formSelRef.props.form.getFieldsValue()['AfcSectorSection'];
    if (sectorSection) {
      sectorSection.sectorType = null;
      this.formSelRef.props.form.setFieldsValue({ AfcSectorSection: sectorSection });
      this.setState({ loading: true });
      EnteService.settoriAccreditatiByMacrotipologia(data, this.props.id_ente, res => {
        this.setState({
          settori: res.data.data,
          loading: false,
        });
      }, () => this.setState({ loading: false }));
    }
  }

  selectedSettore(data) {
    this.formSelRef.props.form.setFieldsValue({
      AfcIndexSection: {
        title: null
      }
    });
    this.setState({ loading: true });

    let req = [
      AccreditamentoService.getBySettore(data),
      QualificaProfessionaliService.getBySettore(data)
    ];

    Promise.all(req).then(res => {
      let [res1, res2] = res;

      let req1 = [
        EnteService.professionalitaCoinvolte({}, this.props.id_ente, res1.data.data),
        EnteService.attrezzatureAccreditate({}, this.props.id_ente, res1.data.data),
        EnteService.audiovisiviAccreditati({}, this.props.id_ente, res1.data.data),
      ]

      Promise.all(req1).then(res2 => {
        let [res3, res4, res5] = res2
        this.props.resetRisorseUmane(res3.data.data, res4.data.data, res5.data.data)
        this.setState({ loading: false,});
      })

      this.setState(
        {
          decreto: res1.data.data,
          qualifiche: res2.data.data,
        },
        ()=> {this.props.resetPersonaleEnti(res1.data.data);}
      );

    },
    ).catch(() => {
      this.setState({ loading: false });
    });

  }

  render() {
    return <div style={{ opacity: !this.props.saved || this.state.loading ? 0.5 : 1 }}>
      {
        !this.props.saved ? <Spin /> : null
      }
      { checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
        <ValidazioneScheda
          canGiveFeedback={this.props.canGiveSectionFeedback && this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
          loadingFeedback={this.props.loadingFeedback}
          saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'generalData')}
          feedback={this.props.section_feedback ? this.props.section_feedback['generalData'] : null}
        />
      }
      <CommonForm
        loading={this.props.saving || this.state.loading || false}
        wrappedComponentRef={inst => (this.formSelRef = inst)}
        form_model={formLayout(this)}
        values={this.getValues()}
        readOnly={this.props.readOnly}
        selectedMacrotipologia={data => {
          this.getSettoreByMacrotipologia(data);
          this.props.loadProvinceSediAccreditate(data)
        }}
        selectedSettore={data => this.selectedSettore(data)}
        _macrotipologie={this.props.macrotipologie}
        _modalita_formativa={this.props.GlobalStore.stubs.modalita_formativa}
        _contenuto_formativo={this.props.GlobalStore.stubs.contenuto_formativo}
        _modalita_svolgimento={this.props.GlobalStore.stubs.modalita_svolgimento}
        _livello_offerta_formativa={this.props.GlobalStore.stubs.livello_offerta_formativa}
        _tipologia_attivita={this.props.GlobalStore.stubs.tipologia_attivita}
        _attestazione_finale={this.props.GlobalStore.stubs.attestazione_finale}
        _tipo_funzione={this.props.GlobalStore.stubs.tipo_funzioni}
        _figura_professionale={this.props.GlobalStore.stubs.tipo_funzioni}  //TODO
        _settori={this.state.settori}
        _qualifiche={this.state.qualifiche}
        submit={(data) => this.submit(data)}

        min_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let min = value;

          if (min < moment()) cb("La data di avvio deve essere maggiore della data odierna");
          let max = this.formSelRef.props.form.getFieldsValue()['AfcCourseTimeSection']['endDate'];

          if (max && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
          else cb()
        }}
        max_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let max = value;

          let min = this.formSelRef.props.form.getFieldsValue()['AfcCourseTimeSection']['startDate'];

          if (min && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
          else cb()
        }}
      />

    </div>
  }
}