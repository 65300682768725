import BaseRequest from "@common/services/_baseRequest";


export default {

	list: ( id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/efficacia/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	},

	addLivelloSpesa: ( id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/efficacia/' + id_accreditamento + '/livello-spesa',
			cb,
			ecb || null,
			null
		);
	},

	deleteLivelloSpesa: ( id_accreditamento, id_elemento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/efficacia/' + id_accreditamento + '/livello-spesa/' + id_elemento,
			cb,
			ecb || null,
			null
		);
	},


	addLivelloAbbandono: ( id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/efficacia/' + id_accreditamento + '/livello-abbandono',
			cb,
			ecb || null,
			null
		);
	},

	deleteLivelloAbbandono: ( id_accreditamento, id_elemento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/efficacia/' + id_accreditamento + '/livello-abbandono/' + id_elemento,
			cb,
			ecb || null,
			null
		);
	},


	addCostiAttivita: ( id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/efficacia/' + id_accreditamento + '/costi-attivita',
			cb,
			ecb || null,
			null
		);
	},

	deleteCostiAttivita: ( id_accreditamento, id_elemento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/efficacia/' + id_accreditamento + '/costi-attivita/' + id_elemento,
			cb,
			ecb || null,
			null
		);
	},

	addCorsiFinanziati: ( id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/efficacia/' + id_accreditamento + '/corsi-finanziati',
			cb,
			ecb || null,
			null
		);
	},

	deleteCorsiFinanziati: ( id_accreditamento, id_elemento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/efficacia/' + id_accreditamento + '/corsi-finanziati/' + id_elemento,
			cb,
			ecb || null,
			null
		);
	},


}
