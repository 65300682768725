import React, { Component } from 'react';
import { Icon } from 'antd';
import CommonForm from '@common/components/form';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Username',
              type_string: 'text', // field type
              name: 'username',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            },
            {
              type: 'inputpwd',
              label: 'Password',
              type_string: 'password', // field type
              name: 'password',
              props: {
                size: 'large',
                prefix: <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                /*{
                  pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).{8,}',
                  message: 'La password deve contenere minuscole e maiuscole, almeno 1 numero e 1 carattere non numerico nè alfabetico e di almeno 8 caratteri, ma tanto tutto questo non metterà al sicuro il tuo account, sappilo'
                }*/
              ],
            },
            /*{
              name: 'submit',
              type: 'submit',
              text: 'Accedi',
              btn_type: 'primary',
              props: {
                block: true,
                size: "large"
              }
            },*/
            {
              name: 'btn_',
              type: 'submit',
              text: 'Accedi',
              btn_type: 'primary',
              props: {
                block: true,
                size: "large"
              }
            },
            // {
            //   name: 'btn',
            //   type: 'button',
            //   text: 'Registra la tua organizzazione',
            //   onClick: 'accreditate',
            //   btn_type: 'primary',

            //   props: {
            //     block: true,
            //     className: "green-form-button",
            //     size: "large",
            //     style: { marginTop: -10 }
            //   }
            // },
          ]
        }

      ]
    }
  ],
  onSubmit: 'submitted'
}


class LoginForm extends Component {

  state = {
    loading: false
  }

  submitted(data) {
    this.props.onSubmit(data);
  }

  render() {
    return (
      <CommonForm
        loading={this.props.loading || false}
        form_model={formLayout}
        values={{

        }}
        submitted={(data) => this.submitted(data)}
        gotocomplete={() => this.props.gotocomplete()}
        accreditate={() => this.props.accreditate()}
      />
    );
  }
}

export default LoginForm