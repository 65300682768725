import moment from 'moment';
import React, { Component } from 'react';
import TableComponent from '@common/components/table';
import ModelServices from '../services/Procedura';

const model = (component) => {
  return {
    with_server_pagination: false,
    columns: [
      {
        title: 'Data modifica',
        dataIndex: '',
        sorter: false,
        key: 'created_at',
        render: element => <div style={{ width: 140 }}>
          {(element.created_at) ? moment.unix(element.created_at).format('DD/MM/YYYY HH:mm') : ''}
        </div>,
      },
      {
        title: 'Candidature aperte fino ad esaurimento',
        dataIndex: '',
        sorter: false,
        key: 'json.fino_ad_esaurimento',
        render: element => <div style={{ width: 100 }}>
          {element.json.fino_ad_esaurimento === '1' ? 'Si' : 'No'}
        </div>
      },
      {
        title: 'Data apertura',
        dataIndex: '',
        sorter: false,
        key: 'json.data_apertura',
        render: element => <div style={{ width: 140 }}>
          {(element.json.data_apertura) ? moment(element.json.data_apertura, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
        </div>,
      },
      {
        title: 'Data chiusura',
        dataIndex: '',
        sorter: false,
        key: 'json.data_chiusura',
        render: element => <div style={{ width: 140 }}>
          {(element.json.data_chiusura) ? moment(element.json.data_chiusura, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
        </div>,
      }
    ]
  }
}


export default class TabellaStoricoProcedure extends Component {
  default_order = 'created_at'

  state = {
    loading: false,
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    //  if (prevProps.location.search !== this.props.location.search) {
    //    this.fetch(this.props.location.search);
    //  } else

    if (prevProps.tab !== this.props.tab) {
      this.refresh();
    }
  }

  /*
  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.tab !== prevState.tab) {
      //return {tab : nextProps.tab};
    }
    else return null;
  }
  */


  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    this.setState({ loading: true });

    ModelServices.detail({ fields: 'storico', expand: 'storico', sort: '-storico.created_at' }, this.props.id_procedura, res => {
      this.setState({
        loading: false,
        data: {
          total: 1,
          current: 1,
          data: res.data.data.storico
        },

      }, () => this.refs.table_component.updateTableData(
        this.state.data.data,
        this.state.data.current,
        this.state.data.total
        )
      );
    });

  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  render() {
    return (
      <div>
        <TableComponent
          data={this.state.data}
          model={model(this)}
          table_props={{ rowKey: 'id' }}
          size="medium"
          ref="table_component"
          loading={this.state.loading}
          with_server_pagination={false}
        />
          {/*
          updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
          updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          */}
      </div>
    );
  }
}
