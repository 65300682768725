import BaseRequest from "@common/services/_baseRequest";

export default {

	detail: (params = {}, id = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			params,
			{},
			null,
			'get',
			'affidamento/nomina/' + id,
			cb,
			ecb || null,
			null
		);
	},
	download_word: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/nomina/' + id + '/download-word/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	upload: (id, id_nomina, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'affidamento/nomina/' + id + '/upload/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	download_autocertificazione_word: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/nomina/' + id + '/autocertificazione-word/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	download_dichiarazione_disponibilita: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/nomina/' + id + '/dichiarazione-disponibilita/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	feedback: (id, id_nomina, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'affidamento/nomina/' + id + '/feedback/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	download_ispettore: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/nomina/' + id + '/ispettore/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	confirm_feedback: (id, id_nomina, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'affidamento/nomina/' + id + '/confirm-feedback/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},
	download_verbale: (id, id_verbale, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/nomina/' + id + '/download-verbale/' + id_verbale,
			cb,
			ecb || null,
			null
		);
	},

	upload_verbale: (id, id_verbale, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'affidamento/nomina/' + id + '/upload-verbale/' + id_verbale,
			cb,
			ecb || null,
			null
		);
	},
}
