let listeners = [];

export default {
    trigger:(eventCod, data) => {
        let listenersByEvent = listeners[eventCod]

		if (!listenersByEvent) {
            return
        }

        let ret = [];
        for (let i = 0; i < listenersByEvent.length; ++i) {
            ret[i] = listenersByEvent[i](data);
        }

        //return ret;
        return ret[0];
    },

    on:(eventCod, callback) => {
        if (!listeners[eventCod]) {
            listeners[eventCod] = []
        }
        if(!listeners.includes(eventCod))
            listeners[eventCod].push(callback)
    }
}