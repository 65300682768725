import BaseRequest from '@common/services/_baseRequest';

var axios = require('axios');

export default {

  list: (params, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente',
      cb,
      ecb || null,
      null
    );
  },

  view: (params, id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente/' + id,
      cb,
      ecb || null,
      null
    );
  },

  macrotipologieAccreditate: (params = null, id_ente = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente/macrotipologie/' + id_ente,
      cb,
      ecb || null,
      null
    );
  },
  settoriAccreditati: (params = null, id_ente = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente/settori/' + id_ente,
      cb,
      ecb || null,
      null
    );
  },

  settoriAccreditatiByMacrotipologia: (macrotipologia, id_ente = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get",
      'ente/settori/macrotipologia/' + macrotipologia + '/' + id_ente,
      cb,
      ecb || null,
      null
    );
  },

  professionalitaCoinvolte: (params = null, id_ente = null, decreto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente/professionalita/' + id_ente + '/' + decreto,
      cb,
      ecb || null,
      null
    );
  },

  personaleEnti: (formulario_type, id_ente, id_formulario, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      { formulario: formulario_type },
      {},
      null,
      "get",
      'ente/personale/' + id_ente + '/formulario/' + id_formulario,
      cb,
      ecb || null,
      null
    );
  },

  attrezzatureAccreditate: (params = null, id_ente = null, decreto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente/attrezzature/' + id_ente + '/' + decreto,
      cb,
      ecb || null,
      null
    );
  },

  audiovisiviAccreditati: (params = null, id_ente = null,  decreto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente/audiovisivi/' + id_ente + '/' + decreto,
      cb,
      ecb || null,
      null
    );
  },

  docenti: (params = null, id_ente = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente/' + id_ente + '/docenti',
      cb,
      ecb || null,
      null
    );
  },

  getEnteByPIva: (params = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'ente/find-ente-by-piva-codfisc',
      cb,
      ecb || null,
      null
    );
  },

  getMediasAttivazione: (params = null, id_ente = null, id_sospensione = null, cb = null, ecb = null) => {
    const {provvedimentoType} = params;
    //let url = `accreditamento/provvedimenti/${id_ente}/sospensione-richiesta/${id_sospensione}/medias`;
    let url = `accreditamento/provvedimenti/${id_ente}`;
    switch(provvedimentoType){
      case 'COMUNICAZIONE_SOSPENSIONE':
        url = `${url}/comunicazione-sospensione/${id_sospensione}/medias/attivazione`;
        break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        url = `${url}/provvedimento-sospensione/${id_sospensione}/medias/attivazione`;
        break;
      case 'COMUNICAZIONE_REVOCA':
        url = `${url}/comunicazione-revoca/${id_sospensione}/medias/attivazione`;
        break;
      case 'PROVVEDIMENTO_REVOCA':
        url = `${url}/provvedimento-revoca/${id_sospensione}/medias/attivazione`;
        break;
    }
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'get',
      url,
      cb,
      ecb || null,
      null
    );
  },

  getMediasControdeduzione: (params = null, id_ente = null, id_sospensione = null, cb = null, ecb = null) => {
    const {provvedimentoType} = params;
    let url = `accreditamento/provvedimenti/${id_ente}`;
    switch(provvedimentoType){
      case 'COMUNICAZIONE_SOSPENSIONE':
        url = `${url}/comunicazione-sospensione/${id_sospensione}/medias/controdeduzione`;
        break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        url = `${url}/provvedimento-sospensione/${id_sospensione}/medias/controdeduzione`;
        break;
      case 'COMUNICAZIONE_REVOCA':
        url = `${url}/comunicazione-revoca/${id_sospensione}/medias/controdeduzione`;
        break;
      case 'PROVVEDIMENTO_REVOCA':
        url = `${url}/provvedimento-revoca/${id_sospensione}/medias/controdeduzione`;
        break;
    }
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'get',
      url,
      cb,
      ecb || null,
      null
    );
  },

  getMediasControdeduzioneTemplate: (params = null, id_ente = null, id_sospensione = null, cb = null, ecb = null) => {
    const {provvedimentoType} = params;
    let url = `accreditamento/provvedimenti/${id_ente}`;
    switch(provvedimentoType){
      case 'COMUNICAZIONE_SOSPENSIONE':
        url = `${url}/comunicazione-sospensione/${id_sospensione}/medias/controdeduzione/template`;
        break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        url = `${url}/provvedimento-sospensione/${id_sospensione}/medias/controdeduzione/template`;
        break;
      case 'COMUNICAZIONE_REVOCA':
        url = `${url}/comunicazione-revoca/${id_sospensione}/medias/controdeduzione/template`;
        break;
      case 'PROVVEDIMENTO_REVOCA':
        url = `${url}/provvedimento-revoca/${id_sospensione}/medias/controdeduzione/template`;
        break;
    }
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'get',
      url,
      cb,
      ecb || null,
      null
    );
  },

  getProvvedimentiEnteControdeduzioni: (params = null, id_ente = null, id_sospensione = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'get',
      `accreditamento/provvedimenti/${id_ente}/sospensione-richiesta/${id_sospensione}/ente-controdeduzioni`,
      cb,
      ecb || null,
      null
    );
  },

  getProvvedimentiEnteControdeduzioniDownload: (params = null, id_ente = null, id_sospensione = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'get',
      `accreditamento/provvedimenti/${id_ente}/sospensione-richiesta/${id_sospensione}/ente-controdeduzioni/download`,
      cb,
      ecb || null,
      null
    );
  },

  provvedimentoAdd: (params = null, id_ente = null, cb = null, ecb = null) => {
    const {provvedimentoType} = params;
    let url = `accreditamento/provvedimenti/${id_ente}`;
    switch(provvedimentoType){
      /* Originale SISFO
      case 'COMUNICAZIONE_SOSPENSIONE':
        url += '/comunicazione-sospensione/add';
        break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        url += '/provvedimento-sospensione/add';
        break;
      case 'COMUNICAZIONE_REVOCA':
        url += '/comunicazione-revoca/add';
        break;
      case 'PROVVEDIMENTO_REVOCA':
        url += '/provvedimento-revoca/add';
        break;
      */
      case 'PROCEDIMENTO':
        url += '/provvedimento-ente/add';
        break;     
    } 
    
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      url,
      cb,
      ecb || null,
      null
    );
  },

  provvedimentoActivate: (params = null, id_ente = null, id_sospensione = null, cb = null, ecb = null) => {
    const {provvedimentoType} = params;
    let url = `accreditamento/provvedimenti/${id_ente}`;
    switch(provvedimentoType){
      /* Originale SISFO
      case 'COMUNICAZIONE_SOSPENSIONE':
        url = `${url}/comunicazione-sospensione/${id_sospensione}/activate`;
        break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        url = `${url}/provvedimento-sospensione/${id_sospensione}/activate`;
        break;
      case 'COMUNICAZIONE_REVOCA':
        url = `${url}/comunicazione-revoca/${id_sospensione}/activate`;
        break;
      case 'PROVVEDIMENTO_REVOCA':
        url = `${url}/provvedimento-revoca/${id_sospensione}/activate`;
        break;
      */
      case 'PROCEDIMENTO':
        url += `/provvedimento-ente/${id_sospensione}/activate`;
        break;        
    }
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      url,
      cb,
      ecb || null,
      null
    );
  },

  provvedimentoControdeduzioni: (params = null, id_ente = null, id_sospensione = null, cb = null, ecb = null) => {
    const {provvedimentoType} = params;
    //let url = `accreditamento/provvedimenti/${id_ente}/sospensione-richiesta/${id_sospensione}/controdeduzioni`;
    let url = `accreditamento/provvedimenti/${id_ente}`;
    switch(provvedimentoType){
      /* Originale SISFO
      case 'COMUNICAZIONE_SOSPENSIONE':
        url = `${url}/comunicazione-sospensione/${id_sospensione}/controdeduzioni`;
        break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        url = `${url}/provvedimento-sospensione/${id_sospensione}/controdeduzioni`;
        break;
      case 'COMUNICAZIONE_REVOCA':
        url = `${url}/comunicazione-revoca/${id_sospensione}/controdeduzioni`;
        break;
      case 'PROVVEDIMENTO_REVOCA':
        url = `${url}/provvedimento-revoca/${id_sospensione}/controdeduzioni`;
        break;
        */
        case 'PROCEDIMENTO':
          url += `/provvedimento-ente/${id_sospensione}/controdeduzioni`;
          break;          
    }
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      url,
      cb,
      ecb || null,
      null
    );
  },

  provvedimentoStatoFinale: (params = null, id_ente = null, id_sospensione = null, cb = null, ecb = null) => {
    const {provvedimentoType} = params;
    const {action} = params;
    //let url = `accreditamento/provvedimenti/${id_ente}/sospensione-richiesta/${id_sospensione}/finale/conferma`;
    let url = `accreditamento/provvedimenti/${id_ente}`;
    switch(provvedimentoType){
      /* Originale SISFO
      case 'COMUNICAZIONE_SOSPENSIONE':
        url = `${url}/comunicazione-sospensione/${id_sospensione}/${action}`;
        break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        url = `${url}/provvedimento-sospensione/${id_sospensione}/${action}`;
        break;
      case 'COMUNICAZIONE_REVOCA':
        url = `${url}/comunicazione-revoca/${id_sospensione}/${action}`;
        break;
      case 'PROVVEDIMENTO_REVOCA':
        url = `${url}/provvedimento-revoca/${id_sospensione}/${action}`;
        break;
        */
        case 'PROCEDIMENTO':
          url += `/provvedimento-ente/${id_sospensione}/${action}`;
          break;            
    }

    //console.warn("TP.1", method, url, params)
    
    var req = new BaseRequest();

    return action == 'confirm' ?
    
    req.makeRequest(
      {},
      params,
      null,
      'post',
      url,
      cb,
      ecb || null,
      null
    )
    
      :

    req.makeRequest(
      params,
      {},
      null,
      'get',
      url,
      cb,
      ecb || null,
      null
    );

  },


  listProvvedimenti: (params, id_ente = null, cb, ecb = null) => {
    const {provvedimentoType} = params;
    //let url = `accreditamento/provvedimenti/${id_ente}/sospensione-richiesta`;
    let url = id_ente !== null ?  `accreditamento/provvedimenti/${id_ente}` : `accreditamento/provvedimenti`;
    switch(provvedimentoType){
      /* Originale SISFO
      case 'COMUNICAZIONE_SOSPENSIONE':
        url += '/comunicazione-sospensione/list';
        break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        url += '/provvedimento-sospensione/list';
        break;
      case 'COMUNICAZIONE_REVOCA':
        url += '/comunicazione-revoca/list';
        break;
      case 'PROVVEDIMENTO_REVOCA':
        url += '/provvedimento-revoca/list';
        break;
      */
      case 'PROCEDIMENTO':
        url += '/provvedimenti-ente/all';
        break;        
      case 'ALL':
        url += '/all';
        break;        
    } 

    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      url,
      cb,
      ecb || null,
      null
    );
  },
}
