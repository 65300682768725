import BaseRequest from "@common/services/_baseRequest";


export default {

	view: (params, id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			params,
			{},
			null,
			"get",
			'auth/rbac/'+id,
			cb,
			ecb || null,
			null
		);
	},

	list: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			params,
			{},
			null,
			"get",
			'auth/rbac',
			cb,
			ecb || null,
			null
		);
	},



}
