import React, { Component } from "react";
import CommonForm from '@common/components/form';
import { Icon } from "antd";

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'datepicker',
              label: 'Data concordata',
              name: 'data_concordata',
              props: {
                //size: 'large',
                prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'visita_in_itinere',
                  operator: '!=',
                  value: "2"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        }
      ],
    }
  ],
  onSubmit: 'onSubmit',
};

class Ente extends Component {
  state = {
    mounted: false,
    loading: false
  };

  async validate() {
    return new Promise((resolve) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getValues() {
    let obj = {
      ...this.formSelRef.props.form.getFieldsValue()
    };
    return obj;
  }

  render() {
    return <CommonForm
      readOnly={this.props.readOnly}
      wrappedComponentRef={inst => (this.formSelRef = inst)}
      loading={this.state.loading || false}
      form_model={formLayout}
      values={this.props.values}
      onSubmit={() => null}
      tipicorsi={[]}
      postType={(f, e) => this.props.onChangeVisita(f, e)}
    />
  }
}


export default Ente;