import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';

import List from '../components/ListValutatori';

@Wrapper({ route: 'ListaValutatori', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['ListValutatore', 'adminPermissions'])
class ListValutatoriRoute extends Component {

  state = {
    loading: false,
  }

  getBreadCrumb() {
    let breads = [
      <Breadcrumb.Item key="dash"><Link to="/dashboard">Home</Link></Breadcrumb.Item>
    ];

    breads.push(<Breadcrumb.Item key="org"><Link to={"/valutatore"}>Componenti di commissione</Link></Breadcrumb.Item>)

    return breads;
  }

  getHeaderText() {

  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          <h3 className="sectionTitle">Componenti di commissione</h3>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            {this.getBreadCrumb()}
          </Breadcrumb>
        </div>

        <List ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default ListValutatoriRoute;
