import React, { Component } from 'react';
import { Card, Icon, Tabs, message } from "antd";
import { Sticky, StickyContainer } from 'react-sticky';
import SpinnerLoading from "@common/components/SpinnerLoading";
import CommonForm from '@common/components/form';
import format from '@common/utils/formatters/_';
import Documenti from '@common/components/Documenti';
import allegatiStatic from '@common/utils/static/allegati'
import ModelServices from '../services/Media';

const TabPane = Tabs.TabPane;

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Titolo',
                type_string: 'text',
                name: 'tipo_media',
                props: {
                  disabled: !component.isEditable(),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Tipo di accreditamento',
                name: 'tipo_accreditamento',
                mode: 'tags',
                onChange: 'setTipoAccreditamento',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options: [
                  {
                    value: '1',
                    label: 'Provvisorio'
                  },
                  {
                    value: '2',
                    label: 'Definitivo'
                  },
                  {
                    value: '3',
                    label: 'Riconoscimento'
                  },
                  {
                    value: '4',
                    // https://cb.schema31.it/cb/issue/420905: Ric. Mantenimento -> Rinnovo
                    // label: 'Mantenimento'
                    label: 'Rinnovo'
                  },
                ],
              },
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Settore',
                type_string: 'text',
                name: 'sottosezione',
                onChange: 'changedSettore',
                props: {
                  disabled: !component.isEditable(),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options: [
                  {
                    value: "Rappresentante legale",
                    label: "Rappresentante legale"
                  },
                  {
                    value: "Ente",
                    label: "Ente"
                  },
                  {
                    value: "Caratteristiche sede",
                    label: "Caratteristiche sede"
                  },
                  {
                    value: "Requisiti aule",
                    label: "Requisiti aule"
                  },
                  {
                    value: "Capacità gestionali",
                    label: "Capacità gestionali"
                  },
                  {
                    value: "Affidabilità economica e finanziaria",
                    label: "Affidabilità economica e finanziaria"
                  },
                  {
                    value: "Qualità",
                    label: "Qualità"
                  },
              ],
              }
            ],
          },
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Sezione del formulario',
                type_string: 'text',
                name: 'sezione',
                props: {
                  disabled: true,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options: [
                  {
                      value: 1,
                      label: "Dati identificativi del soggetto"
                  },
                  {
                      value: 2,
                      label: "Risorse infrastrutturali e disponibilità logistiche"
                  },
                  {
                      value: 3,
                      label: "Capacità gestionali e risorse professionali"
                  },
                  {
                      value: 4,
                      label: "Affidabilità economica e finanziaria"
                  },
                  {
                      value: 5,
                      label: "Qualità"
                  },
              ],
              }
            ],
          },

          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione',
                name: 'descrizione',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'Obbligatorio',
                name: 'obbligatorieta',
                option_type: 'button',
                options: [
                  {
                    value: '1',
                    label: "Si"
                  },
                  {
                    value: '0',
                    label: "No"
                  }
                ],
                props: {},
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'Stato',
                name: 'stato',
                option_type: 'button',
                options: [
                  {
                    value: 1,
                    label: "Attivo"
                  },
                  {
                    value: 2,
                    label: "Disattivo"
                  }
                ],
                props: {},
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 24,
            items: [
              {
                type: 'integer',
                label: 'Validità',
                type_string: 'text',
                name: 'mesi_validita',
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'fac_simile',
                type: 'child',
                child: <div style={{ marginBottom: 16 }}>
                  <Documenti
                    connection={false}
                    canDelete={true}
                    unique={true}
                    canAddNote={false}
                    media_types={component.props.GlobalStore.stubs ? component.props.GlobalStore.stubs.tipo_media : []}
                    readOnly={false}
                    medias={component.state.data.modello || []}
                    current_domain="fac_simile"
                    current_subdomain="fac_simile"
                    accreditation_id={component.state.data.id}
                    required_types={[]}
                    required_dates={false}
                    ref_id={component.state.data.id}
                    root="fac_simile"
                  />
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            offset: 22,
            span: 2,
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                },
              }
            ],
          },
        ],
      },
      
    ],
    onSubmit: 'onSubmit',
  }
};

export default class SchedaAllegato extends Component {

  state = {
    data: {},
    loading: true,
  }

  componentDidMount() {
    if (this.props.allegato) {
      this.setState({ data: this.props.allegato }, () => {
        this.setState({ loading: false })
      });
    }
  }

  isEditable(){
    return (!this.state.data.sottodominio || allegatiStatic.sottodominiAggiuntivi.indexOf(this.state.data.sottodominio) !== -1)
  }

  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  async setTipoAccreditamento(value) {
    this.setState({ tipi_accreditamento: value })
  }

  getValues() {
    let tipiAccreditamento = this.state.data.tipo_accreditamento ? this.state.data.tipo_accreditamento.split(",") : [];
    return format.convertBoolean([
      'obbligatorieta'
    ], { ...this.state.data, tipo_accreditamento: tipiAccreditamento })
  }

  prepareData(data){

    let allegato = format.revertBoolean([
      'obbligatorieta'
    ], data)

    allegato.tipo_accreditamento = data.tipo_accreditamento ? data.tipo_accreditamento.join(",") : "";

    if(this.state.dominio) allegato.dominio = this.state.dominio
    if(this.state.sottodominio) allegato.sottodominio = this.state.sottodominio
    
    return data;
  }

  onSubmit(data) {

    data = this.prepareData(data)

    if (!this.state.saving) {
      this.setState({ saving: true }, () => {

        let save = ModelServices.update(data, this.state.data.id);
        save.then(res => {
          message.success("Allegato salvato correttamente");
          this.setState({ saving: false },
            () => { this.props.refreshInline(res.data.data); });
        }).catch(() => this.setState({ saving: false }));

      });
    }
  }

  render() { 
    return (<div style={{ opacity: this.state.saving ? 0.5 : 1 }} >{this.state.loading && !this.state.data ? <SpinnerLoading message='Caricamento dati...' /> :
      <Card
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={k => this.setState({ current_key: "" + k })}
            defaultActiveKey="1" size="medium" renderTabBar={this.renderTabBar}>
            <TabPane
              tab={
                <span className={'default'}>DATI<br /><Icon type="check-circle" /></span>
              } key="1">
              <div id="generalData">
                <div style={{ padding: 14 }}>
                  <CommonForm
                   wrappedComponentRef={inst => (this.formSelRef = inst)}
                   changedSettore={data => {
                     this.setState({
                       dominio: allegatiStatic.getDominioFromSottosezione(data),
                       sottodominio: allegatiStatic.getSottoDominioFromSottosezione(data),
                     })
                    this.formSelRef.props.form.setFieldsValue({ sezione: allegatiStatic.getSezioneFromSottosezione(data) });
                   }}
                    setTipoAccreditamento={(value) => this.setTipoAccreditamento(value)}
                    loading={this.state.saving || false}
                    form_model={formLayout(this)}
                    values={this.getValues()}
                    readOnly={false}
                    onSubmit={this.onSubmit.bind(this)}
                  />
                  
                </div>
              </div>
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Card>
    }</div>);
  }
}

