import BaseRequest from "@common/services/_baseRequest";


export default {

	list: ( id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	},

	addProtocolloIntesa: ( id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/protocollo-intesa',
			cb,
			ecb || null,
			null
		);
	},

	deleteProtocolloIntesa: ( id_accreditamento, id_protocollo, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/protocollo-intesa/' + id_protocollo,
			cb,
			ecb || null,
			null
		);
	},

	deleteProtocolloIntesaDettaglio: ( id_accreditamento, id_protocollo, id_dettaglio, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/protocollo-intesa/' + id_protocollo + '/dettaglio/' + id_dettaglio,
			cb,
			ecb || null,
			null
		);
	},

	uploadDocumentoProtocollo: ( id_accreditamento, id_protocollo, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			file,
			"post",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/protocollo-intesa/' + id_protocollo + '/file',
			cb,
			ecb || null,
			null
		);
	},

	removeDocumentoProtocollo: ( id_accreditamento, id_protocollo, id_file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/protocollo-intesa/' + id_protocollo + '/file/' + id_file,
			cb,
			ecb || null,
			null
		);
	},













	addPartenariato: ( id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/partenariato',
			cb,
			ecb || null,
			null
		);
	},

	deletePartenariato: ( id_accreditamento, id_partenariato, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/partenariato/' + id_partenariato,
			cb,
			ecb || null,
			null
		);
	},

	deletePartenariatoDettaglio: ( id_accreditamento, id_partenariato, id_dettaglio, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/partenariato/' + id_partenariato + '/dettaglio/' + id_dettaglio,
			cb,
			ecb || null,
			null
		);
	},

	uploadDocumentoPartenariato: ( id_accreditamento, id_partenariato, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			file,
			"post",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/partenariato/' + id_partenariato + '/file',
			cb,
			ecb || null,
			null
		);
	},

	removeDocumentoPartenariato: ( id_accreditamento, id_partenariato, id_file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/partenariato/' + id_partenariato + '/file/' + id_file,
			cb,
			ecb || null,
			null
		);
	},

























	addStudioTerritorio: ( id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/studio-territorio',
			cb,
			ecb || null,
			null
		);
	},

	deleteStudioTerritorio: ( id_accreditamento, id_studio, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/studio-territorio/' + id_studio,
			cb,
			ecb || null,
			null
		);
	},

	deleteStudioTerritorioDettaglio: ( id_accreditamento, id_studio, id_dettaglio, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/studio-territorio/' + id_studio + '/dettaglio/' + id_dettaglio,
			cb,
			ecb || null,
			null
		);
	},

	uploadDocumentoStudioTerritorio: ( id_accreditamento, id_studio, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			file,
			"post",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/studio-territorio/' + id_studio + '/file',
			cb,
			ecb || null,
			null
		);
	},

	removeDocumentoStudioTerritorio: ( id_accreditamento, id_studio, id_file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/interrelazioni/' + id_accreditamento + '/studio-territorio/' + id_studio + '/file/' + id_file,
			cb,
			ecb || null,
			null
		);
	},

}
