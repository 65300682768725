import React, { Component } from 'react';
import moment from 'moment';

import { Icon } from 'antd';
import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';

import formatter from '@common/utils/formatters/_';


const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        span: 8,
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Nome',
                type_string: 'text',
                name: 'nome',
                props: {
                  disabled: component.props.readOnly,
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Cognome',
                type_string: 'text',
                name: 'cognome',
                props: {
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="solution" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Codice fiscale',
                type_string: 'text', // field type
                name: 'cf',
                props: {
                  // size: 'large',
                  prefix: <Icon type="copy" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^[A-z0-9]{16}$',
                    message: 'Codice fiscale non valido'
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'datepicker',
                label: 'Data di nascita',
                name: 'nato_il',
                format: 'DD/MM/YYYY',
                props: {
                  // size: 'large',
                  style: { width: '100%' },
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'age18Validator'
                  }
                ]
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Stato di nascita',
                name: 'stato_nascita',
                props: {
                  //size: 'large',
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'nazioni',
                onChange: 'selectedStato',
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Provincia di nascita',
                name: 'provincia_nascita',
                props: {
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'province',
                onChange: 'selectedNascitaProvincia',
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Comune di nascita',
                name: 'comune_nascita',
                props: {
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'provincia_nascita',
                    operator: '!=',
                    value: null,
                  },
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options_props: 'comuni'
              },
            ],
          }
        ],
      },
    ],
    onSubmit: 'submitted',
  }
}

class DatiRappresentante extends Component {
  state = {
    loading: true,
    loaded: false,
    comuni: [],
  };



  async componentDidMount() {
    this.loadComuni(this.props)
  }


  loadComuni(props) {
    if (props.dati && props.dati.provincia_nascita) {
      this.selectedProvincia(props.dati.provincia_nascita)
    } else {
      this.setState({ loading: false });
    }
  }

  selectedProvincia(provincia) {
    this.setState({ loading: true });
    ComuniService.all({ id_provincia: provincia },
      res => {
        this.setState({
          loading: false,
          comuni: res.data.data || [],
          selected_nascita_provincia: provincia
        });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  getValues() {
    return formatter.replaceFormDatesIn(['nato_il'], { ...this.props.dati })
  }



  render() {

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRefDatiRappr = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          subform={this.props.subform}
          age18Validator={(rule, value, cb) => {
            var today = moment();
            if (today.diff(value, 'years') < 18) cb("Il rappresentante legale deve essere maggiorenne");
            else cb()
          }}

          onlyPastDate={(rule, value, cb) => {
            if (moment() > value) {
              cb()
            } else {
              cb("Data non valida");
            }
          }}

          nazioni={this.props.GlobalStore.stubs.nazione}
          province={this.props.GlobalStore.stubs.provincia}

          readOnly={this.props.readOnly}

          selectedStato={() => {
            this.setState({
              comuni: [],
            }, () => {
              this.formRefDatiRappr.props.form.setFieldsValue({ comune_nascita: null, provincia_nascita: null });
            });
          }}

          selectedNascitaProvincia={data => {
            this.setState({
              selected_nascita_provincia: data,
              comuni: [],
              loading: true,
            }, () => {
              this.formRefDatiRappr.props.form.setFieldsValue({ comune_nascita: null })
              this.selectedProvincia(data);
            });
          }}

          comuni={this.state.comuni.map(e => {
            return {
              key: 'residenza_comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}
        />
      </div>
    );
  }
}

export default DatiRappresentante;