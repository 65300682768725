import React, { Component } from 'react';
import { Icon } from 'antd';
import CommonForm from '@common/components/form';

import zxcvbn from 'zxcvbn';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'inputpwd',
                label: 'Vecchia password',
                placeholder: 'password',
                type_string: 'password', // field type
                name: 'old_password',
                props: {
                  size: 'large',
                  prefix: <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  /*{
                    pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).{8,}',
                    message: 'La password deve contenere minuscole e maiuscole, almeno 1 numero e 1 carattere non numerico nè alfabetico e di almeno 8 caratteri, ma tanto tutto questo non metterà al sicuro il tuo account, sappilo'
                  }*/
                ],
              },
              {
                type: 'inputpwd',
                label: 'Nuova password',
                placeholder: 'password',
                type_string: 'password', // field type
                name: 'new_password',
                props: {
                  size: 'large',
                  onChange: (val) => /*component.setState({pwd: this.value})*/component.setState({ pwd: val.target.value }),
                  prefix: <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})',
                    message: 'La password deve contenere almeno dieci caratteri, una maiuscola, una minuscola, un numero e un carattere speciale'
                  }
                ],
              },
              {
                type: 'inputpwd',
                label: 'Conferma la nuova password',
                placeholder: 'password',
                type_string: 'password', // field type
                name: 'repeat_password',
                props: {
                  size: 'large',
                  prefix: <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})',
                    message: 'La password deve contenere almeno dieci caratteri, una maiuscola, una minuscola, un numero e un carattere speciale'
                  }
                ],
              },
              {
                name: 'submit',
                type: 'submit',
                text: 'Modifica',
                btn_type: 'primary',
                props: {
                  block: true,
                  size: "large"
                }
              }
            ]
          }
        ]
      }
    ],
    onSubmit: 'submitted'
  }
}


class ResetForm extends Component {

  state = {
    loading: false,
    pwd: ''
  }



  getStr() {
    //console.log(zxcvbn(this.state.pwd));
    return zxcvbn(this.state.pwd)
  }

  getStrString() {
    if (zxcvbn(this.state.pwd).score < 3) return 'Debole';
    if (zxcvbn(this.state.pwd).score < 4) return 'Media';
    return 'Forte';
  }






  submitted(data) {
    //console.log('form',data);
    this.props.onSubmit(data);
  }

  render() {
    return (
      <div>
        <CommonForm
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={{

          }}
          submitted={(data) => this.submitted(data)}
          accreditate={() => null}
        />
        <div className={"pwdStr _" + this.getStr().score}>
          <div className="strVal"></div>
        </div>
        <p>Sicurezza: {this.getStrString()}</p>
      </div>
    );
  }
}

export default ResetForm