import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import checkPermissions from '@common/utils/check/permissions';

import ListProvvedimentiSospensioneEnte from '../components/ListProvvedimentiSospensioneEnte';
import ListProvvedimenti from '../components/ListProvvedimenti';
import ListAllProvvedimenti from '../components/ListAllProvvedimenti';

@Wrapper({ route: 'RichiesteAccreditamento', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['ViewRequest'])
class RichiesteAccreditamentoRoute extends Component {

  state = {
    loading: false,
    asked: false,
  }

  render() {
    let ListProvvedimentiProps = this.props;

    let provvedimenti_all = checkPermissions.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions', 'HelpdeskPermissions']) || checkPermissions.hasPermission(this.props.UserStore.user, ['istruttorePermissions']);

    ListProvvedimentiProps.match.params.id = provvedimenti_all ? null : this.props.UserStore.user.ente.id;

    return (

      provvedimenti_all 
      
      ?
      
      /* Provvedimenti TUTTI */
      <div>

        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow"
        >
          <h3 className="sectionTitle">Provvedimenti</h3>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Provvedimenti</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <ListAllProvvedimenti {...ListProvvedimentiProps} />

      </div>
      
      :

      /* Provvedimenti ENTI */
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow"
        >
          <h3 className="sectionTitle">Provvedimenti</h3>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Provvedimenti</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* originale SISFO
        <ListProvvedimenti 
        provvedimentoType='COMUNICAZIONE_SOSPENSIONE'
        {...ListProvvedimentiProps}
        />
        <ListProvvedimenti 
        provvedimentoType='PROVVEDIMENTO_SOSPENSIONE'
        {...ListProvvedimentiProps}
        />
        <ListProvvedimenti 
        provvedimentoType='COMUNICAZIONE_REVOCA'
        {...ListProvvedimentiProps}
        />
        <ListProvvedimenti 
        provvedimentoType='PROVVEDIMENTO_REVOCA'
        {...ListProvvedimentiProps}
        />
        */}
        <ListProvvedimenti 
        provvedimentoType='PROCEDIMENTO'
        {...ListProvvedimentiProps}
        />
      </div>
    );
  }
}

export default RichiesteAccreditamentoRoute;
