import BaseRequest from '@common/services/_baseRequest';

export default {

  list: (vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/iscrizione',
      cb,
      ecb || null,
      null
    );
  },

  detail: (params = {}, id_iscrizione = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/iscrizione/' + id_iscrizione,
      cb,
      ecb || null,
      null
    );
  },

  inviaInviti: (id_corso = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/iscrizione/' + id_corso + '/invia-inviti',
      cb,
      ecb || null,
      null
    );
  },

  inviaInvito: (id_corso = null, id_iscrizione = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/iscrizione/' + id_corso + '/invia-invito/' + id_iscrizione,
      cb,
      ecb || null,
      null
    );
  },

  downloadContratti: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/iscrizione/' + id_progetto + '/download-contratti',
      cb,
      ecb || null,
      null
    );
  },

  downloadContratto: (id_progetto, id_iscrizione, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/iscrizione/' + id_progetto + '/contratto/' + id_iscrizione,
      cb,
      ecb || null,
      null
    );
  },

  uploadContratto: (id_progetto, id_iscrizione, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/iscrizione/' + id_progetto + '/contratto/' + id_iscrizione,
      cb,
      ecb || null,
      null
    );
  },

  convocaDiscenti: (id_corso = null, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/iscrizione/' + id_corso + '/convoca-discenti',
      cb,
      ecb || null,
      null
    );
  },

  richiediGiustificativo: (id_iscrizione, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      `affidamento/iscrizione/${id_iscrizione}/richiedi-giustificativo`,
      cb,
      ecb || null,
      null
    );
  },

  uploadGiustificativo: (id_iscrizione, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      'post',
      `affidamento/iscrizione/${id_iscrizione}/giustificativo`,
      cb,
      ecb || null,
      null
    );
  },

  downloadGiustificativo: (id_iscrizione, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get_file',
      `affidamento/iscrizione/${id_iscrizione}/giustificativo`,
      cb,
      ecb || null,
      null
    );
  },

  accettaGiustificativo: (id_iscrizione, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      null,
      null,
      'post',
      `affidamento/iscrizione/${id_iscrizione}/accetta-giustificativo`,
      cb,
      ecb || null,
      null
    );
  },

  espelli: (id_iscrizione, data, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      `affidamento/iscrizione/${id_iscrizione}/espelli`,
      cb,
      ecb || null,
      null
    );
  },

  compileRegistration: (id, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      `affidamento/iscrizione/${id}/compile-registration`,
      cb,
      ecb || null,
      null
    );
  },

  sendRegistrationRequest: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      `affidamento/iscrizione/${id}/send-registration-request`,
      cb,
      ecb || null,
      null
    );
  },

  downloadGraduatoria: (id_progetto, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get_file',
      'affidamento/iscrizione/' + id_progetto + '/download-graduatoria',
      cb,
      ecb
    );
  },

  suitableLearner: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      `affidamento/iscrizione/${id}/suitable-learner`,
      cb,
      ecb || null,
      null
    );
  },

  notSuitableLearner: (id, data, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      `affidamento/iscrizione/${id}/not-suitable-learner`,
      cb,
      ecb || null,
      null
    );
  },

  onHoldLearner: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      `affidamento/iscrizione/${id}/on-hold-learner`,
      cb,
      ecb || null,
      null
    );
  },

  acceptRegistration: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      `affidamento/iscrizione/${id}/accept-registration`,
      cb,
      ecb || null,
      null
    );
  },

  rejectRegistration: (id, data, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      `affidamento/iscrizione/${id}/reject-registration`,
      cb,
      ecb || null,
      null
    );
  },

  externInvitation: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      `affidamento/iscrizione/${id}/extern-invitation`,
      cb,
      ecb || null,
      null
    );
  },

  externConvocation: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      `affidamento/iscrizione/${id}/extern-convocation`,
      cb,
      ecb || null,
      null
    );
  },

  uploadDocument: (id, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      file,
      "post",
      `affidamento/iscrizione/${id}/upload-document`,
      cb,
      ecb || null,
      null
    );
  },

  removeDocument: (id, id_file, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "delete",
      `affidamento/iscrizione/${id}/remove-document/${id_file}`,
      cb,
      ecb || null,
      null
    );
  },

  getRegistrationDocuments: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      `affidamento/iscrizione/${id}/get-registration-documents`,
      cb,
      ecb || null,
      null
    );
  },
}
