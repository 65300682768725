import React, { Component } from 'react';

import { Icon, AutoComplete, message, Table, Select, InputNumber,Form } from 'antd';
import ComuniService from '@common/services/locations/Comuni';
import CommonForm from '@common/components/form';
import allegatiStatic from '@common/utils/static/allegati'
import formatter from '@common/utils/formatters/_';
import attivita_formativa from '@common/utils/formatters/mappatura_attivita_formativa';
import DocSpan from '@common/components/DocSpan';
import format from '@common/utils/formatters/_';

const Option = AutoComplete.Option;

const getTipoAula = (component, record) => {
    try {
        let type = formatter.replaceStub(record.id_tab_tipo_aula, 'tipo_aula', component.props.GlobalStore)

        return type;
    } catch (e) {
        return 'errore'
    }
}

const columnsTable = (component) => {
    return [
      {
        title: "Descrizione",
        dataIndex: "tipologia",
        render: (text, record) => (
          <div style={{ width: 120 }}>{format.replaceStub(record.tipologia, 'tipo_attrezzatura_rest', component.props.GlobalStore )}</div>
        ),
      },
      {
        title: "Obbligatorio",
        dataIndex: "obbligatorio",
        render: (text, record) => (
          <div style={{ width: 120 }}>
            {format.replaceStubGeneric(record.tipologia, 'tipo_attrezzatura_rest', component.props.GlobalStore, 'obbligatorio' ) == 0 ? "NO" : "SI"}
          </div>
        ),
      },
      {
        title: "Quantità",
        dataIndex: "quantità",
        render: (text, record) => <div style={{ width: 120 }}><InputNumber min={format.replaceStubGeneric(record.tipologia, 'tipo_attrezzatura_rest', component.props.GlobalStore, 'obbligatorio' ) == 0 ? 0 : 1} defaultValue={record.quantita} onChange={(value)=>component.setQuantita(record.id, value)}  /></div>,
      },
      {
        title: "Titolo di possesso",
        dataIndex: "tipologià_di_possesso",
        render: (text, record) => (
          <div style={{ width: 120 }}>
            <Select 
            defaultValue={format.replaceStub(record.id_tab_attrezzature_possesso, 'possesso', component.props.GlobalStore )}
              onChange={(value)=>component.setTitoloPossesso(record.id, value)} 
              showSearch
              style={{
                width: 120,
              }}
              placeholder="Seleziona tipo Proprietà"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Option value="1">Proprietà</Option>
              <Option value="2">Leasing</Option>
              <Option value="3">Affitto</Option>
              <Option value="4">Comodato</Option>
              <Option value="5">Altro</Option>
            </Select>
          </div>
        ),
      },
    ];
  };
  const formLayout = (component) => {
    return {
      layout: "vertical",
      multistep: false,
      rows: [
        {
          cols: [
            {
              span: 24,
              items: [
                {
                  name: "table",
                  type: "child",
                  child: (
                    <Table
                      pagination={{ pageSize: 300 }}
                      columns={columnsTable(component)}
                      loading={component.state.loading}
                      dataSource={
                        component.props.values.attrezzature
                      }
                      rowKey={(record) => record.id}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              props: {
                style: { float: "right" },
              },
              items: [
                {
                  type: "submit",
                  name: "btn_submit_attrezzatura",
                  text: "Salva attrezzatura",
                  //onClick: 'showDrawer',
                  props: {
                    icon: "save",
  
                    className: "bg-green",
                  },
                },
              ],
            },
          ],
        },
      ],
      onSubmit: "onSubmit",
    };
  };

class UpdateAttrezzatura extends Component {

    state = {
        loading: false,
        loaded: false,
        fileList: [],
        comuni: [],
        selected_aula: null,
        altro_tipologia_attrezzatura: null,
        selected_tipologia_attrezzatura: -1,
        tipologia_required: false
    };

    attrezzature={};



    facSimileClausoleRisolutorie() {
        return this.props.GlobalStore.stubs.tipo_media.find(el => el.dominio === 'ente_sede_attrezzatura' && el.sottodominio === 'clausole_risolutorie')?.facsimile
    }

    async componentDidMount() {
        let tipologia_required = false;
        this.props.sede.aule.map(aula => {
            if(this.props.values && this.props.values.id_aula){
                if(aula.id === this.props.values.id_aula){
                    const tipo_aula = getTipoAula(this, aula); 
                    if(tipo_aula === 'Aula Informatica' || tipo_aula === 'Aula Corso'){
                        tipologia_required = true;
                    }
                }
            }
            
            //console.warn("Aula", aula)
            //console.warn("Tipo Aula", getTipoAula(this, aula))
            return null;
        })

        const allTipologie = this.getTipologie(this.props.values.id_aula);
        let altro_tipologia_attrezzatura = null;
        allTipologie.map(el => {
            if(el.label.toLowerCase() === 'altro'){
                altro_tipologia_attrezzatura=  el.value;
            }
            return null;
        });

        this.setState({
            altro_tipologia_attrezzatura: altro_tipologia_attrezzatura,
            tipologia_required: tipologia_required,
            selected_tipologia_attrezzatura: this.props.values.tipologia,
            selected_aula: this.props.values.id_aula,
            fileList: this.props.values.media ? this.props.values.media.map(el => {
                return {
                    uid: el.id,
                    id: el.id,
                    name: el.nome_file,
                    file: null,
                    status: 'done'
                }
            }) : []
        })

         this.props.values.attrezzature.map(m => { this.attrezzature[m.id] = {...this.attrezzature[m.id], 'quantita' : m.quantita, 'titolo' : m.id_tab_attrezzature_possesso }} ) 

         console.error("ducati", this.attrezzature )
    }


    submitFormData(data) {

        data = {id_aula: this.props.values.id, 'attrezzature' : this.attrezzature};

        if (this.attrezzature) {
            for (const key in this.attrezzature ) {
              const elem = this.attrezzature[key];
              if (elem.quantita && !elem.titolo) {
                message.error("inserisci il titolo di possesso a tutte le attrezzature selezionate");
                return;
              }
            }
          }



        if(data.tipologia !== this.state.altro_tipologia_attrezzatura ){
            //Se la tipologia NON è Altro (id 39) allora tolgo la stringa
            data.tipologia_altro = null
        }


        this.props.onSubmit(formatter.replaceFormDatesOut([
            'data_registrazione',
            'data_in_vigore',
            'data_scadenza'
        ], data, 'YYYY-MM-DD'), (parseInt(data.clausole_risolutorie) === 1) ?
            this.state.fileList.filter(el => !el.id) : null)
    }

    setQuantita(id_attrezzatura, quantita){


        console.warn('id_attrezzatura, quantita', id_attrezzatura, quantita);

        this.attrezzature[id_attrezzatura] = {...this.attrezzature[id_attrezzatura], 'quantita' : quantita};
        console.error("ducati attrezzature", this.attrezzature);
      }
    
      setTitoloPossesso(id_attrezzatura, id_titolo){

        console.warn('id_attrezzatura, quantita', id_attrezzatura, id_titolo);

        this.attrezzature[id_attrezzatura] = {...this.attrezzature[id_attrezzatura], 'titolo' : id_titolo};
        //console.error("attrezzature", this.attrezzature);
      } 


    getTipologie(id_aula = null) {
        let selected_aula = id_aula === null ? this.state.selected_aula : id_aula;
        if (!selected_aula) return [];
        let aula = this.props.sede.aule.find(a => a.id === selected_aula);

        return attivita_formativa.getAttrezzatureByTipoAula(
            this.props.GlobalStore.stubs.tipo_laboratorio_rest,
            aula.id_tipo_laboratorio
        ).map(el => {
            return {
                key: el.key,
                label: el.label,
                value: el.value,
                obbligatorio: el.obbligatorio,
                status: el.status,

            }
        })
    }

    selectedTipologia(value) {
        this.setState({selected_tipologia_attrezzatura: value});    
    }

    render() {

        return (
            <div>

                <CommonForm
                    wrappedComponentRef={inst => (this.formRef = inst)}
                    loading={this.props.loading || false}
                    form_model={formLayout(this)}
                    table_model={columnsTable(this)}
                    values={formatter.convertBoolean([
                        'clausole_risolutorie',
                        'disponibili_sede'
                    ],
                        formatter.replaceStringWithIntegeres([
                            'id_aula',
                            'tipologia',
                            'id_tab_attrezzature_possesso'
                        ],
                            formatter.replaceFormDatesIn([
                                'data_registrazione',
                                'data_in_vigore',
                                'data_scadenza'],
                                this.props.values
                            )
                        )
                    )}
                    readOnly={this.props.readOnly}
                    onSubmit={(data) => this.submitFormData(data)}


                    validateTitoloPossesso={(value, form) => {
                        return (form.getFieldValue('id_tab_attrezzature_possesso') === 2 || form.getFieldValue('id_tab_attrezzature_possesso') === 3 || form.getFieldValue('id_tab_attrezzature_possesso') === 4);
                    }}

                    titolo_possesso={this.props.GlobalStore.stubs.possesso}
                    tipologia_attrezzature={this.getTipologie()}
                    setTipologia={(value)=>this.selectedTipologia(value)}

                    selectedAula={(value) => {
                        console.warn("Tutte le aule", this.props.sede.aule)
                        console.warn("Aula selezionata", value)

                        let tipologia_required = false;
                        this.props.sede.aule.map(aula => {
                            if(this.props.values && this.props.values.id_aula){
                                if(value === this.props.values.id_aula){
                                    const tipo_aula = this.getTipoAula(this, aula); 
                                    if(tipo_aula === 'Aula Informatica' || tipo_aula === 'Aula Corso'){
                                        tipologia_required = true;
                                    }
                                }
                            }
                            //console.warn("Aula", aula)
                            //console.warn("Tipo Aula", getTipoAula(this, aula))
                            return null;
                        })

                        this.setState({ selected_aula: value, tipologia_required: tipologia_required });
                        this.formRef.props.form.setFields({
                            tipologia: {
                                value: null
                            }
                        })
                    }}

                    setTitoloPossesso={() => {
                        //console.log('selezionato possesso', value)
                    }}

                    aule={this.props.sede.aule.map((aula) => {
                        if (getTipoAula(this, aula) == "laboratorio" && aula.id == this.props.values.id ) {
                          return {
                            value: aula.id,
                            label: aula.nome_aula, // https://cb.schema31.it/cb/issue/420943 + " " + getTipoAula(this, aula),
                            key: "aula_" + aula.id,
                          };
                        } else return {};
                      })}

                    files={() => this.state.fileList}

                    onRemove={() => {

                        this.setState({
                            fileList: [],
                        });

                    }}

                    customUploadRequest={(obj, tipo_media) => {
                        this.setState({
                            fileList: [{
                                chiave: 'file',
                                uid: obj.file.uid,
                                name: obj.file.name,
                                file: obj.file,
                                status: 'done',
                                tipo_media: tipo_media
                            }]
                        });
                    }}

                    getComune={() => {
                        //console.log('GET COMUNE NASCITA', this.state.comuni);
                        return this.state.comuni.map(city => {
                            return (
                                <Option key={"comune_" + city.id} value={'' + city.comune}>
                                    {city.comune}
                                </Option>
                            );
                        });
                    }}

                    searchingComune={async value => {
                        if (
                            !this.state.searching_comuni
                        ) {
                            this.setState({ searching_comuni: false });
                            //console.log(this.state.selected_residenza_provincia)
                            ComuniService.list(
                                {
                                    id_regione: 18,
                                    comune: value,
                                },
                                res => {
                                    this.setState({
                                        comuni: res.data.data.list || [], //(cities.length > 0) ? cities.slice(0, 10) : [],
                                        searching_comuni: false,
                                    });
                                },
                                () => null
                            );
                        }
                    }}
                />
            </div>
        );
    }
}
export default UpdateAttrezzatura;