import React, {Component} from 'react';
//import { connect } from "react-redux";

import {message} from 'antd';
import moment from 'moment';
import CommonForm from '@common/components/form';
import domandaService from '../../services/Domanda';


class AttivaProgetto extends Component {

  state = {
    loading: false,
  }


  getFormLayout() {

    let form_rows = [];

    form_rows.push(

      {
        cols: [

          {
            span: 12,
            items: [
              {
                type: 'numberinput',
                label: 'Importo ammesso a finanziamento',
                type_string: 'text', // field type
                name: 'importo',
                max: 2000000000,
                props: {
                  placeholder: 'inserisci importo',
                  disabled: this.state.loading,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data entro la quale deve essere avviato il corso',
                name: 'data_presunta_avvio',
                format: 'DD/MM/YYYY',
                props: {
                  size: 'normal',
                  style: { width: '100%' },
                  disabled: this.state.loading,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_date'
                  },
                ],

              }
            ]
          },
        ],
      }

    );

    form_rows.push({
        cols: [
          {
            items: [
              {
                name: 'btn_attiva_progetto',
                type: 'submit',
                text: 'Attiva',
                //onClick: 'attiva',
                btn_type: 'primary'
              },
            ],
          },
        ],
      }
    );

    return {
      layout: 'vertical',
      multistep: false,
      rows: form_rows,
      onSubmit: 'attiva',
    }
  }


  submit(data) {
    if (!this.state.loading) {
      this.setState({ loading: true });

      domandaService.attivaProgetto(this.props.match.params.id, data,
         (res) => {
          //this.props.refresh(false);
          this.props.refreshInline(res.data.data)
          message.success("Il progetto è stato attivato con successo")
          this.setState({
            loading:false,
          });
        },
        err => this.setState({
          loading:false,
        })
      );
    }
  }

  render () {
    return <div>
      {
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={this.getFormLayout()}
          attiva={(data) => this.submit(data)}
          min_date={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            if (moment().diff(value, 'days') > 0) cb("La data entro la quale deve essere avviato il corso non può essere precedente alla data odierna");
            else cb();
          }}

        />
      }
    </div>
  }

}
export default AttivaProgetto;