import moment from 'moment';
import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';
//import checkPermissions from '@common/utils/check/permissions';
import ModelServices from '../services/QualificaProfessionale';
import _ from '@common/utils/formatters/_';
import TableComponent from '@common/components/table';


const model = (component) => {
  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'action',
        render: element => <div style={{ width: 90 }}>
          {
            //checkPermissions.hasPermission(component.props.UserStore.user, ['viewQualificaProfessionale']) &&
            <Tooltip placement="top" title="Visualizza">
              <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/repertorio/qualifiche/" + element.id_qualifica + "/storico/" + element.id)} />
            </Tooltip>
          }
        </div>,
      },
      {
        title: 'Data',
        dataIndex: '',
        sorter: false,
        key: 'created_at',
        render: element => <div style={{ width: 140 }}>
          {(element.created_at) ? moment.unix(element.created_at).format('DD/MM/YYYY HH:mm') : ''}
        </div>,
      },
    ]
  }
}


export default class TabellaStoricoQualificheProfessionali extends Component {
    default_order = '-created_at';
    base_url = '/repertorio/qualifiche/' + this.props.match.params.id;

    state = {
      loading: false,
      data: {
        total: 0,
        current: 1,
        data: []
      },
    }

    componentDidMount() {
      this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.tab !== this.props.tab) {
        this.refresh();
      }
    }

  /**
* Chiamato da sort in tabella
* @param  {[type]} obj [description]
* @return {[type]}     [description]
*/
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }



    /**
     * Carica dati
     * @param  {[type]} props [description]
     * @return {[type]}       [description]
     */
    fetch(props) {
      let { params, pager } = _.getFilterParam(props, this.default_order);
      this.setState({ loading: true });
      ModelServices.storico(params,this.props.match.params.id, res => {
        let data = _.getResponse(res);
        this.setState({
          loading: false,
          data: data
        }, () => this.refs.table_component.updateTableData(
          data.data,
          data.current,
          data.total,
          pager)
        );

      }, () => {
        this.setState({ loading: false });
      })
    }

    /**
     * Ricarica dopo update
     * @return {[type]} [description]
     */
    refresh() {
      this.fetch(this.props.location.search)
    }

    render() {
      return (
        <div>
          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
          />
        </div>
      );
    }
}