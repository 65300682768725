import { Tabs, Card } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';

import DatiIdentificativiForm from "../forms/procedura/DatiIdentificativiForm";
import PersonalizzazioneForm from "../forms/procedura/PersonalizzazioneForm";
import TabellaStoricoProcedure from "./TabellaStoricoProcedure";

const TabPane = Tabs.TabPane;

export default class SchedaProcedura extends Component {

  static propTypes = {
    isEditable: PropTypes.bool,
    refresh: PropTypes.func,
  }

  state = {
    current_key: "1",
  }

  componentDidMount() {
    //this.load(this.props.match.params.id);
    if (this.props.location.state?.tab) {
      this.setState({
        current_key: '' + this.props.location.state.tab,
      });
    }
  }

  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  isModified() {
    return true;
  }

  render() {
    return (<div style={{ marginTop: 12, marginBottom: 12 }}>{
      <Card
        bodyStyle={{paddingTop: 0, paddingBottom: 0}}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={(k) => this.setState({ current_key: "" + k })}
            defaultActiveKey={"1"}
            activeKey={this.state.current_key}
            size="medium"
            renderTabBar={this.renderTabBar}>
            <TabPane
              tab={<span>DATI IDENTIFICATIVI</span>} key="1">
              <div id="datiIdentificativi">
                <DatiIdentificativiForm
                  {...this.props}
                  tab={this.state.current_key}
                  id_procedura={this.props.match.params.id}
                />
              </div>
            </TabPane>
            {this.isModified() &&
              <TabPane
                tab={<span>STORICO MODIFICHE<br /></span>} key="2">
                <div id="storicoModifiche">
                  <TabellaStoricoProcedure
                    {...this.props}
                    tab={this.state.current_key}
                    id_procedura={this.props.match.params.id} />
                </div>
              </TabPane>
            }
            <TabPane
              tab={<span>PERSONALIZZAZIONE<br />FORMULARIO</span>} key="3">
              <div id="personalizzazione">
                <PersonalizzazioneForm
                  {...this.props}
                  tab={this.state.current_key}
                  id_procedura={this.props.match.params.id}
                />
              </div>
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Card>
    }</div>);
  }
}

