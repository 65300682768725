import React, { Component } from 'react';
import { Icon, message, notification } from 'antd';
import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';
import moment from 'moment';

import AccreditamentoRequest from '@accreditamento/services/Request';
import format from '@common/utils/formatters/_';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Nome',
                type_string: 'text', // field type
                name: 'nome',                
                props: {
                  size: 'large',
                  disabled: component.props.values.nome, // https://cb.schema31.it/cb/issue/420880
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: 'input',
                label: 'Cognome',
                type_string: 'text', // field type
                name: 'cognome',
                props: {
                  size: 'large',
                  disabled: component.props.values.cognome, // https://cb.schema31.it/cb/issue/420880
                  prefix: (
                    <Icon type="solution" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Stato di nascita',
                name: 'stato_nascita',
                props: {
                  size: 'large',
                  disabled: component.props.values.comune_nascita && component.props.values.from_spid, // https://cb.schema31.it/cb/issue/420880
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'stato',
                onChange: 'selectedStatoNascita',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Provincia di nascita',
                name: 'provincia_nascita',
                props: {
                  size: 'large',
                  disabled: component.props.values.comune_nascita && component.props.values.provincia_nascita, // https://cb.schema31.it/cb/issue/420880
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'province',
                onChange: 'selectedNascitaProvincia',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Comune di nascita',
                name: 'comune_nascita',
                props: {
                  size: 'large',
                  disabled: component.props.values.comune_nascita, // https://cb.schema31.it/cb/issue/420880
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options_props: 'comuni_nascita'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'datepicker',
                label: 'Data di nascita',
                name: 'nato_il',
                props: {
                  size: 'large',
                  disabled: component.props.values.nato_il, // https://cb.schema31.it/cb/issue/420880
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'age18Validator'
                  }
                ]
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Codice fiscale',
                type_string: 'text', // field type
                name: 'cf',
                props: {
                  size: 'large',
                  disabled: component.props.values.cf, // https://cb.schema31.it/cb/issue/420880
                  prefix: <Icon type="copy" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^[A-z0-9]{16}$',
                    message: 'Codice fiscale non valido'
                  },
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Telefono',
                name: 'telefonolegale',
                props: {
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,20})$',
                    message: 'Telefono non valido'
                  },
                ],
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'titolo_residenze',
                type: 'title',
                element: 'h3',
                text: 'Indirizzo di residenza',
                props: {
                  style: { textAlign: 'center' },
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 14,
            items: [
              {
                type: 'input',
                label: 'Via',
                type_string: 'text', // field type
                name: 'vialegale',
                props: {
                  size: 'large',
                  prefix: (
                    <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'input',
                label: 'N',
                type_string: 'text', // field type
                name: 'civicolegale',
                props: {
                  size: 'large',
                  placeholder: 'N.',
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: 'caplegale',
                props: {
                  size: 'large',
                  placeholder: 'Cap',
                  maxLength: 5,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9]{5,5}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'statolegale',
                    operator: '==',
                    value: 1,
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: 'caplegale',
                props: {
                  size: 'large',
                  placeholder: 'Cap',
                  maxLength: 10,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9a-zA-Z]{4,10}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'statolegale',
                    operator: '!=',
                    value: 1,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Nazione',
                name: 'statolegale',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'stato',
                onChange: 'selectedStatoResidenza',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                name: 'provincialegale',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'statolegale',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'province',
                onChange: 'selectedResidenzaProvincia',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Comune di residenza',
                name: 'comunelegale',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'statolegale',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options_props: 'comuni_residenza'
              },
            ],
          }
        ],
      }
    ],
    onSubmit: 'submitted',
  }
};

class ProfileForm extends Component {
  state = {
    loading: false,
    fileList: [],
    searching_comuni_residenza: false,
    selected_residenza_provincia: null,
    comuni_residenza: [],
    searching_comuni_nascita: false,
    selected_nascita_provincia: null,
    comuni_nascita: [],
    stato: [],
    medias: [],
  };

  componentDidMount() {

    const search = this.props.location.search;

    const nome    = new URLSearchParams(search).get("nome");
    const cognome = new URLSearchParams(search).get("cognome");
    const cf      = new URLSearchParams(search).get("cf");
    const birthdate     = new URLSearchParams(search).get("birthdate");
    const mobile_number = new URLSearchParams(search).get("mobile_number");
    const birthprovince = new URLSearchParams(search).get("birthprovince");
    const locality      = new URLSearchParams(search).get("locality");

    const from_spid     = new URLSearchParams(search).get("from_spid");

    if(birthdate){
      const birthdateArray = birthdate.split("/");
      this.props.values.nato_il = birthdateArray[2] + '-' + birthdateArray[1] + '-' + birthdateArray[0];
    }

    this.props.values.nome    = nome;
    this.props.values.cognome = cognome;
    this.props.values.cf      = cf;    
    this.props.values.telefonolegale = mobile_number;

    this.props.values.from_spid  = from_spid;

    if(birthprovince && (birthprovince != -1)) {
      this.props.values.provincia_nascita = parseInt(birthprovince);
    }

    // carico le province
    //setTimeout(() => {
    //console.log('lo monto', this.props)

    if (this.props.values && this.props.values.provincialegale) {
      ComuniService.all(
        { id_provincia: this.props.values.provincialegale },
        res => {
          this.setState({
            comuni_residenza: res.data.data || []
          },() => {
            this.formRef.props.form.setFieldsValue({ comunelegale: this.props.values.comunelegale });
          });
        },
        err => null
      );
    }

    if (this.props.values && this.props.values.provincia_nascita) {
      ComuniService.all(
        { id_provincia: this.props.values.provincia_nascita },
        res => {
          this.setState({
            comuni_nascita: res.data.data || []
          },() => {
            this.formRef.props.form.setFieldsValue({ comune_nascita: this.props.values.comune_nascita });
          });        
        },
        err => null
      );
      if(locality && (locality != -1)) {
        this.props.values.comune_nascita = parseInt(locality);
      }         
    }
   
    //}, 0)
  }




  async clientValidation() {
    return new Promise( (resolve, reject) => {
      this.formRef.props.form.validateFieldsAndScroll((errors) => {
          if (!errors || errors.length === 0) {
            resolve(true);
          } else {
            message.error('Ricontrolla i campi');
            resolve(false);
          }
        });
    });
  }

  async validate() {
    let cl_val = await this.clientValidation();
    if(!cl_val) return false;
    let values = this.getValues( true );
    if(!values) return false;

    return new Promise( (resolve) => {
      AccreditamentoRequest.validateProfile(format.replaceFormDatesOut(['nato_il'], values), () => {
        notification.destroy();
        resolve(true);
      }, () => {
        resolve(false);
      })
    });
  }

  getValues(with_validation = false) {

    let valori_form = this.formRef.props.form.getFieldsValue();

    return {...valori_form, medias: [...this.props.values.medias]};
  }

  /*updateMedias(media) {
    try{
      if (this.props.onUpload) this.props.onUpload(media)
    } catch(e) {
      console.error(e)
    }

  }*/

  /*deleteMedia(id) {
    try{
      if (this.props.onDeleteMedia) this.props.onDeleteMedia(id)
    } catch(e) {
      console.error(e)
    }

  }*/

  submitted(data) {
    this.props.onSubmit(data);
  }

  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={{
            ...this.props.values,
          }}
          province={this.props.province}
          comunelegale={this.props.comunelegale}
          comune_nascita={this.props.comune_nascita}
          stato={this.props.GlobalStore.stubs.nazione}
          nazione_estera={this.props.nazione_estera}

          age18Validator={(rule, value, cb) => {
            var today = moment();
            if (today.diff(value, 'years') < 18) cb("Il soggetto richiediente deve essere maggiorenne");
            else cb()
          }}

          selectedStatoNascita={(data)=>{
            this.formRef.props.form.setFieldsValue({ 'comune_nascita': null, 'provincia_nascita': null })
          }}

          selectedStatoResidenza={(data)=>{
            this.formRef.props.form.setFieldsValue({ 'comunelegale': null, 'provincialegale': null })
          }}
          selectedResidenzaProvincia={data => {
            //console.log (this.formRef.props.form);

            this.setState({
              selected_residenza_provincia: data,
              comuni_nascita: []
            }, () => {
              this.formRef.props.form.setFieldsValue({ comunelegale: null });
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni_residenza: res.data.data || []
                  },()=>{
                    this.formRef.props.form.setFieldsValue({ comunelegale: null });
                  });
                },
                err => null
              );
            });

          }}

          selectedNascitaProvincia={data => {

            this.setState({
              selected_nascita_provincia: data,
              comuni_nascita: []
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune_nascita: null });
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni_nascita: res.data.data || []
                  },()=>{
                    this.formRef.props.form.setFieldsValue({ comune_nascita: null });
                  });
                },
                err => null
              );
            });

          }}

          comuni_nascita={this.state.comuni_nascita.map(e=>{
            return {
              key: 'nascita_comune_'+e.id,
              value: e.id,
              label: e.comune
            }
          })}

          comuni_residenza={this.state.comuni_residenza.map(e=>{
            return {
              key: 'residenza_comune_'+e.id,
              value: e.id,
              label: e.comune
            }
          })}



        />
      </div>
    );
  }
}

export default ProfileForm;
