import React, {Component} from 'react';
//import { connect } from "react-redux";

import {Icon, message} from 'antd';

import ModelServices from '../services/User';

import CommonForm from '@common/components/form';

class CreateModel extends Component {

  state = {
    loading: false,
    open_drawer: false
  }


  getFormLayout() {

    let form_rows = [];
    form_rows.push({
            cols: [
              {
                span: 24,
                items: [
                  {
                    type: 'input',
                    label: 'Username',
                    type_string: 'text', // field type
                    name: 'username',
                    props: {
                      size: 'large',
                      prefix: <Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />,
                    },
                    validations: [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      },
                    ],
                  },
                ],
              },
            ],
          });
     form_rows.push({
            cols: [
              {
                span: 24,
                items: [
                  {
                    type: 'email',
                    label: 'Email',
                    name: 'email',
                    props: {
                      size: 'large',
                      prefix: <Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />,
                    },
                    validations: [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      },
                    ],
                  },
                ],
              },
            ],
          });

    //  form_rows.push({
    //         cols: [
    //           {
    //             span: 24,
    //             items: [
    //               {
    //                 type: 'input',
    //                 label: 'Password',
    //                 type_string: 'password', // field type
    //                 name: 'password',
    //                 props: {
    //                   size: 'large',
    //                   prefix: <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />,
    //                 },
    //                 validations: [
    //                   {
    //                     type: 'required',
    //                     error_message: 'Campo obbligatorio',
    //                   },
    //                 ],
    //               },
    //             ],
    //           },
    //         ],
    //       });

    //  if(!this.props.role && this.props.roles.length > 0) {
    //    form_rows.push({
    //         cols: [
    //           {
    //             items: [
    //               {
    //                 type: 'select',
    //                 label: 'Ruolo',
    //                 name: 'role_name',
    //                 options: this.props.roles.map(r => {
    //                   return {
    //                     label: r.name,
    //                     value: r.name
    //                   }
    //                 }),
    //                 validations: [
    //                   {
    //                     type: 'required',
    //                     error_message: 'Campo obbligatorio',
    //                   },
    //                 ],
    //               }
    //             ]
    //           },
    //         ],
    //       });
    //  }

       form_rows.push({
            cols: [
              {
                items: [
                  {
                    name: 'btn',
                    type: 'submit',
                    text: 'Conferma',
                    btn_type: 'primary'
                  },
                ],
              },
            ],
          });

    return {
        layout: 'vertical',
        multistep: false,
        rows: form_rows,
        onSubmit: 'submitted',
      }
  }

  async submit(data) {

    if(!this.state.loading) {
      await this.setState({loading: true});

      data.id_anagrafica = this.props.soggetto.id_anagrafica;
      data.role_name = this.props.soggetto.ruolo;

      ModelServices.createByEnte(data, () => {
        message.success("Utente creato con successo");
        this.setState({loading: false},()=>{
          this.props.onCreate();
        });

      }, () => {
        this.setState({loading: false})
      })


    }
  }

  render () {
    return <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={this.getFormLayout()}
          values={{
            ...this.state.model
          }}

          submitted={(data)=>this.submit(data)}
        />
    </div>
  }

}
export default CreateModel;