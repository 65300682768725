import React, { Component } from 'react';
import moment from 'moment';
import { message } from 'antd';
import CommonForm from '@common/components/form';
import EfficaciaServices from '@accreditamento/services/Efficacia';

const formLayout = () => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Tipologia',
                name: 'id_tipo_accreditamento',
                props: {
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'tipo_accreditamento',
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Anno',
                name: 'anno',
                props: {
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'anni',
              },
            ],
          }
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'integer',
                label: 'Ore partecipate autorizzate',
                name: 'ore_autorizzate',
                props: {
                  min: 1,
                  max: 2000000000,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  //{
                  //  pattern: '^[1-9]{1}[0-9]*$',
                  //  message: 'Inserire un valore numerico'
                  //}
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'integer',
                label: 'Ore partecipate rendicontate',
                name: 'ore_rendicontate',
                props: {
                  min: 1,
                  max: 2000000000,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  //{
                  //  pattern: '^[1-9]{1}[0-9]*$',
                  //  message: 'Inserire un valore numerico'
                  //}
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            props: {

              style: { float: 'right' }
            },
            items: [
              {
                type: 'submit',
                name: 'btn_submit_attrezzatura',
                text: 'Salva',
                //onClick: 'showDrawer',
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
}

class Create extends Component {

  state = {
    loading: false,
    loaded: false,
    fileList: [],
    comuni: [],
    selected_aula: null
  };

  async submitFormData(data) {

    if (data.ore_rendicontate > data.ore_autorizzate) {
      message.error('Le ore rendicontate sono troppe');
      return;
    }

    if (!this.state.loading) {
      await this.setState({ loading: true });
      EfficaciaServices.addCorsiFinanziati(this.props.accreditamento.id, data, () => {
        this.props.onCreate();
        this.setState({ loading: false })
        this.reset();
      }, () => this.setState({ loading: false }))
    }

  }

  getTipoAccreditamento() {
    return this.props.tipo_accreditamento.map(e => {
      return {
        key: e.tipologia.id,
        label: e.tipologia.tipologia,
        value: e.tipologia.id
      }
    })
  }

  getAnni() {
    let years = [];
    let current = parseInt(moment().format('YYYY'));

    for (let i = 0; i <= 4; i++) {
      let curr = current - i;
      years.push({
        key: 'anno_' + curr,
        value: curr,
        label: '' + curr
      })
    }

    return years;
  }

  reset() {
    this.formRef.props.form.resetFields();
  }

  render() {

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout()}
          values={{}}
          readOnly={this.props.readOnly}
          onSubmit={(data) => this.submitFormData(data)}

          tipo_accreditamento={this.getTipoAccreditamento()}
          anni={this.getAnni()}
        />
      </div>
    );
  }
}
export default Create;