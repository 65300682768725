import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Link
} from 'react-router-dom'
import { Button, Card, Modal } from 'antd';

import ModelServices from '@accreditamento/services/Nomina';
import moment from 'moment';


import checkPermission from '@common/utils/check/permissions';
import nominaStatic from '@common/utils/static/nomina';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';

import FileSaver from 'file-saver';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'simple_upload',
                name: 'dragger',
                accept: '.p7m,.pdf',
                action: '/', // url upload
                listType: 'text', // text,picture,picture-card
                multiple: false,
                fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
                customRequest: 'customUploadRequest', // sovrascrive upload
                onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
                text: "Carica verbale firmato",
                props: {
                  size: 'small',
                  loading: component.state.loading,
                  showUploadList: false
                }
              },
            ],
          },
        ],
      },
    ],
    onSubmit: 'submitted',
  }
}

const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};

class SingleVerbale extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },
    open_modal_ispettori: false
  }

  static propTy

  componentDidMount() {
    if(this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    if(newProps.accreditamento.id &&
      (newProps.accreditamento !== this.props.accreditamento )) this.loaded(newProps)
  }

  loaded( props ) {
    this.setState({accreditamento: props.accreditamento, loaded: true});
  }

  getMediaUploaderName(media) {
    if ( media.uploaded_by === this.props.ispezione.id_ispettore_1 ) return 'ispettore 1';
    if ( media.uploaded_by === this.props.ispezione.id_ispettore_2 ) return 'ispettore 2';

    return '';
  }

  renderVerbaleFirmato() {

    return <div style={{marginTop: 18}}>
      {
        this.props.verbale && this.props.verbale.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_verbale_firmato).map(l => {
          return <div key={l.id}><p style={{display: 'inline-block', marginBottom: 0}}>Verbale firmato {/* task 419097 da {this.getMediaUploaderName(l)} */}: </p><DocSpan key={l.id} file={l} /></div>
        })
      }
      {
        this.props.verbale && this.props.verbale.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_verbale_controfirmato).map(l => {
          return <div key={l.id}><p style={{display: 'inline-block'}}>Verbale firmato dall&lsquo;ente: </p><DocSpan key={l.id} file={l} /></div>
        })
      }
    </div>
  }

  /**
   * Carica il file
   * @return {[type]} [description]

  upload() {

    if(!this.state.loading) {
      this.setState({loading: true}, () => {

        ModelServices.upload_verbale(this.state.accreditamento.id, this.props.ispezione.id, this.props.verbale.id, {
          filename: 'file',
          note: this.state.note || ""
        }, { ...this.state.file, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.setState({ open_modal_doc: false, loading: false });

        }, (err) => this.setState({ open_modal_doc: false, loading: false }))

      })

    }
  }*/
  /*
  uploadVerbaleControfirmato() {

    if(!this.state.loading) {
      this.setState({loading: true}, () => {

        ModelServices.uploadVerbaleControfirmato(this.state.accreditamento.id, this.props.ispezione.id, this.props.verbale.id, {
          filename: 'file',
          note: this.state.note || ""
        }, { ...this.state.file, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.setState({ open_modal_doc: false, loading: false });

        }, (err) => this.setState({ open_modal_doc: false, loading: false }))

      })

    }
  }*/

  async uploadFile(file) {

    if(!this.state.loading) {
      this.setState({loading: true}, () => {

        ModelServices.upload_verbale(this.state.accreditamento.id, this.props.verbale.id, {
          filename: 'file'
        }, { file: file, chiave: 'file' }, () => {

          this.props.refreshAll();
          this.setState({ loading: false });

        }, () => this.setState({ loading: false }))

      })

    }

  }

  isIstruttore() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) &&
    this.props.accreditamento.istruttore && this.props.accreditamento.istruttore[0].id === this.props.UserStore.user.id
    ? true : false
  }

  isIspettore() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['ispettorePermissions']) &&
    (this.props.ispezione.id_ispettore_1 === this.props.UserStore.user.id || this.props.ispezione.id_ispettore_2 === this.props.UserStore.user.id)
    ? true : false
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) &&
    this.props.accreditamento.id_user === this.props.UserStore.user.id
    ? true : false
  }


  canView() {
    return (
      checkPermission.hasPermission(
      this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ||
      (
        this.isIstruttore()
      ) ||
      (
        this.isEnte()
      ) ||
      (
        checkPermission.hasPermission(
          this.props.UserStore.user, ['ispettorePermissions']) &&
          (this.props.ispezione.id_ispettore_1 === this.props.UserStore.user.id || this.props.ispezione.id_ispettore_2 === this.props.UserStore.user.id)
      )
      ) ? true : false
  }

  canViewNominaDetails() {
    return (checkPermission.hasPermission(
      this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ||
      (
        checkPermission.hasPermission(
          this.props.UserStore.user, ['ispettorePermissions']) &&
          (this.props.ispezione.id_ispettore_1 === this.props.UserStore.user.id || this.props.ispezione.id_ispettore_2 === this.props.UserStore.user.id)
      )
    ) ? true : false
  }

  saveBlob( blob ) {
    const _blob = new Blob([blob], {type: 'application/pdf'});
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `verbale_${date}${month}${year}_${hour}${minu}.pdf`;
    FileSaver.saveAs(_blob, nome_file);

    //this.props.refresh();
  }

  saveBlobOdt( blob ) {
    const _blob = new Blob([blob], {type: 'application/vnd.oasis.opendocument.text' });
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `verbale_${date}${month}${year}_${hour}${minu}.odt`;
    FileSaver.saveAs(_blob, nome_file);

    //this.props.refresh();
  }

  canDownloadUpload() {

    if(this.props.verbale.stato !== nominaStatic.stato_verbale_ispezione.terminato) return false;
    
    if(this.isIspettore()) {
      //https://cb.schema31.it/cb/issue/407208
      if(this.props.ispezione.id_ispettore_1 === this.props.UserStore.user.id && this.props.verbale.firmato_ispettore_1) return false;
      if(this.props.ispezione.id_ispettore_2 === this.props.UserStore.user.id && this.props.verbale.firmato_ispettore_2) return false;      
      return true;
    } else
    if(this.isEnte()) {

      /* https://cb.schema31.it/cb/issue/420472 */
      return false;

      
      /**
       * All'ente lo faccio mandare dopo che hanno firmato entrambi gli ispettori
       */
      if(!this.props.verbale.firmato_ispettore_1 ||
        !this.props.verbale.firmato_ispettore_2) {
        return false;
      }

      if(this.props.verbale.firmato_ente) return false;

      return true;
    }

    return false;
  }

  renderActions() {
    return <div>
          <Button size="small" disabled={this.state.loading} loading={this.state.loading} className="m5h" type="primary" icon="file-word" onClick={()=> {
            if(!this.state.loading) {
              this.setState({loading: true}, () => {

                ModelServices.download_verbale(
                  this.state.accreditamento.id,
                  this.props.verbale.id,
                  (res)=> {

                    this.setState({loading: false});
                    this.saveBlobOdt(res.data);
                  },
                  () => this.setState({loading: false})
                )

              });
            }

          }}>Scarica verbale odt</Button>
      {
        (this.canDownloadUpload())
        ?
        <React.Fragment>
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout(this)}
            values={{
            }}
            fileListProp={() => []}
            customUploadRequest={obj => {
              this.uploadFile(obj.file);
            }}
          />
        </React.Fragment>
        : null}
      {/*
        (this.props.verbale && this.props.verbale.stato == nominaStatic.stato_verbale_ispezione.da_firmare)
        ?
        <React.Fragment>
            <p style={{marginTop: 16}}>Carica il file contenente la firma</p>
            <CommonForm
              wrappedComponentRef={inst => (this.formRef = inst)}
              loading={this.state.loading || false}
              form_model={formLayout}
              values={{
              }}
              fileListProp={() => []}
              customUploadRequest={obj => {
                this.uploadFile(obj.file);
              }}
            />
            <Modal
              title="Inserisci note facoltative"
              visible={this.state.open_modal_doc}
              onOk={async () => {
                this.setState({
                  filename: 'file',
                  note: this.formSelRef.props.form.getFieldsValue()['note'] || ""
                }, () => this.upload())
              }}
              onCancel={() => this.setState({ open_modal_doc: false })}
            >
              <CommonForm
                wrappedComponentRef={inst => (this.formSelRef = inst)}
                loading={this.state.loading || false}
                form_model={formSelLayout}
                values={{

                }}

              />
            </Modal>
        </React.Fragment>
        : null
      */}
      {/*
        (this.props.verbale && this.props.verbale.stato == nominaStatic.stato_verbale_ispezione.firmato)
        ?
        <div>
          <p>Conferma il verbale</p>
          <Button
          loading={this.state.loading} disabled={this.state.loading}
          className="m5h" type="primary" icon="file" onClick={()=> {
            if(!this.state.loading) {
              this.setState({loading: true}, () => {
                ModelServices.confirm_verbale(
                  this.state.accreditamento.id,
                  this.props.ispezione.id,
                  this.props.verbale.id,
                  {},
                  (res)=> {
                    this.setState({loading: false})
                    this.props.refreshInline(res.data.data)
                  },
                  (err) => this.setState({loading: false})
                )
              })
            }
          }}>Conferma</Button>
        </div>
        : null
      */}
    </div>
  }

  getTiming() {
    return moment(this.props.nomina.verbale.data_trasmissione, 'YYYY-MM-DD').add(10, 'd').format('DD/MM/YYYY');
  }

  hasControdeduzioni() {
    return this.props.nomina.verbale.controdeduzioni ? true : false
  }

  getControdeduzioni() {
    return this.props.nomina.verbale.controdeduzioni
  }

  saveBlobWord(blob, nameFile) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, nameFile);
  }

  renderUploadVerbaleControfirmato() {
    return <React.Fragment>
            <p style={{marginTop: 16}}>Carica il verbale controfirmato digitalmente</p>
            <CommonForm
              wrappedComponentRef={inst => (this.formRef = inst)}
              loading={this.state.loading || false}
              form_model={formLayout}
              values={{
              }}
              fileListProp={() => []}
              customUploadRequest={obj => {
                this.uploadFile(obj.file);
              }}
            />
            <Modal
              title="Inserisci note facoltative"
              visible={this.state.open_modal_doc}
              onOk={async () => {
                this.setState({
                  filename: 'file',
                  note: this.formSelRef.props.form.getFieldsValue()['note'] || ""
                }, () => this.uploadVerbaleControfirmato())
              }}
              onCancel={() => this.setState({ open_modal_doc: false })}
            >
              <CommonForm
                wrappedComponentRef={inst => (this.formSelRef = inst)}
                loading={this.state.loading || false}
                form_model={formSelLayout}
                values={{

                }}

              />
            </Modal>
        </React.Fragment>
  }

  renderEnteActions() {
    return this.isEnte() && this.props.verbale.stato === nominaStatic.stato_verbale_ispezione.confermato ?
      <div>
        <p>Data scadenza per invio controdeduzioni: {this.getTiming()}</p>
        {
          (!this.hasControdeduzioni() || this.getControdeduzioni().stato === nominaStatic.stato_controdeduzioni.confermata)
          ?
          this.renderUploadVerbaleControfirmato()
          : null
        }
      </div>
      : null

  }


  render() {
    return (this.canView() && this.props.verbale) ?
      <Card className="shadow no-pad" bordered={false}>
        <p style={{marginBottom: 0}}>Sede: <strong>{this.props.verbale.sede.indirizzo.full_address}</strong></p>
        {
          //  JayBee_#2108
          !this.isEnte() || (this.isEnte() && this.props.verbale.firmato_ispettore_1 && this.props.verbale.firmato_ispettore_2)
          ?
          <React.Fragment>
            <Link className="color-green login-form-forgot" to={"/accreditamento/" + this.state.accreditamento.id + "/ispezione/" + this.props.ispezione.id + "/verbale-ispezione/" + this.props.verbale.id}>
              <strong>Verbale ispezione -&gt;</strong>
            </Link>
          </React.Fragment>
          :
          null
        }
        <div style={{marginTop: 16, marginBottom: 8}}>{this.renderVerbaleFirmato()}</div>
        {this.props.verbale.note && this.props.verbale.note.length > 0 ?
          <div>
          <h4>Note</h4>
          {this.props.verbale.note.map(n => {
            return <div key={"nota_"+n.id}><small><strong>{(n.user) ? n.user.username : " - "}:</strong> {n.note}</small></div>
          })}
          </div> : null}
        {(this.isIspettore() || this.isEnte()) ? this.renderActions(): null}
      </Card>
    :
    null
  }
}

const SingleVerbaleExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SingleVerbale);

export default withRouter(SingleVerbaleExport);
