import React, { Component } from 'react';
import { Button, Tooltip, Row, Popconfirm, Drawer, message } from 'antd';
import affidamentoStatic from '@common/utils/static/affidamento';
import TableComponent from '@components/table';
import CommonForm from '@components/form';


import ModelServices from '@affidamento/services/Programma';
import LezioneServices from '@affidamento/services/Lezioni';

import checkPermission from '@common/utils/check/permissions';

const renderActions = (element, component) => {
  return <div style={{ display: 'inline-block' }}>
    {component.canEditProgramma() &&
      <Tooltip placement="top" title="Modifica argomento">
        <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.editTopic(element)} />
      </Tooltip>
    }

    {component.canDeleteProgramma() && component.isEditableState() && 
      <Tooltip placement="top" title="Elimina argomento">
        <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere?" onConfirm={() => component.deleteTopic(element.id)} okText="Si" cancelText="No">
          <Button className="m2w" size="small" shape="circle" icon="delete" />
        </Popconfirm>
      </Tooltip>
    }
  </div>
}

const model = (component) => {

  return {
    with_server_pagination: false,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "50px",
        render: element => <div style={{ width: 140 }}>
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Dalle',
        dataIndex: '',
        sorter: false,
        key: 'dalle',
        render: element => <div style={{ width: 60 }}>
          {element.dalle || ''}
        </div>
      },
      {
        title: 'Alle',
        dataIndex: '',
        sorter: false,
        key: 'alle',
        render: element => <div style={{ width: 60 }}>
          {element.alle || ''}
        </div>
      },
      {
        title: 'Area',
        dataIndex: '',
        sorter: false,
        key: 'area',
        render: element => <div>
          {element.area || ''}
        </div>
      },
      {
        title: 'Argomento',
        dataIndex: '',
        sorter: false,
        key: 'argomento',
        render: element => <div>
          {element.argomento || ''}
        </div>
      },
    ]
  };
}


const formLayout = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows:
      [
        {
          cols:
            [
              {
                items:
                  [
                    {
                      type: 'textarea',
                      name: 'descrizione_programma',
                      label: component.props.lesson?.stage? 'Descrizione programma dello stage' : 'Descrizione programma della lezione',
                      placeholder: 'Descrizione breve',
                      props: {
                        autoSize: { minRows: 3, maxRows: 5 },
                      }
                    },
                  ],
              },
            ],
        },
        {
          props: {
            style: { float: 'right' }
          },
          cols:
            [
              {
                items:
                  [
                    {
                      span: 2,
                      name: 'submit',
                      type: 'submit',
                      text: 'Modifica',
                      btn_type: 'primary',
                      props: {
                        icon: 'save',
                        className: 'bg-green'
                      },
                      showIf: [
                        {
                          type: 'custom_func',
                          func: () => { return (component.canEditLezione() ||  component.canEditProgramma()) && component.isEditableState() }
                        }
                      ],
                    }
                  ]
              }
            ]
        }
      ],
    onSubmit: 'submit',
  };
}


const lessonFormLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                label: 'Dalle',
                name: 'dalle',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                label: 'Alle',
                name: 'alle',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Area',
                name: 'area',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Argomento',
                name: 'argomento',
              }
            ]
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
};

class ProgrammaLezione extends Component {

  default_order = 'id'

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
    open_drawer: false,
  }

  componentDidMount() {
    this.fetch();
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {

    if (!this.props.lesson) {
      return;
    }

    this.setState({ loading: true });

    ModelServices.list({}, this.props.lesson.id, res => {
      this.setState({
        loading: false,
        data: {
          total: parseInt(res.headers['x-pagination-total-count']),
          current: parseInt(res.headers['x-pagination-current-page']),
          data: res.data.data.list
        }
      }, () => this.refs.table_component.updateTableData(
        this.state.data.data,
        this.state.data.current,
        this.state.data.total,
        { key: this.default_order, order: null })
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  isEditableState() {
    return this.props.lezione && this.props.lezione.progetto && this.props.lezione.progetto.stato_flusso !== affidamentoStatic.stato_flusso_progetto.progetto_completato &&  
    this.props.lezione.progetto.stato_flusso !== affidamentoStatic.stato_flusso_progetto.revoca_corso &&
    this.props.lezione.progetto.stato_flusso !== affidamentoStatic.stato_flusso_progetto.conferma_revoca &&
    this.props.lezione.progetto.stato_flusso !== affidamentoStatic.stato_flusso_progetto.corso_sospeso &&
    this.props.lezione.progetto.stato_flusso !== affidamentoStatic.stato_flusso_progetto.conferma_sospeso
  }

  deleteTopic(id) {
    this.setState({ loading: true });
    ModelServices.delete(this.props.lesson.id, id,
      res => {
        this.setState({
          loading: false,
        }, () => {
          this.fetch();
        })
      }, () => {
        this.setState({ loading: false });
      }
    );
  }

  addTopic() {
    this.setState({ open_drawer: true, editTopic: null });
  }

  editTopic(topic) {
    this.setState({ open_drawer: true, editTopic: topic });
  }

  canAddProgramma() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['addMyTopic']) ? this.props.lezione?.docente?.id === this.props.UserStore.user.anagrafica.id : checkPermission.hasPermission(this.props.UserStore.user, ['addTopic']);
  }

  canEditProgramma() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['editMyTopic']) ? this.props.lezione?.docente?.id === this.props.UserStore.user.anagrafica.id : checkPermission.hasPermission(this.props.UserStore.user, ['editTopic']);
  }

  canEditLezione() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['editLezione']);
  }

  canDeleteProgramma() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['deleteMyTopic']) ? this.props.lezione?.docente?.id === this.props.UserStore.user.anagrafica.id : checkPermission.hasPermission(this.props.UserStore.user, ['deleteTopic']);
  }


  render() {
    return (
      <div>
        <CommonForm
          readOnly={!(this.isEditableState() && (this.canEditLezione() || this.canEditProgramma()))} 
          wrappedComponentRef={inst => (this.formNoteRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={this.props.lezione}
          submit={data => {
            this.setState({ loading: true });
            LezioneServices.editDescrizioneLezione(data, this.props.lezione.id,
              (res) => {
                this.setState({ loading: false });
                message.success('Descrizione del programma modificata');
                this.props.updateCallback(res);
              },
              () => this.setState({ loading: false })
            );
          }}
        />

        <Row type="flex" justify="start" className="m10h">
          {this.canAddProgramma() && this.isEditableState() &&
            <div className="m5h m5w">
              <Button className="m5h bg-green" icon="plus" onClick={() => this.addTopic()} loading={this.state.loading}>Nuovo argomento della lezione</Button>
            </div>
          }
        </Row>

        <TableComponent
          data={this.state.data}
          model={model(this)}
          table_props={{ rowKey: 'id' }}
          size="medium"
          ref="table_component"
          loading={this.state.loading}
          with_server_pagination={false}
        />

        {this.state.open_drawer &&
          <Drawer
            title={this.state.editTopic ? "Modifica argomento" : "Aggiungi argomento"}
            placement="right"
            closable={true}
            onClose={() => this.setState({ open_drawer: false })}
            visible={this.state.open_drawer}
            width={1024}
            destroyOnClose={true}
          >
            <CommonForm
              readOnly={!this.isEditableState()}
              loading={this.state.loading || false}
              form_model={lessonFormLayout(this)}
              values={this.state.editTopic}
              onSubmit={(data) => {
                data = {
                  ...this.state.editTopic,
                  ...data,
                  dalle: data.dalle.format('HH:mm'),
                  alle: data.alle.format('HH:mm'),
                };
                this.setState({ loading: true });
                if (data.id) {
                  ModelServices.update(data, this.props.lesson.id, data.id,
                    res => {
                      this.setState({
                        editTopic: res,
                        open_drawer: false,
                        loading: false,
                      }, () => {
                        message.success('Argomento modificato');
                        this.fetch();
                      })
                    }, () => {
                      this.setState({ loading: false });
                    });
                } else {
                  ModelServices.create(data, this.props.lesson.id,
                    res => {
                      this.setState({
                        editTopic: res,
                        open_drawer: false,
                        loading: false,
                      }, () => {
                        message.success('Argomento aggiunto');
                        this.fetch();
                      })
                    }, () => {
                      this.setState({ loading: false });
                    });
                }
              }}
            />
          </Drawer>
        }
      </div>
    );
  }
}

export default ProgrammaLezione;
