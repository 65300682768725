import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import formatter from '@common/utils/formatters/_';
import eventBroker from '@common/utils/services/EventBroker';
import { Spin, message } from 'antd';
import affidamentoStatic from '@common/utils/static/affidamento';
import checkPermission from '@common/utils/check/permissions';
import Discenti from '../../components/Discenti/Discenti';

import PropTypes from 'prop-types';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';


//TODO: inserire subjectType come elemento del form visibile solo lato amministrazione regionale

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'elenco_discenti',
                type: 'title',
                element: 'h4',
                text: 'Elenco dei Discenti',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcLearnerListSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'elenco_discenti_table',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcLearnerListSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <Discenti
                    {...component.props}
                    learnerList={component.props.domanda.learner.AfcLearnerListSection?.learnerList}
                    saveLearnerList={(list) => component.saveLearnerList(list)}
                    savingLearner={component.state.savingLearner}
                  />
                </div>
              }
            ],
          },
        ],
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'radio',
                label: 'Voucher/buoni formativi',
                name: 'domanda.learner.AfcVoucherSection.beneficiary',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcVoucherSection ? true : false; }
                  },
                ],
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: '1',
                    label: "Si"
                  },
                  {
                    value: '0',
                    label: "No"
                  }
                ],
                props: {

                  className: 'm10h',
                }

              }
            ]
          },

        ]

      },


      {
        cols: [
          {
            items: [
              {
                name: 'inail',
                type: 'title',
                element: 'h4',
                text: 'Inail',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },


      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Posizione N°',
                type_string: 'text', // field type
                name: 'domanda.learner.AfcInailSection.positionNumber',
                props: {

                  placeholder: 'Posizione N°'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ],
                validations: [
                  {
                    pattern: '^[0-9][0-9]*$',
                    message: 'Inserire un valore numerico',
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'datepicker',
                label: 'Del',
                name: 'domanda.learner.AfcInailSection.del',
                format: 'DD/MM/YYYY',
                props: {
                  size: 'normal',
                  style: { width: '100%' },
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ]
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Allievi denunciati N°',
                type_string: 'text', // field type
                name: 'domanda.learner.AfcInailSection.declaredStudent',
                props: {
                  placeholder: 'Allievi denunciati N°',

                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ],
                validations: [
                  {
                    pattern: '^[0-0]{1}$|^[^0][0-9]*$',
                    message: 'Inserire un valore numerico valido',
                  }
                ],
              }
            ]
          }
        ]
      },

      {
        cols: [
          {
            items: [
              {
                name: 'periodo_assicurativo',
                type: 'title',
                element: 'h4',
                text: 'Periodo assicurativo',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Dal',
                name: 'domanda.learner.AfcInailSection.insurancePeriodsStart',
                format: 'DD/MM/YYYY',
                props: {
                  size: 'normal',
                  style: { width: '100%' },
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ],
                validations: [
                  {
                    validator: 'min_date'
                  }],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Al',
                name: 'domanda.learner.AfcInailSection.insurancePeriodsEnd',
                format: 'DD/MM/YYYY',
                props: {
                  size: 'normal',
                  style: { width: '100%' },
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ],
                validations: [
                  {
                    validator: 'max_date'
                  }],
              }
            ]
          }
        ]
      },



      {
        cols: [
          {
            items: [
              {
                type: 'button',
                name: 'btn_submit_soggetti_correlati',
                text: 'Salva i dati',
                onClick: 'saveData',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.anteprima && !component.props.readOnly; }
                  }
                ],
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    //onSubmit: 'submit',
  }
};


class DiscentiForm extends Component {

  constructor(props) {
    super(props);
    this.getBackLearner = this.getBackLearner.bind(this);
    eventBroker.on('getBackLearner', this.getBackLearner);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  }

  state = {
    loading: false,
    savingLearner: false,
    tabLoaded: true,
    dati: {
      domanda: {}
    }

  }

  componentDidMount() {
    this.setState({
      tabLoaded: false
    });
    this.load(this.props)
  }

  load(props) {
    this.setState({
      tabLoaded: true,
      dati: {
        domanda: props.domanda
      }
    });
  }


  prepareData(data) {
    if (this.props.domanda.learner.AfcInailSection) {
      let afcInailSection = formatter.replaceFormDatesOut(['del', 'insurancePeriodsStart', 'insurancePeriodsEnd'], data.domanda.learner.AfcInailSection, 'YYYY-MM-DD');
      data.domanda.learner.AfcInailSection = afcInailSection;
    }

    let domanda = this.props.domanda;
    domanda = {
      ...domanda, ...data?.domanda, learner: {
        ...domanda.learner, ...data?.domanda.learner
      }
    };
    return domanda;
  }

  getBackLearner() {
    return this.prepareData(this.formRef?.props.form.getFieldsValue());
  }

  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }


  /*async submit() {

    let valid_form = await this.validate();
    if (valid_form) {
      let data = this.formRef.props.form.getFieldsValue();
      const bkpDomanda = this.state.dati.domanda;
      if(data.length > 0){
        let domanda = this.prepareData(data);
        this.setState({
          loading: true,
          tabLoaded: false,
          dati: {
            domanda: domanda
          }
        });

        this.props.saveJson(domanda);
      }
    }


  }*/


  submit() {
    //let domanda = this.state.dati.domanda;

    let domanda = this.prepareData(this.formRef.props.form.getFieldsValue());
    this.props.saveJson(domanda);

  }

  saveLearnerList(list) {
    let domanda = this.props.domanda;
    //const bkpDomanda = domanda;
    domanda.learner.AfcLearnerListSection.learnerList = list;
    this.setState({
      dati: {
        domanda: domanda
      }
    });
  }

  getValues() {
    let dati = this.state.dati;
    if (dati.domanda && dati.domanda.learner && dati.domanda.learner.AfcInailSection) {
      dati.domanda.learner.AfcInailSection = formatter.replaceFormDatesIn(['insurancePeriodsEnd', 'insurancePeriodsStart', 'del'], dati.domanda.learner.AfcInailSection)
    }
    return dati
  }

  render() {
    return <div style={{ opacity: !this.props.saved ? 0.5 : 1 }}>
      {
        !this.props.saved ? <Spin /> : null
      }
      {checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
        <ValidazioneScheda
          canGiveFeedback={this.props.canGiveSectionFeedback && this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
          loadingFeedback={this.props.loadingFeedback}
          saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'learner')}
          feedback={this.props.section_feedback ? this.props.section_feedback['learner'] : null}
        />
      }
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.props.saving || false}
        readOnly={this.props.readOnly}
        form_model={formLayout(this)}
        saveData={() => this.submit()}
        //submit={(data) => this.submit(data)}
        values={this.getValues()}

        min_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let min = value;
          let max = this.formRef.props.form.getFieldsValue().domanda.learner.AfcInailSection.insurancePeriodsEnd;

          if (max && min > max) cb("Il campo dal deve essere minore di al");
          else cb()
        }}
        max_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let max = value;
          let min = this.formRef.props.form.getFieldsValue().domanda.learner.AfcInailSection.insurancePeriodsStart;

          if (min && min > max) cb("Il campo dal deve essere minore di al");
          else cb()
        }}
      />
    </div>
  }
}
export default DiscentiForm;