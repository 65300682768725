const listeners = [];

let activeRequests = 0;

/**
 * @component
 *
 * Utility per mostrare spinner in topbar quando presenti chiamate http in corso
 *
 * Si aggancia a XMLHttpRequest
 *
 * @param {Function} fn Funzione da inserire nel listener
 */
export default function subscribeRequestStatus(fn: Function) {
  listeners.push(fn);
}

function notifyListeners() {
  listeners.forEach(fn => fn(activeRequests > 0));
}

function trackRequestStarted() {
  activeRequests += 1;
  notifyListeners();
}

function trackRequestEnded() {
  activeRequests -= 1;
  notifyListeners();
}

const origSend = XMLHttpRequest.prototype.send;

XMLHttpRequest.prototype.send = function(...args) {
  
  trackRequestStarted();
  this.addEventListener('load', trackRequestEnded);
  this.addEventListener('error', trackRequestEnded);
  origSend.apply(this, args);
};
