import React, { Component } from 'react';

import { Icon, message } from 'antd';

import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';

export default class LearnerCourseRegistrationForm extends Component {

  state = {
    loading: false,
    comuni_nascita: [],
    comuni_residenza: [],
    residenza_same_domicilio_checked: true,
    value: {},
  }

  getFormLayout() {

    let form_rows = [];

    if (this.props.readOnly) {
      //Generalità
      form_rows.push(
        {
          cols: [
            {
              items: [
                {
                  name: 'generalita_header',
                  type: 'title',
                  element: 'h4',
                  text: 'Generalità',
                  props: {
                    size: 'large',
                    style: { textAlign: 'left' },
                    className: 'm10h'
                  }
                }
              ],
            }
          ],
        },
        {
          cols: [
            {
              span: 12,
              items: [
                {
                  type: 'input',
                  label: 'Nome',
                  type_string: 'text', // field type
                  name: '_name',
                  props: {
                    size: 'large',
                    disabled: true,
                    prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  },
                },
              ],
            },
            {
              span: 12,
              items: [
                {
                  type: 'input',
                  label: 'Cognome',
                  type_string: 'text', // field type
                  name: '_lastname',
                  props: {
                    size: 'large',
                    disabled: true,
                    prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  },
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              span: 24,
              items: [
                {
                  type: 'input',
                  label: 'Codice fiscale',
                  type_string: 'text', // field type
                  name: '_taxCode',
                  props: {
                    size: 'large',
                    disabled: true,
                    prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  },
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              span: 12,
              items: [
                {
                  type: 'datepicker',
                  label: 'Data di nascita',
                  name: '_birthDate',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                },
              ],
            },
            {
              span: 12,
              items: [
                {
                  type: 'select',
                  label: 'Sesso',
                  name: '_gender',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                  options: [
                    { key: 'M', value: 'M', label: 'M' },
                    { key: 'F', value: 'F', label: 'F' }
                  ],
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              span: 8,
              items: [
                {
                  type: 'select',
                  label: 'Stato di nascita',
                  name: '_birthPlace.stato_nascita',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                  options_props: 'nazioni',
                },
              ],
            },
            {
              span: 8,
              items: [
                {
                  type: 'select',
                  label: 'Provincia di nascita',
                  name: '_birthPlace.provincia_nascita',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                  options_props: 'province',
                },
              ],
            },
            {
              span: 8,
              items: [
                {
                  type: 'select',
                  label: 'Comune di nascita',
                  name: '_birthPlace.comune_nascita',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                  options_props: 'comuni_nascita'
                },
              ],
            }
          ],
        },
      );
    }

    //residenza
    form_rows.push(
      {
        cols: [
          {
            items: [
              {
                name: 'residenza_header',
                type: 'title',
                element: 'h4',
                text: 'Residenza',
                props: {
                  size: 'large',
                  style: { textAlign: 'left' },
                  className: 'm10h'
                }
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 14,
            items: [
              {
                type: 'input',
                label: 'Via',
                type_string: 'text', // field type
                name: '_residenceAddress.via_residenza',
                props: {
                  size: 'large',
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                  prefix: (
                    <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ]
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'input',
                label: 'N',
                type_string: 'text', // field type
                name: '_residenceAddress.ncivico_residenza',
                props: {
                  size: 'large',
                  placeholder: 'N.',
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ]
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: '_residenceAddress.cap_residenza',
                props: {
                  size: 'large',
                  placeholder: 'Cap',
                  maxLength: 5,
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Nazione',
                name: '_residenceAddress.stato_residenza',
                props: {
                  size: 'large',
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'nazioni',
                onChange: 'selectedStatoResidenza',
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                name: '_residenceAddress.provincia_residenza',
                props: {
                  size: 'large',
                  allowClear: true,
                  showSearch: true,
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'province',
                onChange: 'selectedProvinciaResidenza',
              },
            ],
          },
          {
            span: 14,
            items: [
              {
                type: 'select',
                label: 'Comune',
                name: '_residenceAddress.comune_residenza',
                props: {
                  size: 'large',
                  showSearch: true,
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'comuni_residenza'
              },
            ],
          },
        ],
      }
    );

    form_rows.push(
      {
        cols: [
          {
            items: [
              {
                name: 'other_info_header',
                type: 'title',
                element: 'h4',
                text: 'Altre Informazioni',
                props: {
                  size: 'large',
                  style: { textAlign: 'left' },
                  className: 'm10h'
                }
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Cittadinanza',
                type_string: 'text', // field type
                name: '_citizenship',
                options_props: 'cittadinanza',
                props: {
                  size: 'large',
                  disabled: this.props.registration_info?.stato > 1
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          }
        ]
      }
    );

    form_rows.push(
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Titolo di studio',
                type_string: 'text', // field type
                name: '_qualification',
                options_props: 'tipo_titolo_studio',
                props: {
                  size: 'large',
                  disabled: this.props.registration_info?.stato > 1
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Professione',
                name: '_profession',
                props: {
                  size: 'large',
                  disabled: this.props.registration_info?.stato > 1
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                type_string: 'text', // field type
                options_props: 'tipo_stato_lavorativo',
                //type: 'input',
                label: 'Stato lavorativo',
                name: '_employmentStatus',
                props: {
                  size: 'large',
                  disabled: this.props.registration_info?.stato > 1
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                type_string: 'text', // field type
                options_props: 'tipo_condizione_di_vulnerabilita',
                label: 'Condizione di vulnerabilià',
                name: '_vulnerableCondition',
                props: {
                  size: 'large',
                  disabled: this.props.registration_info?.stato > 1
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
    );

    form_rows.push({
      cols: [
        {
          items: [
            {
              name: 'btn',
              type: 'button',
              text: 'Conferma dati',
              onClick: 'compileRegistry',
              btn_type: 'primary',
              props: {
                disabled: this.props.compile_loading
              },
              showIf: [
                {
                  type: 'custom_func',
                  func: () => { return !this.props.registration_info || this.props.registration_info?.stato <= 1; }
                },
              ]
            },
          ],
        },
      ],
    });

    return {
      layout: 'vertical',
      multistep: false,
      rows: form_rows,
    }
  }

  componentDidMount() {
    this.setState({
      value: this.props.registration_info?.domanda_json,
      loading: true
    }, () => {
      this.loadComuni();
    })
  }

  loadComuni() {

    let req = [
      ComuniService.all({ id_provincia: this.state.value?._residenceAddress?.provincia_residenza }),
      ComuniService.all({ id_provincia: this.state.value?._birthPlace?.provincia_nascita }),
    ];

    Promise.all(req).then(res => {

      let [res1, res2] = res;

      this.setState(
        {
          comuni_residenza: res1.data.data || [],
          comuni_nascita: res2.data.data || [],
          loading: false,
        }
      );
    },
    ).catch(() => {
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.registration_info !== this.props.registration_info) {
      this.setState({
        value: this.props.registration_info?.domanda_json,
        loading: this.props.loading
      }, () => this.loadComuni())
    }
  }

  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  async submit() {
    let valid_form = await this.validate();
    if (valid_form && !this.props.compile_loading) {
      let data = this.formRef.props.form.getFieldsValue();
      this.props.onCompile && this.props.onCompile(data);
    }
  }

  render() {
    return <div>
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        form_model={this.getFormLayout()}
        readOnly={this.props.readOnly}

        nazioni={this.props.GlobalStore.stubs.nazione}
        province={this.props.GlobalStore.stubs.provincia}
        comuni_nascita={this.state.comuni_nascita.map(e => {
          return {
            key: 'nascita_comune_' + e.id,
            value: e.id,
            label: e.comune
          }
        })}
        comuni_residenza={this.state.comuni_residenza.map(e => {
          return {
            key: 'residenza_comune_' + e.id,
            value: e.id,
            label: e.comune
          }
        })}
        cittadinanza={this.props.GlobalStore.stubs.cittadinanza}
        tipo_titolo_studio={this.props.GlobalStore.stubs.tipo_titolo_studio}
        tipo_stato_lavorativo={this.props.GlobalStore.stubs.condizione_mercato_ingresso}
        tipo_condizione_di_vulnerabilita={this.props.GlobalStore.stubs.gruppo_vulnerabile_partecipante}

        values={this.state.value}
        compileRegistry={() => this.submit()}
      />
    </div>
  }
}