import { BackTop, Col, Layout, Menu, Row } from "antd";
import React from "react";
import LogoPor from "../../assets/logo-por.png";
import LogoRegione from "../../assets/logo-regione.png";
import LogoFser from "../../assets/fser.png";
import LogoRepubblicaItaliana from "../../assets/repubblica-italiana.png";
import LogoEuropa from "../../assets/flag_europe.png";
import ManualeUtente from "../../assets/manuale.pdf";
import "../less/Footer.less";

export default class Footer extends React.Component {
  state = {
    request_in_progress: false,
    mounted: false,
  };

  componentDidMount() {
    // this.mounted = true
    // LoadingServicesUtils((r) => this.handleRequestStatusChange(r, this));
  }

  componentWillUnmount() {
    //this.mounted = false
  }

  onMenuClick(info) {
    let path = "";
    switch (info.key) {
      case "login":
        path = "/login";
        break;
      case "dashboard":
        path = "/dashboard";
        break;
      case "contatti":
        path = "/contatti";
        break;
      case "documenti_utili":
        path = "/documenti-utili";
        break;
      case "privacy":
        path = "/privacy";
        break;
      case "note_legali":
        path = "/note-legali";
        break;
      default:
        break;
    }

    if (this.props.match.path !== path) this.props.history.push(path);
  }

  render() {
    return (
      <Layout.Footer className="footer" aria-label="footer sito Silf">
        <div>
        <Row >
            <Col lg={18} md={18} xs={24} style={{ textAlign: "left" }}>
              <Menu
                onClick={(info) => this.onMenuClick(info)}
                selectedKeys={[this.state.current]}
                mode="horizontal"
              >
                {/* {
                this.props.UserStore.loggedIn ?
                <Menu.Item key="dashboard">
                  <Icon type="lock" />Dashboard
                </Menu.Item> :
                <Menu.Item key="login">
                  <Icon type="lock" />Login
                </Menu.Item>
              } */}
                <Menu.Item >
                  <a target={"_blank"} href={ManualeUtente} style={{ color: "white" }}>Manuale</a>
                </Menu.Item>
                <Menu.Item ><a target={"_blank"} href="https://lavoro.regione.campania.it/index.php/contatti" style={{ color: "white" }}>Contatti</a></Menu.Item>
                <Menu.Item ><a target={"_blank"} href="https://lavoro.regione.campania.it/index.php/note-legali" style={{ color: "white" }}>Note Legali</a></Menu.Item>
                <Menu.Item ><a target={"_blank"} href="https://lavoro.regione.campania.it/index.php/privacy" style={{ color: "white" }}>Privacy</a></Menu.Item>
              </Menu>
              <BackTop
                style={{ bottom: "22px", right: "20px" }}
                visibilityHeight={800}
              />
            </Col>
          </Row>
          <Row className="m20h">
            <Col style={{ paddingLeft: "10px" }}
              lg={2}
              md={3}
              xs={8}
            >
              <a target={"_blank"} href="https://european-union.europa.eu/index_it">
              <img
                src={LogoEuropa}
                width={60}
                alt="Logo Unione Europea"
              />
              </a>
            </Col>
            <Col style={{ paddingLeft: "10px" }}
              lg={2}
              md={3}
              xs={8}
            >
              <a target={"_blank"} href="https://www.governo.it/">
              <img
                src={LogoRepubblicaItaliana}
                width={60}
                alt="Logo Repubblica Italiana"
              />
              </a>
            </Col>
            <Col style={{ paddingLeft: "10px" }}
              lg={2}
              md={3}
              xs={8}
            >
              <a target={"_blank"} href="http://regione.campania.it/">
              <img
                src={LogoRegione}
                width={60}
                alt="Silf - Regione Campania"
              />
              </a>
            </Col>
            <Col style={{ paddingLeft: "10px" }}
              lg={2}
              md={3}
              xs={8}
            >
              <a target={"_blank"} href="http://porfesr.regione.campania.it/">
              <img
                src={LogoFser}
                width={40}
                alt="Logo Fser"
              />
              </a>
            </Col>

            <Col style={{ textAlign: "left", right: "20px", left: "20px"}}
              offset={6}
              lg={10}
              md={7}
              xs={24}
            >
              <b>Assistenza amministrativa:</b>
                <br></br><a href="mailto:dg11.staff92@regione.campania.it" style={{color: "white", textDecoration: "underline"}}>dg11.staff92@regione.campania.it</a>
                <br></br><a href="mailto:consultazione.accreditamento@pec.regione.campania.it" style={{color: "white", textDecoration: "underline"}}>consultazione.accreditamento@pec.regione.campania.it</a>
              <br></br>
              <b>Assistenza tecnica:</b>
              <br></br><a href="mailto:assistenza.clic@regione.campania.it" style={{color: "white", textDecoration: "underline"}}>assistenza.clic@regione.campania.it</a>
              <br></br>
              Per il corretto invio delle richieste di assistenza è necessario indicare nell'oggetto della mail la “parola chiave” (in maiuscolo) “ACCREDITAMENTO” .
              <br></br>
              La mail dovrà contenere il codice fiscale del rappresentante legale, l’ente per il quale si sta operando, il numero di protocollo della domanda e la descrizione dettagliata della problematica.
              <br></br><br></br>
            </Col>  
            
            <Col style={{ textAlign: "right", right: "20px" }}
              lg={24}
              md={24}
              xs={24}
            >
              <b>v.1.0</b>
            </Col>

          </Row>
        </div>
      </Layout.Footer>
    );
  }
}
