import BaseRequest from '@common/services/_baseRequest';


export default {

  list: (vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/procedura',
      cb,
      ecb || null,
      null
    );
  },

  detail: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/procedura/' + id,
      cb,
      ecb || null,
      null
    );
  },

  create: (params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      'affidamento/procedura',
      cb,
      ecb || null,
      null
    );
  },

  update: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "put",
      'affidamento/procedura/' + id,
      cb,
      ecb || null,
      null
    );
  },

  printPdf: (json, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {json:json},
      null,
      "post_file",
      'affidamento/procedura/print-pdf',
      cb,
      ecb || null,
      null
    );
  },

  delete: (id_procedura, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'affidamento/procedura/' + id_procedura,
      cb,
      ecb || null,
      null
    );
  },

  createDomanda: (id_procedura, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/procedura/' + id_procedura + '/nuova-domanda',
      cb,
      ecb || null,
      null
    );
  },

  getDomande: (id_procedura, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'affidamento/procedura/' + id_procedura + '/domande',
      cb,
      ecb || null,
      null
    );
  },

}
