import { Button, Icon, message } from 'antd';
import React, { Component } from 'react';
import EnteService from '@accreditamento/services/Ente';
import CommonForm from '@common/components/form';
import DatiEnteSingolo from './DatiEnteSingolo';
import moment from 'moment';


const searchModel = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Partita IVA',
              type_string: 'text', // field type
              name: 'partita_iva',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  validator: 'valid_codfisc_piva'
                },
                {
                  pattern: '^[0-9]{11,11}$',
                  message: 'Partita IVA non valida',
                }

              ],
            },
          ],
        },
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Codice Fiscale',
              type_string: 'text', // field type
              name: 'cod_fiscale',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  validator: 'valid_codfisc_piva'
                },
                {
                  pattern: '^[0-9]{11,11}$',
                  message: 'Codice fiscale non valido non valida',
                }

              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'btn_search',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
}



class CreatePartner extends Component {

  state = {
    loaded: false,
    loading: false,
    searched: false,
    found: false,
    partner: {}
  }


  componentDidMount() {
    if (this.props.edit) {
      this.setState({
        searched: true,
        partner: this.props.partner,
        found: this.props.partner.ente_sisfo,
        loaded: true
      });
    } else {
      this.setState({ loaded: true })
    }


  }


  search(data) {
    if (!this.state.loading) {
      this.setState({ loading: true });
      EnteService.getEnteByPIva(data, res => {
        if (res.data !== null) {
          message.warning("Questa partita iva/codice fiscale è già associata ad un ente nel sistema");

          this.setState({ found: true, partner: { ...res.data.data, ente_sisfo: true } });
        } else {
          this.setState({
            partner: {
              entityPersonalData: {
                partita_iva: data.partita_iva,
                cfente: data.cod_fiscale
              }
            }
          });
        }

        this.setState({
          loading: false,
          searched: true,

        })
      }, () => this.setState({ loading: false }))
    }
  }

  async submit() {
    let valid = await this.dati_partner.formRef.props.form.validateFields();
    let valid_rappr_legale = await this.dati_partner.dati_rappr_legale.formRefDatiRappr.props.form.validateFields();
    let valid_indirizzo_referente = await this.dati_partner.dati_indirizzo_referente.formRef.props.form.validateFields();
    let valid_indirizzo_sede_legale = await this.dati_partner.dati_indirizzo_sede_legale.formRef.props.form.validateFields();
    let valid_indirizzo_rappr_legale = await this.dati_partner.dati_indirizzo_referente.formRef.props.form.validateFields();

    if (!valid || !valid_rappr_legale || !valid_indirizzo_referente || !valid_indirizzo_sede_legale || !valid_indirizzo_rappr_legale) {
      return;
    }

    let data = this.state.partner
    let form_data = this.dati_partner.formRef.props.form.getFieldsValue();

    form_data.entityPersonalData.sedeLegale = { indirizzo: { ...this.dati_partner.dati_indirizzo_sede_legale.formRef.props.form.getFieldsValue() } }

    form_data.legalRepresentativePersonalData = {
      ...this.dati_partner.dati_rappr_legale.formRefDatiRappr.props.form.getFieldsValue(),
      residenza: { ...this.dati_partner.dati_indirizzo_rappr_legale.formRef.props.form.getFieldsValue(), }
    };

    form_data.legalRepresentativePersonalData.nato_il = moment(form_data.legalRepresentativePersonalData.nato_il).format('YYYY-MM-DD');

    form_data.projectRepresentativePersonalData = {
      ...form_data.projectRepresentativePersonalData, ...this.dati_partner.dati_rappr_progetto.formRefDatiRappr.props.form.getFieldsValue(),
      residenza: { ...this.dati_partner.dati_indirizzo_referente.formRef.props.form.getFieldsValue(), }
    };

    form_data.projectRepresentativePersonalData.nato_il = moment(form_data.projectRepresentativePersonalData.nato_il).format('YYYY-MM-DD');

    let single_entity_data = {
      ...data, ...form_data,
    };

    if (this.props.addPartner) {
      this.props.addPartner(single_entity_data);
    }

    if (this.props.updatePartner) {
      this.props.updatePartner(single_entity_data);
    }

  }

  render() {
    return this.state.loaded && <div>
      {
        !this.state.searched
          ?
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={searchModel}
            values={{}}
            submitted={(data) => this.search(data)}
            valid_codfisc_piva={(rule, value, cb) => {
              if (this.formRef.props.form.getFieldsValue()['cod_fiscale'] === null
                && this.formRef.props.form.getFieldsValue()['partita_iva'] === null
              ) {
                cb("Almeno uno tra codice fiscale e partita iva deve essere valorizzato")
              } else cb()
            }}
          />
          :
          <div>
            <React.Fragment>
              <div
                ref={(ref) => this.dati_partner_container = ref}
              >
                <DatiEnteSingolo
                  {...this.props}
                  data={this.state.partner}
                  enteSisfo={this.state.found}
                  ref={(ref) => this.dati_partner = ref}
                ></DatiEnteSingolo>

              </div>

              <Button
                type="primary"
                className="bg-green"
                loading={this.state.loading}
                icon="save"
                disabled={this.props.readOnly}
                onClick={() => this.submit()}
              >Salva</Button>
            </React.Fragment>

          </div>

      }
    </div>
  }

}

export default CreatePartner