import React, {Component} from 'react';
//import { connect } from "react-redux";
import PropTypes from 'prop-types';

import {Icon} from 'antd';
import CommonForm from '@common/components/form';

import format from '@common/utils/formatters/_';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'datepicker',
              label: 'Dal',
              name: 'dal',
              props: {
                size: 'large',
                style: {width: '100%'},
                prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 12,
          items: [
            {
              type: 'datepicker',
              label: 'Al',
              name: 'al',
              props: {
                size: 'large',
                style: {width: '100%'},
                prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 16,
          items: [
            {
              type: 'input',
              label: 'Istituzione Formativa/Soggetto erogatore',
              type_string: 'text', // field type
              name: 'erogatore',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Durata (anni)',
              type_string: 'number', // field type
              name: 'durata',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Denominazione del percorso',
              type_string: 'text', // field type
              name: 'denominazione',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Attestazione in esito',
              type_string: 'text', // field type
              name: 'attestazione',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Obiettivi/contenuti',
              type_string: 'text', // field type
              name: 'obiettivi',
              mode: 'tags',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              name: 'submit',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            }
          ]
        }
      ]
    },
  ],
  onSubmit: 'submitted',
};


class EsperienzaFormativaForm extends Component {

  state = {
    loading: false
  };

  static propTypes = {
    submit: PropTypes.func,
    values: PropTypes.object,
  }

  confirm(data) {
    this.setState({loading: true});
    this.props.submit( format.replaceArrayToString( ['obiettivi'], format.replaceFormDatesOut( ['dal', 'al'], data ) ), ()=>this.setState({loading: false}) );
  }

  getValues() {
    let v = this.props.values;
    v.obiettivi = v.obiettivi || []
    return v;
  }

  reset() {
    this.formRef.props.form.resetFields();
  }

  render () {
    return (
      <div>
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={ this.getValues() }
            submitted={(dati) => this.confirm(dati)}

          />
      </div>
    );
  }
}

export default EsperienzaFormativaForm