import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button } from 'antd';

import ModelServices from '@accreditamento/services/Audit';

import checkPermission from '@common/utils/check/permissions';

import SingleAudit  from './SingleAudit';


class ListAudit extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },
    loading: false,
    starting: false,
    audit: {},
    open_modal_audit_refuse: false,
    open_modal_audit_integration: false
  }

  componentDidMount() {
    if (this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id &&
      (newProps.accreditamento !== this.props.accreditamento)) this.loaded(newProps)
  }

  loaded(props) {
    this.setState({ accreditamento: props.accreditamento, loaded: true });
  }

  isIstruttore() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) &&
      this.props.accreditamento.istruttore && this.props.accreditamento.istruttore.length > 0 && this.props.accreditamento.istruttore[0].id === this.props.UserStore.user.id
      ? true : false;
  }

  canStartAudit() {
    /**
     * con lo stato_flusso ci risparmiamo tutto
     */
    return this.state.accreditamento.stato_flusso === 5 && this.isIstruttore()
    ? true : false;
    /*
    return (
      this.state.accreditamento.stato > accreditamentoStatic.stato.inviata &&
      (
        (
          this.isIstruttore() && this.state.accreditamento.audit.length == 0
        ) ||
        (
          checkPermission.hasPermission(this.props.UserStore.user, ['ispettorePermissions']) &&
          this.props.nomina &&
          this.props.UserStore.user.id == this.props.nomina.id_ispettore
          && this.props.accreditamento.audit.filter(a => a.id_user == this.props.UserStore.user.id).length == 0
        )
      )
    ) ? true : false;*/
  }

  canView() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, [
        'dirigentePermissions', 'ispettorePermissions', 'adminPermissions']) ||
      this.isIstruttore() ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.state.accreditamento.id_user)
    ) ? true : false;
  }

  renderStart() {
    return <div>
      {this.isIstruttore() ? <p>inizia istruttoria amministrativa per questa pratica</p> : <p>Inizia Istruttoria Amministrativa per questa pratica</p> }
      <Button
      disabled={this.state.loading}
      loading={this.state.starting}
      className="m5h" type="primary" icon="check" onClick={() => {
        if(!this.state.loading) {
          this.setState({loading: true, starting: true}, ()=> {
            ModelServices.start(
              this.state.accreditamento.id,
              (res) => {
                this.props.refreshInline(res.data.data);
                this.setState({loading: false, starting: false});

              },
              () => {
                this.setState({loading: false, starting: false});
              });
          });
        }
      }}>{this.isIstruttore() ? "Comincia Istruttoria" : "Comincia Audit" }</Button>
    </div>
  }


  render() {
    return (this.state.loaded && this.canView()) ?
      <div>
        {this.canStartAudit() ? this.renderStart() : null}
        {this.props.accreditamento.audit.map(a => {
          //if(
            //((this.props.nomina && this.props.nomina.id_ispettore == a.id_user) || !this.props.nomina) &&
            //a.level == this.props.level
          //) {

            return <SingleAudit key={"la_" + a.id}
              accreditamento={this.state.accreditamento}
              audit={a}
              refresh={() => this.props.refresh()}
              refreshInline={(data) => this.props.refreshInline(data)}
              progress={this.props.progress}
            />
          //} else {
            //return null;
          //}
        }
        )}
      </div>
      : null;
  }
}

const ListAuditExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ListAudit);

export default withRouter(ListAuditExport);
