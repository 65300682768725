import React, { Component } from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom'
import Wrapper from '@common/decorators/Wrapper';

import ResetForm from '../forms/Reset';

import AuthServices from '../services/Auth';


@Wrapper({ route: 'Reset', hasSidebar: false, hasHeader: true, hasFooter: true })
class ResetFormRoute extends Component {

  state = {
    loading: false,
    changed: false,
  }





  tryReset(data) {
    this.setState({ loading: true });
    AuthServices.reset({
      new_pwd: data.new_pwd,
      confirm_password: data.confirm_password,
      token: this.props.match.params.token || null
    }, () => {

      //console.log('responso ask reset', response_reset.data);

      this.setState({ loading: false, changed: true });

    }, () => {

      this.setState({ loading: false });
    })
  }

  render() {

    return (
      <div style={{ width: 400, margin: '20px auto' }}>
        {/* <LogoForm /> */}
        <h2 style={{ textAlign: 'center' }}>MODIFICA PASSWORD</h2>
        <p style={{ textAlign: 'center' }}>{this.state.changed ? '' : 'Modifica la password del tuo account'}</p>
        <Card
          className="shadow"
          bordered={false}
        >

          {/*<Link to="/richiesta-accreditamento" style={{letterSpacing: 2}}>ACCREDITA LA TUA ORGANIZZAZIONE</Link>*/}
          {(!this.state.changed) ? <ResetForm loading={this.state.loading} onSubmit={(data) => this.tryReset(data)} /> : <p>Password modificata</p>}
          <Link to="/" className="login-form-forgot" style={{ float: 'right' }}>Accedi</Link>
        </Card>
      </div>
    );
  }
}

export default ResetFormRoute;
