import React, { Component } from 'react';

import { Table, Checkbox, Form, Input, Button } from 'antd';
import JoinLongArray from '@common/components/JoinLongArray';

const getAule = (component, value) => {
  return <p>
    {
      value.map(el => {
        return <div>{el} - </div>
      })
    }
  </p>
}



const columns = (component) => {
  const { getFieldDecorator } = component.props.form;
  return [
    {
      title: 'Seleziona',
      dataIndex: 'selezione',
      key: 'selezione',
      render: (text, record) => (
        <div style={{ width: 70 }}>
          <Form.Item>
            {getFieldDecorator(record.id + '_attrezzature_checked', {
              initialValue: component.props.metodologie.equipmentList?.find(el => el.id === record.id)
            })(
              <Checkbox
                disabled={component.props.readOnly}
                onChange={(e) => component.checkAttrezzatura(e, record, '_attrezzature_descrizione')}
                checked={component.props.form.getFieldValue(record.id + '_attrezzature_checked')}
              ></Checkbox>)}
          </Form.Item>
        </div>
      ),
    },
    {
      title: 'Accreditamento',
      dataIndex: 'accreditamento',
      key: 'accreditamento',
      render: (text, record) => (<div>{record.protocollo}</div>),
    },
    {
      title: 'Sede',
      dataIndex: 'sede',
      key: 'sede',
      render: (text, record) => (<div>{record.sede}</div>),
    },
    {
      title: 'Settori',
      dataIndex: 'settori',
      key: 'settori',
      render: (text, record) => (<div style={{width: 150}}>{ JoinLongArray(
        ", ",
        record.settori,
        50
      )}</div>)
    },
    {
      title: 'Aula',
      dataIndex: 'aula',
      key: 'aula',
      render: (text, record) => (<div style={{width: 150}}>{ JoinLongArray(
        ", ",
        record.aula,
        50
      )}</div>)
    },
    {
      title: 'Tipologia',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (text, record) => (<div>{record.tipo}</div>)
    },
    {
      title: 'Quantita',
      dataIndex: 'quantita',
      key: 'quantita',
      render: (text, record) => (<div>{record.quantita}</div>)
    },
    {
      title: 'Descrizione (Descrivere le principali attrezzature  e i materiali didattici utilizzati in relazione alla metodologia didattica e alle finalità formative)',
      dataIndex: 'descrizione',
      key: 'descrizione',
      render: (text, record) => (<div>
        <Form.Item>
          {getFieldDecorator(record.id + '_attrezzature_descrizione', {
            initialValue: component.props.metodologie.equipmentList?.find(el => el.id === record.id)?.descrizione
          })(
            <Input disabled={!component.props.form.getFieldValue(record.id + '_attrezzature_checked') || component.props.readOnly}
            />
          )}
        </Form.Item>
      </div>)
    },
  ];
}

const columnsAudiovisivi = (component) => {
  const { getFieldDecorator } = component.props.form;
  return [
    {
      title: 'Seleziona',
      dataIndex: 'selezione',
      key: 'selezione',
      render: (text, record) => (
        <div style={{ width: 70 }}>
          <Form.Item>
            {getFieldDecorator(record.id + '_audiovisivi_checked', {
             initialValue: component.props.metodologie.audioVisualList?.find(el => el.id === record.id)
            })(
              <Checkbox
                disabled={component.props.readOnly}
                onChange={(e) => component.checkAttrezzatura(e, record, '_audiovisivi_descrizione')}
               checked={component.props.form.getFieldValue(record.id + '_audiovisivi_checked')}
              ></Checkbox>)}
          </Form.Item>
        </div>
      ),
    },
    {
      title: 'Accreditamento',
      dataIndex: 'accreditamento',
      key: 'accreditamento',
      render: (text, record) => (<div>{record.protocollo}</div>),
    },
    {
      title: 'Sede',
      dataIndex: 'sede',
      key: 'sede',
      render: (text, record) => (<div>{record.sede}</div>),
    },
    {
      title: 'Settori',
      dataIndex: 'settori',
      key: 'settori',
      render: (text, record) => (<div style={{width: 150}}>{ JoinLongArray(
        ", ",
        record.settori,
        50
      )}</div>)
    },{
      title: 'Aula',
      dataIndex: 'aula',
      key: 'aula',
      render: (text, record) => (<div style={{width: 100}}>{getAule(component, record.aula)
      }
      </div>)
    },
    {
      title: 'Tipologia',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (text, record) => (<div>{record.tipo}</div>)
    },
    {
      title: 'Quantita',
      dataIndex: 'quantita',
      key: 'quantita',
      render: (text, record) => (<div>{record.quantita}</div>)
    },
    {
      title: 'Descrizione (Descrivere le principali attrezzature  e i materiali didattici utilizzati in relazione alla metodologia didattica e alle finalità formative)',
      dataIndex: 'descrizione',
      key: 'descrizione',
      render: (text, record) => (<div>
        <Form.Item>
          {getFieldDecorator(record.id + '_audiovisivi_descrizione', {
            initialValue: component.props.metodologie.audioVisualList?.find(el => el.id === record.id)?.descrizione
          })(
            <Input disabled={!component.props.form.getFieldValue(record.id + '_audiovisivi_checked') || component.props.readOnly}
            />
          )}
        </Form.Item>
      </div>)
    },
  ];
}


class AttrezzatureTable extends Component {

  state = {
    loaded: false,
    metodologie: {}
};


  componentDidMount() {
    this.load(this.props)
}

load(props) {
    this.setState({
        loaded: true,
        metodologie: props.metodologie
    });
}

  checkAttrezzatura(e, record, field) {
    if (!e.target.checked) {
      let data = {};
      data[record.id + field] = null
      this.props.form.setFieldsValue(data);
    }
  }

  handleSubmit = () => {
    let attrezzature = [];
    let audiovisivi = [];
    this.props.form.validateFieldsAndScroll((err, values) => {

      this.props.attrezzature.forEach(el => {
        if(values[el.id + '_attrezzature_checked']){
          let attrezzatura = {
            id : el.id,
            aula: el.aula,
            protocollo: el.protocollo,
            quantita: el.quantita,
            sede: el.sede,
            settori: el.settori,
            tipo: el.tipo,
            descrizione : values[el.id + '_attrezzature_descrizione']
          }
          attrezzature.push(attrezzatura)
        }
      });

      this.props.audiovisivi.forEach(el => {
        if(values[el.id + '_audiovisivi_checked']){
          let audiovisivo = {
            id : el.id,
            aula: el.aula,
            protocollo: el.protocollo,
            quantita: el.quantita,
            sede: el.sede,
            settori: el.settori,
            tipo: el.tipo,
            descrizione : values[el.id + '_audiovisivi_descrizione']
          }
          audiovisivi.push(audiovisivo)
        }
      });

      let metodologie = this.state.metodologie;
      metodologie.equipmentList=attrezzature;
      metodologie.audioVisualList=audiovisivi;
          this.setState({
              loading: true,
              metodologie: metodologie
          });
          this.props.onUpdate(metodologie);
    });
  }

  render() {
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <h4 className="m20h">Attrezzature</h4>
          <Table
            locale={{ emptyText: this.props.domanda_json.relatedSubjects?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.decreto ? '' : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali" }}
            loading={this.props.loading}
            columns={columns(this)}
            dataSource={this.props.attrezzature}
            rowKey="id" />
          <h4 className="m20h">Attrezzature Audio Video</h4>
          <Table
            locale={{ emptyText: this.props.domanda_json.relatedSubjects?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.decreto ? '' : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali" }}
            loading={this.props.loading}
            columns={columnsAudiovisivi(this)}
            dataSource={this.props.audiovisivi}
            rowKey="id" />
            <Button htmlType="submit" disabled={this.props.readOnly} type="primary" icon='save' className="m5h">Salva</Button>
        </Form>
      </div>

    );
  }
}

export default Form.create()(AttrezzatureTable);
