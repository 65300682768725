import React, { Component } from 'react';

import { Button, Card, Drawer, Row, Tooltip } from 'antd';
import TableComponent from '@common/components/table';

import ModelServices from '../services/User';

import moment from 'moment';

import FilterForm from '@common/components/filterForm';
import _ from '@common/utils/formatters/_';
import ModelCreate from '../forms/Create';

const model = (component) => {
  return {
    with_server_pagination: true, // metti paginazione lato server
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'created_at',
        render: element => <div style={{width: 60}}>
          <Tooltip placement="top" title="Visualizza scheda">
            <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push(component.getUserUrl(element))} />
          </Tooltip>
        </div>,
      },
      {
        title: 'Nome',
        dataIndex: '',
        sorter: true,
        key: 'nome',
        render: element => <div>{element.anagrafica.nome}</div>,
      },
      {
        title: 'Cognome',
        dataIndex: '',
        sorter: true,
        key: 'cognome',
        render: element => <div>{element.anagrafica.cognome}</div>,
      },
      {
        title: 'Codice fiscale',
        dataIndex: '',
        sorter: true,
        key: 'cf',
        render: element => <div>{element.anagrafica.cf}</div>,
      },
      {
        title: 'Email',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'email',
        render: element => <div>{element.email}</div>,
      },
      {
        title: 'Ultimo aggiornamento',
        dataIndex: '',
        sorter: true,
        key: 'updated_at',
        render: element => `${moment(element.updated_at,'X').format('DD/MM/YYYY HH:mm')}`,
      },
    ]
  }
}


export default class ListIstruttori extends Component {

  default_order = 'cognome'

  state = {
    loading: false,
    open_drawer: false,
    editing_id: null,
    data: {
      total: 0,
      current: 1,
      data: []
    }
  }

  componentDidMount() {
    this.callAndUpdate(this.props)
  }

  getUserUrl(item) {
    return "/istruttore/" + item.id
  }

  getBaseUrl() {
    return this.props.base_url ? this.props.base_url : "/istruttore";
  }

  setDefaultSearchParams(str) {
    str.fields = 'id,email,anagrafica,count_accreditamenti,count_dossier,updated_at';
    return str
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  callAndUpdate(props) {
    let { params, pager } = _.getFilterParam(props, this.default_order);
    params = this.setDefaultSearchParams(params);
    this.setState({ loading: true });
    ModelServices.listIstruttori(params, (res) => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, () => {
      this.setState({ loading: false });
    })
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.callAndUpdate(this.props.location.search);
    }
  }

  getFilterModel() {
    return {
      layout: 'vertical',
      multistep: false,
      rows: [
        {
          cols: [
            {
              items: [
                {
                  type: 'input',
                  label: 'Nome',
                  type_string: 'text', // field type
                  name: 'nome',
                }
              ]
            },
            {
              items: [
                {
                  type: 'input',
                  label: 'Cognome',
                  type_string: 'text', // field type
                  name: 'cognome',
                }
              ]
            },
            {
              items: [
                {
                  type: 'input',
                  label: 'Email',
                  type_string: 'email', // field type
                  name: 'email',
                }
              ]
            }
          ]
        },
        {
          cols: [
            {
              items: [
                {
                  name: 'submit',
                  type: 'submit',
                  text: 'Cerca',
                  btn_type: 'primary'
                }
              ]
            }
          ]
        },
      ]
    }
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.setState({ open_drawer: false });
    this.callAndUpdate(this.props)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.getBaseUrl(), obj);
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  async updateRecord(id) {
    await this.setState({ editing_id: id });
    this.setState({ open_drawer: true });
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  deleteRecord(id) {
    ModelServices.delete(id, () => this.refresh(), () => null)
  }


  /**
   * Record aggiornato in form
   * @return {[type]} [description]
   */
  updatedRecord() {
    this.setState({ open_drawer: false, editing_id: false });
    this.callAndUpdate(this.props);
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={this.getFilterModel()}
          base_url={this.getBaseUrl()}
          search={this.props.location.search}
          history={this.props.history}
        />
        <Card
          className="shadow no-pad"
          bordered={false}
        >
          <Row type="flex" justify="start" className="m10h">
            <div className="m5h m5w">
              <Button className="m5h bg-green" icon="plus" onClick={() => this.setState({ open_drawer: true })} loading={this.state.loading}>Inserisci istruttore</Button>
            </div>
          </Row>
          <Drawer
            title="Inserisci un nuovo Istruttore"
            width={720}
            placement="right"
            onClose={() => this.setState({ open_drawer: false })}
            maskClosable={true}
            destroyOnClose={true}
            visible={this.state.open_drawer}
            style={{
              overflow: 'auto',
            }}
          >
            {this.state.open_drawer &&
              <ModelCreate
                {...this.props}
                role={'IstruttoreAccreditamento'}
                onCreate={() => this.refresh()}
                infoIspettore={false}
                noPassword={true}
              />
            }

          </Drawer>
          <TableComponent
            data={this.state.data} model={(this.props.table_model) ? this.props.table_model(this) : model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

