import BaseRequest from "@common/services/_baseRequest";


export default {

	pending: (params = {}, cb = null, ecb = null, mantain = false, block = false) => {
		var req = new BaseRequest();
		req.makeRequest(
			params,
			{},
			null,
			"get",
			'notifica/pending',
			cb,
			ecb || null,
			null
		);
	},

	list: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			params,
			{},
			null,
			"get",
			'notifica',
			cb,
			ecb || null,
			null
		);
	},

	setRead: (data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data, // ids 1,2,3
			null,
			"post",
			'notifica/set-read',
			cb,
			ecb || null,
			null
		);
	},

}
