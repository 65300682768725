import BaseRequest from "@common/services/_baseRequest";



export default {

	view: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/ispezione/'+id_accreditamento+'/ispezione',
			cb,
			ecb || null,
			null
		);
	},

	need_ispezione: (id, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/ispezione/'+id+'/need-ispezione',
			cb,
			ecb || null,
			null
		);
	},

	ispezione_sedi: (id, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/ispezione/'+id+'/sedi',
			cb,
			ecb || null,
			null
		);
	},


	addControdeduzioni: ( id_accreditamento, id_ispezione, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/ispezione/'+id_accreditamento+'/controdeduzioni/' + id_ispezione,
			cb,
			ecb || null,
			null
		);
	},

	getControdeduzioni: (id_accreditamento, id_ispezione, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/ispezione/'+id_accreditamento+'/controdeduzioni/' + id_ispezione + '/download',
			cb,
			ecb || null,
			null
		);
	},

	uploadControdeduzioni: (id_accreditamento, id_ispezione, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/ispezione/'+id_accreditamento+'/controdeduzioni/' + id_ispezione + '/upload',
			cb,
			ecb || null,
			null
		);
	},

	/* deprecato
	sendControdeduzioni: (id_accreditamento, id_ispezione, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/ispezione/'+id_accreditamento+'/controdeduzioni/' + id_ispezione + '/send-controdeduzioni',
			cb,
			ecb || null,
			null
		);
	},
	*/

}
