import React, { Component } from 'react';
import { Button, Drawer, Modal, Popconfirm, Table } from 'antd';
import { connect } from "react-redux";
import ModelServices from '@accreditamento/services/Nomina';


const ispettori_table = (component) => {

  return [
      {
        title: 'Username',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'username',
        render: element => <div>{element.username}</div>,
      },
      {
        title: 'Nome',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: '',
        render: element => <div>{element.anagrafica?.nome + " " + element.anagrafica?.cognome}</div>,
      },
      {
        title: 'Email',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'email',
        render: element => <div>{element.email}</div>,
      },
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'created_at',
        render: element => <div style={{ width: 120 }}>
          <Popconfirm disabled={component.props.assigning} placement="top" title="Confermi di voler assegnare la pratica a questo Addetto al controllo?"
            onConfirm={() => {component.props.assign(element.id,  component.props.ispezione.id); component.setState({open_modal_ispettori:false})}}
            okText="Si" cancelText="No">
            <Button disabled={component.props.assigning} className="m5h" type="primary" icon="save" size="small">Assegna la pratica</Button>
          </Popconfirm>
        </div>,
      }
    ]

}





class SceltaIspettore extends Component {

  state = {
      ispettori:[]
  }

loadIspettori() {
    if(!this.state.loading_data) {
       this.setState({loading_data: true});
      ModelServices.listIspettori({}, (res) => {
        this.setState({loading_data: false, ispettori: res.data.data });
      }, () => this.setState({loading_data: false}));
    }
  }

  getIspettori() {
    if (this.props.ispezione.nomine) {
      let excluded_ids = this.props.ispezione.nomine.map(n => n.id_ispettore);
      return this.state.ispettori.filter(e => !excluded_ids.some(i => i === e.id))
    }
    return this.state.ispettori;
  }

  render() {
    return <div>
      <Button
        loading={this.props.random_assigning || this.props.assigning}
        disabled={this.props.random_assigning || this.props.assigning}
        className="m5h" type="primary" icon="search" onClick={() => this.props.assignrandom()}>Nomina Addetto al controllo
      </Button>
      <Modal
          title="Addetto al controllo selezionato"
          visible={this.props.open_modal_ispettore_random}
          confirmLoading={this.props.assigning}
          onOk={() => {
            this.props.assign(this.props.random_ispettore.id, this.props.ispezione.id);
          }}
          destroyOnClose={true}
          okText="Conferma selezione automatica"
          onCancel={() => this.props.closeModal()}
        >{
          this.props.random_ispettore &&
          <React.Fragment>
            <h4>{this.props.random_ispettore?.anagrafica?.nome} {this.props.random_ispettore?.anagrafica?.cognome}</h4>
            <p>{this.props.random_ispettore?.username}</p>
            <Button
            onClick={()=> {
              this.props.closeModal();
              this.props.resetRandomIspettore();
              this.loadIspettori();
              this.setState({open_modal_ispettori: true})
            }}
            disabled={this.state.loading} className="m5h" type="primary" ghost style={{ marginRight: 8 }}>
            Seleziona manualmente
          </Button>
          </React.Fragment>
        }

        </Modal>
      <Drawer
        title="Cerca un Addetto al controllo"
        placement="right"
        closable={true}
        onClose={() => this.setState({ open_modal_ispettori: false })}
        visible={this.state.open_modal_ispettori}
        width={1024}
      >

          <Table
          loading={this.state.loading_data || this.state.assigning}
          columns={ispettori_table(this)}
          rowKey={(record)=>"isp_"+record.id}
          pagination={false}
          dataSource={this.getIspettori(this.state.ispettori)} />

      </Drawer>
      </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SceltaIspettore);