import React, { Component } from "react";
import CommonForm from '@common/components/form';

import moment from 'moment';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    /*{
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Codice corso',
              type_string: 'text', // field type
              name: 'codice_corso',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
              ],
            },
          ],
        },
      ],
    },*/
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esito',
              name: 'esito',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Positivo"
                },
                {
                  value: "2",
                  label: "Negativo"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Sono stati effettuati i seguenti rilievi',
              type_string: 'text', // field type
              name: 'rilievi',
              props: {
              },
              validations: [
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Dichiarazioni rese dal Responsabile/delegato dell’Ente gestore',
              type_string: 'text', // field type
              name: 'dichiarazioni_rese',
              props: {
              },
              validations: [
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Considerazioni conclusive',
              type_string: 'text', // field type
              name: 'considerazioni_conclusive',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'onSubmit',
};


class Considerazioni extends Component {
  state = {
      mounted: false,
      loading: false
    };

  async validate() {
    return new Promise((resolve) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getValues() {
    let obj = {
      ...this.formSelRef.props.form.getFieldsValue()
    };
    return obj;
  }

  render() {
    return <div style={{marginTop: 16}}>
    <CommonForm
            readOnly={this.props.readOnly}
            wrappedComponentRef={inst => (this.formSelRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={this.props.values}
            onSubmit={() => null}
            tipicorsi={[]}
        />
          {
            (this.props.verbale.data_compilazione) ? 'Data/ora chiusura della verifica ' +
            moment(this.props.verbale.data_compilazione, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm') : ''
          }
        </div>
  }
}


export default Considerazioni;