import { Tabs, Card, Row, Col } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';

import PannelloLezione from "./PannelloLezione";
import PannelloInfoPresenze from "./PannelloInfoPresenze";
import TabellaValutazioniDiscente from "./TabellaValutazioniDiscente";
import CalendarioCorsi from "./CalendarioCorsi";

import ModelServices from '@affidamento/services/Corsi';
import SpinnerLoading from "@common/components/SpinnerLoading";

import checkPermission from '@common/utils/check/permissions';
import TabStage from "./TabStage";

const TabPane = Tabs.TabPane;

export default class DettagliDiscente extends Component {

  static propTypes = {
    corso: PropTypes.object,
    studente: PropTypes.object,
    isEditable: PropTypes.bool,
  }

  state = {
    current_key: "1",
    lesson: null,
    loading: true,
  }

  componentDidMount() {
    if (this.props.location.state?.tab) {
      this.setState({
        current_key: '' + this.props.location.state.tab,
      });
    }
    this.loadNextLesson();
  }

  loadNextLesson() {
    if (this.props.corso && checkPermission.hasPermission(this.props.UserStore.user, ['viewLezione'])) {
      ModelServices.prossimaLezione({}, this.props.corso.id, res => {
        this.setState({
          lesson: res.data.data,
          loading: false,
        });
      },
        () => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  isModified() {
    return true;
  }

  render() {
    return (<div style={{ marginTop: 12, marginBottom: 12 }}>

      <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
        {checkPermission.hasPermission(this.props.UserStore.user, ['viewLezione']) &&
          <Col style={{ flex: "1 1 70%" }} >
            <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
              {this.state.loading ? <SpinnerLoading /> :
                <PannelloLezione {...this.props} lesson={this.state.lesson} loading={this.state.loading} isNextLesson={true} />
              }
            </Row>
          </Col>
        }

        <Col style={{ flex: "1 1 30%" }} >
          {this.props.studente &&
            <PannelloInfoPresenze {...this.props} studente={this.props.studente} />
          }
        </Col>
      </Row>

      <Card
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={(k) => this.setState({ current_key: "" + k })}
            defaultActiveKey={"1"}
            activeKey={this.state.current_key}
            size="medium"
            renderTabBar={this.renderTabBar}>

            {checkPermission.hasPermission(this.props.UserStore.user, ['viewPresenze']) &&
              <TabPane
                tab={<span>Calendario presenze</span>} key="1">
                <div id="calendario-presenze">
                  <CalendarioCorsi
                    {...this.props}
                    id_corso={this.props.match.params.id_corso}
                    studente={this.props.studente}
                  />
                </div>
              </TabPane>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewOwnValutazioni']) &&
              <TabPane
                tab={<span>Valutazioni<br /></span>} key="2">
                <div id="valutazioni">
                  <TabellaValutazioniDiscente
                    {...this.props}
                    lesson={this.state.lesson}
                  />
                </div>
              </TabPane>
            }
            { this.props.corso && this.props.corso.scheda_progetto_json.teachingProgramming.AfcCourseArticulationFormSection.courseArticulationList.find(el => el.stageHours > 0) &&
              <TabPane
                tab={<span>Stage<br /></span>} key="2">
                <div id="stage">
                  <TabStage
                    {...this.props}
                    lesson={this.state.lesson}
                    tipo_media={this.props.GlobalStore.stubs.tipo_media}
                  />
                </div>
              </TabPane>
            }
          </Tabs>
        </StickyContainer>
      </Card>
    </div>);
  }
}

