import React, { Component } from 'react';
import { Button, Card, Tooltip, Row, Drawer, message } from 'antd';

import TableComponent from '@components/table';

import ModelServices from '@affidamento/services/Valutazioni';
import ProveServices from '@affidamento/services/Prove'

import checkPermission from '@common/utils/check/permissions';
import CommonForm from '@common/components/form';
import moment from 'moment';

import _ from '@common/utils/formatters/_';


const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Prova di valutazione',
                name: 'id_prova',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'prove_corso',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Valutazione',
                name: 'valutazione',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
};

const renderActions = (element, component) => {
  return <div style={{ display: 'inline-block' }}>

    {checkPermission.hasPermission(component.props.UserStore.user, ['editValutazioni']) &&
      <Tooltip placement="top" title="Modifica valutazione">
        <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.editTest(element)} />
      </Tooltip>
    }

  </div>
}


const model = (component) => {

  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "50px",
        render: element => <div style={{ width: 140 }}>
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Data',
        dataIndex: '',
        sorter: true,
        key: 'data',
        render: element => <div>
          {element.prova?.data ? moment(element.prova?.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        title: 'Oggetto della prova',
        dataIndex: '',
        sorter: true,
        key: 'oggetto',
        render: element => <div>
          {element.prova?.oggetto || ''}
        </div>
      },
      {
        title: 'Valutazione',
        dataIndex: '',
        sorter: true,
        key: 'valutazione',
        render: element => <div>
          {element.valutazione || 0}
        </div>
      },
    ]
  };
}

class TabellaValutazioniDiscente extends Component {

  default_order = 'id'

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
    open_drawer: false,
  }

  componentDidMount() {
    this.fetch();
    this.loadProve();
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(this.state.params, this.default_order);

    this.setState({ loading: true });
    return ModelServices.list(params, this.props.studente.id, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  loadProve() {
    return ProveServices.list({docente: this.props.UserStore.user.anagrafica.id}, this.props.corso.id, res => {
      this.setState({
        prove_corso: res.data.data.list.map(d => {
          return {
            ...d,
            label: d.oggetto,
            key: 'prova_' + d.id,
            value: d.id,
          };
        })
      });
    });
  }

  addTest() {
    this.setState({ open_drawer: true, editTest: null });
  }

  editTest(test) {
    this.setState({ open_drawer: true, editTest: test });
  }

  updateQueryStr(obj) {
    if (obj.order && obj.sort) {
      obj = {
        sort: (obj.order === 'ascend' ? '-' : '') + obj.sort
      }
    }
    this.setState({
      params: {
        ...this.state.params,
        ...obj
      }
    }, () => {
      this.fetch();
    });
  }

  render() {
    return (
      <div>
        <Card
          className="shadow no-pad"
          bordered={false}>

          <Row type="flex" justify="start" className="m10h">
            {checkPermission.hasPermission(this.props.UserStore.user, ['addValutazioni']) &&
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addTest()} loading={this.state.loading}>Nuova valutazione</Button>
              </div>
            }
          </Row>
          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort, order: order })}
          />
        </Card>

        {this.state.open_drawer &&
          <Drawer
            title={"Valutazione"}
            placement="right"
            closable={true}
            onClose={() => this.setState({ open_drawer: false })}
            visible={this.state.open_drawer}
            width={1024}
            destroyOnClose={true}
          >
            <CommonForm
              wrappedComponentRef={ref => this.formRef = ref}
              loading={this.state.loading || false}
              form_model={formLayout(this)}
              values={this.state.editTest}
              isEdit={() => this.state.editTest?.id}
              onSubmit={data => {
                data = {
                  ...this.state.editTest,
                  ...data,
                  id_iscrizione: this.props.studente.id,
                };
                this.setState({ loading: true });
                if (data.id) {
                  ModelServices.update(data, data.id,
                    res => {
                      this.setState({
                        editTest: res,
                        open_drawer: false,
                      }, () => {
                        message.success('Valutazione modificata');
                        this.fetch();
                      })
                    }, () => {this.setState({ loading: false })});
                } else {
                  ModelServices.create(data,
                    res => {
                      this.setState({
                        editTest: res,
                        open_drawer: false,
                      }, () => {
                        message.success('Valutazione aggiunta');
                        this.fetch();
                      })
                    }, () => {this.setState({ loading: false })});
                }
              }}
              prove_corso={this.state.prove_corso}
            />
          </Drawer>
        }
      </div>
    );
  }
}

export default TabellaValutazioniDiscente;
