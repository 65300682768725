export default {
  stato: {
    predisposizione: 0,
    lavorazione: 1,
    compilata: 2,
    da_firmare: 3,
    pronta: 4,
    inviata: 5,
    acquisita: 6,
    accolta: 7,
    accolta_e_confermata: 8,
    sospesa: 9,
    sospesa_comunicazione_ostativa: 10,
    in_istruttoria_documentale: 11,
    valutazione_in_corso: 12,
    sospesa_in_attesa_integrazioni: 13,
    lavorazione_in_integrazione: 14,
    audit_in_corso: 15,
    integrazione_inviata: 18,
    rifiutata_istruttore: 98,
    rifiutata: 99
  },
  /**
   * mappa gli stati flusso per dare un colore al pallino per utente
   * @type {Object}
   */
  pallini: {
    dirigente: {
      green: [3,4,7,8,9,10,13,20,14],
      red: [0,1,2],
      orange: [5,6,16]
    },
    istruttore: {
      green: [5,12,19,21,22],
      red: [11],
      orange: [6]
    },
    ente: {
      green: [0,1,2,11,18],
      red: [],
      orange: [12]
    },
    ispettore: {
      green: [],
      red: [],
      orange: []
    }
  },
  stato_interno: {
    da_assegnare: 0,
    assegnata: 1,
    audit_in_corso: 2,
    audit_completato: 3,
    in_istruttoria_documentale: 4,
    in_attesa_di_integrazioni: 5,
    sospesa: 6,
    valutazione_in_corso: 7,
    accolta: 8,
    rifiutata: 9,
    sospesa_in_attesa_integrazioni: 10
  },
  stato_flusso: {
    accolta_confermata_firmata: 23
  },
  tipo: {
    provvisorio: 1,
    rinnovo: 2,
    variazione: 3,
    definitivo: 4,
    riconoscimento_pregresso_provvisorio: 5,
    riconoscimento_pregresso_definitivo: 6,
    riconoscimento_ente: 7
  },

  tipo_accreditamento: {
    provvisorio: 1,
    definitivivo: 2,
    riconoscimento: 3,
    mantenimento: 4,
  },

  tipo_variazione: {
    ex_ante: 1,
    post_ante: 2,
  },
  tipo_media: {
    sottodominio_richiesta_firmata: 'richiesta_firmata'
  },
  need_ispezione: {
    pending: 0,
    needed: 1,
    unneeded: 2
  },
  timer: {
    accreditamento: 60,
    audit: 30
  },
  getStatoLabel: (accreditamento) => {
    let label = 'Predisposizione';
    switch (accreditamento.stato) {
      case 0: break;
      case 1: label = 'Lavorazione'; break;
      case 2: label = 'Compilata'; break;
      case 3: label = 'Da firmare'; break;
      case 4: label = 'Pronta per l\'invio'; break; // https://cb.schema31.it/cb/issue/447475 'Firmata e pronta per l\'invio',
      case 5: label = 'Inviata all\'amministrazione'; break;
      case 6: label = 'Acquisita'; break;
      case 7: label = 'Accolta'; break;
      case 8: label = 'Accolta e confermata'; break;
      case 9: label = 'Sospesa'; break;
      case 10: label = 'Sospesa con comunicazione ostativa'; break;
      case 11: label = 'In Istruttoria Amministrativa'; break;
      case 12: label = 'Valutazione in corso'; break;
      case 13: label = 'Sospesa in attesa di integrazioni'; break;
      case 14: label = 'Lavorazione in integrazione'; break;
      case 15: label = 'Istruttoria in corso'; break;
      case 16: label = 'Da firmare'; break;
      case 17: label = 'Pronta all\'invio'; break;
      case 18: label = 'Integrazione inviata all\'amministrazione'; break;
      case 98: label = 'Rigettata'; break;
      case 99: label = 'Rigettata'; break;
      default: break;
    }
    return label;
  },
  getStatoInternoLabel: (accreditamento) => {
    let _label = '';
    switch (accreditamento.stato_interno) {
      case 0: _label = 'Da assegnare'; break;
      case 1: _label = 'Assegnata'; break;
      case 2: _label = 'Istruttoria in corso'; break;
      case 3: _label = 'Istruttoria completata'; break;
      case 4: _label = 'In istuttoria documentale'; break;
      case 5: _label = 'In attesa di integrazioni'; break;
      case 6: _label = 'Sospesa'; break;
      case 7: _label = 'Valutazione in corso'; break;
      case 8: _label = 'Accolta'; break;
      case 9: _label = 'Rigettata'; break;
      case 10: _label = 'Sospesa in attesa di integrazioni'; break;
      default: break;
    }
    return _label;
  },
  getTipoLabel: (accreditamento) => {
    let _label = '';
    switch (accreditamento.tipo) {
      case 0: break;
      case 1: _label = 'ai sensi dell\'Art. 9 delle Linee guida approvate con D.G.R. n.136 del 22.3.2022'; break;
      // https://cb.schema31.it/cb/issue/
      // case 2: _label = 'Mantenimento ai sensi dell\'art. 15 comma 3, delle Linee Guida approvate con DGR n. 335 del 28/07/2021'; break;
      case 2: _label = 'ai sensi dell\'art. 9 delle Linee Guida approvate con DGR n. 136 del 22/03/2022'; break;
      case 3: _label = 'ai sensi dell\'art. 11 delle Linee Guida approvate con DGR n. 136 del 22/03/2022'; break;
      // https://cb.schema31.it/cb/issue/419229
      // case 4: _label = 'Definitivo ai sensi dell\'art. 6 comma 6 e 7, dell\'art. 14 e dell\'art. 15 comma 2, delle Linee Guida approvate con DGR n. 335 del 28/07/2021'; break;
      case 4: _label = 'ai sensi dell\'art. 9 delle Linee Guida approvate con DGR n. 136 del 22/03/2022'; break;
      case 5: _label = 'Pregresso provvisorio ai sensi dell\'art. 13 comma 6bis, delle Linee Guida approvate con DGR n. 335 del 28/07/2021'; break;
      case 6: _label = 'Pregresso definitivo ai sensi dell\'art. 13 comma 6, delle Linee Guida approvate con DGR n. 335 del 28/07/2021'; break;
      case 7: _label = 'Riconoscimento Ente Formazione ai sensi dell\'art. 4 comma 12, delle Linee Guida approvate con DGR n. 335 del 28/07/2021'; break;
      default: break;
    }
    return _label;
  },

  getTipoNoRifLabel: (accreditamento) => {
    //console.warn("TP.1", accreditamento.id, accreditamento.tipo)
    let _label = '';
    switch (accreditamento.tipo) {
      case 0: break;
      case 1: _label = ' '; break;
      // https://cb.schema31.it/cb/issue/420905
      // case 2: _label = 'Mantenimento'; break;
      // https://cb.schema31.it/cb/issue/442959
      //case 2: _label = 'Rinnovo'; break;
      //case 3: _label = 'Variazione'; break;
      case 2: _label = 'Rinnovo accreditamento'; break;
      case 3: _label = 'Variazione accreditamento'; break;
      case 4: _label = 'Definitivo'; break;
      case 5: _label = 'Pregresso provvisorio'; break;
      case 6: _label = 'Pregresso definitivo'; break;
      case 7: _label = 'Riconoscimento Ente Formazione'; break;
      default: break;
    }
    return _label;
  },

  getTipoAccreditamentoLabel: (tipo) => {
    let _label = '';
    switch (tipo) {
      case 0: break;
      case 1: _label = 'Provvisorio'; break;
      case 2: _label = 'Definitivo'; break;
      case 3: _label = 'Riconoscimento'; break;
      // https://cb.schema31.it/cb/issue/420905
      // case 4: _label = 'Mantenimento'; break;
      case 4: _label = 'Rinnovo'; break;
      default: break;
    }
    return _label;
  },

  getSezioneFormularioLabel: (tipo) => {
    let _label = '';
    switch (tipo) {
      case 0: break;
      case 1: _label = 'Dati identificativi del soggetto'; break;
      case 2: _label = 'Risorse infrastrutturali e disponibilità logistiche'; break;
      case 3: _label = 'Capacità gestionali e risorse professionali'; break;
      case 4: _label = 'Affidabilità economica e finanziaria'; break;
      case 5: _label = 'Qualità'; break;
      default: break;
    }
    return _label;
  },

  getTipoTitle: (accreditamento) => {
    let _title = accreditamento.tipo !== 7 && accreditamento.tipo !== 2 && accreditamento.tipo !== 3 ? 'accreditamento' : '';
    return _title;
  },

  getTipoVariazioneLabel: (accreditamento) => {
    let _label = '';
    switch (accreditamento.tipo_variazione) {
      case 0: break;
      //https://cb.schema31.it/cb/issue/442959
      //case 1: _label = 'Ex-ante'; break;
      //case 2: _label = 'Ex-post'; break;
      case 1: _label = ''; break;
      case 2: _label = ''; break;
      default: break;
    }
    return _label;
  },
  getStatiFiltri: (user) => {
    switch(user){
      case 'ente':
        return [
          {
            key: 0,
            label: 'Lavorazione',
            value: '0,1'
          },
          {
            key: 1,
            label: 'Pronta per l\'invio', // https://cb.schema31.it/cb/issue/447475 'Firmata e pronta per l\'invio',
            value: '2'
          },
          {
            key: 3,
            label: 'Acquisita',
            value: '4,5'
          },
          {
            key: 4,
            label: 'Istruttoria Amministrativa',
            value: '6'
          },
          {
            key: 5,
            label: 'Istruttoria in corso',
            value: '7,8,9,16,17'
          },
          {
            key: 6,
            label: 'Accolta',
            value: '10,14,22,23'
          },
          {
            key: 7,
            label: 'Sospesa in attesa di integrazioni',
            value: '11,12'
          },
          {
            key: 8,
            label: 'Rigettata',
            value: '13,15'
          },
          {
            key: 9,
            label: 'In attesa di controdeduzioni',
            value: '18'
          },
          {
            key: 10,
            label: 'Attesa esito',
            value: '19'
          },
          {
            key: 11,
            label: 'Sospesa con comunicazione ostativa',
            value: '20,21'
          },
          /*
          {
            key: 12,
            label: 'Sospeso',
            value: '80'
          },
          {
            key: 13,
            label: 'Revocato',
            value: '81'
          }
          */
        ];
      case 'dirigente':
        return [
          {
            key: 3,
            label: 'Da assegnare',
            value: '4'
          },
          {
            key: 4,
            label: 'Assegnata',
            value: '5'
          },
          {
            key: 5,
            label: 'Istruttoria Amministrativa',
            value: '6'
          },
          {
            key: 6,
            label: 'Istruttoria in corso',
            value: '7,8,9,16,17'
          },
          {
            key: 7,
            label: 'Accolta',
            value: '10,14,22,23'
          },
          {
            key: 8,
            label: 'Sospesa in attesa di integrazioni',
            value: '11,12'
          },
          {
            key: 9,
            label: 'Rigettata',
            value: '13,15'
          },
          {
            key: 10,
            label: 'In attesa di controdeduzioni',
            value: '18'
          },
          {
            key: 11,
            label: 'Attesa esito',
            value: '19'
          },
          {
            key: 12,
            label: 'Sospesa con comunicazione ostativa',
            value: '20,21'
          }
        ];
      case 'istruttore':
        return [
          {
            key: 2,
            label: 'Acquisita',
            value: '4'
          },
          {
            key: 4,
            label: 'Da lavorare',
            value: '5'
          },
          {
            key: 5,
            label: 'Istruttoria Amministrativa',
            value: '6'
          },
          {
            key: 6,
            label: 'Istruttoria in corso',
            value: '7,8,9,16,17'
          },
          {
            key: 7,
            label: 'Accolta',
            value: '10,14,22,23'
          },
          {
            key: 8,
            label: 'Sospesa in attesa di integrazioni',
            value: '11,12'
          },
          {
            key: 9,
            label: 'Rigettata',
            value: '13,15'
          },
          {
            key: 10,
            label: 'In attesa di controdeduzioni',
            value: '18'
          },
          {
            key: 11,
            label: 'Attesa esito',
            value: '19'
          },
          {
            key: 12,
            label: 'Sospesa con comunicazione ostativa',
            value: '20,21'
          }
        ];
      case 'ispettore':
        return [
          {
            key: 2,
            label: 'Acquisita',
            value: '4'
          },
          {
            key: 4,
            label: 'Assegnata',
            value: '5'
          },
          {
            key: 5,
            label: 'Istruttoria Amministrativa',
            value: '6'
          },
          {
            key: 6,
            label: 'Istruttoria in corso',
            value: '7,8,9,16,17'
          },
          {
            key: 7,
            label: 'Accolta',
            value: '10,14,22,23'
          },
          {
            key: 8,
            label: 'Sospesa in attesa di integrazioni',
            value: '11,12'
          },
          {
            key: 9,
            label: 'Rigettata',
            value: '13,15'
          },
          {
            key: 10,
            label: 'In attesa di controdeduzioni',
            value: '18'
          },
          {
            key: 11,
            label: 'Attesa esito',
            value: '19'
          },
          {
            key: 12,
            label: 'Sospesa con comunicazione ostativa',
            value: '20,21'
          }
        ];
      default: break;
    }
  }
}
