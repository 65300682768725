import React, { Component } from 'react';
import { Card, Tabs } from "antd";
import { Sticky, StickyContainer } from 'react-sticky';
import SpinnerLoading from "@common/components/SpinnerLoading";

import PropTypes from 'prop-types';
import QualificaProfessionaleForm from '../forms/QualificaProfessionaleForm';
import TabellaStoricoQualificheProfessionali from './TabellaStoricoQualificheProfessionali';

const TabPane = Tabs.TabPane;

export default class DettagliQualificaProfessionale extends Component {

  static propTypes = {
    isEditable: PropTypes.bool,
  }

  state = {
    current_key: "1",
  }

  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  render() {
    return (<div>{this.state.loading ? <SpinnerLoading message='Caricamento dati...' /> :
      <Card
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={k => this.setState({ current_key: "" + k })}
            defaultActiveKey="1" size="medium" renderTabBar={this.renderTabBar}>
            <TabPane
              tab={<span>DATI</span>} key="1">
              <div id="dati">
                <div style={{ padding: 14 }}>
                  <QualificaProfessionaleForm
                    {...this.props}
                    tab={this.state.current_key}
                    repertorio={this.props.repertorio}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={<span>Storia</span>} key="2">
              <div id="storia">
                <div style={{ padding: 14 }}>
                <TabellaStoricoQualificheProfessionali
                   {...this.props}
                   tab={this.state.current_key}
                />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Card>
    }</div>);
  }
}

