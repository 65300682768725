import React, { Component } from "react";
import { Button, message } from "antd";
import CommonForm from "@common/components/form";
import Documenti from "@common/components/Documenti";
import format from "@common/utils/formatters/_";
import checkPermission from "@common/utils/check/permissions";
import allegatiStatic from "@common/utils/static/allegati";
import moment from 'moment';
import DownloadTemplatesServices from "@accreditamento/services/DownloadTemplates";
import FileSaver from "file-saver";

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      /*
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label: "Bilancio certificato",
                name: "bilancio_certificato",
                option_type: "button", // normal|button
                onChange: "changedBilancio",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                 validations: [
                   {
                     type: 'required',
                     error_message: 'Campo obbligatorio',
                   },
                 ],
              },
            ],
          },
        ],
      },
      
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "radio",
                label: "Bilancio degli ultimi 3 anni", //negli ultimi tre anni come da prospetto predisposto acquisibile nella sezione Modulistica firmato dal rappresentante legale o da revisore contabile
                name: "conformita_bilancio_3anni",
                option_type: "button", // normal|button
                onChange: "changedConformitaBilancio",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                 validations: [
                   {
                     type: 'required',
                     error_message: 'Campo obbligatorio',
                },
                ],
              },
            ],
          },
        ],
      },*/
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label:
                  "Certifico il possesso di un sistema contabile per centri di costo contabile, come da regolamento CE N° 1083/2006",
                name: "sistema_contabile_ce",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                type: "radio",
                label:
                  "l'Ente non si trova in stato di fallimento, 'liquidazione coatta' oppure in concordato preventivo",
                name: "assenza_fallimento",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              },
            ] : [ /* {
              type: "radio",
              label:
                "l'Ente non si trova in stato di fallimento, (liquidazione coatta) oppure in concordato preventivo",
              name: "assenza_fallimento",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              props: {
                //style: { textAlign: 'center' },
              },
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
            },*/],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
              {
                type: "radio",
                label:
                  "il rappresentante legale, i soci e le altre cariche sociali, dichiarano di non essere stati condannati, con sentenza passata in giudicato, per uno dei delitti previsti dagli articoli 314, primo comma, 316-bis, 316-ter, 317, 318, 319, 319-bis, 319-ter, 319-quater, 320, 321, 322, 322-bis, 346-bis, 353, 355, 356, 416, 416-bis, 437, 452-bis, 452-quater, 452-sexies, 452-septies, 452-quaterdecies, 501, 501-bis, 640, secondo comma, numero 1, 640-bis e 644 del codice penale",
                name: "assenza_condanne",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
              {
                type: "radio",
                label: "Assenza di cause interdittive Antimafia",
                name: "assenza_cause_antimafia",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia != 3 && component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                type: "radio",
                label:
                  "l'Ente dichiara di adottare un sistema di contabilità separata, tanto relativamente alla formazione finanziata, quanto a quella auto-finanziata svolta a fini commerciali",
                name: "autodichiarazione_presenza_contabilita_separata",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              } ,
            ] : [],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia == 3 ? [
              {
                type: "radio",
                label:
                  "l’operatore adotta un sistema di contabilità separata, tanto relativamente ai servizi per il lavoro finanziati con fondi pubblici, quanto a quelli finanziati con fondi privati",
                name: "contabilita_separata_fondi_privati_e_pubblici",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              },
            ] : [],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? [] : [
              {
                type: "radio",
                label:
                  "l'Ente dichiara che rispetto all'ultimo bilancio approvato: i risultati di bilancio non intaccano il patrimonio netto portandolo al di sotto del minimo legale",
                name: "risultato_bilancio_approvato_ok",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
              {
                type: "radio",
                label:
                  "l’Ente dichiara che rispetto all'ultimo bilancio approvato: sono in corso le seguenti azioni di ripatrimonializzazione",
                name: "ripatrimonializzazione_bilancio_in_corso",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'risultato_bilancio_approvato_ok',
                    operator: '==',
                    value: 0
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "textarea",
                label: "Descrizione riguardante le azioni di ripatrimonializzazione",
                name: "descrizione_ripatrimonializzazione",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'ripatrimonializzazione_bilancio_in_corso',
                    operator: '==',
                    value: true
                  }
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'risultato_bilancio_approvato_ok',
                    operator: '==',
                    value: 0
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia == 3 ? [
              {
                type: "radio",
                label: "di essere interconnesso alla borsa continua nazionale del lavoro per il tramite del portale cliclavoro e si impegna a rilasciare alla regione ogni informazione utile al monitoraggio dei fabbisogni professionali e al buon funzionamento del mercato del lavoro",
                name: "internconnesione_borsa",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              },
            ] : [],
          },
        ],
      },
      {
        cols: [
          {
            items:component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia != 3 && component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                name: "dati_attivita_formative_finanziate",
                type: "title",
                element: "h4",
                text: "Dati derivanti dallo svolgimento di attività formative finanziate",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ] : [],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia != 3 && component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                type: "integerwithzero",
                label: "Costi/Oneri/Acconti",
                name: "dati_attivita_finanziate_costi",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ] : [],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia != 3 && component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                type: "integerwithzero",
                label: "Ricavi/Proventi/Crediti",
                name: "dati_attivita_finanziate_ricavi",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ] : [],
          },
        ],
      },
      {
        cols: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia != 3 && component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
          {
            items: [
              {
                name: "dati_attivita_formative_autofinanziate",
                type: "title",
                element: "h4",
                text: "Dati derivanti dallo svolgimento di attività formative autofinanziate",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ] : [] ,
      },
      {
        cols: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia != 3 && component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
          {
            items: [
              {
                type: "integerwithzero",
                label: "Costi/Oneri/Acconti",
                name: "dati_attivita_autofinanziate_costi",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ] : [],
      },
      {
        cols: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia != 3 && component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
          {
            items: [
              {
                type: "integerwithzero",
                label: "Ricavi/Proventi/Crediti",
                name: "dati_attivita_autofinanziate_ricavi",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ] : [],
      },
      {
        cols: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia == 3 ? [
          {
            items: [
              {
                name: "dati_attivita_servizi_finanziate",
                type: "title",
                element: "h4",
                text: "Dati derivanti dallo svolgimento di attività per i servizi per il lavoro finanziate",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ] : [],
      },
      {
        cols: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia == 3 ? [
          {
            items: [
              {
                type: "integerwithzero",
                label: "Costi/Oneri/Acconti",
                name: "dati_attivita_servizi_finanziate_costi",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ] : [],
      },
      {
        cols: component.props.accreditamento.accreditamentotipologie[0]?.id_tipologia == 3 ? [
          {
            items: [
              {
                type: "integerwithzero",
                label: "Ricavi/Proventi/Crediti",
                name: "dati_attivita_servizi_finanziate_ricavi",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ] : [],
      },
      {
        cols: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
          {
            items: [
              {
                name: "dati_contabili_ultimo_bilancio",
                type: "title",
                element: "h4",
                text: "Dati Contabili relativi all’ultimo bilancio approvato (solo per enti che abbiano chiuso almeno un esercizio contabile)",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
          {
            items: [
              {
                name: "stato_patrimoniale",
                type: "title",
                element: "h4",
                text: "Stato patrimoniale",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []:  [
          {
            items: [
              {
                type: "integerwithzero",
                label: "Totale attivo stato patrimoniale",
                name: "stato_patrimoniale_totale_attivo",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
              },
            ],
          },
        ],
      },
      {
        cols: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
          {
            items: [
              {
                type: "integerwithzero",
                label: "Totale passivo stato patrimoniale ",
                name: "stato_patrimoniale_totale_passivo",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
              },
            ],
          },
        ],
      },
      {
        cols: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
          {
            items: [
              {
                name: "totale_della_produzione",
                type: "title",
                element: "h4",
                text: "Totale della produzione",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
          {
            items: [
              {
                type: "integerwithzero",
                label: "Costi/Oneri",
                name: "costi_oneri_produzione",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
              },
            ],
          },
        ],
      },
      {
        cols: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? []: [
          {
            items: [
              {
                type: "integerwithzero",
                label: "Ricavi/Proventi/Valore",
                name: "ricavi_provenienti_valore_produzione",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "obbligo_bilanci_title",
                type: "title",
                element: "h4",
                text: "Certifico che",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label:
                  "Sono stati regolarmente assolti, per il personale dipendente e per quello a prestazione, gli obblighi previdenziali con il versamento degli importi sia a carico del lavoratore che quelli a carico del datore di lavoro. Non sono tenuti a dimostrare tale requisito i soggetti richiamati all’art. 9.",
                name: "obblighi_previdenziali_assolti",
                option_type: "button", // normal|button
                onChange: "changedObblighiPrevidenziali",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? [] :[
              {
                type: "radio",
                label:
                  "l'Ente si trova in regola con gli obblighi di cui alla legge 12 marzo 1999, n. 68",
                name: "assolvimento_l68_99",
                option_type: "button",
                //JayBee_bugfix_#2917: aggiunta allegato ESENZIONE ASSOLVIMENTO L.68/99
                onChange: "changedEsenzione",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica == 50 || component.props.ente.id_descrizione_forma_giuridica == 49 ? [] :[
              {
                type: "radio",
                label:
                  "l'Ente non è assoggettato agli obblighi di assunzione di cui alla legge n. 68/99 in quanto occupa un numero di lavoratori inferiore a 15 conteggiati in base ai criteri della suddetta legge",
                name: "presenza_obblighi_assunzione",
                option_type: "button",
                //JayBee_bugfix_#2917: aggiunta allegato ESENZIONE ASSOLVIMENTO L.68/99
                onChange: "changedEsenzione",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'assolvimento_l68_99',
                    operator: '==',
                    value: '0'
                  }
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                    {
                      pattern: "^1$",
                      message: 'Devi selezionare "Si"',
                    },
                ],
              },
            ]
          },
        ],
      },
      /*JayBee_bugfix_#2917: eliminazione campo
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'No, esistono documentati motivi di esenzione',
                name: 'esente_l68_99',
                option_type: 'button', // normal|button
                onChange: 'changedEsenzione',
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'assolvimento_l68_99',
                    operator: '==',
                    value: '0'
                  }
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'No senza motivazione',
                name: 'esente_nomotivazione_l68_99',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { textAlign: 'center'  },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ],
      },*/
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label:
                  "Viene applicato il Contratto Collettivo Nazionale di Lavoro della formazione professionale al personale assunto",
                name: "applicazione_ccnl",
                option_type: "button", // normal|button
                onChange: "changedCCNL",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                showIf: [
                  {
                    type: "prop_func",
                    func: "needContrattoCollettivo",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                name: "copertura_assicurativa_title",
                type: "title",
                element: "h4",
                text: "Copertura Assicurativa",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ] :[],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                name: "DATI_Inail_title",
                type: "title",
                element: "h4",
                text: "Dati INAIL",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ] :[],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items:  component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ?[
              {
                type: "input",
                input_type: "text",
                label: "Numero di posizione",
                name: "numero_di_posizione",
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ] :[],
          },
        ],
      },
      {
        cols: [
          {
            items: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                name: "DATI_Polizza_title",
                type: "title",
                element: "h4",
                text: "Dati Polizza RC",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ]:[],
          },
        ],
      },
      {
        cols: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
          {
            span: 12,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "denominazione istituto assicurativo",
                name: "denominazione_istituto_assicurativo",
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ] :[],
      },     
      {
        cols: [
          {
            span: 12,
            items: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                type: "input",
                input_type: "text",
                label: "numero polizza",
                name: "numero_di_polizza",
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ] :[],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                type: 'datepicker',
                label: 'data stipula polizza',
                name: 'data_stipula_polizza',
                //format: 'DD/MM/YYYY',
                props: {
                  // size: 'large',
                  style: { width: '100%' },
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'pastDate'
                  }
                ]
              },
            ] :[],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                type: 'datepicker',
                label: 'data inizio validità',
                name: 'data_inizio_validita',
                //format: 'DD/MM/YYYY',
                props: {
                  // size: 'large',
                  style: { width: '100%' },
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ]
              },
            ] :[],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: component.props.ente.id_descrizione_forma_giuridica != 50 && component.props.ente.id_descrizione_forma_giuridica != 49 ? [
              {
                type: 'datepicker',
                label: 'data scadenza',
                name: 'data_scadenza_polizza',
                //format: 'DD/MM/YYYY',
                props: {
                  // size: 'large',
                  style: { width: '100%' },
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'futureDate'
                  }
                ]
              },
            ]:[] ,
          },
        ],
      },

    ],
    onSubmit: "onSubmit",
  };
};

class AffidabilitaEconomicaForm extends Component {
  state = {
    loaded: false,
    loading: false,
    files: {},
    id_affidabilita_economica: "",
    obblighi_previdenziali_assolti: false,
    esenzione: null,
    applicazione_ccnl: false,
    bilancio: null,
    bilancio_certificato: null,
    medias: [],
  };

  componentDidMount() {
    this.load(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.ente !== this.props.ente ||
      newProps.accreditamento !== this.props.accreditamento ||
      newProps.values !== this.props.values
    ) {
      this.load(newProps);
    }
  }

  load(props) {
    let obj = Object.assign({}, props.values);

    //if (obj.assenza_fallimento !== false) obj.assenza_fallimento = true;
    //if (obj.sistema_contabile_ce !== false) obj.sistema_contabile_ce = true;

    console.warn("props.ente.media", props.ente.media);
    this.setState({
      loaded: true,
      id_affidabilita_economica: obj.id,
      obblighi_previdenziali_assolti:  obj.obblighi_previdenziali_assolti,

      //JayBee_bugfix_#2917: aggiunta allegato ESENZIONE ASSOLVIMENTO L.68/99
      esenzione: parseInt(obj.assolvimento_l68_99) === 1 ? true : false,

      applicazione_ccnl: obj.applicazione_ccnl,
      bilancio: parseInt(obj.conformita_bilancio_3anni) === 1 ? true : false,
      bilancio_certificato:
        parseInt(obj.bilancio_certificato) === 1 ? true : false,
      medias: props.ente.media,
    });
  }

  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error("Ricontrolla i campi");
          resolve(false);
        }
      });
    });
  }

  getValues(with_validation = false) {
    let obj = Object.assign({}, this.props.values);

    //if (obj.assenza_fallimento !== false) obj.assenza_fallimento = true;
    //if (obj.sistema_contabile_ce !== false) obj.sistema_contabile_ce = true;

    return format.convertBoolean(
      [
        "applicazione_ccnl",
        "assolvimento_l68_99",
        "bilanci_certificati_revisore_esterno",
        "bilanci_certificati_revisori_conti",
        "bilancio_certificato",
        "conformita_bilancio_3anni",
        "esente_l68_99",
        "in_amministrazione_controllata",
        "in_concordato_preventivo",
        "in_fallimento",
        "in_liquidazione_coatta",
        "obblighi_previdenziali_assolti",
        "sistema_contabile_ce",
        "assenza_fallimento",
        "esente_nomotivazione_l68_99",
        "presenza_obblighi_assunzione",
        "risultato_bilancio_approvato_ok",
        "assenza_cause_antimafia",
        "autodichiarazione_presenza_contabilita_separata",
        "internconnesione_borsa",
        "ripatrimonializzazione_bilancio_in_corso",
        "autodichiarazione_presenza_contabilita_separata",
        "assenza_condanne"
      ],
      obj
    );
  }

  refresh() {
    return this.props.refresh();
  }

  verifyFileObbligatori() {}

  async submitAffidabilita() {
    let data = this.formRef.props.form.getFieldsValue();

    //console.log('dati', data)
    let valid_form = await this.validate();
    if (valid_form) {
/*      if (this.obblighiPrevidenzialiAssolti()) {
        if (
          this.state.medias.filter(
            (m) =>
              m.tipo.tipo_media?.toLowerCase() === "autocertificazione durc" &&
              m.tipo.sottodominio === "durc_f24" &&
              parseInt(m.deleted) !== 1
          ).length === 0
        ) {
          message.error("Allegato Mancante: DURC");
          return;
        }
      }
   

      if (this.needCertificatoCameraDiCommercio()) {
        if (
          this.state.medias.filter(
            (m) =>
              m.tipo.sottodominio === "certificato_fallimentare" &&
              parseInt(m.deleted) !== 1
          ).length === 0
        ) {
          message.error("Allegato mancante: Certificato fallimentare");
          return;
        }
      }
      */

      

      if (this.esenzione()) {
        
        if (
          this.state.medias.filter(
            (m) =>
              m.tipo.sottodominio === "esenzione_l68_99" &&
              parseInt(m.deleted) !== 1
          ).length === 0
        ) {
          //message.error("Allegato mancante: Esenzione assolvimento L.68/99");
          //return;
        }
      }
    } else {
      return;
    }

    console.error('dati', data)
    this.submitted(data);
  }

  submitted(data) {
    this.props.onSubmit(data);
  }

  needCertificatoCameraDiCommercio() {
    return this.props.ente.numero_ccia &&
      this.props.ente.numero_ccia !== "" &&
      this.props.ente.data_iscrizione &&
      this.props.ente.data_iscrizione !== ""
      ? true
      : false;
  }

  needContrattoCollettivo() {
    let tipologia = this.props.GlobalStore.stubs.tipo_accreditamento.find(
      (t) => t.label === "Istruzione e Formazione Professionale"
    );
    if (!tipologia) return false;

    return this.props.accreditamento.accreditamentotipologie.filter(
      (el) => el.id_tipologia === tipologia.value
    ).length > 0
      ? true
      : false;
  }

  obblighiPrevidenzialiAssolti() {
    return this.state.obblighi_previdenziali_assolti;
  }

  esenzione() {
    return this.state.esenzione;
  }

  applicazione_ccnl() {
    return this.state.applicazione_ccnl;
  }

  bilancio() {
    return this.state.bilancio;
  }

  bilancioCertificato() {
    return this.state.bilancio_certificato;
  }

  render() {
    return this.state.loaded ? (
      <div>
        <CommonForm
          wrappedComponentRef={(inst) => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          onSubmit={(values) => {}}
          changedObblighiPrevidenziali={(key, e) =>
            this.setState({
              obblighi_previdenziali_assolti: parseInt(e.target.value) === 1 ? true : false,
            })
          }
          changedEsenzione={(key, e) => {
            console.warn("TP.2 - ", e.target.value)
            this.setState({
              esenzione: parseInt(e.target.value) === 1 ? true : false,
            })}
          }
          changedCCNL={(key, e) =>
            this.setState({
              applicazione_ccnl: parseInt(e.target.value) === 1 ? true : false,
            })
          }
          changedConformitaBilancio={(key, e) =>
            this.setState({
              bilancio: parseInt(e.target.value) === 1 ? true : false,
            })
          }
          changedBilancio={(key, e) =>
            this.setState({
              bilancio_certificato:
                parseInt(e.target.value) === 1 ? true : false,
            })
          }
          pastDate={(rule, value, cb) => {
            if (moment() < value) cb("Inserisci una data passata");
            else cb()
          }}
          futureDate={(rule, value, cb) => {
            if (moment() > value) cb("Inserisci una data futura");
            else cb()
          }}
          province={this.props.province}
          readOnly={this.props.readOnly}
          checkPermissions={() => {
            return checkPermission.hasPermission(this.props.UserStore.user, [
              "entePermissions",
            ]);
          }}
          needCertificato={(value, form, field_name) =>
            this.needCertificatoCameraDiCommercio()
          }
          needContrattoCollettivo={(value, form, field_name) =>
            this.needContrattoCollettivo()
          }
        />

        {
          (
            allegatiStatic.thereAreMediasFor(
              this.props.GlobalStore.stubs.tipo_media, "ente", "bilancio_ultimi_3_anni", this.props.accreditamento.tipo_accreditamento
            ) 
            ||
            allegatiStatic.thereAreMediasFor(
              this.props.GlobalStore.stubs.tipo_media, "ente", "affidabilita_economica", this.props.accreditamento.tipo_accreditamento
            )
          )
            ? 
              <h4>Documenti</h4>
            :
              null
        }

        {/* this.bilancioCertificato() &&
        allegatiStatic.thereAreMediasFor(
          this.props.GlobalStore.stubs.tipo_media,
          "ente",
          "bilancio_certificato",
          this.props.accreditamento.tipo_accreditamento
        ) ? ( 
          <Documenti
            onUpload={(f) => {
              this.setState({ medias: [...this.state.medias, f] });
            }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) =>
              this.setState({
                medias: this.state.medias.filter((el) => el.id !== id),
              })
            }
            canDelete={!this.props.readOnly}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(
              this.props.GlobalStore.stubs.tipo_media,
              this.props.accreditamento.tipo_accreditamento
            )}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="bilancio_certificato"
            required_types={allegatiStatic.filteredRequiredMedia(
              this.props.GlobalStore.stubs.tipo_media,
              "ente",
              "bilancio_certificato"
            )}
            ref_id={this.props.ente.id}
            readOnly={this.props.readOnly}
          />
            ) : null */}
        {/*this.needCertificatoCameraDiCommercio() && allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'certificato_fallimentare', this.props.accreditamento.tipo_accreditamento)
          ?
          <Documenti
            onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
              accreditation_id={this.props.accreditamento.id}
              onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
              canDelete={!this.props.readOnly}
              canAddNote={false}
              unique={true}
              required_dates={true}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.ente.media}
              current_domain="ente"
              current_subdomain="certificato_fallimentare"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'certificato_fallimentare')}
              ref_id={this.props.ente.id}
              readOnly={this.props.readOnly}
              downloadTemplateEnabled={true}
              downloadTemplateOnClick={(event) => {
                console.warn("downloadTemplateOnClick Click is ok")
                DownloadTemplatesServices.download("certificato_fallimentare", (res) => {
                  const _blob = new Blob([res.data], { type: 'application/vnd.oasis.opendocument.text' });
                  FileSaver.saveAs(_blob, "CertificatoFallimentare.odt");
                });
                event.stopPropagation();
              }}
            />
            : null
            */}
        {/*
          this.obblighiPrevidenzialiAssolti()
          ?
          <React.Fragment>
            {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'autocertificazione durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24', this.props.accreditamento.tipo_accreditamento) &&
            <Documenti
              onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
              accreditation_id={this.props.accreditamento.id}
              onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
              canDelete={!this.props.readOnly}
              canAddNote={false}
              unique={true}
              required_dates={true}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'autocertificazione durc' && el.sottodominio === 'durc_f24'), this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.ente.media}
              current_domain="ente"
              current_subdomain="durc_f24"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24')}
              ref_id={this.props.ente.id}
              readOnly={this.props.readOnly}
              downloadTemplateEnabled={true}
              downloadTemplateOnClick={(event) => {
                console.warn("downloadTemplateOnClick Click is ok")
                DownloadTemplatesServices.download("durc_f24", (res) => {
                  const _blob = new Blob([res.data], { type: 'application/vnd.oasis.opendocument.text' });
                  _blob, "Durc.odt");
                });
                event.stopPropagation();
              }}
            /> }
          </React.Fragment>
          : null
            */}
        {/*
           allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'durc_f24', this.props.accreditamento.tipo_accreditamento)
            ?
            <Documenti
              onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
              accreditation_id={this.props.accreditamento.id}
              onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
              canDelete={!this.props.readOnly}
              canAddNote={false}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() != 'autocertificazione durc' && el.sottodominio === 'durc_f24'), this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.ente.media}
              current_domain="ente"
              current_subdomain="durc_f24"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() != 'autocertificazione durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24')}
              ref_id={this.props.ente.id}
              readOnly={this.props.readOnly}
            />
            : null
            */}
        {
          //JayBee_bugfix_#2917: aggiunta allegato ESENZIONE ASSOLVIMENTO L.68/99
          /*this.esenzione() &&
          allegatiStatic.thereAreMediasFor(
            this.props.GlobalStore.stubs.tipo_media,
            "ente",
            "esenzione_l68_99",
            this.props.accreditamento.tipo_accreditamento
          ) ? (
            <Documenti
              onUpload={(f) => {
                this.setState({ medias: [...this.state.medias, f] });
              }}
              accreditation_id={this.props.accreditamento.id}
              onDelete={(id) =>
                this.setState({
                  medias: this.state.medias.filter((el) => el.id !== id),
                })
              }
              canDelete={!this.props.readOnly}
              canAddNote={false}
              unique={true}
              required_dates={true}
              media_types={allegatiStatic.filteredAccreditationMedia(
                this.props.GlobalStore.stubs.tipo_media.filter(
                  (el) => el.sottodominio === "esenzione_l68_99"
                ),
                this.props.accreditamento.tipo_accreditamento
              )}
              medias={this.props.ente.media}
              current_domain="ente"
              current_subdomain="esenzione_l68_99"
              required_types={allegatiStatic.filteredRequiredMedia(
                this.props.GlobalStore.stubs.tipo_media,
                "ente",
                "esenzione_l68_99"
              )}
              ref_id={this.props.ente.id}
              readOnly={this.props.readOnly}
            />
          ) : null
              */}
        { /*allegatiStatic.thereAreMediasFor(
          this.props.GlobalStore.stubs.tipo_media,
          "ente",
          "autocertificazione_ccnl",
          this.props.accreditamento.tipo_accreditamento
        ) ? (
          <Documenti
            onUpload={(f) => {
              this.setState({ medias: [...this.state.medias, f] });
            }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) =>
              this.setState({
                medias: this.state.medias.filter((el) => el.id !== id),
              })
            }
            canDelete={!this.props.readOnly}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(
              this.props.GlobalStore.stubs.tipo_media,
              this.props.accreditamento.tipo_accreditamento
            )}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="autocertificazione_ccnl"
            required_types={allegatiStatic.filteredRequiredMedia(
              this.props.GlobalStore.stubs.tipo_media,
              "ente",
              "autocertificazione_ccnl"
            )}
            ref_id={this.props.ente.id}
            readOnly={this.props.readOnly}
          />
            ) : null */}
        {allegatiStatic.thereAreMediasFor(
          this.props.GlobalStore.stubs.tipo_media,
          "ente",
          "bilancio_ultimi_3_anni",
          this.props.accreditamento.tipo_accreditamento
        ) ? (
          <Documenti
            onUpload={(f) => {
              this.setState({ medias: [...this.state.medias, f] });
            }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) =>
              this.setState({
                medias: this.state.medias.filter((el) => el.id !== id),
              })
            }
            canDelete={!this.props.readOnly}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(
              this.props.GlobalStore.stubs.tipo_media,
              this.props.accreditamento.tipo_accreditamento
            )}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="bilancio_ultimi_3_anni"
            required_types={allegatiStatic.filteredRequiredMedia(
              this.props.GlobalStore.stubs.tipo_media,
              "ente",
              "bilancio_ultimi_3_anni"
            )}
            ref_id={this.props.ente.id}
            readOnly={this.props.readOnly}
          />
        ) : null}
        {allegatiStatic.thereAreMediasFor(
          this.props.GlobalStore.stubs.tipo_media,
          "ente",
          "affidabilita_economica",
          this.props.accreditamento.tipo_accreditamento
        ) && (
          <Documenti
            onUpload={(f) => {
              this.setState({ medias: [...this.state.medias, f] });
            }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) =>
              this.setState({
                medias: this.state.medias.filter((el) => el.id !== id),
              })
            }
            canDelete={!this.props.readOnly}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(
              this.props.GlobalStore.stubs.tipo_media,
              this.props.accreditamento.tipo_accreditamento
            )}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="affidabilita_economica"
            required_types={allegatiStatic.filteredRequiredMedia(
              this.props.GlobalStore.stubs.tipo_media,
              "ente",
              "affidabilita_economica"
            )}
            ref_id={this.props.ente.id}
            readOnly={this.props.readOnly}
          />
        )}
        <Button
          type="primary"
          className="bg-green"
          loading={this.props.loading || false}
          icon="save"
          style={{ float: "right", marginTop: 50 }}
          disabled={this.props.readOnly}
          onClick={() => this.submitAffidabilita()}
        >
          Salva affidabilità economica
        </Button>
      </div>
    ) : null;
  }
}

export default AffidabilitaEconomicaForm;
