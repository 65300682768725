import React, { Component } from 'react';
import { Button, Card, Row, Drawer, Tooltip, message, Popconfirm } from 'antd';
import checkPermission from '@common/utils/check/permissions';
import FilterForm from '@components/filterForm';
import TableComponent from '@components/table';
import moment from 'moment';
import FileSaver from 'file-saver';
//import ModelServices from '@affidamento/services/Corsi';
import ModelServices from './../services/Corso';
import ComuniService from '@common/services/locations/Comuni';
import _ from '@common/utils/formatters/_';
import Corso from './Corso';
import JoinLongArray from '@common/components/JoinLongArray';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import EditLocation from './EditLocation';
import queryString from 'query-string';

const renderActions = (element, component) => {
  return <div style={{ display: 'inline-block' }}>

    {checkPermission.hasPermission(component.props.UserStore.user, ['viewCalendarioCorsi']) && !element.manuale &&
      <Tooltip placement="top" title="Dettaglio corso">
        <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push('/calendario/' + element.id)} />
      </Tooltip>
    }

    {checkPermission.hasPermission(component.props.UserStore.user, ['editCalendarioCorsi']) && !element.manuale && element.id_ente === component.props.UserStore.user?.ente?.id &&
      <Tooltip placement="top" title="Modifica posizione su mappa">
        <Button className="m2w" size="small" shape="circle" icon="pushpin" onClick={() => component.loadCorso(element)} />
      </Tooltip>
    }
    {checkPermission.hasPermission(component.props.UserStore.user, ['editCalendarioCorsi']) && element.manuale && element.id_ente === component.props.UserStore.user?.ente?.id &&
      <Tooltip placement="top" title="Modifica corso">
        <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.loadCorso(element)} />
      </Tooltip>
    }
    {checkPermission.hasPermission(component.props.UserStore.user, ['deleteCalendarioCorsi']) && element.manuale && element.id_ente === component.props.UserStore.user?.ente?.id &&
      <Tooltip placement="top" title="Cancella corso">
        <Popconfirm placement="leftTop" title="Sicuro di voler cancellare questo elemento" onConfirm={() => component.deleteCorso(element.id)} okText="Si" cancelText="No">
          <Button className="m2w" size="small" shape="circle" icon="delete" />
        </Popconfirm>
      </Tooltip>
    }
  </div>
}

const model = (component) => {

  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        render: element => <div style={{ width: 60 }}>
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Tipo',
        dataIndex: '',
        sorter: true,
        key: 'tipo_finanziamento',
        render: element => <div>
          {element.tipo_finanziamento}
        </div>
      },
      {
        title: 'Tipologia',
        dataIndex: '',
        sorter: true,
        key: 'tipologia',
        render: element => <div>
          {element.tipologia}
        </div>
      },
      {
        title: 'Settore',
        dataIndex: '',
        sorter: true,
        key: 'settore',
        render: element => <div>
          {element.settore}
        </div>
      },
      {
        title: 'Titolo del corso',
        dataIndex: '',
        sorter: true,
        key: 'oggetto',
        render: element => <div style={{ width: 200 }}>
          {element.oggetto || ''}
        </div>
      },
      {
        title: 'Livello',
        dataIndex: '',
        sorter: true,
        key: 'livello',
        render: element => {
          return (<div>
            {element.livello}
          </div>);
        }
      },
      {
        title: 'Durata',
        dataIndex: '',
        sorter: false,
        key: 'durata',
        render: element => {
          return (<div>
            {element.durata}
          </div>);
        }
      },
      {
        title: 'Metodologia didattica',
        dataIndex: '',
        sorter: false,
        key: 'metodologia_didattica',
        render: element => {
          return (<div>
            { element.manuale ? element.metodologia_didattica :

              JSON.parse(element.scheda_progetto_json).teachingProgramming?.AfcMethodAndHumanResourcesFormSection?.methodAndHumanResourcesList ?

                JoinLongArray(
                  ", ",
                  JSON.parse(element.scheda_progetto_json).teachingProgramming?.AfcMethodAndHumanResourcesFormSection?.methodAndHumanResourcesList
                    .map(el => el.methods),
                  50
                ) : ''
            }
          </div>);
        }
      },
      {
        title: 'Destinatari',
        dataIndex: '',
        sorter: false,
        key: 'destinatari',
        render: element => {
          return (<div>{
            JoinLongArray(
              ", ",
             [element.destinatari],
              50
            )
            }

          </div>);
        }
      },
      {
        title: 'Periodo di svolgimento',
        dataIndex: '',
        sorter: false,
        key: 'periodo',
        render: element => {
          let dal = element.dal ? moment(element.dal, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
          let al = element.al ? moment(element.al, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
          return <div>{dal + ' - ' + al} </div>
        }
      },
      {
        title: 'Costo',
        dataIndex: '',
        sorter: false,
        key: 'costo_previsto',
        render: element => {
          return (<div>
            {element.costo_previsto}
          </div>);
        }
      },
      {
        title: 'Ente erogatore',
        dataIndex: '',
        sorter: true,
        key: 'ente',
        render: element => <div>
          {element.ente}
        </div>
      },
      {
        title: 'Ciclo',
        dataIndex: '',
        sorter: true,
        key: 'ciclo',
        render: element => {
          return (<div>
            {element.ciclo}
          </div>);
        }
      },

    ]
  };
}

const filterFormModel = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'string',
                label: 'Titolo',
                name: 'titolo_progetto',
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'string',
                label: 'Ente erogatore',
                name: 'ente',
              }
            ]
          },

          {
            span: 8,
            items: [
              {
                type: 'string',
                label: 'Settore',
                name: 'settore',
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                name: 'provincia',
                options_props: 'province',
                onChange: 'selectedProvincia',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Comune',
                name: 'comune',
                options_props: 'comuni',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'datepicker',
                label: 'Periodo di erogazione',
                name: 'data_erogazione',
                format: 'DD/MM/YYYY',
                props: {
                  style: { width: '100%' },
                },
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Stato',
                name: 'stato',
                options: [
                  {
                    value: "",
                    label: "Tutti"
                  },
                  {
                    value: "7",
                    label: "Avvio progetto"
                  },
                  {
                    value: "8",
                    label: "Iscrizioni aperte"
                  },
                  {
                    value: "10",
                    label: "In erogazione"
                  },
                  {
                    value: "11",
                    label: "Completato"
                  },
                  {
                    value: "13",
                    label: "Sospeso"
                  },
                  {
                    value: "12",
                    label: "Revocato"
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente']); }
                  },
                ]
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Costo minimo',
                name: 'costo_minimo',
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Costo massimo',
                name: 'costo_massimo',
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          },
          {
            items: [
              {
                name: 'showmap',
                type: 'button',
                text: 'Mostra/nascondi mappa',
                btn_type: 'primary',
                onClick: 'showMap'
              }
            ]
          }
        ]
      },
    ]
  };
}

class TabellaCalendarioCorsi extends Component {
  base_url = '/calendario'
  default_order = 'id'

  state = {
    loading: false,
    loaded: false,
    title: 'Aggiungi corso',
    corso: null,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
    showMap: false,
    selected_provincia: null,
    comuni: [],
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search)
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);

    this.setState({ loading: true });
    ModelServices.list({ ...params,
      fields: ['scheda_progetto_json', 'id', 'titolo_progetto', 'ente', 'id_ente', 'ente.denominazione', 'edizione', 'data_effettiva_avvio', 'data_presunta_avvio', 'stato', 'stato_flusso', 'stato_flusso_description'].join(','),
    }, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data,
        loaded: true
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }


  loadCorso(corso) {
    this.setState({
      corso: corso,
      title: 'Modifica corso',
      open_drawer: true
    });
  }

  deleteCorso(id) {
    this.setState({ loading: true },
      () => {
        ModelServices.delete(
          id,
          (res) => {
            message.success("Corso cancellato con successo");
            this.setState({ loading: false },
              () => { this.refresh() })
          },
          () => this.setState({ loading: false }))
      });

  }

  locationToArry(location) {
    const parsed = /\((-*[0-9][0-9.]*),(-*[0-9][0-9.]*)\)/g.exec(location);
    return [parsed[1], parsed[2]];
  }

  render() {
    const position = [39.202560, 16.318307]
    const L = require('leaflet');

    const myIcon = L.icon({
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        iconSize: [25,41],
        iconAnchor: [12, 41],
        popupAnchor: [0, 0],
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        shadowSize: [41,41],
        shadowAnchor: [12, 41],
    });

    return (

      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.state.params}
          history={this.props.history}
          date_fields={['data_erogazione']}
          form_elements={{
            showMap: form => {
              this.setState({showMap: !this.state.showMap})
            },
            province: this.props.GlobalStore.stubs.provincia,
            selectedProvincia: data => {
              this.setState({
                selected_provincia: data,
                comuni: []
              }, () => {
                //this.formSelRef.props.form.setFieldsValue({ comune: null })
                ComuniService.all(
                  { id_provincia: data },
                  res => {
                    this.setState({
                      comuni: res.data.data || []
                    }, () => {
                     // this.formSelRef.props.form.setFieldsValue({ comune: null });
                    });
                  },
                  () => null
                );
              });
            },
            comuni: this.state.comuni.map(e => {
              return {
                key: 'comune_' + e.id,
                value: e.id,
                label: e.comune
              }
            })
          }
          }
        />

        {this.state.showMap &&
          <Row>
            <Card
              className="shadow no-pad"
              bordered={false}
              style={{ marginBottom: 15 }}>

              <MapContainer center={position} zoom={9} scrollWheelZoom={false} style={{ height: "100vh" }}>
                <TileLayer
                  attribution='&copy; SILF'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {this.state.data?.data.filter(element => element.location).map(element =>
                  <Marker position={this.locationToArry(element.location)} icon={myIcon} key={"corso_" + element.manuale + " " + element.id}>
                    <Popup>
                      <p>{element.oggetto}</p>
                      <p>{(element.dal ? moment(element.dal, 'YYYY-MM-DD').format('DD/MM/YYYY') : '') + ' - ' + (element.al ? moment(element.al, 'YYYY-MM-DD').format('DD/MM/YYYY') : '')}</p>
                    </Popup>
                  </Marker>
                )}
              </MapContainer >
            </Card>
          </Row>
        }
        <Row>
            <Card
              className="shadow no-pad"
              bordered={false}>

            <Row type="flex" justify="start" className="m10h">
              {checkPermission.hasPermission(this.props.UserStore.user, ['addCalendarioCorsi']) && this.props.UserStore.user.ente && this.props.UserStore.user.ente.accreditato &&
                <div className="m5h m5w">
                  <Button className="m5h bg-green" icon="plus" onClick={() => this.setState({ open_drawer: true, title: 'Aggiungi corso', corso: {manuale : true} })} loading={this.state.loading}>Inserisci nuovo corso</Button>
                </div>}
                <div className="m5h m5w">
                  <Button className="m5h bg-green" icon="plus" onClick={() => {
                    this.setState({ loading: true}, () => {
                      let params = queryString.parse(this.props.location.search);
                      ModelServices.export(params, (res) => {
                        const _blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver.saveAs(_blob, 'calendario_corsi.ods');
                        this.setState({ loading: false });
                      }, () => this.setState({ loading: false }));
                      })
                  }} loading={this.state.loading}>Esporta elenco</Button>
                </div>
            </Row>
            {/*his.state.loaded &&*/
              <TableComponent
                rowKey={(record) => record.id_table}
                data={this.state.data}
                model={model(this)}
                table_props={{ rowKey: 'id_table' }}
                size="medium"
                ref="table_component"
                loading={this.state.loading}
                with_server_pagination={true}
                updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
              />
            }

          </Card>
        </Row>

        {this.state.corso &&
          <Drawer
            title={this.state.title}
            placement="right"
            closable={true}
            onClose={() => this.setState({ open_drawer: false })}
            visible={this.state.open_drawer}
            width={window.innerWidth - (window.innerWidth / 100 * 5)}
            destroyOnClose={true}
          >
            {this.state.corso.manuale &&
              <Corso
                {...this.props}
                corso={this.state.corso}
                close={() => { this.setState({ open_drawer: false }); this.refresh(); }}
              ></Corso>
            }
            {!this.state.corso.manuale &&
              <EditLocation
                {...this.props}
                corso={this.state.corso}
                close={() => { this.setState({ open_drawer: false }); this.refresh(); }}
              ></EditLocation>
            }
          </Drawer>
        }
      </div>
    );
  }
}

export default TabellaCalendarioCorsi;
