import React, { Component } from 'react';

import { Button, Card, Drawer, List, message } from 'antd';

import checkPermissions from '@common/utils/check/permissions';
import InfoIspettoreForm from '../forms/InfoIspettoreForm';
import UserServices from '../../utente/services/User';
import DocSpan from "@common/components/DocSpan";

class InfoIspettore extends Component {

  state = {
    loading: false,
    element_values: {}, // valori elemento da modificare
    open_drawer: false,
    drawer_title: "Aggiungi Info",
    userExtraInfo: null
  }

  update() {
    //this.props.onUpdate({documenti})
  }

  create() {
    this.setState({ element_values: {}, drawer_title: 'Aggiungi info' });
    this.setState({ open_drawer: true });
  }

  edit(item) {
    this.setState({ element_values: { ...item }, drawer_title: 'Aggiorna info' });
    this.setState({ open_drawer: true });
  }

  async addInfo(data, file) {

    if (data.esterno_amministrazione === "0"){
    
      if (!file || file === null) {
        message.error("Carica il curriculum");
        return;
      }

      let valid_indirizzo = await this.extra_info.dati_sede_lavoro.formRef.props.form.validateFields();
      if (!valid_indirizzo) return;
    }
     
    if (!this.state.loading) {
      this.setState({ loading: true },
        () => {
          UserServices.addUserExtraInfo(data, this.props.match.params.id, file, res => {
            message.success("Operazione effettuata con successo");
            this.props.onUpdate(res.data.data);
            this.setState({ loading: false, open_drawer: false });
          }, () => this.setState({ loading: false }))
        })
    }

  }

  async editInfo(item_id, data, file) {

    if (data.esterno_amministrazione === "0") {

      if ((!file || file === null) && this.state.userExtraInfo?.media?.length < 1) {
        message.error("Carica il curriculum");
        return;
      }

      let valid_indirizzo = await this.extra_info.dati_sede_lavoro.formRef.props.form.validateFields();
      if (!valid_indirizzo) return;
    }

    if (!this.state.loading) {
      this.setState({ loading: true },
        () => {
          UserServices.editUserExtraInfo(item_id, data, this.props.match.params.id, file, res => {
            message.success("Operazione effettuata con successo");
            this.props.onUpdate(res.data.data);
            this.setState({ loading: false, open_drawer: false });
          }, () => this.setState({ loading: false }))
        })
    }
  }

  deleteInfo(id) {
    UserServices.deleteUserExtraInfo(this.props.match.params.id, id, () => {
      message.success("Operazione effettuata con successo");
      this.props.onUpdate(null);
    }, () => null)
  }

  getSource() {
    let items = [];
    if (this.state.userExtraInfo) {
      items.push(this.state.userExtraInfo);
    }
    try {
      return items;
    } catch (e) {
      return []
    }
  }



  getItemActions(item) {
    return (checkPermissions.hasPermission(this.props.UserStore.user, ['EditExtraInfo', 'adminPermissions']))
      ? [
        /*<Popconfirm placement="leftTop" title="Sicuro di voler rimuovere questo elemento?" onConfirm={() => this.deleteInfo(item.id)} okText="Si" cancelText="No">
          <Button type="danger" size="small" ghost>Elimina</Button>
        </Popconfirm>,*/
        <Button type="primary" size="small" onClick={() => this.edit(item)} ghost>Modifica</Button>
      ]
      : null
  }


  componentDidMount() {
    if (this.props.userExtraInfo)
      this.setState({ userExtraInfo: this.props.userExtraInfo })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userExtraInfo !== this.props.userExtraInfo) {
      this.setState({ userExtraInfo: this.props.userExtraInfo })
    }
  }

  renderCurriculum() {

    let media = this.state.userExtraInfo?.media?.filter(el => el.tipo.sottodominio === 'curriculum_dipendente_regionale');

    return media && <div>
      {
        media.map(l => {
          return <div key={'com_ost_' + l.id}>
            <p style={{ display: 'inline-block' }}><strong>Curriculum Vitae: </strong></p>
            <DocSpan key={l.id} file={l} />
          </div>
        })
      }
    </div>
  }

  render() {

    return (
      <div>
        {(!this.state.userExtraInfo?.id && checkPermissions.hasPermission(this.props.UserStore.user, ['EditExtraInfo', 'adminPermissions'])) ?
          <Button type="primary" ghost size="small" style={{ marginBottom: 12 }} onClick={() => this.create()}>Aggiungi</Button>
          : null
        }

        <List
          dataSource={this.getSource()}
          renderItem={item => (
            <Card
              style={{ marginTop: 1 }}
              bordered={false}
              className="small-pad"
            >
              <List.Item
                actions={this.getItemActions(item)}
              >
                <div>
                  <p><strong>Azienda di appartenenza: </strong> {item.azienda_appartenenza} </p>
                  <p><strong>Esterno all'amministrazione: </strong> {parseInt(item.esterno_amministrazione) === 1 ? "si" : "no"} </p>
                  {<div>
                    {
                      parseInt(item.esterno_amministrazione) === 0 && <div>
                        <p><strong>Dipartimento: </strong> {item.settoreRegionale.dipartimento.nome || ""}</p>
                        <p><strong>Settore/Unità operativa: </strong> {item.settoreRegionale.nome || ""}</p>
                        <p><strong>Sede di lavoro: </strong> {item.indirizzoSedeLavoro ? item.indirizzoSedeLavoro.full_address : ""}</p>
                        {this.renderCurriculum()}
                      </div>
                    }


                  </div>} </div>
              </List.Item>
            </Card>
          )}
        />

        <Drawer
          title={this.state.drawer_title}
          width={720}
          placement="right"
          onClose={() => this.setState({ open_drawer: false })}
          maskClosable={true}
          visible={this.state.open_drawer}
          style={{
            overflow: 'auto',
          }}
        >
          <InfoIspettoreForm
            {...this.props}
            ref={(ref) => this.extra_info = ref}
            loading={this.state.loading}
            values={this.state.element_values}
            submit={(data, file) => {
              (!this.state.element_values.id) ?
                this.addInfo(data, file) :
                this.editInfo(this.state.element_values.id, data, file)
            }} />
        </Drawer>
      </div>
    );
  }
}
export default InfoIspettore;
