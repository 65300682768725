const initialState = {
  language: 'it_IT',
  API_URL: '',
  BASE_URL: '',
  PENDING_SET_TIMEOUT: 10000,
  open_menu: false,
  menu_component: [],
  sidebarMargin: 0,


  open_sidebar: true,

  open_right_drawer: false,
  right_drawer_component: null,
  sidebarMarginRight: 0,

  title_modal: "",
  open_modal: false,
  modal_component: null,
  modal_footer: null,
  on_ok_modal: null,
  on_cancel_modal: null,

  stubs: {
    tipo_corso: [],
    tipo_titolo_studio: [],
    tipo_ente: [],
    comune: [],
    regione: [],
    continente: [],
    nazione: [],
    provincia: [],
    tipo_accreditamento: [],
    tipo_aula: [],
    tipo_laboratorio: [],
    tipo_aula_struttura: [],
    tipo_funzioni: [],
    tipo_media: [],
    tipologia_uso_locali: [],
    tipo_rapporto_lavoro: [],
    tipo_attore: [],
    utenze_speciali: [],
    tipo_soggetto_accreditato: [],
    settori: [],
    professioni: [],
    dettagli_funzioni: [],
    divisione_forma_giuridica: [],
    descrizione_forma_giuridica: [],
    possesso: [],
    tipo_attrezzatura: [],
    destinazione_catastale: [],
    mappatura_attivita_formativa: {
      macrotipologie: [],
      tipo_aula: []
    },
    days_integrazione: 30,
    motivo_revoca: []
  },

  current_page: "",
};

/**
 * Global Store
 *
 * ```json
 * initialState = {
 *   language: 'it_IT',
 *   open_menu: false,
 *   menu_component: null,
 *   open_right_drawer: false,
 *   right_drawer_component: null,
 *   title_modal: "",
 *   open_modal: false,
 *   modal_component: null,
 *   modal_footer: null,
 *   on_ok_modal: null,
 *   on_cancel_modal: null,
 *   current_page: "",
 * }
 * ````
 *
 *
 * @param  {object} state  Stato corrente dello store
 * @param  {Object} action Azione del dispatch
 * @return {Object}        Nuovo stato
 */
export default function reducer(state = initialState, action = {}) {
  /** In base all'azione */
  switch (action.type) {
    case "SET_API_URL":
      return {
        ...state,

        //API_URL: action.api_url || "",
        API_URL: (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_API_URL : process.env.REACT_APP_API_URL) || "",
        PENDING_SET_TIMEOUT: (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_PENDING_SET_TIMEOUT : process.env.REACT_APP_PENDING_SET_TIMEOUT)  || 15000,
      };

    case "OPEN_SIDEBAR":
      return {
        ...state,
        open_sidebar: action.val,
      };

    case "SET_BASE_URL":
      return {
        ...state,
        BASE_URL: (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_BASE_URL : process.env.REACT_APP_BASE_URL) || "",
        PENDING_SET_TIMEOUT: (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_PENDING_SET_TIMEOUT : process.env.REACT_APP_PENDING_SET_TIMEOUT)  || 15000,
      };

    case "SET_URLS":
      return {
        ...state,
        //API_URL: action.url.apiUrl || "",
        API_URL: (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_API_URL : process.env.REACT_APP_API_URL) || "",
        BASE_URL: (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_BASE_URL : process.env.REACT_APP_BASE_URL) || "",
        PENDING_SET_TIMEOUT: (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_PENDING_SET_TIMEOUT : process.env.REACT_APP_PENDING_SET_TIMEOUT) || 15000,
      }

    case "SET_CURRENT_PAGE":
      return {
        ...state,
        current_page: action.current_page || "",
      };

    case "SET_MENU_COMPONENT":
      return {
        ...state,
        menu_component: action.component || null,
      };

    case "SET_GLOBAL_STORE_STUB":
      return {
        ...state,
        stubs: action.obj
      };

    case "ADD_MENU_COMPONENT_ELEMENT":
      return {
        ...state,
        menu_component: [...state.menu_component, action.component],
      };

    case "ADD_MENU_COMPONENT_MULTIPLE_ELEMENTS":
      return {
        ...state,
        menu_component: [...state.menu_component, ...action.components],
      };

    case "NO_SIDEBAR":
      return {
        ...state,
        //open_menu: true,
        sidebarMargin: 0,
      };

    case "OPEN_MENU":
      return {
        ...state,
        open_menu: true,
        sidebarMargin: 200,
      };

    case "CLOSE_MENU":
      return {
        ...state,
        open_menu: false,
        sidebarMargin: 70,
      };

    case "OPEN_RIGHT_DRAWER":
      return {
        ...state,
        open_right_drawer: true,
        right_drawer_component: action.component || null,
        sidebarMarginRight: 36
      };

    case "CLOSE_RIGHT_DRAWER":
      return {
        ...state,
        open_right_drawer: false,
        right_drawer_component: null,
        sidebarMarginRight: 0
      };

    case "OPEN_MODAL":
      return {
        ...state,
        title_modal: action.title || "",
        open_modal: true,
        modal_component: action.component || null,
        modal_footer: action.footer_component || null,
        on_ok_modal: action.on_ok || null,
        on_cancel_modal: action.on_cancel || null,
      };

    case "CLOSE_MODAL":
      return {
        ...state,
        title_modal: "",
        open_modal: false,
        modal_component: null,
        modal_footer: null,
        on_ok_modal: null,
        on_cancel_modal: null,
      };

    case "GLOBAL_STORE_SET_CUSTOM":
      return {
        ...state,
        [action.obj]: action.value
      };

    case "GLOBAL_STORE_SET_MULTIPLE_CUSTOM":
      return {
        ...state,
        ...action.obj
      };

    default:
      return state;
  }
}
