import React, { Component } from 'react';

import { Card, notification } from 'antd';
import TableComponent from '@components/table';

import ModelServices from '@accreditamento/services/Ente';

import FilterForm from '@common/components/filterForm';
import _ from '@common/utils/formatters/_';

const model = (component) => {
  let { renderActions } = component.props;
  if (!renderActions) renderActions = [];

  let { extraActions } = component.props;
  if (!extraActions) extraActions = [];

  return {
    with_server_pagination: true, // metti paginazione lato server
    columns: [
      ...renderActions,
      {
        title: 'Nome',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'nome',
        render: element => <div>{element.anagrafica ? element.anagrafica.nome : ''}</div>,
      },
      {
        title: 'Cognome',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'cognome',
        render: element => <div>{element.anagrafica ? element.anagrafica.cognome : ''}</div>,
      },
      {
        title: 'Codice Fiscale',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'cf',
        render: element => <div>{element.anagrafica ? element.anagrafica.cf : ''}</div>,
      },
      ...extraActions
    ]
  };
}

const filterModel = () => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Nome',
                type_string: 'text', // field type
                name: 'nome',
              }
            ]
          },
          {
            items: [
              {
                type: 'input',
                label: 'Cognome',
                type_string: 'text', // field type
                name: 'cognome',
              }
            ]
          },
          {
            items: [
              {
                type: 'input',
                label: 'Codice fiscale',
                type_string: 'text', // field type
                name: 'cf',
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  }
}

class DocentiAccreditati extends Component {

  default_order = 'cognome'

  state = {
    loading: false,
    data: {
      total: 0,
      current: 1,
      data: []
    }
  }

  componentDidMount() {
    this.callAndUpdate(this.props)
  }

  getUserUrl(item) {
    return "/docente/" + item.id
  }

  getBaseUrl() {
    return (this.props.base_url) ? this.props.base_url : "/docente";
  }

  setDefaultSearchParams(str) {
    str.fields = 'id,anagrafica.nome,anagrafica.cognome,anagrafica.cf';
    return str
  }

  callAndUpdate(props) {

    let { params, pager } = _.getFilterParam(props, this.default_order);

    params = this.props.setDefaultSearchParams ? this.props.setDefaultSearchParams(params) : this.setDefaultSearchParams(params);

    this.setState({ loading: true });
    ModelServices.docenti(params, this.props.id_ente, res => {
      let data = _.getResponse(res);
      if(!data || !data.data || data.data.length < 1){
        notification.warning({
          placement: 'topLeft',
          duration: 5,
          message: "Docenti accreditati",
          description: 'Al momento non ci sono docenti disponibili',
        });
      }

      this.setState({
        loading: false,
        data: data,
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, () => {
      this.setState({ loading: false });
    })
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.callAndUpdate(this.props.location.search)
    }
  }


  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.callAndUpdate(this.props)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.getBaseUrl(), obj);
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterModel()}
          base_url={this.getBaseUrl()}
          search={this.props.location.search}
          history={this.props.history}
        />
        <Card
          className="shadow no-pad"
          bordered={false}
        >
          <TableComponent
            data={this.state.data} model={(this.props.table_model) ? this.props.table_model(this) : model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}
export default DocentiAccreditati;
