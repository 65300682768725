
import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';

import error403 from '../../assets/err403.svg';
import error404 from '../../assets/err404.svg';
import error500 from '../../assets/err500.svg';

import '../less/Exception.less';

const styles = {
  exception: 'component-exception-exception',
  imgBlock: 'component-exception-imgBlock',
  imgEle: 'component-exception-imgEle',
  content: 'component-exception-content',
  desc: 'component-exception-desc',
  actions: 'component-exception-actions'
};

const config = {
  403: {
    img: error403,
    title: '403',
    desc: 'Sorry, you don\'t have access to this page'
  },
  404: {
    img: error404,
    title: '404',
    desc: 'Sorry, the requested page could not be found'
  },
  500: {
    img: error500,
    title: '500',
    desc: 'Sorry, something went wrong with the server'
  }
};

export default class Exception extends React.Component {

  static defaultProps = {
      type: 404,
      backText: 'back to home',
      redirect: '/'
  }

  render() {
    let { title, desc, backText, className, type, linkElement, img, actions, redirect } = this.props;
    const LinkElement = linkElement || `a`;

    return <div className={classNames(styles.exception, className)}>
      <div class={styles.imgBlock}>
        <div class={styles.imgEle} style={{ backgroundImage: `url(${img || config[type].img})` }}></div>
      </div>
      <div className={styles.content}>
        <h1>{title || config[type].title}</h1>
        <div className={styles.desc}>{desc || config[type].desc}</div>
        <div className={styles.actions}>
          {actions ||
            <LinkElement to={redirect} href={redirect}>
              <Button type="primary">
                {backText}
              </Button>
            </LinkElement>
          }
        </div>
      </div>
    </div>
  }
}