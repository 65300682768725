import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'antd';

import IspezioneVerbale from './IspezioneVerbale';

class IspezioneVerbali extends Component {

  state = {
    loaded: false,
    loading: false
  }

  render() {
    return <Row gutter={16}>
        <Col span={24}>
          <h3>Verbale/i</h3>
        </Col>
        {
          this.props.ispezione.verbali.sort((a,b)=>{return a.id > b.id ? 1 : -1}).map(n => {
            return <Col span={12} key={n.id}>
              <div style={{marginBottom: 16}}>
                <IspezioneVerbale
                  accreditamento={this.props.accreditamento}
                  ispezione={this.props.ispezione}
                  refreshInline={(data)=>this.props.refreshInline(data)}
                  refresh={()=>this.props.refresh()}
                  refreshAll={()=>this.props.refreshAll()}
                  verbale={n}
                />
              </div>
            </Col>
          })
        }
      </Row>
	}

}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(IspezioneVerbali);
/**
 * Posso editare solo se sono ispettore_1 o ispettore_2 e ispezione in stato in istruttoria
 */