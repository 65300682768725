import React, { Component } from 'react';
import { connect } from "react-redux";
import DocSpan from '@common/components/DocSpan';
import DownloadUpload from '@common/components/DownloadUpload';


class ComunicazioneOstativa extends Component {

  state = {

  }

  renderCom() {
      return <div>
        <p>Scarica la comunicazione ostativa e carica il file firmato digitalmente</p>
        <DownloadUpload
          accept={'.p7m,.pdf'}
          downloading={this.props.downloadingComunicazione}
          uploading={this.props.uploadingComunicazione}
          download={() => this.props.downloadComunicazione()}
          upload={(file) => this.props.uploadComunicazione(file)}
        >
        </DownloadUpload>
      </div>
  }

  renderFileFirmato() {
    return <div style={{ marginTop: 18 }}>
      {
        this.props.media.map(l => {
          return <div key={'com_ost_' + l.id}>
            <p style={{ display: 'inline-block' }}>Comunicazione ostativa firmata: </p>
            <DocSpan key={l.id} file={l} />
            {l.note && l.note.map(n => {
              return <div key={"nota_" + n.id}><small><strong>{n.user ? n.user.anagrafica?.nome + ' ' + n.user.anagrafica?.cognome : " - "}:</strong> {n.note}</small></div>
            })}
          </div>
        })
      }
    </div>
  }

  render() {
    return this.props.canView ?
     <div style={{marginTop: 16}}>
      <p style={{ display: 'inline-block' }}><strong>Comunicazione ostativa: </strong>{this.props.note}</p>
        {this.props.canSendComunicazioneOstativa? this.renderCom() : null}
        {this.props.media? this.renderFileFirmato() : null}
      </div> : null;

  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ComunicazioneOstativa);
