import React, { Component } from 'react';
import { Spin, Button, Drawer, Tooltip, Icon } from 'antd';
import CommonForm from '@common/components/form';
import TableElencoPersonale from '../../components/ElencoPersonale/TableElencoPersonale';
import eventBroker from '@common/utils/services/EventBroker';
import affidamentoStatic from '@common/utils/static/affidamento';
import PartnerTable from '../../components/soggettiCorrelati/PartnerTable'
import SedeService from '@modules/accreditamento/services/Sede';
import PropTypes from 'prop-types';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';
import CreatePartner from '@modules/affidamento/components/soggettiCorrelati/CreatePartner';
import AuleTable from '@modules/affidamento/components/soggettiCorrelati/AuleTable';
import DatiEnteSingolo from '@modules/affidamento/components/soggettiCorrelati/DatiEnteSingolo';
import checkPermission from '@common/utils/check/permissions';
import formatter from '@common/utils/formatters/_';
import comuniService from '@common/services/locations/Comuni.js';

//TODO: inserire subjectType come elemento del form visibile solo lato amministrazione regionale
//TODO: rivedere il caricamento dei dati dell'ente
const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'ente_singolo_capofila',
                type: 'title',
                element: 'h4',
                text: 'Ente singolo o capofila di partenariato',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'ente_singolo_capofila_dati',
                type: 'child',
                child: <div style={{ marginBottom: 16 }}>
                  <React.Fragment>
                    <div
                      ref={(ref) => component.dati_capofila_container = ref}
                    >
                      <DatiEnteSingolo
                        {...component.props}
                        readOnly={component.props.anteprima || component.props.readOnly}
                        enteSisfo={true}
                        capofila={true}
                        data={component.state.dati.domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection}
                        ref={(ref) => component.dati_capofila = ref}
                        subform={true}
                      ></DatiEnteSingolo>
                    </div>
                  </React.Fragment>
                </div>
              }
            ],
          },
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'soggetti_partner',
                type: 'title',
                element: 'h4',
                text: <>Soggetti Partner <Tooltip placement="top" title="I dati e le informazioni inserite nel presente paragrafo saranno utilizzati per valutare la coerenza interna del progetto formativo (area di valutazione F, criterio F.1, F.2 e F.3)."><Icon className="m5w" type="info-circle" /></Tooltip>
                </>,
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcPartnerSubjectsSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'radio',
                label: <>Forma giuridica (Classificazione ISTAT delle forme giuridiche) <Tooltip placement="top" title="I dati e le informazioni inserite nel presente paragrafo saranno utilizzati per valutare la coerenza interna del progetto formativo (area di valutazione F, criterio F.1, F.2 e F.3)"><Icon className="m5w" type="info-circle" /></Tooltip>
                </>,
                name: 'domanda.relatedSubjects.AfcPartnerSubjectsSection.legalForm',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcPartnerSubjectsSection ? true : false; }
                  },
                ],
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "ATI",
                    label: "ATI"
                  },
                  {
                    value: "ATS",
                    label: "ATS"
                  }
                ],
                props: {
                  onChange: (obj) => {
                  },

                }

              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'radio',
                label: 'Stato Forma giuridica partenariato',
                name: 'domanda.relatedSubjects.AfcPartnerSubjectsSection.statuslegalForm',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcPartnerSubjectsSection ? true : false; }
                  },
                ],
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Costituita"
                  },
                  {
                    value: "2",
                    label: "Da costituire"
                  }
                ],
                props: {
                  onChange: (obj) => {
                  },

                }

              }
            ]
          },
        ]
      },

      {
        cols: [
          {
            items: [
              {
                name: 'lista_partner_header',
                type: 'title',
                element: 'h4',
                text: 'Lista dei  partner (ATI/ATS)',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcPartnerListSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'lista_partner',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcPartnerListSection ? true : false; }
                  },
                ],
                child: component.props.domanda.relatedSubjects.AfcPartnerListSection && <div style={{ marginBottom: 16 }}>
                  {!component.props.anteprima && !component.props.readOnly &&
                    <Button className="m5h" type="primary" icon="plus" onClick={() => component.setState({ open_drawer: true })} loading={component.state.loading}>Aggiungi partner</Button>
                  } <PartnerTable
                    {...component.props}
                    readOnly={component.props.anteprima || component.props.readOnly}
                    partnerList={component.state.dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList}
                    deletePartner={(p_iva) => component.deletePartner(p_iva)}
                    updatePartner={(data) => component.updatePartner(data)}
                  >
                  </PartnerTable>

                </div>
              }
            ],
          },
        ],
      },


      {
        cols: [
          {
            items: [
              {
                name: 'sede_svolgimento_header',
                type: 'title',
                element: 'h4',
                text: 'Sede di svolgimento ',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcHeadquartersSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                name: 'domanda.relatedSubjects.AfcHeadquartersSection.provincia',
                options_props: 'province',
                onChange: 'getComuni',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcHeadquartersSection ? true : false; }
                  },
                ]
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Comuni',
                name: 'domanda.relatedSubjects.AfcHeadquartersSection.comune',
                options_props: 'comuni',
                onChange: 'getSedi',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcHeadquartersSection ? true : false; }
                  },
                ]
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Seleziona una sede',
                name: 'domanda.relatedSubjects.AfcHeadquartersSection.id',
                options_props: 'sedi',
                onChange: 'setSede',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcHeadquartersSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'lista_aule',
                label: 'Ambienti didattici della sede di svolgimento',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcHeadquartersSection }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <AuleTable
                    {...component.props}
                    readOnly={component.props.anteprima || component.props.readOnly}
                    auleList={component.state.dati.domanda.relatedSubjects.AfcHeadquartersSection.classroomList}
                    checkAula={(ckecked, data) => component.checkAula(ckecked, data)}
                  >
                  </AuleTable>

                </div>
              }
            ],
          },
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'elenco_personale_impiegato',
                type: 'title',
                element: 'h4',
                text: 'Elenco del personale impiegato nella realizzazione del progetto ed in forza agli Enti proponenti',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'tabella_elenco_personale_impiegato',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  {<TableElencoPersonale
                    {...component.props}
                    checkPersonale={(ckecked, data) => component.checkPersonale(ckecked, data)}
                    elencoPersonale={component.props.personaleEnti}
                    readOnly={component.props.anteprima || component.props.readOnly}

                  />}
                </div>
              }
            ],
          },
        ],
      },

      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Numero docenti/formatori/tutor coinvolti nell’attività',
                type_string: 'text', // field type
                name: 'domanda.relatedSubjects.AfcProjectStaffSection.numberOfTeachers',
                props: {
                  disabled: true,
                  placeholder: 'Numero docenti/formatori/tutor coinvolti nell’attività'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ]
              }
            ]
          },
        ]
      },

      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Seleziona un referente per le informazioni',
                name: 'domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative.id',
                // le opzioni come le selects
                options_props: 'referenti_progetto_info',

                props: {
                  //onChange: 'setReferenteProgettoInfo',
                  onChange: (obj) => {
                    component.setReferenteProgettoInfo(obj);
                  },
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },



      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Nome',
                type_string: 'text', // field type
                name: 'domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative.nome',
                props: {
                  disabled: true,
                  placeholder: 'Nome'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ]
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Cognome',
                type_string: 'text', // field type
                name: 'domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative.cognome',
                props: {
                  disabled: true,
                  placeholder: 'Cognome'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ]
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Funzione',
                type_string: 'text', // field type
                name: 'domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative.funzione',
                props: {
                  placeholder: 'Funzione',
                  disabled: true
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ]
              }
            ]
          },
        ]
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'integer',
                label: 'Ore previste',
                name: 'domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative.ore_previste',
                props: {
                  disabled: component.props.anteprima ||
                    component.props.readOnly ||
                    component.state.dati.domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative === null ||
                    component.state.dati.domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative.id === null,
                  placeholder: 'Ore previste',
                  min: 0
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Principali compiti assegnati',
                type_string: 'text', // field type
                name: 'domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative.principali_compiti',
                props: {
                  disabled: component.props.anteprima ||
                    component.props.readOnly ||
                    component.state.dati.domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative === null ||
                    component.state.dati.domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative.id === null,
                  placeholder: 'Principali compiti assegnati'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.relatedSubjects.AfcProjectStaffSection ? true : false; }
                  },
                ]
              }
            ]
          },

        ]
      },




      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit_soggetti_correlati',
                text: 'Salva i dati',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.anteprima && !component.props.readOnly; }
                  }
                ],
                //onClick: 'showDrawer',
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'submit',
  };
}


export default class SoggettiCorrelatiForm extends Component {

  constructor(props) {
    super(props);
    this.getBackRelatedSubjects = this.getBackRelatedSubjects.bind(this);
    eventBroker.on('getBackRelatedSubjects', this.getBackRelatedSubjects);

    this.resetStaffSection = this.resetStaffSection.bind(this);
    eventBroker.on('resetPersonaleEnti', this.resetStaffSection);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  }

  state = {
    loading: true,
    comuni: [],
    sedi: [],
    tabLoaded: false,
    dati: {
      domanda: {}
    }
  }

  componentDidMount() {
    this.setState({
      tabLoaded: false
    });
    this.load(this.props)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.domanda !== this.props.domanda) this.load(newProps)
  }


  load(props) {
    this.setState({
      tabLoaded: true,
      dati: {
        domanda: props.domanda
      }
    });

    if (props.domanda.relatedSubjects.AfcHeadquartersSection.provincia && this.props.id_macrotipologia) {
      SedeService.getComuniSediAccreditate(props.domanda.relatedSubjects.AfcHeadquartersSection.provincia, this.props.id_macrotipologia, (res) => {

        let comuni = res.data.data;
        if (this.state.dati.domanda.relatedSubjects && this.state.dati.domanda.relatedSubjects.AfcHeadquartersSection && this.state.dati.domanda.relatedSubjects.AfcHeadquartersSection.comune
          && !comuni.find(el => el.id === this.state.dati.domanda.relatedSubjects.AfcHeadquartersSection.comune)) {
          comuniService.view(this.state.dati.domanda.relatedSubjects.AfcHeadquartersSection.comune, res2 => {
            comuni.push(res2.data.data)
            this.setState({comuni:comuni})
          })
        } else {
          this.setState({
            comuni: res.data.data,
          });
        }

      }, () => this.setState({
        loading: false,
      }))
      if (props.domanda.relatedSubjects.AfcHeadquartersSection.comune) {
        SedeService.getSediAccreditate(props.domanda.relatedSubjects.AfcHeadquartersSection.comune, this.props.id_macrotipologia, (res) => {
         
         let sedi = res.data.data;
         if (this.state.dati.domanda.relatedSubjects && this.state.dati.domanda.relatedSubjects.AfcHeadquartersSection && this.state.dati.domanda.relatedSubjects.AfcHeadquartersSection.id
          && !sedi.find(el => el.id === this.state.dati.domanda.relatedSubjects.AfcHeadquartersSection.id)) {
            
            SedeService.view({
              expand: [
                'indirizzo'
              ].join(',')
            },  
              this.state.dati.domanda.relatedSubjects.AfcHeadquartersSection.id, res3 => {
            sedi.push(res3.data.data)
            this.setState({
              sedi: sedi,
              loading: false,
            });
          })
        } else {
          this.setState({
            sedi: sedi,
            loading: false,
          });
        }
          
        }, () => this.setState({
          loading: false,
        }))
      } else {
        this.setState({ loading: false })
      }
    } else {
      this.setState({ loading: false })
    }
  }

  getBackRelatedSubjects() {
    return this.formRef?.props.form.getFieldsValue();
  }

  resetStaffSection() {
    let domanda = this.props.domanda;
    domanda.relatedSubjects.AfcProjectStaffSection.staffPersonalDataList = [];
    domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative = null;
    domanda.relatedSubjects.AfcProjectStaffSection.numberOfTeachers = 0;

    //resetto la select
    let d = {
      domanda: {
        relatedSubjects: {
          AfcProjectStaffSection: {
            projectRepresentative: {
              id: null
            }
          }
        }
      }
    };

    if(this.formRef) this.formRef.props.form.setFieldsValue(d);

    this.setState({
      dati: {
        domanda: domanda
      }
    });
  }

  getReferentiProgetto() {
    if (this.props.domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection?.referenti) {
      return this.props.domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection.referenti.map(e => {
        return {
          key: e.id,
          label: e.nome + ' ' + e.cognome,
          value: e.id
        }
      });
    } else {
      return [];
    }
  }

  getReferentiProgettoInfo() {
    if (this.props.domanda.relatedSubjects.AfcProjectStaffSection?.staffPersonalDataList) {
      return this.props.domanda.relatedSubjects.AfcProjectStaffSection?.staffPersonalDataList.map(e => {
        return {
          key: e.id,
          label: e.nome + ' ' + e.cognome,
          value: e.id
        }
      });
    } else {
      return [];
    }
  }

  setSede(value) {
    let sede = this.state.sedi?.find(el => el.id === value);

    let domanda = this.state.dati.domanda;
    domanda.relatedSubjects.AfcHeadquartersSection.indirizzo = sede.indirizzo;
    domanda.relatedSubjects.AfcHeadquartersSection.tipologiaUsoLocali = sede.tipologiaUsoLocali;

    this.setState({ loading: true });
    SedeService.getAule(value, (res) => {

      domanda.relatedSubjects.AfcHeadquartersSection.classroomList = res.data.data;

      this.setState({
        dati: {
          domanda: domanda
        },
        loading: false,
      });
    }, () => this.setState({
      loading: false,
    }))
  }

  checkAula(checked, data) {
    let domanda = this.state.dati.domanda;
    domanda.relatedSubjects.AfcHeadquartersSection.classroomList.map(el => {
      if (el.id === data.id) el.checked = checked;
      return el
    })
    this.setState({
      dati: {
        domanda: domanda
      }
    })
  }

  checkPersonale(checked, data) {
    data.checked = checked;
    let domanda = this.state.dati.domanda;
    if (checked) {
      domanda.relatedSubjects.AfcProjectStaffSection.staffPersonalDataList.push(data);
    } else {
      domanda.relatedSubjects.AfcProjectStaffSection.staffPersonalDataList = domanda.relatedSubjects.AfcProjectStaffSection.staffPersonalDataList.filter(element => element.id !== data.id);
      let projectRepresentative = domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative;
      if (projectRepresentative && projectRepresentative !== null && projectRepresentative.id === data.id) {
        //resetto i campi con le info
        domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative = null;

        //resetto la select
        let d = {
          domanda: {
            relatedSubjects: {
              AfcProjectStaffSection: {
                projectRepresentative: {
                  id: null,
                  ore_previste: null,
                  principali_compiti: null
                }
              }
            }
          }
        };

        this.formRef.props.form.setFieldsValue(d);

      }

    }


    //calcolo il personale insegnante
    let count = 0;
    let staffList = domanda.relatedSubjects.AfcProjectStaffSection.staffPersonalDataList;
    if (staffList && staffList.length > 0) {
      staffList.forEach(s => {
        if (s.id_funzione && s.id_funzione === 6) count++;
        if ((!s.id_funzione || s.id_funzione === null) && s.funzione.toLowerCase() === 'docente') count++;
      })
    }

    domanda.relatedSubjects.AfcProjectStaffSection.numberOfTeachers = count;

    this.setState({
      dati: {
        domanda: domanda
      }
    })
  }


  setReferenteProgetto(value) {
    let referente = this.props.domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection.referenti?.find(function (element) {
      return element.id === value;
    });

    let domanda = this.state.dati.domanda;
    domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection.projectRepresentativePersonalData = {
      ...referente,
      contatti: referente.contatti.length > 0 ? referente.contatti[0] : null
    }
    this.setState({
      dati: {
        domanda: domanda
      }
    });
  }

  setReferenteProgettoInfo(value) {
    let referenteInfo = this.props.domanda.relatedSubjects.AfcProjectStaffSection?.staffPersonalDataList?.find(function (element) {
      return element.id === value;
    });

    let domanda = this.state.dati.domanda;
    domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative = {
      ...referenteInfo
    }
    this.setState({
      dati: {
        domanda: domanda
      }
    });
  }



  getComuni(value) {

    let domanda = this.state.dati.domanda;
    domanda.relatedSubjects.AfcHeadquartersSection.classroomList = [];

    let dati_form = this.formRef.props.form.getFieldsValue()
    dati_form.domanda.relatedSubjects.AfcHeadquartersSection.id = null;
    dati_form.domanda.relatedSubjects.AfcHeadquartersSection.comune = null;
    this.formRef.props.form.setFieldsValue({ domanda: dati_form.domanda });
    this.setState({
      loading: true,
      dati: {
        domanda: domanda
      }
    });

    SedeService.getComuniSediAccreditate(value, this.props.id_macrotipologia, (res) => {
      this.setState({
        comuni: res.data.data,
        loading: false,
      });
    }, () => this.setState({
      loading: false,
    }))
  }

  getSedi(value) {

    let domanda = this.state.dati.domanda;
    domanda.relatedSubjects.AfcHeadquartersSection.classroomList = [];

    let dati_form = this.formRef.props.form.getFieldsValue()
    dati_form.domanda.relatedSubjects.AfcHeadquartersSection.id = null;
    this.formRef.props.form.setFieldsValue({ domanda: dati_form.domanda });

    this.setState({
      loading: true,
      dati: {
        domanda: domanda,
      }
    });

    SedeService.getSediAccreditate(value, this.props.id_macrotipologia, (res) => {
      this.setState({
        sedi: res.data.data,
        loading: false,
      });
    }, () => this.setState({
      loading: false,
    }))
  }


  async submit(data) {

    let valid = await this.dati_capofila.formRef.props.form.validateFields();
    if (!valid) {
      window.scrollTo({ top: this.dati_capofila_container.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 50, behavior: 'smooth' });
      return;
    }

    // let domanda = this.state.dati.domanda;
    let domanda = this.props.domanda;
    domanda = {
      ...domanda,
      relatedSubjects: {
        ...domanda.relatedSubjects,
        ...data.domanda.relatedSubjects,
        AfcSingleEntityPartnerLeaderSection: {
          ...domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection,
          role: this.dati_capofila.formRef.props.form.getFieldsValue()['role'],
          experiences: this.dati_capofila.formRef.props.form.getFieldsValue()['experiences'],
          projectRepresentativePersonalData: {
            ...formatter.replaceFormDatesOut(['nato_il'], this.dati_capofila.dati_rappr_progetto.formRefDatiRappr.props.form.getFieldsValue(), 'YYYY-MM-DD'),

         //   ...this.dati_capofila.dati_rappr_progetto.formRefDatiRappr.props.form.getFieldsValue(),
            residenza: {
              ...this.dati_capofila.dati_indirizzo_referente.formRef.props.form.getFieldsValue(),
            },
            contatti: {
              ...this.dati_capofila.formRef.props.form.getFieldsValue()['projectRepresentativePersonalData']['contatti']
            },
            id: this.dati_capofila.formRef.props.form.getFieldsValue()['projectRepresentativePersonalData']['id']
          },
        },

        AfcHeadquartersSection: {
          ...domanda.relatedSubjects.AfcHeadquartersSection,
          ...data.domanda.relatedSubjects.AfcHeadquartersSection
        },
        AfcPartnerListSection: {
          partnerList: domanda.relatedSubjects.AfcPartnerListSection?.partnerList
        },
        AfcProjectStaffSection: {
          ...domanda.relatedSubjects.AfcProjectStaffSection,
          ...data.domanda.relatedSubjects.AfcProjectStaffSection,
          projectRepresentative: {
            ...domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative,
            ...data.domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative
          }
        },
      }
    }

    this.props.saveJson(domanda);

    /*ModelServices.save_json(domanda, this.props.match.params.id,
      res => {
        if (this.props.updateProgress) {
          this.props.updateProgress(res.data.data.progress);
          this.props.refresh(false);
          message.success('Dati salvati con successo');
        }
        this.setState({
          loading: false,
          tabLoaded: true,
          dati: {
            domanda: res.data.data.domanda_json
          }
        });
      },
      () => {
        this.setState({
          loading: false,
          tabLoaded: true,
        });
      }
    );*/
  }

  addPartner(data) {
    let dati = this.state.dati;
    if (!dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList) dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList = [];
    dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList.push(data);
    this.setState({ dati: dati, open_drawer: false },
      () => {
        let dati_form = this.formRef.props.form.getFieldsValue()
        this.submit(dati_form);
      })


  }

  deletePartner(p_iva) {
    let dati = this.state.dati;
    dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList = dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList.filter(value => { return value.entityPersonalData.partita_iva !== p_iva })
    this.setState({ dati: dati },
      () => {
        let dati_form = this.formRef.props.form.getFieldsValue()
        this.submit(dati_form);
      }
    )
  }

  updatePartner(data) {
    let dati = this.state.dati;
    dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList = dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList.filter(value => { return value.entityPersonalData.partita_iva !== data.entityPersonalData.partita_iva })
    dati.domanda.relatedSubjects.AfcPartnerListSection.partnerList.push(data);
    this.setState({ dati: dati, open_drawer: false },
      () => {
        let dati_form = this.formRef.props.form.getFieldsValue()
        this.submit(dati_form);
      })
  }

  render() {
    return <div style={{ opacity: !this.props.saved || this.state.loading ? 0.5 : 1 }}>
      {
        !this.props.saved || this.state.loading ? <Spin /> : null
      }
      {checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
        <ValidazioneScheda
          canGiveFeedback={this.props.canGiveSectionFeedback && this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
          loadingFeedback={this.props.loadingFeedback}
          saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'relatedSubjects')}
          feedback={this.props.section_feedback ? this.props.section_feedback['relatedSubjects'] : null}
        />
      }
      {this.state.tabLoaded &&
        <CommonForm
          loading={this.props.saving || this.state.loading || false}
          wrappedComponentRef={inst => (this.formRef = inst)}
          form_model={formLayout(this)}
          submit={(data) => this.submit(data)}
          values={this.state.dati}
          readOnly={this.props.readOnly}
          referenti_progetto={this.getReferentiProgetto()}
          referenti_progetto_info={this.getReferentiProgettoInfo()}
          setReferente={(value) => this.setReferenteProgetto(value)}
          setReferenteInfo={(value) => this.setReferenteProgettoInfo(value)}
          province={this.props.province?.map(e => {
            return {
              key: e.id,
              label: e.provincia,
              value: e.id,
            }
          })}
          getComuni={(data) => this.getComuni(data)}
          comuni={this.state.comuni.map(e => {
            return {
              key: e.id,
              label: e.comune,
              value: e.id,
            }
          })}
          getSedi={(data) => this.getSedi(data)}
          sedi={this.state.sedi.map(e => {
            return {
              key: e.id,
              label: e.indirizzo?.full_address,
              value: e.id,
            }
          })}
          setSede={(value) => this.setSede(value)}
        />
      }


      <Drawer
        title="Aggiungi partner"
        placement="right"
        closable={true}
        onClose={() => this.setState({ open_drawer: false })}
        visible={this.state.open_drawer}
        width={window.innerWidth - (window.innerWidth / 100 * 5)}
        destroyOnClose={true}
      >

        <CreatePartner
          {...this.props}
          addPartner={(data) => this.addPartner(data)}
        ></CreatePartner>

      </Drawer>
    </div>
  }
}
