import { Badge, Col, Icon, Layout, Popover, Row } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import LogoRegione from '../../assets/logo-regione.png';
import NotificheSmallList from '../../modules/notifiche/components/SmallList';
import NotificheServices from '../../modules/notifiche/services/Notification';
import '../less/Header.less';
import LoadingServicesUtils from '../utils/services/loading';

export default class Header extends React.Component {

  state = {
    request_in_progress: false,
    mounted: false
  }
  constructor(props) {
    super(props);

    this.notifiche_component = null
  }

  async handleRequestStatusChange(request_in_progress, component) {

    if (component.mounted) await this.setState({ request_in_progress });

  }

  interval = false

  async componentDidMount() {
    this.mounted = true
    LoadingServicesUtils((r) => this.handleRequestStatusChange(r, this));

    if(window.interval_notifications) {
      clearInterval(window.interval_notifications);
    }
    window.interval_notifications = setInterval(() => this.loadNotifications(), this.props.GlobalStore.PENDING_SET_TIMEOUT);

  }

  loadNotifications() {

    if(localStorage.getItem('token')) {
      let obj = (this.props.UserStore.user.ente) ? {id_ente: this.props.UserStore.user.ente.id} : {};
      NotificheServices.pending(obj, (res) =>{
        this
          .props
          .dispatch({
            type: 'SET_USER_NOTIFICATIONS',
            notifications: res.data.data
          });
          //setTimeout(()=>this.loadNotifications(), 15000);
      }, () => null/*setTimeout(()=>this.loadNotifications(), 15000)*/ )
    } /*else {
      setTimeout(()=>this.loadNotifications(), 15000);
    }*/

  }

  async componentWillUnmount() {
    this.mounted = false
  }

  logout() {
    localStorage.removeItem('token');
    this
      .props
      .dispatch({ type: 'LOGOUT' });

    this.props.history.push('/login');
  }

  /**
   * Al click su un elemento del menu principale effettua il trigger sulla proprietà clicked dello stesso
   * @param  {object} info Elemento Menu
   * @return {void}
   *
   * @public
   */
  clickedMainMenu(info) {
    //console.log(info)
    switch (info.key) {
      case 'setting:account':
        this.props.history.push('/account')
        break;
      case 'setting:logout':
        this.logout()
        break;
      default: break;
    }

  }

  render() {
    return <Layout.Header className="header" aria-label="header sito Silf">

      {/* <Icon
        className="trigger"
        type={!this.props.GlobalStore.open_menu
          ? 'menu-unfold'
          : 'menu-fold'}
        onClick={() => {
          if (!this.props.GlobalStore.open_menu) {
            this.props
              .dispatch({ type: 'OPEN_MENU' })
          } else {
            this.props
              .dispatch({ type: 'CLOSE_MENU' })
          }
        }} />  */}
      {/* {this.state.request_in_progress && (<Spin style={{ marginLeft: '20px' }} />)} */}

      <Row className="headerTop">
        <Col span={18}>
          <a href="http://www.regione.campania.it/" aria-label='Link al sito della regione Campania' title="Silf - Regione Campania">Regione Campania</a>
        </Col>
        <Col span={6} style={{textAlign: 'right', paddingRight: 40}}>
          {
            this.props.UserStore.loggedIn ?
            <Badge count={this.props.UserStore.notifications.count}>
                {/*<Link to="/notifiche">*/}
                <Popover
                overlayClassName="notifichePop"
                placement="rightBottom" title={"Notifiche"}
                onVisibleChange={(visible) => {
                  //if(visible && this.notifiche_component) this.notifiche_component.updateData()
                }}
                content={
                  <NotificheSmallList
                    {...this.props}
                    ref={ (ref) => this.notifiche_component=ref } />
                } trigger="click">
                  <Icon type="bell" style={{fontSize: 21, color: '#fff'}}  />
                </Popover>
                {/*</Link>*/}
             </Badge>
             : null
          }
        </Col>
      </Row>
      <Row className="headerBottom">
        <Col span={2} className="headerLogo" aria-label='Logo della regione Campania' >
          {this.props.UserStore.loggedIn ?
            <Link to="/dashboard"><img src={LogoRegione} width={60} className="m10h" alt="Silf - Regione Campania" /></Link> :
            <Link to="/login"><img src={LogoRegione} width={60} className="m10h" alt="Silf - Regione Campania" /></Link>
          }
        </Col>
        <Col span={22} style={{ paddingLeft: '30px' }}>
          <h1>SILF</h1>
          <h2>Sistema Informativo Istruzione Lavoro e Formazione</h2>
        </Col>
      </Row>


      {/* <Menu
        onClick={(info) => this.clickedMainMenu(info)}
        mode="horizontal"
        className="right-menu main-menu-user">
        <SubMenu title={<Avatar style={{ verticalAlign: 'middle' }} icon="user" size="large">

        </Avatar>}>
          <Menu.Item disabled={true} className="menu-main">{this.props.UserStore.user.username}</Menu.Item>
          <Menu.Item key="setting:account">
            <span className="nav-text">Account</span>
          </Menu.Item>
          <Menu.Item key="setting:logout">
            <span className="nav-text">Esci</span>
          </Menu.Item>
        </SubMenu>
      </Menu> */}

    </Layout.Header>

  }

}
