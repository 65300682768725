import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CommonForm from '@common/components/form';
import { Button, Modal, message, Popconfirm } from 'antd';
import auditStatic from '@common/utils/static/audit';
import moment from 'moment';
import IntegrazionedocumentaleForm from '@modules/repertorio/components/audit/IntegrazioneDocumentaleForm';

const formNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};


class AuditDocumentale extends Component {

  state = {
    loading: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.load(this.props);
    }
  }

  componentDidMount() {
    if (this.props.audit) this.load(this.props);
  }

  load(props) {
    this.setState({ audit: props.audit, loading: false });
  }

  renderStart() {
    return <div>
      <p>Inizia Istruttoria Amministrativa per questa pratica</p>
      <Button
        disabled={this.props.loading}
        loading={this.props.loading}
        className="m5h" type="primary" icon="check" onClick={() => { this.props.startAudit() }}>Comincia audit</Button>
    </div>
  }


  renderIntegrationActions(id_audit) {
    return <div>
      <Popconfirm placement="top" title="Vuoi apportare le integrazioni richieste?"
        onConfirm={() => { this.props.integration(id_audit) }}
        okText="Si" cancelText="No">
        <Button
          loading={this.props.integrating}
          disabled={this.props.integrating}
          className="m5h" type="primary" icon="check">Procedi con le integrazioni</Button>
      </Popconfirm>
    </div>

  }

  renderRequireIntegrationActions(id) {
    return <div>
      <Popconfirm placement="top" title="Vuoi richiedere aggiornamento/integrazioni?"
        onConfirm={() => { this.props.requireIntegration(id) }}
        okText="Si" cancelText="No">
        <Button
          loading={this.props.requiring}
          disabled={this.props.requiring}
          className="m5h" type="primary" icon="check">Richiedi integrazioni</Button>
      </Popconfirm>
    </div>

  }

  renderActions(id_audit) {
    return <div>
      <p>{this.props.description ? this.props.description : 'Verifica il progetto formativo, valida le sezioni della scheda progetto, approva, rifiuta o richiedi una integrazione'}</p>

      <Popconfirm placement="top" title="Confermi l'approvazione?"
        onConfirm={() => {
          this.props.approve(id_audit)
        }}
        okText="Si" cancelText="No">
        <Button
          loading={this.props.approving}
          disabled={this.props.approving || this.props.suspending || this.props.refusing || this.props.disabledApprove}
          className="m5h" type="primary" icon="check"
          style={{ marginRight: 8 }}>Approva</Button>
      </Popconfirm>

      {
        // !this.props.audit.integrazione || this.props.audit.integrazione.length < 1/*2 - palesemente una change request */?
        <Button loading={this.props.suspending}
          disabled={this.props.approving || this.props.suspending || this.props.refusing}
          className="m5h" type="primary" icon="file" style={{ marginRight: 8 }} ghost
          onClick={() => { !this.props.media ? this.setState({ open_modal_audit_suspend: true }) : this.setState({ open_modal_audit_integration: true }) }}>Richiedi integrazione</Button>
        // : null
      }
      {this.props.canViewRefuse ?
        <Button
          loading={this.props.refusing}
          disabled={this.props.approving || this.props.suspending || this.props.refusing}
          style={{ marginRight: 8 }}
          className="m5h" type="danger"
          icon="close" onClick={() => this.setState({ open_modal_audit_refuse: true })}>
          Rifiuta</Button> : null
      }
      <Modal
        title={this.props.descriptionRefuse ? this.props.descriptionRefuse : "Predisponi comunicazione ostativa"}
        visible={this.state.open_modal_audit_refuse}
        onOk={async () => {

          if (!this.formNoteRef.props.form.getFieldsValue()['note'] || this.formNoteRef.props.form.getFieldsValue()['note'] === '') {
            message.error('Inserisci le note');
            return;
          }
          this.props.refuse(id_audit, this.formNoteRef.props.form.getFieldsValue()['note']);
          this.setState({ open_modal_audit_refuse: false })
        }}
        onCancel={() => this.setState({ open_modal_audit_refuse: false })}
      >
        <CommonForm
          wrappedComponentRef={inst => (this.formNoteRef = inst)}
          loading={this.state.loading || false}
          form_model={formNoteLayout}
          values={{

          }}
        />
      </Modal>

      <Modal
        title="Descrivi le incongruenze del progetto"
        visible={this.state.open_modal_audit_suspend}
        onOk={async () => {

          if (!this.formNoteRef.props.form.getFieldsValue()['note'] || this.formNoteRef.props.form.getFieldsValue()['note'] === '') {
            message.error('Inserisci le note');
            return;
          }

          this.props.suspend(id_audit, this.formNoteRef.props.form.getFieldsValue()['note']);
          this.setState({ open_modal_audit_suspend: false })
        }}
        onCancel={() => this.setState({ open_modal_audit_suspend: false })}
      >
        <CommonForm
          wrappedComponentRef={inst => (this.formNoteRef = inst)}
          loading={this.state.loading || false}
          form_model={formNoteLayout}
          values={{

          }}
        />
      </Modal>

      <Modal
        title="Richiedi integrazione documentale"
        visible={this.state.open_modal_audit_integration}
        destroyOnClose={true}
        confirmLoading={this.state.suspending}
        width="90%"
        onOk={async () => {

          let values = this.refs.integration_ref.getValues()

          this.props.integration(id_audit, values);
          this.setState({ open_modal_audit_integration: false })
        }
        }
        onCancel={() => this.setState({ open_modal_audit_integration: false })}
      >
        <IntegrazionedocumentaleForm
          ref="integration_ref"
          getMedia={() => { this.props.getMedia() }}
          documents={this.props.documents || []}
          loading={this.props.loading_media}
        />
      </Modal>



    </div>
  }


  render() {
    return this.props.canView ?
      <div>
        {this.props.canStartAudit && this.renderStart()}
        {this.state.audit &&
          <div>
            {
              this.state.audit.map(a => {

                return <div key={"audit_" + a.id} style={{ marginBottom: 8 }}>
                  <div>
                    <p style={{ marginBottom: 0 }}>Utente: <strong>{a.user.username} ({a.user.anagrafica.nome + " " + a.user.anagrafica.cognome})</strong><br />
                  Stato: <strong className="color-green">{auditStatic.getStatoLabel(a)}</strong><br />
                  Data: <strong>{moment.unix(a.created_at).format('DD/MM/YYYY')}</strong><br />
                      {a.note && a.note.map(n => {
                        return <div key={"nota_" + n.id}>Note:<strong> {n.note} </strong></div>
                      })}

                    </p>
                  </div>
                  {a.integrazione && a.integrazione.map(i => {
                    return <div key={"nota_integrazione" + i.id}>
                      {i.note &&
                        <div>Integrazioni richieste: <strong>{i.note}</strong>
                        </div>}
                      <div>Data richiesta integrazione: <strong>{moment.unix(i.created_at).format('DD/MM/YYYY')} </strong></div>

                      {this.props.canConfirmIntegration && i.stato === auditStatic.stato_integrazione.in_corso && this.renderRequireIntegrationActions(i.id)}
                    </div>
                  })}
                  {this.props.startedAudit && !this.props.isValidated && a.stato === auditStatic.stato.in_corso ? 'Conferma le schede' : ''}
                  {this.props.canGiveFeedback && a.stato === auditStatic.stato.in_corso && this.props.UserStore.user.id === a.id_user ? this.renderActions(a.id) : null}
                  {this.props.canSendIntegration && a.stato === auditStatic.stato.integration ? this.renderIntegrationActions(a.id) : null}
                  {this.props.canRequireIntegration && a.stato === auditStatic.stato.integration ? this.renderRequireIntegrationActions(a.id) : null}
                  {
                    /*
                    (a.integrazione && a.integrazione.length > 0) &&
                      a.integrazione.map(i => {
                        return <SingleIntegrazione
                          key={'integrazione_' + i.id}
                          refresh={() => this.props.refresh()}
                          refreshInline={(data) => this.props.refreshInline(data)}
                          audit={a}
                          integrazione={i}
                          accreditamento={this.props.accreditamento}
                        />
                      })
                    */
                  }

                </div>

              })
            }
          </div>
        }
      </div>
      : null
  }
}

const AuditDocumentaleExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(AuditDocumentale);

export default withRouter(AuditDocumentaleExport);
