import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import SpinnerLoading from '@common/components/SpinnerLoading';
import eventBroker from '@common/utils/services/EventBroker';
import format from '@common/utils/formatters/_';
import { Drawer, message } from 'antd';
import checkPermission from '@common/utils/check/permissions';
import ModelServices from '../../services/RichiesteQualifiche';
import repertorioStatic from '@common/utils/static/repertorio';
import TabellaQualificheProfessionali from '../../components/TabellaQualificheProfessionali'
import { getForm as qualificaProfessionaleForm } from '../QualificaProfessionaleForm';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';
import DettaglioAreaAttivita from '@modules/repertorio/components/DettaglioAreaAttivita';
import TabellaAreaAttivita from '@modules/repertorio/components/TabellaAreaAttivita';
import SettoreServices from '@modules/tabelle/services/Settore';
import AmbitoServices from '@modules/tabelle/services/Ambiti';
import LivelloComplessitaServices from '@modules/tabelle/services/LivelliComplessita';
import Ateco2007Services from '@modules/tabelle/services/Ateco2007';
import Isco08Services from '@modules/tabelle/services/Isco08';
import IstatProfessioniServices from '@modules/tabelle/services/IstatProfessioni';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'dati_richiesta',
                type: 'title',
                element: 'h4',
                text: 'Dati identificativi',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Denominazione soggetto proponente',
                type_string: 'text',
                name: 'denominazione',
                props: {
                  disabled: true,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Categoria di appartenenza',
                name: 'categoria',
                props: {
                  disabled: true,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options: [
                  {
                    value: 1,
                    label: 'Impresa'
                  },
                  {
                    value: 2,
                    label: 'Ente di formazione professionale accreditato'
                  },
                  {
                    value: 3,
                    label: 'Parte sociale o datoriale'
                  },
                  {
                    value: 4,
                    label: 'Associazione'
                  },
                  {
                    value: 5,
                    label: 'Organizzazione del terzo settore e della società civile'
                  },
                  {
                    value: 6,
                    label: 'Amministrazione pubblica e istituzione'
                  },
                  {
                    value: 7,
                    label: 'Dipendente regionale appartenente a Dipartimenti / Settori dell’Amministrazione regionale'
                  },
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione richiesta',
                name: 'descrizione_richiesta',
                props: {
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
        ],
      },

      (component.state.data.tipo_richiesta === repertorioStatic.tipo_richiesta.modifica || component.state.data.tipo_richiesta === repertorioStatic.tipo_richiesta.integrazione) &&
      {
        cols: [
          {
            items: [
              {
                name: 'figura_professionale',
                type: 'title',
                element: 'h4',
                text: 'Figura professionale da modificare/integrare',
                props: {
                  disabled: component.props.readOnly,
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },
      (component.state.tipo_richiesta === repertorioStatic.tipo_richiesta.modifica || component.state.tipo_richiesta === repertorioStatic.tipo_richiesta.integrazione) &&
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Figura professionale',
                type_string: 'text',
                name: 'figura_professionale',
                props: {
                  disabled: true,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
              {
                name: 'seleziona_figura_professionale',
                type: 'button',
                text: 'Seleziona figura professionale',
                props: {
                  loading: false,
                  readOnly: false,
                  className: 'm5h',
                  icon: 'search',
                },
                onClick: 'selezionaFiguraProfessionale'
              }
            ]
          },
        ]
      },

      ...qualificaProfessionaleForm(component),

      {
        cols: [
          {
            offset: 22,
            span: 2,
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
};

export default class DatiIdentificativiForm extends Component {

  constructor(props) {
    super(props);
    this.getBackDatiIdentificativi = this.getBackDatiIdentificativi.bind(this);
    eventBroker.on('getBackDatiIdentificativi', this.getBackDatiIdentificativi);
  }

  state = {
    data: null,
    loading: false,
    open_drawer: false,
  };

  componentDidMount() {
    if(this.props.repertorio) {
      this.initComponent();
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.repertorio !== prevProps.repertorio) {
      this.initComponent();
    }
  }

  initComponent() {
    this.setState({loading:true});
    SettoreServices.list({sort:'nome_settore',pageSize: 0}, res => {
      let settori = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'settori_' + el.id,
            label: el.nome_settore,
            value: el.id
          }
        })
        : [];
    if (this.props.repertorio) {
      let data = format.replaceFormDatesIn([
        'valido_dal',
        'valido_al'
      ], this.props.repertorio.json, 'YYYY-MM-DD');

      if (this.props.repertorio.json.id_settore && !settori.find(el => el.value === this.props.repertorio.json.id_settore)) {
        SettoreServices.view({}, this.props.repertorio.json.id_settore, res => {
          settori.push({
            key: 'settori_' + res.data.data.id,
            label: res.data.data.nome_settore,
            value: res.data.data.id
          });
          this.setState({ settori: settori })
        })

      } else {
        this.setState({ settori: settori })
      }

      this.setState({
        data: data,
        tipo_richiesta: this.props.repertorio.tipo_richiesta,
        id_richiesta: this.props.repertorio.id,
        id_qualifica: this.props.repertorio.id_qualifica,
      },
        () => {
          let periodo_validita = [this.state.data?.valido_dal, this.state.data?.valido_al];
          this.formSelRef.props.form.setFieldsValue({ periodo_validita: periodo_validita });
        }
      );
    }
  })

  AmbitoServices.list({sort:'descrizione', pageSize: 0}, res => {
    let rows = res.data.data ?
      res.data.data.list.map(el => {
        return {
          key: 'ambito_' + el.id,
          label: el.descrizione,
          value: el.id
        }
      })
      : [];

      this.setState({ ambiti: rows })
  })

  LivelloComplessitaServices.list({sort:'descrizione', pageSize: 0}, res => {
    let rows = res.data.data ?
      res.data.data.list.map(el => {
        return {
          key: 'livelloComplessita_' + el.id,
          label: el.gruppo + ' - ' + el.descrizione,
          value: el.id
        }
      })
      : [];

      this.setState({ livelliComplessita: rows })
  })

  Ateco2007Services.list({sort:'descrizione', pageSize: 0}, res => {
    let rows = res.data.data ?
      res.data.data.list.map(el => {
        return {
          key: 'ateco2007_' + el.id,
          label: el.codice + ' - ' + el.descrizione,
          value: el.id
        }
      })
      : [];

      this.setState({ ateco2007: rows })
  })

  Isco08Services.list({sort:'descrizione', pageSize: 0}, res => {
    let rows = res.data.data ?
      res.data.data.list.map(el => {
        return {
          key: 'isco08_' + el.id,
          label: el.descrizione,
          value: el.id
        }
      })
      : [];

      this.setState({ isco08: rows })
  })

  IstatProfessioniServices.list({sort:'descrizione', pageSize: 0}, res => {
    let rows = res.data.data ?
      res.data.data.list.map(el => {
        return {
          key: 'professione_' + el.id,
          label: el.descrizione,
          value: el.id
        }
      })
      : [];

      this.setState({ professioni: rows })
  })

  this.setState({loading:false});

  }

  getBackDatiIdentificativi() {
    return this.prepareData(this.formSelRef?.props.form.getFieldsValue());
  }

  prepareData(data) {

    if (data && data.periodo_validita) {
      data.valido_dal = data.periodo_validita[0];
      data.valido_al = data.periodo_validita[1];

      data = format.replaceFormDatesOut([
        'valido_dal',
        'valido_al'
      ], data, 'YYYY-MM-DD');
    }


    data = format.revertBoolean([
      'laboratorio'
    ], data);

    return data;
  }

  open(element) {
    this.setState({
      element_opened: element
    }, () => this.setState({ open_drawer_dettaglio_area_attivita: true }))
  }

  deleteAttivita(element) {
   let listaAttivita = this.state.data.areeAttivita;
   listaAttivita = listaAttivita.filter(el => el.id !== element.id);
   this.setState({data: {...this.state.data, areeAttivita : listaAttivita}})
  }

  onSubmit(data) {

    data = this.prepareData(data)

    data= {...data, areeAttivita: this.state.data.areeAttivita}

    if (!this.state.loading) {
      this.setState({ loading: true }, () => {

        //let save = this.state.data.id ? ModelServices.update(data, this.state.data.id) : ModelServices.create(data);
        let save = ModelServices.saveJSON(data, this.props.repertorio.id, this.state.id_qualifica);
        save.then(res => {
          message.success("Richiesta salvata correttamente");
          this.setState({ /*data: res.data.data.json,*/ loading: false },
            () => { this.props.refreshInline(res.data.data); });

          //eventBroker.trigger('loadProcedura');
        }).catch(() => this.setState({ loading: false }));

      });
    }
  }

  getValues(data) {
    return format.convertBoolean([
      'laboratorio'
    ], data);
  }

  addAda(element) {

    let listaAttivita = this.state.data.areeAttivita;
    if(!listaAttivita) listaAttivita = [];
    listaAttivita.push(element);
    this.setState({
      open_drawer_add_attivita: false, 
      data: {...this.state.data, areeAttivita : listaAttivita}})
   }

  setJson(data) {
    let element = format.replaceFormDatesIn([
      'valido_dal',
      'valido_al'
    ], data, 'YYYY-MM-DD');

    element = this.getValues(element);

    let periodo_validita = [element.valido_dal, element.valido_al];

    let datiForm = { ...this.formSelRef.props.form.getFieldsValue(), 
      ...element, 
      figura_professionale: element.denominazione_figura, 
      periodo_validita: periodo_validita,
    }

    this.setState({
      open_drawer: false,
      id_qualifica: element.id,
      data: { ...datiForm }
    }, () => {
      this.formSelRef.props.form.setFieldsValue(datiForm);
    })

  }

  render() {
    return (!this.state.data ? <SpinnerLoading message='Caricamento dati...' /> :
      <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>

        {checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
          <ValidazioneScheda
            canGiveFeedback={checkPermission.hasPermission(this.props.UserStore.user, ['addAudit']) && this.props.repertorio.stato_flusso === repertorioStatic.stato_flusso.investigation}
            loadingFeedback={this.props.loadingFeedback}
            saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'generalData')}
            feedback={this.props.section_feedback ? this.props.section_feedback['generalData'] : null}
          />
        }

        <CommonForm
          loading={this.state.loading || false}
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          form_model={formLayout(this)}
          values={this.getValues(this.state.data)}
          readOnly={this.props.readOnly}
          onSubmit={this.onSubmit.bind(this)}
          selezionaFiguraProfessionale={data => this.setState({ open_drawer: true })}
          settori={this.state.settori}
                    ambiti={this.state.ambiti}
          livelliComplessita={this.state.livelliComplessita}
          ateco2007={this.state.ateco2007}
          isco08={this.state.isco08}
          professioni={this.state.professioni}
        />
        <Drawer
          title="Cerca una figura professionale"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false })}
          visible={this.state.open_drawer}
          width={window.innerWidth - (window.innerWidth / 100 * 5)}
          destroyOnClose={true}
        >
          <div>
            <TabellaQualificheProfessionali
              {...this.props}
              base_url={"/repertorio/richieste/" + this.state.id_richiesta}
              nuova_richiesta={true}
              selezionaFigura={(element) => { this.setJson(element) }}
            >
            </TabellaQualificheProfessionali>
          </div>
        </Drawer>

        <Drawer
          title="AREA DI ATTIVITÀ"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_dettaglio_area_attivita: false, element_opened: null })}
          visible={this.state.open_drawer_dettaglio_area_attivita}
          width={window.innerWidth - (window.innerWidth / 100 * 5)}
          destroyOnClose={true}
        >
          <div>
            <DettaglioAreaAttivita
              values={this.state.element_opened}
              subform={true}
            />
          </div>
        </Drawer>

        <Drawer
          title="AGGIUNGI AREA DI ATTIVITÀ"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_add_attivita: false })}
          visible={this.state.open_drawer_add_attivita}
          width={window.innerWidth - (window.innerWidth / 100 * 5)}
          destroyOnClose={true}
        >
          <TabellaAreaAttivita 
          {...this.props}
          addAda={(data) => this.addAda(data)}
          base_url={"/repertorio/richieste/" + this.state.id_richiesta}
          list={this.state.data.areeAttivita || []}
          />
        </Drawer>
      </div>
    )
  }
}
