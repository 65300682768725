import React, { Component } from 'react';

import { Button, Drawer, Table, Tooltip, Popconfirm } from 'antd';
import EditAziendeStage from './EditAziendeStage';



const columns = (component) => {
    let ret = [
        {
            title: 'Denominazione azienda',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (<div>{record.name}</div>)
        },
        {
            title: 'Rappresentante legale',
            dataIndex: 'legalRepresentative',
            key: 'legalRepresentative',
            render: (text, record) => (<div>{record.legalRepresentative}</div>)
        },
        {
            title: 'Indirizzo',
            dataIndex: 'address',
            key: 'address',
            render: (text, record) => (<div>{record.address}</div>)
        },
        {
            title: 'Oggetto sociale',
            dataIndex: 'corporatePurpose',
            key: 'corporatePurpose',
            render: (text, record) => (<div>{record.corporatePurpose}</div>)
        },
        {
            title: 'N° allievi',
            dataIndex: 'studentNumber',
            key: 'studentNumber',
            render: (text, record) => (<div>{record.studentNumber}</div>)
        },
        {
            title: 'Ore totali di stage',
            dataIndex: 'totalStageHours',
            key: 'totalStageHours',
            render: (text, record) => (<div>{record.totalStageHours}</div>)
        },
    ];

    if (!component.props.hiddenActions) {
        ret.push(
            {
                title: 'Azioni',
                dataIndex: 'azione',
                key: 'azione',
                render: (text, record) => (
                    <div>
                        {!component.props.loading_data ?
                            <React.Fragment>
                                <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                                    <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record, record.id, 'edit_azienda')} />
                                </Tooltip>
                                <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questa azienda?"
                                    onConfirm={() => component.deleteAzienda(record.id)} okText="Si" cancelText="No">
                                    <Button disabled={component.props.readOnly} className="m2w" size="small" shape="circle" icon="delete" />
                                </Popconfirm>
                            </React.Fragment>
                            : null}
                    </div>
                ),
            }
        )
    }
    return ret;
}

class AziendeStage extends Component {

    state = {
        open_drawer_edit_azienda: false,
        create_azienda: false,
        element_opened: {},
        aziendeList : []
    };



    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
      this.setState({
          loading: false,
          aziendeList: props.aziendeList
      });
    }


    deleteAzienda(id){
        this.setState({
            aziendeList: this.state.aziendeList.filter(value => {return value.id !== id})
        },()=>{
            this.props.saveAziendeList(this.state.aziendeList)
        });
      }

    async update(data) {
        let list = this.state.aziendeList;
        var index = this.state.aziendeList.map(el => el.id).indexOf(this.state.id_element_opened);
        data.id = this.state.id_element_opened
        list[index] = data
        this.setState(
            {   open_drawer_edit_azienda: false,
                aziendeList: list,
                element_opened : {},
                id_element_opened : null
            }, () => {this.props.saveAziendeList(this.state.aziendeList)}
        );
    }

    async create(data) {

       let id = 0;
       if(this.state.aziendeList.length > 0) {
        id = Math.max.apply(Math, this.state.aziendeList.map(function(o) { return o.id; })) + 1
       }
        let list = this.state.aziendeList;
        data.id = id
        list.push(data);
        this.setState(
            {
                open_drawer_edit_azienda: false,
                aziendeList : list,
                element_opened : {},
                id_element_opened : null
            }, () => {
                this.props.saveAziendeList(this.state.aziendeList);
            });
    }

    open(element, id, action) {
        this.setState({
            create_azienda: false,
            element_opened: element,
            id_element_opened: id,
        }, () => this.setState({ ['open_drawer_' + action]: true }))
    }

    render() {
        return (
            <div>
                {!this.props.readOnly && <Button disabled={this.props.readOnly} type="primary" className="m5h" icon="plus" onClick={() => this.setState({ open_drawer_edit_azienda: true, create_azienda: true })}>Aggiungi</Button>}
                <Table
                    rowKey={(record) => record.id}
                    loading={this.props.loading}
                    columns={columns(this)}
                    dataSource={this.state.aziendeList}
                />
                <Drawer
                    title="AZIENDA"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ open_drawer_edit_azienda: false, element_opened: null })}
                    visible={this.state.open_drawer_edit_azienda}
                    width={window.innerWidth / 3}
                    destroyOnClose={true}
                >
                    <EditAziendeStage
                        {...this.props}
                        close={() => this.setState({ open_drawer_edit_azienda: false })}
                        aziendaStage={this.state.element_opened}
                        idAziendaStage={this.state.id_element_opened}
                        create={this.state.create_azienda}
                        onUpdate={(data) => {
                            this.update(data);
                        }}
                        onCreate={(data) => this.create(data)}
                    />
                </Drawer>
            </div>
        );
    }
}
export default AziendeStage;