import React, { Component } from 'react';

import { Button, Drawer, Table, Tooltip, Popconfirm,
Modal, List } from 'antd';

import JoinLongArray from '@common/components/JoinLongArray';

import format from '@common/utils/formatters/_';
import accreditamentoStatic from '@common/utils/static/accreditamento';
import eventBroker from '@common/utils/services/EventBroker';

import Create from '@accreditamento/forms/completeRequest/infrastruttura/sede/Create';

import InfrastrutturaServices from '@accreditamento/services/Infrastruttura';

import RequisitiAule from './Requisiti';
import Rintracciabilita from './Rintracciabilita';
import Caratteristiche from './Caratteristiche';

import Aule from './Aule';
import Attrezzature from './Attrezzature';
import Audiovisivi from './Audiovisivi';



const columns = (component) => {

  let mancaPrevenzione = false;
  if(component.props.sedi && component.props.sedi[0] && component.props.sedi[0].requisitiAule != null){
    if(component.props.sedi[0].numero_totale_allievi >= 100 && component.props.sedi[0].requisitiAule.certificato_prevenzione_incendi === false) {
      mancaPrevenzione = true
    }
  }
  let attrezzatureObbligatorie = false;
  if(component.props.sedi && component.props.sedi[0] && component.props.sedi[0].aule != null) {
    component.props.sedi[0].aule.map(aula => {
      if(aula.id_tab_tipo_aula == 3 || aula.id_tab_tipo_aula == 4) {
        attrezzatureObbligatorie = true;
      }
    });
  }
  /*
  let certificatoAttrezzatureRispettato = true;
  if(component.props.sedi && component.props.sedi[0]) {
    if(attrezzatureObbligatorie == true && !component.props.sedi[0].certified_attrezzature) {
      certificatoAttrezzatureRispettato = false;
    }else if(attrezzatureObbligatorie == false && component.props.sedi[0].certified_attrezzature) {
      certificatoAttrezzatureRispettato = true;
    }
  }
  */

  return [
      {
        title: 'Indirizzo',
        dataIndex: 'indirizzo',
        key: 'indirizzo',
        render: (text, record) => (<div style={{width: 150}}>{
          format.getIndirizzo( record.enteSede.indirizzo, component.props.GlobalStore)
        }</div>)
      },
      {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (

          <div style={{width: 200}}>
            {!component.props.loading_data ?
            <React.Fragment>
              <Tooltip placement="top" title="Caratteristiche">
                <Button type={/*.tipoAccreditamento.length > 0 && */!(component.props.accreditamento.progress && component.props.accreditamento.progress.errors.caratteristiche && component.props.accreditamento.progress.errors.caratteristiche.find(el => el === record.id)) ? 'default' : 'danger'} 
                className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record.id, record, 'caratteristiche')} />
              </Tooltip>
              <Tooltip placement="top" title="Composizione struttura sede">
                <Button type={record.aule.length > 0 && !(component.props.accreditamento.progress && component.props.accreditamento.progress.errors.composizione && component.props.accreditamento.progress.errors.composizione.find(el => el === record.id)) ? 'default' : 'danger'}
                className="m2w" size="small" shape="circle" icon="bars" onClick={() => component.open(record.id, record, 'aule')} />
              </Tooltip>
              { record.aule[0] ?
              <React.Fragment>
                { component.props.accreditamento.N_anpal == null ? <React.Fragment>
                { //record.aule[0].id_tab_tipo_aula == 8 ?
                record.aule.find(m => m.id_tab_tipo_aula == 8) ?
              <Tooltip placement="top" title="Attrezzature">
                <Button type={ /*certificatoAttrezzatureRispettato &&*/ !(component.props.accreditamento.progress && component.props.accreditamento.progress.errors.sede_attrezzature && component.props.accreditamento.progress.errors.sede_attrezzature.find(el => el === record.id)) ? 'default' : 'danger'} 
                className="m2w" size="small" shape="circle" icon="setting" onClick={() => component.open(record.id, record, 'attrezzature')} />
              </Tooltip> 
              : null} </React.Fragment> : null}
              </React.Fragment>
              : null}
              {/* <Tooltip placement="top" title="Audiovisivi">
              </Tooltip>: null }</React.Fragment> : null
              }
              <Tooltip placement="top" title="Audiovisivi">
                <Button type={record.certified_audiovisivi ? 'default' : 'danger'} className="m2w" size="small" shape="circle" icon="video-camera" onClick={() => component.open(record.id, record, 'audiovisivi')} />
              </Tooltip>*/}
              {/* requisiti + documenti in 2 colonne */}

              {/* https://cb.schema31.it/cb/issue/417022
              <Tooltip placement="top" title="Rispetto della normativa sui luoghi di lavoro">
                <Button type={!mancaPrevenzione && record.requisitiAule && record.requisitiAule.documento_sicurezza == "1" && !(component.props.accreditamento.progress && component.props.accreditamento.progress.errors.requisiti && component.props.accreditamento.progress.errors.requisiti.find(el => el === record.id)) ? 'default' : 'danger'} 
                className="m2w" size="small" shape="circle" icon="copy" onClick={() => component.open(record.id, record, 'requisiti')} />
              </Tooltip>
              */}

              <Tooltip placement="top" title="Requisiti tecnici dell'immobile sede di accreditamento">
                <Button type={!mancaPrevenzione && record.requisitiAule && record.requisitiAule.nulla_osta_tecnico_sanitario == "1" && !(component.props.accreditamento.progress && component.props.accreditamento.progress.errors.requisiti_aule && component.props.accreditamento.progress.errors.requisiti_aule.find(el => el === record.id)) ? 'default' : 'danger'} 
                className="m2w" size="small" shape="circle" icon="home" onClick={() => component.open(record.id, record, 'requisiti_sede')} />
              </Tooltip>              
              <Tooltip placement="top" title="Rintracciabilità e visibilità dei locali">
                <Button type={record.rintracciabilita ? 'default' : 'danger'} className="m2w" size="small" shape="circle" icon="solution" onClick={() => component.open(record.id, record, 'rintracciabilita')} />
              </Tooltip>

              <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questa sede?"
              onConfirm={() => component.delete(record.enteSede.id)} okText="Si" cancelText="No">
                <Button disabled={component.props.UserStore.user.role[0].name == "Ente" ? (component.props.readOnly /* INIB CANCELLAZIONE || component.props.accreditamento.stato != 1*/) : true} loading={component.state.deleting.indexOf(record.enteSede.id) !== -1} className="m2w" size="small" shape="circle" icon="delete" />
              </Popconfirm>
            </React.Fragment>
            : null}
          </div>
        ),
      }
    ];
  }

class Sedi extends Component {

  state = {
    loading: false,
    open_drawer: false,
    updating_model: {},
    deleting: [],
    addingsede_id: null,
    addingsede: false,
    id_sede_opened: null, // sede aperta in modifica
    open_drawer_requisiti: false,
    id_sede_accreditamento_opened: null,
    sede_opened: null,
    sede_acc_pregresso: null,
    readOnly: false,
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    //newProps.sedi.forEach(()=>{
      //console.log('sede', sede, sede.requisitiAule ? true : false, sede.rintracciabilita ? true : false )
    //})
  }

  async delete(id) {
    if(!this.state.deleting.find(a => a === id)) {
      this.setState({deleting: [...this.state.deleting, id]});
      InfrastrutturaServices.delete( this.props.accreditamento.id, id, () => {
        this.props.onUpdate();
        eventBroker.trigger('updateDecretoPregressoInfrastruttura');
        this.setState({deleting: this.state.deleting.filter(el => el !== id)})
      }, () => this.setState({deleting: this.state.deleting.filter(el => el !== id) }))
    }
  }

  refresh() {
    return this.props.refresh();
  }

  open(id_sede, element, action) {

    this.setState({
      id_sede_opened: id_sede,
      id_sede_accreditamento_opened: id_sede,
      sede_opened: element
    }, () => this.setState({['open_drawer_'+action]: true}))
  }

  /**
   * Torna la differenza tra le sedi dell'ente e quelle legate all'accreditamento
   * @return {[type]} [description]
   */
  getSedi() {
    return this.props.sedi;
  }

  getSediEnte() {
    return this.props.sedi_ente.filter(sede => !this.props.sedi.find(s => s.enteSede.id === sede.id));
  }

  getCountConSedi() {
    return this.props.sedi.length;
  }

  /*selectSede(id) {
    if(!this.state.addingsede) {
      this.setState({addingsede: true, addingsede_id: id});
      InfrastrutturaServices.addExistingSede( this.props.accreditamento.id, id, () =>{
        this.props.onUpdate();
        this.setState({open_modal: false, addingsede: false, addingsede_id: null});
      }, () => this.setState({open_modal: false, addingsede: false, addingsede_id: null}))
    }
  }*/

  selectSede(id) {
    if (!this.state.addingsede) {
      let sede = this.getSediEnte().find(s => s.id === id);
      if ((this.props.accreditamento.tipo === accreditamentoStatic.tipo.riconoscimento_pregresso_definitivo || this.props.accreditamento.tipo === accreditamentoStatic.tipo.riconoscimento_pregresso_provvisorio) && (!sede.numero_protocollo || !sede.data_protocollo)) {
        this.setState({ open_drawer: true, open_modal: false , sede_acc_pregresso: sede, readOnly: true});
      } else {
        this.setState({ addingsede: true, addingsede_id: id });
        InfrastrutturaServices.addExistingSede(this.props.accreditamento.id, id, () => {
          this.props.onUpdate();
          eventBroker.trigger('updateDecretoPregressoInfrastruttura');
          this.setState({ open_modal: false, addingsede: false, addingsede_id: null });
        }, () => this.setState({ open_modal: false, addingsede: false, addingsede_id: null }))
      }
    }
  }




  render() {
    return (
      <div>
        {!this.props.readOnly ?
        <Button
          type="primary"
          icon="plus"
          //disabled={this.props.readOnly || this.getCountConSedi() > 0}
          onClick={()=>{
            if(this.getSediEnte().length > 0) {
              this.setState({open_modal: true})
            } else {
              this.setState({open_drawer: true})
            }
          }}>
          Inserisci sede
        </Button> : []}
        <Modal
          visible={this.state.open_modal}
          onOk={() => this.setState({open_modal: false})}
          okButtonProps={{style: {display: 'none'}}}
          width={500}
          destroyOnClose={true}
          onCancel={()=>this.setState({open_modal: false})}
        >
          <p>Usa una delle sedi già associate al tuo ente</p>
          <List
            dataSource={this.getSediEnte()}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button
                    type="primary"
                    size="small"
                    disabled={this.state.addingsede}
                    loading={this.state.addingsede_id === item.id}
                    onClick={()=>this.selectSede(item.id)}
                    ghost>
                      Seleziona
                    </Button>
                  ]}
              >
                  <List.Item.Meta
                    title={format.getIndirizzo( item.indirizzo, this.props.GlobalStore)}
                  />
              </List.Item>

            )}
          />
          <p>o aggiungi una nuova sede</p>
          <Button type="primary" size="small" onClick={()=>this.setState({open_drawer: true, open_modal: false, sede_acc_pregresso: null, readOnly: false})} ghost>Aggiungi</Button>
        </Modal>
        <Drawer
          title="DATI DELLA SEDE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false })}
          visible={this.state.open_drawer}
          width={1024}
          destroyOnClose={true}
        >
          <Create {...this.props}
            onUpdate={()=>{
            this.setState({open_drawer: false});
            this.props.onUpdate();
            }}
            sede={this.state.sede_acc_pregresso}
            readOnly={this.state.readOnly}
           />
        </Drawer>


        <Table
          rowKey={(record) => record.id}
          loading={this.props.loading_data}
          columns={columns(this)}
          dataSource={this.props.sedi}
        />

        <Drawer
          title="CARATTERISTICHE SEDE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_caratteristiche: false, id_sede_opened: null })}
          visible={this.state.open_drawer_caratteristiche}
          width={window.innerWidth - (window.innerWidth/100*5)}
          destroyOnClose={true} 
        >
          <Caratteristiche
            close={()=>this.setState({open_drawer_caratteristiche: false})}
            id_sede={this.state.id_sede_opened}
            sede={this.state.sede_opened}
            onUpdate={()=>{
              this.props.onUpdate();
              this.setState({open_drawer_caratteristiche: false})
            }}
            readOnly={this.props.readOnly}
            accreditamento={this.props.accreditamento}
            recapiti={this.props.recapiti_ente}
          />
        </Drawer>

        <Drawer
          title="COMPOSIZIONE STRUTTURA SEDE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_aule: false, id_sede_opened: null })}
          visible={this.state.open_drawer_aule}
          width={window.innerWidth - (window.innerWidth/100*5)}
          destroyOnClose={true}
        >
          <Aule
            close={()=>this.setState({open_drawer_aule: false})}
            id_sede={this.state.id_sede_opened}
            sede={this.state.sede_opened}
            accreditamento={this.props.accreditamento}
            onAdd={()=>this.props.onUpdate()}
            onEdit={()=>this.props.onUpdate()}
            onDelete={()=>this.props.onUpdate()}
            readOnly={this.props.readOnly}
          />
        </Drawer>

        <Drawer
          title="AUDIOVISIVI"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_audiovisivi: false, id_sede_opened: null })}
          visible={this.state.open_drawer_audiovisivi}
          width={window.innerWidth - (window.innerWidth/100*5)}
          destroyOnClose={true}
        >
          <Audiovisivi
            close={()=>this.setState({open_drawer_aule: false})}
            id_sede={this.state.id_sede_opened}
            sede={this.state.sede_opened}
            accreditamento={this.props.accreditamento}
            onAdd={()=>this.props.onUpdate()}
            onEdit={()=>this.props.onUpdate()}
            onDelete={()=>this.props.onUpdate()}
            readOnly={this.props.readOnly}
          />
        </Drawer>

         <Drawer
          title="ATTREZZATURE DISPONIBILI"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_attrezzature: false, id_sede_opened: null })}
          visible={this.state.open_drawer_attrezzature}
          width={window.innerWidth - (window.innerWidth/100*5)}
          destroyOnClose={true}
        >
          <Attrezzature
            close={()=>this.setState({open_drawer_aule: false})}
            id_sede={this.state.id_sede_opened}
            sede={this.state.sede_opened}
            accreditamento={this.props.accreditamento}
            onAdd={()=>this.props.onUpdate()}
            onEdit={()=>this.props.onUpdate()}
            onDelete={()=>this.props.onUpdate()}
            readOnly={this.props.readOnly}
          />
        </Drawer>
        
        {/* https://cb.schema31.it/cb/issue/417022
        <Drawer
          title="RISPETTO DELLA NORMATIVA SUI LUOGHI DI LAVORO"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_requisiti: false, id_sede_opened: null })}
          visible={this.state.open_drawer_requisiti}
          width={window.innerWidth - (window.innerWidth/100*5)}
          destroyOnClose={true}
        >
          <RequisitiAule
            close={()=>{
              this.setState({open_drawer_requisiti: false});
              this.props.onUpdate();
            }}
            id_sede={this.state.id_sede_opened}
            sede={this.state.sede_opened}
            accreditamento={this.props.accreditamento}
            readOnly={this.props.readOnly}
            type='normativa'
          />
        </Drawer>
          */}

        <Drawer
          title="REQUISITI TECNICI DELL'IMMOBILE SEDE DI ACCREDITAMENTO"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_requisiti_sede: false, id_sede_opened: null })}
          visible={this.state.open_drawer_requisiti_sede}
          width={window.innerWidth - (window.innerWidth/100*5)}
          destroyOnClose={true}
        >
          <RequisitiAule
            close={()=>{
              this.setState({open_drawer_requisiti_sede: false});
              this.props.onUpdate();
            }}
            id_sede={this.state.id_sede_opened}
            sede={this.state.sede_opened}
            accreditamento={this.props.accreditamento}
            readOnly={this.props.readOnly}
            type='requisiti'
          />
        </Drawer>

        <Drawer
          title="RINTRACCIABILITA'"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_rintracciabilita: false, id_sede_opened: null })}
          visible={this.state.open_drawer_rintracciabilita}
          width={window.innerWidth - (window.innerWidth/100*5)}
          destroyOnClose={true}
        >
          <Rintracciabilita
            close={()=>{
              this.setState({open_drawer_rintracciabilita: false})
              this.props.onUpdate();
            }}
            id_sede={this.state.id_sede_opened}
            sede={this.state.sede_opened}
            accreditamento={this.props.accreditamento}
            readOnly={this.props.readOnly}
          />
        </Drawer>
      </div>
    );
  }
}
export default Sedi;