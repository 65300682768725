export default {
  stato: {
    predisposizione: 0,
    compilata: 1,
    da_firmare: 2,
    pronta_invio: 3,
    acquisita: 4,
    assegnata: 5,
    in_istruttoria: 6,
    sospesa_in_attesa_di_integrazioni: 7,
    sospesa_in_attesa_di_controdeduzioni: 8,
    validazione: 9,
    valutazione: 10,
    rifiutata: 11,
    accettata: 12,
    integrazioni_da_firmare: 13,
    integrazioni_firmate: 14,
    completamento: 15,
    gtr_sospesa: 16,
  },

  stato_flusso: {
    compilation: 0,
    compiled: 1,
    nedded_signed: 2,
    signed: 3,
    sent: 4,
    assigned: 5,
    investigation: 6,
    integrations_suspend: 7,
    confirmed_integration_suspend: 8,
    counterarguments_suspend: 9,
    confirmed_counterarguments_suspend: 10,
    accepted: 11,
    validation: 12,
    evaluation: 13,
    rejected: 14,
    confirm_accepted: 15,
    integration_needed_sign: 16,
    integration_signed: 17,
    counterargument_sent: 18,
    prepare_reject: 19,
    gtr_completion: 20,
    gtr_suspend: 21,
    gtr_counterargument_sent: 22,
    gtr_deepening: 23,
    gtr_tavolo_tecnico: 24,
    gtr_completed: 25,
    gtr_prepare_reject: 26,
  },

  tipo_richiesta: {
    nuova: 1,
    modifica: 2,
    integrazione: 3,
  },

  tipo_media: {
    sottodominio_richiesta_firmata: 'richiesta_repertorio_firmata',
    sottodominio_ricevuta_invio_proposta: 'ricevuta_invio_proposta',
    sottodominio_decreto: 'decreto_dirigenziale_repertorio',
    relazione_descrittiva: 'relazione_descrittiva',
    dichiarazione_di_interesse: 'dichiarazione_di_interesse',
    richiesta_integrazione_firmata: 'richiesta_repertorio_integrazione_firmata',
    sottodominio_verbale_tavolo_tecnico: 'verbale_tavolo_tecnico'
  },

  getStatoLabel: (stato) => {
    let label = '';
    switch (stato) {
      case 0: label = 'In predisposizione'; break;
      case 1: label = 'Compilata'; break;
      case 2: label = 'Da firmare'; break;
      case 3: label = 'Pronta all\' invio'; break;
      case 4: label = 'Inviata'; break;
      default: break;
    }
    return label;
  },

  getTipoRichiestaLabel: (tipo) => {
    let label = '';
    switch (tipo) {
      case 1: label = 'Nuova Figura professionale'; break;
      case 2: label = 'Modifica Figura professionale'; break;
      case 3: label = 'Integrazione Documentale'; break;
      default: break;
    }
    return label;
  },

  getPallino(user, c, stato) {
    let pallini = {
      dirigente: {
        green: [
          this.stato_flusso.sent,
          this.stato_flusso.accepted,
          this.stato_flusso.counterarguments_suspend,
          this.stato_flusso.integrations_suspend,
          this.stato_flusso.validation,
          this.stato_flusso.prepare_reject
        ],
        red: [
          this.stato_flusso.assigned,
          this.stato_flusso.investigation,
          this.stato_flusso.confirmed_counterarguments_suspend,
          this.stato_flusso.confirmed_integration_suspend,
          this.stato_flusso.evaluation,
          this.stato_flusso.integration_needed_sign,
          this.stato_flusso.integration_signed,
          this.stato_flusso.counterargument_sent,
          this.stato_flusso.gtr_counterargument_sent,
          this.stato_flusso.gtr_deepening,
          this.stato_flusso.gtr_tavolo_tecnico,
          this.stato_flusso.gtr_completion,
          this.stato_flusso.gtr_completed
        ]
      },
      istruttore: {
        green: [
          this.stato_flusso.assigned,
          this.stato_flusso.investigation,
          this.stato_flusso.counterargument_sent,
          this.stato_flusso.gtr_counterargument_sent
        ],
        red: [
          this.stato_flusso.counterarguments_suspend,
          this.stato_flusso.integrations_suspend,
          this.stato_flusso.confirmed_counterarguments_suspend,
          this.stato_flusso.confirmed_integration_suspend,
          this.stato_flusso.integration_needed_sign,
          this.stato_flusso.integration_signed,
        ]
      },
      ente: {
        green: [
          this.stato_flusso.compilation,
          this.stato_flusso.compiled,
          this.stato_flusso.nedded_signed,
          this.stato_flusso.confirmed_counterarguments_suspend,
          this.stato_flusso.confirmed_integration_suspend,
          this.stato_flusso.integration_needed_sign,
          this.stato_flusso.integration_signed,
          this.stato_flusso.gtr_suspend
         
        ],
        red: [
          this.stato_flusso.sent,
          this.stato_flusso.assigned,
          this.stato_flusso.investigation,
          this.stato_flusso.accepted,
          this.stato_flusso.counterarguments_suspend,
          this.stato_flusso.integrations_suspend, 
          this.stato_flusso.counterargument_sent
        ]
      },
      coordinatore_gtr: {
        green: [
          this.stato_flusso.evaluation,
          this.stato_flusso.gtr_deepening,
          this.stato_flusso.gtr_tavolo_tecnico,
          this.stato_flusso.gtr_completion,
          this.stato_flusso.gtr_completed
        ],
        red: []
      },
      tecnico_gtr: {
        green: [
          this.stato_flusso.evaluation,
          this.stato_flusso.gtr_completion,
        ],
        red: [
          this.stato_flusso.gtr_deepening,
          this.stato_flusso.gtr_tavolo_tecnico,
          this.stato_flusso.gtr_completed
        ]
      },
    }

    return pallini[user][c].indexOf(stato);

  },

  linkToAtlanteRepertorio: 'https://atlantelavoro.inapp.org/atlante_repertori.php'







}
