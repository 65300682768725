import React, { Component } from 'react';
import { Icon, Tabs } from "antd";
import { Sticky, StickyContainer } from 'react-sticky';
import SpinnerLoading from "@common/components/SpinnerLoading";
import LearnerCourseRegistrationForm from '../forms/courseRegistration/LearnerCourseRegistrationForm';
import RegistrationDocumentsList from "@modules/affidamento/components/documents/RegistrationDocumentsList";

import PropTypes from 'prop-types';

const TabPane = Tabs.TabPane;

export default class LearnerCourseRegistration extends Component {

  static propTypes = {
    id_ente: PropTypes.number,
    isEditable: PropTypes.bool,
    refresh: PropTypes.func,
    domanda_json: PropTypes.object,
    progress_obj: PropTypes.object,
  }

  state = {
    current_key: "1",
    loading: false,
  }

  componentDidMount() { }

  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  render() {
    return (
      <div>{
        this.state.loading
          ?
          <SpinnerLoading message='Caricamento dati...' />
          :
          <StickyContainer>
            <Tabs
              onChange={(k) => this.setState({ current_key: "" + k })}
              defaultActiveKey="0" size="medium" renderTabBar={this.renderTabBar}>
              {this.props.domanda_json?.generalData &&
                <TabPane
                  tab={
                    <span className={this.props.anteprima && 'default'}>DATI<br />DISCENTE<br /><Icon type="check-circle" /></span>} key="0">
                  <div id="learner_data">
                    <div style={{ padding: 14 }}>
                      <LearnerCourseRegistrationForm
                        {...this.props}
                        tab={this.state.current_key}
                        readOnly={true}
                        registration_info={this.props.registration_info}
                        loading={this.props.compile_loading}
                        onCompile={(data) => this.props.compile(data)}
                      />
                    </div>
                  </div>
                </TabPane>
              }
              {
                this.props.domanda_json?.generalData &&
                <TabPane
                  tab={
                    <span className={this.props.anteprima && 'default'}>ALLEGATI<br />DOMANDA<br /><Icon type="check-circle" /></span>} key="1">
                  <div id="registration_documents">
                    <div style={{ padding: 14 }}>
                      <RegistrationDocumentsList
                        {...this.props}
                        tab={this.state.current_key}
                        registration_info={this.props.registration_info}
                        registration_id={this.props.registration_id}
                        uploadEnabled={this.props.registration_info.stato < 2 ? true : false}
                        deleteEnabled={this.props.registration_info.stato < 2 ? true : false}
                        uploadDocument={(data) => this.props.uploadDocument(data)}
                        removeDocument={(data) => this.props.removeDocument(data)}
                        loading={this.props.uploading}
                        is_discente={this.props.is_discente}
                      />
                    </div>
                  </div>
                </TabPane>
              }
            </Tabs>
          </StickyContainer>
      }</div>);
  }
}