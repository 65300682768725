import React, { Component } from 'react';
import { connect } from 'react-redux';

import { message } from 'antd';

import CommonForm from '@common/components/form';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: <span>Ciascuna sede oggetto di accreditamento è dotata di segnaletica esterna riportante: <ol type='a'><li>la denominazione dell'operatore</li><li>i recapiti telefonici, mail e pec</li><li>gli orari di apertura al pubblico</li><li>il codice di accreditamento</li></ol></span>,
              name: 'segnaletica',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    /* https://cb.schema31.it/cb/issue/417030 { 
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Denominazione del soggetto',
              name: 'denominazione_soggetto',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    */
    /* https://cb.schema31.it/cb/issue/417030 {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Logo',
              name: 'logo',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    */
    /* https://cb.schema31.it/cb/issue/417030 {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Recapito telefonico',
              name: 'recapito_telefonico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    */
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Per ciascuna sede oggetto di accreditamento deve assicurarsi l\'apertura al pubblico per almeno 5 ore al giorno e 25 ore settimanali',
              name: 'orario_apertura_pubblico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    /* https://cb.schema31.it/cb/issue/417030  {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Linea telefonica di rete fissa',
              name: 'telefono_fisso',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    */
    /* https://cb.schema31.it/cb/issue/417030 {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Collegamento ad Internet',
              name: 'collegamento_internet',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    */
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: <span>L'operatore deve disporre di sito internet dedicato alle attività formative. Il sito internet deve esporre: <ol type='a'><li>le informazioni anagrafiche dell'operatore</li><li>l'elenco delle sedi, i relativi indirizzi, contatti e orari di apertura</li><li>il modello di organizzazione e gestione, comprensivo dei nominativi delle funzioni chiave, dei rispettivi ruoli e contatti</li><li>le informazioni di cui ai requisiti C7, C8 e C9</li><li>le informazioni inerenti i percorsi formativi, distinti tra riconosciuti e non riconosciuti da Regione Campania</li><li>per ciascun percorso formativo riconosciuto, gli standard professionali e formativi, i programmi, le sedi di svolgimento (aula, laboratorio, stage), le indicazioni circa l'eventuale utilizzo di modalità E-Learning</li></ol></span>,
              name: 'pagina_web',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    /* https://cb.schema31.it/cb/issue/417030 {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Indirizzo e-mail',
              name: 'indirizzo_email',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    */
    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'btn',
              text: 'Conferma',
              props: {
                icon: 'check',
                className: 'bg-green'
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'onSubmit',
}

class RequisitiForm extends Component {

  state = {
    loaded: false
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    });
  }

  submitForm(data) {
    let valid = true;
    Object.keys(data).forEach(k => {
      if (parseInt(data[k]) !== 1) valid = false
    })

    if (!valid) {
      message.error("Devi selezionare si per ogni voce");
      return;
    }

    if (this.props.onSubmit) this.props.onSubmit(data);
  }



  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRefBools = inst)}
          loading={this.props.loading || false}
          form_model={formLayout}
          values={this.props.values}
          onSubmit={(values) => {
            this.submitForm(values)
          }}
          readOnly={this.props.readOnly}

        />
      </div>
    );
  }
}

export default connect(({ GlobalStore }) => ({ GlobalStore }))(RequisitiForm);
