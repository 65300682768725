import React, { Component } from 'react';
import { Card, Icon, Tabs, message } from "antd";
import { Sticky, StickyContainer } from 'react-sticky';
import SpinnerLoading from "@common/components/SpinnerLoading";
import PropTypes from 'prop-types';
import DatiIdentificativiForm from '../forms/richiesta/DatiIdentificativiForm';
import AllegatiForm from '../forms/richiesta/AllegatiForm';
import repertorioStatic from '@common/utils/static/repertorio';
import AuditService from '../services/AuditRichiesta'
import checkPermission from '@common/utils/check/permissions';

const TabPane = Tabs.TabPane;

export default class SchedaRichiestaRepertorio extends Component {

  static propTypes = {
    id_ente: PropTypes.number,
    isEditable: PropTypes.bool,
    refresh: PropTypes.func,
    domanda_json: PropTypes.object,
    progress_obj: PropTypes.object,
    updateProgress: PropTypes.func,
  }

  state = {
    current_key: "1",
  }

  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  isValidSection(name) {

    if (this.props.repertorio && this.props.repertorio.section_feedback && this.props.repertorio.section_feedback[name]) {
      return this.props.repertorio.section_feedback[name].valid ? 'checked' : 'invalid';
    } else if (this.props.repertorio.stato_flusso === repertorioStatic.stato_flusso.investigation && (!this.props.section_feedback || !this.props.section_feedback[name])) {
      return 'default';
    }

    if (this.props.progress_obj && this.props.progress_obj.valid_sections && this.props.progress_obj.valid_sections[name]) {
      return this.props.progress_obj.valid_sections[name].valid ? 'checked' : 'invalid';
    } else if (!this.props.progress_obj || !this.props.progress_obj.valid_sections || !this.props.progress_obj.valid_sections[name]) {
      return 'default';
    }
  }


  updateProgress(progress) {
    this.props.progress_obj.valid_sections = progress.valid_sections;
    if (this.props.updateProgress) {
      this.props.updateProgress(progress);
    }
  }

  saveFeedback(note, valid, section) {
    if (!this.state.loadingFeedback) {
      this.setState({
        loadingFeedback: true,
      }, () => {

        AuditService.feedbackSection(
          this.props.repertorio.id,
          {
            section: section,
            note: note,
            valid: valid
          },
          (res) => {
            //this.props.refreshInline(res.data.data);
            message.success("La sezione è stata salvata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data)
            this.setState({ loadingFeedback: false });
          },
          (err) => this.setState({ loadingFeedback: false })
        );
      });
    }
  }

  isEditable() {
    return ((checkPermission.hasPermission(this.props.UserStore.user, ['AddIntegrazioneDocumentale']) &&
      (this.props.repertorio.stato_flusso === repertorioStatic.stato_flusso.confirmed_integration_suspend || this.props.repertorio.stato === repertorioStatic.stato.predisposizione))
      || (checkPermission.hasPermission(this.props.UserStore.user, ['completeRichiesta']) && this.props.repertorio.stato_flusso === repertorioStatic.stato_flusso.gtr_completion))
  }

  render() {


    return (<div>{this.state.loading ? <SpinnerLoading message='Caricamento dati...' /> :
      <Card
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={k => this.setState({ current_key: "" + k })}
            defaultActiveKey="1" size="medium" renderTabBar={this.renderTabBar}>
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('generalData')}>DATI<br />IDENTIFICATIVI<br /><Icon type="check-circle" /></span>
              } key="1">
              <div id="generalData">
                <div style={{ padding: 14 }}>
                  <DatiIdentificativiForm
                    {...this.props}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    tab={this.state.current_key}
                    repertorio={this.props.repertorio}
                    section_feedback={this.props.repertorio.section_feedback}

                    readOnly={!this.isEditable()}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('attachments')}>ALLEGATI<br /><Icon type="check-circle" /></span>
              } key="2">
              <div id="attachments">
                <div style={{ padding: 14 }}>
                  <AllegatiForm
                    {...this.props}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    section_feedback={this.props.repertorio.section_feedback}
                    tab={this.state.current_key}
                    repertorio={this.props.repertorio}
                    tipo_media={this.props.GlobalStore.stubs.tipo_media}
                    readOnly={!this.isEditable()}
                    showIntegrazione = {checkPermission.hasPermission(this.props.UserStore.user, ['AddIntegrazioneDocumentale']) && this.props.repertorio.stato_flusso === repertorioStatic.stato_flusso.confirmed_integration_suspend}
                    integrazioni_richieste={  this.props.repertorio.audit &&  this.props.repertorio.audit.length > 0 &&
                      this.props.repertorio.audit[this.props.repertorio.audit.length - 1].integrazione
                    }
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Card>
    }</div>);
  }
}

