import { Icon } from 'antd';
import React, { Component } from 'react';
import format from '@common/utils/formatters/_';
//import { connect } from "react-redux";
import DatiIndirizzo from '@affidamento/components/soggettiCorrelati/DatiIndirizzo'
import CommonForm from '@common/components/form';

const formLayout = (component) => {
  return { layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Esterno all\'amministrazione',
              name: 'esterno_amministrazione',
                                options: [
                                    {
                                        value: "1",
                                        label: "SI"
                                    },
                                    {
                                        value: "0",
                                        label: "NO"
                                    },
                                ],
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />,
                disabled: component.props.values.id
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Dipartimento',
              type_string: 'text', // field type
              name: 'settoreRegionale.dipartimento.id',
              options_props: 'dipartimenti',
              onChange: 'selectedDipartimento',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />,
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'esterno_amministrazione',
                  operator: '==',
                  value: 0,
                },
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },

        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Settore/Unità operativa',
              type_string: 'text', // field type
              name: 'id_settore_regionale',
              options_props: 'settori',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />,
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'esterno_amministrazione',
                  operator: '==',
                  value: 0,
                },
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        { span: 24,
          items: [
            {
              name: 'titolo_residenza',
              type: 'title',
              element: 'h4',
              text: 'Sede di lavoro',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'esterno_amministrazione',
                  operator: '==',
                  value: "0",
                },
              ],
            },
          ],
        },
        { 
          span: 24,
          items: [
            {
              name: 'indirizzo_dati',
              type: 'child',
              showIf: [
                {
                  type: 'field_validation',
                  field: 'esterno_amministrazione',
                  operator: '==',
                  value: "0",
                },
              ],
              child: <div style={{ marginBottom: 16 }}>
                <React.Fragment>
                  <div
                    ref={(ref) => component.dati_sede_lavoro_container = ref}
                  >
                    <DatiIndirizzo
                      {...component.props}
                      readOnly={false}
                      dati={component.props.values.indirizzoSedeLavoro}
                      ref={(ref) => component.dati_sede_lavoro = ref}
                      subform={true}
                    ></DatiIndirizzo>
                  </div>
                </React.Fragment>
              </div>
            }
          ],
        },

      ],
    },
    

    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'simple_upload',
              name: 'cv',
              accept: '.pdf',
              action: '/',
              customRequest: 'customUploadRequest',
              onRemove: 'onRemove',
              filename: 'file',
              listType: 'text', // text,picture,picture-card
              multiple: false,
              text: "Carica il curriculum pdf",
              showIf: [
                {
                  type: 'field_validation',
                  field: 'esterno_amministrazione',
                  operator: '==',
                  value: 0,
                },
              ],
              props: {
                style: { width: '100%' },
                showUploadList: true
              },
            }
          ]
        }

      ],
    },

    {
      cols: [
        {
          items: [
            {
              name: 'submit',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            }
          ]
        }
      ]
    },
  ],
  onSubmit: 'submitted',
}};


class InfoIspettoreForm extends Component {

  state = {
    loading: false
  };

  componentDidMount() {
  
    let media = this.props.values?.media?.filter(el => el.tipo.sottodominio === 'curriculum_dipendente_regionale');
    this.setState({
      fileName : media && media.length > 0 ? media[0].nome_file : null
    })
    if (this.props.values.settoreRegionale) {
      this.setState({
        settori_dipartimento: this.props.GlobalStore.stubs.settori_regionali?.filter(el => el.id_dipartimento_regionale === parseInt(this.props.values.settoreRegionale.id_dipartimento_regionale)),     
      });
    }
  }

  confirm(data) {
    let file = null;
 
    if(this.state.fileList && this.state.fileList.length > 0){
      file = this.state.fileList;
    }

    let dati_sede_lavoro = this.dati_sede_lavoro? this.dati_sede_lavoro.formRef.props.form.getFieldsValue() : {};
        data.sede_lavoro = dati_sede_lavoro;
    this.props.submit(data, file);
  }

  getValues() {
    return format.replaceIntegeresWithString([
      'esterno_amministrazione'
    ], this.props.values);

  }



  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          submitted={(dati) => this.confirm(dati)}
          dipartimenti={this.props.GlobalStore.stubs.dipartimenti_regionali}
          settori={this.state.settori_dipartimento || []}
          selectedDipartimento={(data) => {
            this.formRef.props.form.setFieldsValue({ 'id_settore_regionale': null })
            this.setState({settori_dipartimento : this.props.GlobalStore.stubs.settori_regionali?.filter(el => el.id_dipartimento_regionale === data )})
          }}
         // files={this.state.fileList}
          file={this.state.fileName}
          onRemove={() => {

              this.setState ({
                fileList: [],
              });

            }}

            customUploadRequest={obj => {
              this.setState({
                fileList: [{
                    chiave: 'file',
                    uid: obj.file.uid,
                    name: obj.file.name,
                    file: obj.file,
                    status: 'done',
                }],
                fileName: obj.file.name,
              });
            }}

        />
      </div>
    );
  }
}

export default InfoIspettoreForm