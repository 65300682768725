import React, { Component } from 'react';

import DatiRappresentanteLegaleForm from '@modules/accreditamento/forms/completeRequest/dati/rappresentante-legale/Dati'
import IndirizzoRappresentanteLegale from '@modules/accreditamento/forms/completeRequest/dati/rappresentante-legale/Indirizzo'
import DatiEnte from '@modules/accreditamento/forms/completeRequest/dati/ente/Dati'
import SedeEnte from '@modules/accreditamento/forms/completeRequest/dati/ente/Sede'

class DatiEnteAccreditato extends Component {

    state = {
        loading: false
    };

    render() {
        return (
            <div style={{ padding: 14 }}>
                <React.Fragment>
                        <DatiRappresentanteLegaleForm
                            {...this.props}
                            accreditamento={this.props.accreditamento}
                            readOnly={this.props.readOnly}
                            ref={(ref) => this.dati_rappr_legale = ref}
                        />

                        <IndirizzoRappresentanteLegale
                            {...this.props}
                            accreditamento={this.props.accreditamento}
                            readOnly={this.props.readOnly}
                            ref={(ref) => this.indirizzo_rappr_legale = ref}

                        />
                        <DatiEnte
                            {...this.props}
                            accreditamento={this.props.accreditamento}
                            readOnly={this.props.readOnly}
                            onChangeLucro={(data) => { }}
                            ref={(ref) => this.dati_ente = ref}
                        />

                        <SedeEnte
                            {...this.props}
                            accreditamento={this.props.accreditamento}
                            readOnly={this.props.readOnly}
                            ref={(ref) => this.sede_ente = ref}
                        />

                </React.Fragment>
            </div>
        )
    }
}

export default DatiEnteAccreditato