import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import { Button, Card, Tooltip, notification, Row, Drawer } from 'antd';
import TableComponent from '@common/components/table';
import moment from 'moment';
import queryString from 'query-string';

import _ from '@common/utils/formatters/_';

import ProvvedimentoForm from '@accreditamento/components/provvedimenti/ProvvedimentoForm';
import ConclusioneProvvedimentoForm from '@accreditamento/components/provvedimenti/ConclusioneProvvedimentoForm';

import EnteServices from '@accreditamento/services/Ente';

const model = (component) => {
    let cols = {
        with_server_pagination: true,
        columns: component.props.UserStore.user.permissions.entePermissions ? [
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'actions',
                //width: "300px",
                render: element => <div style={{ width: 140 }}>
                    {
                        renderActions(element, component)
                    }
                </div>,
            },
            {
                title: 'Data Avvio procedimento',
                dataIndex: 'data_avvio',
                //sorter: true,
                key: 'data_avvio',
                render: element => <div style={{ width: 140 }}>
                    {element == null ? '-' : moment(element).format('DD/MM/YYYY')}
                </div>
            },
            {
                title: 'Giorni restanti per l\'invio di controdeduzioni',
                dataIndex: 'data_avvio',
                //sorter: true,
                key: 'data_avvio',
                render: element => <div style={{ width: 140 }}>
                    {giorniRestanti(element, 10)}
                </div>
            },
            {
                title: 'Data creazione',
                dataIndex: 'created_at',
                //sorter: true,
                key: 'created_at',
                render: element => <div style={{ width: 140 }}>
                    {moment.unix(element).format('DD/MM/YYYY HH:mm')}
                </div>
            },
            {
                title: 'Ultimo aggiornamento',
                dataIndex: 'updated_at',
                //sorter: true,
                key: 'updated_at',
                render: element => <div style={{ width: 140 }}>
                    {moment.unix(element).format('DD/MM/YYYY HH:mm')}
                </div>
            },
            {
                title: 'Stato',
                dataIndex: '',
                //sorter: true,
                key: 'stato',
                render: element => <div>
                    {getStato(element, component)}
                </div>
            },
            {
                title: 'Protocollo',
                dataIndex: '',
                //sorter: true,
                key: 'numero_protocollo',
                render: element => <div>
                    {
                        element.numero_protocollo &&
                        <p>
                           {element.numero_protocollo}
                        </p>
                    }
                </div>
            },
            {
                title: 'Responsabile provvedimento',
                dataIndex: '',
                sorter: false,
                key: 'ana_nome',
                render: element => <div style={{ width: 140 }}>
                    {
                        element.id_user &&
                        <p>
                            <strong>Dirigente/Istruttore</strong>
                            <br />
                            {element.id_user.anagrafica.nome} {element.id_user.anagrafica.cognome}
                        </p>
                    }
                </div>
            }
        ]
        :
        [
        {
            title: '',
            dataIndex: '',
            sorter: false,
            key: 'actions',
            //width: "300px",
            render: element => <div style={{ width: 140 }}>
                {
                    renderActions(element, component)
                }
            </div>,
        },
        {
            title: 'Data Avvio procedimento',
            dataIndex: 'data_avvio',
            //sorter: true,
            key: 'data_avvio',
            render: element => <div style={{ width: 140 }}>
                {element == null ? '-' : moment(element).format('DD/MM/YYYY')}
            </div>
        },
        {
            title: 'Giorni restanti per la conclusione del procedimento',
            dataIndex: 'data_avvio',
            //sorter: true,
            key: 'data_avvio',
            render: element => <div style={{ width: 140 }}>
                {giorniRestanti(element, 30)}
            </div>
        },
        {
            title: 'Data creazione',
            dataIndex: 'created_at',
            //sorter: true,
            key: 'created_at',
            render: element => <div style={{ width: 140 }}>
                {moment.unix(element).format('DD/MM/YYYY HH:mm')}
            </div>
        },
        {
            title: 'Ultimo aggiornamento',
            dataIndex: 'updated_at',
            //sorter: true,
            key: 'updated_at',
            render: element => <div style={{ width: 140 }}>
                {moment.unix(element).format('DD/MM/YYYY HH:mm')}
            </div>
        },
        {
            title: 'Stato',
            dataIndex: '',
            //sorter: true,
            key: 'stato',
            render: element => <div>
                {getStato(element, component)}
            </div>
        },
        {
            title: 'Protocollo',
            dataIndex: '',
            //sorter: true,
            key: 'numero_protocollo',
            render: element => <div>
                {
                    element.numero_protocollo &&
                    <p>
                       {element.numero_protocollo}
                    </p>
                }
            </div>
        },
        {
            title: 'Responsabile provvedimento',
            dataIndex: '',
            sorter: false,
            key: 'ana_nome',
            render: element => <div style={{ width: 140 }}>
                {
                    element.id_user &&
                    <p>
                        <strong>Dirigente/Istruttore</strong>
                        <br />
                        {element.id_user.anagrafica.nome} {element.id_user.anagrafica.cognome}
                    </p>
                }
            </div>
        }
    ]
    }

    return cols;
}

const giorniRestanti = (data_avvio, days) => {

    if(data_avvio == null) return '-';

    var data_scadenza = new Date(data_avvio);
    data_scadenza.setDate(data_scadenza.getDate() + days);

    var today = new Date();

    // To calculate the time difference of two dates
    var Difference_In_Time = data_scadenza.getTime() - today.getTime();
    
    // To calculate the no. of days between two dates
    var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)).toFixed(0);

    return Difference_In_Days < 0 ? 0 : Difference_In_Days;//moment(data_scadenza).format('DD/MM/YYYY');
}

const getStato = (record, component) => {
    let stato_label = '--';
    switch(record.stato){
        case 0: stato_label = record.controdeduzioni_disabilitate ? 'Annullato' : 'Predisposto'; break;
        case 1: stato_label = 'Da firmare'; break;
        case 2: stato_label = 'Firmata'; break;
        case 3: stato_label = 'Attivato'; break;
        case 4: stato_label = 'Chiuso dall\'istruttore'; break;
        case 5: stato_label = 'Inviate controdeduzioni'; break;
        case 6: stato_label = 'Confermato dal dirigente'; break;
        case 7: stato_label = 'Annullato dal dirigente'; break;
    }

    return <div>{stato_label}</div>
}

const renderActions = (element, component) => {
    
    return (
        <>
        <div style={{ display: 'inline-block' }}>

            <Tooltip placement="top" title="Visualizza dettagli del procedimento">
                <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.updateDrawer(element.id, 'DETAILS')} />
            </Tooltip>

            {
                /*component.props.UserStore.user.permissions.dirigentePermissions && */ element.stato == 6 &&
                <Tooltip placement="top" title="Visualizza dettagli della conclusione del procedimento">
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="check" onClick={() => component.updateDrawer(element.id, 'CONCLUDI_PROCEDIMENTO')} />
                </Tooltip>
            }

            {
                component.props.UserStore.user.permissions.dirigentePermissions &&
                (element.stato !== 3 && element.stato !== 4 && element.stato !== 5 && element.stato !== 6) &&
                <Tooltip placement="top" title={component.state.page.btnAttivazioneValue}>
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="check" onClick={() => component.updateDrawer(element.id, 'ACTIVATE')} />
                </Tooltip>
            }
            {
                component.props.UserStore.user.permissions.dirigentePermissions &&
                (element.stato === 5) &&
                <>
                {/*<Tooltip placement="top" title="Scarica documento Controdeduzioni">
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="download" onClick={() => component.downloadDocumentoControdeduzioniEnte(element.id, 'CONTRODEDUZIONI')} />
                </Tooltip>*/}
                
                {/* Originale SISFO
                <Tooltip placement="top" title={component.state.page.btnConfermaValue}>
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="check" onClick={() => component.submitProvvedimentoFinalState(element.id, 'CONFIRM')} />
                </Tooltip>*/}
                <Tooltip placement="top" title={component.state.page.btnConfermaValue}>
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="check" onClick={() => component.updateDrawer(element.id, 'CONCLUDI_PROCEDIMENTO')} />
                </Tooltip>

                <Tooltip placement="top" title={component.state.page.btnAnnullaValue}>
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="close" onClick={() => component.submitProvvedimentoFinalState(element.id, 'ABORT')} />
                </Tooltip>
                </>
            }
            {
                component.props.UserStore.user.permissions.entePermissions && component.props.provvedimentoType != 'PROVVEDIMENTO_REVOCA' &&
                (element.stato === 3) &&
                <>
                {/*<Tooltip placement="top" title="Scarica documento Controdeduzioni">
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="download" onClick={() => component.downloadDocumentoControdeduzioni(element.id, 'CONTRODEDUZIONI')} />
                </Tooltip>*/}
                <Tooltip placement="top" title="Controdeduzioni">
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="check" onClick={() => component.updateDrawer(element.id, 'CONTRODEDUZIONI')} />
                </Tooltip>
                </>
            }

        </div>
        </>
    );
}

class ListProvvedimenti extends Component {
    base_url = '';

    constructor(props) {
        super(props);
        
        this.submitProvvedimentoAdd = this.submitProvvedimentoAdd.bind(this);
        this.submitProvvedimentoActivate = this.submitProvvedimentoActivate.bind(this);
        this.submitProvvedimentoControdeduzioni = this.submitProvvedimentoControdeduzioni.bind(this);
        this.submitConclusioneProcedimento = this.submitConclusioneProcedimento.bind(this);
        
        /* originale SISFO
            let title = '';
        */
        let title = 'Comunicazione di avvio del procedimento';
        /* originale SISFO
        switch(props.provvedimentoType){
            case 'COMUNICAZIONE_SOSPENSIONE':
                title = 'Comunicazione avvio procedimento di sospensione';
                break;
            case 'PROVVEDIMENTO_SOSPENSIONE':
                title = 'Provvedimento di sospensione';
                break;
            case 'COMUNICAZIONE_REVOCA':
                title = 'Comunicazione avvio procedimento di revoca';
                break;
            case 'PROVVEDIMENTO_REVOCA':
                title = 'Provvedimento di revoca';
                break;
        }
        */
        this.state = {
            page:{
                title: title,
                // originale SISFO btnAddValue: 'Avvia ' + title.toLowerCase(),
                btnAddValue: 'Comunicazione di avvio del procedimento',
                btnAttivazioneValue: 'Attivazione ' + title.toLowerCase(),
                // originale SISFO btnConfermaValue: 'Conferma ' + title.toLowerCase(),
                // originale SISFO btnAnnullaValue: 'Annulla ' + title.toLowerCase(),
                btnConfermaValue: 'Provvedimento di conclusione del procedimento',
                btnAnnullaValue: 'Annulla avvio del procedimento',
            },
            loading: true,
            disableNuovaComunicazione: false,
            data: {
                refreshed_at: -1,
                total: 0,
                current: 1,
                data: []
            },
            drawer: {
                title: 'Aggiungi',
                open: false,
                loading: false,
                id: null,
                values: {},
                readOnly: false,
                onSubmit: undefined,
                concludiProcedimento: false,
            }
        }
      }

    componentDidMount() {
        this.callAndUpdate(this.props)
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.data.refreshed_at != this.state.data.refreshed_at){
            this.callAndUpdate(this.props);
        }
    }

    callAndUpdate(props) {
        let { params, pager } = _.getFilterParam(props.location.search, this.default_order);

        EnteServices.listProvvedimenti({provvedimentoType: this.props.provvedimentoType}, props.match.params.id, (res) => {
            this.setState(prevState => ({
                loading: false,
                data: {
                    ...prevState.data,
                    data: res.data.data,
                    current: 1,//data.current,
                    total: 0,//data.total,
                    refreshed_at: -1,
                }
            }), () => this.refs.table_component.updateTableData(
                res.data.data,
                1,//data.current,
                0,//data.total,
                pager)
            );
        
            // https://cb.schema31.it/cb/issue/446355
            res.data.data.map(el => {
                // mi basta anche un solo procedimento NON chiuso e NON annullato per disabilitare il stato nuova comunicazione
                if((el.stato != 4) && (el.stato != 7)){
                    this.setState({disableNuovaComunicazione: true});
                }
            });
        
        }, (err) => {
            this.setState({ loading: false });
            console.error("EnteServices.listProvvedimenti", err)
        });

    }

    updateQueryStr(obj) {
        try {
            let q_s = queryString.parse(this.props.location.search);
            // brutto forte
            if (obj.sort) {
                if (q_s.sort && obj.sort) {
                    if (q_s.sort === obj.sort) {
                        if (q_s.sort[0] === "") {
                            q_s.sort = q_s.sort.replace("-", "");
                        } else {
                            q_s.sort = "-" + q_s.sort
                        }
                    } else {
                        q_s = { ...q_s, ...obj }
                    }
                } else {
                    q_s = { ...q_s, ...obj }
                }
            } else {
                q_s = { ...q_s, ...obj }
            }



            this.props.history.push(this.base_url + '?' + queryString.stringify(q_s))
        } catch (e) {
            //console.log('errore cambio url',e);
        }

    }

    updateDrawer(id, DRAWER_ACTION = 'DETAILS'){
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });
        let title = '';
        let open = false;
        let loading = false;
        let values = selectedElement;
        let readOnly = true;
        let onSubmit = undefined;
        let concludiProcedimento = false;

        switch(DRAWER_ACTION){
            case 'INSERT':
                title = 'Inserisci ' + this.state.page.title.toLowerCase();
                open = true;
                loading = false;
                values = selectedElement;
                readOnly = false;
                onSubmit = this.submitProvvedimentoAdd;
                break;
            case 'DETAILS':
                title = 'Dettagli ' + this.state.page.title.toLowerCase();
                open = true;
                loading = false;
                values = selectedElement;
                readOnly = true;
                break;
            case 'ACTIVATE':
                title = 'Attiva ' + this.state.page.title.toLowerCase();
                open = true;
                loading = false;
                values = selectedElement;
                readOnly = false;
                onSubmit = this.submitProvvedimentoActivate;
                break;
            case 'CONTRODEDUZIONI':
                title = 'Controdeduzioni ' + this.state.page.title.toLowerCase();
                open = true;
                loading = false;
                values = selectedElement;
                readOnly = false;
                onSubmit = this.submitProvvedimentoControdeduzioni;
                break;
            case 'CONCLUDI_PROCEDIMENTO':
                title = 'Conclusione del procedimento';
                open = true;
                loading = false;
                values = selectedElement;
                readOnly = selectedElement.stato == 6;
                onSubmit = this.submitConclusioneProcedimento
                concludiProcedimento = true;
                break;                
        }

        if(values.id){
            if(this.props.UserStore.user.permissions.dirigentePermissions || this.props.UserStore.user.permissions.istruttorePermissions){
                if(values.stato !== 0){
                    /* Originale SISFO
                    EnteServices.getMediasAttivazione({ provvedimentoType: this.props.provvedimentoType }, values.id_ente, values.id, (res) => {
                        let prevMedias = this.state.drawer.values !== undefined ? this.state.drawer.values.medias : [];
                        if(typeof prevMedias == 'undefined'){
                            prevMedias = [];
                        }
                        prevMedias.push(res.data.data);
                        this.setState(prevState => ({
                            drawer: {
                                ...prevState.drawer,
                                values:{
                                    ...prevState.drawer.values,
                                    medias: prevMedias
                                }
                            },
                        }));
                    }, (err) => {
                        console.error("EnteServices.getMediasAttivazione", err)
                    });
                    */
                }
            }
            if(this.props.UserStore.user.permissions.entePermissions){
                if(values.stato === 5 || values.stato === 6 || values.stato === 7){
                    /* Originale SISFO
                    EnteServices.getMediasControdeduzione({ provvedimentoType: this.props.provvedimentoType }, values.id_ente, values.id, (res) => {
                        let prevMedias = this.state.drawer.values.medias;
                        if(typeof prevMedias == 'undefined'){
                            prevMedias = [];
                        }
                        prevMedias.push(res.data.data);
                        this.setState(prevState => ({
                            drawer: {
                                ...prevState.drawer,
                                values:{
                                    ...prevState.drawer.values,
                                    medias: prevMedias
                                }
                            },
                        }));
                    }, (err) => {
                        console.error("EnteServices.getMediasControdeduzione", err)
                    });
                    */
                }
            }
        }else{
            values.medias = [];
        }

        this.setState({
            drawer: {
                title: title,
                open: open,
                loading: loading,
                id: id,
                values: values,
                readOnly: readOnly,
                onSubmit: onSubmit,
                concludiProcedimento: concludiProcedimento,
            }
        });
        
        
    }

    submitProvvedimentoAdd(id, data){
        data.medias = this.state.drawer.values.medias;
        data.provvedimentoType = this.props.provvedimentoType;
        EnteServices.provvedimentoAdd(data, this.props.match.params.id, (res) => {
            notification.success({message: 'Procedimento creato con successo'});
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    refreshed_at: Date.now()
                },
                drawer: {open: false}
            }));
            window.scrollTo({top: 0, behavior: 'smooth' });
        }, (err) => {
            console.warn("Called Function err submitProvvedimentoAdd", err)
            notification.error({ message: 'Errore', description: 'Errore in fase di salvataggio del Procedimento' });
        });
    }

    submitProvvedimentoActivate(id, data){
        data.medias = this.state.drawer.values.medias;
        data.provvedimentoType = this.props.provvedimentoType;
        EnteServices.provvedimentoActivate(data, this.props.match.params.id, id, (res) => {
            notification.success({message: 'Procedimento attivato con successo'});
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    refreshed_at: Date.now()
                },
                drawer: {open: false}
            }));
            window.scrollTo({top: 0, behavior: 'smooth' });
        }, (err) => {
            console.warn("Called Function err submitProvvedimentoActivate", err)
            notification.error({ message: 'Errore', description: 'Errore in fase di attivazione del Procedimento' });
        });
    }

    submitProvvedimentoControdeduzioni(id, data){

        data.medias = this.state.drawer.values.medias;
        data.provvedimentoType = this.props.provvedimentoType;
        
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });
        const values = selectedElement;

        EnteServices.provvedimentoControdeduzioni(data, this.props.match.params.id, id, (res) => {
            notification.success({message: 'Controdeduzione inviata con successo'});
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    refreshed_at: Date.now()
                },
                drawer: {open: false}
            }));
            window.scrollTo({top: 0, behavior: 'smooth' });
        }, (err) => {
            console.warn("Called Function err", err)
            notification.error({ message: 'Errore', description: 'Errore in fase di salvataggio' });
        });
    }

    submitProvvedimentoFinalState(id, action){
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });
        const values = selectedElement;

        EnteServices.provvedimentoStatoFinale({provvedimentoType: this.props.provvedimentoType, action: action.toLowerCase()}, values.id_ente.id, values.id, (res) => {
            notification.success({message: 'Procedimento annullato con successo'});
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    refreshed_at: Date.now()
                }
            }));
            window.scrollTo({top: 0, behavior: 'smooth' });
        }, (err) => {
            console.error("EnteServices.provvedimentoStatoFinale", err)
        });
    }

    submitConclusioneProcedimento(id, data){
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });
        const values = selectedElement;

        EnteServices.provvedimentoStatoFinale({...data, provvedimentoType: this.props.provvedimentoType, action: 'confirm'}, values.id_ente.id, values.id, (res) => {
            notification.success({message: 'Procedimento concluso con successo'});
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    refreshed_at: Date.now()
                },
                drawer: {open: false}
            }));
            window.scrollTo({top: 0, behavior: 'smooth' });
        }, (err) => {
            console.error("EnteServices.provvedimentoStatoFinale", err)
        });
    }    
    

    downloadDocumentoControdeduzioni(id){
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });
        const values = selectedElement;

        //Scarichiamo il file della controdeduzione
        EnteServices.getMediasControdeduzioneTemplate({provvedimentoType: this.props.provvedimentoType}, values.id_ente, values.id, (res) => {
            const blob = new Blob([res.data], { type: 'application/vnd.oasis.opendocument.text' });
            const today = new Date();
            const month = today.getMonth() + 1;
            const year = today.getFullYear();
            const date = today.getDate();
            const hour = ('0' + today.getHours()).slice(-2);
            const minu = ('0' + today.getMinutes()).slice(-2);
            let nome_file = `controdeduzioni_provvedimenti_${date}${month}${year}_${hour}${minu}.odt`;
            FileSaver.saveAs(blob, nome_file);
        }, (err) => {
            console.error("EnteServices.getMediasControdeduzioneTemplate", err)
        });
    }

    downloadDocumentoControdeduzioniEnte(id){
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });
        const values = selectedElement;

        const {id_media_controdeduzioni} = values
        //Scarichiamo il file della controdeduzione
        EnteServices.getMediasControdeduzione({provvedimentoType: this.props.provvedimentoType}, values.id_ente, values.id, (res) => {
            const blob = new Blob([res.data], { type: id_media_controdeduzioni.mime_type });
            var fileURL = window.URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
            //FileSaver.saveAs(blob, id_media_controdeduzioni.nome_file);
        }, (err) => {
            console.error("EnteServices.getMediasControdeduzione", err)
        });
    }

    renderAddButtons(){
        if(this.props.UserStore.user.permissions.istruttorePermissions || this.props.UserStore.user.permissions.dirigentePermissions){
            if(this.state.loading === false){
                return <Row type="flex" justify="start" className="m10h">
                            <div className="m5h m5w">
                                <Button 
                                loading={this.state.loading}
                                className="m5h bg-green"
                                icon="plus"
                                onClick={() => this.updateDrawer(null, 'INSERT')}
                                disabled={this.state.disableNuovaComunicazione}
                                >
                                    {this.state.page.btnAddValue}
                                </Button>
                            </div>
                        </Row>
            }
        }else{
            return null;
        }
    }

    renderDrawerForm(){
        /*
        if(this.state.drawer.values !== undefined){
            this.state.drawer.values.medias = this.state.drawer.values.id_media_attivazione !== undefined ? [this.state.drawer.values.id_media_attivazione] : [];
        }
        */
        return <Drawer
                    title={this.state.drawer.title}
                    width={720}
                    placement="right"
                    onClose={()=>this.setState({drawer: {open: false}})}
                    maskClosable={true}
                    visible={this.state.drawer.open}
                    style={{
                    overflow: 'auto',
                    }}
                >
                    {this.state.drawer.concludiProcedimento
                    ?
                    <ConclusioneProvvedimentoForm
                        loading={this.state.drawer.loading}
                        submit={data => {
                            if(typeof this.state.drawer.onSubmit == 'undefined'){
                                return false;
                            }
                            this.state.drawer.onSubmit(this.state.drawer.id, data);
                        }} 
                        values={this.state.drawer.values}
                        medias={this.state.drawer.values && this.state.drawer.values.medias || []}
                        readOnly={this.state.drawer.readOnly}
                        provvedimentoType={this.props.provvedimentoType}
                        onUpload={(media) => {
                            this.setState(prevState => ({
                                drawer: {
                                    ...prevState.drawer,
                                    values: {
                                        ...prevState.drawer.values,
                                        medias: [media]
                                    }
                                }
                            }))
                        }}
                    />
                    :                    
                    <ProvvedimentoForm
                        loading={this.state.drawer.loading}
                        submit={data => {
                            if(typeof this.state.drawer.onSubmit == 'undefined'){
                                return false;
                            }
                            this.state.drawer.onSubmit(this.state.drawer.id, data);
                        }} 
                        values={this.state.drawer.values}
                        medias={this.state.drawer.values && this.state.drawer.values.medias || []}
                        readOnly={this.state.drawer.readOnly}
                        provvedimentoType={this.props.provvedimentoType}
                        onUpload={(media) => {
                            this.setState(prevState => ({
                                drawer: {
                                    ...prevState.drawer,
                                    values: {
                                        ...prevState.drawer.values,
                                        medias: [media]
                                    }
                                }
                            }))
                        }}
                        />
                    }
                </Drawer>
    }

    render() {
        return (
            <div>
                <h3 className="m10h">{this.state.page.title}</h3>
                <div style={{ padding: 14 }}>
                    <div>
                        <React.Fragment>
                            {this.renderAddButtons()}
                        </React.Fragment>
                        <Card
                            className="shadow no-pad"
                            bordered={false}>
                            <TableComponent
                                data={this.state.data}
                                model={model(this)}
                                table_props={{ rowKey: 'id' }}
                                size="medium"
                                ref="table_component"
                                loading={this.state.loading}
                                with_server_pagination={true}
                                updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                                updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
                            />
                        </Card>
                        {this.renderDrawerForm()}
                    </div>
                </div>
            </div>
            
        )
    }
}
ListProvvedimenti.propTypes = {
    //provvedimentoType: PropTypes.oneOf(['COMUNICAZIONE_SOSPENSIONE', 'PROVVEDIMENTO_SOSPENSIONE','COMUNICAZIONE_REVOCA','PROVVEDIMENTO_REVOCA']),
    provvedimentoType: PropTypes.oneOf(['PROCEDIMENTO']),
}

ListProvvedimenti.defaultProps = {
    provvedimentoType: null,
}

export default ListProvvedimenti;