import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'antd';
import checkPermission from '@common/utils/check/permissions';
import SingleNominaKo from './SingleNominaKo';


class IspezioneNomineKo extends Component {

  state = {
    loaded: false,
    loading: false
  }

  render() {
    return (this.props.nomine && this.props.nomine.length > 0 && checkPermission.hasPermission(this.props.UserStore.user,['dirigentePermissions'])) ?
      <Row>
        <Col span={24}>
          <h4>Nomine rifiutate</h4>
        </Col>
        {
          this.props.nomine.map(n => {
            return <Col span={8}>
              <SingleNominaKo
                accreditamento={this.props.accreditamento}
                ispezione={this.props.ispezione}
                refreshInline={(data) => this.props.refreshInline(data)} nomina={n} />
            </Col>
          })
        }
      </Row>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(IspezioneNomineKo);