import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
//import CheckPermissions from '@common/decorators/CheckPermissions';

import ModelServices from '../services/QualificaProfessionale';
import QualificaProfessionaleForm from '../forms/QualificaProfessionaleForm';

import SpinnerLoading from '@components/SpinnerLoading';

@Wrapper({ route: 'DettagliQualificaProfessionale', hasSidebar: true, hasHeader: true, hasFooter: true, public:true })
//@CheckPermissions(['viewProcedura', 'viewOpenedOrClosedProcedura'])
class DettaglioStoricoQualificaProfessionaleRoute extends Component {


  state = {
    loading: false,
    data: null,
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
      this.setState({ loading: true });
      ModelServices.storicoById({}, this.props.match.params.id, this.props.match.params.idStorico).then(
        res => {
          let json = JSON.parse(res.data.data.json);
          this.setState(
            {
              data: res.data.data,
              jsonStorico: json,
              loading: false
            }
          );
        },
      ).catch(() => {
        this.setState({ loading: false });
      });
  }

  renderTitle() {
    return this.state.data?.qualifica?.denominazione_figura || '#Titolo non valido#';
  }


  render() {

    return (
      <div className="scheda">
        {this.state.loading ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{ this.renderTitle() }</h3>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/repertorio/qualifiche">Qualifiche Professionali</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={"/repertorio/qualifiche/"+this.props.match.params.id}>{this.renderTitle()}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Storico</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {this.state.jsonStorico && <QualificaProfessionaleForm {...this.props} readOnly={true} repertorio={this.state.jsonStorico} />}

          </>
        }
      </div>
    );
  }
}

export default DettaglioStoricoQualificaProfessionaleRoute;
