import React, { Component } from "react";

import { Button, notification, Spin } from "antd";

import DatiIdentificativiServices from "@accreditamento/services/DatiIdentificativi";

import DatiRappresentanteLegale from "@accreditamento/forms/completeRequest/dati/rappresentante-legale/Dati";
import FileRappresentanteLegale from "@accreditamento/forms/completeRequest/dati/rappresentante-legale/File";
import IndirizzoRappresentanteLegale from "@accreditamento/forms/completeRequest/dati/rappresentante-legale/Indirizzo";

import DatiEnte from "@accreditamento/forms/completeRequest/dati/ente/Dati";
import SedeEnte from "@accreditamento/forms/completeRequest/dati/ente/Sede";
import FileEnte from "@accreditamento/forms/completeRequest/dati/ente/File";

import ValidationScheda from "../audit/ValidationScheda";

import AccreditamentoDati from "@accreditamento/forms/completeRequest/dati/ente/Accreditamento";

//const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

class DatiForm extends Component {
  state = {
    loading: false,
    loading_data: false,
    loaded: true,
    accreditamento: {},
  };

  constructor(props) {
    super(props);

    this.dati_rappr_legale = null;
    this.indirizzo_rappr_legale = null;
    this.dati_ente = null;
    this.sede_ente = null;
    this.file_ente = null;
    this.dati_ente_accreditamento = null;

    this.dati_rappr_legale_container = null;
    this.indirizzo_rappr_legale_container = null;
    this.dati_ente_container = null;
    this.sede_ente_container = null;
    this.file_ente_container = null;
    this.dati_ente_accreditamento_container = null;
  }

  async componentDidMount() {
    if (this.props.current_key === "1") {
      this.loadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.current_key !== this.props.current_key &&
      newProps.current_key === "1"
    ) {
      this.loadData(newProps);
    }
  }

  async loadData(props) {
    await this.setState({ loading_data: true });
    DatiIdentificativiServices.list(
      props.accreditamento.id,
      async (res) => {
        await this.setState({
          loaded: true,
          loading_data: false,
          accreditamento: res.data.data,
        });
      },
      (err) => this.setState({ loading_data: false })
    );
  }

  async submitDatiRequest() {
    if (!this.state.loading) {
      await this.setState({ loading: true });

      let valid_rappr = await this.dati_rappr_legale.validate();
      //console.log('rappr')
      if (!valid_rappr) {
        window.scrollTo({
          top:
            this.dati_rappr_legale_container.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            50,
          behavior: "smooth",
        });

        await this.setState({ loading: false });
        return;
      }

      let indirizzo_rappr_legale = await this.indirizzo_rappr_legale.validate();
      //console.log('ind')
      if (!indirizzo_rappr_legale) {
        window.scrollTo({
          top:
            this.indirizzo_rappr_legale_container.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            50,
          behavior: "smooth",
        });

        await this.setState({ loading: false });
        return;
      }

      let dati_ente = await this.dati_ente.validate();
      //console.log('dati')
      if (!dati_ente) {
        window.scrollTo({
          top:
            this.dati_ente_container.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            50,
          behavior: "smooth",
        });

        await this.setState({ loading: false });
        return;
      }

      let dati_ente_accreditamento =
        await this.dati_ente_accreditamento.validate();
      //console.log('acc')
      if (!dati_ente_accreditamento) {
        window.scrollTo({
          top:
            this.dati_ente_accreditamento_container.getBoundingClientRect()
              .top -
            document.body.getBoundingClientRect().top -
            50,
          behavior: "smooth",
        });

        await this.setState({ loading: false });
        return;
      }

      let valid_sede = await this.sede_ente.validate();
      //console.log('sede')
      if (!valid_sede) {
        window.scrollTo({
          top:
            this.sede_ente_container.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            50,
          behavior: "smooth",
        });

        await this.setState({ loading: false });
        return;
      }

      let data = {
        RappresentanteLegale: this.dati_rappr_legale.getFormData(),
        RappresentanteLegaleRecapiti: this.dati_rappr_legale.getFormData(),
        Residenza: this.indirizzo_rappr_legale.getFormData(),
        Ente: {
          ...this.dati_ente.getFormData(),
          //...this.file_ente.getFormData(),
          ...this.dati_ente_accreditamento.getFormData(),
        },
        EnteRecapiti: this.dati_ente.getFormData(),
        SedeLegale: this.sede_ente.getFormData(),
      };

      console.error(data, "data")

      DatiIdentificativiServices.save(
        this.props.accreditamento.id,
        data,
        (res) => {

          if(this.props.UserStore && this.props.UserStore.user && this.props.UserStore.user.ente){
            this.props.UserStore.user.ente.denominazione = data.Ente.denominazione;
          }
          
          if (this.props.refreshInline) this.props.refreshInline(res.data.data);

          notification.success({ message: "Dati aggiornati con successo" });
          window.scrollTo({ top: 0, behavior: "smooth" });

          this.setState({ loading: false });
          if (this.props.reloadProgress) this.props.reloadProgress();
        },
        (err) => this.setState({ loading: false })
      );
      await this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div style={{ opacity: this.state.loading_data ? 0.6 : 1 }}>
        {this.state.loading_data ? <Spin /> : null}
        <ValidationScheda
          section="dati_identificativi"
          refreshInline={this.props.refreshInline}
          accreditamento={this.props.accreditamento}
          progress={this.props.progress}
        />
        {this.state.accreditamento.id ? (
          <React.Fragment>
            <div ref={(ref) => (this.dati_rappr_legale_container = ref)}>
              <DatiRappresentanteLegale
                {...this.props}
                accreditamento={this.state.accreditamento}
                ref={(ref) => (this.dati_rappr_legale = ref)}
              />
            </div> 
            <div ref={(ref) => (this.indirizzo_rappr_legale_container = ref)}>
              <IndirizzoRappresentanteLegale
                {...this.props}
                accreditamento={this.state.accreditamento}
                ref={(ref) => (this.indirizzo_rappr_legale = ref)}
              />
            </div>
            <div ref={(ref) => (this.dati_ente_container = ref)}>
              <DatiEnte
                {...this.props}
                accreditamento={this.state.accreditamento}
                ref={(ref) => (this.dati_ente = ref)}
                onChangeLucro={(data) => {
                  this.props.refreshInline(data);
                }}
              />
            </div>
            <div ref={(ref) => (this.sede_ente_container = ref)}>
              <SedeEnte
                {...this.props}
                accreditamento={this.state.accreditamento}
                ref={(ref) => (this.sede_ente = ref)}
              />
            </div> {/*
            <div ref={(ref) => (this.file_ente_container = ref)}>
              <FileEnte
                {...this.props}
                accreditamento={this.state.accreditamento}
                ref={(ref) => (this.file_ente = ref)}
              />
              </div>*/}
            <div ref={(ref) => (this.dati_ente_accreditamento_container = ref)}>
              <AccreditamentoDati
                {...this.props}
                accreditamento={this.state.accreditamento}
                isFineLucro={this.props.isFineLucro}
                ref={(ref) => (this.dati_ente_accreditamento = ref)}
              />
            </div>
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                className="bg-green"
                loading={this.state.loading}
                icon="save"
                disabled={this.props.readOnly}
                onClick={() => this.submitDatiRequest()}
              >
                Salva dati identificativi del soggetto
              </Button>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default DatiForm;
