import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Popconfirm } from 'antd';

class EsitoIspezione extends Component {

    render() {
        return <div style={{ marginTop: 16 }}>
            {
                this.props.canEvaluate &&
                <React.Fragment>
                    <Popconfirm placement="top" title="Confermi?"
                        onConfirm={() => {
                            this.props.acceptIspezione()
                        }}
                        okText="Si" cancelText="No">
                        <Button
                            loading={this.props.accepting}
                            disabled={this.props.accepting}
                            className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>Nessuna irregolarità</Button>
                    </Popconfirm>
                    <Popconfirm placement="top" title="Vuoi sospendere temporaneamente i corsi per presenza di irregolarità?"
                        onConfirm={() => {
                            this.props.suspendIspezione()
                        }}
                        okText="Si" cancelText="No">
                        <Button
                            loading={this.props.suspending}
                            disabled={this.props.suspending}
                            className="m5h" type="danger" ghost icon="close" style={{ marginRight: 8 }}>Sospendi i corsi</Button>
                    </Popconfirm>
                    <Popconfirm placement="top" title={this.props.isFinanziato ? "Vuoi chiudere i corsi e revocare il finanziamento per presenza di gravi irregolarità?" : "Vuoi chiudere i corsi per presenza di gravi irregolarità?"}
                        onConfirm={() => {
                            this.props.refuseIspezione()
                        }}
                        okText="Si" cancelText="No">
                        <Button
                            loading={this.props.refusing}
                            disabled={this.props.refusing}
                            className="m5h" type="danger" icon="close" style={{ marginRight: 8 }}>Chiudi i corsi</Button>
                    </Popconfirm>
                </React.Fragment>

            }
            {
                this.props.canConfirmSuspend &&
                <React.Fragment>
                    <Popconfirm placement="top" title="Confermi la sospensione del corso?"
                        onConfirm={() => {
                            this.props.confirmSuspendIspezione()
                        }}
                        okText="Si" cancelText="No">
                        <Button
                            loading={this.props.confirming}
                            disabled={this.props.confirming}
                            className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>Conferma sospensione</Button>
                    </Popconfirm>
                </React.Fragment>
            }
            {
                this.props.canConfirmRefuse &&
                <React.Fragment>
                    <Popconfirm placement="top" title="Confermi la revoca del corso?"
                        onConfirm={() => {
                            this.props.confirmRefuseIspezione()
                        }}
                        okText="Si" cancelText="No">
                        <Button
                            loading={this.props.confirming}
                            disabled={this.props.confirming}
                            className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>Conferma revoca</Button>
                    </Popconfirm>
                </React.Fragment>
            }
            {
                this.props.canRestartProject &&
                <React.Fragment>
                    <Popconfirm placement="top" title="Confermi il riavvio del corso?"
                        onConfirm={() => {
                            this.props.restartProject()
                        }}
                        okText="Si" cancelText="No">
                        <Button
                            loading={this.props.confirming}
                            disabled={this.props.confirming}
                            className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>Riavvia il corso</Button>
                    </Popconfirm>
                </React.Fragment>
            }
        </div>

    }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(EsitoIspezione);

