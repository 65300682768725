import React, {Component} from 'react';
import moment from 'moment';
import CommonForm from '@common/components/form';

class ConvocaDiscenti extends Component {

  state = {
    loading: false,
  }


  getFormLayout() {

    let form_rows = [];

    form_rows.push(

      {
        cols: [

          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Luogo',
                type_string: 'text', // field type
                name: 'luogo_appuntamento',
                props: {
                  placeholder: 'inserisci il luogo dell\'appuntamento',
                  disabled: this.state.loading,

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'datetimepicker',
                label: 'Data e ora',
                name: 'data_appuntamento',
                format: 'DD/MM/YYYY HH:mm',
                props: {
                  size: 'normal',
                  style: { width: '100%' },
                  disabled: this.state.loading,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_date'
                  },
                ],

              }
            ]
          },
          {
            span: 8,
            items: [
              {
                name: 'btn_convoca_discenti',
                type: 'submit',
                text: 'Convoca',
                props: {
                  style: { marginTop: 29 },
                },
                btn_type: 'primary'
              },
            ],
          },
        ],
      }

    );

       /* form_rows.push({
            cols: [
              {
                items: [
                  {
                    name: 'btn_convoca_discenti',
                    type: 'submit',
                    text: 'Convoca',
                    //onClick: 'attiva',
                    btn_type: 'primary'
                  },
                ],
              },
            ],
          });*/

    return {
        layout: 'vertical',
        multistep: false,
        rows: form_rows,
        onSubmit: 'convoca',
      }
  }


  submit(data) {
    this.props.convocaDiscenti(data);
  }

  render () {
    return <div>
      {
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading_convoca_discenti || false}
          form_model={this.getFormLayout()}
          convoca={(data) => this.submit(data)}
          min_date={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            if (moment().diff(value, 'days') > 0) cb("La data scelta non può essere precedente alla data odierna");
            else cb();
          }}

        />
      }
    </div>
  }

}
export default ConvocaDiscenti;