import React, { Component } from 'react';
import eventBroker from '@common/utils/services/EventBroker';
import { Drawer } from 'antd';
import ModelCreate from '../forms/CreateByEnte';

export default class CreateModel extends Component {

  state = {
    loading: false,
    open_drawer: false,
    soggetto: {
      id_anagrafica: null,
      ruolo: null,
    }
  }

  constructor() {
    super();
    eventBroker.on('openCreateByEnteDrawer', ({ id_anagrafica, ruolo }) => this.setState({ soggetto: { id_anagrafica, ruolo } }, this.handleOpenDrawer));
  }

  handleOpenDrawer() {
    this.setState({ open_drawer: true });
  }

  async submit() {
    this.setState({ open_drawer: false });
    this.props.oncreate();
  }

  render() {
    return (
      <div>
        <Drawer
          // title="Inserisci un nuovo record"
          title="Crea una nuova utenza per il soggetto"
          width={720}
          placement="right"
          onClose={() => this.setState({ open_drawer: false })}
          maskClosable={true}
          visible={this.state.open_drawer}
          style={{
            overflow: 'auto',
          }}
        >
          {this.state.open_drawer &&
            <ModelCreate
              soggetto={this.state.soggetto}
              onCreate={() => this.submit()}
              {...this.props}
            />
          }

        </Drawer>
      </div>
    );
  }
}
