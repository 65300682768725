import React, { Component } from 'react';
import { Card, Icon, Tabs } from "antd";
import { Sticky, StickyContainer } from 'react-sticky';
import SpinnerLoading from "@common/components/SpinnerLoading";
import DatiUtenteForm from '../forms/DatiUtenteForm';
import ruoloStatic from '@common/utils/static/ruolo';

const TabPane = Tabs.TabPane;

export default class SchedaRichiestaRuolo extends Component {

  state = {
    current_key: "1",
  }

  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  isValidSection(name) {
    if (this.props.progress_obj && this.props.progress_obj.valid_sections && this.props.progress_obj.valid_sections[name]) {
      return this.props.progress_obj.valid_sections[name].valid ? 'checked' : 'invalid';
    } else if (!this.props.progress_obj || !this.props.progress_obj.valid_sections || !this.props.progress_obj.valid_sections[name]) {
      return 'default';
    } 
  }

  isEditable() {
    return this.props.richiesta.stato === ruoloStatic.stato.predisposizione
  }

  render() {

    return (<div>{this.state.loading ? <SpinnerLoading message='Caricamento dati...' /> :
      <Card
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={k => this.setState({ current_key: "" + k })}
            defaultActiveKey="1" size="medium" renderTabBar={this.renderTabBar}>
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('generalData')}>DATI<br />IDENTIFICATIVI<br /><Icon type="check-circle" /></span>
              } key="1">
              <div id="generalData">
                <div style={{ padding: 14 }}>
                  <DatiUtenteForm
                    {...this.props}
                    tab={this.state.current_key}
                    richiesta={this.props.richiesta}
                    readOnly={!this.isEditable()}
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Card>
    }</div>);
  }
}

