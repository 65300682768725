import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Popconfirm, message, Modal } from 'antd';
import CommonForm from '@common/components/form';
import moment from 'moment';

const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Controdeduzioni',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};

class SceltaControdeduzioni extends Component {

  state = {
    loaded: false,
    loading: false,
    feedback: 0, // 0=si,1=no
  }

  render() {
    return <div style={{ marginBottom: 16 }}>
      <strong>Vuoi inviare delle controdeduzioni?</strong>
      <Button
        size="small"
        ghost
        loading={this.props.loading} disabled={this.props.loading}
        className="m5h" type="primary"
        style={{ marginLeft: 8 }}
        onClick={() => this.setState({ open_modal_note: true })}
      >Si</Button>
      <Popconfirm
        placement="leftTop" title="Sicuro di non voler inviare delle controdeduzioni?" onConfirm={() => {
          this.props.addControdeduzione(0, null);
        }} okText="Si" cancelText="No">
        <Button
          size="small"
          loading={this.props.loading} disabled={this.props.loading}
          className="m5h" type="primary"
          style={{ marginLeft: 4 }}
        >No</Button>
      </Popconfirm>
      <Modal
        title="Inserisci note"
        visible={this.state.open_modal_note}
        confirmLoading={this.props.loading}
        onOk={() => {
          let _note = this.formSelComIsp.props.form.getFieldsValue()['note'];
          if (!_note || _note === '') {
            message.error('Inserisci delle note');
            return;
          }
          this.props.addControdeduzione(1, _note);
          this.setState({ open_modal_note: false })
        }}

        onCancel={() => this.setState({ open_modal_note: false })}
      >
        <CommonForm
          wrappedComponentRef={inst => (this.formSelComIsp = inst)}
          loading={this.state.loading || false}
          form_model={formSelLayout}
          values={{

          }}
        />
      </Modal>

      {this.props.data_termine &&
        <div>
        {'Scadenza controdeduzioni: ' + moment(this.props.data_termine, 'YYYY-MM-DD').add(this.props.GlobalStore.stubs.days_controdeduzioni, 'd').format('DD/MM/YYYY')}
        </div>
      }
    </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SceltaControdeduzioni);
/**
 * Nasce quando terminano le compilazioni dei verbali, hai 10 giorni da data_trasmissione verbale
 */