import React, { Component } from 'react';

import { Button, Card, Popconfirm, Row, Tooltip, message } from 'antd';
import TableComponent from '@common/components/table';

import ModelServices from '../services/QualificaProfessionale';
import queryString from 'query-string';
import checkPermissions from '@common/utils/check/permissions';
import repertorioStatic from '@common/utils/static/repertorio'

import _ from '@common/utils/formatters/_';
import FilterForm from '@common/components/filterForm';
import FileSaver from 'file-saver';

import AmbitoServices from '@modules/tabelle/services/Ambiti';
import LivelloComplessitaServices from '@modules/tabelle/services/LivelliComplessita';

const model = (component) => {
  return {
    with_server_pagination: true, // metti paginazione lato server
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'action',
        render: element => <div style={{ width: 90 }}>
          {
            !component.props.nuova_richiesta &&
            <Tooltip placement="top" title={checkPermissions.hasPermission(component.props.UserStore.user, ['editQualificaProfessionale'])? "Modifica" : "Dettaglio"} >
              <Button className="m2w" size="small" shape="circle" 
              icon={checkPermissions.hasPermission(component.props.UserStore.user, ['editQualificaProfessionale'])? "edit" : "eye"}
              onClick={() => component.props.history.push("/repertorio/qualifiche/" + element.id)} />
            </Tooltip>
          }
          {
            !component.props.nuova_richiesta && checkPermissions.hasPermission(component.props.UserStore.user, ['editQualificaProfessionale']) &&
            <Tooltip placement="top" title="Disattiva">
              <Popconfirm placement="leftTop" title="Sicuro di voler disattivare questo elemento" onConfirm={() => component.changeStatus(element.id, 0)} okText="Si" cancelText="No">
                <Button className="m2w" size="small" shape="circle" icon="delete" />
              </Popconfirm>
            </Tooltip>
          }

           {
            component.props.nuova_richiesta &&
            <Tooltip placement="top" title="Seleziona figura professionale">
              <Popconfirm placement="leftTop" title="Confermi di voler selezionare questa figura professionale?" onConfirm={() => component.props.selezionaFigura(element, 0)} okText="Si" cancelText="No">
                <Button className="m5h" type="primary" icon="save" size="small">Seleziona</Button>
              </Popconfirm>
            </Tooltip>
          }
        </div>,
      },
      {
        title: 'Settore',
        dataIndex: '',
        sorter: true,
        key: 'settore.nome_settore',
        render: element => <div style={{ minWidth: 100 }}>{element.settore?.nome_settore}</div>,
      },
      {
        title: 'Denominazioe',
        dataIndex: '',
        sorter: true,
        key: 'denominazione_figura',
        render: element => <div>{element.denominazione_figura}</div>,
      },
      {
        title: 'Descrizione',
        dataIndex: '',
        sorter: true,
        key: 'descrizione',
        render: element => <div style={{ minWidth: 100 }}>{element.descrizione}</div>,
      },
      {
        title: 'Valido dal',
        dataIndex: '',
        sorter: true,
        key: 'valido_dal',
        render: element => <div >{_.formatDate(element.valido_dal, 'DD/MM/YYYY', 'YYYY-MM-DD')}</div>,
      },
      {
        title: 'Valido al',
        dataIndex: '',
        sorter: true,
        key: 'valido_al',
        render: element => <div >{_.formatDate(element.valido_al, 'DD/MM/YYYY', 'YYYY-MM-DD')}</div>,
      },
    ]
  }
}

const filterFormModel = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Settore di riferimento',
                name: 'id_settore',
                options_props: 'settori',
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'string',
                label: 'Denominazione',
                name: 'denominazione_figura',
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'string',
                label: 'Descrizione',
                name: 'descrizione',
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Ambito di attività',
                type_string: 'text',
                name: 'id_ambito',
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                },
                options_props: 'ambiti'
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Livello di complessità',
                type_string: 'text',
                name: 'id_liv_compl',
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'livelliComplessita'
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  };
}

export default class TabellaQualificheProfessionali extends Component {
  base_url = this.props.base_url
  default_order = 'denominazione_figura'

  state = {
    loading: false,
    open_drawer: false,
    editing_id: null,
    data: {
      total: 0,
      current: 1,
      data: []
    },
    ambiti: [],
    livelliComplessita: []
  }

  componentDidMount() {
    AmbitoServices.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'ambito_' + el.id,
            label: el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ ambiti: rows })
    })

    LivelloComplessitaServices.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'livelloComplessita_' + el.id,
            label: el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ livelliComplessita: rows })
    })

    this.callAndUpdate(this.props)
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  callAndUpdate(props) {
    let { params, pager } = _.getFilterParam(props, this.default_order);
    this.setState({ loading: true });
    ModelServices.list(params, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, () => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.callAndUpdate(this.props.location.search)
    }
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.callAndUpdate(this.props)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  updateRecord(id) {
    this.setState({ editing_id: id, open_drawer: true });
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  deleteRecord(id) {
    this.setState({ loading: true });
    ModelServices.delete(id, () => this.refresh(), () => this.setState({ loading: false }));
  }

  /**
   * Attiva / disattiva la qualifica
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  changeStatus(id, status) {
    this.setState({ loading: true });
    ModelServices.changeStatus(id, status, () => 
    message.success("Operazione effettuata con successo"),
    this.refresh(), () => this.setState({ loading: false }));
  }

  exportRepertorio() {
    this.setState({ exporting: true });
    let params = queryString.parse(this.props.location.search);

    ModelServices.exportRepertorio(params, (res) => {
      const _blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(_blob, 'repertorio_qualifiche_professionali.ods');
      this.setState({ exporting: false });
    }, () => this.setState({ exporting: false }));
  }


  /**
   * Record aggiornato in form
   * @return {[type]} [description]
   */
  updatedRecord() {
    this.setState({ open_drawer: false, editing_id: false });
    this.callAndUpdate(this.props);
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.props.location.search}
          history={this.props.history}
          form_elements={{
            settori: [{
              key: null,
              label: "Tutti",
              value: null,
            }, ...this.props.GlobalStore.stubs.settori],
            ambiti: this.state.ambiti,
            livelliComplessita: this.state.livelliComplessita
          }}
        />
        <Card
          className="shadow no-pad"
          bordered={false}
        >
          <Row type="flex" justify="start" className="m10h">
            { !this.props.nuova_richiesta && (checkPermissions.hasPermission(this.props.UserStore.user, ['addQualificaProfessionale'])) && <div className="m5h m5w">
              <Button className="m5h bg-green" icon="plus" onClick={() => this.props.history.push("/repertorio/qualifiche/nuova")}>Aggiungi una qualifica</Button>
            </div>}

            { !this.props.nuova_richiesta && (checkPermissions.hasPermission(this.props.UserStore.user, ['exportQualificaProfessionale'])) && <div className="m5h m5w">
              <Button className="m5h bg-green" icon="plus"
                loading={this.state.exporting}
                disabled={this.state.exporting}
                onClick={() => this.exportRepertorio()}>Esporta repertorio</Button>
            </div>}

            <div className="m5h m5w">
              <Button className="m5h bg-green" icon="global"
                onClick={() => {window.open(repertorioStatic.linkToAtlanteRepertorio, '_blank');}}>Vai al repertorio Nazionale</Button>
            </div>
            
          </Row>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort, order: order })}
          />
        </Card>
      </div>
    );
  }
}