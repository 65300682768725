import React, {Component} from 'react';
//import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { Card } from 'antd';
import TableComponent from '@common/components/table';

import ModelServices from '../services/Notification';

import moment from 'moment';

import _ from '@common/utils/formatters/_';


const renderAction = (ref, component) => {
  switch (ref.type) {
    case 'accreditamento':
      return <Link to={"/accreditamento/scheda/" + ref.id}>Vedi</Link>
    case 'nomina':
      return <Link to={'/accreditamento/' + ref.id + '/nomina/' + ref.id_nomina}>Vedi</Link>
    case 'progetto':
      return <Link to={'/affidamento/progetto/' + ref.id}>Vedi</Link>
    case 'nomina_progetto':
      return <Link to={'/affidamento/' + ref.id + '/nomina/' + ref.id_nomina}>Vedi</Link>
    case 'registro_discente':
      return <Link to={"/affidamento/registro/" + ref.id + "/discente/" + ref.id_discente}>Vedi</Link>
    case 'registro':
      return <Link to={"/affidamento/registro/" + ref.id}>Vedi</Link>
    case 'richiesta':
      return <Link to={"/repertorio/richieste/" + ref.id}>Vedi</Link>
    case 'ruolo':
      return <Link to={"/ruoli/richieste/" + ref.id}>Vedi</Link>
    case 'valutatore':
      return <Link to={"/valutatore/" + ref.id}>Vedi</Link>
    case 'ispettore':
      return <Link to={"/ispettore/" + ref.id}>Vedi</Link>
    default:
      return null;
  }
};

const model = (component) => {
  return {
    with_server_pagination: true,
    columns:[
      {
        title: 'Descrizione',
        dataIndex: '',
        render: element => `${element.message}`,
      },
      {
        title: 'Azioni',
        dataIndex: '',
        render: element => <div>{renderAction(element.ref,component)}</div>,
      },
      {
        title: 'Ultimo aggiornamento',
        dataIndex: '',
        sorter: true,
        key: 'updated_at',
        render: element => `${moment(element.updated_at,'X').format('DD/MM/YYYY HH:mm')}`,
      }
    ]
  };
}

class ListModel extends Component {
  base_url = '/notifiche'
  default_order = '-updated_at'

  state = {
    loading: false,
    open_drawer: false,
    editing_id: null,
    data: {
      total: 0,
      current: 1,
      data: []
    }
  }

  componentDidMount () {
    this.props.dispatch({
      type: 'CLEAR_USER_NOTIFICATIONS',
    });
    this.callAndUpdate(this.props)
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  callAndUpdate(props) {
    let { params, pager } = _.getFilterParam(props, this.default_order);

    if(this.props.UserStore.user.ente && this.props.UserStore.user.ente.id){
      params.id_ente = this.props.UserStore.user.ente.id;
    }
    this.setState({loading: true});
    ModelServices.list ( params, (res) => {
      let data = _.getResponse(res);
      let to_update = data.data.filter(e => e.read === 0).map(e => e.id);

      // imposta le notifiche lette
      if(to_update.length > 0) {
        let obj = this.props.UserStore.user.ente ? {ids: to_update.join(","), id_ente: this.props.UserStore.user.ente.id} : {ids: to_update.join(",")};
        ModelServices.setRead( obj, () => null);
      }

      this.setState({
        loading: false,
        data: data
      }, ()=>this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, () => {
      this.setState({loading: false});
      //console.log('errore dati', e)
    })
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  UNSAFE_componentWillReceiveProps(newProps) {


    if(newProps.location.search !== this.props.location.search) {
      this.callAndUpdate(newProps.location.search);
    }

  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh(){
    this.callAndUpdate(this.props)
  }

  getBaseUrl() {
    return this.base_url;
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {

    _.formatSortChange(this.props, this.getBaseUrl(), obj);

  }

  /**
   * Record aggiornato in form
   * @return {[type]} [description]
   */
  updatedRecord() {
    this.setState({open_drawer: false, editing_id: false});
    this.callAndUpdate(this.props);
  }

  render () {
    return (
      <div>
        <Card
        className="shadow no-pad"
        bordered={false}
        >
          <TableComponent
          data={this.state.data} model={model(this)}
          size="medium"
          ref="table_component"
          loading={this.state.loading}
          with_server_pagination={true}
          updatePage={(newPage) => this.updateQueryStr({page: newPage})}
          updateSort={(sort, order) => this.updateQueryStr({sort: sort})}
          />
        </Card>
      </div>
    );
  }
}
export default ListModel;
