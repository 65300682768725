import BaseRequest from "../_baseRequest";


export default {

  list: (get_vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      get_vars,
      {},
      null,
      "get",
      'locations/comuni',
      cb,
      ecb || null,
      null,
      true
    );
  },

  all: (get_vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      get_vars,
      {},
      null,
      "get",
      'locations/comuni/all',
      cb,
      ecb || null,
      null,
      true
    );
  },

  view: ( id, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			'get',
			'locations/comuni/' + id,
			cb,
			ecb || null,
			null
		);
	},

}
