import React, { Component } from 'react';

import { Button, Card, Tooltip, Drawer, Row } from 'antd';
import TableComponent from '@common/components/table';

import ModelServices from '../services/User';
import RbacServices from '../services/Rbac';
import eventBroker from '@common/utils/services/EventBroker';

import checkPermission from '@common/utils/check/permissions';

import FilterForm from '@common/components/filterForm';
import _ from '@common/utils/formatters/_';
import ModelCreate from '../forms/Create';

const renderActions = ({ id_anagrafica, ruolo }, component) => {
  return <div style={{ display: 'inline-block' }}>

    {checkPermission.hasPermission(component.props.UserStore.user, ['enteAddUser']) &&
      <Tooltip placement="top" title="Crea utente">
        <Button className="m2w" size="small" shape="circle" icon="user-add" onClick={() => eventBroker.trigger('openCreateByEnteDrawer', { id_anagrafica, ruolo })} />
      </Tooltip>
    }
  </div>
}

/*const model = (component) => {
  return {
    with_server_pagination: true, // metti paginazione lato server
    columns: null
  }
}*/


export default class ListSoggetti extends Component {

  default_order = 'cognome';

  state = {
    loading: false,
    open_drawer: false,
    editing_id: null,
    roles: [],
    data: {
      total: 0,
      current: 1,
      data: []
    }
  }

  componentDidMount() {
    RbacServices.list({ type: 1 }, (res) => {
      this.setState({ roles: res.data.data.list });
    }, (err) => { console.error(err) });

    this.callAndUpdate(this.props.location.search)
  }

  getUserUrl(item) {
    return "/soggetto/" + item.id
  }

  getTableModel(component) {

    let cols = []
    cols.push(
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        // id_tipo_funzione è uguale a -1 se è un docente, uguale a 6 se è un tutor
        render: element => (!element.userId && (element.id_tipo_funzione === -1 || element.id_tipo_funzione === 6)) &&
          <div style={{ width: 200 }}>
            {
              renderActions(element, component)
            }
          </div>,
      })

    if (component.props.UserStore.user.entiTutor && component.props.UserStore.user.entiTutor.length > 1) {
      cols.push(
        {
          title: 'Ente',
          dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
          sorter: false,
          key: 'ente_denominazione',
          render: element => <div>{element.ente_denominazione}</div>,
        })
    }



    cols.push(
      {
        title: 'Username',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'username',
        render: element => <div>{element.username}</div>,
      },
      {
        title: 'Nome',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'nome',
        render: element => <div>{element.nome}</div>,
      },
      {
        title: 'Cognome',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'cognome',
        render: element => <div>{element.cognome}</div>,
      },
      {
        title: 'Ruolo',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'ruolo',
        render: element => <div>{element.ruolo ? element.ruolo : '-'}</div>

      },
      {
        title: 'Azienda di appartenenza',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'azienda_appartenenza',
        render: element => <div>{element.azienda_appartenenza}</div>,
      })

    return {
      with_server_pagination: true, // metti paginazione lato server
      columns: cols
    }

  }

  getBaseUrl() {
    return (this.props.base_url) ? this.props.base_url : "/soggetti";
  }

  setDefaultSearchParams(str) {
    str.fields = 'username, nome, cognome, ruolo';
    return str
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  callAndUpdate(props) {
    let { params, pager } = _.getFilterParam(props, this.default_order);
    params = this.setDefaultSearchParams(params);
    this.setState({ loading: true });
    ModelServices.listSoggettiEnte(params, (res) => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager
      )
      );

    }, () => {
      this.setState({ loading: false });
    })
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.callAndUpdate(this.props.location.search);
    }
  }

  getFilterModel() {
    return {
      layout: 'vertical',
      multistep: false,
      rows: [
        {
          cols: [
            {
              items: [
                {
                  type: 'input',
                  label: 'Username',
                  type_string: 'text', // field type
                  name: 'username',
                }
              ]
            },
            {
              items: [
                {
                  type: 'input',
                  label: 'Nome',
                  type_string: 'text', // field type
                  name: 'nome',
                }
              ]
            },
            {
              items: [
                {
                  type: 'input',
                  label: 'Cognome',
                  type_string: 'text', // field type
                  name: 'cognome',
                }
              ]
            },
          ]
        },
        {
          cols: [
            {
              items: [
                {
                  type: 'input',
                  label: 'Ruolo',
                  type_string: 'text', // field type
                  name: 'ruolo',
                }
              ]
            },
            {
              items: [
                {
                  type: 'input',
                  label: 'Azienda di appartenenza',
                  type_string: 'text', // field type
                  name: 'azienda_appartenenza',
                }
              ]
            }
          ]
        },
        {
          cols: [
            {
              items: [
                {
                  name: 'submit',
                  type: 'submit',
                  text: 'Cerca',
                  btn_type: 'primary'
                }
              ]
            }
          ]
        },
      ]
    }
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.setState({ open_drawer: false });
    this.callAndUpdate(this.props.location.search);
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.getBaseUrl(), obj);
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={this.getFilterModel()}
          base_url={this.getBaseUrl()}
          search={this.props.location.search}
          history={this.props.history}
          form_elements={{
            roles: this.state.roles.map(r => {
              return {
                label: r.name,
                value: r.name
              }
            })
          }}
        />
        <Card
          className="shadow no-pad"
          bordered={false}
        >

          {(checkPermission.hasPermission(this.props.UserStore.user, ['addTutorAziendale'])) &&
            <div>
              <Row type="flex" justify="start" className="m10h">
                <div className="m5h m5w">
                  <Button className="m5h bg-green" icon="plus" onClick={() => this.setState({ open_drawer: true })}>Aggiungi un tutor aziendale</Button>
                </div>
              </Row>
              <Drawer
                title="Inserisci un nuovo tutor aziendale"
                width={720}
                placement="right"
                onClose={() => this.setState({ open_drawer: false })}
                maskClosable={true}
                destroyOnClose={true}
                visible={this.state.open_drawer}
                style={{
                  overflow: 'auto',
                }}
              >
                {this.state.open_drawer &&
                  <ModelCreate
                    role={'TutorAziendale'}
                    onCreate={() => this.refresh()}
                    noPassword={true}
                    showAziendaAppartenenza={true}
                    addByEnte={true}
                    showResidenza={true}
                    showTelefono={true}
                    {...this.props}
                  />
                }

              </Drawer>
            </div>}

          <TableComponent
            data={this.state.data}
            model={this.getTableModel(this)}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

