import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelServices from '@accreditamento/services/Ispezione';

import nominaStatic from '@common/utils/static/nomina';

import checkPermission from '@common/utils/check/permissions';

import SceltaIspettoreSingolo from './SceltaIspettoreSingolo';
import IspezioneNomineOk from './IspezioneNomineOk';
import IspezioneNomineKo from './IspezioneNomineKo';
import IspezioneVerbali from './IspezioneVerbali';
import IspezioneDettagli from './IspezioneDettagli';
import SpinnerLoading from '@common/components/SpinnerLoading';


class Ispezione extends Component {

  state = {
    loaded: false,
    loading: false,
    ispezione: {
      nomine: []
    }
  }

  componentDidMount() {
    if(this.props.accreditamento.need_ispezione === 1) this.loadIspezione(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if((newProps.accreditamento.need_ispezione === 1) && newProps.accreditamento !== this.props.accreditamento) this.loadIspezione(newProps);
  }

  loadIspezione(props) {
    this.setState({ loading: true }, () => {
      ModelServices.view(props.accreditamento.id, (res) => {
        this.setState({ ispezione: res.data.data, loading: false, loaded: true })
      }, this.setState({ loading: false,  loaded: false }))
    });
  }

  canNominateIspettore() {
    //https://cb.schema31.it/cb/issue/407208
    return this.getNomineOk().length < 2 &&
    //return this.getNomineOk().length < 1 &&
    checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions'])
    // https://cb.schema31.it/cb/issue/407208 ... quando un Addetto al controllo accetta in virtù del task citato
    // accetta la nomina, automaticamente this.props.accreditamento.stato_flusso VA A 17 quindi commentiamo il seguente controllo
    //&& this.props.accreditamento.stato_flusso === 9
    ? true : false
  }

  reloadIspezioneInline(data) {
    this.setState({ispezione: data})
  }

  getNomineOk(){
    return this.state.ispezione.nomine ? this.state.ispezione.nomine.filter(n => n.stato !== nominaStatic.stato.rifiutato_confermato).sort((a,b)=>{
      return a.id > b.id ? 1 : -1
    }) : []
  }

  getNomineKo(){
    return this.state.ispezione.nomine ? this.state.ispezione.nomine.filter(n => n.stato === nominaStatic.stato.rifiutato_confermato).sort((a,b)=>{
      return a.id > b.id ? 1 : -1
    }) : []
  }

  render() {
    return this.props.accreditamento.need_ispezione === 1 ?
      !this.state.loaded ? <SpinnerLoading message='Caricamento dati...' /> :
        <div>
          <IspezioneDettagli
            ispezione={this.state.ispezione}
            accreditamento={this.props.accreditamento}
            refreshInline={(data) => this.reloadIspezioneInline(data)}
            refreshAccreditamento={() => this.props.refresh()}
            refresh={() => this.loadIspezione(this.props)}
          />
          {
            this.canNominateIspettore()
              ?
              <SceltaIspettoreSingolo
                accreditamento={this.props.accreditamento}
                ispezione={this.state.ispezione}
                refreshInline={(data) => this.reloadIspezioneInline(data)}
              />
              : null
          }
          {
            this.state.ispezione && this.state.ispezione.id
              ?
              <React.Fragment>
                {
                  this.state.ispezione.verbali && this.state.ispezione.verbali.length > 0
                    ?
                    <IspezioneVerbali
                      ispezione={this.state.ispezione}
                      accreditamento={this.props.accreditamento}
                      refresh={() => this.loadIspezione(this.props)}
                      refreshAll={() => {
                        this.props.refresh();
                        this.loadIspezione(this.props);
                      }}
                      refreshInline={(data) => this.reloadIspezioneInline(data)}
                    />
                    : null
                }
                <div style={{ marginTop: 8 }}>
                  <IspezioneNomineOk
                    nomine={this.getNomineOk()}
                    ispezione={this.state.ispezione}
                    accreditamento={this.props.accreditamento}
                    refreshInline={(data) => this.reloadIspezioneInline(data)}
                  />
                  <IspezioneNomineKo
                    nomine={this.getNomineKo()}
                    ispezione={this.state.ispezione}
                    accreditamento={this.props.accreditamento}
                    refreshInline={(data) => this.reloadIspezioneInline(data)}
                  />
                </div>
              </React.Fragment>
              : null
          }

        </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(Ispezione);