import React, { Component } from 'react';
import { Button, Card, Tooltip, Row, message } from 'antd';

import FilterForm from '@components/filterForm';
import TableComponent from '@components/table';
import accreditamentoStatic from '@common/utils/static/accreditamento';
import ModelServices from '../services/Media';
import checkPermissions from '@common/utils/check/permissions';

import _ from '@common/utils/formatters/_';



const renderActions = (element, component) => {
    return <div style={{ display: 'inline-block' }}>
        <Tooltip placement="top" title="Modifica">
            <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.props.history.push("/allegati/" + element.id)} />
        </Tooltip>

        {/* element.stato < ruoloStatic.stato.acquisita &&
      <Tooltip placement="top" title="Elimina richiesta">
        <Popconfirm disabled={component.state.loading} placement="leftTop" title="Sicuro di voler rimuovere la richiesta?" onConfirm={() => { component.deleteRequest(element.id) }} okText="Si" cancelText="No">
          <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="delete" />
        </Popconfirm>
      </Tooltip>
    */}
    </div>
}


const model = (component) => {

    return {
        with_server_pagination: true,
        columns: [
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'actions',
                //width: "300px",
                render: element => <div style={{ width: 120 }}>
                    {
                        renderActions(element, component)
                    }
                </div>,
            },

            {
                title: 'Titolo',
                dataIndex: 'tipo_media',
                sorter: true,
                key: 'tipo_media',
            },
            {
                title: 'Tipo accreditamento',
                dataIndex: '',
                sorter: false,
                key: 'nome',
                render: element => <div>
                    {
                        element.tipo_accreditamento ? element.tipo_accreditamento.split(",").map(el => accreditamentoStatic.getTipoAccreditamentoLabel(parseInt(el))).join() : ''
                    }


                </div>
            },
            {
                title: 'Settore',
                dataIndex: 'sottosezione',
                sorter: true,
                key: 'sottosezione',
            },
            {
                title: 'Sezione formulario',
                dataIndex: '',
                sorter: true,
                key: 'sezione',
                render: element => <div>
                    {
                        accreditamentoStatic.getSezioneFormularioLabel(element.sezione)
                    }
                </div>
            },
            {
                title: 'Obbligatorio',
                dataIndex: '',
                sorter: true,
                key: 'obbligatorieta',
                render: element => <div>
                    {
                        element.obbligatorieta ? 'SI' : 'NO'
                    }
                </div>,
            },
            {
                title: 'Stato',
                dataIndex: '',
                sorter: true,
                key: 'stato',
                render: element => <div>
                    {
                        element.stato === 1 ? 'Attivo' : element.stato === 2 ? 'Disattivo' : ''
                    }
                </div>,
            },
        ]
    };
}

const filterFormModel = (component) => {

    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Tipo accreditamento',
                                name: 'tipo_accreditamento',
                                options: [
                                    {
                                        value: "-1",
                                        label: "Tutti"
                                    },
                                    {
                                        value: "1",
                                        label: "Provvisorio"
                                    },
                                    {
                                        value: "2",
                                        label: "Definitivo"
                                    },
                                    {
                                        value: "3",
                                        label: "Riconoscimento"
                                    },
                                    {
                                        value: "4",
                                        // https://cb.schema31.it/cb/issue/420905: Ric. Mantenimento -> Rinnovo
                                        // label: "Mantenimento"
                                        label: "Rinnovo"
                                    },
                                ],
                            }
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Sezione formulario',
                                name: 'sezione',
                                options: [
                                    {
                                        value: "-1",
                                        label: "Tutti"
                                    },
                                    {
                                        value: "1",
                                        label: "Dati identificativis del soggetto"
                                    },
                                    {
                                        value: "2",
                                        label: "Risorse infrastrutturali e disponibilità logistiche"
                                    },
                                    {
                                        value: "3",
                                        label: "Capacità gestionali e risorse professionali"
                                    },
                                    {
                                        value: "4",
                                        label: "Affidabilità economica e finanziaria"
                                    },
                                    {
                                        value: "5",
                                        label: "Qualità"
                                    },
                                ],
                            }
                        ]
                    },

                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Settore',
                                type_string: 'text',
                                name: 'sottosezione',
                                onChange: 'changedSettore',
                                props: {
                                    disabled: false,
                                },
                                options: [
                                    {
                                        value: "-1",
                                        label: "Tutti"
                                    },
                                    {
                                        value: "Rappresentante legale",
                                        label: "Rappresentante legale"
                                    },
                                    {
                                        value: "Ente",
                                        label: "Ente"
                                    },
                                    {
                                        value: "Caratteristiche sede",
                                        label: "Caratteristiche sede"
                                    },
                                    {
                                        value: "Attrezzature",
                                        label: "Attrezzature"
                                    },
                                    {
                                        value: "Requisiti aule",
                                        label: "Requisiti aule"
                                    },
                                    {
                                        value: "Capacità gestionali",
                                        label: "Capacità gestionali"
                                    },
                                    {
                                        value: "Affidabilità economica e finanziaria",
                                        label: "Affidabilità economica e finanziaria"
                                    },
                                    {
                                        value: "Qualità",
                                        label: "Qualità"
                                    },
                                ],
                            }
                        ],
                    },
                ]
            },

            {
                cols: [
                    {
                        items: [
                            {
                                name: 'submit',
                                type: 'submit',
                                text: 'Cerca',
                                btn_type: 'primary'
                            }
                        ]
                    }
                ]
            },
        ]
    };
}

class TabellaAllegati extends Component {
    base_url = '/allegati/';
    default_order = 'id';

    state = {
        loading: false,
        params: {},
        data: {
            total: 0,
            current: 1,
            data: []
        },
    }

    componentDidMount() {
        this.refresh();
    }

    /**
     * Se cambia la stringa aggiorna
     * @param  {[type]} newProps [description]
     * @return {[type]}          [description]
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.fetch(this.props.location.search)
        }
    }

    /**
     * Carica dati
     * @param  {[type]} props [description]
     * @return {[type]}       [description]
     */
    fetch(query) {
        let { params, pager } = _.getFilterParam(query, this.default_order);

        if (params.tipo_accreditamento === "-1") {
            delete params.tipo_accreditamento;
        }

        if (params.sezione === "-1") {
            delete params.sezione;
        }

        if (params.sottosezione === "-1") {
            delete params.sottosezione;
        }

        this.setState({ loading: true });
        ModelServices.list(params, res => {
            let data = _.getResponse(res);
            this.setState({
                loading: false,
                data: data
            }, () => this.refs.table_component.updateTableData(
                data.data,
                data.current,
                data.total,
                pager)
            );

        }, (e) => {
            this.setState({ loading: false });
            //console.log('errore dati', e)
        })
    }

    /**
     * Ricarica dopo update
     * @return {[type]} [description]
     */
    refresh() {
        this.fetch(this.props.location.search)
    }

    /**
     * Chiamato da sort in tabella
     * @param  {[type]} obj [description]
     * @return {[type]}     [description]
     */
    updateQueryStr(obj) {
        _.formatSortChange(this.props, this.base_url, obj);
    }


    addNew() {
        this.setState({ loading: true }, () => {
          ModelServices.create(res => {
              message.success("Nuovo allegato creato con successo, procedere con la compilazione dei dati!");
              this.setState({ loading: false })
              this.props.history.push("/allegati/" + res.data.data.id)
            },
            () => this.setState({ loading: false }))
        })
    
      }



    render() {
        return (
            <div>
                <FilterForm
                    formLayout={filterFormModel(this)}
                    base_url={this.base_url}
                    search={this.state.params}
                    history={this.props.history}
                    date_fields={['data_apertura', 'data_chiusura']}
                />
                <Card
                    className="shadow no-pad"
                    bordered={false}>

                    <Row type="flex" justify="start" className="m10h">
                        {!this.props.nuova_richiesta && (checkPermissions.hasPermission(this.props.UserStore.user, ['adminPermissions'])) && <div className="m5h m5w">
                            <Button className="m5h bg-green" icon="plus" onClick={() => this.addNew()}>Aggiungi un allegato</Button>
                        </div>}
                    </Row>

                    <TableComponent
                        data={this.state.data}
                        model={model(this)}
                        table_props={{ rowKey: 'id' }}
                        size="medium"
                        ref="table_component"
                        loading={this.state.loading}
                        with_server_pagination={true}
                        updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                        updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
                    />
                </Card>
            </div>
        );
    }
}

export default TabellaAllegati;
