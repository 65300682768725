import BaseRequest from '@common/services/_baseRequest';

export default {

	list: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'tabelle/settore',
			cb,
			ecb || null,
			null
		);
	},

	view: (params = {}, id = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'tabelle/settore/'+id,
			cb,
			ecb || null,
			null
		);
	},

	update: (params = {}, id = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			params,
			null,
			'put',
			'tabelle/settore/'+id,
			cb,
			ecb || null,
			null
		);
	},

	create: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			params,
			null,
			'post',
			'tabelle/settore',
			cb,
			ecb || null,
			null
		);
	},

	changeStatus: (id = null, status, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{status: status},
			null,
			'post',
			'tabelle/settore/'+id + '/change-status',
			cb,
			ecb || null,
			null
		);
	},

}
