import React, { Component } from 'react';
import { Button, Drawer } from 'antd';
import ModelCreate from '../forms/Create';

export default class CreateModel extends Component {

  state = {
    loading: false,
    open_drawer: false
  }

  submit() {
    this.setState({ open_drawer: false });
    this.props.oncreate();
  }

  render() {
    return (
      <div>
        <Button className="m5h bg-green" icon="plus" onClick={() => this.setState({ open_drawer: true })}>Aggiungi</Button>
        <Drawer
          title="Inserisci un nuovo record"
          width={720}
          placement="right"
          onClose={() => this.setState({ open_drawer: false })}
          maskClosable={true}
          visible={this.state.open_drawer}
          style={{
            overflow: 'auto',
          }}
        >
          {this.state.open_drawer &&
            <ModelCreate
              role={this.props.role || []}
              roles={this.props.roles || null}
              withMatricola={true}
              onCreate={() => this.submit()}
              {...this.props}
            />
          }

        </Drawer>
      </div>
    );
  }
}
