import BaseRequest from "@common/services/_baseRequest";


export default {

	accept: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/esito/'+id+'/accept',
			cb,
			ecb || null,
			null
		);
	},

	suspend: (id, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/esito/'+id+'/suspend',
			cb,
			ecb || null,
			null
		);
	},

	refuse: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/esito/'+id+'/refuse',
			cb,
			ecb || null,
			null
		);
	},

	confirm_accept: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/esito/'+id+'/confirm-accept',
			cb,
			ecb || null,
			null
		);
	},

	confirm_accept_decreto: (id, data = {}, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/esito/'+id+'/dati-decreto',
			cb,
			ecb || null,
			null
		);
	},

	need_ispezione: (id, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/esito/'+id+'/need-ispezione',
			cb,
			ecb || null,
			null
		);
	},

	download_decreto: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/esito/' + id + '/decreto',
			cb,
			ecb || null,
			null
		);
	},

	download_accoglimento_word: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/esito/' + id + '/accoglimento-word',
			cb,
			ecb || null,
			null
		);
	},

	download_decreto_word: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/esito/' + id + '/decreto-word',
			cb,
			ecb || null,
			null
		);
	},

	upload_decreto: (id, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/esito/' + id + '/decreto',
			cb,
			ecb || null,
			null
		);
	},
}
