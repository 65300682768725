import React, { Component } from "react";
import { Button, message, Spin } from "antd";

import QualitaServices from "@accreditamento/services/Qualita";

import accreditamentoStatic from "@common/utils/static/accreditamento";

import IsoCertificatoForm from "@accreditamento/forms/completeRequest/qualita/IsoCertificato";
import CertificatoSalute from "@accreditamento/forms/completeRequest/qualita/CertificatoSalute";
import CertificatoTrattamentoDatiPersonali from "@accreditamento/forms/completeRequest/qualita/CertificatoTrattamentoDatiPersonali";
import ContinuitaForm from "@accreditamento/forms/completeRequest/qualita/Continuita";

import ValidationScheda from "../audit/ValidationScheda";

class Qualita extends Component {
  state = {
    loading: false,
    files: {},
    accreditamento: {},
    continuita: {},
    certificazione: {},
    ente: {
      media: [],
    },
  };

  constructor(props) {
    super(props);

    this.continuita = null;
    this.certificazione = null;
  }

  componentDidMount() {
    if (this.props.current_key === "5") this.loadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.current_key !== this.props.current_key &&
      newProps.current_key === "5"
    )
      this.loadData(newProps);
  }

  loadData(props) {
    this.setState({ loading: true }, () => {
      QualitaServices.list(
        props.accreditamento.id,
        (res) => {
          this.setState({
            accreditamento: res.data.data.accreditamento,
            continuita: res.data.data.continuita,
            certificazione: res.data.data.certificazione,
            ente: res.data.data.ente,
            loading: false,
          });
        },
        () =>
          this.setState({
            loading: false,
          })
      );
    });
  }

  isDefinitivoOrRinnovo() {

    // ATTENZIONE! 22/11/2022 -> gli accreditamenti Definitivi devono comportarsi come precedentemente visto per i provvisori.
    // Quindi questa funzione deve tornare FALSE
    return false;
    return this.state.accreditamento.tipo ===
      accreditamentoStatic.tipo.definitivo ||
      this.state.accreditamento.tipo === accreditamentoStatic.tipo.rinnovo
      ? true
      : false;
  }

  async submitForm() {
    if (!this.state.loading) {
      await this.setState({ loading: true });

      let data = {
        EnteCertificazione: {},
        AccQualitaContinuita: {},
      };

      let valid_certificazione = await this.certificazione.validate();
      console.error("test",this.certificazione);
      if (!this.certificazione.state.has_dati || !this.certificazione.state.has_salute || !this.certificazione.state.has_iso ) {
        await this.setState({ loading: false });
        message.error("Selezionare tutti SI e procedere");
        return;
      }
      if (!valid_certificazione) {
        await this.setState({ loading: false });
        return;
      }
      data.EnteCertificazione = this.certificazione.getFormData();

      if (this.isDefinitivoOrRinnovo()) {
        let valid_continuita = await this.continuita.validate();
        if (!valid_continuita) {
          await this.setState({ loading: false });
          return;
        }

        data.AccQualitaContinuita = this.continuita.getFormData();
      }

      QualitaServices.saveData(
        this.state.accreditamento.id,
        data,
        (res) => {
          this.setState({
            accreditamento: res.data.data.accreditamento,
            continuita: res.data.data.continuita,
            certificazione: res.data.data.certificazione,
            ente: res.data.data.ente,
            loading: false,
          });
          message.success("Dati salvati");

          if (this.props.reloadProgress) this.props.reloadProgress();
        },
        () => this.setState({ loading: false })
      );
    }
  }

  render() {
    return (
      <div style={{ opacity: this.state.loading ? 0.6 : 1 }}>
        {this.state.loading ? <Spin /> : null}
        <ValidationScheda
          section="qualita"
          refreshInline={this.props.refreshInline}
          accreditamento={this.props.accreditamento}
          progress={this.props.progress}
        />
        <IsoCertificatoForm
          ref={(ref) => (this.certificazione = ref)}
          values={this.state.certificazione}
          readOnly={this.props.readOnly}
          ente={this.state.ente}
          accreditamento={this.state.accreditamento}
          isDefinitivoOrRinnovo={this.isDefinitivoOrRinnovo()}
          GlobalStore={this.props.GlobalStore}
        />
        <ContinuitaForm
          ref={(ref) => (this.continuita = ref)}
          values={this.state.continuita}
          readOnly={this.props.readOnly}
          ente={this.state.ente}
          isDefinitivoOrRinnovo={this.isDefinitivoOrRinnovo()}
          GlobalStore={this.props.GlobalStore}
        />
        <Button
          type="primary"
          className="bg-green"
          loading={this.state.loading}
          icon="save"
          disabled={this.props.readOnly}
          onClick={() => this.submitForm()}
        >
          Salva sezione qualità
        </Button>
      </div>
    );
  }
}

export default Qualita;
