import React, { Component } from 'react';
//import { Form, Select, InputNumber, DatePicker, Switch, Slider, Button, message, Row, Col } from 'antd';

/**
 * Bug
 *
 * quando aggiorno un dato delle tabelle al momento di aprirle non si aggiornano con l'id di quella aperta
 */
import {
  HashRouter,
} from 'react-router-dom'

import './App.css';

import AppRoutes from './AppRoutes';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';

import reducers from './reducers';

const middleware = [
  /* ...your middleware (i.e. thunk) */
];

const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
);
const store = createStore(reducers, enhancer);



class App extends Component {

  state = {
    initialized: false
  }

  componentDidMount() {

    store.dispatch({
      type: 'SET_URLS',
      url: this.props
    })
    this.setState({ initialized: true });
  }

  render() {
    return this.state.initialized &&
     <Provider store={store}>
      <HashRouter>
        <AppRoutes />
      </HashRouter>
    </Provider>
  }
}

export { store };
export default App;
