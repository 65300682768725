import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import TabellaRegistroElettronico from '@affidamento/components/registro/TabellaRegistroElettronico';

import '../less/Affidamento.less';

@Wrapper({ route: 'TabellaRegistroElettronico', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewProgetto', 'adminPermissions'])
class TabellaRegistroElettronicoRoute extends Component {

  state = {
    loading: false,
  }

  renderTitle() {
    return 'Registro elettronico'
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          <h3 className="sectionTitle">{this.renderTitle()}</h3>
          <p>Lista dei registri elettronici</p>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Registro Elettronico</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <TabellaRegistroElettronico ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default TabellaRegistroElettronicoRoute;
