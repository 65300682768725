import React, { Component } from 'react';
import { connect } from "react-redux";

import { Button, Popconfirm } from 'antd';

import moment from 'moment';

import comunicazioneOstativaStatic from '@common/utils/static/comunicazioneOstativa';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';
import DownloadUpload from '@common/components/DownloadUpload';

const formSelNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'btn_submit',
              text: 'Salva le note per poter scaricare il template',
              props: {
                className: "bg-green",
                icon: 'save'
              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};

class Controdeduzione extends Component {

  state = {
  }

  getTiming() {
    return moment(this.props.data_trasmissione, 'YYYY-MM-DD').add(this.props.GlobalStore.stubs.days_controdeduzioni, 'd').format('DD/MM/YYYY');
  }

  renderActions() {
    return <div>
      {this.props.canDownloadControdeduzioni ?
        <div>
          <p>Scarica il template delle controdeduzioni e carica il file firmato</p>
          <DownloadUpload
            accept={this.props.ext ? this.props.ext : '.p7m,.pdf'}
            downloading={this.props.loadingControdeduzioni}
            uploading={this.props.uploadingControdeduzioni}
            download={() => this.props.downloadControdeduzioni()}
            upload={(file) => this.props.uploadControdeduzioni(file)}
          >
          </DownloadUpload>

        </div> : null}
      {this.props.canSendControdeduzioni ?
        <div>
          <h4>Conferma</h4>
          <Popconfirm placement="top" title="Confermi la comunicazione?"
            onConfirm={() => {
              this.props.sendControdeduzioni()
            }}
            okText="Si" cancelText="No">
            <Button
              loading={this.props.sendingControdeduzioni}
              disabled={this.props.sendingControdeduzioni}
              className="m5h" type="primary" ghost icon="check">Conferma</Button>
          </Popconfirm>
        </div>
        : null}
    </div>
  }

  render() {
    return (this.props.controdeduzioni && this.props.canView) ?
      <div style={{ marginTop: 16 }}>
        <div>
          <h4>Controdeduzioni</h4>
          <p style={{ marginBottom: 0 }}>Scadenza: <strong>{this.getTiming()}</strong></p>
          <p>Stato: <strong>{comunicazioneOstativaStatic.getStatoControdeduzioneLabel(this.props.controdeduzioni)}</strong></p>
          {this.props.controdeduzioni.note ?
            <p style={{ display: 'inline-block' }}><strong>Controdeduzioni: </strong>{this.props.controdeduzioni.note}</p>
            : this.props.canEditControdeduzioni ?
              <CommonForm
                wrappedComponentRef={inst => (this.formSelComIsp = inst)}
                loading={this.props.editingControdeduzioni || false}
                form_model={formSelNoteLayout}
                values={{

                }}
                submitted={(data) => { this.props.editControdeduzioni(data) }}
              /> : null}
          {
            this.props.media?.map(l => {
              return <div key={l.id}><p style={{ display: 'inline-block' }}>Controdeduzioni firmate: </p><DocSpan key={l.id} file={l} /></div>
            })
          }
          {this.renderActions()}
        </div>
      </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(Controdeduzione);
