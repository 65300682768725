import React, { Component } from 'react';
import { Button, Card, Tooltip, notification, Row, Drawer } from 'antd';
import TableComponent from '@common/components/table';
import moment from 'moment';
import queryString from 'query-string';

import _ from '@common/utils/formatters/_';

import ProvvedimentoForm from '@accreditamento/components/provvedimenti/ProvvedimentoForm';

import EnteServices from '@accreditamento/services/Ente';

import FileSaver from 'file-saver';

const model = (component) => {
    let cols = {
        with_server_pagination: true,
        columns: [
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'actions',
                //width: "300px",
                render: element => <div style={{ width: 140 }}>
                    {
                        renderActions(element, component)
                    }
                </div>,
            },
            {
                title: 'Data creazione',
                dataIndex: 'created_at',
                //sorter: true,
                key: 'created_at',
                render: element => <div style={{ width: 140 }}>
                    {moment.unix(element).format('DD/MM/YYYY HH:mm')}
                </div>
            },
            {
                title: 'Ultimo aggiornamento',
                dataIndex: 'updated_at',
                //sorter: true,
                key: 'updated_at',
                render: element => <div style={{ width: 140 }}>
                    {moment.unix(element).format('DD/MM/YYYY HH:mm')}
                </div>
            },
            {
                title: 'Stato',
                dataIndex: '',
                //sorter: true,
                key: 'stato',
                render: element => <div>
                    {getStato(element, component)}
                </div>
            },
            {
                title: 'Protocollo',
                dataIndex: '',
                //sorter: true,
                key: 'numero_protocollo',
                render: element => <div>
                    {
                        element.numero_protocollo &&
                        <p>
                           {element.numero_protocollo}
                        </p>
                    }
                </div>
            },
            {
                title: 'Responsabile provvedimento',
                dataIndex: '',
                sorter: false,
                key: 'ana_nome',
                render: element => <div style={{ width: 140 }}>
                    {
                        element.id_user &&
                        <p>
                            <strong>Istruttore</strong>
                            <br />
                            {element.id_user.anagrafica.nome} {element.id_user.anagrafica.cognome}
                        </p>
                    }
                </div>
            }
        ]
    }

    return cols;
}

const getStato = (record, component) => {
    let stato_label = '--';
    switch(record.stato){
        case 0: stato_label = record.controdeduzioni_disabilitate ? 'Annullato' : 'Predisposto'; break;
        case 1: stato_label = 'Da firmare'; break;
        case 2: stato_label = 'Firmata'; break;
        case 3: stato_label = 'Attivato'; break;
        case 4: stato_label = 'Chiuso dall\'istruttore'; break;
        case 5: stato_label = 'Inviate controdeduzioni'; break;
        case 6: stato_label = 'Confermato dal dirigente'; break;
        case 7: stato_label = 'Annullato dal dirigente'; break;
    }

    return <div>{stato_label}</div>
}

const renderActions = (element, component) => {
    return (
        <>
        <div style={{ display: 'inline-block' }}>
            <Tooltip placement="top" title="Visualizza dettagli">
                <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.updateDrawer(element.id)} />
            </Tooltip>
            {
                component.props.UserStore.user.permissions.entePermissions &&
                (element.stato === 3) &&
                <>
                {/*<Tooltip placement="top" title="Scarica documento Controdeduzioni">
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="download" onClick={() => component.downloadDocumentoControdeduzioni(element.id, 'CONTRODEDUZIONI')} />
                </Tooltip>*/}
                <Tooltip placement="top" title="Controdeduzioni">
                    <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="check" onClick={() => component.updateDrawer(element.id, 'CONTRODEDUZIONI')} />
                </Tooltip>
                </>
            }
        </div>
        </>
    );
}

class ListProvvedimentiSospensioneEnte extends Component {
    base_url = '/enti/' + this.props.match.params.id + '/accreditamenti';

    state = {
        loading: true,
        data: {
            refreshed_at: -1,
            total: 0,
            current: 1,
            data: []
        },
        drawer: {
            title: 'Aggiungi',
            open: false,
            loading: false,
            id: null,
            values: {},
            readOnly: false,
            onSubmit: undefined
        }
    }

    constructor(props) {
        super(props);
        
        this.submitSuspensionRequest = this.submitSuspensionRequest.bind(this);
        this.submitSuspensionConfirm = this.submitSuspensionConfirm.bind(this);
        this.submitSuspensionControdeduzioni = this.submitSuspensionControdeduzioni.bind(this);
      }

    componentDidMount() {
        this.callAndUpdate(this.props)
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.data.refreshed_at != this.state.data.refreshed_at){
            this.callAndUpdate(this.props);
        }
    }

    callAndUpdate(props) {
        let { params, pager } = _.getFilterParam(props.location.search, this.default_order);

        this.setState({ loading: true });
        EnteServices.listSospensione({}, props.UserStore.user.ente.id, (res) => {
            this.setState(prevState => ({
                loading: false,
                data: {
                    ...prevState.data,
                    data: res.data.data,
                    current: 1,//data.current,
                    total: 0,//data.total,
                    refreshed_at: -1,
                }
            }), () => this.refs.table_component.updateTableData(
                res.data.data,
                1,//data.current,
                0,//data.total,
                pager)
            );
        }, (err) => {
            this.setState({ loading: false });
            console.error("EnteServices.listSospensione", err)
        });
    }

    updateQueryStr(obj) {
        try {
            let q_s = queryString.parse(this.props.location.search);
            // brutto forte
            if (obj.sort) {
                if (q_s.sort && obj.sort) {
                    if (q_s.sort === obj.sort) {
                        if (q_s.sort[0] === "") {
                            q_s.sort = q_s.sort.replace("-", "");
                        } else {
                            q_s.sort = "-" + q_s.sort
                        }
                    } else {
                        q_s = { ...q_s, ...obj }
                    }
                } else {
                    q_s = { ...q_s, ...obj }
                }
            } else {
                q_s = { ...q_s, ...obj }
            }



            this.props.history.push(this.base_url + '?' + queryString.stringify(q_s))
        } catch (e) {
            //console.log('errore cambio url',e);
        }

    }

    updateDrawer(id, DRAWER_ACTION = 'DETAILS'){
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });

        let title = '';
        let open = false;
        let loading = false;
        let values = selectedElement;
        let readOnly = true;
        let onSubmit = undefined;

        switch(DRAWER_ACTION){
            case 'DETAILS':
                title = 'Visualizza dettagli';
                open = true;
                loading = false;
                values = selectedElement;
                readOnly = true;
                onSubmit = this.submitSuspensionRequest;
                break;
            case 'CONFIRM_SUSPEND':
                title = 'Conferma sospensione';
                open = true;
                loading = false;
                values = selectedElement;
                readOnly = false;
                onSubmit = this.submitSuspensionConfirm;
                break;
            case 'CONTRODEDUZIONI':
                title = 'Controdeduzioni';
                open = true;
                loading = false;
                values = selectedElement;
                readOnly = false;
                onSubmit = this.submitSuspensionControdeduzioni;
                break;
        }

        if(values.id){
            /*EnteServices.getMedias({}, values.id_ente, values.id, (res) => {
                values.medias = [res.data.data];
                this.setState({
                    drawer: {
                        title: title,
                        open: open,
                        loading: loading,
                        id: id,
                        values: values,
                        readOnly: readOnly,
                        onSubmit: onSubmit
                    }
                });
            }, (err) => {
                console.error("EnteServices.getMedias", err)
            });*/
            this.setState({
                drawer: {
                    title: title,
                    open: open,
                    loading: loading,
                    id: id,
                    values: values,
                    readOnly: readOnly,
                    onSubmit: onSubmit
                }
            });
        }else{
            values.medias = [];
            //values.enteControdeduzioni = [];
            this.setState({
                drawer: {
                    title: title,
                    open: open,
                    loading: loading,
                    id: id,
                    values: values,
                    readOnly: readOnly,
                    onSubmit: onSubmit
                }
            });
        }
    }

    downloadDocumentoControdeduzioni(id){
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });
        const values = selectedElement;

        //Scarichiamo il file della controdeduzione
        EnteServices.getProvvedimentiEnteControdeduzioniDownload({}, values.id_ente, values.id, (res) => {
            console.warn("values.enteControdeduzioni", res)
            const blob = new Blob([res.data], { type: 'application/vnd.oasis.opendocument.text' });
            const today = new Date();
            const month = today.getMonth() + 1;
            const year = today.getFullYear();
            const date = today.getDate();
            const hour = ('0' + today.getHours()).slice(-2);
            const minu = ('0' + today.getMinutes()).slice(-2);
            let nome_file = `controdeduzioni_motivi_ostativi_${date}${month}${year}_${hour}${minu}.odt`;
            FileSaver.saveAs(blob, nome_file);
        }, (err) => {
            console.error("EnteServices.getMedias", err)
        });
    }

    renderDrawerForm(){
        return <Drawer
                    title={this.state.drawer.title}
                    width={720}
                    placement="right"
                    onClose={()=>this.setState({drawer: {open: false}})}
                    maskClosable={true}
                    visible={this.state.drawer.open}
                    style={{
                    overflow: 'auto',
                    }}
                >
                    <ProvvedimentoForm
                        loading={this.state.drawer.loading}
                        submit={data => {
                            if(typeof this.state.drawer.onSubmit == 'undefined'){
                                return false;
                            }
                            this.state.drawer.onSubmit(this.state.drawer.id, data);
                        }} 
                        values={this.state.drawer.values}
                        medias={this.state.drawer.values && this.state.drawer.values.medias || []}
                        readOnly={this.state.drawer.readOnly}
                        onUpload={(media) => {
                            this.setState(prevState => {
                                let jasper = Object.assign({}, prevState.drawer);  // creating copy of state variable jasper
                                jasper.values.medias = [media];                     // update the name property, assign a new value                 
                                return { jasper };                                 // return new object jasper object
                            })
                        }}
                        />
                </Drawer>
    }

    submitSuspensionRequest(id, data){
        EnteServices.richiestaSospensione(data, this.props.match.params.id, (res) => {
            notification.success({message: 'Richiesta inviata con successo'});
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    refreshed_at: Date.now()
                },
                drawer: {open: false}
            }));
            window.scrollTo({top: 0, behavior: 'smooth' });
        }, (err) => {
            console.warn("Called Function err", err)
            notification.error({ message: 'Errore', description: 'Errore in fase di salvataggio' });
        });
    }

    submitSuspensionConfirm(id, data){
        data.medias = this.state.medias;
        EnteServices.ConfermaSospensione(data, this.props.match.params.id, id, (res) => {
            notification.success({message: 'Conferma inviata con successo'});
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    refreshed_at: Date.now()
                }
            }));
            window.scrollTo({top: 0, behavior: 'smooth' });
        }, (err) => {
            console.warn("Called Function err", err)
            notification.error({ message: 'Errore', description: 'Errore in fase di salvataggio' });
        });
    }

    submitSuspensionControdeduzioni(id, data){
        data.medias = this.state.drawer.values.medias;
        
        let selectedElement = {};
        this.state.data.data.map(el => {
            if(el.id === id){
                selectedElement = el;
            }
        });
        const values = selectedElement;

        EnteServices.ConfermaControdeduzioni(data, values.id_ente, values.id, (res) => {
            notification.success({message: 'Controdeduzione inviata con successo'});
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    refreshed_at: Date.now(),
                    drawer:{
                        ...prevState.data.drawer,
                        open: false
                    }
                }
            }));
            window.scrollTo({top: 0, behavior: 'smooth' });
        }, (err) => {
            console.warn("Called Function err", err)
            notification.error({ message: 'Errore', description: 'Errore in fase di salvataggio' });
        });
    }

    render() {
        return (
            <div>
                <Card
                    className="shadow no-pad"
                    bordered={false}>
                    <TableComponent
                        data={this.state.data}
                        model={model(this)}
                        table_props={{ rowKey: 'id' }}
                        size="medium"
                        ref="table_component"
                        loading={this.state.loading}
                        with_server_pagination={true}
                        updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                        updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
                    />
                </Card>
                {this.renderDrawerForm()}
            </div>
        )
    }
}

export default ListProvvedimentiSospensioneEnte;