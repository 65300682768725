import React, { Component } from 'react';
import { Button, Card, message, Popconfirm, Row, Tooltip } from 'antd';

import moment from 'moment';

import checkPermission from '@common/utils/check/permissions';

import FilterForm from '@components/filterForm';
import TableComponent from '@components/table';

//import ModelServices from '../services/MockData';

import ModelServices from '../services/RichiestaRuolo';
import ruoloStatic from '@common/utils/static/ruolo';

import _ from '@common/utils/formatters/_';

const getStato = (richiesta, component) => {

  if (!richiesta.stato_flusso_description) return '';
  let stato = 'dipendente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  return <div>
    {richiesta.stato_flusso_description['stato_' + stato]}
  </div>

}

const renderActions = (element, component) => {
  return <div style={{ display: 'inline-block' }}>
    <Tooltip placement="top" title="Visualizza richiesta">
      <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/ruoli/richieste/" + element.id)} />
    </Tooltip>

    {element.stato < ruoloStatic.stato.acquisita &&
      <Tooltip placement="top" title="Elimina richiesta">
        <Popconfirm disabled={component.state.loading} placement="leftTop" title="Sicuro di voler rimuovere la richiesta?" onConfirm={() => { component.deleteRequest(element.id) }} okText="Si" cancelText="No">
          <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="delete" />
        </Popconfirm>
      </Tooltip>
    }
  </div>
}

const renderStatusIndicator = (record, component) => {

  let user = 'dipendente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';

  let defined_c = '';
  let description = record.stato_flusso_description['descrizione_' + user];
  let colors = ['green', 'red'];
  colors.forEach(c => {
    if (ruoloStatic.getPallino(user, c, parseInt(record.stato_flusso)) !== -1) defined_c = c;
  })

  return (defined_c !== '') ?
    <Tooltip placement="topRight" title={description}>
      <span className={"pallino " + defined_c}></span>
    </Tooltip>
    : <span className={"pallino invisible"}></span>;
}

const model = (component) => {

  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        render: element => <div style={{ width: 120 }}>
          {
            renderStatusIndicator(element, component)
          }
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Cognome Dipendente',
        dataIndex: '',
        sorter: true,
        key: 'cognome',
        render: element => <div>
          {element.user ? element.user.anagrafica.cognome : ''}
        </div>
      },

      {
        title: 'Nome Dipendente',
        dataIndex: '',
        sorter: true,
        key: 'nome',
        render: element => <div>
          {element.user ? element.user.anagrafica.nome : ''}
        </div>
      },

      {
        title: 'Ruolo',
        dataIndex: '',
        sorter: true,
        key: 'tipo_ruolo',
        render: element => <div>
          {ruoloStatic.getTipoRuolo(element.tipo_ruolo)}
        </div>
      },
      {
        title: 'Stato',
        dataIndex: '',
        sorter: true,
        key: 'stato',
        render: element => <div style={{ width: 140 }}>
          {getStato(element, component)}
        </div>,
      },
      {
        title: 'Responsabile di procedimento',
        dataIndex: '',
        sorter: false,
        key: 'responsabile',
        render: element => <div style={{ width: 140 }}>
          {element.responsabile ? element.responsabile.anagrafica.cognome + ' ' + element.responsabile.anagrafica.nome : ''}
        </div>
      },
      {
        title: 'Data presentazione',
        dataIndex: '',
        sorter: true,
        key: 'data_presentazione',
        render: element => <div style={{ width: 140 }}>
          {element.data_presentazione ? moment(element.data_presentazione, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
        </div>,
      },
      {
        title: 'Data inizio predisposizione',
        dataIndex: '',
        sorter: true,
        key: 'created_at',
        render: element => <div style={{ width: 140 }}>
          {element.created_at ? moment.unix(element.created_at).format('DD/MM/YYYY') : ''}
        </div>,
      },
    ]
  };
}

const filterFormModel = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'string',
                label: 'Cognome Dipendente',
                name: 'cognome',
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'string',
                label: 'Nome Dipendente',
                name: 'nome',
              }
            ]
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Ruolo',
                name: 'tipo_ruolo',
                options: [
                  {
                    value: "-1",
                    label: "Tutti"
                  },
                  {
                    value: "1",
                    label: "Ispettore"
                  },
                  {
                    value: "2",
                    label: "Valutatore"
                  },
                ],
              }
            ]
          },
         
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Stato',
                name: 'stato',
                options: [
                  {
                    value: "-1",
                    label: "Tutti"
                  },
                  {
                    value: "0",
                    label: "Predisposizione"
                  },
                  {
                    value: "1",
                    label: "Compilata"
                  },
                  {
                    value: "2",
                    label: "Inviata"
                  },                
                ],
              }
            ]
          },

        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  };
}

class TabellaRichiesteRuolo extends Component {
  base_url = '/ruoli/richieste/';
  default_order = 'id';

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search)
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);

    if (params.tipo_ruolo === "-1") {
      delete params.tipo_ruolo;
    }

    if (params.stato === "-1") {
      delete params.stato;
    }

    //params.with = [
      //'responsabile'
    //].join(',')

    this.setState({ loading: true });
    ModelServices.list(params, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  addRequest(tipo) {
    this.setState({ loading: true }, () => {
      ModelServices.create({ ruolo: tipo },
        res => {
          message.success("Nuova richiesta creata con successo, procedere con la compilazione dei dati");
          this.setState({ loading: false })
          this.props.history.push("/ruoli/richieste/" + res.data.data.id);
        },
        () => this.setState({ loading: false }))
    })
  }

  deleteRequest(id) {
    this.setState({ loading: true });
    ModelServices.delete(id,
      res => {
        this.setState({
          open_drawer: false,
        }, () => {
          message.success('Richiesta di ruolo rimossa');
          this.fetch();
        })
      }, () => { this.setState({ loading: false }) }); 
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.state.params}
          history={this.props.history}
          date_fields={['data_apertura', 'data_chiusura']}
        />
        <Card
          className="shadow no-pad"
          bordered={false}>

          <Row type="flex" justify="start" className="m10h">
            {checkPermission.hasPermission(this.props.UserStore.user, ['addRichiestaRuolo']) && !this.props.UserStore.user?.role.find(el => el.name === "IspettoreAccreditamento") && 
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addRequest(ruoloStatic.tipo_ruolo.ispettore)} loading={this.state.loading}>Richiedi ruolo Addetto al controllo</Button>
              </div>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['addRichiestaRuolo']) && !this.props.UserStore.user?.role.find(el => el.name === "Valutatore") && 
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addRequest(ruoloStatic.tipo_ruolo.valutatore)} loading={this.state.loading}>Richiedi ruolo valutatore</Button>
              </div>
            }
          </Row>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

export default TabellaRichiesteRuolo;
