import BaseRequest from "@common/services/_baseRequest";


export default {

  start: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'repertorio/audit/' + id + '/start',
      cb,
      ecb || null,
      null
    );
  },

  feedbackSection: (id, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'repertorio/audit/' + id + '/feedback-section',
      cb,
      ecb
    );
  },

  prepareApprove: (id, id_audit, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'repertorio/audit/' + id + '/prepare_approve/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },


  approveControdeduzioni: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'repertorio/audit/' + id + '/approve_controdeduzioni',
      cb,
      ecb || null,
      null
    );
  },

  confirmApprove: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'repertorio/audit/' + id + '/confirm_approve',
      cb,
      ecb || null,
      null
    );
  },

  integration: (id, id_audit, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'repertorio/audit/' + id + '/integration/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  confirmIntegration: (id, id_integration, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'repertorio/audit/' + id + '/confirm-integration/' + id_integration,
      cb,
      ecb || null,
      null
    );
  },

  suspend: (id, id_audit, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'repertorio/audit/' + id + '/suspend/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  prepareRefuse: (id, id_audit, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'repertorio/audit/' + id + '/prepare-refuse/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  confirm_integrazione: (id, id_audit, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'repertorio/audit/' + id + '/confirm-integration-request/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  downloadComunicazione: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'repertorio/audit/' + id + '/download_comunicazione',
      cb,
      ecb || null,
      null
    );
  },

  uploadComunicazione: (id, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'repertorio/audit/' + id + '/upload_comunicazione',
      cb,
      ecb || null,
      null
    );
  },



  prepareReject: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'repertorio/audit/' + id + '/prepare_reject',
      cb,
      ecb || null,
      null
    );
  },

  confirmReject: (id, id_motivazione, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'repertorio/audit/' + id + '/confirm-refuse/' + id_motivazione,
      cb,
      ecb || null,
      null
    );
  },

  downloadDecreto: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'repertorio/audit/' + id + '/decreto_autorizzativo',
      cb,
      ecb || null,
      null
    );
  },

  uploadDecreto: (id, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'repertorio/audit/' + id + '/decreto_autorizzativo',
      cb,
      ecb || null,
      null
    );
  },
}
