import { Card, Progress, message } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CommonForm from '@components/form';

import affidamentoStatic from '@common/utils/static/affidamento';

import iscrizioneService from '@affidamento/services/Iscrizione';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica giustificativo pdf",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

export default class PannelloInfoPresenze extends Component {

  static propTypes = {
    studente: PropTypes.object,
    uploadJustification: PropTypes.func,
  }

  state = {
    loading: false,
  }

  canSendJustification() {
    return this.props.studente.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.attesa_giustificativo;
  }

  needJustification() {
    return this.props.studente.giustificativo && this.props.studente.giustificativo.media?.length === 0 /*this.props.studente.assenze >= 5*/
  }


  uploadJustification(file) {
    if (!this.state.loading) {
      this.setState({
        loading: true
      }, () => {

        iscrizioneService.uploadGiustificativo(this.props.studente.id, { filename: 'file' }, { file: file, name: file.name, chiave: 'file' }, res => {

          this.props.studente.giustificativo = res.data.giustificativo;

          message.success('Giustificativo caricato');
          this.setState({ loading: false });

        }, () => this.setState({ loading: false }))

      });
    }
  }


  render() {
    return (
      <Card
        style={{ height: "100%" }}
        bordered={false}>

        <div style={{ textAlign: 'center' }}>
          <h4 className="p10h">Percentuale presenze al corso</h4>
          <Progress
            width={140}
            type="circle"
            percent={Math.round(this.props.studente.presenze / this.props.studente.lezioniTotali * 100)}
            format={(percent, successPercent) => this.props.studente.presenze + ' / ' + this.props.studente.lezioniTotali}
            status={this.needJustification() ? 'exception' : 'active'}
          />
          {this.needJustification() && this.canSendJustification() &&
            <div>
              <p className="p30h p20w text-justify"><strong>ATTENZIONE:</strong> è richiesto un giustificativo per le assenze.</p>
              <CommonForm
                wrappedComponentRef={inst => (this.formRef = inst)}
                loading={this.state.loading || false}
                form_model={formLayout}
                values={{}}
                fileListProp={() => []}
                customUploadRequest={obj => {
                  //this.props.uploadJustification && this.props.uploadJustification(obj.file);
                  this.uploadJustification(obj.file)
                }}
              />
            </div>
          }
        </div>

      </Card>
    );
  }
}

