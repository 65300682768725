import React, { Component } from 'react';

import { message, Table } from 'antd';

import ModelServices from '@accreditamento/services/Audit';
import AccreditamentoServices from '../../services/Accreditamento';

import CommonForm from '@common/components/form';

import DocSpan from '@common/components/DocSpan';

import moment from 'moment';

const formLayout = (el, component) => {
  let cols = {
    layout: 'vertical',
    multistep: false,
    onSubmit: 'onSubmit',
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'simple_upload',
                name: 'dragger',
                accept: '.p7m,.pdf',
                action: '/', // url upload
                listType: 'text', // text,picture,picture-card
                multiple: false,
                fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
                customRequest: 'customUploadRequest', // sovrascrive upload
                onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
                text: "Seleziona un file",
                props: {
                  style: { display: component.state.medias.filter(m => m.id === el.id).length > 0 ? 'none' : 'block' },
                  showUploadList: false
                }
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'textarea',
                label: 'Descrizione',
                name: 'note',
                props: {

                }
              },
            ],
          },
        ],
      }
    ]
  };


  if (el.media && el.media.original && (el.media.original.valido_dal || el.media.original.valido)) cols.rows.push({
    cols: [
      {
        items: [
          {
            type: 'datepicker',
            label: 'Valido dal',
            name: 'valido_dal',
            format: 'YYYY-MM-DD',
            props: {
              size: 'large',
              style: { width: '100%' },
            },
            validations: [
              {
                type: 'required',
                error_message: 'Campo obbligatorio',
              },
              {
                validator: 'min_date'
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            type: 'datepicker',
            label: 'Valido al',
            name: 'valido_al',
            format: 'YYYY-MM-DD',
            props: {
              size: 'large',
              style: { width: '100%' },
            },
            validations: [
              {
                type: 'required',
                error_message: 'Campo obbligatorio',
              },
              {
                validator: 'max_date'
              },
            ]
          },
        ],
      },
    ]
  });

  cols.rows.push(
    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'submit',
              text: 'Carica il nuovo file',
              props: {
                style: { display: component.state.medias.filter(m => m.id === el.id).length > 0 ? 'block' : 'none' },
                icon: 'save',
                className: 'bg-green'
              }
            }
          ],
        },
      ],
    })

  return cols;
}

/*
const getDate = (dt) => {
  if (moment(dt, 'YYYY-MM-DD').isValid())
    return moment(dt, 'YYYY-MM-DD').format('DD/MM/YYYY')
  return '';
}
*/

const TableModel = (component) => [
  {
    title: "Carica nuovo file",
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ minWidth: 250 }}>
      <strong style={{ color: (element.new_file) ? 'green' : 'red' }}>{element.media && element.media.original ? element.media.original.tipo.tipo_media : ''}</strong><br />
      {
        component.state.medias.filter(m => m.id === element.id).map(m => {
          return <p>{m.file.name}</p>
        })
      }
      <h4 style={{ marginBottom: 8 }}>Sostitisci il file caricato</h4>
      <CommonForm
        onSubmit={(data) => component.confirmUpload(data, element)}
        wrappedComponentRef={inst => (component.formRef[element.id] = inst)}
        loading={component.state.uploading.filter(e => e === element.id).length > 0 ? true : false}
        form_model={formLayout(element, component)}
        values={{
        }}
        fileListProp={() => component.state.medias.filter(m => m.id !== element.id)}
        customUploadRequest={obj => {
          component.uploadFile(obj.file, element);
        }}
        min_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let min = value;
          let max = component.formRef[element.id].props.form.getFieldsValue()['valido_al'];

          if (max && min > max) cb("Data valido dal deve essere minore di valido al");
          else cb()
        }}
        max_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let max = value;

          if (max < moment()) cb("Data 'valido al' deve essere successiva alla data odierna");

          let min = component.formRef[element.id].props.form.getFieldsValue()['valido_dal'];

          if (min && min > max) cb("Data valido dal deve essere minore di valido al");
          else cb()
        }}
      />
    </div>
  },
  {
    title: "Note istruttore",
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ width: 250 }}>{element.note}</div>
  },
  {
    title: "File",
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ width: 250 }}>
      {
        element.media && element.media.original
          ?
          <React.Fragment>
            {element.new_id ?
              <React.Fragment>
                <DocSpan key={element.new_file.id} file={element.new_file} />
                <p style={{ color: 'red' }}>{element.media.original.nome_file}</p>
              </React.Fragment>
              : <DocSpan key={element.media.original.id} file={element.media.original} />
            }
          </React.Fragment>
          : null
      }

    </div>
  }
];

class CaricamentoDocumentiIntegrazione extends Component {

  state = {
    loaded: false,
    documents: [],
    requested: [],
    uploading: [],
    medias: [],
    loading: false
  }

  constructor(props) {
    super(props);

    this.formRef = {}
  }

  componentDidMount() {
    AccreditamentoServices.getDocuments(this.props.accreditamento.id, (res) => {
      this.setState({ documents: res.data.data })
    })
  }

  onSelectRow(e, element) {

    if (e.target.checked) {
      this.setState({
        requested: [...this.state.requested, { id: element.id, note: '' }]
      })
    } else {
      this.setState({
        requested: this.state.requested.filter(l => l.id !== element.id)
      })
    }
  }

  addNote(e, element) {
    this.setState({
      requested: [...this.state.requested.filter(l => l.id !== element.id), { id: element.id, note: e.target.value, media: element }]
    })
  }

  isSelected(element) {
    return this.state.requested.find(e => e.id === element.id) ? true : false
  }

  getValues() {
    return this.state.requested
  }

  async uploadFile(file, element) {
    await this.setState({
      medias: [...this.state.medias.filter(e => e.id !== element.id), { id: element.id, file: { file: file, name: file.name } }]
    });

    return;

  }

  confirmUpload(form_data, element) {
    let file = this.state.medias.find(m => m.id === element.id);
    if (!file) {
      message.error("Carica il file");
      return;
    }

    let data = {
      id_old: element.media.id
    }

    if (form_data.valido_dal) data.valido_dal = form_data.valido_dal;
    if (form_data.valido_al) data.valido_al = form_data.valido_al;
    if (form_data.note) data.note = form_data.note;

    if (!this.state.uploading.find(e => e === element.id)) {
      this.setState({ uploading: [...this.state.uploading, element.id] })
      ModelServices.uploadIntegration(this.props.accreditamento.id, this.props.integrazione.id, data, { ...file.file, chiave: 'file' }, (res) => {
        this.setState({
          uploading: this.state.uploading.filter(e => e !== element.id)
        });
        if (this.props.refreshInline) {
          this.props.refreshInline(res.data.data);
        }
      }, () => this.setState({ uploading: this.state.uploading.filter(e => e !== element.id) }))
    }

  }

  render() {
    return <div>
      <Table
        rowKey={(record) => record.id}
        columns={TableModel(this)}
        dataSource={this.props.documents}
        pagination={false}
      />
    </div>
  }
}

export default CaricamentoDocumentiIntegrazione
