import React, { Component } from 'react';
import { connect } from "react-redux";

import SingleComunicazione from './SingleComunicazioneOstativa';

class ComunicazioneOstativaList extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },
    audit: {},
    open_modal_audit_refuse: false,
    open_modal_audit_integration: false
  }

  componentDidMount() {
    if(this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    if(newProps.accreditamento.id && (newProps.accreditamento !== this.props.accreditamento)) this.loaded(newProps)
  }

  loaded( props ) {
    this.setState({accreditamento: props.accreditamento, loaded: true});
  }


  renderCom() {
    return this.props.accreditamento.comunicazioneOstativa ?
      <SingleComunicazione
        refresh={()=>this.props.refresh()}
        refreshInline={(data)=>this.props.refreshInline(data)}
        accreditamento={this.state.accreditamento}
        comunicazione={this.props.accreditamento.comunicazioneOstativa} />
    : null
  }

  render() {
    return (this.state.loaded) ?
      <div style={{marginTop: 16}}>
        {this.renderCom()}
      </div>
    : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ComunicazioneOstativaList);
