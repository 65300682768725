import { Breadcrumb, Button, Card, Col, Row, message, Modal, Progress } from "antd";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SpinnerLoading from "@common/components/SpinnerLoading";
import CheckPermissions from '@common/decorators/CheckPermissions';
import eventBroker from '@common/utils/services/EventBroker';
import Wrapper from '@common/decorators/Wrapper';
import checkPermission from '@common/utils/check/permissions';
import LearnerCourseRegistration from "../components/LearnerCourseRegistration";
import RegistrationServices from '@affidamento/services/Iscrizione';
import AnagraficaService from '@anagrafica/services/Anagrafica';
import affidamentoStatic from '@common/utils/static/affidamento';
import ProgettoService from '@modules/affidamento/services/Progetto';
import CommonForm from '@common/components/form';
import moment from 'moment';

const formNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },

      ],
    }
  ],
  onSubmit: 'submitted',
};

@Wrapper({ route: 'DettagliDomandaPartecipazione', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewProgetto', 'viewOwnProgetto', 'manageRegistration'])
class DettagliDomandaPartecipazioneRoute extends Component {

  state = {
    loading: true,
    compile_loading: false,
    progetto: null,
    registration_info: {},
    totalHours: 0,
    uploading: false
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.loadData(this.props);
    }
  }

  loadData(props) {
    this.setState({ loading: true });
    Promise.all([
      ProgettoService.detail({}, props.match.params.id),
      RegistrationServices.detail({}, props.match.params.id_iscrizione),
    ]).then(
      res => {
        let [r1, r2] = res;
        this.setState(
          {
            progetto: r1.data.data,
            registration_info: r2.data.data,
            loading: false
          },
        );
        AnagraficaService.getOreDiCorso(r2.data.data.anagrafica.id, r3 => {
          this.setState(
            {
              totalHours: r3.data.data.totalhours,
            },
          );
        })
      },
    ).catch(() => {
      this.setState({ loading: false });
    });
  }

  compile(data) {
    this.setState({ loading: true, compile_loading: true }, () => {
      RegistrationServices.compileRegistration(
        this.props.match.params.id_iscrizione,
        { data },
        res => {
          message.success('Domanda compilata correttamente');
          this.setState({
            registration_info: res.data.data,
            loading: false,
            compile_loading: false
          })
        },
        () => this.setState({
          loading: false,
          compile_loading: false
        })
      )
    });
  }

  uploadDocument(props, file) {
    if (!this.state.uploading) {
      this.setState({ uploading: true },
        () => {
          RegistrationServices.uploadDocument(
            props.match.params.id_iscrizione,
            {
              filename: 'file'
            },
            {
              file: file,
              chiave: 'file'
            },
            () => {
              message.success('Allegato caricato correttamente');
              eventBroker.trigger('refreshDocumenti');
              this.setState({
                uploading: false
              });
            },
            () => this.setState({
              uploading: false
            })
          );
        }
      );
    }
  }

  removeDocument(props, file) {
    if (!this.state.uploading) {
      this.setState({ uploading: true },
        () => {
          RegistrationServices.removeDocument(
            props.match.params.id_iscrizione,
            file.id,
            () => {
              message.success('Allegato rimosso correttamente');
              this.setState({
                uploading: false
              }, () => eventBroker.trigger('refreshDocumenti'));
            },
            () => this.setState({
              uploading: false
            })
          );
        }
      );
    }
  }



  sendRegistrationRequest(props) {
    this.setState({ loading: true, loading_sendRegistrationRequest: true }, () => {
      RegistrationServices.sendRegistrationRequest(
        props.match.params.id_iscrizione,
        res => {
          message.success('Domanda di partecipazione inviata');
          this.setState({
            registration_info: res.data.data,
            loading: false,
            loading_sendRegistrationRequest: false
          })
        },
        () => this.setState({
          loading: false,
          loading_sendRegistrationRequest: false
        })
      )
    });
  }

  suitableLearner(props) {
    this.setState({ loading: true, loading_suitableLearner: true }, () => {
      RegistrationServices.suitableLearner(
        props.match.params.id_iscrizione,
        res => {
          message.success('Domanda valutata idonea');
          this.setState({
            registration_info: res.data.data,
            loading: false,
            loading_suitableLearner: false,
          })
        },
        () => this.setState({
          loading: false,
          loading_suitableLearner: false,
        })
      )
    });
  }

  notSuitableLearner(props, note) {
    this.setState({ loading: true, loading_notSuitableLearner: true }, () => {
      RegistrationServices.notSuitableLearner(
        props.match.params.id_iscrizione,
        {
          note: note
        },
        res => {
          message.success('Domanda valutata non idonea');
          this.setState({
            registration_info: res.data.data,
            loading: false,
            loading_notSuitableLearner: false,
          })
        },
        () => this.setState({
          loading: false,
          loading_notSuitableLearner: false,
        })
      )
    });
  }

  onHoldLearner(props) {
    this.setState({
      loading: true,
      loading_on_hold_learner: true
    },
      () => {
        RegistrationServices.onHoldLearner(
          props.match.params.id_iscrizione,
          res => {
            message.success('Domanda messa in attesa');
            this.setState({
              registration_info: res.data.data,
              loading: false,
              loading_on_hold_learner: false,
            })
          },
          () => this.setState({
            loading: false,
            loading_on_hold_learner: false,
          })
        )
      });
  }

  acceptRegistration(props) {
    this.setState({ loading: true, loading_acceptRegistration: true }, () => {
      RegistrationServices.acceptRegistration(
        props.match.params.id_iscrizione,
        res => {
          message.success('Invito di partecipazione accettato');
          this.setState({
            registration_info: res.data.data,
            loading: false,
            loading_acceptRegistration: false,
          })
        },
        () => this.setState({
          loading: false,
          loading_acceptRegistration: false,
        })
      )
    });
  }

  rejectRegistration(props, note) {
    this.setState({ loading: true, loading_rejectRegistration: true }, () => {
      RegistrationServices.rejectRegistration(
        props.match.params.id_iscrizione,
        {
          note: note
        },
        res => {
          message.success('Invito di partecipazione rifiutato');
          this.setState({
            registration_info: res.data.data,
            loading: false,
            loading_rejectRegistration: false,
          })
        },
        () => this.setState({
          loading: false,
          loading_rejectRegistration: false,
        })
      )
    });
  }

  externInvitation() {
    this.setState({ loading: true }, () => {
      RegistrationServices.externInvitation(
        this.props.match.params.id_iscrizione,
        res => {
          message.success('Operazione effettuata con successo');
          this.setState({
            registration_info: res.data.data,
            loading: false,
          })
        },
        () => this.setState({
          loading: false,
        })
      )
    });
  }

  externConvocation() {
    this.setState({ loading: true }, () => {
      RegistrationServices.externConvocation(
        this.props.match.params.id_iscrizione,
        res => {
          message.success('Operazione effettuata con successo');
          this.setState({
            registration_info: res.data.data,
            loading: false,
          })
        },
        () => this.setState({
          loading: false,
        })
      )
    });
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']);
  }

  isDiscente() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['manageRegistration']);
  }

  isRegistrationRequestSent() {
    return this.state.registration_info
      &&
      this.state.registration_info.stato === affidamentoStatic.stato_iscrizione.inviata
  }

  isInvitationSent() {
    return this.state.registration_info
      && this.state.registration_info.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.invitato;
  }

  isRegistrationClose() {
    return this.state.progetto
      && (
        this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_in_preparazione
        || this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_pronto_all_avvio
      )
      && this.state.progetto.data_chiusura_candidature;
  }

  isValuationLabel() {
    return this.state.registration_info && !(this.state.registration_info.stato_flusso === null)
  }

  isNotEnoughHours() {
    return this.isEnte()
      && this.isRegistrationClose()
      && this.isRegistrationRequestSent()
      && ((2400 - this.state.totalHours - this.state.progetto.totalHours) <= 0)
  }

  render() {
    let { totalHours } = this.state;
    totalHours = 2400 - totalHours;
    let totalHoursPct = totalHours / 2400 * 100;
    return (
      <div className="scheda">
        {this.state.loading && !this.state.progetto
          ?
          <SpinnerLoading />
          :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">Domande di partecipazione</h3>
              <p>
                Compila la domanda di partecipazione del corso {this.state.progetto?.titolo_progetto}
              </p>
            </Card>

            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/dashboard">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/affidamento/progetti">Progetti formativi</Link>
                </Breadcrumb.Item>
                {
                  this.isEnte() &&
                  <Breadcrumb.Item>
                    <Link to={"/affidamento/progetto/" + this.state.progetto?.id + "/domande"}>Domande</Link>
                  </Breadcrumb.Item>
                }
                <Breadcrumb.Item>
                  Domanda di partecipazione
            </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
              <Col style={{ flex: "1 1 70%" }} >
                <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >

                  <Card style={{ flex: "1 1 auto", marginBottom: 12 }}
                    bordered={false}>
                    {this.state.progetto ? <h4><span style={{ color: '#000' }}>PROGETTO:</span> {this.state.progetto.titolo_progetto}</h4> : ''}
                  </Card>

                  <Card style={{ flex: "1 1 auto" }}
                    bordered={false}
                    className="shadow">
                    <Row>
                      <Col lg={24} sm={24} xs={24}>
                        <div id="actions">
                          <h4 className="p10h">Stato</h4>
                          {
                            <p>Stato Domanda: <strong>{this.state.registration_info ? affidamentoStatic.getStatoIscrizioneLabel(this.state.registration_info.stato, this) : ''}</strong></p>
                          }
                          {
                            this.isValuationLabel()
                            &&
                            <p>Stato valutazione: <strong>{this.state.registration_info ? affidamentoStatic.getStatoFlussoIscrizioneLabel(this.state.registration_info.stato_flusso, this) : ''}</strong></p>
                          }
                          {
                            this.isNotEnoughHours()
                            &&
                            <p style={{ color: 'red' }}>ATTENZIONE: se questo utente dovesse iscriversi al corso, il suo monte ore residuo diventerebbe negativo</p>
                          }
                          {
                            checkPermission.hasPermission(this.props.UserStore.user, ['manageRegistration'])
                            && this.isInvitationSent()
                            &&
                            <p>Data scadenza accettazione invito: <strong>{this.state.registration_info.scadenza ? moment(this.state.registration_info.scadenza, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'non inserita'}</strong></p>
                          }
                          {checkPermission.hasPermission(this.props.UserStore.user, ['manageRegistration'])
                            && this.state.registration_info?.stato === affidamentoStatic.stato_iscrizione.compilata
                            &&
                            <Button icon="mail" className="m5h m5w" type="primary"
                              disabled={this.state.loading}
                              loading={this.state.loading_sendRegistrationRequest}
                              onClick={() => this.setState({ send_registration_request: true })}>
                              Inoltra domanda di partecipazione
                              </Button>
                          }
                          {checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto'])
                            &&
                            this.isRegistrationClose()
                            &&
                            (this.isRegistrationRequestSent() || this.state.registration_info?.stato === affidamentoStatic.stato_iscrizione.in_attesa)
                            &&
                            <Button icon="check" className="m5h m5w" type="primary"
                              disabled={this.state.loading}
                              loading={this.state.loading_suitableLearner}
                              onClick={() => this.suitableLearner(this.props)}>
                              Idoneo
                              </Button>
                          }
                          {checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto'])
                            && this.isRegistrationClose() && this.isRegistrationRequestSent()
                            &&
                            <Button icon="close" className="m5h m5w" type="primary"
                              disabled={this.state.loading}
                              loading={this.state.loading_notSuitableLearner}
                              onClick={() => this.setState({ modal_note: true })}>
                              Non Idoneo
                              </Button>
                          }
                          {checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto'])
                            && this.isRegistrationClose() && this.isRegistrationRequestSent()
                            &&
                            <Button icon="bars" className="m5h m5w" type="primary"
                              disabled={this.state.loading}
                              loading={this.state.loading_on_hold_learner}
                              onClick={() => this.onHoldLearner(this.props)}>
                              Discente in attesa
                              </Button>
                          }
                          {checkPermission.hasPermission(this.props.UserStore.user, ['manageRegistration'])
                            && this.isInvitationSent()
                            &&
                            <Button icon="check" className="m5h m5w" type="primary"
                              disabled={this.state.loading}
                              loading={this.state.loading_acceptRegistration}
                              onClick={() => this.acceptRegistration(this.props)}>
                              Accetta
                              </Button>
                          }
                          {checkPermission.hasPermission(this.props.UserStore.user, ['manageRegistration'])
                            && this.isInvitationSent()
                            &&
                            <Button icon="close" className="m5h m5w" type="primary"
                              disabled={this.state.loading}
                              loading={this.state.loading_rejectRegistration}
                              onClick={() => this.setState({ modal_note: true })}>
                              Rifiuta
                              </Button>
                          }
                          {checkPermission.hasPermission(this.props.UserStore.user, ['enteManageRegistration'])
                            && this.state.registration_info?.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.sospeso
                            &&
                            <Button icon="check" className="m5h m5w" type="primary"
                              disabled={this.state.loading}
                              loading={this.state.loading}
                              onClick={() => this.externInvitation()}>
                              Invito inviato esternamente
                              </Button>
                          }
                          {checkPermission.hasPermission(this.props.UserStore.user, ['enteManageRegistration'])
                            && this.state.registration_info?.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.convocazione_sospesa
                            && <Button icon="check" className="m5h m5w" type="primary"
                              disabled={this.state.loading}
                              loading={this.state.loading}
                              onClick={() => this.externConvocation()}>
                              Convocazione esterna
                              </Button>
                          }
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              </Col>

              <Col style={{ flex: "1 1 30%" }} >
                <Card
                  style={{ height: "100%" }}
                  bordered={false}>
                  <React.Fragment>
                    <div style={{ textAlign: 'center' }}>
                      <h4 className="p10h">Monte ore</h4>
                      <Progress
                        width={140}
                        type="circle"
                        percent={Math.round(totalHoursPct)}
                        format={(percent, successPercent) => <>{totalHours}</>}
                        status={'active'}
                      />
                    </div>
                  </React.Fragment>
                </Card>
              </Col>
            </Row>

            <Card style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <div>
                <LearnerCourseRegistration
                  {...this.props}
                  formulario={'progetto'}
                  id_ente={this.state.progetto?.id_ente}
                  id_progetto={this.state.progetto?.id}
                  registration_id={this.props.match.params.id_iscrizione}
                  progetto={this.state.progetto}
                  stato_flusso={this.state.progetto?.stato_flusso}
                  isEditable={false}
                  registration_info={this.state.registration_info}
                  domanda_json={this.state.progetto?.scheda_progetto_json}
                  anteprima={true}
                  saved={true}
                  compile={(data) => this.compile(data)}
                  compile_loading = {this.state.compile_loading}
                  uploadDocument={(data) => this.uploadDocument(this.props, data)}
                  removeDocument={(data) => this.removeDocument(this.props, data)}
                  uploading={this.state.uploading}
                  is_discente={this.isDiscente()}
                />
              </div>
            </Card>
            <Modal
              title="Inoltro domanda di partecipazione al corso"
              visible={this.state.send_registration_request}
              onOk={
                () => {
                  this.sendRegistrationRequest(
                    this.props
                  );
                  this.setState(
                    {
                      send_registration_request: false
                    });
                }}
              onCancel={
                () => this.setState(
                  {
                    send_registration_request: false
                  })}
            >
              <p>Conferma inoltro domanda di partecipazione, proseguendo non sarà più possibile modificarne i dati</p>
            </Modal>
            <Modal
              title="Inserisci note"
              confirmLoading={this.props.loadingFeedback}
              visible={this.state.modal_note}
              onOk={
                async () => {
                  if (!this.formNoteRef.props.form.getFieldsValue()['note']
                    || this.formNoteRef.props.form.getFieldsValue()['note'] === '') {
                    message.error('Inserisci le note');
                    return;
                  }
                  if (this.isEnte()) {
                    this.notSuitableLearner(
                      this.props,
                      this.formNoteRef.props.form.getFieldsValue()['note']
                    );
                  }
                  else if (this.isDiscente()) {
                    this.rejectRegistration(
                      this.props,
                      this.formNoteRef.props.form.getFieldsValue()['note']
                    );
                  }
                  this.setState(
                    {
                      modal_note: false
                    });
                }}
              onCancel={
                () => this.setState(
                  {
                    modal_note: false
                  })}
            >
              <CommonForm
                wrappedComponentRef={
                  inst => (
                    this.formNoteRef = inst
                  )}
                loading={
                  this.props.loadingFeedback || false}
                form_model={formNoteLayout}
                values={
                  this.props.feedback ?
                    { valid: this.props.feedback.valid, note: this.props.feedback.note } : { valid: true, note: '' }
                }
              />
            </Modal>
          </>
        }
      </div>
    );
  }
}

export default DettagliDomandaPartecipazioneRoute;