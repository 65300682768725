import React, { Component } from "react";
import CommonForm from '@common/components/form';

import { Table, Input, Radio } from 'antd';

import format from '@common/utils/formatters/_';

const getTipoAula = (component, record) => {
  try {
    let type = format.replaceStub(record.id_tab_tipo_aula, 'tipo_aula', component.props.GlobalStore)

    return type;
  } catch(e) {
    console.error(e)
    return 'errore'
  }
}

const getAule = (component, value) => {
  return <p>
    {
      value.map(el => {
        return <span key={"aula_"+el.id} style={{display: 'inline-block'}}><strong>{el.nome_aula}</strong> - { getTipoAula(component, el) } </span>
      })
    }
  </p>
}

const columns = (component) => {
  return [{
        title: 'Conforme',
        dataIndex: '',
        render: (text, record) => (<div>
          {/*<Checkbox
          checked={component.validAttrezzatura(record)}
          disabled={component.props.readOnly}
          onChange={(e)=>component.check(e, record)}></Checkbox>*/}
          <Radio.Group
            disabled={component.props.readOnly}
            onChange={(e)=>component.checkAttrezzatura(e, record)}
            value={component.validAttrezzatura(record)}>
            <Radio.Button value={1}>Ok</Radio.Button>
            <Radio.Button value={0}>Ko</Radio.Button>
          </Radio.Group>
          {!component.validAttrezzatura(record) ?
            <Input.TextArea
            style={{marginTop: 8}}
              value={component.getNoteAttrezzatura(record)}
              onChange={(e)=>component.changeNoteAttrezzatura(e, record)}
              disabled={component.props.readOnly}
              placeholder="Osservazioni"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
            : null
          }
        </div>),
    }, {
        title: 'Aula',
        dataIndex: 'aula',
        render: (text, record) => (<div style={{width: 120}}>{
          record.aula.nome_aula + " - " + getTipoAula(component, record.aula)
        }
        </div>),
    }, {
        title: 'Tipologia',
        dataIndex: 'tipologia',
        render: (text, record) => (<div style={{width: 120}}>{
          format.replaceStub(record.tipologia, 'tipo_attrezzatura', component.props.GlobalStore )
        }
        </div>),
    }, {
        title: 'Quantità',
        dataIndex: 'quantita',
        //key: 'quantita',
        render: (text, record) => (<div style={{width: 80}}>{record.quantita}</div>)
    }, {
        title: 'Mesi di disponibilità',
        dataIndex: 'mesi_disponibilita',
        //key: 'mesi_disponibilita',
        render: (text, record) => (<div style={{width: 80}}>{record.mesi_disponibilita}</div>)

    }]
}


const audiovisivi_columns = (component) => {
  return [{
        title: 'Conforme',
        dataIndex: '',
        render: (text, record) => (<div>
          {/*<Checkbox
          checked={component.validAttrezzatura(record)}
          disabled={component.props.readOnly}
          onChange={(e)=>component.check(e, record)}></Checkbox>*/}
          <Radio.Group
            disabled={component.props.readOnly}
            onChange={(e)=>component.checkAudiovisivo(e, record)}
            value={component.validAudiovisivo(record)}>
            <Radio.Button value={1}>Ok</Radio.Button>
            <Radio.Button value={0}>Ko</Radio.Button>
          </Radio.Group>
          {!component.validAudiovisivo(record) ?
            <Input.TextArea
            style={{marginTop: 8}}
              value={component.getNoteAudiovisivo(record)}
              onChange={(e)=>component.changeNoteAudiovisivo(e, record)}
              disabled={component.props.readOnly}
              placeholder="Osservazioni"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
            : null
          }
        </div>),
    }, {
        title: 'Ausili',
        dataIndex: 'ausili',
        render: (text, record) => (<div style={{width: 180}}>{record.ausili}</div>),
    }, {
        title: 'Quantità',
        dataIndex: 'quantita',
        //key: 'quantita',
        render: (text, record) => (<div style={{width: 60}}>{record.quantita}</div>)
    }, {
        title: 'Aule',
        dataIndex: 'aule',
        //key: 'mesi_disponibilita',
        render: (text, record) => (<div style={{width: 300}}>{getAule(component, record.aule)}</div>)

    },]
}


/*
const table_columns = (component) => [{
    title: 'Numero',
    dataIndex: 'n_macchina',
    key: 'n_macchina',
    render: (text, record) => (<span>{record.n_macchina}</span>)
  }, {
    title: 'Descrizione beni',
    dataIndex: 'descrizione_beni',
    key: 'descrizione_beni',
    render: (text, record) => (<span>{record.descrizione_beni}</span>)
  }, {
    title: 'Titolo di possesso',
    dataIndex: 'titolo_possesso',
    key: 'titolo_possesso',
    render: (text, record) => (<span>{record.titolo_possesso}</span>)
  }, {
    title: 'Azioni',
    dataIndex: 'azione',
    key: 'azione',
    render: (text, record) => (
      <div>
        <span className="p5w">
          <a onClick={(e) => {
            component.deleteMacchina(record)
          }} disabled={!component.canEdit()}>Cancella</a>
        </span>
      </div>
    ),
  }];
*/

/*
  const formMacchinaLayout = {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 4,
            items: [
              {
                type: 'input',
                label: 'Numero',
                type_string: 'number', // field type
                name: 'n_macchina',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 16,
            items: [
              {
                type: 'input',
                label: 'Descrizione beni',
                type_string: 'text', // field type
                name: 'descrizione_beni',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'input',
                label: 'Titolo di possesso',
                type_string: 'text', // field type
                name: 'titolo_possesso',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          }
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Inserisci macchina',
                props: {
                  className: 'm10h',
                  icon: 'save'
                },
                //onClick: 'showDrawer'
              },
            ],
          },
        ],
      }
    ],
    onSubmit: 'onSubmit',
  };
*/
/*
const formAttiLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Acquisite agli atti n. riportate nella tabella',
              type_string: 'text', // field type
              name: 'verbale_elenco_macchine_numero',
              // props: {
              //   //size: 'large',
              //   prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              // },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ]
    }
  ],
  onSubmit: 'onSubmit',
};
*/

const formExtraLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Sono rispondenti a quelli riportati in perizia e a quelli elencati nella monografia elaborata dall’Ente Gestore e inoltrata all’Assessorato alla Formazione Professionale',
              name: 'verbale_elenco_macchine_corrispondente_domanda',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'verbale_elenco_macchine_corrispondente_domanda_note',
              props: {
              },
              validations: [
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Le attrezzature da utilizzare per l’attività formativa sono funzionali?',
              name: 'attrezzature_formative_funzionali',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'attrezzature_formative_funzionali_note',
              props: {
              },
              validations: [
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'I macchinari e le attrezzature mossi elettronicamente sono dotati dei prescritti accorgimenti antinfortunistici?',
              name: 'attrezzature_elettroche_antinfortunistici',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'attrezzature_elettroche_antinfortunistici_note',
              props: {
              },
              validations: [
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste il certificato di conformità alla L. 46/90 dell’impianto elettrico',
              name: 'certificato_conformita_impianto_elettrico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'certificato_conformita_impianto_elettrico_note',
              props: {
              },
              validations: [
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Il certificato di conformità alla L. 46/90 dell’impianto termico si riferisce alla sede oggetto di verifica?',
              name: 'certificato_conformita_impianto_termico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'certificato_conformita_impianto_termico_note',
              props: {
              },
              validations: [
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Il certificato di accreditamento della sede operativa si riferisce alla sede oggetto di verifica?',
              name: 'certificato_conformita_sede_operativa',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'certificato_conformita_sede_operativa_note',
              props: {
              },
              validations: [
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Il certificato di prevenzione incendi si riferisce alla sede oggetto di verifica?',
              name: 'certificato_prevenzione_incendi',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'certificato_prevenzione_incendi_note',
              props: {
              },
              validations: [
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'I verbali di collaudo e di verifica per gli impianti, attrezzature e macchine soggetti a controllo preventivo e periodico(ascensori, impianti di messa a terra, dispositivi contro le scariche atmosferiche, estintori ecc. si riferiscono alla sede oggetto di verifica?',
              name: 'verbali_collaudo_verifica_impianti',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'verbali_collaudo_verifica_impianti_note',
              props: {
              },
              validations: [
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'note_attrezzature',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'sic___',
              type: 'title',
              element: 'h4',
              text: 'SICUREZZA SUI LUOGHI DI LAVORO',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Il documento di valutazione dei rischi si riferisce alla sede oggetto di verifica?',
              name: 'documento_valutazione_rischi',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
  ],
  onSubmit: 'onSubmit',
}

class AttrezzatureMacchine extends Component {

  state = {
    mounted: false,
    loading: false,
    macchine: [],
    validAttrezzature: {},
    noteAttrezzature: {},
    validAudiovisivi: {},
    noteAudiovisivi: {}
  };
/*
  addMacchina( data ) {
    data.id = Math.random();
    this.setState({
      macchine: [...this.state.macchine, data]
    })
  }

  deleteMacchina( data ) {
    this.setState({
      macchine: this.state.macchine.filter(c => c.id != data.id)
    })
  }

  canEdit() {
    return true;
  }
*/

  componentDidMount() {
    if(this.props.values && this.props.values.validAttrezzature) this.setState({validAttrezzature: this.props.values.validAttrezzature})

    if(this.props.values && this.props.values.noteAttrezzature) this.setState({noteAttrezzature: this.props.values.noteAttrezzature})

    if(this.props.values && this.props.values.validAudiovisivi) this.setState({validAudiovisivi: this.props.values.validAudiovisivi})

    if(this.props.values && this.props.values.noteAudiovisivi) this.setState({noteAudiovisivi: this.props.values.noteAudiovisivi})
  }

  async validate() {
    //let v_a = await this.validateAtti();
    let v_e = await this.validateExtra();

    return /*v_a && */v_e ? true : false;
  }

  async validateAtti() {
    return new Promise((resolve) => {
      this.formAttiSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async validateExtra() {
    return new Promise((resolve) => {
      this.formExtraSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getValues() {
    let obj = {
      //...this.formAttiSelRef.props.form.getFieldsValue(),
      ...this.formExtraSelRef.props.form.getFieldsValue(),
      validAttrezzature: this.state.validAttrezzature,
      noteAttrezzature: this.state.noteAttrezzature,
      validAudiovisivi: this.state.validAudiovisivi,
      noteAudiovisivi: this.state.noteAudiovisivi
    };
    return obj;
  }

  validAttrezzatura(record) {
    return this.state.validAttrezzature[record.id] || 0;
  }

  checkAttrezzatura(e, record) {
    let f = this.state.validAttrezzature;
    f[record.id] = e.target.value;
    this.setState({validAttrezzature: f});

    if(e.target.value === 1){
      this.setState(
        {
          noteAttrezzature: {
            ...this.state.noteAttrezzature,
            [record.id]: ''
          }
        }
      )
    }
  }

  getNoteAttrezzatura(record) {
    return this.state.noteAttrezzature[record.id] || '';
  }

  changeNoteAttrezzatura(e, record) {
    this.setState({
      noteAttrezzature: {
        ...this.state.noteAttrezzature,
        [record.id]: e.target.value
      }
    })
  }

  validAudiovisivo(record) {
    return this.state.validAudiovisivi[record.id] || 0;
  }

  checkAudiovisivo(e, record) {
    let f = this.state.validAudiovisivi;
    f[record.id] = e.target.value;
    this.setState({validAudiovisivi: f});

    if(e.target.value === 1){
      this.setState(
        {
          noteAudiovisivi: {
            ...this.state.noteAudiovisivi,
            [record.id]: ''
          }
        }
      )
    }
  }

  getNoteAudiovisivo(record) {
    return this.state.noteAudiovisivi[record.id] || '';
  }

  changeNoteAudiovisivo(e, record) {
    this.setState({
      noteAudiovisivi: {
        ...this.state.noteAudiovisivi,
        [record.id]: e.target.value
      }
    })
  }

  render() {
    return <div style={{marginTop: 16}}>
            {/*
            <CommonForm
              wrappedComponentRef={inst => (this.formAttiSelRef = inst)}
              loading={this.state.loading || false}
              form_model={formAttiLayout}
              values={this.props.values}
              readOnly={this.props.readOnly}
            />
          */}

            <h4>ELENCO MACCHINE, DELLE ATTREZZATURE E DEGLI ARREDI</h4>
            {/*
              this.canEdit()
              ?
              <CommonForm
                  wrappedComponentRef={inst => (this.formSelRef = inst)}
                  loading={this.state.loading || false}
                  form_model={formMacchinaLayout}
                  values={{}}
                  onSubmit={(data) => this.addMacchina(data)}
              />
              : null
            */}
            <Table
            rowKey={(r)=>"att_"+r.id}
            columns={columns(this)}
            dataSource={this.props.attrezzature} />

            <Table
            rowKey={(r)=>"aud_"+r.id}
            columns={audiovisivi_columns(this)}
            dataSource={this.props.audiovisivi} />

            <CommonForm
              wrappedComponentRef={inst => (this.formExtraSelRef = inst)}
              loading={this.state.loading || false}
              form_model={formExtraLayout}
              values={this.props.values}
              readOnly={this.props.readOnly}
            />
        </div>
  }
}


export default AttrezzatureMacchine;