import React, { Component } from 'react';

import { Table } from 'antd';
import moment from 'moment';
import ModelServices from '@accreditamento/services/Accreditamento';
import DocSpan from '@common/components/DocSpan';

const getDate = (dt) => {
  if (moment(dt, 'YYYY-MM-DD').isValid()) return moment(dt, 'YYYY-MM-DD').format('DD/MM/YYYY')

  return ''
}

class DocumentiList extends Component {
  model = [
    {
      title: "Data Upload",
      dataIndex: 'created_at',
      key: 'created_at',
      render: element => <div style={{ width: 100 }}>
        {
          moment.unix(element).format('DD/MM/YYYY')
        }
      </div>
    },
    {
      title: "Nome file",
      dataIndex: '',
      sorter: false,
      render: element => <div style={{ width: 250 }}><DocSpan key={element.id} file={element} /></div>
    },
    {
      title: "Tipo",
      dataIndex: 'tipo',
      sorter: false,
      render: element => <div style={{ width: 250 }}>{element}</div>
    },
    {
      title: "Validità",
      dataIndex: 'valido_dal',
      sorter: false,
      render: (element, row) => <div style={{ width: 250 }}>{getDate(row.valido_dal) + " - " + getDate(row.valido_al)}</div>
    }
  ];

  state = {
    documents: [],
    loading: false,
  }


  componentDidMount() {
    this.load(this.props)
  }

  load(props) {
    this.setState({ loading: true }, () =>
      ModelServices.getDocuments(props.accreditamento.id, (res) => {
        this.setState({
          documents: res.data.data,
          loading: false
        });
      }, () => this.setState({ loading: true }))
    );
  }

  render() {

    return <div>
      <Table
        columns={this.model}
        dataSource={this.state.documents}
        loading={this.state.loading}
      />
    </div>
  }

}
export default DocumentiList;

