import React, { Component } from 'react';

import { Table, Checkbox, Tooltip, Icon } from 'antd';

import JoinLongArray from '@common/components/JoinLongArray';

const columns = (component) => {
  return [
    {
      title: 'Nome',
      dataIndex: '',
      key: 'nome',
      render: (text, record) => (<div>{record.nome}</div>)
    },
    {
      title: 'Cognome',
      dataIndex: '',
      key: 'cognome',
      render: (text, record) => (<div>{record.cognome}</div>)
    },
    {
      title: 'Ruolo',
      dataIndex: '',
      key: 'funzione',
      render: (text, record) => (<div>{record.funzione}</div>)
    },
    {
      title: 'Principali compiti assegnati',
      dataIndex: '',
      key: 'compiti',
      render: (text, record) => (<div></div>)
    },
    {
      title: 'Esperienza maturata',
      dataIndex: '',
      key: 'esperienze',
      render: (text, record) => (
        <div style={{ width: 160 }}>
          {record.esperienze ?
            JoinLongArray(
              ", ",
              JSON.parse(record.esperienze).map((esp) => esp),
              50
            ) : null
          }

        </div>)
    },
    {
      title: 'Soggetto che mette a disposizione la risorsa',
      dataIndex: '',
      key: 'denominazione_ente_appartenenza',
      render: (text, record) => (<div>{record.denominazione_ente_appartenenza}</div>)
    },
    {
      title: <>Interno/Esterno <Tooltip placement="top" title="Specificare se si tratta di personale interno o esterno al soggetto attuatore e/o partner"><Icon className="m5w" type="info-circle" /></Tooltip></>,
      dataIndex: '',
      key: 'interno_esterno',
      render: (text, record) => (<div>{record.interno_esterno}</div>)
    },
    {
      title: '',
      dataIndex: 'azione',
      key: 'azione',
      render: (text, record) => (
        <div style={{ width: 70 }}>
          <Checkbox
            disabled={component.props.readOnly}
            onChange={(e) => component.props.checkPersonale(e.target.checked, record)}
            checked={record.checked}
          ></Checkbox>
        </div>
      ),
    }

  ];
}

class TableElencoPersonale extends Component {

  state = {
    loading: false,

  };

  refresh() {
    return this.props.refresh();
  }


  render() {
    return (
      <div>
        <Table
          readOnly={this.props.readOnly}
          locale={{ emptyText: "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali" }}
          rowKey={(record) => record.id}
          loading={this.props.loading_data}
          columns={columns(this)}
          dataSource={this.props.elencoPersonale}
        />
      </div>
    );
  }
}
export default TableElencoPersonale;