import { Card, Row, Col } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import affidamentoStatic from '@common/utils/static/affidamento';



export default class PannelloDettaglioCorso extends Component {

    static propTypes = {
        lesson: PropTypes.object,
        isNextLesson: PropTypes.bool,
        loading: PropTypes.bool,
    }

    state = {
        loading: false,
    }

    render() {


        return (<>

        
            { this.props.corso?.stato === affidamentoStatic.stato_progetto.completato &&
                <Card style={{ flex: "1 1 auto", marginTop: 12 }}
                    bordered={false}
                    className="shadow">
                    <Row>
                        <Col lg={24} sm={24} xs={24}>
                            <div id="info-panel">
                                <h4 className="p5h">Corso completato</h4>
                                <p><strong>Data chiusura corso:</strong> {this.props.corso && typeof this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.endDate === 'string'? moment(this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')  : this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.endDate.format('DD/MM/YYYY')}</p>
                                <p><strong>Numero di discenti:</strong> {this.props.corso.countIscritti}</p>
                                {/* TODO non abbiamo queste info 
                                <p><strong>Numero di discenti ammessi all'esame:</strong> </p>
                                <p><strong>Numero di discenti certificati/qualificati:</strong></p>
                                */}
                                <p><strong>Ore di corso programmate:</strong> {this.props.corso.oreLezioniTotali} - <strong>Ore di corso svolte:</strong> {this.props.corso.oreLezioniErogate}</p>

                            </div>
                        </Col>
                    </Row>
                </Card>
            }

            { this.props.corso?.stato === affidamentoStatic.stato_progetto.sospensione_corso &&
                <Card style={{ flex: "1 1 auto", marginTop: 12 }}
                    bordered={false}
                    className="shadow">
                    <Row>
                        <Col lg={24} sm={24} xs={24}>
                            <div id="info-panel">
                                <h4 className="p5h">Corso Sospeso</h4>
                                <p><strong>Data sospensione:</strong> {this.props.corso.ultimaIspezione && this.props.corso.ultimaIspezione.data_termine ? moment(this.props.corso.ultimaIspezione.data_termine, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</p>
                            </div>
                        </Col>
                    </Row>
                </Card>
            }

        </>
        );
    }
}

