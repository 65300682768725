import React, { Component } from 'react';



import EfficaciaServices from '../../services/Efficacia';

import CorsiFinanziati from './efficacia/CorsiFinanziati';
import LivelloAbbandono from './efficacia/LivelloAbbandono';
import LivelloSpesa from './efficacia/LivelloSpesa';
import CostiAttivita from './efficacia/CostiAttivita';
import ValidationScheda from '../audit/ValidationScheda';

class Efficacia extends Component {

  state = {
    loading: false,
    loading_protocolli_intesa: false,
    loading_partenariato: false,
    loading_studio_territorio: false,
    open_drawer: false,
    corsi_finanziati: [],
    costi_attivita: [],
    livello_abbandono: [],
    livello_spesa: [],
    tipo_accreditamento: []
  };



  componentDidMount() {
    if(this.props.current_key === "6") this.loadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.current_key !== this.props.current_key && newProps.current_key === "6") this.loadData(newProps)
  }

  loadData(props, el = null) {

    /**
     * se arriva il caricamento da un el significa che i dati sono stati aggiornati
     */
    if(el && this.props.reloadProgress) this.props.reloadProgress();

    let str_loading = (!el) ? 'loading' : 'loading_' + el;

    this.setState({[str_loading]: true},()=>{
      setTimeout(() => {
        EfficaciaServices.list( props.accreditamento.id, (res) => {

          this.setState({
            corsi_finanziati: res.data.data.corsi_finanziati,
            costi_attivita: res.data.data.costi_attivita,
            livello_abbandono: res.data.data.livello_abbandono,
            livello_spesa: res.data.data.livello_spesa,
            tipo_accreditamento: res.data.data.tipo_accreditamento,
            [str_loading]: false
          })
        }, () => this.setState({[str_loading]: false}))
      }, 500);
    })

  }


  render() {
    return (
      <React.Fragment>
        <ValidationScheda
          section="efficacia"
          refreshInline={this.props.refreshInline}
          accreditamento={this.props.accreditamento}
          progress={this.props.progress}
        />
        <CorsiFinanziati
          accreditamento={this.props.accreditamento}
          corsi_finanziati={this.state.corsi_finanziati}
          loading_data={this.state.loading_corsi_finanziati}
          GlobalStore={this.props.GlobalStore}
          readOnly={this.props.readOnly}
          tipo_accreditamento={this.state.tipo_accreditamento}
          onCreate={()=>this.loadData(this.props, 'corsi_finanziati')}
          onDelete={(id) => this.setState({corsi_finanziati: this.state.corsi_finanziati.filter(e=>e.id !== id)})}
        />
        <LivelloAbbandono
          accreditamento={this.props.accreditamento}
          livello_abbandono={this.state.livello_abbandono}
          loading_data={this.state.loading_livello_abbandono}
          GlobalStore={this.props.GlobalStore}
          readOnly={this.props.readOnly}
          tipo_accreditamento={this.state.tipo_accreditamento}
          onCreate={()=>this.loadData(this.props, 'livello_abbandono')}
          onDelete={(id) => this.setState({livello_abbandono: this.state.livello_abbandono.filter(e=>e.id !== id)})}
        />
        <LivelloSpesa
          accreditamento={this.props.accreditamento}
          livello_spesa={this.state.livello_spesa}
          loading_data={this.state.loading_livello_spesa}
          GlobalStore={this.props.GlobalStore}
          readOnly={this.props.readOnly}
          tipo_accreditamento={this.state.tipo_accreditamento}
          onCreate={()=>this.loadData(this.props, 'livello_spesa')}
          onDelete={(id) => this.setState({livello_spesa: this.state.livello_spesa.filter(e=>e.id !== id)})}
        />
        <CostiAttivita
          accreditamento={this.props.accreditamento}
          costi_attivita={this.state.costi_attivita}
          loading_data={this.state.loading_costi_attivita}
          GlobalStore={this.props.GlobalStore}
          readOnly={this.props.readOnly}
          tipo_accreditamento={this.state.tipo_accreditamento}
          onCreate={()=>this.loadData(this.props, 'costi_attivita')}
          onDelete={(id) => this.setState({costi_attivita: this.state.costi_attivita.filter(e=>e.id !== id)})}
        />
      </React.Fragment>
    );
  }
}
export default Efficacia;