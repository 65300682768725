import React, { Component } from 'react';
import { Button, Card, Popconfirm, message, Tooltip, Row, Icon } from 'antd';

import moment from 'moment';

import checkPermission from '@common/utils/check/permissions';

import FilterForm from '@common/components/filterForm';
import TableComponent from '@common/components/table';

import ModelServices from '../services/Progetto';

import FileSaver from 'file-saver';

import affidamentoStatic from '@common/utils/static/affidamento';
import ProjectServices from '@affidamento/services/Progetto';

import _ from '@common/utils/formatters/_';

const renderActions = (element, component) => {

  return <div style={{ display: 'inline-block' }}>

    {checkPermission.hasPermission(component.props.UserStore.user, ['viewProgetto']) && checkPermission.hasPermission(component.props.UserStore.user, ['editProgetto']) &&
      <Tooltip placement="top" title={element.stato === affidamentoStatic.stato_progetto.registrato || element.stato === affidamentoStatic.stato_progetto.predisposizione ? "Modifica scheda di progetto" : "Visualizza scheda di progetto"}>
        <Button className="m2w" size="small" shape="circle" icon={element.stato === affidamentoStatic.stato_progetto.registrato || element.stato === affidamentoStatic.stato_progetto.predisposizione ? "edit" : "eye"} onClick={() => component.props.history.push("/affidamento/progetto/" + element.id)} />
      </Tooltip>
    }

    {checkPermission.hasPermission(component.props.UserStore.user, ['viewProgetto'])
      && !checkPermission.hasPermission(component.props.UserStore.user, ['editProgetto'])
      &&
      <Tooltip placement="top" title="Visualizza scheda di progetto">
        <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/affidamento/progetto/" + element.id)} />
      </Tooltip>
    }

    {checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente'])
      && !checkPermission.hasPermission(component.props.UserStore.user, ['editProgetto'])
      && element.stato === affidamentoStatic.stato_progetto.iscrizioni_aperte
      && !element.iscrizioni[0]
      &&
      <Tooltip placement="top" title="Compila domanda di partecipazione">
        <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.loadRegistrationData(element.id)} />
      </Tooltip>
    }

    {checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente'])
      && !checkPermission.hasPermission(component.props.UserStore.user, ['editProgetto'])
      && element.iscrizioni[0]
      &&
      <Tooltip placement="top" title={element.iscrizioni[0].stato >= affidamentoStatic.stato_iscrizione.inviata ? "Visualizza domanda di partecipazione" : "Modifica domanda di partecipazione"}>
        <Button className="m2w" size="small" shape="circle" icon={element.iscrizioni[0].stato >= affidamentoStatic.stato_iscrizione.inviata ? "solution" : "edit"} onClick={() => component.props.history.push("/affidamento/progetto/" + element.id + "/iscrizione/" + element.iscrizioni[0].id)} />
      </Tooltip>
    }
    {checkPermission.hasPermission(component.props.UserStore.user, ['viewProgetto'])
      && !checkPermission.hasPermission(component.props.UserStore.user, ['manageRegistration'])
      &&
      < Tooltip placement="top" title="Scarica modello in pdf del progetto">
        <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="file-pdf" onClick={() => component.printPdf(element.id)} />
      </Tooltip>
    }

    {checkPermission.hasPermission(component.props.UserStore.user, ['viewDomandePartecipazionePerProgetto']) && element.stato >= affidamentoStatic.stato_progetto.avvio_progetto &&
      <Tooltip placement="top" title="Visualizza domande di partecipazione">
        <Button className="m2w" size="small" shape="circle" icon="solution" onClick={() => component.props.history.push("/affidamento/progetto/" + element.id + "/domande")} />
      </Tooltip>
    }

    {checkPermission.hasPermission(component.props.UserStore.user, ['viewProgetto']) &&
      ((element.istruttore && element.istruttore.length > 0 && element.istruttore[0].id === component.props.UserStore.user.id && element.stato_flusso >= affidamentoStatic.stato_flusso_progetto.corso_pronto_all_avvio)
        ||
        (element.stato >= affidamentoStatic.stato_progetto.avvio_progetto && (!element.istruttore && element.istruttore && element.istruttore.length > 0 && element.istruttore[0].id !== component.props.UserStore.user.id))) &&
      <Tooltip placement="top" title="Visualizza registro elettronico">
        <Button className="m2w" size="small" shape="circle" icon="profile" onClick={() => component.props.history.push("/affidamento/registro/" + element.id)} />
      </Tooltip>
    }
    {element.tipoFinanziamento.id === affidamentoStatic.tipo_finanziamento.autofinanziato &&
      element.stato >= affidamentoStatic.stato_progetto.avvio_progetto &&
      checkPermission.hasPermission(component.props.UserStore.user, ['addProgetto']) && checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']) &&
      <Tooltip placement="top" title={element.edizione ? "Nuova edizione" : "Duplica"}>
        <Button className="m2w" size="small" shape="circle" icon="copy" onClick={() => component.newEdition(element.id)} />
      </Tooltip>
    }
    {element.tipoFinanziamento.id === affidamentoStatic.tipo_finanziamento.autofinanziato && element.stato < affidamentoStatic.stato_progetto.presentato &&
      checkPermission.hasPermission(component.props.UserStore.user, ['deleteProgetto']) &&
      <Tooltip placement="top" title="Elimina il progetto">
        <Popconfirm disabled={component.state.loading} placement="leftTop" title="Sicuro di voler rimuovere il progetto?" onConfirm={() => component.deleteProgetto(element.id)} okText="Si" cancelText="No">
          <Button disabled={component.state.loading} className="m2w" size="small" shape="circle" icon="delete" />
        </Popconfirm>

      </Tooltip>
    }
  </div>
}

const renderStatusIndicator = (record, component) => {

  let user = '';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['manageOwnProgetto'])) user = 'ente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['manageProgetto'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['addAudit'])) user = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['manageOwnNomina'])) user = 'ispettore';

  let colors = ['green', 'red',];
  let defined_c = '';

  if (user !== '') {
    colors.forEach(c => {
      if (affidamentoStatic.getPallino(user, c, parseInt(record.stato_flusso)) !== -1) defined_c = c;
    })
  }

  return (defined_c !== '') ? <Tooltip placement="topRight" title={record.stato_flusso_description['descrizione_' + user]}>
    <span className={"pallino " + defined_c}></span>
  </Tooltip> : <span className={"pallino invisible"}></span>;

}

const model = (component) => {
  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        render: element => <div style={{ width: 200 }}>
          {
            renderStatusIndicator(element, component)
          }
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Tipo',
        dataIndex: '',
        sorter: false,
        key: 'tipo_finanziamento',
        render: element => <div>
          {<strong>{element.tipoFinanziamento?.nome || ''}</strong>}
        </div>
      },
      {
        title: 'Titolo',
        dataIndex: '',
        sorter: true,
        key: 'titolo_progetto',
        render: element => <div>
          {element.titolo_progetto}
        </div>
      },
      {
        title: 'Edizione',
        dataIndex: '',
        sorter: false,
        key: 'edizione',
        render: element => <div>
          {element.edizione}
        </div>
      },
      !checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']) && {
        title: 'Ente erogatore',
        dataIndex: '',
        sorter: false,
        key: 'ente_erogatore',
        render: element => <div>
          {element.ente?.denominazione || ''}
        </div>
      },
      {
        title: 'Data di avvio prevista',
        dataIndex: '',
        sorter: true,
        key: 'data_presunta_avvio',
        render: element => <div>
          {(element.data_presunta_avvio) ? moment(element.data_presunta_avvio, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        title: 'Responsabile',
        dataIndex: '',
        sorter: false,
        key: 'responsabile',
        render: element => <div>
          {element.istruttore && element.istruttore[0] ? element.istruttore[0].anagrafica.cognome + ' ' + element.istruttore[0].anagrafica.nome : ''}
        </div>
      },
      {
        title: 'Stato',
        dataIndex: '',
        sorter: true,
        key: 'stato',
        render: element => <div style={{ width: 140 }}>
          {affidamentoStatic.getProgettoLabel(element.stato, checkPermission.hasPermission(component.props.UserStore.user, ['entePermission']))}
        </div>,
      },
      checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente']) && {
        title: 'Stato domanda',
        dataIndex: '',
        sorter: false,
        key: 'stato_iscrizione',
        render: element => <div >
          {
            element.iscrizioni[0]
            &&
            (
              (element.iscrizioni[0].stato === affidamentoStatic.stato_iscrizione.non_idoneo
                || element.iscrizioni[0].stato_flusso === affidamentoStatic.stato_flusso_iscrizione.rifiutato)
                ?
                <React.Fragment>{
                  element.iscrizioni[0].stato_flusso
                    ?
                    affidamentoStatic.getStatoFlussoIscrizioneLabel(element.iscrizioni[0].stato_flusso)
                    :
                    affidamentoStatic.getStatoIscrizioneLabel(element.iscrizioni[0].stato)
                }
                  <Tooltip placement="top" title={element.iscrizioni[0].note || 'Nessuna motivazione fornita'}><Icon type="info-circle" className="ico-blue" />
                  </Tooltip>
                </React.Fragment>
                :
                element.iscrizioni[0].stato_flusso
                  ?
                  affidamentoStatic.getStatoFlussoIscrizioneLabel(element.iscrizioni[0].stato_flusso)
                  :
                  affidamentoStatic.getStatoIscrizioneLabel(element.iscrizioni[0].stato)
            )
          }
        </div>
      },
      checkPermission.hasPermission(component.props.UserStore.user, ['manageRegistration'])
      &&
      {
        title: 'Data apertura candidature',
        dataIndex: '',
        sorter: true,
        key: 'data_apertura_candidature',
        render: element => <div style={{ width: 140 }}>
          {(element.data_apertura_candidature)
            ? moment(element.data_apertura_candidature, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : ''}
        </div>,
      },
      checkPermission.hasPermission(component.props.UserStore.user, ['manageRegistration'])
      &&
      {
        title: 'Data chiusura candidature',
        dataIndex: '',
        sorter: true,
        key: 'data_chiusura_candidature',
        render: element => <div style={{ width: 140 }}>
          {(element.data_chiusura_candidature)
            ? moment(element.data_chiusura_candidature, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : ''}
        </div>,
      }
    ]
  }
}

const filterFormModel = (component) => {

  const isEnte = component.props.UserStore.user.role.some(r => r.name !== 'Ente');
  const isDiscente = component.props.UserStore.user.role.some(r => r.name === 'Discente');

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: isDiscente ? 12 : isEnte ? 6 : 8,
            items: [
              {
                type: 'string',
                label: 'Titolo progetto',
                name: 'titolo_progetto',
              }
            ]
          },
          {
            span: isEnte ? 6 : 8,
            items: [
              {
                type: 'datepicker',
                label: 'Data entro la quale deve essere avviato',
                name: 'data_avvio',
                format: 'DD/MM/YYYY',
                props: {
                  style: { width: '100%' },
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente']); }
                  },
                ]
              }]
          },
          {
            span: isEnte ? 6 : 8,
            items: [
              { // TODO
                type: 'select',
                label: 'Stato',
                name: 'stato',
                options: [
                  {
                    value: "-1",
                    label: "Tutti"
                  },
                  {
                    value: "0",
                    label: "Registrato"
                  },
                  {
                    value: "1",
                    label: "In predisposizione"
                  },
                  {
                    value: "2",
                    label: "Presentato"
                  },
                  {
                    value: "3",
                    label: "Assegnato"
                  },
                  {
                    value: "4",
                    label: "In istruttoria"
                  },
                  {
                    value: "5",
                    label: "Avvio sospeso"
                  },
                  {
                    value: "6",
                    label: "Rigettato"
                  },
                  {
                    value: "7",
                    label: "Avvio progetto"
                  },
                  {
                    value: "8",
                    label: "Iscrizioni aperte"
                  },
                  {
                    value: "10",
                    label: "In erogazione"
                  },
                  {
                    value: "11",
                    label: "Completato"
                  },
                  {
                    value: "12",
                    label: "Revoca corso"
                  },
                  {
                    value: "13",
                    label: "Sospensione corso"
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente']); }
                  },
                ]
              }
            ]
          },
          isEnte ? {
            span: 6,
            items: [
              {
                type: 'string',
                label: 'Ente erogatore',
                name: 'ente_erogatore',
              }
            ]
          } : {},
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Stato domanda',
                name: 'stato_iscrizione',
                options: [
                  {
                    value: "",
                    label: "Tutti"
                  },
                  {
                    value: "0",
                    label: "Bozza"
                  },
                  {
                    value: "1",
                    label: "Compilata"
                  },
                  {
                    value: "2",
                    label: "Inviata"
                  },
                  {
                    value: "3",
                    label: "Idoneo"
                  },
                  {
                    value: "4",
                    label: "Non idoneo"
                  },
                  {
                    value: "5",
                    label: "Abbandonato"
                  },
                  {
                    value: "6",
                    label: "In attesa"
                  },
                  {
                    value: "7",
                    label: "Iscritto"
                  },
                  {
                    value: "8",
                    label: "Espulso"
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente']); }
                  },
                ]
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  };
}

class TableProgetti extends Component {
  base_url = '/affidamento/progetti'
  default_order = '-created_at';

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search)
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);

    if (params.stato === "-1") {
      delete params.stato;
    }

    //  params.with = 'domande';  TODO inserire quello che serve
    if (checkPermission.hasPermission(this.props.UserStore.user, ['viewProgettoDiscente'])) {
      params.anagrafica = this.props.UserStore.user?.anagrafica.id;
      params.with = [
        'istruttore',
        'iscrizioni',
      ].join(',');
    } else {
      params.with = [
        'istruttore',
      ].join(',');
    }

    params.fields = [
      'id',
      'tipo_finanziamento',
      'titolo_progetto',
      'edizione',
      'ente_erogatore',
      'data_presunta_avvio',
      'responsabile',
      'stato',
      'stato_flusso',
      'stato_flusso_description',
      'data_apertura_candidature',
      'data_chiusura_candidature'
    ].join(',');

    this.setState({ loading: true });
    ModelServices.list(params, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
    })
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  deleteProgetto(id) {
    this.setState({ loading: true }, () => {
      ModelServices.delete(
        id,
        res => {
          message.success("Progetto eliminato con successo!");
          this.setState({ loading: false })
          this.refresh();
        },
        () => this.setState({ loading: false }))
    });
  }

  printPdf(id) {
    this.setState({
      loading: true
    }, () => {
      ModelServices.printPdf(id, res => {
        const _blob = new Blob([res.data], { type: 'application/pdf' });
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hour = ('0' + today.getHours()).slice(-2);
        const minu = ('0' + today.getMinutes()).slice(-2);
        let nome_file = `progetto_${date}${month}${year}_${hour}${minu}.pdf`;
        FileSaver.saveAs(_blob, nome_file);
        this.setState({ loading: false })
      });
    })

  }

  addNew() {
    this.setState({ loading: true }, () => {
      ModelServices.create(null,
        res => {
          message.success("Nuovo progetto creato con successo, procedere con la compilazione dei dati");
          this.setState({ loading: false })
          this.props.history.push("/affidamento/progetto/" + res.data.data.id);
        },
        () => this.setState({ loading: false }))
    })
  }

  newEdition(id) {
    this.setState({ loading: true }, () => {
      ModelServices.newEdition(
        id,
        res => {
          message.success("Nuova edizione creata con successo, procedere con la compilazione dei dati");
          this.setState({ loading: false })
          this.props.history.push("/affidamento/progetto/" + res.data.data.id);
        },
        () => this.setState({ loading: false }))
    });
  }

  delete(id) {
    this.setState({ loading: true }, () => {
      ModelServices.delete(
        id,
        res => {
          message.success("Formulario eliminato con successo!");
          this.setState({ loading: false })
          this.refresh();
        },
        () => this.setState({ loading: false }))
    });
  }

  loadRegistrationData(id) {
    this.setState({ loading: true }, () => {
      ProjectServices.loadRegistrationData(
        {},
        id,
        res => {
          this.setState({
            model_anagrafica: res.data.data,
            loading: false,
          })
          this.props.history.push("/affidamento/progetto/" + id + "/iscrizione/" + res.data.data.id);
        },
        () => this.setState({ loading: false }))
    });
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.props.location.search}
          history={this.props.history}
          date_fields={['data_avvio']}
        />
        <Card
          className="shadow no-pad"
          bordered={false}>
          <Row type="flex" justify="start" className="m10h">
            {(checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])) &&
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addNew()} loading={this.state.loading}>Nuovo progetto autofinanziato</Button>
              </div>
            }
          </Row>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

export default TableProgetti;
