import React, { Component } from 'react';
import { Button, message } from 'antd';
import CommonForm from '@common/components/form';

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Titolo Modulo',
                                name: 'moduleTitle',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },

                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Descrizione modulo (Descrivere gli obiettivi formativi,  e i contenuti di ciascun Modulo in relazione agli obiettivi formativi generali)',
                                name: 'moduleDescription',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 6,
                        items: [
                            {
                                type: 'integer',
                                label: 'Durata modulo (totale ore previste per il modulo)',
                                name: 'moduleDuration',
                                props: {
                                    min: 0,
                                    disabled: true,
                                },
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'integer',
                                label: 'Unità didattiche (Numero di Unità didattiche  previste per il modulo)',
                                name: 'teachingUnits',
                                props: {
                                    min: 0,
                                    disabled: true,
                                },
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'select',
                                label: 'Modalità formative',
                                name: 'teachingMethods',
                                options_props: '_modalita_formativa',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'select',
                                label: 'Annualità',
                                name: 'annuity',
                                options_props: '_annuity',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },

                ]
            },
        ],
    }
}

class EditModuliDidattici extends Component {

    state = {
        loaded: false,
        loading: true,
        moduloDidattico: {},
        unitList : []
    };

    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loaded: true,
            loading: false,
            moduloDidattico: props.moduloDidattico
        });
    }

    async validate() {
        return new Promise((resolve) => {
          this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
            if (!errors || errors.length === 0) {
              resolve(true);
            } else {
              message.error('Ricontrolla i campi');
              resolve(false);
            }
          });
        });
      }

    async submit() {
        if (await this.validate()) {
            let data = {};
           // data.unitList = this.state.unitList;
            data.unitList = [];
            data.moduleTitle = this.formSelRef?.props.form?.getFieldValue('moduleTitle');
            data.moduleDescription = this.formSelRef?.props.form?.getFieldValue('moduleDescription');
            data.moduleDuration = this.formSelRef?.props.form?.getFieldValue('moduleDuration');
            data.teachingUnits = this.formSelRef?.props.form?.getFieldValue('teachingUnits');
            data.teachingMethods = this.formSelRef?.props.form?.getFieldValue('teachingMethods');
            data.annuity = this.formSelRef?.props.form?.getFieldValue('annuity');
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    moduloDidattico: data
                });
                if (this.props.create) {
                    this.props.onCreate(data);
                } else {
                    this.props.onUpdate(data);
                }
            }
        }
    }

    render() {
        return (
            <div>
                <CommonForm
                    wrappedComponentRef={inst => (this.formSelRef = inst)}
                    loading={this.state.loading || false}
                    form_model={formLayout(this)}
                    values={this.state.moduloDidattico}
                    readOnly={this.props.readOnly}
                    _annuity={this.props.annuityList}
                    _modalita_formativa={this.props.GlobalStore.stubs.modalita_formativa_modulo_didattico}
                />
                <Button disabled={this.props.readOnly} type="primary" className="m5h" onClick={() => this.submit()}>Salva</Button>
            </div>
        )
    }
}

export default EditModuliDidattici;