import React, { Component } from 'react';

import { Button, Drawer } from 'antd';

import ModelCreate from '../../forms/settore/Create';


class CreateModel extends Component {
  base_url = '/settore'

  state = {
    loading: false,
    open_drawer: false
  }

  submit() {
    this.props.oncreate();
    this.setState({ open_drawer: false });
  }

  render() {
    return (
      <div>
        <Button className="m5h bg-green" icon="plus" onClick={() => this.setState({ open_drawer: true })}>Aggiungi</Button>
        <Drawer
          title="Inserisci un nuovo record"
          width={720}
          placement="right"
          onClose={() => this.setState({ open_drawer: false })}
          maskClosable={true}
          visible={this.state.open_drawer}
          style={{
            overflow: 'auto',
          }}
        >
          <ModelCreate
            oncreate={() => this.submit()}
          />
        </Drawer>
      </div>
    );
  }
}
export default CreateModel;