import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Row, Col, Button, notification, message } from 'antd';

import InfrastrutturaServices from '@accreditamento/services/Infrastruttura';

import IndirizzoForm from '@accreditamento/forms/completeRequest/infrastruttura/caratteristiche/Indirizzo';
import RecapitoForm from '@accreditamento/forms/completeRequest/infrastruttura/caratteristiche/Recapito';
import CaratteristicheForm from '@accreditamento/forms/completeRequest/infrastruttura/caratteristiche/Caratteristiche';
import FilesForm from '@accreditamento/forms/completeRequest/infrastruttura/caratteristiche/Files';

class Caratteristiche extends Component {

  state = {
    loading: false,
    loaded: false,
    values: {
      enteSede: {
        indirizzo: {},
        recapito: {}
      }
    }
  };

  constructor(props) {
    super(props);

    this.indirizzo_ref = null;
    this.recapito_ref = null;
    this.caratteristiche_ref = null;
    this.macrotipologie_ref = null;
    this.file_ref = null;
  }

  componentDidMount() {
    //this.recapito_ref = this.props.recapiti;
    this.setState({
      loaded: true
    }, ()=>{
      this.changeTitoloUsoLocali(this.props.sede.id_tipologia_uso_locali)
      this.changeClausoleRisolutorie(this.props.sede.contratto_clausole_risolutorie)
    });
  }

  changeClausoleRisolutorie(value) {
    this.file_ref.updateClausoleRisolutorie(value === "1")
  }

  changeTitoloUsoLocali(value) {
    this.file_ref.updateTitoloUsoLocali(value)
  }

  async submit() {
    if(!this.state.loading) {

      await this.setState({ loading: true });

      let valid_indirizzo = await this.indirizzo_ref.validate();
      if(!valid_indirizzo) {
        message.error('Inserisci l\'indirizzo');
        await this.setState({ loading: false });
        return;
      }

      let valid_recapito = await this.recapito_ref.validate();
      if(!valid_recapito) {
        message.error('Inserisci i recapiti');
        await this.setState({ loading: false });
        return;
      }

      let valid_caratteristiche = await this.caratteristiche_ref.validate();
      if(!valid_caratteristiche) {
        message.error('Inserisci le caratteristiche');
        await this.setState({ loading: false });
        return;
      }

      let valid_file = await this.file_ref.validate();
      if(!valid_file) {
        message.error('Carica tutti i file');
        await this.setState({ loading: false });
        return;
      }

      let data = {
        UtlIndirizzi: this.indirizzo_ref.getFormData(),
        UtlRecapiti: this.recapito_ref.getFormData(),
        ConAccreditamentoEnteSede: this.caratteristiche_ref.getFormData(),
      }

      if(!data.ConAccreditamentoEnteSede.contratto_clausole_risolutorie){
        data.ConAccreditamentoEnteSede.contratto_clausole_risolutorie = "0";
      }

      console.error("data", data)

      // come da email 24 maggio 2024 15:42 questo vincolo non si deve porre per le domande migrate
      // OVVERO SE NON C'E' IL COD ENTE!
      if (!this.props.accreditamento.cod_ente && data.ConAccreditamentoEnteSede.superficie_minima_netta_mq < 130) {

        message.error('La superficie netta della sede deve essere minimo 130 mq');
        await this.setState({ loading: false });
        return;

      }

      InfrastrutturaServices.saveCaratteristiche( this.props.accreditamento.id, this.props.sede.enteSede.id, data, (res) => {
        notification.success({message: 'Dati aggiornati con successo'});
        if(this.props.onUpdate) this.props.onUpdate();

        this.setState({ loading: false })
      }, () => this.setState({ loading: false }) );
    }
  }

  render() {
    return (
      <div>
        {this.state.loaded ?
        <Row gutter={16}>
          <Col md={12}>
            <IndirizzoForm
              ref={ (ref) => this.indirizzo_ref=ref }
              GlobalStore={this.props.GlobalStore}
              values={this.props.sede.enteSede.indirizzo}
              readOnly={this.props.readOnly}
            />
            <RecapitoForm
              ref={ (ref) => this.recapito_ref=ref }
              GlobalStore={this.props.GlobalStore}
              values={this.props.sede.enteSede.recapiti || {}}
              //values={this.props.recapiti || {}}
              readOnly={this.props.readOnly}
            />
            <CaratteristicheForm
              ref={ (ref) => this.caratteristiche_ref=ref }
              GlobalStore={this.props.GlobalStore}
              values={this.props.sede}
              onChangeClausole={(value)=>this.changeClausoleRisolutorie(value)}
              onChangeTitoloUsoLocali={(value)=>this.changeTitoloUsoLocali(value)}
              readOnly={this.props.readOnly}
              accreditamento={this.props.accreditamento}
            />
            <Button
                type="primary"
                className="bg-green"
                loading={this.state.loading}
                icon="save"
                disabled={this.props.readOnly}
                onClick={() => this.submit()}
              >Salva dati</Button>
          </Col>
          <Col md={12}>
            <FilesForm
              ref={ (ref) => this.file_ref=ref }
              media={this.props.sede.media}
              GlobalStore={this.props.GlobalStore}
              id_sede_accreditamento={this.props.sede.id}
              accreditamento={this.props.accreditamento}
              readOnly={this.props.readOnly}
            />
          </Col>
        </Row>
        : null}
      </div>
    );
  }
}

export default connect(({GlobalStore})=>({GlobalStore}))(Caratteristiche);