import React, { Component } from 'react';
import ModelServices from '../../accreditamento/services/Ente';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card, Tabs, Button, notification, Row } from 'antd'
import { Sticky, StickyContainer } from 'react-sticky';
import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import SpinnerLoading from '@common/components/SpinnerLoading';
import ListAccreditamentiEnte from '../components/ListAccreditamentiEnte';
import ListSospensioniEnte from '../components/ListSospensioniEnte';
import ListRevocheEnte from '../components/ListRevocheEnte';
import ListProvvedimenti from '../components/ListProvvedimenti';
import DatiEnteAccreditato from '../components/DatiEnteAccreditato';

const TabPane = Tabs.TabPane;

@Wrapper({ route: 'accreditamenti', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['ListEnte', 'adminPermissions'])
class StoricoAccreditamentiEnte extends Component {

    state = {
        ente: {},
        current_key: "1",
        loading: true,
        sospensione: null
    }

    componentDidMount() {
        this.loadEnte(this.props)

        
    }

    renderTabBar(props, DefaultTabBar) {
        return (
            <Sticky bottomOffset={80}>
                {({ style }) => (
                    <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
                )}
            </Sticky>
        )
    }

    loadEnte(props) {
        this.setState({ loading: true });
        ModelServices.view(
            {
                expand: [
                    'contatti', 'enteSede', 'enteSede.indirizzo', 'rappresentanteLegale', 'rappresentanteLegale.contatti', 'rappresentanteLegale.conAnagraficaIndirizzi', 'rappresentanteLegale.conAnagraficaIndirizzi.indirizzo', 'media'
                ].join(',')
            },
            props.match.params.id,
            res => {
                this.setState({ ente: res.data.data, loading: false });
            },
            e => { this.setState({ loading: false }) }
        );
    }

    render() {
        return (
            <div> {this.state.loading ? <SpinnerLoading message='Caricamento dati...' /> :
                <div>
                    <Card
                        style={{ marginBottom: '12px', backgroundColor: '#fff' }}
                        bordered={false}
                    >
                        <h3 className="sectionTitle">Ente di formazione {this.state.ente.denominazione}</h3>

                    </Card>
                    <div style={{ padding: '12px' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/enti">Enti accreditati</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state.ente.denominazione}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <Card style={{ marginTop: 12, marginBottom: 12 }}
                        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
                        bordered={false}>
                        <StickyContainer>
                            <Tabs
                                onChange={(k) => this.setState({ current_key: "" + k })}
                                defaultActiveKey={"1"}
                                activeKey={this.state.current_key}
                                size="medium"
                                renderTabBar={this.renderTabBar}>
                                <TabPane
                                    tab={<span>DATI IDENTIFICATIVI</span>} key="1">
                                    <div id="datiIdentificativi">
                                        <h3 className="m10h">Dati identificativi del soggetto</h3>

                                       <DatiEnteAccreditato
                                       {...this.props}
                                       accreditamento={{
                                           ente: this.state.ente
                                       }}
                                       readOnly={true}
                                       ></DatiEnteAccreditato>

                                    </div>
                                </TabPane>

                                <TabPane
                                    tab={<span>STORICO ACCREDITAMENTO<br /></span>} key="2">
                                    <div id="storicoAccreditamento">
                                        <ListAccreditamentiEnte {...this.props}></ListAccreditamentiEnte>
                                    </div>
                                </TabPane>

                                {
                                    this.state.ente.accreditato && this.state.ente.accreditato === true
                                    ?
                                    <TabPane tab={<span>PROVVEDIMENTI<br /></span>} key="3">

                                        {/* originale SISFO
                                        <ListProvvedimenti 
                                        provvedimentoType='COMUNICAZIONE_SOSPENSIONE'
                                        {...this.props}
                                        />
                                        <ListProvvedimenti 
                                        provvedimentoType='PROVVEDIMENTO_SOSPENSIONE'
                                        {...this.props}
                                        />
                                        <ListProvvedimenti 
                                        provvedimentoType='COMUNICAZIONE_REVOCA'
                                        {...this.props}
                                        />
                                        <ListProvvedimenti 
                                        provvedimentoType='PROVVEDIMENTO_REVOCA'
                                        {...this.props}
                                        />
                                        */}
                                        <ListProvvedimenti 
                                        provvedimentoType='PROCEDIMENTO'
                                        {...this.props}
                                        />                                        

                                    </TabPane>
                                    : null
                                }
                            </Tabs>
                        </StickyContainer>
                    </Card>
                </div>

            }
            </div>
        )
    }
}

export default StoricoAccreditamentiEnte