import React, { Component } from 'react';
import Wrapper from '@common/decorators/Wrapper';
import queryString from 'query-string';
import UserService from '@common/services/User'
import SpinnerLoading from '@common/components/SpinnerLoading';

@Wrapper({ route: 'SpidAcs', hasSidebar: false, hasHeader: true, hasFooter: true, public: true })
class SpidAcs extends Component {

  state = {
    loading: false
  }

  componentDidMount() {

    let params = queryString.parse(this.props.location.search)
   
    if (params.token) {

      localStorage.setItem('token', params.token);
      this.setState({ loading: true });
      UserService.profile(response => {
        this
          .props
          .dispatch({
            type: 'SET_USER_STORE',
            obj: {
              jwt: params.token,
              loggedIn: true,
              user: response.data.data,
              role: response.data.data.role.name
            }
          });
        this.setState({ loading: false })
        this.props.history.push('/dashboard');
      }, () => this.setState({ loading: false }))
    } else {
      this
        .props
        .dispatch({
          type: 'SET_USER_STORE',
          obj: {
            initialized: true
          }
        });
    }
  }

  render() {
    return (
      this.state.loading ? <SpinnerLoading message='Caricamento dati...' /> :
        <div className="App"></div>);
  }
}

export default SpidAcs;