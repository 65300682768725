import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button, Card, Drawer, Popconfirm, message } from 'antd';

import IstruttoriList from '@modules/utente/components/ListIstruttori';


const istruttori_table = (component_list, component_assegnazione) => {

    return {
        with_server_pagination: true, // metti paginazione lato server
        columns: [
            {
                title: 'Nome',
                dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
                sorter: true,
                key: 'nome',
                render: element => <div>{element.anagrafica ? element.anagrafica.nome : ''}</div>,
            },
            {
                title: 'Cognome',
                dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
                sorter: true,
                key: 'cognome',
                render: element => <div>{element.anagrafica ? element.anagrafica.cognome : ''}</div>,
            },
            {
                title: 'Email',
                dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
                sorter: true,
                key: 'email',
                render: element => <div>{element.email}</div>,
            },
            {
                title: 'Pratiche assegnate',
                dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
                sorter: false,
                key: 'pratiche',
                //  JayBee_BugFix_#4809
                render: element => <div>{element.count_dossier || 0}</div>,
            },
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'created_at',
                render: element => <div style={{ width: 120 }}>
                    <Popconfirm placement="top" title="Confermi di voler assegnare la pratica a questo istruttore?"
                        onConfirm={() => {component_assegnazione.assign(element.id); }}
                        okText="Si" cancelText="No">
                        <Button
                            disabled={component_assegnazione.props.assigning}
                            loading={component_assegnazione.props.assigning}
                            className="m5h" type="primary" icon="save" size="small">Assegna la pratica</Button>
                    </Popconfirm>
                </div>,
            }
        ]
    };
};



class AssegnazioneIstruttore extends Component {

    state = {
        loaded: false,
        progetto: {
            id: null
        },
        assigning: false,
        open_modal_istruttori: false
    }

    componentDidMount() {
        if (this.props.progetto.id) this.loaded(this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.progetto !== this.props.progetto) {
          this.loaded(this.props);
        }
      }


    loaded(props) {
        this.setState({ progetto: props.progetto, loaded: true });
    }

    async assign(id) {
        let savedIstruttore = await this.props.assign(id);
        if (savedIstruttore) message.success("L'istruttore è stato assegnato correttamente");
        this.state.open_modal_istruttori = !savedIstruttore;
    }


    getUserUrl(item) {
        return "/istruttore/" + item.id;
    }

    render() {
        return (this.state.loaded) ?
            <div style={{ height: "100%" }}>
                <Card
                    bordered={false}
                >
                    <h4>Istruttore</h4>

                    {this.props.canAssignIstruttore ?
                        <div>
                            <p style={{ marginBottom: 0 }}>Devi assegnare la pratica a un istruttore</p>
                            <Button
                                className="m5h"
                                style={{ marginRight: 8 }}
                                type="primary"
                                icon="user-add"
                                onClick={() => this.setState({ open_modal_istruttori: true })}>Cerca un istruttore</Button>
                        </div> : null}
                    {this.state.progetto.istruttore && this.state.progetto.istruttore.length > 0 ?
                        <div>
                            <p>Pratica assegnata all&apos;istruttore: {this.state.progetto.istruttore.map(i => <strong key={i.id}>{`${i.anagrafica.cognome} ${i.anagrafica.nome} ${i.username ? '(' + i.username + ')' : ''}`}</strong>)}</p>
                            <Button style={{ marginRight: 12 }} type="primary" size="small" ghost
                                onClick={() => this.props.history.push(this.getUserUrl(this.state.progetto.istruttore[0]))}>Profilo</Button>
                            {
                                this.props.canChangeIstruttore ?
                                    (
                                        this.props.auditExists?
                                            <Popconfirm placement="top" title="Cambiando istruttore in questa fase, con una istruttoria in corso, questa verrà interrotta e il nuovo istruttore dovrà iniziarne una nuova, confermi la scelta?"
                                                onConfirm={() => this.setState({ open_modal_istruttori: true })}
                                                okText="Si" cancelText="No">
                                                    <Button className="m5h" type="primary" icon="sync" size="small">Cambia istruttore</Button>
                                            </Popconfirm>
                                            :
                                            <Button className="m5h" type="primary" icon="sync" size="small" onClick={() => this.setState({ open_modal_istruttori: true })}>Cambia istruttore</Button>
                                    )
                                    : null}
                        </div> : null}

                </Card>

                <Drawer
                    title="Cerca un istruttore"
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({ open_modal_istruttori: false });
                    }}
                    visible={this.state.open_modal_istruttori}
                    width={1024}>
                    <IstruttoriList {...this.props}
                        base_url={'/affidamento/progetto/' + this.state.progetto.id}
                        table_model={(component) => istruttori_table(component, this)}/>
                </Drawer>
            </div>
            : null;
    }
}

const AssegnazioneExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(AssegnazioneIstruttore);

export default withRouter(AssegnazioneExport);
