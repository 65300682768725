import BaseRequest from '@common/services/_baseRequest';

export default {

    listRepertorio: (params = {}, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            params,
            {},
            null,
            'get',
            'repertorio/richiesta',
            cb,
            ecb
        );
    },

    detail: (params = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            params,
            {},
            null,
            'get',
            'repertorio/richiesta/' + id,
            cb,
            ecb
        );
    },

    info_and_validate: (json = {}, id_richiesta = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            { json_richiesta: json },
            null,
            'post',
            'repertorio/richiesta/' + id_richiesta + '/info-and-validate',
            cb,
            ecb
        );
    },

    rollback: (id, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            "post",
            'repertorio/richiesta/' + id + '/rollback',
            cb,
            ecb
        );
    },

    update: (params = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            params,
            null,
            'put',
            'repertorio/richiesta/' + id,
            cb,
            ecb
        );
    },

    create: (params = {}, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            params,
            null,
            'post',
            'repertorio/richiesta',
            cb,
            ecb
        );
    },

    delete: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'delete',
            'repertorio/richiesta/' + id,
            cb,
            ecb
        );
    },

    saveJSON: (json = {}, id = null, id_qualifica = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {
                json: json,
                id_qualifica: id_qualifica
            },
            null,
            'post',
            'repertorio/richiesta/' + id + '/save-json',
            cb,
            ecb
        );
    },

    printRichiesta: (id_richiesta, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            "get_file",
            'repertorio/richiesta/' + id_richiesta + '/download-richiesta',
            cb,
            ecb
        );
    },

    printProposta: (id_richiesta, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            "get_file",
            'repertorio/richiesta/' + id_richiesta + '/download-proposta',
            cb,
            ecb
        );
    },

    downloadDichiarazione: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'get_file',
            'repertorio/richiesta/' + id + '/dichiarazione-interesse',
            cb,
            ecb
        );
    },

    downloadRelazione: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'get_file',
            'repertorio/richiesta/' + id + '/relazione-descrittiva',
            cb,
            ecb
        );
    },

    confirm: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/confirm',
            cb,
            ecb
        );
    },

    downloadModuloRichiesta: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'get_file',
            'repertorio/richiesta/' + id + '/modulo-richiesta',
            cb,
            ecb
        );
    },

    uploadModuloRichiesta: (id, data = {}, file = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            data,
            file,
            "post",
            'repertorio/richiesta/' + id + '/upload-richiesta',
            cb,
            ecb || null,
            null
        );
    },

    downloadModuloRichiestaIntegrazione: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'get_file',
            'repertorio/richiesta/' + id + '/modulo-integrazioni',
            cb,
            ecb
        );
    },

    uploadModuloRichiestaIntegrazione: (id, data = {}, file = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            data,
            file,
            "post",
            'repertorio/richiesta/' + id + '/upload-integrazioni',
            cb,
            ecb || null,
            null
        );
    },

    send: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/send',
            cb,
            ecb
        );
    },

    confirmIntegration: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/integration-confirm',
            cb,
            ecb
        );
    },

    sendIntegrazione: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/send-integration',
            cb,
            ecb
        );
    },

    duplica: (id, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            "post",
            'repertorio/richiesta/' + id + '/duplica',
            cb,
            ecb
        );
    },

    assign: (id, data = { id_user: null }, cb = null, ecb = null) => {
        var req = new BaseRequest();
        req.makeRequest(
            {},
            data,
            null,
            "post",
            'repertorio/richiesta/' + id + '/assign',
            cb,
            ecb || null,
            null
        );
    },

    getMedia: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'get',
            'repertorio/richiesta/' + id + '/documenti',
            cb,
            ecb
        );
    },

    proceduraSemplificata: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/procedura-semplificata',
            cb,
            ecb
        );
    },

    proceduraStandard: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/procedura-standard',
            cb,
            ecb
        );
    },

    uploadDecreto: (id, data = {}, file = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
          {},
          data,
          file,
          "post",
          'repertorio/richiesta/' + id + '/upload-decreto',
          cb,
          ecb || null,
          null
        );
      },

      editControdeduzioni: (id, id_comunicazione, data, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
          {},
          data,
          null,
          "post",
          'repertorio/richiesta/' + id + '/comunicazione/' + id_comunicazione + '/edit_controdeduzioni',
          cb,
          ecb || null,
          null
        );
      },
    
      downloadControdeduzioni: (id, id_comunicazione, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
          {},
          {},
          null,
          "get_file",
          'repertorio/richiesta/' + id + '/comunicazione/' + id_comunicazione + '/controdeduzioni',
          cb,
          ecb || null,
          null
        );
      },
    
      uploadControdeduzioni: (id, id_comunicazione, data = {}, file = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
          {},
          data,
          file,
          "post",
          'repertorio/richiesta/' + id + '/comunicazione/' + id_comunicazione + '/controdeduzioni',
          cb,
          ecb || null,
          null
        );
      },
    
      sendControdeduzioni: (id, id_comunicazione, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
          {},
          {},
          null,
          "post",
          'repertorio/richiesta/' + id + '/comunicazione/' + id_comunicazione + '/send-controdeduzioni',
          cb,
          ecb || null,
          null
        );
      },

      approveControdeduzioni: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/approve-controdeduzioni',
            cb,
            ecb
        );
    },

    approveControdeduzioniGtr: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/approve-controdeduzioni-gtr',
            cb,
            ecb
        );
    },

    prepareReject: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/reject-controdeduzioni',
            cb,
            ecb
        );
    },

    prepareRejectGtr: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/reject-controdeduzioni-gtr',
            cb,
            ecb
        );
    },

    confirmReject: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/confirm-reject',
            cb,
            ecb
        );
    },

    gtrValidate: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/gtr-validate',
            cb,
            ecb
        );
    },

    gtrApprofondimento: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post', 
            'repertorio/richiesta/' + id + '/gtr-request-more-info',
            cb,
            ecb
        );
    },

    gtrValidateRiserva: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'repertorio/richiesta/' + id + '/gtr-validate-riserva',
            cb,
            ecb
        );
    },

    gtrSuspend: (id, data, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
          {},
          data,
          null,
          "post",
          'repertorio/richiesta/' + id + '/gtr-suspend' ,
          cb,
          ecb || null,
          null
        );
      },

      gtrTavoloTecnico: (id, data, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
          {},
          data,
          null,
          "post",
          'repertorio/richiesta/' + id + '/gtr-tavolo-tecnico' ,
          cb,
          ecb || null,
          null
        );
      },

      uploadVerbaleGtr: (id, data = {}, file = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            data,
            file,
            "post",
            'repertorio/richiesta/' + id + '/upload-verbale-gtr',
            cb,
            ecb || null,
            null
        );
    }, 

    gtrCompletion: (id, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            "post",
            'repertorio/richiesta/' + id + '/gtr-completion',
            cb,
            ecb || null,
            null
        );
    },

    gtrEndCompletion: (id, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            "post",
            'repertorio/richiesta/' + id + '/gtr-end-completion',
            cb,
            ecb || null,
            null
        );
    },

    gtrConfirmCompletion: (id, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            "post",
            'repertorio/richiesta/' + id + '/gtr-confirm-completion',
            cb,
            ecb || null,
            null
        );
    },
}
