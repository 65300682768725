import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import ListEnti from '../components/ListEnti';

@Wrapper({ route: 'enti', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['manageElencoRegionale'])
class EntiAccreditati extends Component {

  getBreadCrumb() {
    let breads = [
      <Breadcrumb.Item key="dash"><Link to="/dashboard">Home</Link></Breadcrumb.Item>
    ];
    breads.push(<Breadcrumb.Item key="org">Enti accreditati</Breadcrumb.Item>)
    return breads;
  }

  render() {
    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          <h3 className="sectionTitle">Elenco regionale degli enti accreditati</h3>

        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            {this.getBreadCrumb()}
          </Breadcrumb>
        </div>

        <ListEnti ref="listTable" {...this.props} />

      </div>
    );
  }
}


export default EntiAccreditati;