import React, {Component} from 'react';
//import { connect } from "react-redux";

import {message} from 'antd';

import CommonForm from '@common/components/form';

import format from '@common/utils/formatters/_';

import CapacitaGestionaliServices from '../../../services/CapacitaGestionali';


const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      /*{
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Dichiaro di applicare il CCNL agli operatori  della Formazione Professionale',
                name: 'assunzione_applicazione_ccnl',
                option_type: 'button', // normal|button
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ],
          }
        ],
    },*/
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'radio',
                label: 'Dichiaro di attenermi alle procedure di pubblicizzazione dell’attività',
                name: 'procedure_pubblicazione',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'radio',
                label: 'Dichiaro di attenermi alle procedure di aggiornamento delle competenze delle risorse umane',
                name: 'aggiornamento_competenze',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva sezione capacità gestionale',
                props: {
                  style: { float: 'right' },
                  className: "bg-green",
                  //type: "primary",
                  icon: 'save'
                },
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'checkPermissions'
                  },
                ],
                //onClick: 'showDrawer'
              },
            ],
          },
        ],
      },
    ],
    onSubmit: 'submitted',
  };
};


class CreateModel extends Component {

  state = {
    loading: false,
    model: {}
  }

  getFormLayout() {

  }

  componentDidMount () {
    this.setData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.capacita !== this.props.capacita) this.setData(newProps);
  }

  setData(props) {
    if(props.capacita) this.setState({model: format.convertBoolean( [
      'assunzione_applicazione_ccnl',
      'procedure_pubblicazione',
      'aggiornamento_competenze'
    ], props.capacita ) });
  }

  async confirm(data) {

    if(!this.state.loading) {
      await this.setState({loading: true});

      CapacitaGestionaliServices.edit( data, this.props.accreditamento.id, (res)=>{
        this.setState({loading: false, model: format.convertBoolean( [
          'assunzione_applicazione_ccnl',
          'procedure_pubblicazione',
          'aggiornamento_competenze'
        ], res.data.data) });
        message.success('Dati salvati');
        if(this.props.onUpdate) this.props.onUpdate();
      }, (err) => this.setState({loading: false}) )

    }
  }



  render () {
    return <div style={{marginTop: 24}}>
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.state.loading || false}
        form_model={formLayout(this)}
        values={this.state.model}
        submitted={(dati) => this.confirm(dati)}
        readOnly={this.props.readOnly}
        checkPermissions={()=>true}
      />
    </div>
  }

}
export default CreateModel;