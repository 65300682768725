import BaseRequest from "@common/services/_baseRequest";


export default {

	list: ( id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	},

	create: ( id_accreditamento, data, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	},

	delete: ( id_accreditamento, id_sede, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede,
			cb,
			ecb || null,
			null
		);
	},

	addExistingSede: ( id_accreditamento, id_sede, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede,
			cb,
			ecb || null,
			null
		);
	},

	viewSede: ( id_accreditamento, id_sede, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/sede',
			cb,
			ecb || null,
			null
		);
	},

	updateSede: ( id_accreditamento, id_sede, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/sede',
			cb,
			ecb || null,
			null
		);
	},

	viewRequisiti: ( id_accreditamento, id_sede, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/requisiti-aule',
			cb,
			ecb || null,
			null
		);
	},

	addRequisiti: ( id_accreditamento, id_sede, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/requisiti-aule',
			cb,
			ecb || null,
			null
		);
	},

	saveCaratteristiche: ( id_accreditamento, id_sede, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/caratteristiche',
			cb,
			ecb || null,
			null
		);
	},

	addAula: ( id_accreditamento, id_sede, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/aula',
			cb,
			ecb || null,
			null
		);
	},

	editAula: ( id_accreditamento, id_sede, id_aula, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"put",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/aula/' + id_aula,
			cb,
			ecb || null,
			null
		);
	},

	deleteAula: ( id_accreditamento, id_sede, id_aula, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/aula/' + id_aula,
			cb,
			ecb || null,
			null
		);
	},

	listAttrezzatura: ( id_accreditamento, id_sede, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/attrezzatura',
			cb,
			ecb || null,
			null
		);
	},

	addAttrezzatura: ( id_accreditamento, id_sede, data, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/attrezzatura',
			cb,
			ecb || null,
			null
		);
	},

	editAttrezzatura: ( id_accreditamento, id_sede, id_attrezzatura, data, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/attrezzatura/' + id_attrezzatura,
			cb,
			ecb || null,
			null
		);
	},

	deleteAttrezzatura: ( id_accreditamento, id_sede, id_attrezzatura, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/attrezzatura/' + id_attrezzatura,
			cb,
			ecb || null,
			null
		);
	},






	listAudiovisivo: ( id_accreditamento, id_sede, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/audiovisivo',
			cb,
			ecb || null,
			null
		);
	},

	addAudiovisivo: ( id_accreditamento, id_sede, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/audiovisivo',
			cb,
			ecb || null,
			null
		);
	},

	editAudiovisivo: ( id_accreditamento, id_sede, id_audiovisivo, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"put",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/audiovisivo/' + id_audiovisivo,
			cb,
			ecb || null,
			null
		);
	},

	deleteAudiovisivo: ( id_accreditamento, id_sede, id_audiovisivo, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/audiovisivo/' + id_audiovisivo,
			cb,
			ecb || null,
			null
		);
	},

	downloadAttrezzature: (id_accreditamento, id_sede, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/attrezzatura/autocertificazione',
			cb,
			ecb || null,
			null
		);
	},

	uploadAttrezzature: (id_accreditamento, id_sede, data, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/attrezzatura/autocertificazione',
			cb,
			ecb || null,
			null
		);
	},

	downloadAudiovisivi: (id_accreditamento, id_sede, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/audiovisivo/autocertificazione',
			cb,
			ecb || null,
			null
		);
	},

	uploadAudiovisivi: (id_accreditamento, id_sede, data, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/scheda/risorse-infrastrutturali/' + id_accreditamento + '/' + id_sede + '/audiovisivo/autocertificazione',
			cb,
			ecb || null,
			null
		);
	},

}
