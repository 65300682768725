import React, { Component } from 'react';

import { Table, Button, Drawer, message, Tooltip, Popconfirm } from 'antd';
import CreateDiscenti from './CreateDiscenti';

const columns = (component) => {
  return [

    {
      title: 'Codice fiscale',
      dataIndex: '_taxCode',
      key: '_taxCode',
      render: (text, record) => (<div>{record._taxCode}</div>)
    },
    {
      title: 'Nome',
      dataIndex: '_name',
      key: '_name',
      render: (text, record) => (<div>{record._name}</div>)
    },
    {
      title: 'Cognome',
      dataIndex: '_lastname',
      key: '_lastname',
      render: (text, record) => (<div>{record._lastname}</div>)
    },

    /* {
       title: 'Sesso',
       dataIndex: '_gender',
       key: '_gender',
       render: (text, record) => (<div>{record._gender}</div>)
     },
     {
       title: 'Luogo di nascita',
       dataIndex: '_birthPlace',
       key: '_birthPlace',
       render: (text, record) => (<div>{record._birthPlace}</div>)
     },
     {
       title: 'Data di nascita',
       dataIndex: '_birthDate',
       key: '_birthDate',
       render: (text, record) => (<div>{record._birthDate}</div>)
     },
     {
       title: 'Domicilio',
       dataIndex: '_domicileAddress',
       key: '_domicileAddress',
       render: (text, record) => (<div>{record._domicileAddress}</div>)
     },
     {
       title: 'Residenza',
       dataIndex: '_residenceAddress',
       key: '_residenceAddress',
       render: (text, record) => (<div>{record._residenceAddress}</div>)
     },
     {
       title: 'Cittadinanza',
       dataIndex: '_citizenship',
       key: '_citizenship',
       render: (text, record) => (<div>{record._citizenship}</div>)
     },
     {
       title: 'Titolo di studio',
       dataIndex: '_qualification',
       key: '_qualification',
       render: (text, record) => (<div>{record._qualification}</div>)
     },
     {
       title: 'Stato lavorativo',
       dataIndex: '_employmentStatus',
       key: '_employmentStatus',
       render: (text, record) => (<div>{record._employmentStatus}</div>)
     },
     {
       title: 'Professione;',
       dataIndex: '_profession',
       key: '_profession',
       render: (text, record) => (<div>{record._profession}</div>)
     },
     {
       title: 'Condizione di vulnerabilià',
       dataIndex: '_vulnerableCondition',
       key: '_vulnerableCondition',
       render: (text, record) => (<div>{record._vulnerableCondition}</div>)
     },*/
    {
      title: 'Numero ore assegnate',
      dataIndex: '_assignedHours',
      key: '_assignedHours',
      render: (text, record) => (<div>{record._assignedHours}</div>)
    },
    {
      title: 'Azioni',
      dataIndex: '',
      sorter: false,
      key: 'azioni',
      render: (text, record) => (
        <div >
          {!component.state.loading ?
            <React.Fragment>
              <Tooltip placement="top" title="Dettaglio discente">
                <Button type="default" className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.openDiscente(record)} />
              </Tooltip>

              <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo discente?"
                onConfirm={() => component.deleteDiscente(record._taxCode)} okText="Si" cancelText="No">
                <Button disabled={component.props.readOnly} className="m2w" size="small" shape="circle" icon="delete" />
              </Popconfirm>
            </React.Fragment>
            : null}
        </div>
      ),
    },


  ];
}

class Discenti extends Component {
  state = {
    loading: false,
    open_insert_drawer: false,
    open_detail_drawer: false,
    learnerList: [],
    learnerToOpen: {}
  }

  createLearner(data) {
    return {
      _taxCode: data.cf,
      _name: data.nome,
      _lastname: data.cognome,
      _gender: data.genere,
      _birthDate: data.nato_il,
      _birthPlace: {
        stato_nascita: data.stato_nascita,
        provincia_nascita: data.provincia_nascita,
        comune_nascita: data.comune_nascita
      },
      _domicileAddress: {
        ...data._domicileAddress
      },
      _residenceAddress: {
        ...data._residenceAddress
      },
      _profession: data._profession,
      _employmentStatus: data._employmentStatus,
      _qualification: data._qualification,
      _assignedHours: data._assignedHours,
      _citizenship: data._citizenship,
      _vulnerableCondition: data._vulnerableCondition,
    };
  }

  submit(data) {
    let list = this.state.learnerList;
    if (!list.find(l => l._taxCode === data.cf)) {
      list.push(this.createLearner(data));
    } else {
      message.warn("Il codice fiscale utilizzato è già presente nella lista");
    }
    this.setState(
      {
        open_insert_drawer: false,
        learnerList: list
      }, () => {
        this.props.saveLearnerList(this.state.learnerList);
      });
  }

  componentDidMount() {
    this.load(this.props)
  }

  openDiscente(record) {
    this.setState({
      learnerToOpen: record,
      open_detail_drawer: true
    })
  }

  deleteDiscente(_taxCode) {
    this.setState({
      learnerList: this.state.learnerList.filter(value => { return value._taxCode !== _taxCode })
    }, () => {
      this.props.saveLearnerList(this.state.learnerList);
    });
  }

  load(props) {
    this.setState({
      loading: false,
      learnerList: props.learnerList
    });
  }

  render() {
    return (
      <div>
        {!this.props.readOnly && <Button disabled={this.props.readOnly} type="primary" className="m5h" icon="plus" onClick={() => this.setState({ open_insert_drawer: true })}>Aggiungi discente</Button>}
        <Table
          rowKey={(record) => record._taxCode}
          loading={this.props.savingLearner}
          columns={columns(this)}
          dataSource={this.state.learnerList}
        />
        <Drawer
          title="Inserisci un discente"
          width={1024}
          placement="right"
          onClose={() => this.setState({ open_insert_drawer: false })}
          maskClosable={true}
          visible={this.state.open_insert_drawer}
          style={{
            height: '100%',
            overflow: 'auto',
            paddingBottom: 53,
          }}
        >
          {this.state.open_insert_drawer &&
            <CreateDiscenti
              {...this.props}
              readOnly={false}
              onCreate={(data) => this.submit(data)}
            />
          }
        </Drawer>
        <Drawer
          title="Dettaglio discente"
          width={1024}
          placement="right"
          onClose={() => this.setState({ open_detail_drawer: false })}
          maskClosable={true}
          visible={this.state.open_detail_drawer}
          style={{
            height: '100%',
            overflow: 'auto',
            paddingBottom: 53,
          }}
        >
          {this.state.open_detail_drawer &&
            <CreateDiscenti
              {...this.props}
              readOnly={true}
              onClose={() => this.setState({ open_detail_drawer: false })}
              learner={this.state.learnerToOpen}
            />
          }

        </Drawer>
      </div>
    );
  }
}
export default Discenti;