import BaseRequest from '@common/services/_baseRequest';

export default {

    list: (params = {}, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            params,
            {},
            null,
            'get',
            'ftr/calendario-corsi',
            cb,
            ecb
        );
    },

    detail: (params = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            params,
            {},
            null,
            'get',
            'ftr/calendario-corsi/' + id,
            cb,
            ecb
        );
    },

    update: (params = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            params,
            null,
            'put',
            'ftr/calendario-corsi/' + id,
            cb,
            ecb
        );
    },

    create: (params = {}, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            params,
            null,
            'post',
            'ftr/calendario-corsi',
            cb,
            ecb
        );
    },

    delete: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'delete',
            'ftr/calendario-corsi/' + id,
            cb,
            ecb
        );
    },

    export: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			null,
			null,
			'get_file',
			'ftr/calendario-corsi/export',
			cb,
			ecb || null,
			null
		);
  },

}
