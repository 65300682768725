import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import TabellaCalendarioCorsi from '../components/TabellaCalendarioCorsi';

@Wrapper({ route: 'TabellaCalendarioCorsiRoute', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewCalendarioCorsi', 'adminPermissions'])
class TabellaCalendarioCorsiRoute extends Component {

  state = {
    loading: false,
  }

  renderTitle() {
    return 'Calendario corsi'
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          <h3 className="sectionTitle">{this.renderTitle()}</h3>
          <p>Calendario corsi</p>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Calendario corsi</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <TabellaCalendarioCorsi ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default TabellaCalendarioCorsiRoute;
