import React, { Component } from 'react';

import { Button, Table, Popconfirm, Modal, Upload } from 'antd';

import format from '@common/utils/formatters/_';

import DocList from '@common/components/DocList';
import Create from '@accreditamento/forms/completeRequest/interrelazioni/Studi';

import InterrelazioniServices from '@accreditamento/services/Interrelazioni';




const columns = (component) => {
  return [
      {
        title: 'Relazione con il sistema sociale, economico e territoriale',
        dataIndex: 'oggetto',
        key: 'oggetto',
        render: (text, record) => (<div style={{width: 350}}>{record.oggetto}</div>)
      }, {
        title: 'Documentazione',
        dataIndex: 'documentazione',
        key: 'documentazione',
        render: (text, record) => (<div style={{width: 350}}>{
            record.media.map(m=>{
              return <DocList
              key={"file_"+m.id}
              file={m}
              removing={component.state.removing_file.indexOf(m.id) !== -1}
              canRemove={!component.props.readOnly}
              onRemove={(file)=>component.removeFile(file,record.id)}
              />
            })
        }
          <div>
            <Upload
            showUploadList={false}
            customRequest={obj => {
              component.uploadFile(obj, record.id);
            }}
            >
              <Button disabled={component.props.readOnly} size="small" icon="upload" loading={component.state.uploading.indexOf(record.id) !== -1 }>
                Carica documentazione
              </Button>
            </Upload>
          </div>
        </div>)
      },
      {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
          <div style={{width: 120}}>
            <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo elemento?"
            onConfirm={() => component.delete(record.id)} okText="Si" cancelText="No">
              <Button disabled={component.props.readOnly} loading={component.state.deleting.indexOf(record.id) !== -1} className="m2w" size="small" shape="circle" icon="delete" />
            </Popconfirm>

          </div>
        ),
      }
    ];
  }


const child_columns = (component) => {
  return [{
        title: 'Tipologia',
        dataIndex: 'id_tipo_accreditamento',
        render: (text, record) => (<div style={{width: 80}}>{
          format.replaceStub(record.id_tipo_accreditamento, 'tipo_accreditamento', component.props.GlobalStore)
        }
        </div>),
    }, {
        title: 'Tipo attore',
        dataIndex: 'id_tipo_attore',
        render: (text, record) => (<div style={{width: 150}}>{
          format.replaceStub(record.id_tipo_attore, 'tipo_attore', component.props.GlobalStore)
        }
        </div>),
    }, {
        title: 'Attore',
        dataIndex: 'attore',
        render: (text, record) => (<div style={{width: 300}}>{record.attore}
        </div>),
    },
    {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
            <div style={{width: 80}}>
                <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo elemento?"
                onConfirm={() => component.deleteChild(record.id, record)} okText="Si" cancelText="No">
                  <Button loading={component.state.deleting_child.indexOf(record.id) !== -1} className="m2w" size="small" shape="circle" icon="delete" />
                </Popconfirm>
            </div>
        ),
    }]
}



const expandedRowRender = (record, index, indent, expanded, component) => {
    return <Table
    columns={child_columns(component)}
    dataSource={record.dettagli.sort( (a,b) => {
      return format.replaceStub(a.id_tipo_accreditamento, 'tipo_accreditamento', component.props.GlobalStore) >
      format.replaceStub(b.id_tipo_accreditamento, 'tipo_accreditamento', component.props.GlobalStore) ? 1 : -1
    })}
    rowKey={(r)=>"dettaglio_studio_"+r.id}
    pagination={false} />;
  }

class StudioTerritorioList extends Component {

  state = {
    loading: false,
    open_drawer: false,
    updating_model: {},
    deleting: [],
    uploading: [],
    deleting_child: [],
    removing_file: [],
    open_modal: false
  };



  componentDidMount() {
    this.setState({

    })
  }





  delete(id) {
    if(this.state.deleting.indexOf(id) === -1) {
      this.setState({deleting: [...this.state.deleting, id]}, ()=>{
         InterrelazioniServices.deleteStudioTerritorio( this.props.accreditamento.id, id, () => {
           this.setState({deleting: this.state.deleting.filter(e=>e !== id)});
           if(this.props.onDelete) this.props.onDelete(id);
         }, ()=>this.setState({deleting: this.state.deleting.filter(e=>e !== id)}))
      })

    }
  }

  deleteChild(id, record) {
    if(this.state.deleting.indexOf(id) === -1) {
      this.setState({deleting_child: [...this.state.deleting, id]}, ()=>{
        InterrelazioniServices.deleteStudioTerritorioDettaglio( this.props.accreditamento.id, record.id_studio_territorio, id, () => {
           this.setState({deleting_child: this.state.deleting_child.filter(e=>e !== id)});
           if(this.props.onDeleteChild) this.props.onDeleteChild(id, record.id_studio_territorio);
         }, ()=>this.setState({deleting_child: this.state.deleting_child.filter(e=>e !== id)}))
      })

    }
  }

  documenti(id) {
    this.setState({
      open_documenti: true,
      updating_model: this.props.docenti.find(el => el.id === id)
    })
  }

  uploadFile(file, id) {
    if(this.state.uploading.indexOf(id) === -1) {
      this.setState({uploading: [...this.state.uploading, id]}, ()=>{
        InterrelazioniServices.uploadDocumentoStudioTerritorio(
          this.props.accreditamento.id, id, { ...file, chiave: 'file' }, (res) => {
            if (this.props.onUpload) this.props.onUpload(res.data.data)
            this.setState({uploading: this.state.uploading.filter(e => e !== id)})
          }, () => this.setState({uploading: this.state.uploading.filter(e => e !== id) }) )
      })
    }
  }

  async removeFile(file, id) {
    if(this.state.removing_file.indexOf(file.id) !== -1) {
      await this.setState({removing_file: [...this.state.removing_file, file.id]});
      InterrelazioniServices.removeDocumentoStudioTerritorio(
        this.props.accreditamento.id, id, file.id, (res) => {
          if (this.props.onRemoveUpload) this.props.onRemoveUpload(res.data.data)
          this.setState({removing_file: this.state.removing_file.filter(e=>e !== id)});
        }, () => this.setState({removing_file: this.state.removing_file.filter(e=>e !== id)}) )
    }

  }

  render() {
    return (
      <div style={{marginTop: 32}}>
        <h4>Studio del territorio con la valutazione delle opportunità di occupazione nei diversi settori dell’economia</h4>
        <Button
          type="primary"
          icon="plus"
          disabled={this.props.readOnly}
          onClick={()=>this.setState({open_modal: true})}>
          Inserisci studio
        </Button>
        <Modal
          title="Inserisci studio"
          visible={this.state.open_modal}
          onCancel={()=>this.setState({open_modal: false})}
          width={window.innerWidth / 100 * 80}
          okButtonProps={{style: {display: 'none'}}}

        >
          <Create
          accreditamento={this.props.accreditamento}
          GlobalStore={this.props.GlobalStore}
          tipo_accreditamento={this.props.tipo_accreditamento}
          onCreate={()=>{
            this.setState({open_modal: false})
            this.props.onCreate()
          }}
          />
        </Modal>

        <Table
        loading={this.props.loading_data || this.props.loading_studio_territorio}
        columns={columns(this)}
        rowKey={(record)=>"studio_"+record.id}
        pagination={false}
        expandedRowRender={(record, index, indent, expanded) => expandedRowRender(record, index, indent, expanded, this)}
        dataSource={this.props.studio_territorio} />
      </div>
    );
  }
}
export default StudioTerritorioList;