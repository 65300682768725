import BaseRequest from "@common/services/_baseRequest";


export default {

	assign: (id, data = { id_user: null }, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/assegnazione/'+id+'/assign',
			cb,
			ecb || null,
			null
		);
	},

	take: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/assegnazione/'+id+'/take',
			cb,
			ecb || null,
			null
		);
	},

}
