import React, { Component } from 'react';
import { Icon } from 'antd';
import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';
import UserStatic from '@common/utils/static/user'
import DatiIndirizzo from '@affidamento/components/soggettiCorrelati/DatiIndirizzo'

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Ruolo',
                name: 'tipo_utente',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'tipo_utente',
              },
            ],
          }
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Nome',
                type_string: 'text', // field type
                name: 'nome',
                props: {
                  size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: 'input',
                label: 'Cognome',
                type_string: 'text', // field type
                name: 'cognome',
                props: {
                  size: 'large',
                  prefix: (
                    <Icon type="solution" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Stato di nascita',
                name: 'stato_nascita',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'stato',
                onChange: 'selectedStatoNascita',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Provincia di nascita',
                name: 'provincia_nascita',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'province',
                onChange: 'selectedNascitaProvincia',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Comune di nascita',
                name: 'comune_nascita',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options_props: 'comuni_nascita'
              },
            ],
          },
        ],
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di nascita',
                name: 'nato_il',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  //{
                  //  validator: 'age18Validator'
                  //}
                ]
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Codice fiscale',
                type_string: 'text', // field type
                name: 'cf',
                props: {
                  size: 'large',
                  prefix: <Icon type="copy" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^[A-z0-9]{16}$',
                    message: 'Codice fiscale non valido'
                  },
                ],
              },
            ],
          },

        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Telefono',
                name: 'telefono',
                props: {
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,20})$',
                    message: 'Telefono non valido'
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Cellulare',
                name: 'cellulare',
                props: {
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    //type: 'required',
                    //error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,20})$',
                    message: 'Telefono non valido'
                  },
                ],
              },
            ],
          },
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Email',
                type_string: 'email', // field type
                name: 'email',
                props: {
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    type: 'email',
                    error_message: 'Email non valida'
                  }
                ],
              },
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'PEC',
                type_string: 'email', // field type
                name: 'pec',
                props: {
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    type: 'email',
                    error_message: 'Pec non valida'
                  }
                ],
              },
            ]
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'titolo_residenze',
                type: 'title',
                element: 'h3',
                text: 'Indirizzo di residenza',
                props: {
                  style: { textAlign: 'center' },
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'indirizzo_dati',
                type: 'child',
                child: <div style={{ marginBottom: 16 }}>
                  <React.Fragment>
                    <div
                      ref={(ref) => component.dati_indirizzo_container = ref}
                    >
                      <DatiIndirizzo
                        {...component.props}
                        readOnly={false}
                        dati={{}}
                        ref={(ref) => component.dati_indirizzo = ref}
                        subform={true}
                      ></DatiIndirizzo>
                    </div>
                  </React.Fragment>
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Azienda di appartenenza',
                type_string: 'text', // field type
                name: 'azienda_appartenenza',
                props: {
                  size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Dipartimento',
                name: 'dipartimento',
                options_props: 'dipartimenti',
                onChange: 'selectedDipartimento',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.formRef && component.formRef.props.form.getFieldsValue()['tipo_utente'] === 'DipendenteRegionale')}
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Settore',
                name: 'id_settore_regionale',
                options_props: 'settori',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.formRef && component.formRef.props.form.getFieldsValue()['tipo_utente'] === 'DipendenteRegionale')}
                  },
                ],
              },
            ],
          }
        ],
      },

    ],
    onSubmit: 'submitted',
  }
};

class ProfileUtenteGenerico extends Component {
  state = {
    comuni_nascita: [],
    loaded_roles: false,
    roles: []
  };

  componentDidMount() {
    /*UserServices.registrableUser({}, data => {
      this.setState({
        roles: data.data.data.filter(el => UserStatic.utente_generico[el.name] !== -1),
        loaded_roles: true
      });
    }, (err) => console.error(err)); */
    this.setState({
      roles: Object.entries(UserStatic.utente_generico).map(([key, value]) => {
        return {
          key: key,
          value: key,
          label: value
        }
      }),
      loaded_roles: true
    });
  }

  submitted(data) {
    this.props.onSubmit(data);
  }

  render() {
    return (
      this.state.loaded_roles && !this.props.saved &&
      <div style={{ opacity: this.props.loading ? 0.5 : 1 }}>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={{}}
          tipo_utente={this.state.roles}
          stato={this.props.GlobalStore.stubs.nazione}
          province={this.props.GlobalStore.stubs.provincia}
          dipartimenti={this.props.GlobalStore.stubs.dipartimenti_regionali}
          settori={this.state.settori_dipartimento || []}
          selectedDipartimento={(data) => {
            this.formRef.props.form.setFieldsValue({ 'id_settore_regionale': null })
            this.setState({settori_dipartimento : this.props.GlobalStore.stubs.settori_regionali?.filter(el => el.id_dipartimento_regionale === data )})
          }}
        
          selectedStatoNascita={(data) => {
            this.formRef.props.form.setFieldsValue({ 'comune_nascita': null, 'provincia_nascita': null })
          }}
          selectedNascitaProvincia={data => {

            this.setState({
              selected_nascita_provincia: data,
              comuni_nascita: []
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune_nascita: null });
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni_nascita: res.data.data || []
                  }, () => {
                    this.formRef.props.form.setFieldsValue({ comune_nascita: null });
                  });
                },
                err => null
              );
            });

          }}
          comuni_nascita={this.state.comuni_nascita.map(e => {
            return {
              key: 'nascita_comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}
        />
      </div>
    );
  }
}

export default ProfileUtenteGenerico;