import BaseRequest from "@common/services/_baseRequest";


export default {

	view: (params, id, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'user/' + id,
			cb,
			ecb || null,
			null
		);
	},

	list: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'user',
			cb,
			ecb || null,
			null
		);
	},

	listIstruttori: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'user/istruttore',
			cb,
			ecb || null,
			null
		);
	},

	listIspettori: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'user/ispettore',
			cb,
			ecb || null,
			null
		);
	},

	listValutatori: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'user/valutatore',
			cb,
			ecb || null,
			null
		);
	},

	listDocenti: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'user/docente',
			cb,
			ecb || null,
			null
		);
	},

	listSoggettiEnte: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'ente/soggetti-correlati',
			cb,
			ecb || null,
			null
		);
	},

	update: (params = {}, id = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			params,
			null,
			"put",
			'user/' + id,
			cb,
			ecb || null,
			null
		);
	},

	associateAnagrafica: (id_anagrafica, params = {}, id = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			params,
			null,
			"put",
			'user/' + id + '/associate-anagrafica/' + id_anagrafica,
			cb,
			ecb || null,
			null
		);
	},

	changeRole: (id, params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			params,
			null,
			"post",
			'user/' + id + '/change-role',
			cb,
			ecb || null,
			null
		);
	},

	create: (data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"post",
			'user',
			cb,
			ecb || null,
			null
		);
	},

	createByEnte: (data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"post",
			'user/create-by-ente',
			cb,
			ecb || null,
			null
		);
	},

	createTutorAziendale: (data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"post",
			'user/create-tutor-aziendale',
			cb,
			ecb || null,
			null
		);
	},


	createIspettore: (data = {}, file, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			file,
			"post",
			'user/create-ispettore',
			cb,
			ecb || null,
			null
		);
	},

	/*delete: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"delete",
			'user/' + id,
			cb,
			ecb || null,
			null
		);
	},*/

	changePassword: (data, id, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"post",
			'user/' + id + '/change-user-password',
			cb,
			ecb || null,
			null
		);
	},

	blockIp: (id, ip, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{
				'UserAllowedIps': {
					'ip': ip
				}
			},
			null,
			"post",
			'user/block-ip/' + id,
			cb,
			ecb || null,
			null
		);
	},

	allowIp: (id, ip, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{
				'UserAllowedIps': {
					'ip': ip
				}
			},
			null,
			"post",
			'user/allow-ip/' + id,
			cb,
			ecb || null,
			null
		);
	},

	removeBlkIp: (id, id_ip, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"delete",
			'user/' + id + '/remove-blk-ip/' + id_ip,
			cb,
			ecb || null,
			null
		);
	},

	registrableUser: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'user/registrable-user',
			cb,
			ecb || null,
			null
		);
	},

	changeStatus: (id, status = 10, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{ status: status },
			null,
			'post',
			'user/' + id + '/status',
			cb,
			ecb || null,
			null
		);
	},


	addUserExtraInfo: (params = {}, id = null, file, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
		  {},
		  params,
		  file,
		  'post',
		  'user/' + id + '/user-extra-info',
		  cb,
		  ecb
		);
	  },
	
	  editUserExtraInfo: (id_info, params = {}, id = null, file, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
		  {},
		  params,
		  file,
		  'post',
		  'user/' + id + '/user-extra-info/' + id_info,
		  cb,
		  ecb
		);
	  },
	
	  deleteUserExtraInfo: (id, id_info, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
		  {},
		  {},
		  null,
		  'delete',
		  'user/' + id + '/user-extra-info/' + id_info,
		  cb,
		  ecb
		);
	  },

	  export: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			null,
			null,
			'get_file',
			'user/export',
			cb,
			ecb || null,
			null
		);
  },

  removeRole: (id, params = {}, cb = null, ecb = null) => {
	var req = new BaseRequest();
	return req.makeRequest(
		{},
		params,
		null,
		"post",
		'user/' + id + '/remove-role',
		cb,
		ecb || null,
		null
	);
},

addRole: (id, params = {}, cb = null, ecb = null) => {
	var req = new BaseRequest();
	return req.makeRequest(
		{},
		params,
		null,
		"post",
		'user/' + id + '/add-role',
		cb,
		ecb || null,
		null
	);
},

}
