import BaseRequest from "../_baseRequest";


export default {
  all: (cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "get",
      'media/tipomedia/gettipomedia',
      cb,
      ecb || null,
      null
    );
  },

  list: (get_vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      get_vars,
      {},
      null,
      "get",
      'media/tipomedia/all',
      cb,
      ecb || null,
      null
    );
  }

}
