import React, { Component } from 'react';
import InfrastrutturaServices from '../../services/Infrastruttura';


import ValidationScheda from '../audit/ValidationScheda';
import Sedi from './infrastruttura/List';

class Infrastruttura extends Component {

  state = {
    loading: false,
    open_drawer: false,
    loading_data: true,
    accreditamento: {},
    sedi: [],
    sedi_ente: [],
    recapiti_ente: [],
  };



  componentDidMount() {
    if(this.props.current_key === "4") this.loadData(this.props, false);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.current_key !== this.props.current_key && newProps.current_key === "4") this.loadData(newProps, false)
  }

  loadData(props, refresh = true) { 
    if(this.props.reloadProgress && refresh) this.props.reloadProgress();

    this.setState({loading_data: true},()=>{
      InfrastrutturaServices.list( props.accreditamento.id, (res) => {
        this.setState({
          accreditamento: res.data.data.accreditamento,
          sedi: res.data.data.sedi_accreditamento,
          sedi_ente: res.data.data.sedi_ente,
          recapiti_ente: res.data.data.recapiti_ente,
          loading_data: false
        })
      }, () => {
        this.setState({loading_data: false});
      }
      )
    })

  }


  render() {

    return (
      <React.Fragment>
        <ValidationScheda
          section="risorse_infrastrutturali"
          refreshInline={this.props.refreshInline}
          accreditamento={this.props.accreditamento}
          progress={this.props.progress}
        />
        {
          this.state.accreditamento.id
          ?
          <Sedi
            {...this.props}
            accreditamento={this.state.accreditamento}
            sedi={this.state.sedi}
            sedi_ente={this.state.sedi_ente}
            recapiti_ente={this.state.recapiti_ente}
            onUpdate={()=>this.loadData(this.props)}
            onUpdateInline={(data)=>this.setState({sedi: data})}
            loading_data={this.state.loading_data}
            readOnly={this.props.readOnly}
          />
          : null
        }

      </React.Fragment>
    );
  }
}
export default Infrastruttura;