import React from 'react';

import { Tag, Popover } from 'antd';



const getContent = (string) => {
  return string;
}

export default (separator, array, max_str_len) => {
  if (!array || !Array.isArray(array))
    return '';
  let string = array.join(separator);
  let show_string = string;
  if (string.length > (max_str_len - 3)) {
    show_string = string.substring(0, max_str_len - 3);

    return <div>
      {show_string} <Popover placement="topLeft" content={getContent(string)} trigger="click">
        <Tag closable={false} style={{ cursor: 'pointer' }}>
          ...
        </Tag>
      </Popover>
    </div>
  }

  return show_string;
}