import React, { Component } from 'react';

import {
    Button, Drawer, Table, Tooltip, Popconfirm,
} from 'antd';

import EditStrutturaTest from './EditStrutturaTest';



const columns = (component) => {
    let ret = [
        {
            title: 'Tipologia',
            dataIndex: 'testTypeDescription',
            key: 'testTypeDescription',
            render: (text, record) => (<div>{record.testTypeDescription}</div>)
        },
        {
            title: 'Caratteristica',
            dataIndex: 'testFeatureDescription',
            key: 'testFeatureDescription',
            render: (text, record) => (<div>{record.testFeatureDescription}</div>)
        },
        {
            title: 'Competenza/e oggetto della valutazione',
            dataIndex: 'testSkills',
            key: 'testSkills',
            render: (text, record) => (<div>{record.testSkills}</div>)
        },
      /*  {
            title: 'Prestazione attesa',
            dataIndex: 'testPerformance',
            key: 'testPerformance',
            render: (text, record) => (<div>{record.testPerformance}</div>)
        },
        {
            title: 'Criteri di accettabilità della prova',
            dataIndex: 'testCriteria',
            key: 'testCriteria',
            render: (text, record) => (<div>{record.testCriteria}</div>)
        },
        {
            title: 'Modalità di svolgimento delle prove',
            dataIndex: 'testProcedure',
            key: 'testProcedure',
            render: (text, record) => (<div>{record.testProcedure}</div>)
        },
        {
            title: 'Tempi e durata di somministrazione delle prove',
            dataIndex: 'testDuration',
            key: 'testDuration',
            render: (text, record) => (<div>{record.testDuration}</div>)
        },
        {
            title: 'Strumenti per la realizzazione della prova di verifica finale (attrezzature e/o materiali, spazi, ecc.)',
            dataIndex: 'finalTestTools',
            key: 'finalTestTools',
            render: (text, record) => (<div>{record.finalTestTools}</div>)
        },
        {
            title: 'Strumenti per la valutazione delle prestazioni (griglie di analisi, schede di osservazioni, etc.)' ,
            dataIndex: 'valutationTools',
            key: 'valutationTools',
            render: (text, record) => (<div>{record.valutationTools}</div>)
        }, */
    ];

    if (!component.props.hiddenActions) {
        ret.push(
            {
                title: 'Azioni',
                dataIndex: 'azione',
                key: 'azione',
                render: (text, record) => (
                    <div>
                        {!component.props.loading_data &&
                            <React.Fragment>
                                <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                                    <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record, record.id, 'edit_test')} />
                                </Tooltip>
                                <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo orario?"
                                    onConfirm={() => component.deleteTest(record.id)} okText="Si" cancelText="No">
                                    <Button disabled={component.props.readOnly} className="m2w" size="small" shape="circle" icon="delete" />
                                </Popconfirm>
                            </React.Fragment>
                        }
                    </div>
                ),
            }
        )
    }
    return ret;
}

class StrutturaTest extends Component {

    state = {
        open_drawer_edit_test: false,
        create_test: false,
        element_opened: {},
        testList : []
    };



    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
      this.setState({
          loading: false,
          testList: props.testList
      });
    }


    deleteTest(id){
        this.setState({
            testList: this.state.testList.filter(value => {return value.id !== id})
        },()=>{
            this.props.saveTestList(this.state.testList)
        });
      }

    async update(data) {
        let list = this.state.testList;
        var index = this.state.testList.map(el => el.id).indexOf(this.state.id_element_opened);
        data.id = this.state.id_element_opened
        list[index] = data
        this.setState(
            {   open_drawer_edit_test: false,
                testList: list,
                element_opened : {},
                id_element_opened : null
            }, () => {this.props.saveTestList(this.state.testList)}
        );
    }

    async create(data) {

       let id = 0;
       if(this.state.testList.length > 0) {
        id = Math.max.apply(Math, this.state.testList.map(function(o) { return o.id; })) + 1
       }
        let list = this.state.testList;
        data.id = id
        list.push(data);
        this.setState(
            {
                open_drawer_edit_test: false,
                testList : list,
                element_opened : {},
                id_element_opened : null
            }, () => {
                this.props.saveTestList(this.state.testList);
            });
    }

    open(element, id, action) {
        this.setState({
            create_test: false,
            element_opened: element,
            id_element_opened: id,
        }, () => this.setState({ ['open_drawer_' + action]: true }))
    }

    render() {
        return (
            <div>
                {!this.props.readOnly && <Button disabled={this.props.readOnly} type="primary" className="m5h" icon="plus" onClick={() => this.setState({ open_drawer_edit_test: true, create_test: true })}>Aggiungi</Button>}
                <Table
                    rowKey={(record) => record.id}
                    loading={this.props.loading}
                    columns={columns(this)}
                    dataSource={this.state.testList}
                />
                <Drawer
                    title="ARTICOLAZIONE E STRUTTURA DELLA PROVA DI VERIFICA"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ open_drawer_edit_test: false, element_opened: null })}
                    visible={this.state.open_drawer_edit_test}
                    width={window.innerWidth - (window.innerWidth/100*5)}
                    destroyOnClose={true}
                >
                    <EditStrutturaTest
                        {...this.props}
                        close={() => this.setState({ open_drawer_edit_test: false })}
                        test={this.state.element_opened}
                        idtest={this.state.id_element_opened}
                        create={this.state.create_test}
                        onUpdate={(data) => {
                            this.update(data);
                        }}
                        onCreate={(data) => this.create(data)}
                    />
                </Drawer>
            </div>
        );
    }
}
export default StrutturaTest;