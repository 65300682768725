export default {
    jsonDomanda: {
        dataSectionB1: {
            B_1_1: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_1_2: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_1_3: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_1_4: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_1_5: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_1_6: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_1_7: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_1_8: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_1_9: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            }
        },

        dataSectionB2: {
            B_2_1: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_2: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_3: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_4: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_5: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_6: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_7: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_8: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_8_1: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_8_2: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_9: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_10: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_11: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_12: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_13: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_2_14: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
        },

        dataSectionB3: {
            B_3_1: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_3_2: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_3_3: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_3_4: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
        },
        dataSectionB4: {
            B_4_1: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_2: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_3: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_4: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_5: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_6: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_7: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_8: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_9: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_10: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_11: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_12: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_13: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_14: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_15: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
            B_4_16: {
                staffDirectCost: '',
                othersDirectCost: '',
                costDistributionRate: '',
            },
        },
        dataSectionC: {
            C_1: {
                indirectCost: '',
                costDistributionRate: '',
            },
            C_2: {
                indirectCost: '',
                costDistributionRate: '',
            },
            C_3: {
                indirectCost: '',
                costDistributionRate: '',
            },
            C_4: {
                indirectCost: '',
                costDistributionRate: '',
            },
            C_5: {
                indirectCost: '',
                costDistributionRate: '',
            },
        }
    }

}

