import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Popconfirm } from 'antd';

import ModelServices from '@accreditamento/services/Ispezione';

import checkPermission from '@common/utils/check/permissions';

class SceltaIspezione extends Component {

  state = {
    loaded: false,
    loading: false,
    assigning: false,
    ispezione_ko: false,
    ispezione_ok: false
  }






  render() {
    //console.warn('1', this.props.accreditamento.stato_flusso)
    //console.warn('2', this.props.accreditamento.need_ispezione)
    //console.warn('3', checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions']))
    return (this.props.accreditamento.stato_flusso === 8 && this.props.accreditamento.need_ispezione !== 1 && checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions'])) ?
      <div>
        <Button
          onClick={()=> {
            if(!this.state.loading) {
              this.setState({
                loading: true, ispezione_ok: true
              }, () => {
                ModelServices.need_ispezione(this.props.accreditamento.id, { need_ispezione: 1 },
                  (res) => {
                    this.setState({loading: false, ispezione_ok: false})
                    this.props.refreshInline({...res.data.data});
                  },
                  () => this.setState({loading: false, ispezione_ok: false})
                )
              })
            }
          }}
          disabled={this.state.loading} loading={this.state.ispezione_ok} className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>
          Ispezione necessaria
        </Button>
        <Popconfirm placement="top" title="Confermi la scelta?"
          onConfirm={() => {
            if(!this.state.loading) {
              this.setState({
                loading: true, ispezione_ko: true
              }, () => {
                ModelServices.need_ispezione(this.props.accreditamento.id, { need_ispezione: 0 },
                  (res) => {
                    this.setState({loading: false, ispezione_ko: false})
                    this.props.refreshInline({...res.data.data});
                  },
                  () => this.setState({loading: false, ispezione_ko: false})
                )
              })
            }
          }}
          okText="Si" cancelText="No">
          <Button disabled={this.state.loading} loading={this.state.ispezione_ko} className="m5h" type="danger" icon="close" style={{ marginRight: 8 }}>Ispezione non necessaria</Button>
        </Popconfirm>
      </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SceltaIspezione);