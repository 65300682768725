import React, { Component } from "react";
import { connect } from "react-redux";
import allegatiStatic from "@common/utils/static/allegati";

import { Row, Col, message } from "antd";

import formatter from "@common/utils/formatters/_";
import CommonForm from "@common/components/form";
import Documenti from "@common/components/Documenti";

const formLayoutRequisiti = {
  layout: "vertical",
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                "Certificazione di idoneità igienico-sanitaria rilasciata dalla ASL territorialmente competente",
              name: "nulla_osta_tecnico_sanitario",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },/*
    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                "Certificato di collaudo statico o perizia giurata redatta da un tecnico abilitato",
              name: "collaudo_statico",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                "Certificato di prevenzione incendi rilasciata dai VVFF obbligatoria per le strutture che prevedono la presenza contemporanea di un numero di persone maggiore o uguale a 100",
              name: "certificato_prevenzione_incendi",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                "Dichiarazione di conformità degli impianti e delle attrezzature ai sensi della L.46/90 e del D.P.R. 380/2001 rilasciata dalle ditte installatrici",
              name: "copia_conforme_collaudo",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },*/
    ,

    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                <>Relazione asseverata rilasciata da un tecnico abilitato
                <br></br>
                <b>La grandezza massima consentita per la Relazione asseverata rilasciata da un tecnico abilitato è di 10 Mb, se superiore dividere il file in più parti (allegati)</b>
                </>,
              name: "perizia_giurata",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },

    {
      cols: [
        {
          items: [
            {
              label: <><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></>,
              name: 'label',
              type: 'simple_label',
              showIf: [
                {
                  type: "prop_func",
                  func: "getSpace",
                },
              ],
            }
          ]
        }
      ]
    },

    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                "Certificato di Agibilità o richiesta presentata al Comune",
              name: "relazione_attrezzature",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },
    /*
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Documento sulla sicurezza ai sensi del D. Lgs. N° 81/2008, art. 17 e 18, contenente: -Relazione sulla valutazione dei rischi; -Individuazione delle misure di prevenzione e protezione e delle procedure di sicurezza',
                            name: 'documento_sicurezza',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Copia della comunicazione agli organi competenti, della nomina del Responsabile del Servizio Prevenzione e Protezione e dei relativi addetti',
                            name: 'comunicazione_responsabile',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Copia dell'incarico del medico (ove necessario)',
                            name: 'comunicazione_medico',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        */
    /*{
      cols: [
        {
          items: [
            {
              type: "radio",
              label: "Visura catastale",
              name: "certificato_catastale",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },*/
    {
      cols: [
        {
          items: [
            {
              type: "submit",
              name: "btn",
              text: "Conferma",
              props: {
                icon: "check",
                className: "bg-green",
              },
            },
          ],
        },
      ],
    },
  ],
  onSubmit: "onSubmit",
};

const formLayoutNormativa = {
  layout: "vertical",
  multistep: false,
  rows: [
    /*
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'ASL per attività formative o servizi per il lavoro',
                            name: 'nulla_osta_tecnico_sanitario',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Copia del certificato di collaudo statico o perizia giurata, effettuata da tecnico abilitato, oppure certificato di agibilità rilasciato dal comune',
                            name: 'collaudo_statico',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },

        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Certificato di prevenzione incendi, se previsto rilasciato dai Vigili del fuoco o equipollente dichiarazione rilasciata da professionista abilitato iscritto negli elenchi del Ministero, ai sensi della L. N° 818/95;',
                            name: 'certificato_prevenzione_incendi',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Copia conforme di collaudo degli impianti e delle attrezzature',
                            name: 'copia_conforme_collaudo',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Perizia giurata rilasciata da tecnico abilitato, contenente: -Dichiarazione di conformità dell’immobile alla L. 104/92 relativa all’eliminazione delle barriere architettoniche; Dichiarazione di conformità dell’immobile alle norme di sicurezza',
                            name: 'perizia_giurata',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Relazione ed elaborati grafici completi di arredi e attrezzature disponibili nei locali relativi ai locali oggetto di accreditamento redatti da tecnico abilitato',
                            name: 'relazione_attrezzature',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        */
    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                "Documento sulla sicurezza ai sensi del D. Lgs. N° 81/2008 contenente: -Relazione sulla valutazione dei rischi; -Individuazione delle misure di prevenzione e protezione e delle procedure di sicurezza, nomina del responsabiledella sicurezza che deve essere in possesso dell’attestato previsto dalla normativa vigente al momento della domanda",
              name: "documento_sicurezza",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                "Copia della comunicazione agli organi competenti, della nomina del Responsabile del Servizio Prevenzione e Protezione e dei relativi addetti",
              name: "comunicazione_responsabile",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: "radio",
              label:
                "Copia dell'incarico del medico (ove necessario)",
              name: "comunicazione_medico",
              onChange: "ch",
              option_type: "button", // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si",
                },
                {
                  value: "0",
                  label: "No",
                },
              ],
              validations: [
                /*
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },                
                {
                  pattern: "^1$",
                  message: 'Devi selezionare "Si"',
                },
                */
              ],
              props: {
                style: { paddingBottom: "75px" },
              },
            },
          ],
        },
      ],
    },
    /*
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Certificato catastale',
                            name: 'certificato_catastale',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                },
                            ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        */
    {
      cols: [
        {
          items: [
            {
              type: "submit",
              name: "btn",
              text: "Conferma",
              props: {
                icon: "check",
                className: "bg-green",
              },
            },
          ],
        },
      ],
    },
  ],
  onSubmit: "onSubmit",
};

class RequisitiForm extends Component {
  state = {
    loaded: false,
    required_files: [],
    medias: [],
    space: false,
  };

  componentDidMount() {
    this.setState({
      loaded: true,
      medias: this.props.media,
      required_files: this.getRequiredFilesFromProps(),
    });
  }

  getRequiredFilesFromProps() {
    let req = [];
    if (this.props.values) {
      
      Object.keys(this.props.values).forEach((k) => {
        if (this.props.values[k] === true || this.props.values[k] === "1")
          if(k == 'perizia_giurata'){
            req.push('perizia_giurata');
            req.push('perizia_giurata_1');
            req.push('perizia_giurata_2');
            req.push('perizia_giurata_3');
            this.setState({ space: true });
          } else {
            req.push(k);
          }
      });
    }
    console.warn('getRequiredFilesFromProps', req)
    return req;
  }

  updateValues(a, b, c) {
    let required = [];
    Object.keys(c).forEach((key) => {
      if (parseInt(c[key]) === 1) required.push(key);
    });

    this.setState({ required_files: required });
  }

  submitForm(data) {
    let valid = true;
    Object.keys(data).forEach((key) => {
      if (
        parseInt(data[key]) === 1 &&
        !this.state.medias.some((el) => el.tipo.sottodominio === key)
      )
        valid = false;
    });
    if (!valid) {
      message.error("Inserisci tutti i documenti obbligatori");
      return;
    }

    if (this.props.onSubmit) this.props.onSubmit(data);
  }

  changed(name, e) {
    let required_files_not_ordered = [];
    if(name != 'perizia_giurata') {
      if (parseInt(e.target.value) === 1) {
        required_files_not_ordered = [...this.state.required_files, name];
      } else {
        required_files_not_ordered = this.state.required_files.filter(
          (el) => el !== name
        );
      }
    } else {
      if (parseInt(e.target.value) === 1) {
        required_files_not_ordered = [...this.state.required_files, 'perizia_giurata', 'perizia_giurata_1', 'perizia_giurata_2', 'perizia_giurata_3'];
        this.setState({ space: true });
      } else {
        required_files_not_ordered = this.state.required_files.filter(
          (el) => (el !== 'perizia_giurata' && el !== 'perizia_giurata_1' && el !== 'perizia_giurata_2' & el !== 'perizia_giurata_3')
        );
        this.setState({ space: false });
      }
    } 
    console.warn('required_files_not_ordered', required_files_not_ordered) 
    this.setState({ required_files: required_files_not_ordered });
  }

  render() {
    console.warn("required_files", this.state.required_files);
    const filteredAccreditationMediaNotOrdered =
      allegatiStatic.filteredAccreditationMedia(
        this.props.GlobalStore.stubs.tipo_media.filter(
          (el) =>
            el.dominio === "requisiti_aule" &&
            this.state.required_files.indexOf(el.sottodominio) !== -1
        ),
        this.props.accreditamento.tipo_accreditamento
      );
    const input_ordered =
      this.props.type == "requisiti"
        ? [
            "nulla_osta_tecnico_sanitario",
            "collaudo_statico",
            "certificato_prevenzione_incendi",
            "copia_conforme_collaudo",
            "perizia_giurata",
            "perizia_giurata_1",
            "perizia_giurata_2",
            "perizia_giurata_3",
            "relazione_attrezzature",
            //'documento_sicurezza',
            //'comunicazione_responsabile',
            //'comunicazione_medico',
            "certificato_catastale",
          ]
        : [
            //'nulla_osta_tecnico_sanitario',
            //'collaudo_statico',
            //'certificato_prevenzione_incendi',
            //'copia_conforme_collaudo',
            //"perizia_giurata",
            //"perizia_giurata_1",
            //"perizia_giurata_2",
            //"perizia_giurata_3",
            //'relazione_attrezzature',
            "documento_sicurezza",
            "comunicazione_responsabile",
            "comunicazione_medico",
            //'certificato_catastale'
          ];

    let filteredAccreditationMedia = [];
    input_ordered.map((input) => {
      filteredAccreditationMediaNotOrdered.map((field) => {
        if (input === field.sottodominio) {
          filteredAccreditationMedia.push(field);
        }
        return null;
      });
      return null;
    });
    console.warn("filteredAccreditationMedia", filteredAccreditationMedia);
    return (
      <div>
        {this.state.loaded ? (
          <Row gutter={16}>
            <Col md={10}>
              <CommonForm style={{ height: '300px'}}
                wrappedComponentRef={(inst) => (this.formRefBools = inst)}
                loading={this.props.loading || false}
                form_model={
                  this.props.type == "requisiti"
                    ? formLayoutRequisiti
                    : formLayoutNormativa
                }
                getSpace={()=>{return this.state.space}}
                ch={(name, ev) => this.changed(name, ev)}
                values={formatter.convertBoolean(
                  this.props.type == "requisiti"
                    ? [
                        "nulla_osta_tecnico_sanitario",
                        "collaudo_statico",
                        "certificato_prevenzione_incendi",
                        "copia_conforme_collaudo",
                        "perizia_giurata",
                        "relazione_attrezzature",
                        //'documento_sicurezza',
                        //'comunicazione_responsabile',
                        //'comunicazione_medico',
                        "certificato_catastale",
                      ]
                    : [
                        //'nulla_osta_tecnico_sanitario',
                        //'collaudo_statico',
                        //'certificato_prevenzione_incendi',
                        //'copia_conforme_collaudo',
                        //"perizia_giurata",
                        //'relazione_attrezzature',
                        "documento_sicurezza",
                        "comunicazione_responsabile",
                        "comunicazione_medico",
                        //'certificato_catastale'
                      ],
                  this.props.values
                )}
                onSubmit={(values) => {
                  this.submitForm(values);
                }}
                readOnly={this.props.readOnly}
              />
            </Col>
            <Col md={14}>
              <Documenti
                canDelete={!this.props.readOnly && this.props.accreditamento.stato == 1}
                accreditation_id={this.props.accreditation_id}
                unique={true}
                media_types={filteredAccreditationMedia}
                readOnly={this.props.readOnly || this.props.accreditamento.stato != 1}
                medias={this.state.medias}
                current_domain="requisiti_aule"
                current_subdomain={null}
                required_types={this.props.GlobalStore.stubs.tipo_media
                  .filter(
                    (el) =>
                      this.state.required_files.indexOf(el.sottodominio) !==
                        -1 &&
                      el.dominio === "requisiti_aule" &&
                      el.obbligatorieta &&
                      el.stato === allegatiStatic.stato.attivo
                  )
                  .map((e) => e.tipo_media)}
                ref_id={this.props.id_sede_accreditamento}
                onUpload={(f) => {
                  this.setState({ medias: [...this.state.medias, f] });
                }}
                onDelete={(id) => {
                  this.setState({
                    medias: this.state.medias.filter((x) => x.id !== id),
                  });
                }}
                ListItem={{ style: { paddingTop: "0" } }}
                descriptionSection={{ lg: 14 }}
                iconSection={{ lg: 2 }}
                uploadSection={{ lg: 8 }}
              />
              {allegatiStatic.thereAreMediasFor(
                this.props.GlobalStore.stubs.tipo_media,
                "requisiti_aule",
                "documenti_requisiti_aule",
                this.props.accreditamento.tipo_accreditamento
              ) && (
                <Documenti
                  canAddNote={false}
                  canDelete={!this.props.readOnly && this.props.accreditamento.stato == 1}
                  accreditation_id={this.props.accreditation_id}
                  unique={true}
                  media_types={allegatiStatic.filteredAccreditationMedia(
                    this.props.GlobalStore.stubs.tipo_media,
                    this.props.accreditamento.tipo_accreditamento
                  )}
                  readOnly={this.props.readOnly || this.props.accreditamento.stato != 1}
                  medias={this.state.medias}
                  current_domain="requisiti_aule"
                  current_subdomain={"documenti_requisiti_aule"}
                  required_types={allegatiStatic.filteredRequiredMedia(
                    this.props.GlobalStore.stubs.tipo_media,
                    "requisiti_aule",
                    "documenti_requisiti_aule"
                  )}
                  ref_id={this.props.id_sede_accreditamento}
                  onUpload={(f) => {
                    this.setState({ medias: [...this.state.medias, f] });
                  }}
                  onDelete={(id) => {
                    this.setState({
                      medias: this.state.medias.filter((x) => x.id !== id),
                    });
                  }}
                />
              )}
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default connect(({ GlobalStore }) => ({ GlobalStore }))(RequisitiForm);
