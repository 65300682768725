import { Button, Drawer, Popconfirm, Table, Tooltip } from 'antd';
import React, { Component } from 'react';
import CreatePartner from './CreatePartner';

const columns = (component) => {
    let ret = [
        {
            title: 'Denominazione',
            dataIndex: 'entityPersonalData.denominazione',
            key: 'denominazione',
         },
        {
            title: 'Partita IVA',
            dataIndex: 'entityPersonalData.partita_iva',
            key: 'partita_iva',
         },
        {
            title: 'Rappresentante legale',
            dataIndex: '',
            key: 'rappresentante_legale',
            render: (text, record) => (<div className={component.recordNotValid(record, 3) ? 'record-not-valid' : ''}>{record.legalRepresentativePersonalData.nome + ' ' + record.legalRepresentativePersonalData.cognome}
                {component.recordNotValid(record, 3) && <Tooltip placement="top" title={"Attenzione: codice fiscale del rappresentante legale non valido"}><Button className="m2w record-not-valid" size="small" shape="circle" icon={"exclamation"} /></Tooltip>}
            </div>)
        },
        {
            title: 'Referente del progetto',
            dataIndex: '',
            key: 'referente_progetto',
            render: (text, record) => (<div className={component.recordNotValid(record, 4) ? 'record-not-valid' : ''}>{record.projectRepresentativePersonalData.nome + ' ' + record.projectRepresentativePersonalData.cognome}
                {component.recordNotValid(record, 4) && <Tooltip placement="top" title={"Attenzione: codice fiscale del referente non valido"}><Button className="m2w record-not-valid" size="small" shape="circle" icon={"exclamation"} /></Tooltip>}
            </div>)
        },
        {
            title: 'Azioni',
            dataIndex: 'azione',
            key: 'azione',
            render: (text, record) => (
                <div>

                    <React.Fragment>
                        <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                            <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record)} />
                        </Tooltip>
                        {!component.props.readOnly &&
                            <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo partner?"
                                onConfirm={() => component.delete(record.entityPersonalData.partita_iva)} okText="Si" cancelText="No">
                                <Button disabled={component.props.readOnly} className="m2w" size="small" shape="circle" icon="delete" />
                            </Popconfirm>
                        }

                    </React.Fragment>

                </div>
            ),
        }

    ];

    /* if (!component.props.hiddenActions) {
         ret.push(
             {
                 title: 'Azioni',
                 dataIndex: 'azione',
                 key: 'azione',
                 render: (text, record) => (
                     <div>
                         {!component.props.loading_data &&
                             <React.Fragment>
                                 <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                                     <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record, record.id, 'edit_orario')} />
                                 </Tooltip>
                                 <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo orario?"
                                     onConfirm={() => component.deleteOrario(record.id)} okText="Si" cancelText="No">
                                     <Button disabled={component.props.readOnly} className="m2w" size="small" shape="circle" icon="delete" />
                                 </Popconfirm>
                             </React.Fragment>
                         }
                     </div>
                 ),
             }
         );
     }*/
    return ret;
}

class PartnerTable extends Component {

    state = {
        partnerList: []
    }

    constructor(props) {
        super(props);

        this.dati_partner = null;
    }

    recordNotValid(record, p) {
        let error = this.props.progress_obj.errors.relatedSubjects.find(el =>
            (el[0] === 'partnerList' && el[2] === record.entityPersonalData.partita_iva && el[p] === record.legalRepresentativePersonalData.cf));
        return error !== undefined
    }

    delete(p_iva) {
        this.props.deletePartner(p_iva)
    }

    componentDidMount() {
        this.setState({
            partnerList: this.props.partnerList
        });
    }

    open(record) {
        this.setState({
            open_drawer: true,
            element_opened: record
        })
    }

    submit() {

    }

    render() {
        return <div>
            <Table
                rowKey={(record) => record.id}
                loading={this.props.loading}
                columns={columns(this)}
                dataSource={this.props.partnerList}
            />


            <Drawer
                title="Dettaglio partner"
                placement="right"
                closable={true}
                onClose={() => this.setState({ open_drawer: false })}
                visible={this.state.open_drawer}
                width={window.innerWidth - (window.innerWidth / 100 * 5)}
                destroyOnClose={true}
            >

                <CreatePartner
                    {...this.props}
                    edit={true}
                    partner={this.state.element_opened}
                    updatePartner={(data) => { this.props.updatePartner(data); this.setState({ open_drawer: false }) }}
                ></CreatePartner>

            </Drawer>
        </div>
    }


}

export default PartnerTable