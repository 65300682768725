import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";

import { connect } from "react-redux";

import itIt from 'antd/lib/locale-provider/it_IT';
import enGb from 'antd/lib/locale-provider/en_GB';
import {
  ConfigProvider,
  notification,
} from 'antd';

import moment from 'moment';
import 'moment/locale/it';

import Exception from './common/components/Exception';

import UserServices from './common/services/User';
import UtilServices from './common/services/Util';
import AuthServices from './modules/access/services/Auth';

import PrivacyPage from './common/pages/Privacy';
import NoteLegaliPage from './common/pages/NoteLegali';
import ContattiPage from './common/pages/Contatti';
import DocumentiUtili from './common/pages/DocumentiUtili';

import AccessLogin from './modules/access/routes/Login';
import LoginUserPwd from './modules/access/routes/LoginUserPwd';
import samlSsoResponse from './modules/access/routes/samlSsoResponse';
import openIdConnectResponse from './modules/access/routes/openIdConnectResponse';
import AccessAskReset from './modules/access/routes/AskReset';
import AccessReset from './modules/access/routes/Reset';
import AccessChangeTempPassword from './modules/access/routes/ChangeTempPassword';

import AccreditamentoRequest from './modules/accreditamento/routes/RegistrazioneEnte';
import LearnerRequest from './modules/accreditamento/routes/RegistrazioneDiscente';
import RegistrazioneUtenteGenerico from "@modules/accreditamento/routes/RegistrazioneUtenteGenerico";
import AccreditamentoPdfRequest from './modules/accreditamento/routes/PdfRequest';

import AccreditamentoRequestList from './modules/accreditamento/routes/RichiesteAccreditamentoRoute';
import ProvvedimentiEnteRoute from './modules/accreditamento/routes/ProvvedimentiEnteRoute';

import Dashboard from './modules/dashboard/routes/Dashboard';
import AccreditamentoScheda from './modules/accreditamento/routes/Scheda';

import GestioneFormulariRoute from './modules/affidamento/routes/GestioneFormulariRoute';
import DettagliFormulariRoute from './modules/affidamento/routes/DettagliFormularioRoute';
import FormulariCompilatiRoute from './modules/affidamento/routes/FormulariCompilatiRoute';
import CompilazioneFormularioRoute from './modules/affidamento/routes/CompilazioneFormularioRoute';
import ProgettoRoute from './modules/affidamento/routes/ProgettoRoute';

import TabellaRegistroElettronicoRoute from './modules/affidamento/routes/TabellaRegistroElettronicoRoute';
import RegistroElettronicoRoute from './modules/affidamento/routes/RegistroElettronicoRoute';
import LezioneRoute from './modules/affidamento/routes/LezioneRoute';
import DettagliDiscenteRoute from './modules/affidamento/routes/DettagliDiscenteRoute';

import Account from './modules/access/routes/Account';

import UserList from './modules/utente/routes/List';
import IspettoreList from './modules/utente/routes/Ispettori';
import IstruttoreList from './modules/utente/routes/Istruttori';
import ValutatoreList from './modules/utente/routes/Valutatori';
import EntiAccreditati from './modules/accreditamento/routes/EntiAccreditati';
import UtenteEnti from './modules/accreditamento/routes/UtenteEnti';
import SoggettoList from './modules/utente/routes/Soggetti';
import UserScheda from './modules/utente/routes/Scheda';
import Nomina from './modules/accreditamento/routes/Nomina';
import NominaProgettoRoute from './modules/affidamento/routes/NominaProgettoRoute';
import NominaList from './modules/nomina/routes/List';

import VerbaleIspezione from './modules/accreditamento/routes/VerbaleIspezione';
import Notifiche from './modules/notifiche/routes/List';
import SettoreRoute from './modules/tabelle/routes/Settore';

import jwtDecode from 'jwt-decode';

import checkPermissions from './common/utils/check/permissions';
import ProgettiFormativiRoute from "./modules/affidamento/routes/ProgettiFormativiRoute";
import DomandePartecipazioneRoute from "./modules/affidamento/routes/DomandePartecipazioneRoute";
import TabellaRichiesteRepertorioRoute from "@modules/repertorio/routes/TabellaRichiesteRepertorioRoute";
import RichiestaRepertorioRoute from "@modules/repertorio/routes/RichiestaRepertorioRoute";
import StoricoAccreditamentiEnte from "@modules/accreditamento/routes/StoricoAccreditamentiEnte";
import TabellaCalendarioCorsiRoute from "@modules/repertorio/routes/TabellaCalendarioCorsiRoute";
import QualificheProfessionaliRoute from "@modules/repertorio/routes/QualificheProfessionaliRoute";
import DettagliQualificaProfessionaleRoute from "@modules/repertorio/routes/DettagliQualificaProfessionaleRoute";
import DettagliDomandaPartecipazioneRoute from "@modules/affidamento/routes/DettagliDomandaPartecipazioneRoute";
import DettaglioStoricoQualificaProfessionaleRoute from "@modules/repertorio/routes/DettaglioStoricoQualificaProfessionaleRoute";
import StatiEsteriRoute from "@modules/statiEsteri/routes/StatiEsteriRoute";
import ComuniRoute from "@modules/comuni/routes/ComuniRoute";
import TabellaRichiestaRuoloRoute from "@modules/ruoli/routes/TabellaRichiesteRuoloRoutes";
import RichiestaRuoloRoute from "@modules/ruoli/routes/RichiestaRuoloRoute";
import AllegatiRoute from "@modules/accreditamento/routes/AllegatiRoute";
import DettaglioAllegatoRoute from "@modules/accreditamento/routes/DettaglioAllegatoRoute";
import SpidAcs from "@modules/access/routes/SpidAcs";
import DettaglioCorsoRoute from "@modules/affidamento/routes/DettaglioCorsoRoute";

moment.locale('it');

export const ROUTES = [

  {
    title: 'Account',
    //icon: 'user',
    key: 'account',
    path: '/account',
    exact: true,
    component: Account,
    parent: '/user',
  },
  {
    parent: '/account',
    path: '/account/:id',
    component: UserScheda,
    //permission: ['ViewIspettore', 'adminPermissions']
  },

  {
    title: 'Home',
    icon: 'home',
    key: 'home',
    path: '/dashboard',
    exact: true,
    component: Dashboard
  },

  {
    path: '/accreditamento/myenti',
    parent: '/account',
    exact: true,
    component: UtenteEnti,
    permission: ['entePermissions']
  },
    
  {
    title: 'Utenti',
    icon: 'team',
    key: 'all_usr',
    path: '/utente',
    exact: true,
    component: UserList,
    permission: ['ListUser', 'adminPermissions']
  },
  {
    parent: '/utente',
    path: '/utente/:id',
    component: UserScheda,
    //permission: ['ViewUser', 'adminPermissions']
  },
  {
    title: 'Addetti al controllo',
    icon: 'team',
    key: 'isp_usr',
    path: '/ispettore',
    exact: true,
    component: IspettoreList,
    permission: ['ListIspettore', 'adminPermissions']
  },
  {
    parent: '/ispettore',
    path: '/ispettore/:id',
    component: UserScheda,
    //permission: ['ViewIspettore', 'adminPermissions']
  },
  {
    title: 'Istruttori',
    icon: 'team',
    key: 'ist_usr',
    path: '/istruttore',
    exact: true,
    component: IstruttoreList,
    permission: ['ListIstruttore', 'adminPermissions']
  },
  {
    parent: '/istruttore',
    path: '/istruttore/:id',
    component: UserScheda,
    //permission: ['ViewIstruttore', 'adminPermissions']
  },
  {
    title: 'Componenti di commissione',
    icon: 'team',
    key: 'val_usr',
    path: '/valutatori',
    exact: true,
    component: ValutatoreList,
    permission: ['deleted']
  },
  {
    parent: '/valutatore',
    path: '/valutatore/:id',
    component: UserScheda,
    //permission: ['ViewIspettore', 'adminPermissions']
  },
  {
    title: 'Enti accreditati',
    icon: 'read',
    path: '/enti',
    exact: true,
    component: EntiAccreditati,
    permission: ['manageElencoRegionale']
  },
  {
    parent: '/enti',
    path: '/enti/:id/accreditamenti',
    component: StoricoAccreditamentiEnte,
    permission: ['ListEnte']
  },
  {
    title: (props) => checkPermissions.hasPermission(props.UserStore.user, ['entePermissions']) ? 'Richieste di accreditamento' : 'Richieste di accreditamento',
    icon: 'bars',
    key: 'acc_req',
    path: '/accreditamento/richieste',
    exact: true,
    component: AccreditamentoRequestList,
    permission: ['ViewRequest']
  },
  {
    parent: '/accreditamento/richieste',
    path: '/accreditamento/scheda/:id',
    component: AccreditamentoScheda
  },
  {
    title: 'Soggetti correlati',
    icon: 'deployment-unit',
    key: 'sgc_usr',
    path: '/soggetti',
    exact: true,
    component: SoggettoList,
    permission: ['deleted'],
  },
  {
    title: 'Nomine',
    icon: 'audit',
    key: 'nom_req',
    path: '/nomina',
    exact: true,
    component: NominaList,
    permission: ['ispettorePermissions', 'dirigentePermissions', 'adminPermissions', 'HelpdeskPermissions']
  },
  {
    parent: '/nomina',
    path: '/affidamento/:id_progetto/nomina/:id',
    component: NominaProgettoRoute
  },
  {
    title: 'Gestione formulari',
    icon: 'file-text',
    key: 'afc_ ',
    path: '/affidamento/formulari',
    exact: true,
    component: GestioneFormulariRoute,
    permission: ['deleted']
  },
  {
    parent: '/affidamento/formulari',
    path: '/affidamento/formulari/:id_procedura/compilati/:id',
    component: CompilazioneFormularioRoute,
  },
  {
    parent: '/affidamento/formulari',
    path: '/affidamento/formulari/:id/compilati',
    component: FormulariCompilatiRoute,
  },
  {
    parent: '/affidamento/formulari',
    path: '/affidamento/formulari/:id',
    component: DettagliFormulariRoute,
  },
  {
    title: 'Progetti formativi',
    icon: 'project',
    key: 'afc_progetti',
    path: '/affidamento/progetti',
    exact: true,
    component: ProgettiFormativiRoute,
    permission: ['deleted']
  },
  {
    parent: '/affidamento/progetti',
    path: '/affidamento/progetto/:id/iscrizione/:id_iscrizione',
    component: DettagliDomandaPartecipazioneRoute,
  },
  {
    parent: '/affidamento/progetti',
    path: '/affidamento/progetto/:id/domande',
    component: DomandePartecipazioneRoute,
  },
  {
    parent: '/affidamento/progetti',
    path: '/affidamento/progetto/:id',
    component: ProgettoRoute,
  },
  {
    title: 'Registro elettronico',
    icon: 'reconciliation',
    key: 'afc_regstro',
    path: '/affidamento/registro',
    exact: true,
    component: TabellaRegistroElettronicoRoute,
    permission: ['deleted']
  },
  {
    parent: '/affidamento/registro',
    path: '/affidamento/registro/:id_corso/lezione/:id',
    component: LezioneRoute,
  },
  {
    parent: '/affidamento/registro',
    path: '/affidamento/registro/:id_corso/discente/:id',
    component: DettagliDiscenteRoute,
  },
  {
    parent: '/affidamento/registro',
    path: '/affidamento/registro/:id',
    component: RegistroElettronicoRoute,
  },

  {
    title: 'Calendario corsi',
    icon: 'schedule',
    key: 'ftr_calendario_corsi',
    path: '/calendario',
    exact: true,
    component: TabellaCalendarioCorsiRoute,
    permission: ['deleted']
  },
  {
    parent: '/calendario',
    path: '/calendario/:id',
    component: DettaglioCorsoRoute,
  },

  {
    title: 'Settori',
    icon: 'cluster',
    key: 'ftr_settori',
    path: '/settore',
    exact: true,
    component: SettoreRoute,
    permission: ['adminPermissions']
  },

  {
    title: 'Repertorio delle qualifiche',
    icon: 'cluster',
    key: 'ftr_repertorio',
    path: '/repertorio/qualifiche',
    exact: true,
    component: QualificheProfessionaliRoute,
    permission: ['deleted']
  },
  {
    parent: '/repertorio/qualifiche',
    path: '/repertorio/qualifiche/:id/storico/:idStorico',
    component: DettaglioStoricoQualificaProfessionaleRoute,
  },
  {
    parent: '/repertorio/qualifiche',
    path: '/repertorio/qualifiche/nuova',
    component: DettagliQualificaProfessionaleRoute,
  },
  {
    parent: '/repertorio/qualifiche',
    path: '/repertorio/qualifiche/:id',
    component: DettagliQualificaProfessionaleRoute,
  },

  {
    title: 'Richieste Repertorio',
    icon: 'solution',
    key: 'ftr_richieste_repertorio',
    path: '/repertorio/richieste',
    exact: true,
    component: TabellaRichiesteRepertorioRoute,
    permission: ['delete']
  },
  {
    parent: '/repertorio',
    path: '/repertorio/richieste/:id_repertorio',
    component: RichiestaRepertorioRoute,
  },

  {
    title: 'Richieste Ruolo',
    icon: 'solution',
    key: 'ftr_richieste_ruolo',
    path: '/ruoli/richieste',
    exact: true,
    component: TabellaRichiestaRuoloRoute,
    permission: ['deleted']
  },
  {
    parent: '/ruoli/richieste',
    path: '/ruoli/richieste/:id_ruolo',
    component: RichiestaRuoloRoute,
  },
  {
    title: 'Stati Esteri',
    icon: 'cluster',
    key: 'ftr_stati esteri',
    path: '/stati-esteri',
    exact: true,
    component: StatiEsteriRoute,
    permission: ['manageStatiEsteri']
  },
  {
    title: 'Comuni',
    icon: 'cluster',
    key: 'ftr_stati esteri',
    path: '/comuni',
    exact: true,
    component: ComuniRoute,
    permission: ['deleted']
  },
  {
    title: 'Allegati richiesta di accreditamento',
    icon: 'cluster',
    key: 'afc_progetti',
    path: '/allegati',
    exact: true,
    component: AllegatiRoute,
    permission: ['adminPermissions']
  },
  {
    parent: '/allegati',
    path: '/allegati/:id',
    component: DettaglioAllegatoRoute,
  },
  {
    title: (props) => checkPermissions.hasPermission(props.UserStore.user, ['entePermissions']) ? 'Provvedimenti' : 'Provvedimenti', /* si vabbè! tanto poi diversificheranno le voci di menù */
    icon: 'bars',
    key: 'acc_req',
    path: '/provedimenti/lista',
    exact: true,
    component: ProvvedimentiEnteRoute,
    permission: ['dirigentePermissions', 'HelpdeskPermissions', 'entePermissions', 'ListIspettore']
  },
];

/**
 * Definizione route applicazione
 *
 * Impostazione del layout principale, se utente loggato mostra routes applicativo, in alternativa le routes per login e reset password
 */
class AppRoutes extends Component {
  state = {
    request_in_progress: false
  };

  constructor(props) {
    super(props);

    this.props.history.listen(() => {
      notification.destroy()
    });
  }

  componentDidMount() {

    UtilServices.stub((res) => {
      this
        .props
        .dispatch({
          type: 'SET_GLOBAL_STORE_STUB',
          obj: res.data.data
        });
    })


    if (localStorage.getItem('token')) {

      let tkn = localStorage.getItem('token');
      let decoded = jwtDecode(tkn);

      if (moment() > moment(decoded.exp, 'X')) {

        localStorage.removeItem('token')
        this
          .props
          .dispatch({
            type: 'SET_USER_STORE',
            obj: {
              initialized: true
            }
          });

      } else {


        UserServices.profile(response => {
          this
            .props
            .dispatch({
              type: 'SET_USER_STORE',
              obj: {
                jwt: localStorage.getItem('token'),
                loggedIn: true,
                user: response.data.data,
                initialized: true
              }
            });

        }, () => {
          this.logout();
          //console.log('errore app routes se localstorage')

        })

      }

    } else {

      this
        .props
        .dispatch({
          type: 'SET_USER_STORE',
          obj: {
            initialized: true
          }
        });

    }
    //LoadingServicesUtils(this.handleRequestStatusChange);
    //this.checkLogin();
  }

  logout() {
    AuthServices.logout(() => {
      this.closeToken();
    }, () => this.closeToken())
  }

  closeToken() {
    localStorage.removeItem('token')
    this
      .props
      .dispatch({
        type: 'SET_USER_STORE',
        obj: {
          initialized: true
        }
      });
  }

  checkLogin() {

  }

  /**
   * Ritorna la costante del linguaggio selezionato
   * @return {object} Linguaggio selezionato o itIt di default
   *
   * @public
   */
  returnLanguage() {
    switch (this.props.GlobalStore.language) {
      case 'it_IT':
        return itIt;
      case 'en_GB':
        return enGb;
      default:
        return itIt;
    }
  }

  render() {

    return this.props.UserStore.initialized ? <ConfigProvider locale={itIt}>
      <div>
        <Switch>
          <Route exact path="/" component={Dashboard} />

          {
            ROUTES.map((route) => (
              <Route {...route} key={route.key || route.path} />
            ))
          }

          <Route exact path="/login" component={AccessLogin} />
          {/*<Route exact path="/login-user-pwd" component={LoginUserPwd} />*/}
          <Route exact path="/samlSsoResponse" component={samlSsoResponse} />
          <Route exact path="/openIdConnectResponse" component={openIdConnectResponse} />
          <Route exact path="/spid/acs" component={SpidAcs} />
          <Route exact path="/contatti" component={ContattiPage} />
          <Route exact path="/documenti-utili" component={DocumentiUtili} />
          <Route exact path="/privacy" component={PrivacyPage} />
          <Route exact path="/note-legali" component={NoteLegaliPage} />
          {/*<Route exact path="/recupera" component={AccessAskReset} />*/}
          <Route exact path="/reset/:token" component={AccessReset} />
          <Route exact path="/cambia-password-temporanea" component={AccessChangeTempPassword} />
          <Route exact path="/richiesta-accreditamento/:id/:pwd" component={AccreditamentoPdfRequest} />
          <Route exact path="/accreditamento" component={AccreditamentoRequest} />
          <Route exact path="/learner" component={LearnerRequest} />
          <Route exact path="/utente-generico" component={RegistrazioneUtenteGenerico} />

          <Route exact path="/accreditamento/:id_accreditamento/ispezione/:id/verbale-ispezione/:id_verbale" component={VerbaleIspezione} />
          <Route exact path="/accreditamento/:id_accreditamento/nomina/:id" component={Nomina} />

          <Route exact path="/notifiche" component={Notifiche} />

          <Route component={() => <Exception
            type="404"
            desc="La risorsa richiesta non è disponibile"
            backText="Torna alla home"
          />} />
        </Switch>
      </div>
    </ConfigProvider> : <div></div>

  }
}

const AppRoutesExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(AppRoutes);

export default withRouter(AppRoutesExport);
