import React, { Component } from 'react';
import {connect} from 'react-redux';


import { Button, Table, Tooltip, Popconfirm,
Modal } from 'antd';

import format from '@common/utils/formatters/_';


import InfrastrutturaServices from '@accreditamento/services/Infrastruttura';
import CreateAula from '@accreditamento/forms/completeRequest/infrastruttura/aule/Create';
import UpdateAula from '@accreditamento/forms/completeRequest/infrastruttura/aule/Update';

const getTipoAula = (component, record) => {
  try {
    let type = format.replaceStub(record.id_tab_tipo_aula, 'tipo_aula', component.props.GlobalStore)

    return type;
  } catch(e) {
    return 'errore'
  }
}

const columns = (component) => {
  return [{
        title: 'Nome',
        dataIndex: 'nome_aula',
        render: (text, record) => (<span>{
          record.nome_aula
        }
        </span>),
    },
    {
        title: 'Tipologia',
        dataIndex: 'tipologia',
        render: (text, record) => (<span>{
          getTipoAula(component, record)
        }
        </span>),
    }, {
        title: 'Metri quadrati',
        dataIndex: 'metri_quadrati',
        //key: 'quantita',
        render: (text, record) => (<span>{record.metri_quadrati}</span>)
    }, {
        title: 'Numero PC',
        dataIndex: 'numero_pc',
        //key: 'mesi_disponibilita',
        render: (text, record) => (<span>{record.numero_pc}</span>)

    }, {
        title: 'Numero Allievi',
        dataIndex: 'numero_allievi',
        //key: 'mesi_disponibilita',
        render: (text, record) => (<span>{record.numero_allievi}</span>)
    },
    {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
            <div>
                <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                  <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.editAula(record)} />
                </Tooltip>
                <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questa aula?"
                onConfirm={() => component.deleteAula(record.id)} okText="Si" cancelText="No">
                  <Button disabled={component.props.readOnly /* INIB CANCELLAZIONE || component.props.accreditamento.stato != 1*/} loading={component.state.deleting_id === record.id} className="m2w" size="small" shape="circle" icon="delete" />
                </Popconfirm>
            </div>
        ),
    }]
}

class Aule extends Component {

  state = {
    loading: false,
    aule: [],
    show_add: false,
    adding: false,
    show_edit: false,
    editing: false,
    editing_element: null,
    deleting: false,
    deleting_id: null
  };

  componentDidMount() {
        this.setState({aule: this.props.sede.aule})
  }

  createAula(data){
    if(!this.state.adding) {

      this.setState({loading: true, adding: true});

      InfrastrutturaServices.addAula( this.props.accreditamento.id, this.props.sede.enteSede.id, data, (res) => {
        if(this.props.onAdd) this.props.onAdd(res.data.data);
        this.setState({loading: false, adding: false, show_add: false, aule: [...this.state.aule, res.data.data] })
      }, () => this.setState({adding: false, loading: false}) )

    }
  }

  editAula(element) {
    this.setState({
      show_edit: true,
      editing_element: element
    })
  }

  updateAula(data){
    if(!this.state.editing) {

      this.setState({loading: true, editing: true});

      InfrastrutturaServices.editAula( this.props.accreditamento.id, this.props.sede.enteSede.id, this.state.editing_element.id, data, (res) => {
          if(this.props.onEdit) this.props.onEdit(res.data.data);
          this.setState({loading: false, editing: false, show_edit: false, aule: this.state.aule.map(el => {
            return (el.id === this.state.editing_element.id) ? res.data.data : el
          })
        })
      }, () => this.setState({editing: false, loading: false}) )

    }
  }

  deleteAula(id) {
    if(!this.state.deleting) {

      this.setState({loading: true, deleting: true, deleting_id: id});
      InfrastrutturaServices.deleteAula( this.props.accreditamento.id, this.props.sede.enteSede.id, id, () => {
        if(this.props.onDelete) this.props.onDelete(id);
        this.setState({loading: false, deleting: false, deleting_id: null, aule: this.state.aule.filter(el=>el.id !== id)})
      }, () => this.setState({loading: false, deleting: false, deleting_id: null }) )

    }

  }

  render() {
    return (
      <div>
        <Table
        columns={columns(this)}
        dataSource={this.state.aule}
        rowKey={(record)=>record.id}
        />

        <Button
          disabled={this.props.readOnly}
          style={{marginTop: 16}}
          onClick={()=>this.setState({show_add: !this.state.show_add})}
          type='primary'
        >
          Inserisci una nuova tipologia
        </Button>
        <Modal
          visible={this.state.show_add}
          onOk={() => this.setState({show_add: false})}
          okButtonProps={{style: {display: 'none'}}}
          width={500}
          destroyOnClose={true}
          onCancel={()=>this.setState({show_add: false})}
        >
          <CreateAula
            loading={this.state.adding}
            GlobalStore={this.props.GlobalStore}
            accreditamento={this.props.accreditamento}
            sede={this.props.sede}
            onSubmit={(data)=>this.createAula(data)}
            readOnly={this.props.readOnly}
          />
        </Modal>
        <Modal
          visible={this.state.show_edit}
          onOk={() => this.setState({show_edit: false})}
          okButtonProps={{style: {display: 'none'}}}
          width={500}
          destroyOnClose={true}
          onCancel={()=>this.setState({show_edit: false})}
        >
          <UpdateAula
            loading={this.state.editing}
            GlobalStore={this.props.GlobalStore}
            sede={this.props.sede}
            values={this.state.editing_element}
            accreditamento={this.props.accreditamento}
            onSubmit={(data)=>this.updateAula(data)}
            readOnly={this.props.readOnly}
          />
        </Modal>
      </div>
    );
  }
}
export default connect(({GlobalStore})=>({GlobalStore}))(Aule);