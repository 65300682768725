import React, { Component } from 'react';

import './App.css';

import firefox from './assets/firefox.png';
import chrome from './assets/chrome.png';

class IEApp extends Component {


  render() {
    return <div style={{width: 500, maxWidth: '90%', textAlign: 'center', margin: '20vh auto'}}>
      <h1 style={{textTransform: 'uppercase'}}>Browser non supportato</h1>
      <p>Per un corretto funzionamento dell&apos;applicativo utilizza uno dei browser seguenti, cliccando sull&apos;immagine sarai indirizzato al sito web di riferimento</p>
      <p>
        <a href="https://www.google.com/intl/it/chrome/"><img style={{width: 110, margin: 40}} src={chrome} alt=""/></a>
        <a href="https://www.mozilla.org/it/firefox/new/"><img style={{width: 110, margin: 40}} src={firefox} alt=""/></a>
      </p>
    </div>
  }

}

export default IEApp;
