import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Popconfirm, message, Modal } from 'antd';

import ModelServices from '@accreditamento/services/Ispezione';
import CommonForm from '@common/components/form';


const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};



class IspezioneControdeduzioniSend extends Component {

  state = {
    loaded: false,
    loading: false,
    feedback: 0, // 0=si,1=no
  }




  render() {
    return <div style={{marginBottom: 16}}>
      <strong>Vuoi inviare delle controdeduzioni?</strong>
        <Button
        size="small"
        ghost
        loading={this.state.loading} disabled={this.state.loading}
        className="m5h" type="primary"
        style={{marginLeft: 8}}
        onClick={()=>this.setState({open_modal_note: true})}
        >Si</Button>
        <Popconfirm
        placement="leftTop" title="Sicuro di non voler inviare delle controdeduzioni?" onConfirm={() => {
          if(!this.state.loading) {
            this.setState({loading: true}, () => {
              ModelServices.addControdeduzioni(
                this.props.accreditamento.id,
                this.props.ispezione.id,
                {feedback: 0},
                ()=> {
                  this.setState({loading: false})
                  this.props.refreshAccreditamento()
                },
                () => this.setState({loading: false})
              )
            })
          }
        }} okText="Si" cancelText="No">
          <Button
          size="small"
          loading={this.state.loading} disabled={this.state.loading}
          className="m5h" type="primary"
          style={{marginLeft: 4}}
          >No</Button>
        </Popconfirm>
        <Modal
          title="Inserisci note"
          visible={this.state.open_modal_note}
          confirmLoading={this.state.loading}
          onOk={async () => {
            let _note = this.formSelComIsp.props.form.getFieldsValue()['note'];

            if(!_note || _note === '') {
              message.error('Inserisci delle note');
              return;
            }

            if(!this.state.loading) {
              this.setState({loading: true}, () => {
                ModelServices.addControdeduzioni(
                  this.props.accreditamento.id,
                  this.props.ispezione.id,
                  {feedback: 1, note: _note },
                  ()=> {
                    this.setState({loading: false, open_modal_note: false})
                    this.props.refresh()
                  },
                  () => this.setState({loading: false })
                )
              })
            }

          }}

          onCancel={() => this.setState({ open_modal_note: false })}
        >
          <CommonForm
            wrappedComponentRef={inst => (this.formSelComIsp = inst)}
            loading={this.state.loading || false}
            form_model={formSelLayout}
            values={{

            }}
          />
        </Modal>
    </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(IspezioneControdeduzioniSend);
/**
 * Nasce quando terminano le compilazioni dei verbali, max giorni da inizio specificato nel file accreditamento-properties.php, counter_arguement_days (default 30 giorni)
 */