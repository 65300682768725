import React, {Component} from 'react';
import {connect} from "react-redux";

import {Icon} from 'antd';
import CommonForm from '@common/components/form';

import ComuniService from '@common/services/locations/Comuni';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Via',
              type_string: 'text', // field type
              name: 'via',
              props: {
                size: 'large',
                prefix: <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 4,
          items: [
            {
              type: 'input',
              label: 'N',
              type_string: 'text', // field type
              name: 'ncivico',
              props: {
                size: 'large',
                placeholder: 'N.'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 8,
          items: [
            {
              type: 'select',
              label: 'Tipo',
              name: 'type',
              props: {
                size: 'large',
                style: {width: '100%'}
              },
              optionsFunc: 'getAddressType',
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Nazione',
              name: 'stato',
              props: {
                size: 'large',
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              options_props: 'nazioni',
              onChange: 'selectedNazione',
            },
          ],
        },
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Cap',
              type_string: 'text', // field type
              name: 'cap',
              props: {
                size: 'large',
                placeholder: 'Cap',
                maxLength: 5
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  pattern: '[0-9]{5,5}',
                  message: 'Cap non valido'
                }
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'stato',
                  operator: '==',
                  value: 1,
                },
              ]
            }
          ]
        },
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Cap',
              type_string: 'text', // field type
              name: 'cap',
              props: {
                size: 'large',
                placeholder: 'Cap',
                maxLength: 10
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  pattern: '[0-9a-zA-Z]{4,10}',
                  message: 'Cap non valido'
                }
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'stato',
                  operator: '!=',
                  value: 1,
                },
              ]
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Provincia',
              name: 'provincia',
              props: {
                size: 'large',
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'stato',
                  operator: '==',
                  value: 1,
                }
              ],
              options_props: 'province',
              onChange: 'selectedProvincia'
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Comune',
              name: 'comune',
              props: {
                size: 'large',
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              validations: [
                {
                  type: 'requiredIf',
                  error_message: 'Campo obbligatorio',
                  field: 'stato',
                  operator: '==',
                  value: 1,
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'provincia',
                  operator: '!=',
                  value: null,
                },
                {
                  type: 'field_validation',
                  field: 'stato',
                  operator: '==',
                  value: 1,
                }
              ],
              options_props: 'comuni'
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              name: 'submit',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            }
          ]
        }
      ]
    },
  ],
  onSubmit: 'submitted',
};


class IndirizzoForm extends Component {

  state = {
    loading: false,
    comuni: [],
    province: []
  };

  confirm(data) {
    this.props.submit(data);
  }

  getAddressType() {
    return (this.props.model?.conAnagraficaIndirizzi && this.props.model?.conAnagraficaIndirizzi.find(el => parseInt(el.type) === 0 || parseInt(el.type) === 2)) ?
      [
        {value: "1", label: "Domicilio"}
      ]
    : [
        {value: "0", label: "Residenza"},
        {value: "1", label: "Domicilio"},
        {value: "2", label: "Residenza/domicilio"}
      ]
  }

  getValues() {
    return this.props.values || {};
  }

  reset() {
    this.formRef.props.form.resetFields();
  }

  render () {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout}
          values={this.getValues()}
          submitted={(dati) => this.confirm(dati)}

          getAddressType={() => this.getAddressType()}

          nazioni={this.props.GlobalStore.stubs.nazione}
          province={this.props.GlobalStore.stubs.provincia}

          selectedProvincia={data => {
            //console.log (this.formRef.props.form);

            this.setState({
              selected_provincia: data,
              comuni: []
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune: null });
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni: res.data.data || []
                  }, () => {
                    this.formRef.props.form.setFieldsValue({ comune: null });
                  });
                },
                err => null
              );
            });
          }}

          selectedNazione={data => {
            this.setState({
              comuni: [],
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune: null, provincia: null });
            });
          }}

          comuni={this.state.comuni.map(e => {
            return {
              key: 'residenza_comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}

        />
      </div>
    );
  }
}

export default connect(({GlobalStore})=>({GlobalStore}), null, null, { withRef: true })(IndirizzoForm);