import React from 'react';
import { connect } from "react-redux";

import checkPermissions from '../utils/check/permissions';

export default (permission) => (WrappedComponent) => {
  return connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore })) (class extends React.Component {

      verifyPermissions() {
        return checkPermissions.hasPermission(this.props.UserStore.user, permission);
      }

      render() {
        return !this.verifyPermissions() ? null : <WrappedComponent {...this.props} />
      }

    })
};

