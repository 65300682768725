import { Spin, Tabs } from "antd";
import React, { Component } from "react";
import format from '@common/utils/formatters/_';
import AnagraficaServices from "../services/Anagrafica";
import ContattiComponent from './Contatti';
import IndirizziComponent from './Indirizzi';
import InfoIspettore from "./InfoIspettore";
import SpinnerLoading from "@common/components/SpinnerLoading";
import userStatic from "@common/utils/static/user";

const TabPane = Tabs.TabPane;

class UpdateAnagrafica extends Component {
  state = {
    loading: false,
    model: {
      nome: '',
      cognome: '',
      nato_a: '',
      nato_il: null,
      conAnagraficaIndirizzo: []
    }
  };

  componentDidMount() {
    this.init(this.props)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.id !== this.props.id) this.init(newProps)
  }

  init(props) {
    if (props.anagrafica)
      this.setState({ model: props.anagrafica });

    if (!this.state.loading) {
      this.setState({ loading: true });

      AnagraficaServices.view({
        expand: 'contatti,conAnagraficaIndirizzi,conAnagraficaIndirizzi.indirizzo'},
        props.id, (res) => {
        this.setState({model: res.data.data, loading: false})
      } )

    }
  }

  update(obj) {
    this.setState({ model: { ...this.state.model, ...obj } });
  }

  render() {
    return (
      <div style={{opacity: this.state.loading ? 0.5 : 1}}>
        {
          this.state.loading ? <Spin /> : null
        }
        <div style={{textAlign: 'center'}}>

          <h3>{this.state.model.nome + " " + this.state.model.cognome}</h3>
          {this.state.model.matricola ? <h4>matricola: {this.state.model.matricola}</h4> : null}
          {this.state.model.valido_dal ? <h4>valido dal: {format.formatDate(this.state.model.valido_dal, 'DD/MM/YYYY')}</h4> : null}
          <p>{format.formatDate(this.state.model.nato_il, 'DD/MM/YYYY') + " " + this.state.model.nato_a}</p>
          <div style={{ textAlign: 'left' }}>
            <Tabs size="large" defaultActiveKey="1" onChange={null}>
              <TabPane tab="Contatti" key="1">
                {this.state.loading && <SpinnerLoading />}
                <ContattiComponent stubs={this.state.stubs}
                  UserStore={this.props.UserStore}
                  model={this.state.model}
                  onUpdate={(obj) => this.update(obj)}
                />
              </TabPane>
              <TabPane tab="Indirizzi" key="2">
                {this.state.loading && <SpinnerLoading />}
                <IndirizziComponent stubs={this.state.stubs}
                  UserStore={this.props.UserStore}
                  model={this.state.model}
                  onUpdate={(obj) => this.update(obj)}
                />
              </TabPane>
              { this.props.role.find(el => userStatic.userExtraInfo.indexOf(el.name) !== -1) &&
                <TabPane tab="Info" key="3">
                  <InfoIspettore
                    {...this.props}
                    UserStore={this.props.UserStore}
                    model={this.state.model}
                    onUpdate={(obj) => this.props.update(obj)}
                  />
                </TabPane>
              }
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateAnagrafica;
