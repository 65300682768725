import React, { Component } from 'react';

import { Button, Card, Drawer, Popconfirm, List, message } from 'antd';

import AnagraficaServices from '../services/Anagrafica';
import ModelForm from '../forms/Contatto';
import checkPermissions from '@common/utils/check/permissions';

class Contatti extends Component {

  state = {
    loading: false,
    element_values: {}, // valori elemento da modificare
    open_drawer: false,
    drawer_title: "Aggiungi record"
  }

  update() {
    //this.props.onUpdate({documenti})
  }

  create() {
    this.setState({ element_values: {}, drawer_title: 'Aggiungi un record', open_drawer: true });
  }

  edit(item) {
    this.setState({ element_values: { ...item }, drawer_title: 'Aggiorna record',  open_drawer: true });
  }

  addContatto(data) {
    this.setState({ loading: true })
    AnagraficaServices.addContatto(data, this.props.model.id, (res) => {
      message.success("Contatto inserito con successo");
      this.props.onUpdate({
        ...this.props.model,
        contatti: [
          ...this.props.model.contatti,
          res.data.data
        ]
      })
      this.setState({ open_drawer: false, loading: false });
    }, () => this.setState({ loading: false }))
  }

  editContatto(item_id, data) {
    this.setState({ loading: true })
    AnagraficaServices.editContatto(item_id, data, this.props.model.id, (res) => {
      message.success("Contatto aggiornato con successo");
      this.props.onUpdate({
        ...this.props.model,
        contatti: [
          res.data.data
        ]
      })
      this.setState({ open_drawer: false, loading: false });
    }, () => this.setState({ loading: false }))
  }

  deleteContatto(id) {
    this.setState({ loading: true })
    AnagraficaServices.deleteContatto(this.props.model.id, id, () => {
      message.success("Contatto eliminato con successo");
      this.setState({ loading: false })
      this.props.onUpdate({
        ...this.props.model,
        contatti: this.props.model.contatti.filter(els => els.id !== id)
      })
    }, () => this.setState({ loading: false }))
  }

  getSource() {
    try {
      return this.props.model.contatti
    } catch (e) {
      return []
    }
  }

  getItemActions(item) {
    return (checkPermissions.hasPermission(this.props.UserStore.user, ['EditAnagrafica', 'adminPermissions']))
      ? [
        <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere questo elemento?" onConfirm={() => this.deleteContatto(item.id)} okText="Si" cancelText="No">
          <Button type="danger" size="small" ghost>Elimina</Button>
        </Popconfirm>,
        <Button type="primary" size="small" onClick={() => this.edit(item)} ghost>Modifica</Button>
      ]
      : null
  }

  render() {

    return (
      <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>
        {(this.props.model.contatti && this.props.model.contatti.length === 0 && checkPermissions.hasPermission(this.props.UserStore.user, ['EditAnagrafica', 'adminPermissions'])) ?
          <Button type="primary" ghost size="small" style={{ marginBottom: 12 }} onClick={() => this.create()}>Aggiungi</Button>
          : null
        }
        <List
          dataSource={this.getSource()}
          renderItem={item => (
            <Card
              style={{ marginTop: 1 }}
              bordered={false}
              className="small-pad"
            >
              <List.Item
                actions={this.getItemActions(item)}
              >

                <div>
                  <p><strong>Telefono: </strong> {item.telefono || ""} </p>
                  <p><strong>Cellulare: </strong> {item.cellulare || ""}</p>
                  {/* https://cb.schema31.it/cb/issue/446340 */}
                  {/* <p><strong>Fax: </strong> {item.fax || ""}</p> */}
                  <p><strong>Pec: </strong> {item.pec || ""}</p>
                  <p><strong>Email: </strong> {item.email || ""}</p>
                  {/* https://cb.schema31.it/cb/issue/446340 */}
                  {/* <p><strong>Sito web: </strong> {item.sitoweb || ""}</p> */}
                </div>
              </List.Item>
            </Card>
          )}
        />
        <Drawer
          title={this.state.drawer_title}
          width={720}
          placement="right"
          onClose={() => this.setState({ open_drawer: false })}
          maskClosable={true}
          visible={this.state.open_drawer}
          style={{
            overflow: 'auto',
          }}
        >
          <ModelForm
            loading={this.state.loading}
            values={this.state.element_values}
            submit={(data) => {
              (!this.state.element_values.id) ?
                this.addContatto(data) :
                this.editContatto(this.state.element_values.id, data)
            }} />
        </Drawer>
      </div>
    );
  }
}
export default Contatti;
