import React, { Component } from 'react';
import { Card, Row, Col } from 'antd';
import { Link } from 'react-router-dom'
import Wrapper from '@common/decorators/Wrapper';
import LoginForm from '../forms/Login';
import AuthServices from '../services/Auth';
import '../script/actions';
import '../../../spid_css/spid.css'
@Wrapper({ route: 'Login', hasSidebar: false, hasHeader: true, hasFooter: true })
class LoginUserPwd extends Component {

  state = {
    loading: false,
    mounted: false
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  componentWillUnmount() {
    this.setState({ mounted: false })
  }

  async tryLogin(data) {
    if (this.state.mounted) this.setState({ loading: true });
    AuthServices.login({ username: data.username, password: data.password }, async (response_user) => {
      // login andato a buon fine, posso impostare utente
            
      if (this.state.mounted) this.setState({ loading: false });

      localStorage.setItem('token', response_user.data.data.token)
      await this
        .props
        .dispatch({
          type: 'SET_USER_STORE',
          obj: {
            jwt: response_user.data.data.token,
            loggedIn: true,
            user: response_user.data.data.user,
            role: response_user.data.data.user.role.name
          }
        });

      //this.props.history.push('dashboard');

      console.warn('TP.1 - Token login: ', response_user.data.data.token)
      
    }, (e) => {
      if (this.state.mounted) this.setState({ loading: false });
    })
  }

  render() {
    console.warn("process.env", process.env);

    return (

      <Row type="flex" justify="center">
        <Col lg={8} md={24} xs={24} sm={24} style={{ margin: '40px auto' }}>
          <h3 className="sectionTitle" style={{ textAlign: 'center' }}>LOGIN</h3>
          <Card
            className="shadow"
            bordered={false}
            style={{ marginTop: 20 }}
          >

            <LoginForm loading={this.state.loading}
              gotocomplete={() => this.props.history.push('/accreditamento/completa-richiesta')}
              accreditate={() => this.props.history.push('/accreditamento')}
              onSubmit={(data) => this.tryLogin(data)} />
            <Link to="/login" className="login-form-forgot" style={{ float: 'right' }}>Annulla</Link>
            <br/>
            <Link to="/recupera" className="login-form-forgot" style={{ float: 'right' }}>Password dimenticata</Link>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default LoginUserPwd;