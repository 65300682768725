import { Table } from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import React, { Component } from 'react';

let dragingIndex = -1;

class BodyRow extends Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

const columns = [
  {
    title: 'Campo',
    dataIndex: 'name',
    key: 'name',
  },
  /*
  {
    title: 'Filtro',
    dataIndex: '',
    key: 'filter',
    render: (text, record) => (
        <Input ></Input>
      ),
  },
  */
];

export default class ExportTable extends React.Component {

  state = {
    data: [
      {
        key: 'id_ente',
        name: 'ID Ente',
      },
      {
        key: 'denominazione',
        name: 'Denominazione Ente',
      },

      {
        key: 'stato',
        name: 'Stato',
      },

      //https://cb.schema31.it/cb/issue/446627
      {
        key: 'rappresentante_legale',
        name: 'Rappresentante Legale',
      },
      {
        key: 'responsabile_processo_direzione',
        name: 'Responsabile del processo di direzione',
      },
      {
        key: 'responsabile_processo_progettazione',
        name: 'Responsabile progettazione ed erogazione servizi formativi',
      },
      {
        key: 'indirizzo_sede_legale',
        name: 'Indirizzo sede legale',
      },
      {
        key: 'comune_sede_legale',
        name: 'Comune sede legale',
      },
      {
        key: 'provincia_sede_legale',
        name: 'Provincia sede legale',
      },      
      {
        key: 'tipologia_sezione',
        name: 'Sezioni',
      },
      {
        key: 'indirizzo_sede_formativa',
        name: 'Indirizzo sede operativa',
      },
      {
        key: 'comune_sede_formativa',
        name: 'Comune sede operativa',
      },
      {
        key: 'provincia_sede_formativa',
        name: 'Provincia sede operativa',
      },
      {
        key: 'spazi',
        name: 'Spazi',
      },
      {
        key: 'numero_decreto',
        name: 'Numero decreto',
      },
      {
        key: 'data_decreto',
        name: 'Data decreto',
      },
      {
        key: 'tipologia',
        name: 'Tipologia',
      },
      {
        name: 'Indirizzo PEC / Email',
        key: 'email',
      },
      {
        name: 'Telefono',
        key: 'telefono',
      },
      {
        name: 'Sito web',
        key: 'sitoweb',
      },
      {
        key: 'note',
        name: 'Note',
      },
    ],
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
  };

  onSelectChange = selectedRowKeys => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  getSelection = () => {
    return this.state.data.filter(i => this.state.selectedRowKeys.includes(i.key)).map(i => i.key);
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    );
  };

  render() {
    const { /*loading,*/ selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    //const hasSelected = selectedRowKeys.length > 0;
    return (
      <DndProvider backend={HTML5Backend}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={this.state.data}
          components={this.components}
          pagination={false}
          onRow={(record, index) => ({
            index,
            moveRow: this.moveRow,
          })}
        />
      </DndProvider>
    );
  }
}
