import React, { Component } from 'react';
import CommonForm from '@common/components/form';

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [

            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Denominazione azienda',
                                name: 'name',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Rappresentante legale',
                                name: 'legalRepresentative',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Indirizzo',
                                name: 'address',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Oggetto sociale',
                                name: 'corporatePurpose',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'integer',
                                label: 'N° allievi',
                                name: 'studentNumber',
                                props: {
                                    min: 0
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'integer',
                                label: 'Ore totali di stage',
                                name: 'totalStageHours',
                                props: {
                                    min: 0
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'btn',
                                type: 'submit',
                                text: 'Salva',
                                btn_type: 'primary'
                            },
                        ],
                    },
                ],
            }

        ],
        onSubmit: 'submitted',
    }
}

class EditAziendeStage extends Component {

    state = {
        loaded: false,
        loading: true,
        azienda: {}
    };

    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loaded: true,
            loading: false,
            azienda: props.aziendaStage
        });
    }

     submit(data) {
        if (!this.state.loading) {
            this.setState({
                loading: true,
                azienda : data });
            if(this.props.create){
                this.props.onCreate(data);
            }else{
                this.props.onUpdate(data);
            }
        }
    }

    render() {
        return (
            <div>
                <CommonForm
                    loading={this.state.loading || false}
                    form_model={formLayout(this)}
                    values={this.state.azienda}
                    readOnly={this.props.readOnly}
                    submitted={(data) => this.submit(data)}
                />
            </div>
        )
    }
}

export default EditAziendeStage;