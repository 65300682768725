import React, { Component } from 'react';
import { Icon, message, notification } from 'antd';
import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';

import AccreditamentoRequest from '../../services/Request';
import format from '@common/utils/formatters/_';
import staticEnte from '@common/utils/static/ente';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Denominazione',
              type_string: 'text', // field type
              name: 'denominazione',
              props: {
                size: 'large',
                prefix: <Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Codice fiscale',
              type_string: 'text', // field type
              name: 'cfente',
              props: {
                size: 'large',
                prefix: <Icon type="audit" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
                {
                  pattern: '^[0-9]{11,11}$',
                  message: 'Codice fiscale non valido',
                }
              ],
            }
          ]
        },
        {
          items: [
            {
              type: 'input',
              label: 'P.Iva',
              type_string: 'text', // field type
              name: 'partita_iva',
              props: {
                size: 'large',
                prefix: <Icon type="wallet" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  validator: 'requiredPiva'
                },
                {
                  pattern: '^[0-9]{11,11}$',
                  message: 'Partita iva non valida',
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Email',
              type_string: 'email', // field type
              name: 'email',
              props: {
                size: 'large',
                prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  type: 'email',
                  error_message: 'Email non valida'
                }
              ],
            },
          ]
        },
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'PEC',
              type_string: 'email', // field type
              name: 'pec',
              props: {
                size: 'large',
                prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  type: 'email',
                  error_message: 'Pec non valida'
                }
              ],
            },
          ]
        },
        {
          span: 12,
          items: [
            {
              type: 'input',
              label: 'Telefono/Cellulare',
              type_string: 'number', // field type
              name: 'telefonoente',
              props: {
                size: 'large',
                prefix: <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                  message: 'Telefono non valido'
                },
              ],
            },
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              name: 'titolo_sede_legale',
              type: 'title',
              element: 'h3',
              text: 'Sede legale',
              props: {
                style: { textAlign: 'center' },
              },
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 14,
          items: [
            {
              type: 'input',
              label: 'Via',
              type_string: 'text', // field type
              name: 'viaente',
              props: {
                size: 'large',
                prefix: <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 4,
          items: [
            {
              type: 'input',
              label: 'N',
              type_string: 'text', // field type
              name: 'civicoente',
              props: {
                size: 'large',
                placeholder: 'N.'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Cap',
              type_string: 'text', // field type
              name: 'capente',
              props: {
                size: 'large',
                placeholder: 'Cap',
                maxLength: 5
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  pattern: '[0-9]{5,5}',
                  message: 'Cap non valido'
                }
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'statoente',
                  operator: '==',
                  value: 1,
                },
              ]
            }
          ]
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Cap',
              type_string: 'text', // field type
              name: 'capente',
              props: {
                size: 'large',
                placeholder: 'Cap',
                maxLength: 10
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  pattern: '[0-9a-zA-Z]{4,10}',
                  message: 'Cap non valido'
                }
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'statoente',
                  operator: '!=',
                  value: 1,
                },
              ]
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'select',
              label: 'Nazione',
              name: 'statoente',
              props: {
                //disabled: true,
                size: 'large',
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              options_props: 'stato',
              onChange: 'selectedStatoEnte',
            },
          ],
        },
        {
          span: 8,
          items: [
            {
              type: 'select',
              label: 'Provincia',
              name: 'provinciaente',
              props: {
                size: 'large',
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'statoente',
                  operator: '==',
                  value: 1,
                },
              ],
              options_props: 'province',
              onChange: 'selectedResidenzaProvincia'
            }
          ]
        },
        {
          span: 8,
          items: [
            {
              type: 'select',
              label: 'Comune',
              name: 'comuneente',
              props: {
                size: 'large',
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'provinciaente',
                  operator: '!=',
                  value: null
                },
                {
                  type: 'field_validation',
                  field: 'statoente',
                  operator: '==',
                  value: 1,
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                }
              ],
              options_props: 'comuni_ente'
            },
          ],
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              name: 'titolo_sede_legale',
              type: 'title',
              element: 'h3',
              text: 'Informazioni ente',
              props: {
                style: { textAlign: 'center' },
              },
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Tipologia forma giuridica',
              name: 'fine_lucro',
              options_props: 'tipi_ente',
              onChange: 'setTipologia',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Divisione forma giuridica',
              name: 'divisione',
              options_props: 'divisioni',
              onChange: 'setDivisione',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ]
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Descrizione forma giuridica',
              name: 'id_descrizione_forma_giuridica',
              options_props: 'descrizioni',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ]
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Sito web ( Indicare URL esempio: https:// o http:// )',
              type_string: 'text', // field type
              name: 'sitoweb',
              props: {
                size: 'large',
                prefix: <Icon type="laptop" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
              ],
              showIf: [
                {
                  type: "prop_func",
                  func: "is_not_divisione_2_6",
                },
              ],
            },
          ]
        }
      ]
    },
    /*
    {
      cols: [
        {
          items: [
            {
              name: 'testo_extra',
              type: 'text',
              element: 'p',
              text: `Il soggetto richiedente è uno tra i seguenti:
              università statali e non statali legalmente riconosciute,
              scuole ed istituti professionali statali e paritari, centri di formazione pubblici`,
              props: {
                style: { textAlign: 'center' },
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'fine_lucro',
                  operator: '==',
                  value: staticEnte.lucro.no,
                },
              ]
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: '',
              name: 'universita_scuole',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                style: { textAlign: 'center' },
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'fine_lucro',
                  operator: '==',
                  value: staticEnte.lucro.no,
                },
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        }
      ],
    }*/
  ],
  onSubmit: 'submitted'
}


class OrganizationForm extends Component {

  state = {
    loading: false,
    searching_comuni_residenza: false,
    selected_residenza_provincia: null,
    comuni_ente: [],
    fine_lucro: null,
    divisione: null,
    fileList: [],
  }



  componentDidMount() {
    // carico le province
    //console.log(this.props);
    if (this.props.values.comuneente) {
      ComuniService.all(
        { id_provincia: this.props.values.provinciaente },
        res => {
          this.setState({
            comuni_ente: res.data.data || []
          },() => {
            this.formRef.props.form.setFieldsValue({ comuneente: this.props.values.comuneente });
          });
        },
        () => null
      );
    }

    this.setState({
      fine_lucro: this.props.values.fine_lucro || null,
      divisione: this.props.values.divisione || null
    })

  }



  async clientValidation() {
    return new Promise( (resolve) => {

      this.formRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });

    });
  }

  async validate() {

    let cl_val = await this.clientValidation();
    if(!cl_val) return false;


    let values = this.getValues( );
    if(!values) return false;

    return new Promise((resolve) => {

      console.error("come in 10")
      AccreditamentoRequest.validateOrganization(format.replaceFormDatesOut(['nato_il'], values), () => {
        notification.destroy();
        console.error("come in 11",true)
        resolve(true);
        
      }, () => {
        console.error("come in 13",false)
        resolve(false);
        
      });

    });
  }

  getValues() {
    let valori_form = this.formRef.props.form.getFieldsValue();
    //valori_form.statoente = 1;
    return valori_form;
  }

  submitted(data) {
    this.props.onSubmit(data);
  }

  getDivisioni() {

    try {
      return this.props.GlobalStore.stubs.divisione_forma_giuridica.filter(el => parseInt(el.fine_lucro) === parseInt(this.state.fine_lucro))
    } catch(e) {
      return []
    }
  }

  getDescrizioni() {

    try {
      return this.props.GlobalStore.stubs.descrizione_forma_giuridica.filter(el => parseInt(el.id_divisione) === parseInt(this.state.divisione))
    } catch(e) {
      return []
    }
  }

  selectedTipologia(value) {
    
    this.setState({divisione: null})

    if(this.state.fine_lucro !== value) {
      this.formRef.props.form.setFields(
        {
          divisione: {
            value: []
          },
          id_descrizione_forma_giuridica: {
            value: []
          }
        });
    }
    this.setState({fine_lucro: value});
  }

  selectedDivisione(value) {

    if(this.state.divisione !== value) {
      this.formRef.props.form.setFields(
        {
          id_descrizione_forma_giuridica: {
            value: []
          }
        });
    }
    this.setState({divisione: value});
  }

  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={(inst) => this.formRef = inst}
          loading={this.props.loading || false}
          form_model={formLayout}
          tipi_ente={[
            {
              key: staticEnte.lucro.no,
              label: "1 - Forme disciplinate dal diritto privato",
              value: staticEnte.lucro.no
            },
            {
              key: staticEnte.lucro.si,
              label: "2 - Forme disciplinate dal diritto pubblico",
              value: staticEnte.lucro.si
            }
          ]}
          divisioni={this.getDivisioni()}
          descrizioni={this.getDescrizioni()}
          setTipologia={(value)=>this.selectedTipologia(value)}
          setDivisione={(value)=>this.selectedDivisione(value)}
          is_not_divisione_2_6={() => {
            return this.state.divisione != 15;
          }}
          values={{
            ...this.props.values
          }}

          tipi_organizzazione={this.props.tipi_organizzazione}

          province={this.props.province}
          stato={this.props.GlobalStore.stubs.nazione}

          selectedResidenzaProvincia={(data) => {
            //console.log(this.formRef.props.form)
            this.formRef.props.form.setFieldsValue({ 'comuneente': null });

            this.setState({
              selected_residenza_provincia: data,
              comuni_ente: []
            }, () => {
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni_ente: res.data.data || []
                  });
                },
                () => null
              );
            });
          }}

          comuni_ente={this.state.comuni_ente.map(e=>{
            return {
              key: 'nascita_comune_'+e.id,
              value: e.id,
              label: e.comune
            }
          })}

          selectedStatoEnte={()=>{
            this.formRef.props.form.setFieldsValue({ 'comuneente': null, 'provinciaente': null })
          }}

          requiredCf={(rule, value, cb) => {

            /*
            cfente
            partita_iva
             */
            let piva = this.formRef.props.form.getFieldsValue()['partita_iva'];
            if ((value === '' || !value) && (piva === '' || !piva)) cb("Codice fiscale o partita iva obbligatorio");
            else cb()
          }}

          requiredPiva={(rule, value, cb) => {

            /*
            cfente
            partita_iva
             */
            let cf = this.formRef.props.form.getFieldsValue()['cfente'];
            if ((value === '' || !value) && (cf === '' || !cf)) cb("Codice fiscale o partita iva obbligatorio");
            else cb()
          }}
        />

      </div>
    );
  }
}

export default OrganizationForm