import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import ProceduraService from '../../services/Procedura';
import SpinnerLoading from '@common/components/SpinnerLoading';
import { message, Drawer } from 'antd';
import SchedaFormulario from '../../components/SchedaFormulario';
import schedafinanziariaJsonDomandaStatic from '@common/utils/static/schedafinanziariaJsonDomanda';
import affidamentoStatic from '@common/utils/static/affidamento';
import eventBroker from '@common/utils/services/EventBroker';
import FileSaver from 'file-saver';

const sectionName = {
  generalData: 'Dati generali del progetto',
  relatedSubjects: 'Soggetti correlati',
  learner: 'Discenti',
  teachingProgramming: 'Programazione didattica',
  financialStatement: 'Scheda finanziaria',
  nonTrainingElements: 'Elementi non formativi',
};


const formLayout = (component, tipo) => {

  let result = component.props.GlobalStore.stubs.sezioni_formulario.filter(s => s.visibility !== 2 && s.tipoFinanziamento.some(t => t.id === tipo)).reduce((h, obj) => {
    h[obj.group] = (h[obj.group] || []).concat(obj);
    return h;
  }, {});

  let rows = Object.keys(result).flatMap((key, index) => {
    return  [
    {
      cols: [
        {
          span: 24,
          items: [
            {
              name: key,
              type: 'title',
              element: 'h4',
              text: sectionName[key],
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        ...result[key].map(s => {
          return {
            span: 24, //8
            items: [
              {
                name: s.key,
                type: 'checkbox',
                text: s.nome,
                props: {
                  style: { marginLeft: 10 },
                  //checked: s.visibility === 1 || component.isEnabled(s.key),
                  disabled: s.visibility === 1 || s.visibility === 2 || component.state.data.stato !== affidamentoStatic.stato_procedura.attivo,
                },
              }
            ]
          }
        }),
      ]
    }
  ]});
  rows.push({
      cols: [
        {
          offset: 14,
          span: 2,
          items: [
            {
              type: 'submit',
              name: 'btn_submit',
              text: 'Salva',
              props: {
                icon: 'save',
                className: 'bg-green',
              }
            },
          ],
        },
        {
          span: 4,
          items: [
            {
              type: 'button',
              name: 'btn_preview',
              text: 'Stampa formulario',
              onClick: 'onPrintPdf',
              props: {
                icon: 'file-pdf',
                disabled: false,
              }
            },
          ],
        },
        {
          span: 4,
          items: [
            {
              type: 'button',
              name: 'btn_preview',
              text: 'Anteprima formulario',
              onClick: 'onCklickAnteprima',
              props: {
                icon: 'layout',
                disabled: false,
              }
            },
          ],
        },
      ],
  });

  return {
    layout: 'vertical',
    multistep: false,
    rows: rows,
    onSubmit: 'onSubmit',
  };

};

export default class PersonalizzazioneForm extends Component {

  state = {
    open_drawer: false,
    data: null,
    loading: true,
    domanda_json : {}
  };

  constructor(props){
    super(props);
    this.loadProcedura = this.loadProcedura.bind(this);
    eventBroker.on('loadProcedura', this.loadProcedura);
  }

  componentDidMount() {
    this.load(this.props.id_procedura);
  }

  load(id) {
    ProceduraService.detail({fields: 'stato,bando.id,sezioni.id,sezioni.key,defaultSezioni.id,defaultSezioni.key', expand: 'sezioni,defaultSezioni'}, id, async (res) => {
      this.setState({
        data: res.data.data,
        loading: false,
      });
    });
  }

  loadProcedura(){
    this.setState({loading:true}, () => {
      this.load(this.props.id_procedura);
    })
  }

  isEnabled(key) {
    return this.state.data?.sezioni?.some(s => s.key === key) || this.state.data?.defaultSezioni?.some(s => s.key === key);
  }

  getValues() {
    return this.props.GlobalStore.stubs.sezioni_formulario.reduce((h, obj) => {
      h[obj.key] = this.isEnabled(obj.key);
      return h;
    }, {});
  }

  onSubmit(data) {
    if (!this.state.loading && data) {
      this.setState({ loading: true }, () => {
        let sections = Object.entries(data).filter(entry => entry[1]).map(entry => this.props.GlobalStore.stubs.sezioni_formulario.find(s => s.key === entry[0]));

        ProceduraService.update({sezioni: sections}, this.props.id_procedura, res => {
          message.success("Formulario salvato correttamente");
          this.setState({ data: {...this.state.data, sezioni : sections} /*res.data.data*/, loading: false });
        }, () => this.setState({loading:false}));
      });
    }
  }

  openDrower(data) {
    let domanda_json = {};
    if (data) {
      let sections = Object.entries(data.getFieldsValue()).filter(entry => entry[1]).map(entry => this.props.GlobalStore.stubs.sezioni_formulario.find(s => s.key === entry[0]));
      sections.forEach(s => {
        if (!domanda_json[s.group]) {
          domanda_json[s.group] = {}
        }
        domanda_json[s.group][s.key] = {}

        if(s.group === 'financialStatement'){
          domanda_json[s.group][s.key] = schedafinanziariaJsonDomandaStatic.jsonDomanda
        }
      })
      this.setState({
        domanda_json : domanda_json,
        open_drawer : true
      })
    }
  }

  printPdf(data) {
    let domanda_json = {};
    if (data) {
      let sections = Object.entries(data.getFieldsValue()).filter(entry => entry[1]).map(entry => this.props.GlobalStore.stubs.sezioni_formulario.find(s => s.key === entry[0]));
      sections.forEach(s => {
        if (!domanda_json[s.group]) {
          domanda_json[s.group] = {}
        }
        domanda_json[s.group][s.key] = {}

        if (s.group === 'financialStatement') {
          domanda_json[s.group][s.key] = schedafinanziariaJsonDomandaStatic.jsonDomanda
        }
      })

      this.setState({ loading: true }, () => {
        ProceduraService.printPdf(domanda_json, (res) => {
          const _blob = new Blob([res.data], { type: 'application/pdf' });
          const today = new Date();
          const month = today.getMonth() + 1;
          const year = today.getFullYear();
          const date = today.getDate();
          const hour = ('0' + today.getHours()).slice(-2);
          const minu = ('0' + today.getMinutes()).slice(-2);
          let nome_file = `formulario_${date}${month}${year}_${hour}${minu}.pdf`;
          FileSaver.saveAs(_blob, nome_file);
          this.setState({ loading: false });

        }, () => this.setState({ loading: false }));
      });
    }
  }


  render() {
    return (!this.state.data ? <SpinnerLoading message='Caricamento dati...' /> :
      <div className="no-margin" style={{opacity: this.state.loading ? 0.5 : 1}}>
        <CommonForm
          loading={this.state.loading || false}
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          form_model={formLayout(this, 0)}
          values={this.getValues()}
          readOnly={this.state.data.stato !== affidamentoStatic.stato_procedura.attivo}
          onSubmit={this.onSubmit.bind(this)}
          onCklickAnteprima={(data) => this.openDrower(data)}
          onPrintPdf={(data) => this.printPdf(data)}
        />

        <Drawer
          className = "scheda"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false})}
          visible={this.state.open_drawer}
          width={window.innerWidth - (window.innerWidth / 100 * 5)}
          destroyOnClose={true}
        >
          <SchedaFormulario
            {...this.props}
            close={() => this.setState({open_drawer: false })}
            isEditable={false}
            domanda_json={this.state.domanda_json}
            anteprima = {true}
            saved = {true}
          />
        </Drawer>
      </div>
    )
  }
}
