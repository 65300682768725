import React, { Component } from "react";
import CommonForm from '@common/components/form';
import { Button } from "antd";

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'registro_title',
                type: 'title',
                element: 'h4',
                text: 'Registro presenze allievi',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 3,
            items: [
              {
                type: 'text',
                text: 'Allievi presenti alla data dell’ispezione',
                name: 'titolo_allievi_presenti'
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integerwithzero',
                label: '',
                name: 'allievi_presenti',
                props: {
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      } ,
      {
        cols: [
          {
            span: 3,
            items: [
              {
                type: 'text',
                text: 'Allievi assenti alla data dell’ispezione',
                name: 'titolo_allievi_assenti'
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integerwithzero',
                label: '',
                name: 'allievi_assenti',
                props: {
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'text',
                text: 'Di cui  con assenza ingiustificata',
                name: 'titolo_allievi_assenti_ing'
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integerwithzero',
                label: '',
                name: 'allievi_assenti_ing',
                props: {
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'maxAllievi'
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'La sussistenza dei requisiti di ammissione degli allievi è stata regolarmente accertata dall’Ente Attuatore?',
                name: 'domanda.0.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.0.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.0.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'L’ammissione al corso conseguente a selezione preliminare per sovrannumero di domande di adesione?',
                name: 'domanda.1.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.1.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.1.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Il programma didattico in svolgimento è conforme a quello dichiarato?',
                name: 'domanda.2.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.2.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.2.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Sono presenti nel registro elettronico le firme giornaliere degli allievi in entrata e in uscita?',
                name: 'domanda.3.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.3.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.3.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Sono presenti nel registro elettronico l’orario delle lezioni e gli argomenti trattati giornalmente?',
                name: 'domanda.4.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.4.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.4.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Sono presenti nel registro elettronico le firme dei docenti, responsabile del corso/tutor?',
                name: 'domanda.5.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.5.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.5.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Sono presenti nel registro elettronico le assenze  a cura dell’Insegnante della prima orai in corrispondenza dei nominativi degli allievi non presentatisi per la frequenza delle lezioni?',
                name: 'domanda.6.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.6.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.6.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Le assenze degli allievi risultano giustificate entro il 5° giorno?',
                name: 'domanda.7.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.7.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.7.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Materia insegnata al momento della visita ispettiva corrisponde a quella riportata nell’orario settimanale delle lezioni?',
                name: 'domanda.8.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.8.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.8.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Gli allievi, il personale docente e non docente presenti al momento della visita ispettiva sono quelli preposti al corso?',
                name: 'domanda.9.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.9.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.9.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'In caso di variazioni queste sono state effettuate regolarmente?',
                name: 'domanda.10.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.10.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.10.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'I discenti/docenti hanno i requisiti richiesti dal corso?',
                name: 'domanda.11.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.11.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.11.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Sono presenti allievi extracomunitari?',
                name: 'domanda.12.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.12.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.12.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Se presenti allievi extracomunitari, c’è l’evidenza del test effettuato sulla conoscenza della lingua italiana?',
                name: 'domanda.13.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.13.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.13.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'È stato consegnato il materiale didattico e non individuale previsto (libri, dispense, indumenti di lavoro e di protezione personale,  ecc)?',
                name: 'domanda.14.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.14.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.14.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Il materiale è conforme al corso/ alla normativa?',
                name: 'domanda.15.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.15.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.15.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'È presente agli atti dell’Ente la denuncia INAIL contro gli infortuni sul lavoro?',
                name: 'domanda.16.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.16.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.16.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Sono presenti le dichiarazioni (in originale) del medico competente e/o i certificati medici (laddove previsto) relativi alla idoneità dei singoli allievi a poter svolgere le attività previste nel corso?',
                name: 'domanda.17.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.17.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.17.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Se lo stage e/o tirocinio formativo è stato già effettuato si ha evidenza dell’attività svolta?',
                name: 'domanda.18.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.18.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.18.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'I contratti (in originale) stipulati con il personale dipendente interno all’Ente, i docenti impegnati nel corso, allievi sono presenti in sede;',
                name: 'domanda.19.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.19.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.19.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Si ha evidenza di eventuali  test pianificati?',
                name: 'domanda.20.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.20.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.20.note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Ci sono difformità tra quanto dichiarato nel registro e quanto riscontrato in fase di verifica?',
                name: 'domanda.21.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'domanda.21.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'domanda.21.note'
              },
            ],
          },
        ],
      } ,
    ],
    onSubmit: 'onSubmit',
  };
}

/**
 * info locali:
 * aula teorica = aula corso
 * aula teorico-pratica = laboratorio
 */

class RegistroPresenze extends Component {

  state = {
    mounted: false,
    loading: false,
    custom_domanda: []
  };

  componentDidMount() {
    if(this.props.values && this.props.values.custom_domanda) this.setState({custom_domanda: this.props.values.custom_domanda})
  }

  async validate() {
    return new Promise((resolve) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getValues() {
    let obj = {
      ...this.formSelRef.props.form.getFieldsValue(),
      ...this.formSelRefCustom.props.form.getFieldsValue(),
      //custom_domanda: this.state.custom_domanda
    };
    return obj;
  }

  customformLayout() {
    let rows = [];

    if (this.state.custom_domanda) {
      this.state.custom_domanda.forEach((d, index) => {
        rows.push(
          {
            cols: [
              {
                span: 12,
                items: [
                  {
                    type: 'input',
                    input_type: 'text',
                    name: 'custom_domanda.' + index + '.text'
                  },
                ],
              },
              {
                span: 6,
                items: [
                  {
                    type: 'radio',
                    label: '',
                    name: 'custom_domanda.' + index + '.risposta',
                    option_type: 'button', // normal|button
                    // le opzioni come le selects
                    options: [
                      {
                        value: "1",
                        label: "Si"
                      },
                      {
                        value: "0",
                        label: "No"
                      }
                    ],
                    props: {
                      //style: { width: '100%' },
                    },
                    validations: [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      },
                    ],
                  }
                ],
              },
              {
                span: 6,
                items: [
                  {
                    type: 'textarea',
                    label: '',
                    name: 'custom_domanda.' + index + '.note',
                    props: {
                    },
                    validations: [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      },
                    ],
                  },
                ],
              }
            ]
          }
        )
      });
    }

    return {
      layout: 'vertical',
      multistep: false,
      rows: rows,
      onSubmit: 'onSubmit',
    }


  }

  addDomanda() {
    this.setState({
      custom_domanda: [...this.state.custom_domanda, {
        text: null,
        risposta: null,
        note: null
      }]
    })
  }

  render() {
    return <React.Fragment>
          <CommonForm
            readOnly={this.props.readOnly}
            wrappedComponentRef={inst => (this.formSelRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout(this)}
            values={this.props.values}
            onSubmit={() => null}
            tipicorsi={[]}
            maxAllievi={(rule, value, cb) => {
              //console.log('custom validator', rule, value, cb);
              if (value > this.formSelRef.props.form.getFieldValue('allievi_assenti')) {
                cb("Non possono essere più degli allievi assenti");
              } else {
                cb()
              }
            }}
          />
          <CommonForm
            readOnly={this.props.readOnly}
            wrappedComponentRef={inst => (this.formSelRefCustom = inst)}
            loading={this.state.loading || false}
            form_model={this.customformLayout()}
            values={this.props.values}
            onSubmit={() => null}
            tipicorsi={[]}
          />
          <Button
            disabled={this.props.readOnly}
            icon="plus"
            onClick={()=>this.addDomanda()}
          >
            Aggiungi domanda
          </Button>
        </React.Fragment>
  }
}


export default RegistroPresenze;