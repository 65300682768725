import BaseRequest from "./_baseRequest";


export default {
	
	stub: (cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'util/stub',
			cb,
			ecb || null,
			null
		);
	},

	scadenza_adeguamento: (cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'utility/costanti-applicativo/datenowinrange/SCADENZA_ADEG',
			cb,
			ecb || null,
			null
		);
	}

}
