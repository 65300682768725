import React, { Component } from 'react';

import { Radio } from 'antd';
import allegatiStatic from '@common/utils/static/allegati'
import Documenti from '@common/components/Documenti';

import DownloadCasellarioGiudiziale from '@accreditamento/components/scheda/DownloadCasellarioGiudiziale';



class FileEnte extends Component {
  state = {
    loading: false,
    loaded: false,
    modifiche_statuto: "0",
    files: {}

  };



  async componentDidMount() {
    this.setModificheStatuto(this.props)
  }



  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id !== this.props.accreditamento.id) this.setModificheStatuto(newProps)
  }

  setModificheStatuto(props) {
    this.setState({ modifiche_statuto: props.accreditamento.ente.modifiche_statuto })
  }

  getFormData() {
    return { modifiche_statuto: this.state.modifiche_statuto }
  }


  render() {

    return (
      <div>
        {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'documenti_ente', this.props.accreditamento.tipo_accreditamento) &&
          <Documenti
            canDelete={!this.props.readOnly}
            canAddNote={false}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.props.accreditamento.ente.media}
            current_domain="ente"
            unique={true}
            current_subdomain="documenti_ente"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'documenti_ente')}
            ref_id={this.props.accreditamento.ente.id}
            readOnly={this.props.readOnly}
            accreditation_id={this.props.accreditamento.id}
          />}
      </div>
    );
  }
}

export default FileEnte;