import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Card, Row, Col, Button, Modal, message, Table } from 'antd';

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import checkPermissions from '@common/utils/check/permissions';
import ModelServices from "../services/User";

import ModelUpdateForm from "../forms/Update";
import ModelChangePwdForm from "../forms/ChangePwd";

import UpdateAnagrafica from '../../anagrafica/components/Update';
import CreateAnagrafica from '../../anagrafica/forms/Create';
import RbacServices from '../services/Rbac';
import SpinnerLoading from '@common/components/SpinnerLoading';

@Wrapper({ route: 'SchedaUtente', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['ViewUser', 'ViewIspettore', 'ViewIstruttore', 'adminPermissions'])
class SchedaRoute extends Component {

  state = {
    loading: false,
    asked: false,
    stubs: {
      tipo_media: []
    },
    model: {
      allowedIp: []
    },
  }

  componentDidMount() {
    //console.log('route props', this.props)

    this.loadUser(this.props)
    RbacServices.list({ type: 1 }, data => {
      this.setState({ roles: data.data.data.list, loaded_roles: true });
    }, err => console.error(err));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id && this.props.match.params.id !== prevProps.match.params.id) {
      this.loadUser(this.props);
    }
  }

  updateUserExtraInfo(obj) {
    this.setState(
      { model: { 
          ...this.state.model, 
          extraInfo: obj
        } 
      });

      

  }

  loadUser(props) {
    if (!this.state.loading) {
      this.setState({ loading: true });
      ModelServices.view(
        { expand: `allowedIp,anagrafica,role,extraInfo,extraInfo.media,extraInfo.settoreRegionale, extraInfo.indirizzoSedeLavoro` },
        props.match.params.id,
        res => {
          //console.log('utente', res.data.data)
          this.setState({ model: res.data.data, loading: false, autorizzato: true });
        },
        e => this.setState({ loading: false, autorizzato: false })
      );
    }
  }

  associateAnagrafica(anagrafica) {
    ModelServices.associateAnagrafica(anagrafica.id, {}, this.state.model.id, res => {
      this.setState({
        open_modal_ana: false,
        model: {
          ...this.state.model,
          anagrafica: res.data.data
        }
      })
    }, err => { })
  }


  update(obj) {
    this.setState({ model: obj });
  }

  returnRole() {
    try {
      return this.state.model.role.map(r => r.name).join(', ');
    } catch (e) {
      return " - "
    }
  }

  changeRole(roles) {
    Modal.confirm({
      title: 'Attenzione',
      content: 'Sei sicuro di voler applicare le modifiche?',
      okText: 'Si',
      cancelText: 'No',
      onOk: () => {
        this.setState({ loading: true });
        return ModelServices.changeRole(this.state.model.id, { role: roles }, res => {
          this.setState({
            open_modal_role: false,
            model: {
              ...this.state.model,
              role: res.data.data.map(k => this.state.roles.find(r => r.name === k)),
            },
            loading: false,
          })
        }, () => this.setState({ loading: false }))
      }
    });
  }

  returnLinkList() {
    let url = '/utente';
    let name = 'Utenti';
    if (this.props.match.path.match(/\/ispettore\//)) {
      url = '/ispettore';
      name = 'Addetti al controllo';
    }
    if (this.props.match.path.match(/\/istruttore\//)) {
      url = '/istruttore';
      name = 'Istruttori';
    }
    if (this.props.match.path.match(/\/account\//)) {
      url = '/account';
      name = 'utente';
    }
    return <Breadcrumb.Item><Link to={url}>{name}</Link></Breadcrumb.Item>
  }


  render() {
    //const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys || this.state.model.role?.map(r => r.name),
      onChange: selectedRowKeys => {
        this.setState({ selectedRowKeys });
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Ente',
        name: record.name,
      }),
    };

    return (

      this.state.loading ? <SpinnerLoading /> :

      <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>

        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          <h3 className="sectionTitle">{this.state.model.username}</h3>
          {checkPermissions.hasPermission(this.props.UserStore.user, ['EditUser', 'adminPermissions']) &&
            <Button disabled={this.state.loading} style={{ marginRight: 12 }} type="primary" size="small" onClick={() => this.setState({ open_modal_role: true })} ghost>Modifica ruolo</Button>}
          {checkPermissions.hasPermission(this.props.UserStore.user, ['EditUser', 'adminPermissions']) &&
            <Button disabled={this.state.loading} style={{ marginRight: 12 }} type="primary" size="small" onClick={() => this.setState({ open_modal: true })} ghost>Aggiorna</Button>}
          {checkPermissions.hasPermission(this.props.UserStore.user, ['EditUser', 'adminPermissions']) &&
            <Button disabled={this.state.loading} style={{ marginRight: 12 }} type="primary" size="small" onClick={() => this.setState({ open_modal_pwd: true })} >Cambia password</Button>}

          {
            !this.state.loading && checkPermissions.hasPermission(this.props.UserStore.user, ['EditUser', 'EditAnagrafica']) && (!this.state.model.anagrafica || !this.state.model.anagrafica.id) && this.state.autorizzato && 
            <Button
              disabled={this.state.loading}
              type="primary"
              ghost
              size="small" onClick={() => this.setState({ open_modal_ana: true })} >Inserisci dati anagrafici</Button>
          }
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            {this.returnLinkList()}
            <Breadcrumb.Item>{this.state.model.username}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Row gutter={12}>
          <Col span={24}>

            {
              (this.state.model.anagrafica && this.state.model.anagrafica.id) ?
                <Card
                  style={{}}
                  bordered={false}
                  className="shadow">
                  <UpdateAnagrafica 
                    {...this.props}
                    role={this.state.model.role || []}
                    UserStore={this.props.UserStore} 
                    anagrafica={this.state.model.anagrafica} 
                    update={(obj)=>this.updateUserExtraInfo(obj)}
                    userExtraInfo={this.state.model.extraInfo}
                    id={this.state.model.anagrafica?.id} />
                </Card>
                :
                null
            }
          </Col>
        </Row>
        <Modal
          title="Modifica"
          visible={this.state.open_modal}
          onCancel={() => this.setState({ open_modal: false })}
          footer={false}
        >
          {this.state.open_modal &&
            <ModelUpdateForm
              id={this.state.model.id}
              {...this.props}
              updateRecord={() => {
                this.loadUser(this.props)
                this.setState({ open_modal: false })
              }} />
          }

        </Modal>
        <Modal
          title="Modifica"
          visible={this.state.open_modal_pwd}
          onCancel={() => this.setState({ open_modal_pwd: false })}
          footer={false}
        >
          {this.state.open_modal_pwd &&
            <ModelChangePwdForm
              id={this.state.model.id}
              {...this.props}
              updateRecord={() => {
                message.success("Password modificata");
                this.setState({ open_modal_pwd: false })
              }} />
          }

        </Modal>
        <Modal
          className="no-select-all"
          title="Modifica ruolo utente"
          visible={this.state.open_modal_role}
          confirmLoading={this.state.loading}
          onOk={() => {
            this.changeRole(this.state.selectedRowKeys);
            //let model = { ...this.state.model };
            //model.role = this.state.selectedRowKeys.map(k => this.state.roles.find(r => r.name === k));
            //this.setState({ model, open_modal_role: false });
          }}
          onCancel={() => this.setState({ open_modal_role: false })}
        >
          {this.state.open_modal_role
            &&
            <div>
              <div>
                <h3>Seleziona il ruolo</h3>

                <Table
                  rowKey={'name'}
                  rowSelection={rowSelection}
                  columns={[
                    {
                      title: 'Ruolo',
                      dataIndex: 'name',
                      key: 'name',
                    },
                  ]}
                  dataSource={this.state.roles}
                  pagination={false}
                />
              </div>
            </div>
          }

        </Modal>
        <Modal
          title="Dati anagrafici"
          visible={this.state.open_modal_ana}
          onCancel={() => this.setState({ open_modal_ana: false })}
          footer={false}
        >
          {this.state.open_modal_ana &&
            <CreateAnagrafica returnAnagrafica={(ana) => this.associateAnagrafica(ana)} />
          }

        </Modal>
      </div>
    );
  }
}

export default SchedaRoute;
