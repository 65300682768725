import { Spin, Table } from 'antd';
import React, { Component } from 'react';
import ProgettoService from '../../services/Progetto'

const columns = (component) => {
  let ret = [
    {
      title: 'Sede',
      dataIndex: 'sede.full_address',
      key: 'sede',
    },
    {
      title: 'Macrotipologia',
      dataIndex: 'macrotipologia',
      key: 'macrotipologia',
    },
    {
      title: 'Anno',
      dataIndex: 'anno',
      key: 'anno',
    },
    {
      title: 'Ore annue previste dal progetto',
      dataIndex: 'ore_annue_progetto',
      key: 'ore_annue',
    },
    {
      title: 'Ore disponibili',
      dataIndex: 'ore_annue_disponibili',
      key: 'toTime',
    },
  ];

  return ret;
}

class CoperturaProgetto extends Component {

  state = {
    data: {},
    loading: true,
  }

  componentDidMount() {
    this.load(this.props)
  }

  load(props) {
    this.setState({ loading: true })
    ProgettoService.getCopertura(props.id_progetto, (res) => {
      this.setState({
        dati: res.data.data,
        loading: false
      });
    }, () => this.setState({ loading: false }))
  }

  render() {
    return <div>
      {this.state.loading && <Spin className="spin-overlay" />}
      <Table
        dataSource={this.state.dati}
        columns={columns(this)}
        pagination={false}
        rowKey="id"
        style={{ opacity: this.state.loading ? 0.6 : 1 }}
      />
    </div>
  }
}

export default CoperturaProgetto;