import BaseRequest from "@common/services/_baseRequest";

export default {

  view: (id_accreditamento, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "get",
      'accreditamento/ispezione/' + id_accreditamento + '/ispezione',
      cb,
      ecb || null,
      null
    );
  },

  needIspezione: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      null,
      null,
      "post",
      'affidamento/ispezione/' + id + '/need-ispezione',
      cb,
      ecb || null,
      null
    );
  },

  ispezione_sedi: (id, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      null,
      "post",
      'accreditamento/ispezione/' + id + '/sedi',
      cb,
      ecb || null,
      null
    );
  },


  addControdeduzioni: (id_progetto, id_ispezione, data, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/ispezione/' + id_progetto + '/controdeduzioni/' + id_ispezione,
      cb,
      ecb || null,
      null
    );
  },

  getControdeduzioni: (id_progetto, id_ispezione, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/ispezione/' + id_progetto + '/controdeduzioni/' + id_ispezione + '/download',
      cb,
      ecb || null,
      null
    );
  },

  uploadControdeduzioni: (id_progetto, id_ispezione, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/ispezione/' + id_progetto + '/controdeduzioni/' + id_ispezione + '/upload',
      cb,
      ecb || null,
      null
    );
  },

  assign: (id, data = { id_ispettore: null, id_ispezione: null }, cb = null, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/ispezione/' + id + '/assign',
      cb,
      ecb || null,
      null
    );
  },

  randomassign: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/ispezione/' + id + '/random-assign',
      cb,
      ecb || null,
      null
    );
  },

  accept: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/ispezione/' + id + '/conferma',
      cb,
      ecb || null,
      null
    );
  },

  suspend: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/ispezione/' + id + '/sospendi',
      cb,
      ecb || null,
      null
    );
  },

  refuse: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/ispezione/' + id + '/revoca',
      cb,
      ecb || null,
      null
    );
  },

  confirmSuspend: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/ispezione/' + id + '/conferma-sospensione',
      cb,
      ecb || null,
      null
    );
  },

  confirmRefuse: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/ispezione/' + id + '/conferma-revoca',
      cb,
      ecb || null,
      null
    );
  },

  restartProject: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/ispezione/' + id + '/ripresa-erogazione',
      cb,
      ecb || null,
      null
    );
  },

}
