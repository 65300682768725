import BaseRequest from '@common/services/_baseRequest';

export default {

	list: (params, cb, ecb = null) => {
		//console.warn("TP.1", params)
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'accreditamento/elenco-regionale/list',
			cb,
			ecb || null,
			null
		);
	},

	view: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			'get',
			'accreditamento/elenco-regionale/' + id,
			cb,
			ecb || null,
			null
		);
	},

	edit: (id, data, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			'put',
			'accreditamento/elenco-regionale/' + id,
			cb,
			ecb || null,
			null
		);
	},

	addElencoRegionale: (data, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			'post',
			'accreditamento/elenco-regionale',
			cb,
			ecb || null,
			null
		);
	},

	addDecreto: (data, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			'post',
			'accreditamento/elenco-regionale/add-decreto',
			cb,
			ecb || null,
			null
		);
	},

	exportAlbo: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			null,
			null,
			'get_file',
			'accreditamento/elenco-regionale/export',
			cb,
			ecb || null,
			null
		);
	},

	checkEnte: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			'get',
			'accreditamento/elenco-regionale/check-ente/' + id,
			cb,
			ecb || null,
			null
		);
	},

	revoca: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			'post',
			'accreditamento/elenco-regionale/revoca/' + id,
			cb,
			ecb || null,
			null
		);
	},
}