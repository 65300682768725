import React, { Component } from 'react';

import { Button, Card, message, Popconfirm } from 'antd';
import TableComponent from '@common/components/table';

import ModelServices from '../services/AreaAttivita';

import FilterForm from '@common/components/filterForm';
import _ from '@common/utils/formatters/_';

const model = (component) => {
    return {
        with_server_pagination: true, // metti paginazione lato server
        columns: [
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'created_at',
                render: record => <div style={{ width: 120 }}>
                    <Popconfirm placement="top" title="Confermi di voler aggiungere questa ada?"
                        onConfirm={() => {component.addAda(record); }}
                        okText="Si" cancelText="No">
                        <Button className="m5h" type="primary" icon="save" size="small">Inserisci</Button>
                    </Popconfirm>
                </div>,
            },
            {
                title: 'Denominazione ADA',
                dataIndex: 'denominazione_ada',
                key: 'denominazione_ada',
                render: (text, record) => (<div>{record.denominazione_ada}</div>)
            },

            {
                title: 'UC',
                dataIndex: 'uc',
                key: 'uc',
                render: (text, record) => (<div>{record.uc}</div>)
            },
        ]
    }
}


export default class TabellaAreaAttivita extends Component {

    default_order = 'denominazione_ada'

    state = {
        loading: false,
        open_drawer: false,
        editing_id: null,
        data: {
            total: 0,
            current: 1,
            data: []
        }
    }

    componentDidMount() {
        this.callAndUpdate(this.props)
    }


    getBaseUrl() {
        return this.props.base_url;
    }

    addAda(element) {
        if (this.props.list.find(el => el.id === element.id)) {
            message.error("L'area di attività è già stata selezionata");
        } else this.props.addAda(element);
    }



    /**
     * Carica dati
     * @param  {[type]} props [description]
     * @return {[type]}       [description]
     */
    callAndUpdate(props) {
        let { params, pager } = _.getFilterParam(props, this.default_order);
        this.setState({ loading: true });
        ModelServices.list(params, (res) => {
            let data = _.getResponse(res);
            this.setState({
                loading: false,
                data: data
            }, () => this.refs.table_component.updateTableData(
                data.data,
                data.current,
                data.total,
                pager)
            );

        }, () => {
            this.setState({ loading: false });
        })
    }

    /**
     * Se cambia la stringa aggiorna
     * @param  {[type]} newProps [description]
     * @return {[type]}          [description]
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.callAndUpdate(this.props.location.search);
        }
    }

    getFilterModel() {
        return {
            layout: 'vertical',
            multistep: false,
            rows: [
                {
                    cols: [
                        {
                            items: [
                                {
                                    type: 'input',
                                    label: 'Denominazione Ada',
                                    type_string: 'text', // field type
                                    name: 'denominazione_ada',
                                }
                            ]
                        },
                        {
                            items: [
                                {
                                    type: 'input',
                                    label: 'UC',
                                    type_string: 'text', // field type
                                    name: 'uc',
                                }
                            ]
                        },

                    ]
                },
                {
                    cols: [
                        {
                            items: [
                                {
                                    name: 'submit',
                                    type: 'submit',
                                    text: 'Cerca',
                                    btn_type: 'primary'
                                }
                            ]
                        }
                    ]
                },
            ]
        }
    }

    /**
     * Ricarica dopo update
     * @return {[type]} [description]
     */
    refresh() {
        this.callAndUpdate(this.props)
    }

    /**
     * Chiamato da sort in tabella
     * @param  {[type]} obj [description]
     * @return {[type]}     [description]
     */
    updateQueryStr(obj) {
        _.formatSortChange(this.props, this.getBaseUrl(), obj);
    }

    /**
     * Chiamato da tabella
     * @param  {[type]} id [description]
     * @return {[type]}    [description]
     */
    async updateRecord(id) {
        await this.setState({ editing_id: id });
        this.setState({ open_drawer: true });
    }

    /**
     * Record aggiornato in form
     * @return {[type]} [description]
     */
    updatedRecord() {
        this.setState({ open_drawer: false, editing_id: false });
        this.callAndUpdate(this.props);
    }

    render() {
        return (
            <div>
                <FilterForm
                    formLayout={this.getFilterModel()}
                    base_url={this.getBaseUrl()}
                    search={this.props.location.search}
                    history={this.props.history}
                />
                <Card
                    className="shadow no-pad"
                    bordered={false}
                >
                    <TableComponent
                        data={this.state.data} 
                        model={model(this)}
                        table_props={{ rowKey: 'id' }}
                        size="medium"
                        ref="table_component"
                        loading={this.state.loading}
                        with_server_pagination={true}
                        updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                        updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
                    />
                </Card>
            </div>
        );
    }
}

