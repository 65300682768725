import React, { Component } from 'react';
import { Table } from 'antd';

import AccreditamentoServices from '@accreditamento/services/Accreditamento';

import DocSpan from '@common/components/DocSpan';


const TableModel = (component) => [
  {
    title: "Note",
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ width: 250 }}>{element.note}</div>
  },
  {
    title: "Tipo file",
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ width: 250 }}>{element.media.tipo}</div>
  },
  {
    title: "File",
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ width: 250 }}>
      {
        element.media && element.media.original
          ?
          <React.Fragment>
            {element.new_id ?
              <React.Fragment>
                <DocSpan key={element.new_file.id} file={element.new_file} />
                <p style={{ color: 'red' }}>{element.media.original.nome_file}</p>
              </React.Fragment>
              : <DocSpan key={element.media.original.id} file={element.media.original} />
            }
          </React.Fragment>
          : null
      }
    </div>
  }
];

class DocumentiCaricati extends Component {

  state = {
    loaded: false,
    documents: [],
    requested: [],
    uploading: [],
    medias: [],
    loading: false
  }

  constructor(props) {
    super(props);

    this.formRef = {}
  }

  componentDidMount() {
    AccreditamentoServices.getDocuments(this.props.accreditamento.id, (res) => {
      this.setState({ documents: res.data.data })
    })
  }

  render() {
    return <div>
      <Table
        rowKey={(record) => record.id}
        columns={TableModel(this)}
        dataSource={this.props.documents}
        pagination={false}
      />
    </div>
  }
}

export default DocumentiCaricati
