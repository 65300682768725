import { Col, Row } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import SingleNominaOk from './SingleNominaOk';


class IspezioneNomineOk extends Component {

  state = {
    loaded: false,
    loading: false
  }


  render() {
    return (this.props.nomine && this.props.nomine.length > 0) ?
      <Row>
        <Col span={24}>
          <h4>Addetti al controllo</h4>
        </Col>
        <Col span={12}>
          {
            this.props.nomine[0] ? <SingleNominaOk
            accreditamento={this.props.accreditamento}
            ispezione={this.props.ispezione}
            refreshInline={(data) => this.props.refreshInline(data)} nomina={this.props.nomine[0]} /> : null
          }
        </Col>
        <Col span={12}>
          {
            this.props.nomine[1] ? <SingleNominaOk
            accreditamento={this.props.accreditamento}
            ispezione={this.props.ispezione}
            refreshInline={(data) => this.props.refreshInline(data)} nomina={this.props.nomine[1]} /> : null
          }
        </Col>
      </Row>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(IspezioneNomineOk);