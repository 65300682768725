import React, {Component} from 'react';

import {Button, Popconfirm, List, Modal} from 'antd';

import moment from 'moment';

import AnagraficaServices from '../services/Anagrafica';
import ModelForm from '../forms/EsperienzaProfessionale';
import checkPermissions from '@common/utils/check/permissions';

import format from '@common/utils/formatters/_';
export default class EsperienzeProfessionali extends Component {


  state = {
    loading: false,
    element_values: {}, // valori elemento da modificare
    open_drawer: false,
    drawer_title: "Aggiungi una esperienza professionale",
    eliminating: []
  }

  update() {
    //this.props.onUpdate({documenti})
  }

  async create() {
    this.setState(
      {
        element_values: {},
        drawer_title: 'Aggiungi una esperienza professionale',
        open_drawer: true
      }
    );
  }

  addEsperienzaProfessionale(data, cb) {
    this.setState({ loading: true });
    AnagraficaServices.addEsperienzaProfessionale( data, this.props.model.id, (res) => {
      this.props.onUpdate({
        ...this.props.model,
        ...res.data.data
      })
      this.setState({open_drawer: false, loading: false}, () => this.form.reset());
      cb()
    }, () => this.setState({ loading: false }, () => cb()));
  }

  deleteEsperienzaProfessionale(id) {
    if(this.state.eliminating.indexOf(id) !== -1) return;

    this.setState({eliminating: [...this.state.eliminating, id]}, ()=>{
      AnagraficaServices.deleteEsperienzaProfessionale( this.props.model.id, id, () => {
        this.setState({eliminating: this.state.eliminating.filter(el => el !== id)});
        this.props.onUpdate({
            ...this.props.model,
            esperienzeProfessionali: this.props.model.esperienzeProfessionali.filter(els => els.id !== id)
          })
      }, () => this.setState({eliminating: this.state.eliminating.filter(el => el !== id)}) )
    })
  }

  getSource() {
    try {
      return this.props.model.esperienzeProfessionali
    } catch(e) {
      return []
    }
  }

  getItemActions(item) {
    return (checkPermissions.hasPermission(this.props.UserStore.user, ['EditAnagrafica','adminPermissions']) && !this.props.readOnly)
    ? [
      <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere questo elemento" onConfirm={()=>this.deleteEsperienzaProfessionale(item.id)} okText="Si" cancelText="No">
        <Button loading={this.state.eliminating.indexOf(item.id) !== -1} type="danger" size="small" ghost>Elimina</Button>
      </Popconfirm>
    ]
    : null
  }  

  getAttivitaBySettori(id_settore, id_attivita){

    let attivita_settore = undefined;
    
    this.props.GlobalStore.stubs.settori.map(r => {
      if(r.key == 'settori_' + id_settore){
        attivita_settore = r.attivita;
      }
    })

    let attivita = undefined;

    attivita_settore.map(r => {
      if(r.key == 'attivita_' + id_attivita){
        attivita = r.label;
      }
    })

    return attivita;

  }

  render () {
    /*

    */    

    return (
      <div>
        {
          (checkPermissions.hasPermission(this.props.UserStore.user, ['EditAnagrafica','adminPermissions']))
          ?
          <Button disabled={this.props.readOnly} type="primary" ghost size="small" style={{marginBottom: 12}} onClick={()=>this.create()}>Aggiungi</Button>
          : null
        }
        <List
          dataSource={this.getSource()}
          renderItem={item => (
              <List.Item
              actions={this.getItemActions(item)}
            >
            <p>
              <strong>Periodo: </strong> {moment(item.dal).format('DD/MM/YYYY') + " - " + moment(item.al).format('DD/MM/YYYY')}<br />
              <strong>Organizzazione: </strong> {item.organizzazione}<br />
              <strong>Posizione: </strong> {item.posizione}<br />
              <strong>Settore: </strong> { format.replaceStub( item.id_settore, 'settori', this.props.GlobalStore ) }<br />
              <strong>Tipo rapporto di lavoro: </strong> { format.replaceStub( item.id_tipo_rapporto_lavoro, 'tipo_rapporto_lavoro', this.props.GlobalStore ) }<br />              
              <strong>Attività: </strong> {this.getAttivitaBySettori(item.id_settore, item.attivita)}<br />
            </p>
            </List.Item>
          )}
        />
        <Modal
          title={this.state.drawer_title}
          visible={this.state.open_drawer}
          okText={null}
          width={800}
          okButtonProps={{ style: {display: 'none' }} }
          onCancel={() => this.setState({ open_drawer: false }, () => this.form.reset())}
        >
        <ModelForm
          ref={(ref) => this.form = ref}
          values={this.state.element_values}
          model={this.props.model}
          loading={this.state.loading}
          GlobalStore={this.props.GlobalStore}
          submit={(data, cb)=>this.addEsperienzaProfessionale(data, cb)} />
      </Modal>
    </div>
    );
  }
}

