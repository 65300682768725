import React, { Component } from 'react';
import { Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import Wrapper from '@common/decorators/Wrapper';
import moment from 'moment';
import AccreditamentoRequest from '@accreditamento/services/Request';
import ProfileUtenteGenerico from '../forms/request/ProfileUtenteGenerico';

import '../less/Accreditamento.less';

@Wrapper({ route: 'AccreditamentoRequest', hasSidebar: false, hasHeader: true, hasFooter: true, public: true })
class RegistrazioneUtenteGenerico extends Component {

    state = {
        loading: false,
        saved: false,
    };

    async submitRequest() {

        let valid = await this.profile_form.formRef.props.form.validateFields();
        let valid_indirizzo = await this.profile_form.dati_indirizzo.formRef.props.form.validateFields();

        if (valid && valid_indirizzo)
            var valori_profilo = this.profile_form.formRef.props.form.getFieldsValue();
        valori_profilo.indirizzo = this.profile_form.dati_indirizzo.formRef.props.form.getFieldsValue();
        if (valori_profilo) {

            valori_profilo.nato_il = moment(
                valori_profilo.nato_il
            ).format('YYYY-MM-DD')
            this.setState({ loading: true }, () => {
                AccreditamentoRequest.registrazioneUtenteGenerico(
                    { ...valori_profilo, },
                    res => {
                        this.setState({ loading: false, saved: true });
                    },
                    err => {
                        this.setState({ loading: false });
                    }
                );
            })
        }
    }

    render() {
        return (
            <div style={{ maxWidth: 800, margin: '20px auto' }}>
                <h3 className="sectionTitle" style={{ textAlign: 'center' }}>REGISTRAZIONE UTENTE GENERICO</h3>
                <p style={{ textAlign: 'center' }}>
                    <span><em className="color-red">*</em> I campi contrassegnati da asterisco sono obbligatori</span>
                </p>
                <Card className="shadow" bordered={false}>
                    {!this.state.saved ?
                        <div className="steps-content">
                            <ProfileUtenteGenerico
                                {...this.props}
                                ref={(ref) => this.profile_form = ref}
                                loading={this.state.loading}
                            />
                        </div> :
                        <p
                            style={{
                                margin: '40px auto',
                                width: '320px',
                                textAlign: 'center',
                                fontSize: 18,
                            }}
                        >
                            Hai inoltrato una richiesta di registrazione, verifica di aver ricevuto la mail di conferma all'indirizzo EMAIL fornito
          </p>
                    }
                    <Link
                        to="/login"
                        className="login-form-forgot"
                        style={{ float: 'right' }}
                        onClick={() => {
                        }}
                    >
                        Annulla
          </Link>
                </Card>
                { !this.state.saved &&
                    <div className="steps-action m10h">
                        <Button
                            type="primary"
                            loading={this.state.loading}
                            size="large"
                            onClick={() => this.submitRequest()}
                        >
                            Conferma i dati
        </Button>
                    </div>
                }


            </div>
        );
    }
}

export default RegistrazioneUtenteGenerico;