import React, {Component} from 'react';

import {Button, Card, message, Popconfirm, Row, Tag, Tooltip} from 'antd';
import TableComponent from '@common/components/table';

import Create from '../components/Create';

import ModelServices from '../services/User';

import moment from 'moment';

import FilterForm from '@common/components/filterForm';
import checkPermissions from '@common/utils/check/permissions';
import _ from '@common/utils/formatters/_';

const status = {
  cancellato: 0,
  disabilitato: 1,
  attivo: 10
}

const statusLabel = {
  0: 'Cancellato',
  1: 'Disabilitato',
  10: 'Attivo',
}

const model = (component) => {
  return {
    with_server_pagination: true, // metti paginazione lato server
    columns:[
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'created_at',
        render: element => <div style={{width: 100}}>
          <Tooltip placement="top" title="Visualizza scheda">
            <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push(component.getUserUrl(element))} />
          </Tooltip>
          { /*
            checkPermissions.hasPermission(component.props.UserStore.user, ['DeleteUser', 'adminPermissions']) && component.props.UserStore.user.id !== element.id && element.status !== status.cancellato &&
            <Tooltip placement="top" title="Elimina">
              <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere questo elemento" onConfirm={() => component.deleteRecord(element.id)} okText="Si" cancelText="No">
                <Button className="m2w" size="small" shape="circle" icon="delete" />
              </Popconfirm>
            </Tooltip> */
          }
          {
            checkPermissions.hasPermission(component.props.UserStore.user, ['DeleteUser', 'adminPermissions']) && component.props.UserStore.user.id !== element.id  && element.status === status.attivo &&
            <Tooltip placement="top" title="Disabilita">
              <Popconfirm placement="leftTop" title="Sicuro di voler disabilitare questo elemento?" onConfirm={() => component.changeStatus(element.id, status.disabilitato)} okText="Si" cancelText="No">
                <Button className="m2w" size="small" shape="circle" icon="user-delete" />
              </Popconfirm>
            </Tooltip>
          }
          {
            checkPermissions.hasPermission(component.props.UserStore.user, ['DeleteUser', 'adminPermissions']) && component.props.UserStore.user.id !== element.id  && element.status === status.disabilitato &&
            <Tooltip placement="top" title="Attiva">
              <Popconfirm placement="leftTop" title="Sicuro di voler attivare questo elemento?" onConfirm={() => component.changeStatus(element.id, status.attivo)} okText="Si" cancelText="No">
                <Button className="m2w" size="small" shape="circle" icon="user-add" />
              </Popconfirm>
            </Tooltip>
          }
        </div>,
      },
      {
        title: 'Nome',
        dataIndex: '',
        sorter: true,
        key: 'nome',
        render: element => <div>{element.anagrafica?.nome}</div>,
      },
      {
        title: 'Cognome',
        dataIndex: '',
        sorter: true,
        key: 'cognome',
        render: element => <div>{element.anagrafica?.cognome}</div>,
      },
      {
        title: 'Username',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'username',
        render: element => <div>{element.username}</div>,
      },
      {
        title: 'Email',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'email',
        render: element => <div>{element.email}</div>,
      },
      {
        title: 'Ruolo',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'role.name',
        render: element => {
          try{
            return <div>{element.role.map(r => r.name).join(', ')}</div>
          } catch(e) {
            return <div>-</div>
          }
        }
      },
      {
        title: 'Ultimo aggiornamento',
        dataIndex: '',
        sorter: true,
        key: 'updated_at',
        render: element => `${moment(element.updated_at,'X').format('DD/MM/YYYY HH:mm')}`,
      },
      {
        title: 'Stato',
        dataIndex: '',
        sorter: true,
        key: 'status',
        render: element => <Tag color={element.status === status.cancellato ? 'red' : element.status === status.attivo ? 'green' : 'orange'}>{statusLabel[element.status]}</Tag>
      },
    ]
  }
}


export default class List extends Component {

  default_order = 'cognome'

  state = {
    loading: false,
    open_drawer: false,
    editing_id: null,
    roles: [],
    data: {
      total: 0,
      current: 1,
      data: []
    }
  }

  componentDidMount () {
    this.callAndUpdate(this.props)
  }

  getUserUrl(item) {
    return "/utente/" + item.id
  }

  getBaseUrl() {
    return "/utente";
  }

  setDefaultSearchParams(str) {
    return str
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  callAndUpdate(props) {
    let { params, pager } = _.getFilterParam(props, this.default_order);
    params = this.setDefaultSearchParams(params);
    this.setState({ loading: true });
    ModelServices.list(params, (res) => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, () => {
      this.setState({ loading: false });
    })
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.callAndUpdate(this.props.location.search);
    }
  }

  getFilterModel() {
    return  {
      layout: 'vertical',
      multistep: false,
      rows: [
        {
          cols: [
            {
              items: [
                {
                  type: 'input',
                  label: 'Username',
                  type_string: 'text', // field type
                  name: 'username',
                }
              ]
            },
            {
              items: [
                {
                  type: 'input',
                  label: 'Email',
                  type_string: 'email', // field type
                  name: 'email',
                }
              ]
            },
            {
              items: [
                {
                  type: 'select',
                  label: 'Ruolo',
                  name: 'role',
                  options_props: 'roles'
                }
              ]
            },
          ]
        },
        {
          cols: [
            {
              items: [
                {
                  name: 'submit',
                  type: 'submit',
                  text: 'Cerca',
                  btn_type: 'primary'
                }
              ]
            }
          ]
        },
      ]
    }
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh(){
    this.callAndUpdate(this.props)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.getBaseUrl(), obj);
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  updateRecord(id) {
    this.setState({ editing_id: id, open_drawer: true });
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  /*deleteRecord(id) {
    ModelServices.delete(id, () => this.refresh(), () => null)
  }*/

  changeStatus(id, s) {
    this.setState({ loading: true });
    ModelServices.changeStatus(id, s, () => {
      message.success("Operazione effettuata con successo")
      this.refresh()
    }
      , () => this.setState({ loading: false }))
  }

  /**
   * Record aggiornato in form
   * @return {[type]} [description]
   */
  updatedRecord() {
    this.setState({open_drawer: false, editing_id: false});
    this.callAndUpdate(this.props);
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={this.getFilterModel()}
          base_url={this.getBaseUrl()}
          search={this.props.location.search}
          history={this.props.history}
          form_elements={{
            roles: this.props.roles.map(r => {
              return {
                label: r.name,
                value: r.name
              }
            })
          }}
        />
        <Card
          className="shadow no-pad"
          bordered={false}
        >

          <Row type="flex" justify="start" className="m10h">
            <div className="m5h m5w">
              {
                checkPermissions.hasPermission(this.props.UserStore.user, ['AddUser', 'adminPermissions']) &&
                <Create
                  roles={this.state.roles}
                  oncreate={() => this.refresh()}
                {...this.props} />
              }
            </div>
          </Row>

          <TableComponent
            data={this.state.data} model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

