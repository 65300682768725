import React, { Component } from 'react';
import { Table, Input, Checkbox } from 'antd';
import ModelServices from '@accreditamento/services/Accreditamento';
import DocSpan from '@common/components/DocSpan';
import moment from 'moment';

const getDate = (dt) => {
  if (moment(dt, 'YYYY-MM-DD').isValid()) return moment(dt, 'YYYY-MM-DD').format('DD/MM/YYYY')

  return ''
}

const TableModel = (component) => [
  {
    title: "Richiedi integrazione",
    dataIndex: '',
    key: 'id',
    sorter: false,
    render: element => <div style={{ minWidth: 250 }}>
      {
        element.ext === 'p7m' || element.ext === 'pdf'
          ?
          <React.Fragment>
            <Checkbox
              onChange={(e) => component.onSelectRow(e, element)}
            ></Checkbox>
            {
              component.isSelected(element)
                ?
                <Input.TextArea
                  style={{ width: '100%' }}
                  placeholder="Inserisci dettagli"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  onChange={(e) => component.addNote(e, element)}
                />
                : null
            }
          </React.Fragment>
          : null
      }
    </div>
  },
  {
    title: "Nome file",
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ width: 250 }}><DocSpan key={element.id} file={element} /></div>
  },
  {
    title: "Tipo",
    dataIndex: 'tipo',
    sorter: false,
    render: element => <div style={{ width: 250 }}>{element}</div>
  },
  {
    title: "Validità",
    dataIndex: 'valido_dal',
    sorter: false,
    render: (element, row) => <div style={{ width: 250 }}>{getDate(row.valido_dal) + " - " + getDate(row.valido_al)}</div>
  }
];

class RichiestaIntegrazioneForm extends Component {

  state = {
    loaded: false,
    documents: [],
    requested: [],
    loading: false
  }



  componentDidMount() {
    ModelServices.getDocuments(this.props.accreditamento.id, (res) => {
      this.setState({ documents: res.data.data })
    })
  }

  onSelectRow(e, element) {

    if (e.target.checked) {
      this.setState({
        requested: [...this.state.requested, { id: element.id, note: '', media: element }]
      })
    } else {
      this.setState({
        requested: this.state.requested.filter(l => l.id !== element.id)
      })
    }
  }

  addNote(e, element) {
    this.setState({
      requested: [...this.state.requested.filter(l => l.id !== element.id), { id: element.id, note: e.target.value, media: element }]
    })
  }

  isSelected(element) {
    return this.state.requested.find(e => e.id === element.id) ? true : false
  }

  getValues() {
    return this.state.requested
  }

  render() {
    return <div>
      <Table
        rowKey={(record) => record.id}
        columns={TableModel(this)}
        dataSource={this.state.documents}
        pagination={false}
      />
    </div>
  }
}

export default RichiestaIntegrazioneForm
