import { Tabs, Card, Row, Col } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';

import checkPermission from '@common/utils/check/permissions';
import PannelloLezione from "./PannelloLezione";
import PresenzeGiornaliere from "./PresenzeGiornaliere";
import ProgrammaLezione from "./ProgrammaLezione";
import PannelloInfoPresenze from "./PannelloInfoPresenze";
import SpinnerLoading from "@common/components/SpinnerLoading";

const TabPane = Tabs.TabPane;

export default class DettagliLezione extends Component {

  static propTypes = {
    isEditable: PropTypes.bool,
  }

  state = {
    current_key: "1",
    lesson: this.props.lezione,
    loading: false
  }

  refreshInline(data) {
    this.setState({
      lesson: { ...this.state.lesson, ...data }
    }
    );
  }

  componentDidMount() {
    if (this.props.location.state?.tab) {
      this.setState({
        current_key: '' + this.props.location.state.tab,
      });
    }
  }

  updateHandler(payload) {
    this.setState({ lesson: payload.data.data });
  }

  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  render() {
    return (<div style={{ marginTop: 12, marginBottom: 12 }}>

      <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
        <Col style={{ flex: "1 1 70%" }} >
          <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
            {this.state.loading ? <SpinnerLoading /> :
              <PannelloLezione
                {...this.props}
                lesson={this.state.lesson}
                loading={this.state.loading}
                refreshInline={(data) => this.refreshInline(data)}
                updateCallback={(res) => this.updateHandler(res)}
                isNextLesson={false} />
            }
          </Row>
        </Col>

        {checkPermission.hasPermission(this.props.UserStore.user, ['manageMyPresenze']) && this.props.studente &&
          <Col style={{ flex: "1 1 30%" }} >
            <PannelloInfoPresenze {...this.props} />
          </Col>
        }
      </Row>

      <Card
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={(k) => this.setState({ current_key: "" + k })}
            defaultActiveKey={"1"}
            activeKey={this.state.current_key}
            size="medium"
            renderTabBar={this.renderTabBar}>
            <TabPane
              tab={<span>Programma</span>} key="1">
              <div id="programma">
                <ProgrammaLezione
                  {...this.props}
                  updateCallback={(res) => this.updateHandler(res)}
                  lesson={this.state.lesson}
                />
              </div>
            </TabPane>
            <TabPane
              tab={<span>Registro<br /></span>} key="2">
              <div id="registro">
                <PresenzeGiornaliere
                  {...this.props}
                  lesson={this.state.lesson}
                  corso={this.state.lesson?.progetto}
                />
              </div>
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Card>
    </div>);
  }
}

