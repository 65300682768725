import React, { Component } from 'react';
import { Button, Card, Tooltip } from 'antd';

import moment from 'moment';

import checkPermission from '@common/utils/check/permissions';

import FilterForm from '@components/filterForm';
import TableComponent from '@components/table';

import ModelServices from '@affidamento/services/Corsi';

import affidamentoStatic from '@common/utils/static/affidamento';
import _ from '@common/utils/formatters/_';

const renderActions = (element, component) => {
  return <div style={{ display: 'inline-block' }}>
    <Tooltip placement="top" title="Visualizza registro">
      <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/affidamento/registro/" + element.id)} />
    </Tooltip>
  </div>
}

const renderStatusIndicator = (record, component) => {

  let user = 'ente';

  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) user = 'ispettore';

  let defined_c = '';
  let description = record.stato_flusso_description['descrizione_' + user];
  let colors = ['green', 'red', 'orange'];
  colors.forEach(c => {
    if (affidamentoStatic.pallini[user][c].indexOf(parseInt(record.stato_flusso)) !== -1) defined_c = c;
  })

  return (defined_c !== '') ?
    <Tooltip placement="topRight" title={description}>
      <span className={"pallino " + defined_c}></span>
    </Tooltip>
    : <span className={"pallino invisible"}></span>;
}

const model = (component) => {

  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        render: element => <div style={{ width: 60 }}>
          {
            renderStatusIndicator(element, component)
          }
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Titolo del corso',
        dataIndex: '',
        sorter: true,
        key: 'titolo_progetto',
        render: element => <div>
          {element.titolo_progetto || ''}
        </div>
      },
      {
        title: 'Edizione',
        dataIndex: '',
        sorter: true,
        key: 'edizione',
        render: element => <div style={{ width: 60 }}>
          {element.edizione || ''}
        </div>
      },
      !checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']) && {
        title: 'Ente erogatore',
        dataIndex: '',
        sorter: true,
        key: 'ente',
        render: element => <div style={{ width: 140 }}>
          {element.ente?.denominazione || ''}
        </div>
      },
      {
        title: 'Data di avvio prevista',
        dataIndex: '',
        sorter: true,
        key: 'data_presunta_avvio',
        render: element => <div style={{ width: 140 }}>
          {element.data_presunta_avvio ? moment(element.data_presunta_avvio, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
        </div>,
      },
      {
        title: 'Responsabile',
        dataIndex: '',
        sorter: false,
        key: 'responsabile',
        render: element => <div style={{ width: 140 }}>
          {element.istruttore && element.istruttore[0] ? element.istruttore[0].anagrafica.cognome + ' ' + element.istruttore[0].anagrafica.nome : ''}
        </div>
      },
      {
        title: 'Stato',
        dataIndex: '',
        sorter: true,
        key: 'stato',
        render: element => <div style={{ width: 140 }}>
          {affidamentoStatic.getProgettoLabel(element.stato, checkPermission.hasPermission(component.props.UserStore.user, ['entePermission'])) || ''}
        </div>,
      }
    ]
  };
}

const filterFormModel = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: !checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']) ? 10 : 20,
            items: [
              {
                type: 'string',
                label: 'Titolo',
                name: 'titolo_progetto',
              }
            ]
          },
          !checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']) ? {
            span: 10,
            items: [
              {
                type: 'string',
                label: 'Ente erogatore',
                name: 'ente_erogatore',
              }
            ]
          } : null,
          {
            span: 4,
            items: [
              {
                type: 'select',
                label: 'Stato',
                name: 'stato',
                options: [
                  {
                    value: "-1",
                    label: "Tutti"
                  },
                  {
                    value: "7",
                    label: "Avvio progetto"
                  },
                  {
                    value: "8",
                    label: "Iscrizioni aperte"
                  },
                  {
                    value: "10",
                    label: "In erogazione"
                  },
                  {
                    value: "11",
                    label: "Completato"
                  },
                  {
                    value: "12",
                    label: "Revocato"
                  },
                  {
                    value: "13",
                    label: "Sospenso"
                  },
                ],
              }
            ]
          },

        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  };
}

class TabellaRegistroElettronico extends Component {
  base_url = '/affidamento/registro'
  default_order = '-created_at';

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search)
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);

    if (params.stato === "-1") {
      delete params.stato;
    }

    this.setState({ loading: true });
    ModelServices.list(
      { ...params, 
        fields: ['id', 'titolo_progetto', 'ente', 'ente.denominazione', 'edizione', 'data_effettiva_avvio', 'data_presunta_avvio', 'stato', 'stato_flusso', 'stato_flusso_description'].join(','),
        expand: ['istruttore'].join(',')
      }, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.state.params}
          history={this.props.history}
          date_fields={['data_apertura', 'data_chiusura']}
        />
        <Card
          className="shadow no-pad"
          bordered={false}>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

export default TabellaRegistroElettronico;
