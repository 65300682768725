import React, { Component } from 'react';

import { Button, Drawer, Table, Tooltip } from 'antd';
import CommonForm from '@common/components/form';

import EditArticolazionePercorso from './EditArticolazionePercorso';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'integer',
                label: 'Durata del corso (N° max di ore)',
                name: 'courseDuration',
                props: {
                  disabled: true,
                },
              }
            ],
          }
        ],
      },
    ]
  }
}

const columns = (component) => {
  let ret = [
    {
      title: 'Annualità',
      dataIndex: 'annuity',
      key: 'annuity',
      render: (text, record) => (<div>{record.annuity}</div>)
    },
    {
      title: 'Ore in aula',
      dataIndex: 'classroomHours',
      key: 'classroomHours',
      render: (text, record) => (<div>{record.classroomHours}</div>)
    },
    {
      title: 'Ore in laboratorio',
      dataIndex: 'labHours',
      key: 'labHours',
      render: (text, record) => (<div>{record.labHours}</div>)
    },
    {
      title: 'Ore di stage',
      dataIndex: 'stageHours',
      key: 'stageHours',
      render: (text, record) => (<div>{record.stageHours}</div>)
    },
    {
      title: 'Ore totali',
      dataIndex: 'totalHours',
      key: 'totalHours',
      render: (text, record) => (<div className={record.totalHours > 2400 ? 'record-not-valid' : ''}>{record.totalHours}</div>)
    }
  ];

  if (!component.props.hiddenActions) {
    ret.push(
      {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
          <div>
            {!component.props.loading_data &&
              <React.Fragment>
                <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                  <Button type={record.saved? 'default' : 'danger'} className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record.id, record, 'edit_articolazione')} />
                </Tooltip>
              </React.Fragment>
            }
          </div>
        ),
      }
    )
  }
  return ret;
}

class ArticolazionePercorso extends Component {

  state = {
    open_drawer: false,
  };

  update(data) {
    var index = this.props.percorsoList.map(el => el.annuity).indexOf(data.annuity);
    this.props.percorsoList[index] = {
      annuity: data.annuity,
      classroomHours: data.classroomHours,
      labHours: data.labHours,
      stageHours: data.stageHours,
      totalHours: data.totalHours,
      saved: true
    }

    this.setState(
      {
        open_drawer_edit_articolazione: false,
        //learnerList: list
      }, () => {
        this.props.saveArticolazionePercorsoList(this.props.percorsoList);
      }
    );
  }

  open(annuity, element, action) {
    this.setState({
      annuity_opened: annuity,
      element_opened: element
    }, () => this.setState({ ['open_drawer_' + action]: true }))
  }

  render() {
    return (
      <div>
        <Table
          rowKey={(record) => record.annuity}
          loading={this.props.loading}
          columns={columns(this)}
          dataSource={this.props.percorsoList}
        />
        <CommonForm
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          form_model={formLayout(this)}
          values={{courseDuration: this.props.courseDuration }}
          readOnly={true}
          subform={this.props.subform}
        />
        <Drawer
          title="ARTICOLAZIONE DEL PERCORSO PER ANNUALITÀ"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_edit_articolazione: false, annuity_opened: null })}
          visible={this.state.open_drawer_edit_articolazione}
          width={window.innerWidth / 3}
          destroyOnClose={true}
        >
          <EditArticolazionePercorso
            close={() => this.setState({ open_drawer_edit_articolazione: false })}
            articolazionePercorso={this.state.element_opened}
            annuity={this.state.annuity_opened}
            onUpdate={(data) => {
              this.update(data);
            }}
            readOnly={this.props.readOnly}
          />
        </Drawer>
      </div>
    );
  }
}
export default ArticolazionePercorso;