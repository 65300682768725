import React, { Component } from 'react';
import { Button, Row, Drawer, Popconfirm, Tooltip, message, Modal } from 'antd';

import affidamentoStatic from '@common/utils/static/affidamento';
import CommonForm from '@common/components/form';
import checkPermission from '@common/utils/check/permissions';
import TabellaDiscenti from './TabellaDiscenti';

import ModelServices from '@affidamento/services/Corsi';
import IscrizioneServices from '@affidamento/services/Iscrizione';

import CreateDiscenti from '../Discenti/CreateDiscenti';
import FileSaver from 'file-saver';

const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

class Discenti extends Component {

  state = {
    loading: false,
    open_drawer: false,
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, "giustificativo.pdf");
  }

  requestJustification(student) {
    this.setState({ loading: true });
    IscrizioneServices.richiediGiustificativo(student.id, { note: '' },
      res => {
        message.success('Giustificativo richiesto');
        this.tabellaRef.fetch();
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      });
  }

  downloadJustification(student) {
    this.setState({ loading: true });
    IscrizioneServices.downloadGiustificativo(student.id,
      res => {
        this.saveBlob(res.data);
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      })
  }

  accept(student) {
    this.setState({ loading: true });
    IscrizioneServices.accettaGiustificativo(student.id,
      res => {
        message.success('Giustificativo accettato');
        this.tabellaRef.fetch();
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      });
  }

  async remove(student, note) {

    let valid = await this.formSelRef.props.form.validateFields();
    if (!valid) return;

    this.setState({ loading: true });
    IscrizioneServices.espelli(student.id, { note: note },
      res => {
        message.success('Discente espulso');
        this.tabellaRef.fetch();
        this.setState({ loading: false, open_modal: false });
      }, () => {
        this.setState({ loading: false, open_modal: false });
      });
  }

  addIscrizione(student) {
    let req = {};
    if (student?.id) {
      req.id_iscrizione = student.id;
      this.setState({ loading: true });
      ModelServices.addDiscente(this.props.corso.id, req, res => {
        this.tabellaRef.fetch();
        this.setState({ open_drawer: false, loading: false });
        message.success('Il discente è stato aggiunto correttamente');
      }, () => {
        this.setState({ open_drawer: false, loading: false });
      });
    }
  }

  addAnagrafica(student) {
    let req = {};
    if (student?.id) {
      req.id_anagrafica = student.id;
      this.setState({ loading: true });
      ModelServices.addDiscente(this.props.corso.id, req, res => {
        this.tabellaRef.fetch();
        this.setState({ open_drawer: false, loading: false });
      }, () => {
        this.setState({ open_drawer: false, loading: false });
      });
    }
  }


  showAdd() {
    this.setState({ open_drawer: true });
  }

  renderActions() {
    return [{
      title: '',
      dataIndex: '',
      sorter: false,
      key: 'actions',
      //width: "300px",
      render: element => <div style={{ width: 140 }}>
        {  


          <div style={{ display: 'inline-block' }}>
            { checkPermission.hasPermission(this.props.UserStore.user, ['manageRegistration', 'enteManageRegistration', 'viewRegistration']) && 
              <Tooltip placement="top" title="Visualizza discente">
                <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => this.props.history.push("/affidamento/registro/" + this.props.match.params.id + "/discente/" + element.id)} />
              </Tooltip>
            }


            {checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto']) && element.giornate_assenze >= 5 && element.stato === affidamentoStatic.stato_iscrizione.iscritto && this.props.isEditableState &&
              <Tooltip placement="top" title={element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.attesa_giustificativo ? "In attesa di giustificativo" : "Richiedi giustificativo"}>
                <Button className="m2w" size="small" shape="circle" icon="file-protect" onClick={() => this.requestJustification(element)}
                  disabled={element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.attesa_giustificativo || this.state.loading} />
              </Tooltip>
            }

            {checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto']) && element.giornate_assenze >= 5 && element.giustificativo &&
              <Tooltip placement="top" title="Mostra giustificativo">
                <Button className="m2w" size="small" shape="circle" icon="file-search" 
                onClick={() => this.downloadJustification(element)} 
                disabled={element.giustificativo.media?.length === 0 || this.state.loading} />
              </Tooltip>
            }

            {checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto']) && element.stato === affidamentoStatic.stato_iscrizione.attesa_giustificativo && this.props.isEditableState &&
              <Tooltip placement="top" title="Accetta giustificativo">
                <Button className="m2w" size="small" shape="circle" icon="user-add" 
                onClick={() => this.accept(element)} disabled={element.giustificativo.media?.length === 0 || this.state.loading} />
              </Tooltip>
            }

            {checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto']) && element.stato === affidamentoStatic.stato_iscrizione.attesa_giustificativo && this.props.isEditableState &&
              <Tooltip placement="top" title="Espelli">
                <Button className="m2w" size="small" shape="circle" icon="user-delete" onClick={() =>
                  this.setState({ open_modal: true, student_to_remove: element })}
                  disabled={this.state.loading}
                  />
              </Tooltip>
            }

          </div>
        }
      </div>,
    }];
  }

  extraActions() {
    return [{
      title: '',
      dataIndex: '',
      sorter: false,
      key: 'created_at',
      render: element => <div style={{ width: 120 }}>
        <Popconfirm placement="top" title="Confermi di voler aggiungere il discente?"
          onConfirm={() => this.addIscrizione(element)}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            className="m5h" type="primary" icon="save" size="small">Aggiungi discente</Button>
        </Popconfirm>
      </div>,
    }];
  }

  renderAdd() {
    if (this.props.corso.id_tipo_finanziamento === affidamentoStatic.tipo_finanziamento.finanziato) {
      return (
        <CreateDiscenti {...this.props} onCreate={(data) => this.addAnagrafica(data)} />
      );
    } else {
      return (
        <>
          <p>Seleziona un discente da aggiungere al corso</p>
          <TabellaDiscenti {...this.props} filterStatus={affidamentoStatic.stato_iscrizione.in_attesa} extraActions={this.extraActions()} />
        </>
      );
    }
  }

  render() {
    return (
      <div>
        {/*
          per i corsi finanziati di Formazione Professionale avere la possibilità di inserire manualmente i
          discenti mentre per i corsi liberi avere la possibilità di acquisirli dalla graduatoria degli iscritti
        */}
        <Row type="flex" justify="start" className="m10h">
          {checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto']) && this.props.isEditableState &&
            <div className="m5h m5w">
              <Button className="m5h bg-green" icon="plus" onClick={() => this.showAdd()} loading={this.state.loading}>Aggiungi discente</Button>
            </div>
          }
        </Row>

        <TabellaDiscenti
          ref={ref => this.tabellaRef = ref}
          {...this.props}
          filterStatus={[affidamentoStatic.stato_iscrizione.iscritto, affidamentoStatic.stato_iscrizione.attesa_giustificativo, affidamentoStatic.stato_iscrizione.espulso].join(',')}
          fields={checkPermission.hasPermission(this.props.UserStore.user, ['editDiscentiPerProgetto']) ? ['giustificativo'] : []}
          renderActions={this.renderActions()}
        />

        <Drawer
          title="Aggiungi discente"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false })}
          visible={this.state.open_drawer}
          width={1024}
          destroyOnClose={true}
        >

          {this.renderAdd()}

        </Drawer>

        <Modal
          title="Inserisci note"
          visible={this.state.open_modal}
          confirmLoading={this.state.loading || false}
          onOk={() => {
            this.remove(this.state.student_to_remove, this.formSelRef.props.form.getFieldsValue()['note'] || "")
          }}
          onCancel={() => this.setState({ open_modal: false, student_to_remove: null })}
        >
          <CommonForm
            wrappedComponentRef={inst => (this.formSelRef = inst)}
            loading={this.state.loading || false}
            form_model={formSelLayout}
            values={{
            }}
            submitted={() => {
              this.remove(this.state.student_to_remove, this.formSelRef.props.form.getFieldsValue()['note'] || "")
            }}

          />
        </Modal>
      </div>
    );
  }
}

export default Discenti;
