import React, {Component} from 'react';
import {connect} from "react-redux";

import CommonForm from '@common/components/form';
import Documenti from '@common/components/Documenti';

import checkPermission from '@common/utils/check/permissions';

import { Button ,Upload, Icon } from 'antd';

import FileSaver from 'file-saver';
import EnteServices from '@accreditamento/services/Ente';
import MediaServices from '../../../../common/services/media/Media';

const formLayout = (component) => {
  const dirigentePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions']);
  const istruttorePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions']);
  const entePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']);
  
  let mediasAttivazione = [];
  if( component.props.values !== undefined && component.props.values.id_media_attivazione ) {
    mediasAttivazione = [component.props.values.id_media_attivazione];
  } else {
    mediasAttivazione = component.props.values && component.props.values.medias || [];
  }

  let mediasControdeduzione = [];
  if( component.props.values !== undefined && component.props.values.id_media_controdeduzioni ) {
    mediasControdeduzione = [component.props.values.id_media_controdeduzioni];
  } else {
    mediasControdeduzione = component.props.values && component.props.values.medias || [];
  }

  //console.warn('component.props.values', component.props.values)
  //console.warn('mediasAttivazione', mediasAttivazione)
  //console.warn('mediasControdeduzione', mediasControdeduzione)

  return {
    layout: 'vertical',
    multistep: false,
    rows: [

      // CONTRODEDUZIONI ENTE

      {
        cols: [
          {
            span: 24,
            items: (entePermissions && component.props.values && component.props.values.controdeduzioni_disabilitate) ? 
            [
              {
                label: <b>Attenzione: non è possibile inviare nuove controdeduzioni poichè il procedimento è stato riattivato</b>,
                name: 'simple_label_3',
                type: 'simple_label'                
              }
            ] : []
          }
        ]
      },

      {
        cols: [
          {
            span: 24,
            items: (component.props.values && component.props.values.controdeduzioni_disabilitate) || ((component.props.values) && (entePermissions || (component.props.values && component.props.values.stato == 5))) ? 
            [
              {
                type: 'textarea',
                label: 'Note sulle controdeduzioni',
                type_string: 'text', // field type
                name: 'note_controdeduzione',
                props: {
                  size: 'large',
                  readOnly: entePermissions && !component.props.values.controdeduzioni_disabilitate ? component.props.readOnly : true
                },
                validations: entePermissions ?
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ] : []
              }
            ] : []
          }
        ]
      },

      {
        cols: [
          {
            items: (component.props.values && component.props.values.controdeduzioni_disabilitate) || ((component.props.values) && (entePermissions || (component.props.values && component.props.values.stato == 5))) ? 
              [
                {
                  name: 'child',
                  type: 'child',
                  child: <Documenti
                    canDelete={true}
                    canAddNote={false}
                    tempFile={true}
                    unique={true}
                    media_types={component.props.GlobalStore.stubs.tipo_media}
                    medias={mediasControdeduzione}
                    //medias={component.props.values !== undefined && component.props.values.id_media_controdeduzioni !== undefined ? [component.props.values.id_media_controdeduzioni] : []}
                    //medias={component.props.values && component.props.values.medias || []}
                    current_domain="provvedimento"
                    current_subdomain="sospensione_attivazione_controdeduzioni"
                    /* Originale SISFO
                    required_types={component.props.GlobalStore.stubs.tipo_media
                      .filter(el => el.sottodominio === 'sospensione_attivazione_controdeduzioni' && el.dominio === 'provvedimento')
                      .map(e => e.tipo_media)
                    } */
                    required_types={[]}
                    ref_id={component.props.id_ente}
                    onUpload={(f) => component.updateMedias(f)}
                    onDelete={(id)=>component.deleteMedia(id)}
                    readOnly={entePermissions && !component.props.values.controdeduzioni_disabilitate ? component.props.readOnly : true}
                    descriptionSection={{lg:22}}
                    iconSection={{lg:2}}
                    uploadSection={{lg:24}}
                    hideModalNoteFacoltative={true}
                  />
                }
              ] 
              : [],
          },
        ],
      },  

      // ---------------------------------------

      {
        cols: [
          {
            span: 24,
            items: 
            [
              {
                label: <b>Ai sensi delle norme sul procedimento amministrativo, si comunica l’avvio del procedimento in oggetto nei confronti di codesto operatore</b>,
                name: 'simple_label_1',
                type: 'simple_label'                
              }
            ]
          }
        ]
      },

      {
        cols: [
          {
            span: 22,
            offset: 2,
            items: 
            [
              {
                type: 'textarea',
                label: 'Amministrazione competente',
                type_string: 'text', // field type
                name: 'amministrazione_competente',
                props: {
                  size: 'large',
                  readOnly: entePermissions ? true : component.props.readOnly
                },
                validations: entePermissions ?
                [] : 
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ]
              }
            ]
          }
        ]
      },
      
      {
        cols: [
          {
            span: 22,
            offset: 2,
            items: 
            [
              {
                type: 'textarea',
                label: 'Oggetto e motivazione del procedimento promosso',
                type_string: 'text', // field type
                name: 'oggetto_motivazione',
                props: {
                  size: 'large',
                  readOnly: entePermissions ? true : component.props.readOnly
                },
                validations: entePermissions ?
                [] : 
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ]
              }
            ]
          }
        ]
      },      

      {
        cols: [
          {
            span: 22,
            offset: 2,
            items: 
            [
              {
                type: 'textarea',
                label: 'Ufficio, domicilio digitale dell\'amministrazione e persona responsabile del procedimento',
                type_string: 'text', // field type
                name: 'ufficio',
                props: {
                  size: 'large',
                  readOnly: entePermissions ? true : component.props.readOnly
                },
                validations: entePermissions ?
                [] : 
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ]
              }
            ]
          }
        ]
      },  

      {
        cols: [
          {
            span: 22,
            offset: 2,
            items: 
            [
              {
                type: 'textarea',
                label: 'Termine entro cui deve concludersi il procedimento e i rimedi esperibili in caso di inerzia dell\'amministrazione',
                type_string: 'text', // field type
                name: 'termine_conclusione',
                props: {
                  size: 'large',
                  readOnly: entePermissions ? true : component.props.readOnly
                },
                validations: entePermissions ?
                [] : 
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ]
              }
            ]
          }
        ]
      },  

      {
        cols: [
          {
            span: 22,
            offset: 2,
            items: 
            [
              {
                type: 'textarea',
                label: 'Modalità con le quali è possibile prendere visione degli atti, accedere al fascicolo ed accedere i diritti previsti dalla presente legge',
                type_string: 'text', // field type
                name: 'modalita_visione_atti',
                props: {
                  size: 'large',
                  readOnly: entePermissions ? true : component.props.readOnly
                },
                validations: entePermissions ?
                [] : 
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ]
              }
            ]
          }
        ]
      },  

      {
        cols: [
          {
            span: 24,
            items: 
            [
              {
                label: <b>L’operatore ha diritto di prendere visione degli atti del procedimento e/o di presentare memorie scritte e documenti, che l'amministrazione ha l'obbligo di valutare ove siano pertinenti all'oggetto del procedimento</b>,
                name: 'simple_label_2',
                type: 'simple_label'                
              }
            ]
          }
        ]
      },

      {
        cols: [
          {
            span: 22,
            offset: 2,
            items: 
            [
              {
                type: 'textarea',
                label: 'Termine entro il quale l’operatore può prendere visione degli atti del procedimento e/o presentare memorie scritte e documenti',
                type_string: 'text', // field type
                name: 'termine_visione',
                props: {
                  size: 'large',
                  readOnly: entePermissions ? true : component.props.readOnly
                },
                validations: entePermissions ?
                [] : 
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ]
              }
            ]
          }
        ]
      },

      {
        // Le note interne sono solo per gli operatori regionali
        cols: entePermissions ? [] : [
          {
            span: 24,
            items: 
            [
              {
                type: 'textarea',
                label: 'Note interne',
                type_string: 'text', // field type
                name: 'note_interne',
                props: {
                  size: 'large',
                }
              }
            ]
          }
        ]
      },  
            
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Conferma',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ],
    onSubmit: 'submitted',
  };
};

class ProvvedimentoForm extends Component {

  state = {
    loading: false,
    medias: [],
  };

  confirm(data) {
    this.props.submit(data);
  }

  getValues() {
    return this.props.values || {};
  }

  updateMedias(media) {
    let just = localStorage.getItem('document_form_provvedimento');
    if(just) {
      just = JSON.parse(just)
    } else {
      just = { medias: [] }
    }

    just.medias = [...just.medias, media];
    localStorage.setItem('document_form_provvedimento', JSON.stringify(just));

    if (this.props.onUpload) this.props.onUpload(media)
    this.setState({ medias: [...this.state.medias, media] })
  }

  /*
  async validate() {
    return new Promise((resolve, reject) => {

        this.formRef.props.form.validateFields((errors, values) => {

            if (this.state.medias.filter(e => e.tipo.sottodominio === 'sospensione_attivazione' && e.tipo.dominio === 'provvedimento' && e.deleted !== 1).length === 0) {
              message.error('Inserire documentio');
              resolve(false)
            } else {
              resolve(true);
            }

        });
    });
  }
  */

  deleteMedia(id) {
    try{
      if (this.props.onDeleteMedia) this.props.onDeleteMedia(id)
    } catch(e) {
      console.error(e)
    }

  }

  render () {
    
    let max_termine_presentazione_controdeduzioni = 0;

    switch(this.props.provvedimentoType){
      case 'COMUNICAZIONE_SOSPENSIONE':
        max_termine_presentazione_controdeduzioni = 45;
          break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        max_termine_presentazione_controdeduzioni = 180;
          break;
      case 'COMUNICAZIONE_REVOCA':
        max_termine_presentazione_controdeduzioni = 10;
          break;
      case 'PROCEDIMENTO':
        max_termine_presentazione_controdeduzioni = 10;
          break;
    }   

    //console.warn('this.props.provvedimentoType', this.props.provvedimentoType);
    //console.warn('this.getValues()', this.getValues());

    let procedimento    = this.getValues();
    let disabilita_form = checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && procedimento && ( procedimento.stato == 0 || procedimento.stato == 5) && procedimento.controdeduzioni_disabilitate;

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          provvedimentoType={this.props.provvedimentoType}
          readOnly={this.props.readOnly || disabilita_form}
          submitted={(dati) => this.confirm(dati)}
          range={(rule, value, cb) => {
            if(value > max_termine_presentazione_controdeduzioni) {
              cb("Il termine presentazione controdeduzioni non può andare oltre i " + max_termine_presentazione_controdeduzioni + " giorni");
            } else {
              cb();
            }
          }}          
        />
      </div>
    );
  }
}

export default connect(({UserStore, GlobalStore})=>({UserStore, GlobalStore}), null, null, { withRef: true })(ProvvedimentoForm);