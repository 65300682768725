import React, { Component } from 'react';

import { Card } from 'antd';
import TableComponent from '@common/components/table';

import ModelServices from '@common/services/locations/Comuni';

import _ from '@common/utils/formatters/_';
import FilterForm from '@common/components/filterForm';

const model = (component) => {
    return {
        with_server_pagination: true, // metti paginazione lato server
        columns: [
            {
                title: 'Comune',
                dataIndex: 'comune',
                sorter: true,
                key: 'comune',
            },
            {
                title: 'Provincia',
                dataIndex: 'provincia.provincia',
                sorter: false,
                key: 'provincia',
            },
            {
                title: 'Regione',
                dataIndex: 'regione.regione',
                sorter: false,
                key: 'regione',
            },
        ]
    }
}

const filterFormModel = (component) => {

    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Regione',
                                name: 'regione',
                                props: {
                                    allowClear: true,
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                                options: component.props.GlobalStore.stubs.regione,
                            },
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Provincia',
                                name: 'nome_provincia',
                                props: {
                                    allowClear: true,
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                                options: component.props.GlobalStore.stubs.provincia,
                            }
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'string',
                                label: 'Comune',
                                name: 'comune',
                            }
                        ]
                    },

                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'submit',
                                type: 'submit',
                                text: 'Cerca',
                                btn_type: 'primary'
                            }
                        ]
                    }
                ]
            },
        ]
    };
}

export default class TabellaComuni extends Component {
    base_url = '/comuni'
    default_order = 'comune'

    state = {
        loading: false,

        data: {
            total: 0,
            current: 1,
            data: []
        }
    }

    componentDidMount() {
        this.callAndUpdate(this.props)
    }

    /**
     * Carica dati
     * @param  {[type]} props [description]
     * @return {[type]}       [description]
     */
    callAndUpdate(props) {
        let { params, pager } = _.getFilterParam(props, this.default_order);
        this.setState({ loading: true });
        params = {
            ...params,
            with: ['regione'].join(',')
        }
        ModelServices.list(params, res => {
            let data = _.getResponse(res);
            this.setState({
                loading: false,
                data: data
            }, () => this.refs.table_component.updateTableData(
                data.data,
                data.current,
                data.total,
                pager)
            );

        }, () => {
            this.setState({ loading: false });
            //console.log('errore dati', e)
        })
    }

    /**
     * Se cambia la stringa aggiorna
     * @param  {[type]} newProps [description]
     * @return {[type]}          [description]
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.callAndUpdate(this.props.location.search)
        }
    }

    /**
     * Ricarica dopo update
     * @return {[type]} [description]
     */
    refresh() {
        this.callAndUpdate(this.props)
    }

    /**
     * Chiamato da sort in tabella
     * @param  {[type]} obj [description]
     * @return {[type]}     [description]
     */
    updateQueryStr(obj) {
        _.formatSortChange(this.props, this.base_url, obj);
    }

    render() {
        return (
            <div>
                <FilterForm
                    formLayout={filterFormModel(this)}
                    base_url={this.base_url}
                    search={this.props.location.search}
                    history={this.props.history}
                />
                <Card
                    className="shadow no-pad"
                    bordered={false}
                >
                    <TableComponent
                        data={this.state.data}
                        model={model(this)}
                        table_props={{ rowKey: 'id' }}
                        size="medium"
                        ref="table_component"
                        loading={this.state.loading}
                        with_server_pagination={true}
                        updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                        updateSort={(sort, order) => this.updateQueryStr({ sort: sort, order: order })}
                    />
                </Card>
            </div>
        );
    }
}