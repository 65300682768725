import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card, Row, Col, Button, } from 'antd'
import FileSaver from 'file-saver';
import Wrapper from '@common/decorators/Wrapper';
//import CheckPermissions from '@common/decorators/CheckPermissions';
import checkPermissions from '@common/utils/check/permissions';
import ModelServices from '../services/QualificaProfessionale';
import QualificaProfessionaleForm from '../forms/QualificaProfessionaleForm';

import SpinnerLoading from '@components/SpinnerLoading';
import DettagliQualificaProfessionale from '../components/DettagliQualificaProfessionale';

@Wrapper({ route: 'DettagliQualificaProfessionale', hasSidebar: true, hasHeader: true, hasFooter: true, public: true })
//@CheckPermissions(['viewQualificaProfessionale'])
class DettagliQualificaProfessionaleRoute extends Component {


  state = {
    loading: false,
    data: null,
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    if (this.props.match.params.id) {
      this.setState({ loading: true });
      ModelServices.detail({
        expand: [
          'settore', 'areeAttivita'
        ].join(',')
      }, this.props.match.params.id).then(
        res => {
          this.setState(
            {
              data: res.data.data,
              loading: false
            }
          );
        },
      ).catch(() => {
        this.setState({ loading: false });
        this.props.history.push('/repertorio/qualifiche');
      });
    } else {
      this.setState(
        {
          data: undefined
        }
      );
    }

  }
  renderTitle() {
    return this.state.data?.denominazione_figura || '#Titolo non valido#';
  }


  saveBlob(blob, title) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, title);
  }


  render() {

    return (
      <div className="scheda">
        {this.state.loading ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{this.props.match.params.id ? this.renderTitle() : 'Nuova qualifica professionale'}</h3>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/repertorio/qualifiche">Qualifiche Professionali</Link></Breadcrumb.Item>
                {this.state.data && <Breadcrumb.Item>{this.props.match.params.id ? this.renderTitle() : 'Nuova qualifica professionale'}</Breadcrumb.Item>}
                {!this.state.data && <Breadcrumb.Item>Nuova qualifica professionale</Breadcrumb.Item>}
              </Breadcrumb>
            </div>

            {this.state.data &&
              <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
                <Col style={{ flex: "1 1 70%" }} >
                  <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
                    <Card style={{ flex: "1 1 auto", marginBottom: 12 }}
                      bordered={false}>
                      <Button icon="file-pdf" className="m5h m5w" type="primary"
                        loading={this.state.downloading}
                        disabled={this.state.downloading}
                        onClick={() => {
                          this.setState({ downloading: true })
                          ModelServices.printScheda(this.state.data.id, (res) => {
                            const today = new Date();
                            const month = today.getMonth() + 1;
                            const year = today.getFullYear();
                            const date = today.getDate();
                            const hour = ('0' + today.getHours()).slice(-2);
                            const minu = ('0' + today.getMinutes()).slice(-2);
                            let nome_file = `scheda_${date}${month}${year}_${hour}${minu}.pdf`;
                            this.saveBlob(res.data, nome_file);
                            this.setState({ downloading: false })
                          }, (err) => {
                            this.setState({ downloading: false })
                          })
                        }}>Scarica scheda della figura professionale</Button>
                    </Card>
                  </Row>
                </Col>
              </Row>
            }


            {this.state.data ?
              <DettagliQualificaProfessionale
                {...this.props}
                repertorio={this.state.data}
                readOnly={!checkPermissions.hasPermission(this.props.UserStore.user, ['editQualificaProfessionale'])}
              /> :
              <QualificaProfessionaleForm
                {...this.props}
                repertorio={{}}
                readOnly={!checkPermissions.hasPermission(this.props.UserStore.user, ['editQualificaProfessionale'])}
              />}
          </>
        }
      </div>
    );
  }
}

export default DettagliQualificaProfessionaleRoute;
