import BaseRequest from '@common/services/_baseRequest';


export default {

  list: (vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/corso',
      cb,
      ecb || null,
      null
    );
  },

  detail: (params = {}, id_corso = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/corso/' + id_corso,
      cb,
      ecb || null,
      null
    );
  },

  listDiscenti: (params = {}, id_corso = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/corso/' + id_corso + '/iscrizioni',
      cb,
      ecb || null,
      null
    );
  },


  addDiscente(id_corso, docente, cb = null, ecb = null) {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      docente,
      null,
      'post',
      'affidamento/corso/' + id_corso + '/add-discente',
      cb,
      ecb || null,
      null
    );
  },

  listDocenti: (params = {}, id_corso = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/corso/' + id_corso + '/docenti',
      cb,
      ecb || null,
      null
    );
  },

  addDocente(id_corso, docente, cb = null, ecb = null) {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      docente,
      null,
      'post',
      'affidamento/corso/' + id_corso + '/add-docente',
      cb,
      ecb || null,
      null
    );
  },

  prossimaLezione: (params, id_corso, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/corso/' + id_corso + '/prossima-lezione',
      cb,
      ecb || null,
      null
    );
  },

  listPresenze: (params = {}, id_corso = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/corso/' + id_corso + '/presenze',
      cb,
      ecb || null,
      null
    );
  },

  inviaInviti: (id_corso = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/corso/' + id_corso + '/invia-inviti',
      cb,
      ecb || null,
      null
    );
  },

  inviaInvito: (id_corso = null, id_iscrizione = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/corso/' + id_corso + '/invia-invito/' + id_iscrizione,
      cb,
      ecb || null,
      null
    );
  },


  myiscrizione: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      data,
      {},
      null,
      "get",
      'affidamento/corso/' + id_progetto + '/my-iscrizione',
      cb,
      ecb || null,
      null
    );
  },

  predisponiRegistro: (id_progetto, params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      "get",
      'affidamento/corso/' + id_progetto + '/predisponi-registro',
      cb,
      ecb || null,
      null
    );
  },

  cancellaRegistro: (id_progetto, params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      "get",
      'affidamento/corso/' + id_progetto + '/cancella-registro',
      cb,
      ecb || null,
      null
    );
  },
}
