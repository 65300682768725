import React, {Component} from 'react';
import {Tabs, Spin} from 'antd';

import EsperienzeProfessionali from '../../../../anagrafica/components/EsperienzeProfessionali';
import EsperienzeFormative from '../../../../anagrafica/components/EsperienzeFormative';

import AnagraficaServices from '../../../../anagrafica/services/Anagrafica';

const TabPane = Tabs.TabPane;

class TitoliCompetenze extends Component {

  state = {
    loading: false,
    searched_anagrafica: false,
    model_anagrafica: {},
    open_drawer: false,
    selected_funzione: null
  }

  componentDidMount () {
    AnagraficaServices.view({expand: 'esperienzeProfessionali,esperienzeFormative'}, this.props.anagrafica.id, (res)=>{
      this.setState({model_anagrafica: res.data.data})
    });
  }

  render () {
    return <div>
      {
        this.state.model_anagrafica.id
        ?
        <Tabs defaultActiveKey="1" size="medium">
          <TabPane tab={<span >Esperienze formative</span>} key="1">
            <EsperienzeFormative
              {...this.props}
              readOnly={this.props.readOnly}
              model={this.state.model_anagrafica}
              onUpdate={(obj)=>{this.setState({model_anagrafica: {...this.state.model_anagrafica, ...obj} })}}
            />
          </TabPane>
          <TabPane tab={<span >Esperienze professionali</span>} key="2">
            <EsperienzeProfessionali
              {...this.props}
              readOnly={this.props.readOnly}
              model={this.state.model_anagrafica}
              onUpdate={(obj)=>{this.setState({model_anagrafica: {...this.state.model_anagrafica, ...obj} })}}
            />
          </TabPane>
        </Tabs>
        : <Spin size="large" />
      }

    </div>
  }

}
export default TitoliCompetenze;