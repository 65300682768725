import BaseRequest from '@common/services/_baseRequest';

export default {

	list: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'tabelle/istat-professioni',
			cb,
			ecb || null,
			null
		);
	},

}
