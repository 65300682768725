import React, { Component } from 'react';
import Documenti from '@common/components/Documenti';


import CommonForm from '@common/components/form';

const formLayout = (component) => {
    return {
      layout: 'vertical',
      multistep: false,
      rows: [
        {
          cols: [
            {
              items: [
                {
                  span: 4,
                  name: 'relazione_conclusiva',
                  type: 'title',
                  element: 'h4',
                  text: 'Relazione conclusiva',
                  props: {
                    style: { textAlign: 'left' },
                    className : 'm10h'
                  },
                }
              ],
            },
          ],
        },

        {
          cols: [
            {
              items: [
                {
                  name: 'relazione_conclusiva_upload',
                  type: 'child',
                  child: <Documenti
                    readOnly={!component.canUploadRelazioneConclusiva()}
                    root={'progetto'}
                    canDelete={true}
                    required_dates={false}
                    accept={'.pdf'}
                    hideTitle={true}
                    uploadButtonText={'Carica il documento'}
                    accreditation_id={component.props.corso.id}
                    unique={true}
                    tempFile={false}
                    media_types={component.props.tipo_media}
                    medias={component.props.studente.media || []}
                    current_domain="iscrizione"
                    current_subdomain="relazione_conclusiva"
                    canAddNote={false}
                    required_types={component.props.tipo_media
                      .filter(el => el.sottodominio === 'relazione_conclusiva' && el.dominio === 'iscrizione')
                      .map(e => e.tipo_media)
                    }
                    ref_id={component.props.studente.id}
                    //onUpload={() => component.props.refresh()}
                    //onDelete={() => component.props.refresh()}
                  />
                }
              ],
            },
          ],
        },




    ],
    }
  };
class TabStage extends Component {



    state = {

    }

    componentDidMount() {

    }

    submit(){

    }

    canUploadRelazioneConclusiva(){
        return (this.props.corso?.tutors?.some(t => t.id === this.props.UserStore.user.anagrafica.id))
    }


    render() {
      return (
        <div>
 <CommonForm
          loading={this.state.loading || false}
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          form_model={formLayout(this)}
          values={{}}
          onSubmit={this.submit()}

        />
        </div>
      );
    }
  }

  export default TabStage;