export default {
    sectionMap: {
        B_1_1: 'Indagine preliminare di mercato / Analisi dei fabbisogni professionali del territorio/analisi del fabbisogno formativo dei potenziali destinatari',
        B_1_2: 'Ideazione e progettazione',
        B_1_3: 'Pubblicizzazione e promozione del progetto',
        B_1_4: 'Selezione e orientamento partecipanti',
        B_1_5: 'Elaborazione materiale didattico',
        B_1_6: 'Formazione personale docente',
        B_1_7: 'Determinazione del prototipo',
        B_1_8: 'Spese di costituzione ATI/ATS / polizza fideiussoria',
        B_1_9: 'Altro (Preparazione)',
        // mapSection.set('B_2', 'Realizzazione');
        B_2_1: 'Docenza',
        B_2_2: 'Tutoraggio di aula ',
        B_2_3: 'Personale tecnico amministrativo ',
        B_2_4: 'Spese di viaggio, vitto e alloggio docenti',
        B_2_5: 'Attività di sostegno all’utenza svantaggiata: docenza di supporto, ecc.',
        B_2_6: 'Spese di sostegno all’utenza (trasporto, vitto, alloggio partecipanti, sostegno a persone con disabilità)',
        B_2_7: 'Utilizzo materiali di consumo e didattico (es. costo stampa attestati e libretto formativo)',
        B_2_8: 'Utilizzo attrezzature per l’attività programmata',
        B_2_8_1: 'Immobili',
        B_2_8_2: 'Costi per attrezzature',
        B_2_9: 'Costi per servizi',
        B_2_10: 'Rendicontazione',
        B_2_11: 'Esami: gettoni presenza rimborso spese viaggi vitto e alloggio Commissione',
        B_2_12: 'Spese per Certificazione rilasciata da soggetti esterni (es. ECDL – Lingue straniere, ecc.)',
        B_2_13: 'Assicurazione destinatari',
        B_2_14: 'Altre funzioni tecniche (specificamente previste e descritte nel progetto)',
        //  mapSection.set("B_3 ", "Diffusione dei risultati ",
        B_3_1: "Conferenze, articoli",
        B_3_2: "Organizzazione di seminari finali",
        B_3_3: "Elaborazione reports e studi",
        B_3_4: "Pubblicazioni finali",
        //  mapSection.set("B_4", "Direzione progetto e controllo interno",
        B_4_1: "Direzione e valutazione finale dell'operazione o del progetto",
        B_4_2: "Direttore di corso o di progetto interno",
        B_4_3: "Direttore di corso o di progetto esterno",
        B_4_4: "Componenti comitati tecnico-scientifici interni",
        B_4_5: "Componenti comitati tecnico-scientifici esterni",
        B_4_6: "Coordinatori interni",
        B_4_7: "Coordinatori esterni",
        B_4_8: "Consulenti /ricercatori",
        B_4_9: "Alloggio direttori, coordinatori, valutatori",
        B_4_10: "Vitto direttori, coordinatori, valutatori",
        B_4_11: "Viaggi direttori, coordinatori, valutatori",
        B_4_12: "Valutatori interni",
        B_4_13: "Valutatori esterni",
        B_4_14: "Coordinamento e amministrazione tecnica organizzativa",
        B_4_15: "Monitoraggio fisico-procedurale",
        B_4_16: "Altro (Direzione e controllo interno)",
        //  mapSection.set("C", "COSTI INDIRETTI (tasso forfettario di cui all'art. 68, par. 1, lettera b), del Reg. (UE) n. 1303/2013) "
        C_1: "Contabilità generale (civilistico, fiscale)",
        C_2: "Servizi ausiliari (centralino, portineria, ecc.)",
        C_3: "Pubblicità istituzionale",
        C_4: "Forniture per ufficio",
        C_5: "Altro (Costi indiretti)",
    }

}