import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Card, Popconfirm, Modal } from 'antd';
import ModelServices from '@accreditamento/services/Nomina';
import FileSaver from 'file-saver';
import nominaStatic from '@common/utils/static/nomina';
import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';
import checkPermission from '@common/utils/check/permissions';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica file pdf",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};



class NominaActions extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },
    nomina: {},
    open_modal_audit_refuse: false,
    open_modal_audit_integration: false,
    loading: false,
    downloading: false,
    confirming: false,
    accepting: false,
    refusing: false
  }



  componentDidMount() {
    if (this.props.accreditamento.id && this.props.nomina.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento !== this.props.accreditamento ||
      (newProps.nomina !== this.props.nomina) ||
      (newProps.nomina.stato !== this.props.nomina.stato)) this.loaded(newProps)
  }

  loaded(props) {
    this.setState({ loaded: true });
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `nomina_${date}${month}${year}_${hour}${minu}.pdf`;
    FileSaver.saveAs(_blob, nome_file);

    this.setState({loading: false, downloading: false})

  }

  /**
   * Carica il file
   * @return {[type]} [description]
   */
  upload() {
    if(!this.state.loading) {

      this.setState({
        loading: true
      }, () => {
        ModelServices.upload(this.props.accreditamento.id, this.props.nomina.id, {
          filename: 'file',
          note: this.state.note || ""
        }, { ...this.state.file, chiave: 'file' }, () => {

          this.props.refreshInline({
            nomina: [{
              ...this.props.nomina,
              stato: nominaStatic.stato.firmata_dirigente
            }]
          })
          this.setState({ open_modal_doc: false, loading: false });

        }, () => this.setState({ open_modal_doc: false, loading: false }))
      })

    }
  }

  async uploadFile(file) {
    await this.setState({ file: { file: file, name: file.name }, open_modal_doc: true });

    return;

  }


  async uploadFileIspettore(file) {
    //await this.setState({ file: { file: file, name: file.name }, open_modal_nom: true });

    this.setState({
      loading: true
    }, () => {
      ModelServices.confirm_feedback(this.props.accreditamento.id, this.props.nomina.id, {
        filename: 'file'
      }, { file: file, chiave: 'file' }, (res) => {

        this.props.refreshInline(res.data.data)
        this.setState({ loading: false });

      }, () => this.setState({loading: false }))
    })

    return;

  }

  renderRequestDirigenteFileFirmato() {
    return <div style={{ marginTop: 18 }}>
      {
        this.props.nomina.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_richiesta_firmata_dirigente).map(l => {
          return <div key={l.id}><p style={{ display: 'inline-block' }}><strong>Dispositivo di servizio caricato dal dirigente:</strong> </p><DocSpan key={l.id} file={l} /></div>
        })
      }
    </div>
  }

  renderNominaFirmata() {

    return <div style={{ marginTop: 18 }}>
      {
        this.props.nomina.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_feedback_ispettore).map(l => {
          return <div key={l.id}><p style={{ display: 'inline-block' }}><strong>Documento firmato dall&apos;ispettore:</strong> </p><DocSpan key={l.id} file={l} /></div>
        })
      }
    </div>
  }


  bottoneAccettazioneNomina() {
    /* https://cb.schema31.it/cb/issue/407212: la nomina può essere accettata direttamente senza caricare nulla
    return !this.props.nomina.media.some(
      m =>
        m.tipo.sottodominio === 'autocertificazione_ispettore' && m.deleted !== 1 && m.tipo.tipo_media.toUpperCase() === 'AUTOCERTIFICAZIONE ASSENZA CONFLITTO DI INTERESSI'
    )
      ? <p>Per accettare la nomina inserisci l&apos;autocertificazione di assenza conflitti di interesse</p>
      : */
    return <Popconfirm placement="top" title="Accetti la nomina?"
        onConfirm={() => {
            this.setState({feedback: 1, open_modal_feedback: true})
          }}
        okText="Si" cancelText="No">
        <Button
        disabled={this.state.loading}
        loading={this.state.accepting}
        style={{ marginRight: 8 }} className="m5h" type="primary" icon="check">Accetta la nomina</Button>
      </Popconfirm>
  }

  /**
   * Azioni dell'ispettore se è chi gestisce l'audit
   * @return {[type]} [description]
   */
  renderIspettoreActions() {
    if (this.props.UserStore.user.id !== this.props.nomina.id_ispettore) return null;

    return <div>

      {
        (this.props.nomina.stato === nominaStatic.stato.attesa_conferma)
          ?
          <div>
            <Modal
              title="Inserisci note facoltative"
              visible={this.state.open_modal_feedback}
              confirmLoading={this.state.loading}
              onOk={async () => {
                if(!this.state.loading) {
                    this.setState({
                      loading: true
                    }, () => {

                      ModelServices.feedback(
                      this.props.accreditamento.id,
                      this.props.nomina.id,
                      { accept: this.state.feedback, note: this.formFeedbackIsp.props.form.getFieldsValue()['note'] },
                      (res) => {
                        this.setState({
                          loading: false,
                          refusing: false
                        })
                        this.props.refreshInline(res.data.data)
                      },
                      () => this.setState({
                          loading: false,
                          refusing: false
                        }))

                    })

                  }
              }}
              onCancel={() => this.setState({ open_modal_feedback: false })}
            >
              <CommonForm
                wrappedComponentRef={inst => (this.formFeedbackIsp = inst)}
                loading={this.state.loading || false}
                form_model={formSelLayout}
                values={{

                }}
              />
            </Modal>
            {this.bottoneAccettazioneNomina()}
            <Popconfirm placement="top" title="Rifiuti la nomina?"
              onConfirm={() => {
                 this.setState({feedback: 0, open_modal_feedback: true})
              }}
              okText="Si" cancelText="No">
              <Button
              disabled={this.state.loading}
              loading={this.state.refusing}
              className="m5h" type="danger" ghost icon="close">Rifiuta la nomina</Button>
            </Popconfirm>
          </div>
          : null
      }
      {
        (this.props.nomina.stato === nominaStatic.stato.accettata ||
          this.props.nomina.stato === nominaStatic.stato.rifiutata)
          ?
          <div>
            <p>Carica il Dispositivo di servizio</p>
            <CommonForm
              wrappedComponentRef={inst => (this.formRef = inst)}
              loading={this.state.loading || false}
              form_model={formLayout}
              values={{
              }}
              fileListProp={() => []}
              customUploadRequest={obj => {
                this.uploadFileIspettore(obj.file);
              }}
            />
          </div>
          : null
      }

    </div>
  }


  render() {
    return (this.state.loaded) ?
      <div>
        <Card
          className="shadow no-pad"
          bordered={false}
        >
          {
            this.props.loading
            ?
            <div className="overlayer"></div>
            : null
          }
          <div style={{ marginTop: 16, marginBottom: 8 }}>{this.renderRequestDirigenteFileFirmato()}</div>
          <div style={{ marginTop: 16, marginBottom: 8 }}>{this.renderNominaFirmata()}</div>
          {this.props.nomina.note_ispettore && this.props.nomina.note_ispettore !== '' ? <span><strong>Note:</strong> {this.props.nomina.note_ispettore}</span> : ''}
          {checkPermission.hasPermission(this.props.UserStore.user, ['ispettorePermissions', 'adminPermissions', 'HelpdeskPermissions']) ? this.renderIspettoreActions() : null}
        </Card>
      </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(NominaActions);
