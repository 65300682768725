import BaseRequest from "@common/services/_baseRequest";


export default {

	progress: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/' + id_accreditamento + '/progress',
			cb,
			ecb || null,
			null
		);
	},

	list: (vars = {}, cb = null, ecb = null) => {
		console.log('vars', vars)
		var req = new BaseRequest();
		req.makeRequest(
			vars,
			{},
			null,
			"get",
			// CHIAMATA ORIGINALE USATA SOLO IN modules/accreditamento/components/List.js
			//'accreditamento?fields=id_elenco_regionale,id,tipo_accreditamento,blocked_timer,numero_protocollo,tipo,stato,settori,stato_flusso,stato_flusso_formatted,data_acquisizione,updated_at,created_at,data_timer_audit,integration_added_days&with=aule,tipologie,istruttore,settori,sedi.indirizzo,storico&expand=ispezione',	//&expand=richiedente,ente,richiedente.contatti,sedi,accreditamentosettori,accreditamentosettori.settore,audit,audit.integrazione,istruttore
			// 	id_elenco_regionale si usa per la TIPOLOGIA ACCREDITAMENTO C - created_at e storico SI USA PER IL PASSAGGIO DA PROVVISORIO A DEFINITIVO
			//'accreditamento?fields=                    id,tipo_accreditamento,blocked_timer,numero_protocollo,tipo,stato,       ,stato_flusso,stato_flusso_formatted,data_acquisizione,updated_at,          ,data_timer_audit,integration_added_days&with=     tipologie,istruttore,        sedi.indirizzo,       &expand=ispezione',
			// SCREMANDO GLI SPAZI:
			'accreditamento?fields=id,tipo_accreditamento,blocked_timer,numero_protocollo,tipo,stato,stato_flusso,stato_flusso_formatted,data_acquisizione,updated_at,data_timer_audit,integration_added_days&with=tipologie,istruttore,sedi.indirizzo&expand=ispezione',
			cb,
			ecb || null,
			null
		);
	},

	list_by_ente: (id_ente, vars = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			vars,
			{},
			null,
			"get",
			'accreditamento/list-ente/' + id_ente + '? fields=id,tipo_accreditamento,blocked_timer,numero_protocollo,tipo,stato,settori,stato_flusso,stato_flusso_formatted,data_acquisizione,updated_at,created_at,data_timer_audit,integration_added_days&with=aule,tipologie,istruttore,settori,sedi.indirizzo,storico',	//&expand=richiedente,ente,richiedente.contatti,sedi,accreditamentosettori,accreditamentosettori.settore,audit,audit.integrazione,istruttore
			cb,
			ecb || null,
			null
		);
	},

	detail: (params = {}, id = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			params,
			{},
			null,
			"get",
			'accreditamento/' + id,
			cb,
			ecb || null,
			null
		);
	},

	//New scheda accreditamento
	create: (tipo_accreditamento, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{tipo_accreditamento: tipo_accreditamento},
			null,
			"post",
			'accreditamento',
			cb,
			ecb || null,
			null
		);
	},


	duplica: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/' + id_accreditamento + '/duplica',
			cb,
			ecb || null,
			null
		);
	},

	rinnova: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/' + id_accreditamento + '/rinnovo',
			cb,
			ecb || null,
			null
		);
	},

	definitivo: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/' + id_accreditamento + '/definitivo',
			cb,
			ecb || null,
			null
		);
	},

	variazione: (id_accreditamento, tipo_variazione, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{tipo_variazione: tipo_variazione},
			null,
			"post",
			'accreditamento/' + id_accreditamento + '/variazione/' + tipo_variazione,
			cb,
			ecb || null,
			null
		);
	},

	delete: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	},

	pdfRequest: (id, pwd, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/request/pdf-richiesta/' + id + "/" + pwd,
			cb,
			ecb || null,
			null
		);
	},

	printPdf: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/' + id + '/print-pdf',
			cb,
			ecb || null,
			null
		);
	},

	docVariazioni: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/' + id + '/variazioni',
			cb,
			ecb || null,
			null
		);
	},




	//List docenti
	getPecLog: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/' + id_accreditamento + '/pec-log',
			cb,
			ecb || null,
			null
		);
	},


	getTimer: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/' + id_accreditamento + '/timer',
			cb,
			ecb || null,
			null
		);
	},

	getDocuments: (id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/' + id_accreditamento + '/documenti',
			cb,
			ecb || null,
			null
		);
	},

	feedbackSection: (id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/' + id_accreditamento + '/feedback-section',
			cb,
			ecb || null,
			null
		);
	},

/*
	//Save scheda accreditamento
	saveStoricoAccreditamento: (id_accreditamento = null, vars = {}, files = {}, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			vars,
			files,
			"post",
			'accreditamento/' + id_accreditamento + '/storico-accreditamento',
			cb,
			ecb || null,
			null
		);
	},*/

	storicoDiff: (id_accreditamento, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{
				format: 1
			},
			data,
			null,
			"get",
			'accreditamento/' + id_accreditamento + '/storico-diff',
			cb,
			ecb || null,
			null
		);
	},

	download_casellario_word: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/scheda/dati-identificativi/' + id + '/autodichiarazione-casellario-word',
			cb,
			ecb || null,
			null
		);
	},

	getBySettore: (id_settore = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/find-by-settore/' + id_settore,
			cb,
			ecb || null,
			null
		);
	},
}
