import React, { Component } from 'react';
import CommonForm from '@common/components/form';

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [

            {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'select',
                                label: 'Tipologia',
                                name: 'testType',
                                options_props: '_tipo_test',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                    {
                        span: 12,
                        items: [
                            {
                                type: 'select',
                                label: 'Caratteristica',
                                name: 'testFeature',
                                options_props: '_caratteristiche_test',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                              }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Competenza/e oggetto della valutazione',
                                name: 'testSkills',
                                type_string: 'text',
                              }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Prestazione attesa',
                                name: 'testPerformance',
                                type_string: 'text',
                              }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Criteri di accettabilità della prova',
                                name: 'testCriteria',
                                type_string: 'text',
                              }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Modalità di svolgimento delle prove',
                                name: 'testProcedure',
                                type_string: 'text',
                            }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Tempi e durata di somministrazione delle prove',
                                name: 'testDuration',
                                type_string: 'text',
                            }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Strumenti per la realizzazione della prova di verifica finale (attrezzature e/o materiali, spazi, ecc.)',
                                name: 'finalTestTools',
                                type_string: 'text',
                            }
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Strumenti per la valutazione delle prestazioni (griglie di analisi, schede di osservazioni, etc.)',
                                name: 'valutationTools',
                                type_string: 'text',
                            }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'btn',
                                type: 'submit',
                                text: 'Salva',
                                btn_type: 'primary'
                            },
                        ],
                    },
                ],
            }

        ],
        onSubmit: 'submitted',
    }
}

class EditStrutturaTest extends Component {

    state = {
        loaded: false,
        loading: true,
        test: {}
    };

    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loaded: true,
            loading: false,
            test: props.test
        });
    }

     submit(data) {
        if (!this.state.loading) {

            data.testTypeDescription = this.props.GlobalStore.stubs.tipo_prove_verifica.find(el => el.value === data.testType)?.label;
            data.testFeatureDescription = this.props.GlobalStore.stubs.caratteristiche_prove_verifica.find(el => el.value === data.testFeature)?.label;

            this.setState({
                loading: true,
                test : data });
            if(this.props.create){
                this.props.onCreate(data);
            }else{
                this.props.onUpdate(data);
            }
        }
    }

    render() {
        return (
            <div>
                <CommonForm
                    loading={this.state.loading || false}
                    form_model={formLayout(this)}
                    values={this.state.test}
                    readOnly={this.props.readOnly}
                    submitted={(data) => this.submit(data)}
                    _tipo_test={this.props.GlobalStore.stubs.tipo_prove_verifica}
                    _caratteristiche_test={this.props.GlobalStore.stubs.caratteristiche_prove_verifica}
                />
            </div>
        )
    }
}

export default EditStrutturaTest;