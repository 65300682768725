import React, { Component } from 'react';
import { Button, message, Spin, Tooltip, Icon } from 'antd';
import CommonForm from '@common/components/form';
import ArticolazionePercorso from '../../components/ProgrammazioneDidattica/ArticolazionePercorso';
import OrarioSettimanale from '../../components/ProgrammazioneDidattica/OrarioSettimanale';
import Metodologie from '../../components/ProgrammazioneDidattica/Metodologie';
import ModuliDidattici from '../../components/ProgrammazioneDidattica/ModuliDidattici';
import AziendeStage from '../../components/ProgrammazioneDidattica/AziendeStage';
import StrutturaTest from '../../components/ProgrammazioneDidattica/StrutturaTest';
import eventBroker from '@common/utils/services/EventBroker';
import affidamentoStatic from '@common/utils/static/affidamento';
import moment from 'moment';
import checkPermission from '@common/utils/check/permissions';


import PropTypes from 'prop-types'
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'destinatari',
                type: 'title',
                element: 'h4',
                text: 'Destinatari',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcRecipientsFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'integer',
                label: 'Numero massimo partecipanti',
                name: 'AfcRecipientsFormSection.partecipantsMax',
                props: {
                  min: 0,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcRecipientsFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Caratteristiche degli allievi',
                type_string: 'text',
                name: 'AfcRecipientsFormSection.studentsFeatures',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcRecipientsFormSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'ciclo',
                type: 'title',
                element: 'h4',
                text: 'Ciclo',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCycleFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Ciclo unico/pluriennale',
                name: 'AfcCycleFormSection.cycleType',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                  onChange: (e) => { component.onChangeCicloUnico(e) }
                },
                options_props: '_tipo_ciclo',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCycleFormSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'integer',
                label: 'Per Ciclo pluriennale inserire la durata in numero di anni',
                name: 'AfcCycleFormSection.cycleDuration',
                props: {
                  min: 1,
                  onChange: (e) => { component.setAnnuity(e) },
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCycleFormSection && !component.state.disabledCicloPluriennale ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    field: 'AfcCycleFormSection.cycleType',
                    operator: '==',
                    value: 2,
                  },
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'articolazione_del_percorso',
                type: 'title',
                element: 'h4',
                text: <>Articolazione del percorso <Tooltip placement="top" title="Riportare nella tabella la ripartizione oraria del percorso formativo nell’arco del periodo di riferimento nel rispetto dell’articolazione indicata all’art. 5 dell’Avviso pubblico."><Icon className="m5w" type="info-circle" /></Tooltip></>,
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCourseArticulationFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'articolazione_percorso_table',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCourseArticulationFormSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <ArticolazionePercorso
                    {...component.props}
                    percorsoList={component.state.teachingProgramming.AfcCourseArticulationFormSection?.courseArticulationList}
                    courseDuration={component.state.teachingProgramming.AfcCourseArticulationFormSection?.courseDuration}
                    loading={component.state.loadingTable}
                    saveArticolazionePercorsoList={(list) => component.saveArticolazionePercorsoList(list)}
                    subform={true}
                  />
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'metodologie_risorse_umane',
                type: 'title',
                element: 'h4',
                text: 'Metodologie e risorse umane coinvolte',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcMethodAndHumanResourcesFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'metodologie_risorse_umane_table',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcMethodAndHumanResourcesFormSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <Metodologie
                    {...component.props}
                  //  metodologieList={component.state.teachingProgramming.AfcMethodAndHumanResourcesFormSection?.methodAndHumanResourcesList}
                    metodologieList={component.props.methodList}

                    loading={component.state.loadingTable}
                    saveMetodologieList={(list) => component.saveMetodologieList(list)}
                  />
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'orario_settimanale',
                type: 'title',
                element: 'h4',
                text: 'Orario settimanale',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcWeeklyScheduleFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'orario_settimanale_table',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcWeeklyScheduleFormSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <OrarioSettimanale
                    {...component.props}
                    orarioList={component.state.teachingProgramming.AfcWeeklyScheduleFormSection?.weeklyScheduleList}
                    teacherList={component.getTeacherList()}
                    loading={component.state.loading}
                    saveOrarioList={(list) => component.saveOrarioList(list)}
                  />
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'articolazione_corso_moduli',
                type: 'title',
                element: 'h4',
                text: 'Articolazione del corso in moduli didattici/Unità formative',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCourseTeachingModulesFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'moduli_table',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCourseTeachingModulesFormSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <ModuliDidattici
                    {...component.props}
                    moduleList={component.state.teachingProgramming.AfcCourseTeachingModulesFormSection?.moduleList}
                    annuityList={component.state.annuityList}
                    loading={component.state.loadingTable}
                    saveModuleList={(list) => component.saveModuleList(list)}
                  />
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'attivita_corso',
                type: 'title',
                element: 'h4',
                text: 'Attività del corso',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCourseActivitiesFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Descrizione Stage (descriverne le modalità, gli obiettivi ed i tempi)',
                name: 'AfcCourseActivitiesFormSection.stageDescription',
                type_string: 'text',
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    func: () => component.props.teachingProgramming.AfcCourseArticulationFormSection.courseArticulationList?.reduce((a, b) => a + (b.stageHours || 0), 0) > 0
                  }
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCourseActivitiesFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: <>
                  Descrizione visite guidate (descriverne le modalità, gli obiettivi ed i tempi)
                  {component.props.domanda_json?.generalData?.AfcProjectInfoFormSection?.courseType === 1 && <Tooltip placement="top" title="Obbligatorio se previsto dal bando di riferimento."><Icon className="m5w" type="info-circle" /></Tooltip>}
                </>,
                name: 'AfcCourseActivitiesFormSection.guidedToursDescription',
                type_string: 'text',
                //validations: [
                //  {
                //    type: 'required',
                //    error_message: 'Campo obbligatorio',
                //  }
                //],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCourseActivitiesFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: <>
                  Descrizione viaggi studio (descriverne le modalità, gli obiettivi ed i tempi)
                  {component.props.domanda_json?.generalData?.AfcProjectInfoFormSection?.courseType === 1 && <Tooltip placement="top" title="Obbligatoro se previsto dal bando di riferimento."><Icon className="m5w" type="info-circle" /></Tooltip>}
                  </>,
                name: 'AfcCourseActivitiesFormSection.studyTravelDescription',
                type_string: 'text',
                //validations: [
                //  {
                //    type: 'required',
                //    error_message: 'Campo obbligatorio',
                //  }
                //],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcCourseActivitiesFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'aziende_stage',
                type: 'title',
                element: 'h4',
                text: <>
                  {(component.props.teachingProgramming?.AfcCourseArticulationFormSection?.courseArticulationList?.reduce((a, b) => a + (b.stageHours || 0), 0) || 0) > 0 ? <span style={{color: "#f5222d"}}>* </span> : null}
                  Aziende che si sono dichiarate disponibili ad ospitare gli allievi
                </>,
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcStageCompanyListFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'aziende_stage_table',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcStageCompanyListFormSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <AziendeStage
                    {...component.props}
                    aziendeList={component.state.teachingProgramming.AfcStageCompanyListFormSection?.stageCompanyList}
                    saveAziendeList={(list) => component.saveAziendeList(list)}
                  />
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'test_struscture',
                type: 'title',
                element: 'h4',
                text: 'Articolazione e struttura delle prove di verifica',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcTestStructureFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'test_structure_table',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcTestStructureFormSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <StrutturaTest
                    {...component.props}
                    testList={component.state.teachingProgramming.AfcTestStructureFormSection?.testList}
                    saveTestList={(list) => component.saveTestList(list)}
                  />
                </div>
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'final_test',
                type: 'title',
                element: 'h4',
                text: 'Prova finale',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcFinalTestFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Modalità di Valutazione e Certificazione delle Competenze acquisite',
                name: 'AfcFinalTestFormSection.judgmentMethod',
                type_string: 'text',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcFinalTestFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione della prova d’esame finale',
                name: 'AfcFinalTestFormSection.finalTestDescription',
                type_string: 'text',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcFinalTestFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'accompanying_measures',
                type: 'title',
                element: 'h4',
                text: 'Misure di accompagnamento',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcAccompanyingMeasuresFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Misure di accompagnamento a supporto della frequenza, del recupero di eventuali debiti formativi, dell’inserimento professionale in uscita dal percorso',
                name: 'AfcAccompanyingMeasuresFormSection.accompanyingMeasuresDesription',
                type_string: 'text',
                props: {
                  maxLength: "500"
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.teachingProgramming.AfcAccompanyingMeasuresFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
    ],
  }
}

export default class ProgrammazioneDidatticaForm extends Component {


  constructor(props) {
    super(props);
    this.getBackTeachingProgramming = this.getBackTeachingProgramming.bind(this);
    eventBroker.on('getBackTeachingProgramming', this.getBackTeachingProgramming);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  }

  state = {
    loading: true,
    loaded: false,
    loadingTable: false,
    teachingProgramming: {},
    annuityList: [],
    disabledCicloPluriennale: true,
    methodList: this.props.methodList
  };

  componentDidMount() {
    this.load(this.props)
  }

    componentDidUpdate(prevProps, prevState) {
    if (prevProps.methodList !== this.props.methodList) {
      this.setState({methodList : this.props.methodList})
    }
  }

  getBackTeachingProgramming() {
    return this.formSelRef?.props.form.getFieldsValue();
  }


  load(props) {
    let annuityList = [];
    if (props.teachingProgramming.AfcCycleFormSection) {

      let annuity = props.teachingProgramming.AfcCycleFormSection.cycleDuration === null ? 0 : props.teachingProgramming.AfcCycleFormSection.cycleDuration;
      for (let a = 1; a <= annuity; a++) {
        annuityList.push({
          key: 'annualita_' + a,
          label: a,
          value: a
        })
      }
    }

    this.setState({
      loaded: true,
      loading: false,
      loaded_source: true,
      teachingProgramming: props.teachingProgramming,
      methodList: this.props.methodList,
      disabledCicloPluriennale: props.teachingProgramming.AfcCycleFormSection?.cycleType === 1,
      annuityList: annuityList
    });
  }

  onChangeCicloUnico(e) {
    if (e === 1) {
      this.setState({
        disabledCicloPluriennale: true
      });

      this.setAnnuity(1);
    } else {
      this.setState({
        disabledCicloPluriennale: false
      });
    }
  }

  setAnnuity(annuity) {

    if (Number.isInteger(annuity) && annuity !== 0) {

      this.setState({
        loadingTable: true,
      });

      let teachingProgrammingObj = this.state.teachingProgramming;
      let methodList = this.state.methodList;
      let annuityList = this.state.annuityList;
      let old_annuity = this.state.teachingProgramming.AfcCycleFormSection.cycleDuration === null ? 0 : this.state.teachingProgramming.AfcCycleFormSection.cycleDuration;

      let annuity_diff = annuity - old_annuity;

      if (annuity_diff > 0) {
        for (let i = 1; i <= annuity_diff; i++) {


          if (teachingProgrammingObj.AfcCourseArticulationFormSection) {
            //righe della tabella ARTICOLAZIONE DEL PERCORSO
            teachingProgrammingObj.AfcCourseArticulationFormSection.courseArticulationList.push({
              annuity: old_annuity + i
            });

            //righe della tabella METODOLOGIE E RISORSE UMANE COINVOLTE
          //  teachingProgrammingObj.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList.push({
          //    annuity: old_annuity + i,
          //    organogramList: [],
          //    teacherList: [],
          //    specialUserList: [],
          //    equipmentList: [],
          //    audioVisualList: []
          //  })

            methodList.push({
              annuity: old_annuity + i,
              organogramList: [],
              teacherList: [],
              specialUserList: [],
              equipmentList: [],
              audioVisualList: []
            })
          }

          //lista di annualità da selezionare nella tabella ARTICOLAZIONE DEL CORSO IN MODULI DIDATTICI/UNITÀ FORMATIVE
          annuityList.push({
            key: 'annualita_' + old_annuity + i,
            label: old_annuity + i,
            value: old_annuity + i
          })
        }
      } else if (annuity_diff < 0) {

        //righe della tabella ARTICOLAZIONE DEL PERCORSO
        if (teachingProgrammingObj.AfcCourseArticulationFormSection) {
          teachingProgrammingObj.AfcCourseArticulationFormSection.courseArticulationList.length = annuity;

          let duration = 0;
          teachingProgrammingObj.AfcCourseArticulationFormSection.courseArticulationList.forEach(element => {
            duration = duration + element.totalHours
          });
          teachingProgrammingObj.AfcCourseArticulationFormSection.courseDuration = duration;
        }

        //righe della tabella METODOLOGIE E RISORSE UMANE COINVOLTE
        if (teachingProgrammingObj.AfcMethodAndHumanResourcesFormSection) {
          teachingProgrammingObj.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList.length = annuity;
        }

        //lista di annualità da selezionare nella tabella ARTICOLAZIONE DEL CORSO IN MODULI DIDATTICI/UNITÀ FORMATIVE
        annuityList.length = annuity

      }

      teachingProgrammingObj.AfcCycleFormSection.cycleDuration = annuity;

      this.setState({
        loadingTable: false,
        teachingProgramming: teachingProgrammingObj,
        annuityList: annuityList,
        methodList: methodList
      });
    }

  }

  getTeacherList() {
    let list = [];
    //if (this.state.teachingProgramming.AfcMethodAndHumanResourcesFormSection && this.state.teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList) {
    //  this.state.teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList.forEach(element => {
        if (this.state.methodList) {
          this.state.methodList.forEach(element => {
       
        element.teacherList.forEach(teacher => {
          if (!list.find(x => x.key === teacher.cf)) {
            list.push({
              key: teacher.cf,
              label: teacher.cognome + ' ' + teacher.nome + ' ' + teacher.cf,
              value: teacher.cf,
            })
          }
        });
      });
    }
    return list;
  }

  saveOrarioList(list) {
    let teachingProgramming = this.state.teachingProgramming;
    teachingProgramming.AfcWeeklyScheduleFormSection.weeklyScheduleList = list;
    this.setState({
      teachingProgramming: teachingProgramming,
    })
  }

  saveArticolazionePercorsoList(list) {
    let teachingProgramming = this.state.teachingProgramming;
    teachingProgramming.AfcCourseArticulationFormSection.courseArticulationList = list;
    let duration = 0;
    list.forEach(element => {
      duration += element.totalHours
    });
    teachingProgramming.AfcCourseArticulationFormSection.courseDuration = duration;
    this.setState({
      teachingProgramming: teachingProgramming,
    })
  }

  saveMetodologieList(list) {
    let teachingProgramming = this.state.teachingProgramming;
    teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList = list;

    this.setState({
      teachingProgramming: teachingProgramming,
      methodList:list
    })
  }

  saveModuleList(list) {
    let teachingProgramming = this.state.teachingProgramming;
    teachingProgramming.AfcCourseTeachingModulesFormSection.moduleList = list;
    this.setState({
      teachingProgramming: teachingProgramming
    })
  }

  saveAziendeList(list) {
    let teachingProgramming = this.state.teachingProgramming;
    teachingProgramming.AfcStageCompanyListFormSection.stageCompanyList = list;
    this.setState({
      teachingProgramming: teachingProgramming
    })
  }

  saveTestList(list) {
    let teachingProgramming = this.state.teachingProgramming;
    teachingProgramming.AfcTestStructureFormSection.testList = list;
    this.setState({
      teachingProgramming: teachingProgramming
    })
  }

  async submit() {
    
    if (await this.validate()) {
      let domanda = this.props.domanda_json;

      let data = this.formSelRef?.props.form.getFieldsValue();
      let teachingProgramming = this.state.teachingProgramming;

      domanda.teachingProgramming = data;

      if (teachingProgramming.AfcCourseArticulationFormSection) {
        domanda.teachingProgramming.AfcCourseArticulationFormSection = teachingProgramming.AfcCourseArticulationFormSection;
      }

      if (teachingProgramming.AfcCourseTeachingModulesFormSection) {
        domanda.teachingProgramming.AfcCourseTeachingModulesFormSection = teachingProgramming.AfcCourseTeachingModulesFormSection;
      }

      if (teachingProgramming.AfcCycleFormSection) {
        domanda.teachingProgramming.AfcCycleFormSection.cycleDuration = teachingProgramming.AfcCycleFormSection.cycleDuration;
      }
      if (teachingProgramming.AfcMethodAndHumanResourcesFormSection) {
        domanda.teachingProgramming.AfcMethodAndHumanResourcesFormSection = teachingProgramming.AfcMethodAndHumanResourcesFormSection;
        domanda.teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList = this.state.methodList;
      }

      if (teachingProgramming.AfcStageCompanyListFormSection) {
        domanda.teachingProgramming.AfcStageCompanyListFormSection = teachingProgramming.AfcStageCompanyListFormSection;
      }

      if (teachingProgramming.AfcTeachingUnitsFormSection) {
        domanda.teachingProgramming.AfcTeachingUnitsFormSection = teachingProgramming.AfcTeachingUnitsFormSection;
      }

      if (teachingProgramming.AfcTestStructureFormSection) {
        domanda.teachingProgramming.AfcTestStructureFormSection = teachingProgramming.AfcTestStructureFormSection;
      }

      if (teachingProgramming.AfcWeeklyScheduleFormSection) {
        domanda.teachingProgramming.AfcWeeklyScheduleFormSection = teachingProgramming.AfcWeeklyScheduleFormSection;
      }

      /* this.setState({
         loading: true,
         loaded: false,
       });*/

      this.props.saveJson(domanda);

      /* ModelServices.save_json(domanda, this.props.match.params.id,
         (res) => {
           if (this.props.updateProgress) {
             this.props.updateProgress(res.data.data.progress);
             this.props.refresh(false);
             message.success('Dati salvati con successo');
           }
           this.setState({
             loading: false,
             loaded: true,
             teachingProgramming: res.data.data.domanda_json.teachingProgramming

           });
         },
         () => {
           this.setState({
             loading: false,
             loaded: true,
           });
         }
       );*/
    }
  }

  annuityModuleIsValid() {
    let valid = true;
    if (this.state.teachingProgramming.AfcCourseTeachingModulesFormSection) {
      this.state.teachingProgramming.AfcCourseTeachingModulesFormSection.moduleList.forEach(element => {
        if (!(this.state.annuityList.find(annuity => annuity.value === element.annuity))) {
          valid = false
        }
      });
    }
    return valid;
  }

  weeklyScheduleIsValid() {
    let valid = true;
    if (this.state.teachingProgramming.AfcWeeklyScheduleFormSection) {
      this.state.teachingProgramming.AfcWeeklyScheduleFormSection.weeklyScheduleList.forEach(element => {
        if (element.teacher && !(this.getTeacherList().some(teacher => teacher.value === element.teacher))) {
          valid = false
        }

        let fromDate = moment(element.fromDate, 'YYYY-MM-DD');
        let toDate = moment(element.toDate, 'YYYY-MM-DD');
        let startCourse = moment(this.props.startDate, 'YYYY-MM-DD');
        let endCourse = moment(this.props.endDate, 'YYYY-MM-DD');
        if ((endCourse && toDate > endCourse) ||
          (startCourse && fromDate < startCourse)) {
          valid = false;
        }
      });
    }
    return valid;
  }

  weeklyScheduleLessonIsValid() {
    let valid = true;
    if (this.state.teachingProgramming.AfcWeeklyScheduleFormSection) {
      let list = this.state.teachingProgramming.AfcWeeklyScheduleFormSection.weeklyScheduleList;
      for (let index = 0; index < list.length - 1; index++) {
        const data = list[index];
        let fromDate = moment(data.fromDate, 'YYYY-MM-DD');
        let toDate = moment(data.toDate, 'YYYY-MM-DD');
        let fromTime = moment(data.fromTime, 'HH:mm');
        let toTime = moment(data.toTime, 'HH:mm');
        for (let j = index + 1; j < list.length; j++) {
          const element = list[j];
          if (element.weekDay === data.weekDay) {
            let currentFromDate = moment(element.fromDate, 'YYYY-MM-DD');
            let currentToDate = moment(element.toDate, 'YYYY-MM-DD');
            if (currentToDate >= fromDate && currentFromDate <= toDate) {
              let currentFromTime = moment(element.fromTime, 'HH:mm');
              let currentToTime = moment(element.toTime, 'HH:mm');
              if (currentToTime > fromTime && currentFromTime < toTime) {
                valid = false;
              }

            }
          }
        }
      }
    }
    return valid;
  }



  courseArticulationIsValid() {
    let valid = true;
    if (this.state.teachingProgramming.AfcCourseArticulationFormSection) {
      this.state.teachingProgramming.AfcCourseArticulationFormSection.courseArticulationList.forEach(element => {
        if (!element.saved) {
          valid = false
        }
      });
    }
    return valid;
  }

  methodAndHumanResourceIsValid() {
    let valid = true;
   // if (this.state.teachingProgramming.AfcMethodAndHumanResourcesFormSection) {
   //   this.state.teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList.forEach(element => {
 
        if (this.state.methodList) {
          this.state.methodList.forEach(element => {   
        if (!element.methods) {
          valid = false
        }
      });
    }
    return valid;
  }

  async validate() {
    return new Promise((resolve) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if ((!errors || errors.length === 0) && this.annuityModuleIsValid() && this.courseArticulationIsValid() && this.methodAndHumanResourceIsValid() && this.weeklyScheduleIsValid() && this.weeklyScheduleLessonIsValid()) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  render() {
    return this.state.loaded_source ? <div style={{ opacity: !this.props.readOnly && !this.props.saved ? 0.5 : 1 }}>
      {
        !this.props.readOnly && !this.props.saved ? <Spin /> : null
      }
      { checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
        <ValidazioneScheda
          canGiveFeedback={this.props.canGiveSectionFeedback && this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
          loadingFeedback={this.props.loadingFeedback}
          saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'teachingProgramming')}
          feedback={this.props.section_feedback ? this.props.section_feedback['teachingProgramming'] : null}
        />
      }
      <CommonForm
        wrappedComponentRef={inst => (this.formSelRef = inst)}
        loading={this.props.saving || false}
        form_model={formLayout(this)}
        values={this.state.teachingProgramming}
        readOnly={this.props.readOnly}
        _tipo_ciclo={this.props.GlobalStore.stubs.tipo_ciclo}
        _contenuto_formativo={this.props.GlobalStore.stubs.contenuto_formativo}
      />
      {!this.props.anteprima && !this.props.readOnly &&
        <Button disabled={this.props.readOnly} type="primary" icon='save' loading={this.state.loading} className="m5h bg-green" onClick={() => this.submit()}>Salva dati</Button>
      }

    </div>
      : null
  }
}