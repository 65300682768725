import React, { Component } from 'react';
import { Table, Input, Checkbox } from 'antd';
//import ModelServices from '@accreditamento/services/Accreditamento';
import DocSpan from '@common/components/DocSpan';
import moment from 'moment';
import SpinnerLoading from '@common/components/SpinnerLoading';

const getDate = (dt) => {
  if (moment(dt, 'YYYY-MM-DD').isValid()) return moment(dt, 'YYYY-MM-DD').format('DD/MM/YYYY')

  return ''
}

const TableModel = (component) => [
  {
    title: "Richiedi integrazione",
    dataIndex: '',
    key: 'tipo_media.id',
    sorter: false,
    render: element => <div style={{ minWidth: 250 }}>
      <React.Fragment>
        <Checkbox
          onChange={(e) => component.onSelectRow(e, element)}
        ></Checkbox>
        {
          component.isSelected(element)
            ?
            <Input.TextArea
              style={{ width: '100%' }}
              placeholder="Inserisci dettagli"
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={(e) => component.addNote(e, element)}
            />
            : null
        }
      </React.Fragment>
    </div>
  },
  {
    title: "Nome file",
    dataIndex: '',
    sorter: false,
    render: element => <div style={{ width: 250 }}><DocSpan key={element.id} file={element} /></div>
  },
  {
    title: "Tipo",
    dataIndex: 'tipo',
    sorter: false,
    render: element => <div style={{ width: 250 }}>{element}</div>
  },
  {
    title: "Validità",
    dataIndex: 'valido_dal',
    sorter: false,
    render: (element, row) => <div style={{ width: 250 }}>{getDate(row.valido_dal) + " - " + getDate(row.valido_al)}</div>
  }
];

class IntegrazionedocumentaleForm extends Component {

  state = {
    loaded: false,
    documents: [],
    requested: [],
    loading: false
  }



  componentDidMount() {
    this.props.getMedia();
  }

  onSelectRow(e, element) {
    if (e.target.checked) {
      this.setState({
        requested: [...this.state.requested, { id: element.id, note: '', media: element }]
      })
    } else {
      this.setState({
        requested: this.state.requested.filter(l => l.media !== element)
      })
    }
  }

  addNote(e, element) {
    this.setState({
      requested: [...this.state.requested.filter(l => l.media !== element), { id: element.id, note: e.target.value, media: element }]
    })
  }

  isSelected(element) {
    return this.state.requested.find(e => e.media === element) ? true : false
  }

  getValues() {
    return this.state.requested
  }

  render() {
    return <div>
      {this.props.loading ? <SpinnerLoading /> :
        <Table
          rowKey={(record) => record.tipo_media.id}
          columns={TableModel(this)}
          dataSource={this.props.documents}
          pagination={false}
        />}
    </div>
  }
}

export default IntegrazionedocumentaleForm
