import React, { Component } from "react";
import moment from "moment";

import { message } from "antd";
import CommonForm from "@common/components/form";

import formatter from "@common/utils/formatters/_";

import Settori from "./accreditamento/Settori";
import Estremi from "./accreditamento/Estremi";

import { notification } from "antd";

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      ,
      /*
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label: "Formazione a distanza",
                name: "formazione_a_distanza",
                option_type: "button",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      } */
      /*
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label: "Sezioni",
                name: "id_sezioni",
                props: {
                  disabled: component.props.readOnly,
                  
                  // size: 'large',
                  //showSearch: true,
                  //filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "sezioni",
                onChange: 'setTipoSezione',
              },
            ],
          },
        ],
      },*/ {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label: "Sezioni",
                name: "id_tipologia",
                mode: "tags",
                onChange: "setTipoAccreditamento",
                props: {
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "tipologia_accreditamento",
                //onChange: 'selectedResidenzaProvincia',
              },
            ],
          },
        ],
      } /*
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Settori',
                name: 'id_settore',
                mode: 'tags',
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'tipo_settore',
              },
            ],
          },

        ],
      },,
      {
        cols: [
          {
            items: [
              {
                name: "_settori",
                type: "child",
                child: (
                  <div style={{ marginBottom: 16 }}>
                    <Settori
                      readOnly={component.props.readOnly}
                      ref={component.settoriRef}
                      GlobalStore={component.props.GlobalStore}
                      tipi_accreditamento={component.state.tipi_accreditamento}
                      id_settore={component.state.id_settore}
                    />
                  </div>
                ),
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label:
                  "Utenze speciali per le quali viene richiesto accreditamento",
                name: "id_utenza_speciale",
                mode: "tags",
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  //showSearch: true,
                  //filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "isSectionAB",
                  },
                ],
                validations: [
                  // {
                  //   type: 'required',
                  //   error_message: 'Campo obbligatorio',
                  // },
                ],
                options_props: "utenze_speciali",
                //onChange: 'selectedResidenzaProvincia',
              },
            ],
          },
        ],
      },*/,
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label:
                  "Elenco regionale degli operatori pubblici e privati accreditati",
                name: "id_elenco_regionale",
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  //showSearch: true,
                  //filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "isSectionC",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "elenco_regionale",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "input",
                label: "Numero di Autorizzazione ANPAL",
                type_string: "text",
                name: "N_anpal",
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  //showSearch: true,
                  //filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "isSectionC",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "elenco_regionale",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "input",
                label: "Tipologia di Autorizzazione ANPAL",
                type: "select",
                name: "id_tipologia_anpal",
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  //showSearch: true,
                  //filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "isSectionC",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "tipologia_autorizzazioni_anpal",
              },
            ],
          },
        ],
      },
      {
        // QUANDO SI GENERA IL PDF A DB TENERE CONTO DI QUESTO CONFRONTO
        cols: [
          {
            items: [
              {
                type: "radio",
                label:
                  "Dichiaro che nello statuto è prevista, tra le finalità dell’Ente, l’attività di servizi per lavoro",
                name: "dichiarazione_formazione",
                option_type: "button",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  style: { textAlign: "center" },
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "isSectionC",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        // QUANDO SI GENERA IL PDF A DB TENERE CONTO DI QUESTO CONFRONTO
        cols: [
          {
            items: [
              {
                type: "radio",
                label:
                  "Dichiaro che nello statuto è prevista, tra le finalità dell’Ente, l’attività di formazione",
                name: "dichiarazione_formazione",
                option_type: "button",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  style: { textAlign: "center" },
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "isSectionAB",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "label",
                name: "soggetto_presso_accreditato_fake",
                text: "Il Soggetto è già accreditato presso",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 4,
            items: [
              {
                type: "radio",
                label: "Altra Regione",
                name: "tipo_soggetto_accreditato_1",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  disabled: component.props.readOnly,
                },
              },
            ],
          },
          {
            span: 10,
            items: [
              {
                type: "select",
                label: "Regioni",
                name: "tipo_soggetto_accreditato_regioni",
                mode: "multiple",
                onChange: "changeRegione",
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                options_props: "_regioni",
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                showIf: [
                  {
                    type: "field_validation",
                    field: "tipo_soggetto_accreditato_1",
                    operator: "==",
                    value: "1",
                  },
                ],
              },
            ],
          },
          {
            span: 10,
            items: [
              {
                name: "estremi_soggetto_accreditato_regioni",
                type: "child",
                showIf: [
                  {
                    type: "field_validation",
                    field: "tipo_soggetto_accreditato_1",
                    operator: "==",
                    value: "1",
                  },
                ],
                child: (
                  <div style={{ marginBottom: 16 }}>
                    <Estremi
                      readOnly={component.props.readOnly}
                      ref={component.estremiRefRegioni}
                      stubs={component.props.GlobalStore.stubs.regione}
                      parents={component.state.soggetto_accreditato_regioni}
                      initialValue={component.getAccreditatoEstremi(1)}
                    />
                  </div>
                ),
              } ,
            ],
          },
        ],
      } ,
    ],
    onSubmit: "submitted",
  };
};

class DatiEnteForm extends Component {
  state = {
    loading: false,
    loaded: false,
    comuni_nascita: [],
    files: {},
    fine_lucro: null,
    divisione: null,
    tipi_accreditamento: [],
    id_settore: [],
    soggetto_accreditato_regioni: [],
    soggetto_accreditato_province: [],
    soggetto_accreditato_nazioni: [],
    isFineLucro: false,
    tipo_sezione: [],
    elenco_regionale: [
      {
        key: "C1",
        label:
          "C1 - Servizi per il lavoro con copertura sull’intero territorio regionale",
        value: "1",
      },
      {
        key: "C2",
        label:
          "C2 - Servizi per il lavoro con copertura di specifiche aree svantaggiate",
        value: "2",
      },
    ],
  };

  constructor(props) {
    super(props);

    this.settoriRef = React.createRef();
    this.estremiRefRegioni = React.createRef();
    this.estremiRefProvince = React.createRef();
    this.estremiRefNazioni = React.createRef();
  }

  componentDidMount() {
    this.setState({
      loaded: true,
      tipi_accreditamento:
        this.props.accreditamento.accreditamentotipologie.map((el) =>
          el.id_tipologia.toString()
        ),
      id_settore: this.props.accreditamento.accreditamentosettori,
      soggetto_accreditato_regioni: this.getAccreditatoValues(1),
      soggetto_accreditato_province: this.getAccreditatoValues(2),
      soggetto_accreditato_nazioni: this.getAccreditatoValues(3),
      isFineLucro: this.props.isFineLucro,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isFineLucro !== this.props.isFineLucro) {
      this.setState({ isFineLucro: newProps.isFineLucro });
    }
  }

  getFormData() {
    return {
      ...this.formRef.props.form.getFieldsValue(),
      // id_settore: this.settoriRef.current.getValues(),
      estremi_provvedimento_1: this.estremiRefRegioni.current
        ? this.estremiRefRegioni.current.getFormData()
        : null,
      estremi_provvedimento_2: this.estremiRefProvince.current
        ? this.estremiRefProvince.current.getFormData()
        : null,
      estremi_provvedimento_3: this.estremiRefNazioni.current
        ? this.estremiRefNazioni.current.getFormData()
        : null,
    };
  }

  async validate() {
    // if (!this.settoriRef.current.validate()) return false;
    if (
      this.estremiRefRegioni.current &&
      !this.estremiRefRegioni.current.validate()
    )
      return false;
    if (
      this.estremiRefProvince.current &&
      !this.estremiRefProvince.current.validate()
    )
      return false;
    if (
      this.estremiRefNazioni.current &&
      !this.estremiRefNazioni.current.validate()
    )
      return false;

    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error("Ricontrolla i campi");
          resolve(false);
        }
      });
    });
  }

  getAccreditatoValues(n) {
    let item = this.props.accreditamento.entegiaaccreditatopresso.find(
      (el) => el.id_accreditato_presso === n
    );
    if (item) {
      if (n === 2) console.error("errore", item);
      return JSON.parse(item.accreditamento_associazioni).map((el) =>
        el.toString()
      );
    } else {
      return [];
    }
  }

  getAccreditatoEstremi(n) {
    let item = this.props.accreditamento.entegiaaccreditatopresso.find(
      (el) => el.id_accreditato_presso === n
    );
    if (item) {
      return item.estremi_provvedimento;
    } else {
      return [];
    }
  }

  getAccreditatoPresso() {
    let vals = {};
    for (let n = 1; n <= 3; n++) {
      let item = this.props.accreditamento.entegiaaccreditatopresso.find(
        (el) => el.id_accreditato_presso === n
      );
      if (item) {
        vals["tipo_soggetto_accreditato_" + n] = "1";
        vals["estremi_provvedimento_" + n] = item.estremi_provvedimento;
      } else {
        vals["tipo_soggetto_accreditato_" + n] = "0";
        vals["estremi_provvedimento_" + n] = "";
      }

      switch (n) {
        case 1:
          vals["tipo_soggetto_accreditato_regioni"] = item
            ? JSON.parse(item.accreditamento_associazioni).map((el) =>
                el.toString()
              )
            : [];
          //this.setState({soggetto_accreditato_regioni: vals['tipo_soggetto_accreditato_regioni']});
          break;
        case 2:
          vals["tipo_soggetto_accreditato_province"] = item
            ? JSON.parse(item.accreditamento_associazioni).map((el) =>
                el.toString()
              )
            : [];
          //this.setState({soggetto_accreditato_province: vals['tipo_soggetto_accreditato_province']});
          break;
        case 3:
          vals["tipo_soggetto_accreditato_nazioni"] = item
            ? JSON.parse(item.accreditamento_associazioni).map((el) =>
                el.toString()
              )
            : [];
          //this.setState({soggetto_accreditato_nazioni: vals['tipo_soggetto_accreditato_nazioni']});
          break;
        default:
          break;
      }
    }

    return vals;
  }

  getValues() {
    try {
      return formatter.convertBoolean(
        [
          "formazione_speciale_anno",
          "formazione_speciale_treanni",
          "formazione_speciale_associazione",
          "formazione_professionale",
          "fine_lucro_in_statuto",
          "utili_divisi_soci",
          "dichiarazione_formazione",
          "tipo_soggetto_accreditato_1",
          "tipo_soggetto_accreditato_2",
          "tipo_soggetto_accreditato_3",
          "formazione_speciale_assistenza",
        ],
        {
          ...this.props.accreditamento.ente,
          id_tipologia: this.props.accreditamento.accreditamentotipologie.map(
            (el) => el.id_tipologia.toString()
          ),
          id_settore: this.props.accreditamento.accreditamentosettori.map(
            (el) => el.id_settore.toString()
          ),
          id_sezioni: this.props.accreditamento.id_sezioni.value,
          id_elenco_regionale:
            this.props.accreditamento.id_elenco_regionale.value,
          N_anpal: this.props.accreditamento.N_anpal,
          id_tipologia_anpal: this.props.accreditamento.id_tipologia_anpal.value,
          id_utenza_speciale:
            this.props.accreditamento.accreditamentoutenzespeciali.map((el) =>
              el.id.toString()
            ),
          formazione_a_distanza: this.props.accreditamento.formazione_a_distanza
            ? "1"
            : "0",
          ...this.getAccreditatoPresso(),
        }
      );
    } catch (e) {
      return this.props.accreditamento;
    }
  }

  getDateAccettazione() {
    try {
      return (
        "Accettazione del trattamento dei dati personali esplicitata il " +
        moment(
          this.props.accreditamento.ente.accettazione_termini_data,
          "YYYY-MM-DD HH:mm:ss"
        ).format("DD/MM/YYYY") +
        " alle " +
        moment(
          this.props.accreditamento.ente.accettazione_termini_data,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm")
      );
    } catch (e) {
      return "";
    }
  }

  async setTipoAccreditamento(value) {
    this.setState({ tipi_accreditamento: value });
  }

  async setTipoSezione(value) {
    this.setState({ tipo_sezione: value });
  }

  render() {
    // rimuoviamo temporaneamente la sezione C come da
    // https://cb.schema31.it/cb/issue/432802
    let tipiAcc = this.props.GlobalStore.stubs.tipo_accreditamento.filter(
      (p) => p.key !== 'tipo_accreditamento_3'
    )
    return (
      <div>
        <h3 className="m10h">Sezioni</h3>
        {this.state.loaded ? (
          <React.Fragment>
            <CommonForm
              wrappedComponentRef={(inst) => (this.formRef = inst)}
              loading={this.props.loading || false}
              form_model={formLayout(this)}
              values={this.getValues()}
              readOnly={this.props.readOnly}
              existsUtenteSpeciale={(value, form) => {
                var arr = form.getFieldValue("id_utenza_speciale");
                return arr && arr.length > 0;
              }}
              isSectionC={(value, form) => {
                var arr = this.state.tipi_accreditamento;
                if (arr.length > 0 && arr[0] == "3") {
                  return true;
                } else {
                  return false;
                }
              }}
              isSectionAB={(value, form) => {
                var arr = this.state.tipi_accreditamento;
                if ((arr.length > 0 && arr[0] == "1") || arr[0] == "2") {
                  return true;
                } else {
                  return false;
                }
              }}
              isFineLucro={() => this.state.isFineLucro}
              setTipoAccreditamento={(value) =>
                this.setTipoAccreditamento(value)
              }
              setTipoSezione={(value) => this.setTipoSezione(value)}
              _regioni={this.props.GlobalStore.stubs.regione}
              _province_autonome={this.props.GlobalStore.stubs.provincia.filter(
                (p) => p.autonoma === 1
              )}
              _nazioni_eu={this.props.GlobalStore.stubs.nazione.filter(
                (n) => n.eu_member === 1
              )}
              tipologia_accreditamento={
                tipiAcc //this.props.GlobalStore.stubs.tipo_accreditamento
              }
              tipo_settore={this.props.GlobalStore.stubs.settori}
              utenze_speciali={this.props.GlobalStore.stubs.utenze_speciali}
              sezioni={this.state.sezioni}
              elenco_regionale={this.state.elenco_regionale}
              tipo_soggetto_accreditato={
                this.props.GlobalStore.stubs.tipo_soggetto_accreditato
              }
              tipologia_autorizzazioni_anpal={
                this.props.GlobalStore.stubs.tipologia_autorizzazioni_anpal
              }
              changeRegione={(value) =>
                this.setState({ soggetto_accreditato_regioni: value })
              }
              changeProvincia={(value) =>
                this.setState({ soggetto_accreditato_province: value })
              }
              changeNazione={(value) =>
                this.setState({ soggetto_accreditato_nazioni: value })
              }
            />
            <p>{this.getDateAccettazione()}</p>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default DatiEnteForm;
