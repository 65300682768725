import React, { Component } from 'react';



import InterrelazioniServices from '../../services/Interrelazioni';
import ProtocolliIntesa from './interrelazioni/ProtocolliIntesa';
import Partenariato from './interrelazioni/Partenariato';
import StudioTerritorio from './interrelazioni/StudioTerritorio';

import ValidationScheda from '../audit/ValidationScheda';

class Interrelazioni extends Component {

  state = {
    loading: false,
    loading_protocolli_intesa: false,
    loading_partenariato: false,
    loading_studio_territorio: false,
    open_drawer: false,
    protocolli_intesa: [],
    partenariato: [],
    studio_territorio: [],
    tipo_accreditamento: [],
  };



  componentDidMount() {
    if(this.props.current_key === "7") this.loadData(this.props, null, false);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.current_key !== this.props.current_key && newProps.current_key === "7") this.loadData(newProps, null, false)
  }

  loadData(props, el = null, refresh = true) {

    if(this.props.reloadProgress && refresh) this.props.reloadProgress();

    let str_loading = (!el) ? 'loading' : 'loading_' + el;

    this.setState({[str_loading]: true},()=>{
      setTimeout(() => {
        InterrelazioniServices.list( props.accreditamento.id, (res) => {
          this.setState({
            protocolli_intesa: res.data.data.protocolli_intesa,
            partenariato: res.data.data.partenariato,
            studio_territorio: res.data.data.studio_territorio,
            tipo_accreditamento: res.data.data.tipo_accreditamento,
            [str_loading]: false
          })
        }, () => this.setState({[str_loading]: false}))
      }, 500);
    })

  }

  refresh() {
    return this.props.refresh();
  }

  render() {
    return (
      <React.Fragment>
        <ValidationScheda
          section="interrelazioni"
          refreshInline={this.props.refreshInline}
          accreditamento={this.props.accreditamento}
          progress={this.props.progress}
        />
        <ProtocolliIntesa
          accreditamento={this.props.accreditamento}
          protocolli_intesa={this.state.protocolli_intesa}
          loading_data={this.state.loading_protocolli_intesa}
          GlobalStore={this.props.GlobalStore}
          readOnly={this.props.readOnly}
          tipo_accreditamento={this.state.tipo_accreditamento}
          onCreate={()=>this.loadData(this.props, 'protocolli_intesa')}
          onUpload={()=>this.loadData(this.props, 'protocolli_intesa')}
          onRemoveUpload={()=>this.loadData(this.props, 'protocolli_intesa')}
          onDelete={(id) => this.setState({protocolli_intesa: this.state.protocolli_intesa.filter(e=>e.id !== id)})}
          onDeleteChild={(id, id_protocollo) => this.setState({protocolli_intesa: [
            ...this.state.protocolli_intesa.filter(e=>e.id !== id_protocollo),
            {
              ...this.state.protocolli_intesa.find(e=>e.id === id_protocollo),
              dettagli: this.state.protocolli_intesa.find(e=>e.id === id_protocollo).dettagli.filter(d=>d.id !== id)
            }]
          })}
        />

        <Partenariato
          accreditamento={this.props.accreditamento}
          partenariato={this.state.partenariato}
          loading_data={this.state.loading_partenariato}
          GlobalStore={this.props.GlobalStore}
          readOnly={this.props.readOnly}
          tipo_accreditamento={this.state.tipo_accreditamento}
          onCreate={()=>this.loadData(this.props, 'partenariato')}
          onUpload={()=>this.loadData(this.props, 'partenariato')}
          onRemoveUpload={()=>this.loadData(this.props, 'partenariato')}
          onDelete={(id) => this.setState({partenariato: this.state.partenariato.filter(e=>e.id !== id)})}
          onDeleteChild={(id, id_protocollo) => this.setState({partenariato: [
            ...this.state.partenariato.filter(e=>e.id !== id_protocollo),
            {
              ...this.state.partenariato.find(e=>e.id === id_protocollo),
              dettagli: this.state.partenariato.find(e=>e.id === id_protocollo).dettagli.filter(d=>d.id !== id)
            }]
          })}
        />

        <StudioTerritorio
          accreditamento={this.props.accreditamento}
          studio_territorio={this.state.studio_territorio}
          loading_data={this.state.loading_studio_territorio}
          GlobalStore={this.props.GlobalStore}
          readOnly={this.props.readOnly}
          tipo_accreditamento={this.state.tipo_accreditamento}
          onCreate={()=>this.loadData(this.props, 'studio_territorio')}
          onUpload={()=>this.loadData(this.props, 'studio_territorio')}
          onRemoveUpload={()=>this.loadData(this.props, 'studio_territorio')}
          onDelete={(id) => this.setState({studio_territorio: this.state.studio_territorio.filter(e=>e.id !== id)})}
          onDeleteChild={(id, id_protocollo) => this.setState({studio_territorio: [
            ...this.state.studio_territorio.filter(e=>e.id !== id_protocollo),
            {
              ...this.state.studio_territorio.find(e=>e.id === id_protocollo),
              dettagli: this.state.studio_territorio.find(e=>e.id === id_protocollo).dettagli.filter(d=>d.id !== id)
            }]
          })}
        />

      </React.Fragment>
    );
  }
}
export default Interrelazioni;