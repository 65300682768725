import React, { Component } from 'react';
import Wrapper from '../decorators/Wrapper';
import PrivacyContent from './PrivacyContent';


@Wrapper({ route: 'Privacy', hasSidebar: false, hasHeader: true, hasFooter: true })
class PrivacyPageRoute extends Component {

  getBaseUrl() {
    return (window.env.SILF_NODE_ENV === 'produzione' ? window.env.SILF_BASE_URL : process.env.REACT_APP_BASE_URL)
  }

  render() {

    return (<PrivacyContent/>);
  }
}

export default PrivacyPageRoute;