import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import checkPermission from '@common/utils/check/permissions';
import IspezioneControdeduzioni from './IspezioneControdeduzioni';
import IspezioneControdeduzioniSend from './IspezioneControdeduzioniSend';

const getStato = (record, component) => {
  let stato = 'ente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';


  return record.stato_flusso_formatted['stato_'+stato] == "inviata" || record.stato_flusso_formatted['stato_'+stato] == "acquisita" || record.stato_flusso_formatted['stato_'+stato] == "accolta" || record.stato_flusso_formatted['stato_'+stato] == "accolta_e_confermata" || record.stato_flusso_formatted['stato_'+stato] == "valutazione_in_corso"  ? "Accreditamento Provvisorio" : record.stato_flusso_formatted['stato_'+stato] ;
}

class IspezioneDettagli extends Component {

  state = {
    loaded: false,
    loading: false
  }

  isEnte() {
    return this.props.accreditamento.id_user === this.props.UserStore.user.id ? true : false;
  }

  canSendControdeduzioni() {
    if(!this.isEnte()) return false;
    if( !this.props.ispezione.data_termine ) return false;
    if ( moment().diff(moment(this.props.ispezione.data_termine,'YYYY-MM-DD'),'days') > 10 ) return false;
    if(this.props.ispezione.controdeduzioni && this.props.ispezione.controdeduzioni.id) return false;
    if(this.props.accreditamento.stato_flusso !== 18) return false;

    return true;
  }

  hasControdeduzioni() {
    return this.props.ispezione.controdeduzioni && this.props.ispezione.controdeduzioni.id ? true : false
  }

  render() {
    return <div style={{marginBottom: 16}}>
      <strong>{
          this.props.ispezione && this.props.ispezione.id ? getStato(this.props.ispezione, this) : ''
        }</strong><br />
      {this.props.ispezione.data_termine ? moment( this.props.ispezione.data_termine, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' (data trasmissione verbali)' : '' }
      {
        this.canSendControdeduzioni() ? <IspezioneControdeduzioniSend
          accreditamento={this.props.accreditamento}
          ispezione={this.props.ispezione}
          refresh={()=>this.props.refresh()}
          refreshAccreditamento={()=>this.props.refreshAccreditamento()}
          refreshInline={(data)=>this.props.refreshInline(data)}
        /> : ''
      }
      {
        this.hasControdeduzioni() ? <IspezioneControdeduzioni
          accreditamento={this.props.accreditamento}
          ispezione={this.props.ispezione}
          controdeduzioni={this.props.ispezione.controdeduzioni}
          refresh={()=>this.props.refresh()}
          refreshInline={(data)=>this.props.refreshInline(data)}
          refreshAccreditamento={()=>this.props.refreshAccreditamento()}
        /> : ''
      }
    </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(IspezioneDettagli);