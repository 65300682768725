import React, { Component } from 'react';

import { Icon, message } from 'antd';
import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';

import enteStatic from '@common/utils/static/ente';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'titolo_residenze',
                type: 'title',
                element: 'h4',
                text: 'Sede legale',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 14,
            items: [
              {
                type: 'input',
                label: 'Via',
                type_string: 'text', // field type
                name: 'via',
                props: {
                  // size: 'large'
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'input',
                label: 'N',
                type_string: 'text', // field type
                name: 'ncivico',
                props: {
                  // size: 'large',
                  placeholder: 'N.',
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: 'cap',
                props: {
                  //size: 'large',
                  placeholder: 'Cap',
                  maxLength: 5,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9]{5,5}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: 'cap',
                props: {
                  //size: 'large',
                  placeholder: 'Cap',
                  maxLength: 10,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9a-zA-Z]{4,10}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '!=',
                    value: 1,
                  },
                ],
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Nazione',
                name: 'stato',
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'nazioni',
                onChange: 'selectedNazione',
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                name: 'provincia',
                props: {
                  // size: 'large',
                  allowClear: true,
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  },
                ],
                options_props: 'province',
                onChange: 'selectedProvincia',
              },
            ],
          },
          {
            span: 14,
            items: [
              {
                type: 'select',
                label: 'Comune',
                name: 'comune',
                props: {
                  disabled: component.props.readOnly,
                  allowClear: true,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'provincia',
                    operator: '!=',
                    value: null,
                  },
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  }
                ],
                options_props: 'comuni'
              },
            ],
          },
        ],
      },

    ],
    onSubmit: 'submitted',
  }
}

class IndirizzoSedeLegaleForm extends Component {
  state = {
    loading: false,
    loaded: false,
    comuni: [],
    files: {}
  };



  async componentDidMount() {

    //if (this.props.values) this.setFileInState(this.props);
    this.loadComuni(this.props)
  }



  getFormData() {
    return this.formRef.props.form.getFieldsValue();
  }


  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.accreditamento.id !== this.props.accreditamento.id) this.loadComuni(newProps)
  }

  loadComuni(props) {

    let addr = this.getIndirizzo(props);
    if ( addr.id ) {
      ComuniService.all(
        {
          id_provincia: addr.provincia
        },
        res => {

          this.setState({
            comuni: res.data.data || [],
            selected_provincia: addr.provincia
          });
        },
        () => null
      );
    }
  }


  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  getIndirizzo(props) {
    try {
      return props.accreditamento.ente.enteSede.find(el => el.tipo === enteStatic.sede.tipo.legale).indirizzo
    } catch(e) {
      return {

      }
    }
  }


  getValues() {
    try {
      return this.getIndirizzo(this.props);
    } catch(e) {
      return {}
    }
  }

  render() {

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={
            this.getValues()
          }


          nazioni={this.props.GlobalStore.stubs.nazione}
          province={this.props.GlobalStore.stubs.provincia}

          readOnly={this.props.readOnly}

          selectedProvincia={data => {
            //console.log (this.formRef.props.form);

            this.setState({
              selected_provincia: data,
              comuni: []
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune: null });
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni: res.data.data || []
                  }, ()=>{
                    this.formRef.props.form.setFieldsValue({ comune: null });
                  });
                },
                () => null
              );
            });
          }}

          selectedNazione={() => {
            //console.log (this.formRef.props.form);

            this.setState({
              comuni: [],
            },()=>{
              this.formRef.props.form.setFieldsValue({ comune: null, provincia: null });
            });
          }}

          comuni={this.state.comuni.map(e=>{
            return {
              key: 'residenza_comune_'+e.id,
              value: e.id,
              label: e.comune
            }
          })}

        />
      </div>
    );
  }
}

export default IndirizzoSedeLegaleForm;