import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import formatter from '@common/utils/formatters/_';

const getTipoAula = (component, record) => {
  try {
    let type = formatter.replaceStub(record.id_tab_tipo_aula, 'tipo_aula', component.props.GlobalStore)

    return type;
  } catch(e) {
    return 'errore'
  }
}

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'select',
                                label: 'Aule',
                                name: 'aule',
                                mode: 'multiple',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                options_props: 'aule',
                            },
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                input_type: 'string',
                                label: 'Ausili',
                                name: 'ausili',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        span: 12,
                        items: [
                            {
                                type: 'integer',
                                label: 'Quantità',
                                name: 'quantita',

                                props: {
                                    style: { width: '100%' },
                                    min: 1,
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                            },
                        ],
                    }
                ],
            }, /*
            {
                cols: [
                    {
                        items: [
                            {
                                type: 'radio',
                                label: 'Le attrezzature indicate sono tutte disponibili presso la sede indicata all’atto nella presente richiesta',
                                name: 'disponibile_in_sede',
                                option_type: 'button', // normal|button
                                // le opzioni come le selects
                                options: [
                                    {
                                        value: "1",
                                        label: "Si"
                                    },
                                    {
                                        value: "0",
                                        label: "No"
                                    }
                                ],
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                            }
                        ],
                    }
                ]
            }, */
            {
                cols: [
                    {
                        props: {

                            style: { float: 'right' }
                        },
                        items: [
                            {
                                type: 'submit',
                                name: 'btn_submit_attrezzatura',
                                text: 'Salva materiale audio/video',
                                //onClick: 'showDrawer',
                                props: {
                                    icon: 'save',

                                    className: 'bg-green'
                                }
                            }
                        ],
                    },
                ],
            },
        ],
        onSubmit: 'onSubmit',
    }
}

class CreateAudiovisivo extends Component {

    state = {
        loading: false,
        loaded: false,
        fileList: [],
        comuni: [],
        selected_aula: null
    };

    submitFormData(data) {
        this.props.onSubmit(data);
    }

  render() {

    return (
      <div>
        <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.props.loading || false}
            form_model={formLayout(this)}
            values={{
                aule: []
            }}
            readOnly={this.props.readOnly}
            onSubmit={(data)=>this.submitFormData(data)}


            validateTitoloPossesso={(value, form) => {
                return (form.getFieldValue('id_tab_attrezzature_possesso') === 2 || form.getFieldValue('id_tab_attrezzature_possesso') === 3 || form.getFieldValue('id_tab_attrezzature_possesso') === 4);
            }}



            aule={this.props.sede.aule.map(aula => {
                return {
                    value: aula.id,
                    label: aula.nome_aula + " " + getTipoAula(this, aula),
                    key: 'aula_' + aula.id
                }
            })}

        />
      </div>
    );
  }
}
export default CreateAudiovisivo;