import React, { Component } from 'react';
import { Button, Card } from 'antd';
import Wrapper from '@common/decorators/Wrapper';

import ChangeForm from '../forms/ChangePassword';
import LogoForm from '@common/components/logoForm';

import UserServices from '@common/services/User';




@Wrapper({ route: 'ChangeTempPassword' })
class ChangeTempPassword extends Component {

  state = {
    loading: false,
    changed: false,
  }

  componentDidMount() {
    //console.log('prop change pwd', this.props)
  }

  tryChange(data) {

    this.setState({ loading: true });
    UserServices.changePassword({
      new_password: data.new_password,
      old_password: data.old_password,
      repeat_password: data.repeat_password
    }, () => {

      //console.log('responso password cambiata', response_change.data);

      this
        .props
        .dispatch({
          type: 'USER_STORE_SET_USER',
          user: {
            ...this.props.UserStore.user,
            is_temp_password: 0
          }
        });

      this.props.history.push('/dashboard');



    }, () => {

      this.setState({ loading: false });
    })
  }


  annullate() {
    this
      .props
      .dispatch({
        type: 'LOGOUT'
      });
  }

  render() {

    return (
      <div style={{ width: 400, margin: '40px auto' }}>
        <LogoForm />
        <p style={{ textAlign: 'center' }}>La tua password è scaduta, impostane una nuova
        </p>
        <Card
          className="shadow"
          bordered={false}
        >

          {(!this.state.changed) ? <ChangeForm loading={this.state.loading} onSubmit={(data) => this.tryChange(data)} /> : <p>Password modificata</p>}
          <Button type="link" style={{cursor: 'pointer', float: 'right'}} className="login-form-forgot" onClick={()=>this.annullate()}>Annulla</Button>
        </Card>
      </div>
    );
  }
}

export default ChangeTempPassword;
