import BaseRequest from '@common/services/_baseRequest';

export default {

    list: (params = {}, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            params,
            {},
            null,
            'get',
            'ruolo/richiesta',
            cb,
            ecb
        );
    },

    detail: (params = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            params,
            {},
            null,
            'get',
            'ruolo/richiesta/' + id,
            cb,
            ecb
        );
    },

    update: (params = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            params,
            null,
            'put',
            'ruolo/richiesta/' + id,
            cb,
            ecb
        );
    },

    create: (params = {}, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            params,
            null,
            'post',
            'ruolo/richiesta',
            cb,
            ecb
        );
    },

    delete: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'delete',
            'ruolo/richiesta/' + id,
            cb,
            ecb
        );
    },

    saveJSON: (json = {}, id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {
                json: json,
            },
            null,
            'post',
            'ruolo/richiesta/' + id + '/save-json',
            cb,
            ecb
        );
    },

    confirm: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'ruolo/richiesta/' + id + '/confirm',
            cb,
            ecb
        );
    },

    send: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'ruolo/richiesta/' + id + '/send',
            cb,
            ecb
        );
    },
  
    uploadDecreto: (id, data = {}, file = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
          {},
          data,
          file,
          "post",
          'ruolo/richiesta/' + id + '/upload-decreto',
          cb,
          ecb || null,
          null
        );
      },


    confirmReject: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'ruolo/richiesta/' + id + '/reject',
            cb,
            ecb
        );
    },

    approve: (id = null, cb = null, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            'post',
            'ruolo/richiesta/' + id + '/approve',
            cb,
            ecb
        );
    },

    rollback: (id, cb, ecb = null) => {
        var req = new BaseRequest();
        return req.makeRequest(
            {},
            {},
            null,
            "post",
            'ruolo/richiesta/' + id + '/rollback',
            cb,
            ecb
        );
    },
}