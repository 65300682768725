import BaseRequest from "@common/services/_baseRequest";


export default {

	validateProfile: (data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/request/validate-profile',
			cb,
			ecb || null,
			null
		);
	},

	validateOrganization: (data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/request/validate-organization',
			cb,
			ecb || null,
			null
		);
	},

	registrazione: (vars = {}, files = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			vars,
			files,
			"post",
			'accreditamento/request/registrazione',
			cb,
			ecb || null,
			null
		);
	},

	registrazioneDiscente: (vars = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			vars,
			null,
			"post",
			'accreditamento/request/registrazione-discente',
			cb,
			ecb || null,
			null
		);
	},

	validateProfileDiscente: (data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/request/validate-profile-discente',
			cb,
			ecb || null,
			null
		);
	},

	registrazioneUtenteGenerico: (vars = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			vars,
			null,
			"post",
			'accreditamento/request/registrazione-utente-generico',
			cb,
			ecb || null,
			null
		);
	},

	registrazioneUtenteEnte: (userId, vars = {}, files = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			vars,
			files,
			"post",
			`accreditamento/request/registrazione-ente/${userId}`,
			cb,
			ecb || null,
			null
		);
	},	

}
