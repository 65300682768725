import React, { Component } from 'react';
import { Icon } from 'antd';
import CommonForm from '@common/components/form';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Indirizzo email',
              type_string: 'email', // field type
              name: 'email',
              props: {
                size: 'large',
                prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                // {
                //   pattern: 'email',
                //   message: 'Indirizzo email non valido'
                // }
              ],
            },
            {
              name: 'submit',
              type: 'submit',
              text: 'Invia',
              btn_type: 'primary',
              props: {
                block: true,
                size: "large"
              }
            }
          ]
        }

      ]
    }
  ],
  onSubmit: 'submitted'
}


class AskResetForm extends Component {

  state = {
    loading: false
  }



  componentDidMount() {
    //this.props.dispatch({
    //type: 'SET_CURRENT_PAGE',
    //current_page: '/Esempio form'
    //});
  }




  submitted(data) {
    //console.log('form',data);
    this.props.onSubmit(data);
  }

  render() {
    return (
      <div>
        <CommonForm
          loading={this.props.loading || false}
          form_model={formLayout}
          values={{

          }}
          submitted={(data) => this.submitted(data)}
          accreditate={() => null}
        />

      </div>
    );
  }
}

export default AskResetForm