import BaseRequest from "@common/services/_baseRequest";



export default {

	view: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/nomina/'+id,
			cb,
			ecb || null,
			null
		);
	},

	viewVerbale: (id_accreditamento, id_nomina, id_verbale, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/nomina/'+id_accreditamento+'/verbale/'+id_verbale,
			cb,
			ecb || null,
			null
		);
	},

	list: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			params,
			{},
			null,
			"get",
			'accreditamento/nomina',
			cb,
			ecb || null,
			null
		);
	},

	listIspettori:  (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			params,
			{},
			null,
			"get",
			'accreditamento/nomina/ispettori',
			cb,
			ecb || null,
			null
		);
	},

	assign: (id, data = { id_ispettore: null, id_accreditamento: null }, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/nomina/' + id + '/assign',
			cb,
			ecb || null,
			null
		);
	},

	randomassign: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/nomina/' + id + '/random', //?expand=accreditamenti ma che mettete gli expand qui? ma perchè??? perchè???
			cb,
			ecb || null,
			null
		);
	},

	download: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/nomina/' + id + '/download/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	download_word: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/nomina/' + id + '/download-word/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	upload: (id, id_nomina, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/nomina/' + id + '/upload/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	confirm: (id, id_nomina, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/nomina/' + id + '/confirm/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	download_ispettore: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/nomina/' + id + '/ispettore/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	feedback: (id, id_nomina, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/nomina/' + id + '/feedback/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	confirm_feedback: (id, id_nomina, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/nomina/' + id + '/confirm-feedback/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	download_verbale: (id, id_verbale, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/nomina/' + id + '/verbale/' + id_verbale + '/download',
			cb,
			ecb || null,
			null
		);
	},

	upload_verbale: (id, id_verbale, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/nomina/' + id + '/verbale/' + id_verbale + '/upload',
			cb,
			ecb || null,
			null
		);
	},

	confirm_verbale: (id, id_nomina, id_verbale, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/nomina/' + id + '/verbale/' + id_nomina + '/confirm/' + id_verbale,
			cb,
			ecb || null,
			null
		);
	},

	update_verbale_ispezione: (id, id_nomina, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"put",
			'accreditamento/nomina/' + id + '/verbale/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	saveVerbale: ( id_accreditamento, id_verbale, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"put",
			'accreditamento/nomina/'+id_accreditamento+'/verbale/' + id_verbale,
			cb,
			ecb || null,
			null
		);
	},

	confirmCompilazioneVerbale: (id_accreditamento, id_verbale, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/nomina/'+id_accreditamento+'/verbale/' + id_verbale + '/confirm-compilazione',
			cb,
			ecb || null,
			null
		);
	},

	uploadVerbaleControfirmato: (id_accreditamento, id_nomina, id_verbale, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/nomina/'+id_accreditamento+'/verbale/' + id_nomina + '/upload-verbale-controfirmato/' + id_verbale,
			cb,
			ecb || null,
			null
		);
	},

	addCorso: (id_verbale, data = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/nomina/verbale/' + id_verbale + '/corso',
			cb,
			ecb || null,
			null
		);
	},

	removeCorso: (id_verbale, id_corso, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/nomina/verbale/' + id_verbale + '/corso/' + id_corso,
			cb,
			ecb || null,
			null
		);
	},

	download_autocertificazione: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/nomina/' + id + '/autocertificazione/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	download_autocertificazione_word: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/nomina/' + id + '/autocertificazione-word/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

	download_dichiarazione_disponibilita: (id, id_nomina, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/nomina/' + id + '/dichiarazione-disponibilita/' + id_nomina,
			cb,
			ecb || null,
			null
		);
	},

}
