import React, { Component } from 'react';
import { Button, Card, Row, Drawer, Tooltip, Popconfirm, message } from 'antd';

import TableComponent from '@components/table';

import ModelServices from '@affidamento/services/Prove';
import CorsiServices from '@affidamento/services/Corsi';

import checkPermission from '@common/utils/check/permissions';
import CommonForm from '@common/components/form';
import moment from 'moment';

import _ from '@common/utils/formatters/_'

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'datepicker',
                label: 'Data',
                name: 'data',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                label: 'Dalle',
                name: 'dalle',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                label: 'Alle',
                name: 'alle',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Docente',
                name: 'id_docente',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'docenti_corso',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.canSelectTeacher(); }
                  },
                ]
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Oggetto della prova',
                name: 'oggetto',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
};


const renderActions = (element, component) => {
  return <div style={{ display: 'inline-block' }}>

    {checkPermission.hasPermission(component.props.UserStore.user, ['editValutazioni']) &&
      <Tooltip placement="top" title="Modifica prova">
        <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.editTest(element)} />
      </Tooltip>
    }

    {checkPermission.hasPermission(component.props.UserStore.user, ['deleteValutazioni']) &&
      <Tooltip placement="top" title="Elimina prova">
        <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere?" onConfirm={() => component.deleteTest(element.id)} okText="Si" cancelText="No">
          <Button className="m2w" size="small" shape="circle" icon="delete" />
        </Popconfirm>
      </Tooltip>
    }
  </div>
}

const model = (component) => {

  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "50px",
        render: element => <div style={{ width: 140 }}>
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Data',
        dataIndex: '',
        sorter: true,
        key: 'data',
        render: element => <div>
          {element.data ? moment(element.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        title: 'Dalle',
        dataIndex: '',
        sorter: false,
        key: 'dalle',
        render: element => <div>
          {element.dalle ? moment(element.dalle, 'HH:mm:ss').format('HH:mm') : ''}
        </div>
      },
      {
        title: 'Alle',
        dataIndex: '',
        sorter: false,
        key: 'alle',
        render: element => <div>
          {element.alle ? moment(element.alle, 'HH:mm:ss').format('HH:mm') : ''}
        </div>
      },
      {
        title: 'Oggetto della prova',
        dataIndex: '',
        sorter: true,
        key: 'oggetto',
        render: element => <div>
          {element.oggetto || ''}
        </div>
      }
    ]
  };
}

class TabellaProveDiValutazione extends Component {

  default_order = 'id'

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
    open_drawer: false,
    docenti_corso: null,
  }

  componentDidMount() {
    this.fetch();
    this.loadDocenti();
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(this.state.params, this.default_order);

    this.setState({ loading: true });
    ModelServices.list(params, this.props.corso.id, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  canSelectTeacher() {
    //return checkPermission.hasPermission(this.props.UserStore.user, ['editDocentiPerProgetto']);
    return this.props.UserStore.user.role.some(r => r.name !== 'Docente'); //TODO aggiungere un permesso
  }

  loadDocenti() {
    if (!this.canSelectTeacher()) {
      return;
    }
    CorsiServices.listDocenti({}, this.props.corso.id, res => {
      this.setState({
        docenti_corso: res.data.data.list.map(d => {
          return {
            ...d,
            label: d.anagrafica.nome + ' ' + d.anagrafica.cognome,
            key: 'docente_' + d.id,
            value: d.id,
          };
        })
      });
    });
  }

  canEditTest(id) {
    return checkPermission.hasPermission(this.props.UserStore.user, ['editValutazioni']);
  }

  canAddTest() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['addValutazioni']);
  }

  deleteTest(id) {
    this.setState({ loading: true });
    ModelServices.delete(id,
      res => {
        this.setState({
          open_drawer: false,
        }, () => {
          message.success('Prova di valutazione rimossa');
          this.fetch();
        })
      });
  }

  addTest() {
    this.setState({
      open_drawer: true,
      editTest: {
        id_progetto: this.props.corso.id,
      }
    });
  }

  editTest(test) {
    this.setState({ open_drawer: true, editTest: test });
  }

  updateQueryStr(obj) {
    if (obj.order && obj.sort) {
      obj = {
        sort: (obj.order === 'descend' ? '-' : '') + obj.sort
      }
    }
    this.setState({
      params: {
        ...this.state.params,
        ...obj
      }
    }, () => {
      this.fetch();
    });
  }

  render() {
    return (
      <div>
        <Card
          className="shadow no-pad"
          bordered={false}>

          <Row type="flex" justify="start" className="m10h">
            {this.canAddTest() &&
              <div className="m5h m5w">
                <Button className="m5h bg-green" icon="plus" onClick={() => this.addTest()} loading={this.state.loading}>Nuova valutazione</Button>
              </div>
            }
          </Row>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort, order: order })}
          />
        </Card>

        {this.state.open_drawer &&
          <Drawer
            title={this.state.editTest?.id ? "Modifica prova di valutazione" : "Aggiungi prova di valutazione"}
            placement="right"
            closable={true}
            onClose={() => this.setState({ open_drawer: false })}
            visible={this.state.open_drawer}
            width={1024}
            destroyOnClose={true}
          >
            <CommonForm
              wrappedComponentRef={ref => this.formRef = ref}
              loading={this.state.loading || false}
              form_model={formLayout(this)}
              values={this.state.editTest}
              isEdit={() => this.state.editTest?.id}
              onSubmit={data => {
                data = {
                  ...this.state.editTest,
                  ...data,
                  dalle: data.dalle.format('HH:mm'),
                  alle: data.alle.format('HH:mm'),
                };
                this.setState({ loading: true });
                if (data.id) {
                  ModelServices.update(data, data.id,
                    res => {
                      this.setState({
                        editTest: res,
                        open_drawer: false,
                      }, () => {
                        message.success('Prova di valutazione modificata');
                        this.fetch();
                      })
                    }, () => {this.setState({ loading: false })});
                } else {
                  ModelServices.create(data,
                    res => {
                      this.setState({
                        editTest: res,
                        open_drawer: false,
                      }, () => {
                        message.success('Prova di valutazione aggiunta');
                        this.fetch();
                      })
                    }, () => {this.setState({ loading: false })});
                }
              }}
              docenti_corso={this.state.docenti_corso}
            />
          </Drawer>
        }
      </div>
    );
  }
}

export default TabellaProveDiValutazione;
