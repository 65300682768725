import React, { Component } from 'react';
import { Button, Row, Col, notification } from 'antd';
import Wrapper from '@common/decorators/Wrapper';
import AuthServices from '../services/Auth';
import '../script/actions';
import '../../../spid_css/spid.css'


@Wrapper({ route: 'Login', hasSidebar: false, hasHeader: true, hasFooter: true })
class LoginRoute extends Component {

  state = {
    loading: false,
    mounted: false
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  componentWillUnmount() {
    this.setState({ mounted: false })
  }

  async tryLogin(data) {
    if (this.state.mounted) this.setState({ loading: true });
    AuthServices.login({ username: data.username, password: data.password }, async (response_user) => {
      // login andato a buon fine, posso impostare utente
            
      if (this.state.mounted) this.setState({ loading: false });

      localStorage.setItem('token', response_user.data.data.token)
      await this
        .props
        .dispatch({
          type: 'SET_USER_STORE',
          obj: {
            jwt: response_user.data.data.token,
            loggedIn: true,
            user: response_user.data.data.user,
            role: response_user.data.data.user.role.name
          }
        });

      //this.props.history.push('dashboard');

    }, (e) => {
      if (this.state.mounted) this.setState({ loading: false });
    })
  }

  render() {
    console.warn("process.env", process.env);

    return (

      <Row type="flex" justify="center">
        <Col lg={8} md={24} xs={24} sm={24} style={{ margin: '40px auto' }}>
          <h3 className="sectionTitle" style={{ textAlign: 'center' }}>LOGIN</h3>
          
          <br/>
          <Button 
          type="primary" 
          size="large" 
          className="italia-it-button italia-it-button-size-auto button-spid" 
          block={true} 
          onClick={() => {
            //const apiUrl = this.props.GlobalStore.API_URL;
            //const baseUrl = this.props.GlobalStore.BASE_URL;
            //const returnTo = encodeURIComponent(`${baseUrl}#/samlSsoResponse`);
            //console.warn("returnTo", returnTo)
            //window.location.href = `http://regione.campania.it/`;
            /*window.location.href = `${apiUrl}auth/saml/sso?returnTo=${returnTo}`;*/
            AuthServices.openIdConnectUrlLogin( 
              /* callback success */
              (response_user) => {
                console.warn(response_user.data.data);
                window.location.href = response_user.data.data;
                },
              /* callback error */
              (response_user) => {
                notification.error({
                  message: 'Errore',
                  description: 'Si è verificato un errore. Contattare l\'Assistenza',
                });
              }, )
            return null;
          }}>
            Accesso con SPID
          </Button>
          <br/>
          {
            process.env.REACT_APP_ENVIRONMENT !== 'production' ? 
            <>
            {/*
            <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/login-user-pwd')}>Accesso standard</Button>
            <br/>
            */}
            </>
            : null
          }
          <br/>
          <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/accreditamento')}>Registra il tuo ente</Button>
          {/*
          <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/learner')}>Registrazione Discente</Button>
          <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/utente-generico')}>Registrazione Utente</Button>
          */}
        </Col>
      </Row>
    );
  }
}

export default LoginRoute;

//export default connect(({UserStore, GlobalStore}) => ({UserStore, GlobalStore}))(FormConnected);
//export default Form.create()(LoginForm)