import React, { Component } from 'react';

class PrivacyContent extends Component {

  render() {

    return (
      <div>
        
        <h2>Privacy</h2>

        <h3>INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI (AI SENSI DELL'ARTICOLO 13 DEL REGOLAMENTO (UE) 2016/679)</h3>
        <p>
          Ai sensi dell’art. 13 del Regolamento UE n. 2016/679 (“General Data Protection Regulation” o “GDPR”)  gli utenti che accedono al sito https://www (inserire l’indirizzo del proprio sito) e che utilizzano i servizi disponibili in via telematica, prendono atto delle seguenti informazioni e accettando le condizioni alle quali la Giunta della Regione Campania in materia di  trattamento dei dati personali. La presente informativa è resa esclusivamente in relazione al sito  (inserire l’indirizzo del proprio sito) e non anche in relazione ad altri siti web che possono essere consultati dall’utente tramite link riportati o accessibili nel portale medesimo.        </p>        
        <p>
        <br />

        <h3>TITOLARE DEL TRATTAMENTO</h3>
        <p>
          Il titolare del trattamento è La Giunta della Regione Campania con sede in Napoli  alla Via Santa Lucia n 81.</p>        
        </p>
        <br />
        
        <h3>Finalità del trattamento e base giuridica</h3>
        <p>
          I dati personali acquisiti mediante il sito saranno trattati ai sensi dell’art. 6 lett. b) ed e) del GDPR, per gestire e mantenere il sito, per consentire la fruizione dei servizi e il soddisfacimento delle richieste degli utenti, per consentire un’efficace comunicazione istituzionale, per adempiere agli obblighi previsti dalla legge, da un regolamento, dalla normativa comunitaria o da un ordine dell’Autorità o comunque connessi alle attività e funzioni istituzionali, o infine per prevenire o scoprire attività fraudolente o abusi a danno dell’Associazione attraverso il sito.
        </p>
        <p>
          L’invio di posta elettronica agli indirizzi istituzionali indicati nel portale e la compilazione di format comportano la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella mail o nel format. In tal caso i dati acquisiti saranno trattati esclusivamente per rispondere alle richieste degli utenti. Specifiche informative o indicazioni sulla normativa/base giuridica di riferimento potranno essere inserite, in relazione ai particolari trattamenti di dati, in specifiche pagine del sito, nei format o nei modelli di documenti pubblicati nel sito.          
        </p>
        <br />

        <h3>Luogo e modalità del trattamento</h3>
        <p>
          Il trattamento dei dati acquisiti mediante il sito e/o connessi ai servizi del sito si svolge presso gli Uffici della Giunta Regionale della Campania ed eventualmente presso altri soggetti o sistemi informatici/server di altri soggetti appositamente designati come Responsabili (esterni) del trattamento. Il trattamento dei dati avviene sia in via cartacea sia mediante l’utilizzo di strumenti informatici, secondo i principi di correttezza, liceità, trasparenza, pertinenza e non eccedenza rispetto alle finalità di raccolta e di successivo trattamento e previa adozione le misure di sicurezza adeguate volte a prevenire la perdita dei dati, gli usi illeciti o non corretti, gli accessi non autorizzati ed in generale volte ad assicurare il rispetto delle previsioni del GDPR e del D. Lgs. n. 193/2006 e ss.mm. I dati sono trattati esclusivamente da personale, amministrativo e tecnico, autorizzato al trattamento o da eventuali persone autorizzate per occasionali operazioni di manutenzione. Il sito e i servizi on line non sono destinati a minori di 18 anni. I dati relativi a minori potranno essere trasmessi all’Associazione tramite l’accesso al sito e ai servizi solamente dai soggetti esercitanti la responsabilità genitoriale.
        </p>
        <br />

        <h3>Obbligo o facoltà di conferire i dati</h3>
        <p>
        L’utente è libero di fornire i dati personali inseriti nei format di richiesta. Il mancato conferimento dei dati necessari a rendere il servizio può comportare l’impossibilità di ottenere quanto richiesto.
        </p>
        <br />

        <h3>Periodo di conservazione</h3>
        <p>
          Ai sensi dell’art. 5 GDPR, i dati verranno trattati e conservati per un periodo di tempo non superiore al conseguimento delle finalità proprie del servizio e del trattamento e/o nel rispetto dei termini previsti da norme di legge o regolamento.
        </p>
        <br />

        <h3>Comunicazione e diffusione dei dati</h3>
        <p>
          I dati conferiti dagli Utenti non sono destinati a terzi e non saranno oggetto di comunicazione o diffusione, salvo che disposizioni di legge o di regolamento dispongano diversamente (in particolare i dati potranno essere comunicati a Organismi di vigilanza, Autorità giudiziarie nonché a tutti gli altri soggetti ai quali la comunicazione sia obbligatoria per legge per l’espletamento delle suddette finalità.
        </p>
        <br />

        <h3>Trasferimento dei dati all’estero</h3>
        <p>
        I dati non vengono trasferiti a paesi terzi al di fuori dello Spazio Economico Europeo.
        </p>
        <br />

        <h3>Diritti dell’interessato</h3>
        <p>
          All’utente/interessato sono garantiti tutti i diritti specificati all’art. 15 - 20 GDPR, tra cui il diritto all’accesso, rettifica e cancellazione dei dati, il diritto di limitazione e opposizione al trattamento, il diritto di revocare il consenso al trattamento (senza pregiudizio per la liceità del trattamento basata sul consenso acquisito prima della revoca), nonché il di proporre reclamo al Garante per la Protezione dei dati personali qualora si ritenga che il trattamento violi il Regolamento. I suddetti diritti possono essere esercitati mediante comunicazione scritta da inviare a mezzo posta elettronica, p.e.c. o fax, o a mezzo Raccomandata presso la sede dell’Associazione.
        </p>
        <p>
          Per tutto quanto riguarda la regolamentazione sul trattamento dei dati ai sensi del Reg. UE 2016/679 si rinvia alla Deliberazione di G.R. n. 466 del 17.07.2018 reperibile sul sito istituzionale della Regione Campania nella sezione “Casa di Vetro”.
        </p>
        <br />

      </div>
    );
  }
}

export default PrivacyContent;