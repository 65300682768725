export default {
  sede: {
    tipo: {
      legale: 0,
      operativa: 1
    }
  },
  lucro: {
    si: 1,
    no: 2
  }
}