import React, { Component } from 'react';
import { message } from 'antd';
import CommonForm from '@common/components/form';
import formatter from '@common/utils/formatters/_';

const getTipoAula = (component, record) => {
  try {
    let type = formatter.replaceStub(record.id_tab_tipo_aula, 'tipo_aula', component.props.GlobalStore)

    return type;
  } catch(e) {
    return 'errore'
  }
}

const formLayout = () => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'select',
                                label: 'Aule',
                                name: 'aule',
                                mode: 'multiple',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                options_props: 'aule',
                            },
                        ],
                    },
                ]
            },
            {
                cols:[
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                input_type: 'string',
                                label: 'Ausili',
                                name: 'ausili',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        span: 12,
                        items: [
                            {
                                type: 'integer',
                                label: 'Quantità',
                                name: 'quantita',

                                props: {
                                    style: { width: '100%' },
                                    min: 1,
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                            },
                        ],
                    }
                ],
            },/*
            {
                cols: [
                    {
                        items: [
                            {
                                type: 'radio',
                                label: 'Le attrezzature indicate sono tutte disponibili presso la sede indicata all’atto nella presente richiesta',
                                name: 'disponibile_in_sede',
                                option_type: 'button', // normal|button
                                // le opzioni come le selects
                                options: [
                                    {
                                        value: "1",
                                        label: "Si"
                                    },
                                    {
                                        value: "0",
                                        label: "No"
                                    }
                                ],
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                            }
                        ],
                    }
                ]
            }, */
            {
                cols: [
                    {
                        props: {

                            style: { float: 'right' }
                        },
                        items: [
                            {
                                type: 'submit',
                                name: 'btn_submit_attrezzatura',
                                text: 'Salva materiale audio/video',
                                //onClick: 'showDrawer',
                                props: {
                                    icon: 'save',

                                    className: 'bg-green'
                                }
                            }
                        ],
                    },
                ],
            },
        ],
        onSubmit: 'onSubmit',
    }
}

class UpdateAudiovisivo extends Component {

    state = {
        loading: false,
        loaded: false,
        fileList: [],
        comuni: [],
        selected_aula: null
    };



    async componentDidMount() {
        this.setState({
            selected_aula: this.props.values.id_aula,
            fileList: this.props.values.media ? this.props.values.media.map(el => {
                return {
                    uid: el.id,
                    id: el.id,
                    name: el.nome_file,
                    file: null,
                    status: 'done'
                }
            }) : []
        })
    }




    submitFormData(data) {

        if(parseInt(data.clausole_risolutorie) === 1 && this.state.fileList.length === 0) {
            message.error('Carica il documento con le clausole risolutorie');
            return;
        }

        this.props.onSubmit(formatter.replaceFormDatesOut( [
            'data_registrazione',
            'data_in_vigore',
            'data_scadenza'
            ], data, 'YYYY-MM-DD' ), (parseInt(data.clausole_risolutorie) === 1) ?
        this.state.fileList.filter(el=>!el.id) : null)
    }

    replaceAule(el){
        let obj = Object.assign({}, el);
        obj.aule = el.aule.map(a => {
            return ""+a.id
        });

        return obj;
    }



  render() {

    return (
      <div>
        <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.props.loading || false}
            form_model={formLayout()}
            values={this.replaceAule(
                formatter.convertBoolean([
                    'disponibile_in_sede'
                ],
                this.props.values
                )
            )}
            readOnly={this.props.readOnly}
            onSubmit={(data)=>this.submitFormData(data)}



            aule={this.props.sede.aule.map(aula => {
                return {
                    value: aula.id,
                    label: aula.nome_aula + " " + getTipoAula(this, aula),
                    key: 'aula_' + aula.id
                }
            })}


        />
      </div>
    );
  }
}
export default UpdateAudiovisivo;