import BaseRequest from '@common/services/_baseRequest';


export default {

  list: (vars = {}, id_iscrizione = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      { id_iscrizione: id_iscrizione, ...vars },
      {},
      null,
      'get',
      'affidamento/valutazione',
      cb,
      ecb || null,
      null
    );
  },

  detail: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/valutazione/' + id,
      cb,
      ecb || null,
      null
    );
  },

  create: (data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/valutazione',
      cb,
      ecb || null,
      null
    );
  },

  update: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "put",
      'affidamento/valutazione/' + id,
      cb,
      ecb || null,
      null
    );
  },

  delete: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'affidamento/valutazione/' + id,
      cb,
      ecb || null,
      null
    );
  },

}
