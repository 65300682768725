import React, { Component } from 'react';
import CommonForm from '@common/components/form';

const formLayoutAreaAttivita = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Denominazione AdA',
                type_string: 'text',
                name: 'denominazione_ada',
                props: {
                  disabled: true,
                },
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione della performance',
                type_string: 'text',
                name: 'descrizione_performance',
                props: {
                  disabled: true,
                },
                validations: [
                  !component.props.readOnly ? {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  } : {},
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'UC',
                type_string: 'text',
                name: 'uc',
                props: {
                  disabled: true,
                },
              }
            ]
          },

          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Capacità-abilità',
                type_string: 'text',
                name: 'capacita_abilita',
                props: {
                  disabled: true,
                },
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Conoscenze',
                type_string: 'text',
                name: 'conoscenze',
                props: {
                  disabled: true,
                },
              }
            ]
          },
        ]
      }

    ],
  }
}


export default class DettaglioAreaAttivita extends Component {

  state = {
  };


    render() {
        return <div>
            <CommonForm
                loading={this.props.loading || false}
                form_model={formLayoutAreaAttivita(this)}
                values={this.props.values}
                readOnly={true}
                subform={this.props.subform}
            />
        </div>
    }
}

