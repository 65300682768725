import React, { Component } from 'react';
//import { connect } from "react-redux";

import { message } from 'antd';
import moment from 'moment';
import CommonForm from '@common/components/form';
import progettoService from '../../services/Progetto';


export default class ProgrammaInizioCorso extends Component {

  state = {
    loading: false,
  }

  getFormLayout() {

    let form_rows = [];

    form_rows.push(
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'datepicker',
                label: 'Data di inizio corso',
                name: 'data_avvio_corso',
                format: 'DD/MM/YYYY',
                props: {
                  size: 'normal',
                  style: { width: '100%' },
                  disabled: this.state.loading,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_date'
                  },
                ],

              }
            ]
          },
        ],
      }

    );

    form_rows.push({
      cols: [
        {
          items: [
            {
              name: 'btn_submit',
              type: 'submit',
              text: 'Programma inizio corso',
              btn_type: 'primary'
            },
          ],
        },
      ],
    });

    return {
      layout: 'vertical',
      multistep: false,
      rows: form_rows,
      onSubmit: 'attiva',
    }
  }

  submit(data) {
    if (!this.state.loading) {
      this.setState({ loading: true });
      progettoService.prepareCourse(this.props.id_progetto, data,
        (res) => {
          //this.props.refresh(false);
          this.props.refreshInline(res.data.data)
          message.success("Data inizio corso salvata")
          this.setState({
            loading: false,
          });
        },
        err => this.setState({
          loading: false,
        })
      );
    }
  }

  render() {
    return <div>
      {
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={this.getFormLayout()}
          attiva={(data) => this.submit(data)}
          min_date={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            if (moment().diff(value, 'days') > 0) cb("La data di inizio corso non può essere precedente alla data odierna");
            else cb();
          }}

        />
      }
    </div>
  }

}
