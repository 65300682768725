import React, { Component } from "react";
import CommonForm from '@common/components/form';
import { Button } from "antd";

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'registro_title',
                type: 'title',
                element: 'h4',
                text: 'Locali e Attrezzature',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'E’ consentito il libero accesso ai locali?',
                name: 'locale.0.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'locale.0.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'locale.0.note',
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'I locali sono quelli dichiarati idonei in occasione di visita  preliminare?',
                name: 'locale.1.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'locale.1.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'locale.1.note',
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Gli impianti fissi ed installati sono quelli dichiarati idonei in occasione di visita preliminare?',
                name: 'locale.2.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'locale.2.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'locale.2.note',
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Le attrezzature tecnico-didattiche sono quelle rilevate in sede di visita preliminare? ',
                name: 'locale.3.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'locale.3.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'locale.3.note',
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Le attrezzature tecnico-didattiche sono efficienti e  funzionali all’attività svolta? ',
                name: 'locale.4.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'locale.4.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'locale.4.note',
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Le attrezzature sono quelle previste per lo svolgimento delle attività svolte?',
                name: 'locale.5.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'locale.5.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'locale.5.note',
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'text',
                text: 'Le attrezzature sono sufficienti per il numero degli allievi Impegnati?',
                name: 'locale.6.domanda'
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: '',
                name: 'locale.6.risposta',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                  {
                    value: "2",
                    label: "N.A."
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'textarea',
                label: '',
                name: 'locale.6.note',
              },
            ],
          },
        ],
      }
    ],
    onSubmit: 'onSubmit',
  };
}

/**
 * info locali:
 * aula teorica = aula corso
 * aula teorico-pratica = laboratorio
 */

class LocaliEAttrezzature extends Component {

  state = {
    mounted: false,
    loading: false,
    custom_domanda_locali_e_attrezzature: []
  };

  componentDidMount() {
    if(this.props.values && this.props.values.custom_domanda_locali_e_attrezzature) this.setState({custom_domanda_locali_e_attrezzature: this.props.values.custom_domanda_locali_e_attrezzature})
  }

  async validate() {
    return new Promise((resolve) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getValues() {
    let obj = {
      ...this.formSelRef.props.form.getFieldsValue(),
      ...this.formSelRefCustom.props.form.getFieldsValue(),
    };
    return obj;
  }

  customformLayout() {
    let rows = [];

    if (this.state.custom_domanda_locali_e_attrezzature) {
      this.state.custom_domanda_locali_e_attrezzature.forEach((d, index) => {
        rows.push(
          {
            cols: [
              {
                span: 12,
                items: [
                  {
                    type: 'input',
                    input_type: 'text',
                    name: 'custom_domanda_locali_e_attrezzature.' + index + '.text'
                  },
                ],
              },
              {
                span: 6,
                items: [
                  {
                    type: 'radio',
                    label: '',
                    name: 'custom_domanda_locali_e_attrezzature.' + index + '.risposta',
                    option_type: 'button', // normal|button
                    // le opzioni come le selects
                    options: [
                      {
                        value: "1",
                        label: "Si"
                      },
                      {
                        value: "0",
                        label: "No"
                      }
                    ],
                    props: {
                      //style: { width: '100%' },
                    },
                    validations: [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      },
                    ],
                  }
                ],
              },
              {
                span: 6,
                items: [
                  {
                    type: 'textarea',
                    label: '',
                    name: 'custom_domanda_locali_e_attrezzature.' + index + '.note',
                    props: {
                    },
                    validations: [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      },
                    ],
                  },
                ],
              }
            ]
          }
        )
      });
    }

    return {
      layout: 'vertical',
      multistep: false,
      rows: rows,
      onSubmit: 'onSubmit',
    }


  }

  addDomanda() {
    this.setState({
      custom_domanda_locali_e_attrezzature: [...this.state.custom_domanda_locali_e_attrezzature, {
        text: null,
        risposta: null,
        note: null
      }]
    })
  }

  render() {
    return <div style={{marginTop: 16}}>
          <CommonForm
            readOnly={this.props.readOnly}
            wrappedComponentRef={inst => (this.formSelRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout(this)}
            values={this.props.values}
            onSubmit={() => null}
            tipicorsi={[]}
          />
          <CommonForm
            readOnly={this.props.readOnly}
            wrappedComponentRef={inst => (this.formSelRefCustom = inst)}
            loading={this.state.loading || false}
            form_model={this.customformLayout()}
            values={this.props.values}
            onSubmit={() => null}
            tipicorsi={[]}
          />
          <Button
            disabled={this.props.readOnly}
            icon="plus"
            onClick={()=>this.addDomanda()}
          >
            Aggiungi domanda
          </Button>
        </div>
  }
}


export default LocaliEAttrezzature;