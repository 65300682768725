import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import TabellaComuni from '../components/TabellaComuni';

@Wrapper({ route: 'Comuni', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['manageComuni'])
class ComuniRoute extends Component {

    state = {
        loading: false,
    }

    render() {

        return (
            <div>
                <Card
                    style={{ marginBottom: '12px', backgroundColor: '#fff' }}
                    bordered={false}
                >
                    <h3 className="sectionTitle">Lista dei comuni</h3>
                </Card>
                <div style={{ padding: '12px' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/comuni">Lista dei comuni</Link></Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <TabellaComuni ref="listTable" {...this.props} />

            </div>
        );
    }
}

export default ComuniRoute;
