import React, {Component} from 'react';
import { message, Alert} from 'antd';

import moment from 'moment';

import CommonForm from '@common/components/form';

import format from '@common/utils/formatters/_';
import CreateAnagrafica from '@modules/anagrafica/forms/Create';
import Indirizzi from '@modules/anagrafica/components/Indirizzi';

import CapacitaGestionaliServices from '@accreditamento/services/CapacitaGestionali';


const formLayout =  (component) => {
  return {
  layout: 'vertical',
  multistep: false,
  rows: [/*
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Macrotipologia attività formativa',
              name: 'tipo_formazione',
              options_props: 'tipo_accreditamento',
              mode: 'multiple',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 16,
          items: [
            {
              type: 'select',
              label: 'Tipo rapporto di lavoro',
              type_string: 'text', // field type
              name: 'id_tipo_rapporto_lavoro',
              options_props: 'tipo_rapporto_lavoro',
              onChange: 'setRapportoLavoro',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Altro:',
              name: 'altro_rapporto_lavoro',
              props: {
                size: 'large'
              },
              showIf: [
                {
                  type: 'prop_func',
                  func: 'needToAddAltroRapporto'
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 8,
          items: component.state.tipo_rapporto_lavoro == 2 || component.state.tipo_rapporto_lavoro == null ? [
            {
              type: 'integerwithzero',
              label: 'Durata (anni)',
              type_string: 'number', // field type
              name: 'durata_rapporto_lavoro',
              props: {
                size: 'large'
              },
            }
          ] : [ {
            type: 'integerwithzero',
            label: 'Durata (anni)',
            type_string: 'number', // field type
            name: 'durata_rapporto_lavoro',
            min: 1,
            props: {
              size: 'large'
            },
            validations: [
              {
                type: 'required',
                error_message: 'Campo obbligatorio'
              },
              {
                validator: 'minUno'
              }
            ],
          } ]
        }
      ]
    },
    
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Titolo di studio',
              type_string: 'text', // field type
              name: 'titolo_studio',
              options_props: 'tipo_titolo_studio',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    */
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Sedi',
              name: 'sedi',
              options_props: 'sedi',
              mode: 'multiple',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },/*
    {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Autorizzazione al trattamento dei dati personali secondo i termini di legge',
                name: 'autorizz_tratt_dati',
                option_type: 'button', // normal|button
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ],
          }
        ],
    },*/
    {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Deve possedere i requisiti minimi professionali previsti dagli standard professionali e formativi e/o dagli specifici provvedimenti dei competenti uffici della Giunta Regionale. Inoltre deve aver proceduto alla pubblicazione del Cv sul sistema regionale https://cliclavoro.lavorocampania.it/nella sezione "Il mio CV"',
                name: 'requisiti_minimi',
                option_type: 'button', // normal|button
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    pattern: "1$",
                    message: 'Devi selezionare "Si"',
                  },
                ],
              }
            ],
          }
        ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'submit',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            }
          ]
        }
      ]
    },
  ],
  onSubmit: 'submitted',
}};


class CreateModel extends Component {

  state = {
    loading: false,
    searched_anagrafica: false,
    model_anagrafica: {},
    open_drawer: false,
    model: {
      tipo_formazione: [],
      sedi: []
    },
    tipo_rapporto_lavoro: null,
    altro_rapporto_selected: false
  }



  getFormLayout() {

  }






  updateQueryStr() {

  }

  selectedRapportoLavoro(value) {
    this.setState({tipo_rapporto_lavoro: value});

    if(value == 10) {
      this.setState({altro_rapporto_selected: true});
    } else {
      this.setState({altro_rapporto_selected: false});
      this.formRef.props.form.setFields(
        {
          altro_rapporto_selected: {
            value: ''
          }
        });
    }
  }




  async confirm(data) {

    if(!this.state.loading) {
      await this.setState({loading: true});

      data.id_anagrafica = this.state.model_anagrafica.id;

      CapacitaGestionaliServices.addDocente( data, this.props.accreditamento.id, ()=>{

        this.setState({loading: false});
        if(this.props.onUpdate) this.props.onUpdate();
      }, () => this.setState({loading: false}) )



    }
  }

  hasIndirizzi() {
    return this.state.model_anagrafica.conAnagraficaIndirizzi.some(el => parseInt(el.type) === 2) ||
    (
      this.state.model_anagrafica.conAnagraficaIndirizzi.some(el => parseInt(el.type) === 1) &&
      this.state.model_anagrafica.conAnagraficaIndirizzi.some(el => parseInt(el.type) === 0)
    );
  }

  getSedi() {
    return this.props.accreditamento.sedi
  }

  getAnaRapportoLavoro(ana) {
    try{
      return ana.rapportoLavoro.find(el => el.id_ente === this.props.accreditamento.id_ente)
    } catch(e) {
      return {}
    }
  }

  render () {
    return <div>
      {
        (!this.state.model_anagrafica.id)
        ?
        <CreateAnagrafica returnAnagrafica={ (ana)=>{

          this.setState({
            model_anagrafica: ana,
            model: {
              tipo_formazione: [],
              sedi: [],
              ...this.getAnaRapportoLavoro(ana)
            }
          })
        }}
        {...this.props} />
        :
        <React.Fragment>
          <p>
            {this.state.model_anagrafica.nome + " " +this.state.model_anagrafica.cognome + " (" + this.state.model_anagrafica.genere + ")"}<br />
            {this.state.model_anagrafica.cf}<br />
            Nato a {this.state.model_anagrafica.nato_a} il {moment(this.state.model_anagrafica.nato_il).format('DD/MM/YYYY')}<br />
            <strong>Cittadinanza:</strong> {format.replaceStub( this.state.model_anagrafica.id_nazione, 'nazione', this.props.GlobalStore)}
          </p>
          {
            (!this.hasIndirizzi()) ?
            <Alert
              style={{marginBottom: 16}}
              message="Inserisci indirizzi"
              description="Per poter inserire i dati relativi al docente occorre prima inserire indirizzo di residenza e indirizzo di domicilio, se coincidono inserisci un indirizzo selezionando come tipo: residenza/domicilio"
              type="info"
            /> :
            <React.Fragment>
              <h4>Dettagli funzione</h4>
              <CommonForm
                wrappedComponentRef={inst => (this.formRef = inst)}
                loading={this.state.loading || false}
                form_model={formLayout(this)}
                values={this.state.model}
                submitted={(dati) => this.confirm(dati)}
                tipo_accreditamento={this.props.GlobalStore.stubs.tipo_accreditamento.filter(el => this.props.accreditamento.accreditamentotipologie.some(e => e.id_tipologia === el.value))}
                setRapportoLavoro={(value)=>this.selectedRapportoLavoro(value)}

                tipo_rapporto_lavoro={this.props.GlobalStore.stubs.tipo_rapporto_lavoro}
                tipo_titolo_studio={this.props.GlobalStore.stubs.tipo_titolo_studio}

                needToAddAltroRapporto={()=>this.state.altro_rapporto_selected}

                minUno={(rule, value, cb) => {
                  if (parseInt(value) < 1) cb("Minimo 1");
                  else cb()
                }}

                sedi={this.props.sedi.map(el => {
                  return {
                    key: 'sede_' + el.enteSede.id,
                    label: format.getIndirizzo( el.enteSede.indirizzo, this.props.GlobalStore),//el.enteSede.indirizzo.via + " " + el.enteSede.indirizzo.ncivico + " (" + el.enteSede.indirizzo.locComune.comune + ")",
                    value: el.enteSede.id
                  }
                })}
              />
            </React.Fragment>
          }
          <h4>Indirizzi</h4>
          <Indirizzi
            UserStore={this.props.UserStore}
            model={this.state.model_anagrafica}
            onUpdate={(obj)=>{this.setState({model_anagrafica: obj})}}
            mayBeTeacher = {true}
          />
        </React.Fragment>
      }
    </div>
  }

}
export default CreateModel;