import React, {Component} from 'react';
import {
  Link
} from 'react-router-dom'
import {List} from 'antd';

import ModelServices from '../services/Notification';

import moment from 'moment';


const goTo = (notification, component) => {
  try {
    if (notification.ref && notification.ref.type) {
      switch (notification.ref.type) {
        case 'accreditamento':
          component.props.history.push('/accreditamento/scheda/' + notification.ref.id);
          break;
        case 'nomina':
          component.props.history.push('/accreditamento/' + notification.ref.id + '/nomina/' + notification.ref.id_nomina);
          break;
        case 'progetto':
          component.props.history.push('/affidamento/progetto/' + notification.ref.id);
          break;
        case 'nomina_progetto':
          component.props.history.push('/affidamento/' + notification.ref.id + '/nomina/' + notification.ref.id_nomina);
          break;
        case 'registro_discente':
          component.props.history.push('/affidamento/registro/' + notification.ref.id + '/discente/' + notification.ref.id_discente);
          break;
        case 'registro':
          component.props.history.push('/affidamento/registro/' + notification.ref.id);
          break;
        case 'richiesta':
          component.props.history.push('/repertorio/richieste/' + notification.ref.id);
          break;
        case 'ruolo':
          component.props.history.push('/ruoli/richieste/' + notification.ref.id);
          break;
        case 'valutatore':
          component.props.history.push('/valutatore/' + notification.ref.id);
          break;
        case 'ispettore':
          component.props.history.push('/valutatore/' + notification.ref.id);
          break;
        default: break;
      }
    }
  } catch (e) {
    console.error(e)
  }
}

class ListModel extends Component {

  state = {
    loading: false
  }

  componentDidMount () {
    this.updateData();

    //this.props.dispatch({
    //  type: 'CLEAR_USER_NOTIFICATIONS',
    //})

  }

  async updateData() {

    if(this.props.UserStore.notifications.list.length === 0 && !this.state.loading){
      await this.setState({loading: true});

      let obj = (this.props.UserStore.user.ente) ? {id_ente: this.props.UserStore.user.ente.id} : {};
      obj.page = 1;
      obj.sort = '-updated_at';
      ModelServices.list(obj, (res)=>{
        this
          .props
          .dispatch({
            type: 'ADD_LAST_USER_NOTIFICATIONS',
            notifications: res.data.data.list
          });
          this.setState({loading: false})
      }, (err)=>this.setState({loading: false}))
    }
  }

  clicked(notification) {
    if(notification.read !== 1) {
      let obj = (this.props.UserStore.user.ente) ? {ids: notification.id, id_ente: this.props.UserStore.user.ente.id} : {ids: notification.id};
      ModelServices.setRead( obj, (res) => {
        this
            .props
            .dispatch({
              type: 'SET_NOTIFICATION_READ',
              ids: [notification.id]
            });
      } );

    }
    goTo(notification, this);
  }


  render () {
    return (
      <div style={{width: 400}}>
        <div style={{maxHeight: 200, overflow:'auto'}}>
         <List
           loading={this.state.loading}
           locale={{ emptyText: 'nessuna nuova notifica da leggere' }}
            header={null}
            footer={null}
            dataSource={this.props.UserStore.notifications.list}
            renderItem={item => (
              <List.Item
                className={item.read !== 1 ? "not-read" : ""}
                onClick={()=>this.clicked(item)}
              >
                {/*<Typography.Text mark>{item.message}</Typography.Text>*/}
                {item.message}<br />
                <small style={{textAlign: 'right'}}>{moment(item.created_at,'X').format('DD/MM/YYYY HH:mm')}</small>
              </List.Item>
            )}
          />
        </div>
        <p className="popover-footer"><small><Link to="/notifiche">Vedi tutte</Link></small></p>
      </div>
    );
  }
}
export default ListModel;
