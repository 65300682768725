import BaseRequest from '@common/services/_baseRequest';


export default {

  list: (vars = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/procedura/' + id + '/domande',
      cb,
      ecb || null,
      null
    );
  },

  detail: (params = {}, id_domanda = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/domanda/' + id_domanda,
      cb,
      ecb || null,
      null
    );
  },

  delete: (id_domanda, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'affidamento/domanda/' + id_domanda,
      cb,
      ecb || null,
      null
    );
  },

  save_json: (domanda_json = {}, id_domanda = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { domanda_json: domanda_json },
      null,
      'post',
      'affidamento/domanda/' + id_domanda + '/save-json',
      cb,
      ecb || null,
      null
    );
  },

  progress: (params = {}, id_domanda = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/domanda/' + id_domanda + '/progress',
      cb,
      ecb || null,
      null
    );
  },

  info_and_validate: (domanda_json = {}, id_domanda = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { domanda_json: domanda_json },
      null,
      'post',
      'affidamento/domanda/' + id_domanda + '/info-and-validate',
      cb,
      ecb || null,
      null
    );
  },

  winner: (id_domanda = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/domanda/' + id_domanda + '/winner',
      cb,
      ecb || null,
      null
    );
  },

  loser: (id_domanda = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/domanda/' + id_domanda + '/loser',
      cb,
      ecb || null,
      null
    );
  },

  send: (id_domanda = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/domanda/' + id_domanda + '/send',
      cb,
      ecb || null,
      null
    );
  },

  printPdf: (id_domanda, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/domanda/' + id_domanda + '/print-pdf',
      cb,
      ecb || null,
      null
    );
  },

  attivaProgetto: (id_domanda, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/domanda/' + id_domanda + '/attiva-progetto',
      cb,
      ecb || null,
      null
    );
  },


}
