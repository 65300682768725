export default {
    stato: {
        predisposizione: 0,
        compilata: 1,
        acquisita: 2,
        rifiutata: 3,
        accettata: 4,
    },
  
    stato_flusso: {
        compilation: 0,
        compiled: 1,
        sent: 2,
        rejected: 3,
        approved: 4,
        confirm_approved: 5
    },
  
    tipo_richiesta: {
      nuova: 1,
      modifica: 2,
      integrazione: 3,
    },
  
    tipo_media: {
        sottodominio_decreto: 'decreto_accettazione_richiesta'
    },

    tipo_ruolo: {
        ispettore: 1,
        valutatore: 2,
    },

    getTipoRuolo(ruolo){
        let label = '';
        switch (ruolo) {
          case 1: label = 'Ispettore'; break;
          case 2: label = 'Valutatore'; break;
          default: break;
        }
        return label;
    },
  
    getPallino(user, c, stato) {
        let pallini = {
            dirigente: {
                green: [
                    this.stato_flusso.sent,
                    this.stato_flusso.approved,
                ],
                red: []
            },
            istruttore: {
                green: [],
                red: []
            },
            dipendente: {
                green: [
                    this.stato_flusso.compilation,
                    this.stato_flusso.compiled,
                ],
                red: [
                ]
            },
        }
        return pallini[user][c].indexOf(stato);

    }

  
  }
  