import React, { Component } from 'react';

import CommonForm from './form';

import { Card } from 'antd';

import queryString from 'query-string';
import moment from 'moment';

export default class FilterForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filters: {}
    };
  }





  replaceDatesIn(obj) {
    Object.keys(obj).forEach(k => {
      if (this.props.date_fields && this.props.date_fields.indexOf(k) !== -1) {
        if (moment(obj[k], 'YYYY-MM-DD').isValid()) {
          obj[k] = moment(obj[k], 'YYYY-MM-DD')
        } else {
          delete obj[k];
        }
      }
    })

    return obj
  }

  replaceDatesOut(obj) {
    Object.keys(obj).forEach(k => {
      if (!obj[k] || obj[k] === "") {
        delete obj[k];
      } else {
        if (this.props.date_fields && this.props.date_fields.indexOf(k) !== -1) {
          if (moment(obj[k]).isValid()) {
            obj[k] = moment(obj[k]).format('YYYY-MM-DD')
          } else {
            obj[k] = null
          }
        }
      }
    })

    return obj
  }

  handleSearchParams(){
    let qs = null;
    if(this.props.skipParse && this.props.skipParse === true){
      qs = this.props.search;
    } else {
      qs = queryString.parse(this.props.search)
    }
    return qs;
  }

  getValues() {
    let values = this.replaceDatesIn(this.handleSearchParams());
    return values
  }

  submitted(data) {
    let values = this.handleSearchParams();
    values = {
      ...values,
      ...data
    }

    values = this.replaceDatesOut(values);
    this.props.history.push(this.props.base_url + '?' + queryString.stringify(values))
  }

  getLayout() {
    let form = this.props.formLayout
    form.onSubmit = 'submitted';

    return form
  }

  render() {
    return <Card bordered={false} className="shadow" style={{ marginTop: 12, marginBottom: 12 }}>
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.props.loading || false}
        {...this.props.elements}
        form_model={this.getLayout()}
        values={this.getValues()}
        submitted={(data) => this.submitted(data)}
        {...this.props.form_elements}
      /></Card>
  }
}
