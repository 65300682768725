import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import SpinnerLoading from '@common/components/SpinnerLoading';
import Documenti from '@common/components/Documenti';
import ModelServices from '../../services/RichiesteQualifiche';
import FileSaver from 'file-saver';
import checkPermission from '@common/utils/check/permissions';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';
import repertorioStatic from '@common/utils/static/repertorio';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                span: 4,
                name: 'relazione_descrittiva',
                type: 'title',
                element: 'h4',
                text: 'Relazione descrittiva',
                props: {
                  style: { textAlign: 'left' },
                  className: component.props.repertorio?.validation?.requiredFiles?.relazione_descrittiva ? 'm10h ant-form-item-required' : 'm10h'
                },
              }
            ],
          },
        ],
      },
      component.props.showIntegrazione && {
        cols: [
          {
            items: [
              {
                name: 'relazione_descrittiva_note',
                type: 'child',
                child: <div style={{color:'red'}}>
                  <p>{component.daIntegrare(repertorioStatic.tipo_media.relazione_descrittiva)}</p>
                </div>
              }
            ],
          }
        ]
      },

      {
        cols: [
          {
            items: [
              {
                name: 'relazione_descrittiva_template_text',
                type: 'text',
                text: 'Scarica il template della relazione descrittiva',
                props: {
                  style: { textAlign: 'left' },
                },
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'relazione_descrittiva_template',
                type: 'button',
                text: 'Scarica file',
                props: {
                  loading: false,
                  disabled: false,
                  className: "m5h",
                  icon: "file-word",
                },
                onClick: 'downloadRelazione'
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'relazione_descrittiva_template_upload',
                type: 'child',
                child: <Documenti
                  readOnly={component.props.readOnly}
                  root={'repertorio'}
                  canDelete={true}
                  required_dates={false}
                  accept={'.pdf'}
                  hideTitle={true}
                  uploadButtonText={'Carica il documento'}
                  accreditation_id={component.props.repertorio.id}
                  unique={true}
                  tempFile={false}
                  media_types={component.props.tipo_media}
                  medias={component.props.repertorio.media}
                  current_domain="richieste_repertorio"
                  current_subdomain="relazione_descrittiva"
                  canAddNote={false}
                  required_types={[]}
                  ref_id={component.props.repertorio.id}
                  onUpload={() => component.props.refresh()}
                  onDelete={() => component.props.refresh()}
                />
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'dichiarazione_interesse',
                type: 'title',
                element: 'h4',
                text: 'Dichiarazione di interesse',
                props: {
                  style: { textAlign: 'left' },
                  className: component.props.repertorio?.validation?.requiredFiles?.dichiarazione_di_interesse ? 'm10h ant-form-item-required' : 'm10h'

                },
              }
            ],
          }
        ],
      },
      component.props.showIntegrazione && {
        cols: [
          {
            items: [
              {
                name: 'dichiarazione_interesse_note',
                type: 'child',
                child: <div style={{color:'red'}}>
                  <p>{component.daIntegrare(repertorioStatic.tipo_media.dichiarazione_di_interesse)}</p>
                </div>
              }
            ],
          }
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'dichiarazione_interesse_template_text',
                type: 'text',
                text: 'Scarica il template della dichiarazione di interesse',
                props: {
                  style: { textAlign: 'left' },
                },
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'dichiarazione_interesse_template',
                type: 'button',
                text: 'Scarica file',
                props: {
                  loading: false,
                  disabled: false,
                  className: "m5h",
                  icon: "file-word",
                },
                onClick: 'downloadDichiarazione'
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'dichiarazione_di_interesse_template_upload',
                type: 'child',
                child: <Documenti
                  readOnly={component.props.readOnly}
                  root={'repertorio'}
                  canDelete={true}
                  required_dates={false}
                  accept={'.pdf'}
                  hideTitle={true}
                  uploadButtonText={'Carica il documento'}
                  accreditation_id={component.props.repertorio.id}
                  unique={true}
                  tempFile={false}
                  media_types={component.props.tipo_media}
                  medias={component.props.repertorio.media}
                  current_domain="richieste_repertorio"
                  current_subdomain="dichiarazione_di_interesse"
                  canAddNote={false}
                  required_types={[component.props.tipo_media
                    .filter(el => el.sottodominio === 'dichiarazione_di_interesse' && el.dominio === 'richieste_repertorio')
                    .tipo_media]
                  }
                  ref_id={component.props.repertorio.id}
                  onUpload={() => component.props.refresh()}
                  onDelete={() => component.props.refresh()}
                />
              }
            ],
          },
        ],
      },
      /* {
         cols: [
           {
             offset: 22,
             span: 2,
             items: [
               {
                 type: 'submit',
                 name: 'btn_submit',
                 text: 'Salva',
                 props: {
                   icon: 'save',
                   className: 'bg-green',
                 }
               }
             ],
           },
         ],
       },*/
    ],
    //onSubmit: 'onSubmit',
  }
};

export default class AllegatiForm extends Component {

  state = {
    data: null,
    loading: false,
  };

  componentDidMount() {
    this.setState({ data: this.props.repertorio });
  }

  saveBlob(blob, filename) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, filename + ".odt");

    //this.props.refresh();
  }

  daIntegrare(tipo_media) {
    if (this.props.showIntegrazione && this.props.integrazioni_richieste && this.props.integrazioni_richieste.length > 0) {
      let request_media = this.props.integrazioni_richieste[0].requested_media.find(el => el.media.sottodominio === tipo_media);
      if (request_media)
        return "RICHIESTE INTEGRAZIONI: " + request_media.note
    }
  }

  downloadDichiarazione() {
    if (!this.state.loading) {
      this.setState({ loading: true }, () => {
        ModelServices.downloadDichiarazione(
          this.props.match.params.id_repertorio,
          (res) => {
            this.saveBlob(res.data, 'DichiarazioneInteressi');
            this.setState({ loading: false })

          },
          () => this.setState({ loading: false })
        )
      })
    }
  }

  downloadRelazione() {
    if (!this.state.loading) {
      this.setState({ loading: true }, () => {
        ModelServices.downloadRelazione(
          this.props.match.params.id_repertorio,
          (res) => {
            this.saveBlob(res.data, 'RelazioneDescrittiva');
            this.setState({ loading: false })

          },
          () => this.setState({ loading: false })
        )
      })
    }
  }


  onSubmit(data) {
    if (!this.state.loading) {
      this.setState({ loading: true }, () => {
        /*
        let save = this.state.data.id ? ModelService.update : ModelService.create
        save(data, this.state.data.id, res => {
          message.success("Formulario salvato correttamente");
          this.setState({ data: res.data.data, loading: false });
          eventBroker.trigger('loadProcedura');
          }, () => this.setState({loading: false }) );
          */
      });
    }
  }

  getValues() {
    return this.state.data.json_richiesta;
  }

  render() {
    return (!this.state.data ? <SpinnerLoading message='Caricamento dati...' /> :
      <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>

        {checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
          <ValidazioneScheda
            canGiveFeedback={checkPermission.hasPermission(this.props.UserStore.user, ['addAudit']) && this.props.repertorio.stato_flusso === repertorioStatic.stato_flusso.investigation}
            loadingFeedback={this.props.loadingFeedback}
            saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'attachments')}
            feedback={this.props.section_feedback ? this.props.section_feedback['attachments'] : null}
          />
        }

        <CommonForm
          loading={this.state.loading || false}
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          form_model={formLayout(this)}
          values={this.getValues()}
          readOnly={this.props.readOnly}
          onSubmit={this.onSubmit.bind(this)}
          downloadDichiarazione={() => this.downloadDichiarazione()}
          downloadRelazione={() => this.downloadRelazione()}
        />
      </div>
    )
  }
}
