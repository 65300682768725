import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';
import ElencoREgionaleService from '../services/ElencoRegionale';
import { message } from 'antd';
import formatter from '@common/utils/formatters/_';


const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'integer',
                                label: 'ID Ente',
                                name: 'id_ente',
                                props: {
                                    min: 1,
                                    disabled: component.props.edit || component.props.element_opened
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]

                            }
                        ],
                    },
                    {
                        span: 16,
                        items: [
                            {
                                type: 'input',
                                label: 'Denominazione ente',
                                name: 'denominazione',
                                props: {
                                    disabled: component.props.element_opened || component.props.edit
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 6,
                        items: [
                            {
                                type: 'select',
                                label: 'Provincia sede legale',
                                name: 'provincia_sede_legale',
                                options_props: 'province',
                                onChange: 'selectedLegaleProvincia',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ],
                                props: {
                                    disabled: component.props.element_opened || component.props.edit,
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'select',
                                label: 'Comune sede legale',
                                name: 'comune_sede_legale',
                                options_props: 'comuni_legale',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ],
                                props: {
                                    disabled: component.props.element_opened || component.props.edit,
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'input',
                                label: 'Via sede legale',
                                name: 'via_sede_legale',
                                props: {
                                    disabled: component.props.element_opened || component.props.edit,
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'input',
                                label: 'Civico sede legale',
                                name: 'civico_sede_legale',
                                props: {
                                    disabled: component.props.element_opened || component.props.edit,
                                },
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 6,
                        items: [
                            {
                                type: 'select',
                                label: 'Provincia sede formativa',
                                name: 'provincia_sede_formativa',
                                options_props: 'province',
                                onChange: 'selectedFormativaProvincia',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ],
                                props: {
                                    disabled: component.props.edit,
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'select',
                                label: 'Comune sede formativa',
                                name: 'comune_sede_formativa',
                                options_props: 'comuni_formativa',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ],
                                props: {
                                    disabled: component.props.edit,
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'input',
                                label: 'Via sede formativa',
                                name: 'via_sede_formativa',
                                props: {
                                    disabled: component.props.edit,
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'input',
                                label: 'Civico sede formativa',
                                name: 'civico_sede_formativa',
                                props: {
                                    disabled: component.props.edit,
                                },

                            }
                        ],
                    },

                ],
            },
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'integer',
                                label: 'N° decreto',
                                name: 'numero_decreto',
                                props: {
                                    min: 1,
                                    disabled: component.props.edit,
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'datepicker',
                                format: 'DD/MM/YYYY',
                                label: 'Data',
                                name: 'data_decreto',
                                props: {
                                    style: { width: '100%' },
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Tipologia',
                                name: 'tipologia',
                                options: [
                                    {
                                        value: 1,
                                        label: "Provvisorio"
                                    },
                                    {
                                        value: 2,
                                        label: "Definitivo"
                                    },
                                ],
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],

                    },
                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Note',
                                name: 'note',
                            }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                type: 'submit',
                                name: 'btn_submit_ente_accreditato',
                                text: 'Salva i dati',
                                props: {
                                    icon: 'save',
                                    className: 'bg-green'
                                }
                            }
                        ],
                    },
                ],
            },
        ],
        onSubmit: 'submit',
    }
};

export class InsertEnteAccreditato extends Component {

    state = {
        ente: {},
        comuni_legale: [],
        comuni_formativa: [],
        enteIsPresent: false,
    }

    componentDidMount() {
        if (this.props.edit && this.props.id_elenco_regionale) {
            this.setState({ loading: true })
            ElencoREgionaleService.view(this.props.id_elenco_regionale, (res) => {
                ComuniService.all({ id_provincia: res.data.data.provincia_sede_legale }, res => {
                    this.setState({ comuni_legale: res.data.data || [] })
                }
                );
                ComuniService.all({ id_provincia: res.data.data.provincia_sede_formativa }, res => {
                    this.setState({ comuni_formativa: res.data.data || [] })
                }
                );
                this.setState({ ente: res.data.data, loading: false });
            }, (err) => this.setState({ loading: false }))

        }

        if (this.props.element_opened) {
            this.setState({ ente: this.props.element_opened })
        }
    }

    submit(data) {
        if (!this.state.loading) {

            this.setState({ loading: true });
            data = this.getFormData(data)
            if (this.props.element_opened) {
                ElencoREgionaleService.addDecreto({ ...data, comune_sede_legale: this.props.element_opened.id_comune_sede_legale, provincia_sede_legale: this.props.element_opened.id_provincia_sede_legale }, (res) => {
                    message.success('Elenco regionale aggiornato con successo');
                    this.setState({ loading: false });
                    if (this.props.onUpdate) this.props.onUpdate();
                }, (err) => this.setState({ loading: false }))
            } else if (this.props.edit) {
                ElencoREgionaleService.edit(this.props.id_elenco_regionale, data, (res) => {
                    message.success('Elenco regionale aggiornato con successo');
                    this.setState({ loading: false });
                    if (this.props.onUpdate) this.props.onUpdate();
                }, (err) => this.setState({ loading: false }))
            } else {
                ElencoREgionaleService.addElencoRegionale(data, (res) => {
                    message.success('Elenco regionale aggiornato con successo');
                    this.setState({ loading: false });
                    if (this.props.onUpdate) this.props.onUpdate();
                }, (err) => this.setState({ loading: false }))
            }

        }
    }

    getValues() {
        return formatter.replaceFormDatesIn(['data_decreto'], {
            ...this.state.ente,
        })
    }

    getFormData(data) {
        return formatter.replaceFormDatesOut(['data_decreto'], data);
    }

    render() {
        return (
            <div style={{ padding: 14, opacity: this.state.loading ? 0.5 : 1 }}>
                <CommonForm
                    loading={this.state.loading}
                    wrappedComponentRef={inst => (this.formSelRef = inst)}
                    form_model={formLayout(this)}
                    values={this.getValues()}
                    submit={(data) => this.submit(data)}
                    province={this.props.GlobalStore.stubs.provincia}
                    selectedLegaleProvincia={data => {
                        this.setState({
                            selected_legale_provincia: data,
                            comuni_legale: []
                        }, () => {
                            this.formSelRef.props.form.setFieldsValue({ comune_sede_legale: null })
                            ComuniService.all(
                                { id_provincia: data },
                                res => {
                                    this.setState({
                                        comuni_legale: res.data.data || []
                                    }, () => {
                                        this.formSelRef.props.form.setFieldsValue({ comune_sede_legale: null });
                                    });
                                },
                                () => null
                            );
                        });
                    }}

                    comuni_legale={this.state.comuni_legale.map(e => {
                        return {
                            key: 'legale_comune_' + e.id,
                            value: e.id,
                            label: e.comune
                        }
                    })}

                    selectedFormativaProvincia={data => {
                        this.setState({
                            selected_formativa_provincia: data,
                            comuni_formativa: []
                        }, () => {
                            this.formSelRef.props.form.setFieldsValue({ comune_sede_formativa: null })
                            ComuniService.all(
                                { id_provincia: data },
                                res => {
                                    this.setState({
                                        comuni_formativa: res.data.data || []
                                    }, () => {
                                        this.formSelRef.props.form.setFieldsValue({ comune_sede_formativa: null });
                                    });
                                },
                                () => null
                            );
                        });
                    }}

                    comuni_formativa={this.state.comuni_formativa.map(e => {
                        return {
                            key: 'legale_comune_' + e.id,
                            value: e.id,
                            label: e.comune
                        }
                    })}
                />
            </div>
        )
    }
}

export default InsertEnteAccreditato