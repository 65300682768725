import React, { Component } from 'react';

import InfrastrutturaServices from '@accreditamento/services/Infrastruttura';
import RintracciabilitaForm from '@accreditamento/forms/completeRequest/infrastruttura/Rintracciabilita';

class Rintracciabilita extends Component {

  state = {
    loading: false,
    values: {}
  };

  getJsonValues(props) {
    try{
      return JSON.parse(props.rintracciabilita) || {};
    } catch(e) {
      console.error('errore parse json', e);
      return {}
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true
    })
  }

  async save(data) {

    if(!this.state.loading) {
      await this.setState({loading: true});
      InfrastrutturaServices.updateSede(
        this.props.accreditamento.id,
        this.props.sede.id_ente_sede,
        {rintracciabilita: JSON.stringify(data)},
        () => {
          if(this.props.onUpdate) this.props.onUpdate()
          this.setState({loading: false});
          if(this.props.close) this.props.close()
        }, () => this.setState({loading: false})
      )
    }
  }

  render() {
    return (
      <div>
        {this.state.loaded ?
        <RintracciabilitaForm
          values={this.getJsonValues(this.props.sede)}
          onSubmit={(data)=>this.save(data)}
          readOnly={this.props.readOnly}
          loading={this.state.loading}
        /> : null}
      </div>
    );
  }
}
export default Rintracciabilita;