import BaseRequest from "@common/services/_baseRequest";



export default {

	download: (id, id_comunicazione, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/comunicazione-ostativa/'+id+'/download/'+id_comunicazione,
			cb,
			ecb || null,
			null
		);
	},

	upload: (id, id_comunicazione, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/comunicazione-ostativa/'+id+'/upload/'+id_comunicazione,
			cb,
			ecb || null,
			null
		);
	},

	confirm: (id, id_comunicazione, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/comunicazione-ostativa/'+id+'/confirm/'+id_comunicazione,
			cb,
			ecb || null,
			null
		);
	},

	edit_controdeduzioni: (id, id_comunicazione, id_controdeduzioni, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"put",
			'accreditamento/comunicazione-ostativa/'+id+'/'+id_comunicazione+'/controdeduzioni/'+id_controdeduzioni,
			cb,
			ecb || null,
			null
		);
	},

	download_controdeduzioni: (id, id_comunicazione, id_controdeduzioni, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/comunicazione-ostativa/'+id+'/'+id_comunicazione+'/controdeduzioni/'+id_controdeduzioni,
			cb,
			ecb || null,
			null
		);
	},

	upload_controdeduzioni: (id, id_comunicazione, id_controdeduzioni, data = {}, file = null, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/comunicazione-ostativa/'+id+'/'+id_comunicazione+'/controdeduzioni/'+id_controdeduzioni,
			cb,
			ecb || null,
			null
		);
	},

	confirm_controdeduzioni: (id, id_comunicazione, id_controdeduzioni, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/comunicazione-ostativa/'+id+'/'+id_comunicazione+'/controdeduzioni/'+id_controdeduzioni+'/send',
			cb,
			ecb || null,
			null
		);
	},

}
