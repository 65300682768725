import BaseRequest from '@common/services/_baseRequest';

export default {

  getDomande: (id, vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/progetto/' + id + '/domande',
      cb,
      ecb
    );
  },

  list: (vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/progetto',
      cb,
      ecb
    );
  },

  detail: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/progetto/' + id,
      cb,
      ecb
    );
  },

  create: (data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/progetto',
      cb,
      ecb
    );
  },

  newEdition: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      'affidamento/progetto/' + id_progetto + '/new-edition',
      cb,
      ecb
    );
  },

  update: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "put",
      'affidamento/progetto/' + id,
      cb,
      ecb
    );
  },

  delete: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'affidamento/progetto/' + id_progetto,
      cb,
      ecb
    );
  },

  save_json: (scheda_progetto_json = {}, id_progetto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { scheda_progetto_json: scheda_progetto_json },
      null,
      'post',
      'affidamento/progetto/' + id_progetto + '/save-json',
      cb,
      ecb
    );
  },

  progress: (params = {}, id_progetto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/progetto/' + id_progetto + '/progress',
      cb,
      ecb
    );
  },

  info_and_validate: (scheda_progetto_json = {}, id_progetto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { scheda_progetto_json: scheda_progetto_json },
      null,
      'post',
      'affidamento/progetto/' + id_progetto + '/info-and-validate',
      cb,
      ecb
    );
  },

  printPdf: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/progetto/' + id_progetto + '/print-pdf',
      cb,
      ecb
    );
  },

  upload: (id, data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/progetto/' + id + '/upload',
      cb,
      ecb
    );
  },

  requestOpenRegistration: (project_id, data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/progetto/' + project_id + '/request-open-registration',
      cb,
      ecb
    );
  },

  approveOpenRegistration: (project_id, data = { id_user: null }, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/progetto/' + project_id + '/approve-open-registration',
      cb,
      ecb
    );
  },

  rejectOpenRegistration: (project_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/progetto/' + project_id + '/reject-open-registration',
      cb,
      ecb
    );
  },

  setOpenRegistrationDate: (project_id, data, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/progetto/' + project_id + '/set-open-registration-date',
      cb,
      ecb
    );
  },

  confirm: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/progetto/' + id + '/confirm',
      cb,
      ecb
    );
  },

  send: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/progetto/' + id + '/send',
      cb,
      ecb
    );
  },

  downloadAutorizzazione: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/progetto/' + id_progetto + '/download-autorizzazione',
      cb,
      ecb
    );
  },

  rollback: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/progetto/' + id + '/rollback',
      cb,
      ecb
    );
  },

  assign: (id, data = { id_user: null }, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/progetto/' + id + '/assign',
      cb,
      ecb
    );
  },

  feedbackSection: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/progetto/' + id_progetto + '/feedback-section',
      cb,
      ecb
    );
  },

  storicoDiff: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      'affidamento/progetto/' + id_progetto + '/storico-diff',
      cb,
      ecb
    );
  },

  editionDiff: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      'affidamento/progetto/' + id_progetto + '/edition-diff',
      cb,
      ecb
    );
  },

  formularioDiff: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      'affidamento/progetto/' + id_progetto + '/formulario-diff',
      cb,
      ecb
    );
  },

  prepareCourse: (id, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/progetto/' + id + '/prepare-course',
      cb,
      ecb
    );
  },

  loadRegistrationData: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'post',
      'affidamento/progetto/' + id + '/iscrizione',
      cb,
      ecb
    );
  },

  getCopertura: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'affidamento/progetto/' + id + '/copertura',
      cb,
      ecb
    );
  }
}
