import BaseRequest from "@common/services/_baseRequest";


export default {

	list: ( id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	},

	edit: (data, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	},

	addOrganigramma: (data, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/organigramma',
			cb,
			ecb || null,
			null
		);
	},

	updateOrganigramma: (data, id, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"put",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/organigramma/' + id,
			cb,
			ecb || null,
			null
		);
	},

	deleteOrganigramma: (id, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/organigramma/' + id,
			cb,
			ecb || null,
			null
		);
	},

	addDocente: (data, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/docente',
			cb,
			ecb || null,
			null
		);
	},

	updateDocente: (data, id, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"put",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/docente/' + id,
			cb,
			ecb || null,
			null
		);
	},

	deleteDocente: (id, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/docente/' + id,
			cb,
			ecb || null,
			null
		);
	},

	addUtenzaSpeciale: (data, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/utenza-speciale',
			cb,
			ecb || null,
			null
		);
	},

	updateUtenzaSpeciale: (data, id, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"put",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/utenza-speciale/' + id,
			cb,
			ecb || null,
			null
		);
	},

	deleteUtenzaSpeciale: (id, id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"delete",
			'accreditamento/scheda/capacita-gestionali/' + id_accreditamento + '/utenza-speciale/' + id,
			cb,
			ecb || null,
			null
		);
	}
}
