import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Card, Popconfirm, Modal } from 'antd';
import DocSpan from '@common/components/DocSpan';
import nominaStatic from '@common/utils/static/nomina';
import CommonForm from '@common/components/form';
import DownloadUpload from '@common/components/DownloadUpload';

const formSelLayout = {
    layout: 'vertical',
    multistep: false,
    rows: [
        {
            cols: [
                {
                    items: [
                        {
                            type: 'textarea',
                            label: 'Note',
                            name: 'note',
                            props: {

                            }
                        },
                    ],
                },
            ],
        }
    ],
    onSubmit: 'submitted',
};

class NominaProgettoActions extends Component {

    state = {

    }

    renderRequestDirigenteFileFirmato() {
        return <div style={{ marginTop: 18 }}>
            {
                this.props.nomina.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_richiesta_firmata_dirigente).map(l => {
                    return <div key={l.id}><p style={{ display: 'inline-block' }}><strong>Richiesta firmata dal dirigente:</strong> </p><DocSpan key={l.id} file={l} /></div>
                })
            }
        </div>
    }

    renderNominaFirmata() {
        return <div style={{ marginTop: 18 }}>
            {
                this.props.nomina.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_feedback_ispettore).map(l => {
                    return <div key={l.id}><p style={{ display: 'inline-block' }}><strong>Documento firmato dall&apos;ispettore:</strong> </p><DocSpan key={l.id} file={l} /></div>
                })
            }
        </div>
    }

    bottoneAccettazioneNomina() {
        return !this.props.nomina.media.some(
            m =>
                m.tipo.sottodominio === 'autocertificazione_ispettore' && m.deleted !== 1 && m.tipo.tipo_media.toUpperCase() === 'AUTOCERTIFICAZIONE ASSENZA CONFLITTO DI INTERESSI'
        )
            ? <p>Per accettare la nomina inserisci l&apos;autocertificazione di assenza conflitti di interesse</p>
            : <Popconfirm placement="top" title="Accetti la nomina?"
                onConfirm={() => {
                    this.setState({ feedback: 1, open_modal_feedback: true })
                }}
                okText="Si" cancelText="No">
                <Button
                    disabled={this.props.refusing}
                    loading={this.props.refusing}
                    style={{ marginRight: 8 }} className="m5h" type="primary" icon="check">Accetta la nomina</Button>
            </Popconfirm>
    }
    /**
 * Azioni dell'ispettore se è chi gestisce l'audit
 * @return {[type]} [description]
 */
    renderActions() {
        return <div>
            {
                (this.props.nomina.stato === nominaStatic.stato.attesa_conferma) &&
                <div>
                    <Modal
                        title="Inserisci note facoltative"
                        visible={this.state.open_modal_feedback}
                        confirmLoading={this.props.loading}
                        onOk={async () => {
                            this.props.feedback(this.state.feedback, this.formFeedbackIsp.props.form.getFieldsValue()['note'])
                            this.setState({ open_modal_feedback: false })
                        }}
                        onCancel={() => this.setState({ open_modal_feedback: false })}
                    >
                        <CommonForm
                            wrappedComponentRef={inst => (this.formFeedbackIsp = inst)}
                            loading={this.state.loading || false}
                            form_model={formSelLayout}
                            values={{

                            }}
                        />
                    </Modal>
                    {this.bottoneAccettazioneNomina()}
                    <Popconfirm placement="top" title="Rifiuti la nomina?"
                        onConfirm={() => {
                            this.setState({ feedback: 0, open_modal_feedback: true })
                        }}
                        okText="Si" cancelText="No">
                        <Button
                            disabled={this.props.refusing}
                            loading={this.props.refusing}
                            className="m5h" type="danger" ghost icon="close">Rifiuta la nomina</Button>
                    </Popconfirm>
                </div>
            }
            {
                (this.props.nomina.stato === nominaStatic.stato.accettata ||
                this.props.nomina.stato === nominaStatic.stato.rifiutata)
                &&<div>
                    <p>Scarica il documento della nomina e carica il file firmato digitalmente</p>
                    <DownloadUpload
                        accept={'.p7m,.pdf'}
                        downloading={this.props.downloading_nomina}
                        uploading={this.props.uploading_nomina}
                        download={()=>this.props.downloadNomina()}
                        upload={(file) => this.props.uploadNomina(file)}
                    >
                    </DownloadUpload>
                </div>
            }
        </div>
    }

    render() {
        return <div>
            <Card
                className="shadow no-pad"
                bordered={false}
            >
                <div style={{ marginTop: 16, marginBottom: 8 }}>{this.renderRequestDirigenteFileFirmato()}</div>
                <div style={{ marginTop: 16, marginBottom: 8 }}>{this.renderNominaFirmata()}</div>
                {this.props.nomina.note_ispettore && this.props.nomina.note_ispettore !== '' && <span><strong>Note:</strong> {this.props.nomina.note_ispettore}</span>}
                {this.props.canEditOwnNomina && this.renderActions()}
            </Card>
        </div>

    }


}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(NominaProgettoActions);
