import React, { Component } from 'react';
import { message, Spin } from 'antd';

import AffidabilitaServices from '../../services/Affidabilita';

import accreditamentoStatic from '@common/utils/static/accreditamento';

import AffidabilitaForm from '../../forms/completeRequest/affidabilita/Form';
import ValidationScheda from '../audit/ValidationScheda';

class Affidabilita extends Component {
  state = {
    loading: false,
    files: {},
    accreditamento: {
      accreditamentotipologie: []
    },
    continuita: {},
    certificazione: {},
    ente: {
      media: []
    }
  };

  constructor(props) {
    super(props);

    this.continuita = null;
    this.certificazione = null;
  }

  componentDidMount() {
    if(this.props.current_key === "3") this.loadData();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.current_key !== this.props.current_key && newProps.current_key === "3") this.loadData()
  }

  loadData() {

    this.setState({loading: true},()=>{

      AffidabilitaServices.list( this.props.accreditamento.id, (res) => {

        this.setState({
          accreditamento: res.data.data.accreditamento,
          affidabilita: res.data.data.affidabilita,
          ente: res.data.data.ente,
          loading: false
        })
      }, () => this.setState({
        loading: false
      }))
    })

  }





  isDefinitivoOrRinnovo() {

    return (
      this.state.accreditamento.tipo === accreditamentoStatic.tipo.definitivo ||
      this.state.accreditamento.tipo === accreditamentoStatic.tipo.rinnovo
      ) ? true : false
  }

  async submitForm(data) {

    if(!this.state.loading) {
      await this.setState({loading: true});

      AffidabilitaServices.saveData( this.state.accreditamento.id, data, (res) => {
        this.setState({
          accreditamento: res.data.data.accreditamento,
          affidabilita: res.data.data.affidabilita,
          ente: res.data.data.ente,
          loading: false
        });
        message.success('Dati salvati');
        if(this.props.reloadProgress) this.props.reloadProgress();
      }, () => this.setState({loading: false}))

    }

  }

  render() {
    return (
      <div style={{opacity: this.state.loading ? 0.6 : 1}}>
        {
          this.state.loading ? <Spin /> : null
        }
        <ValidationScheda
          section="affidabilita"
          refreshInline={this.props.refreshInline}
          accreditamento={this.props.accreditamento}
          progress={this.props.progress}
        />
        <AffidabilitaForm
            loading={this.state.loading}
            ref={ (ref) => this.certificazione = ref }
            values={this.state.affidabilita}
            accreditamento={this.state.accreditamento}
            ente={this.state.ente}
            readOnly={this.props.readOnly}
            GlobalStore={this.props.GlobalStore}
            onSubmit={(data) => this.submitForm(data)}
          />
      </div>
    );
  }
}

export default Affidabilita;
