import BaseRequest from "@common/services/_baseRequest";


export default {

	list: ( id_accreditamento, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'accreditamento/scheda/affidabilita/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	},

	saveData: ( id_accreditamento, data, cb, ecb = null ) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/scheda/affidabilita/' + id_accreditamento,
			cb,
			ecb || null,
			null
		);
	}

}
