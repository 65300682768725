/*
const STATO_IN_CORSO = 0; // ente modifica i documenti
const STATO_DA_FIRMARE = 1; // conferma le modifiche
const STATO_FIRMATA = 2; // carica file con firma
const STATO_FIRMA_CONFERMATA = 3; // carica file con firma

const STATO_IN_CORSO = 0; // ente modifica i documenti
const STATO_DA_FIRMARE = 1; // conferma le modifiche
const STATO_FIRMATA = 2; // carica file con firma
const STATO_INVIATA = 3; // inviata all'amministrazione
*/
export default {
  stato: {
    in_corso: 0,
    da_firmare: 1,
    firmata: 2,
    confermata: 3
  },
  stato_controdeduzioni: {
    in_corso: 0,
    da_firmare: 1,
    firmata: 2,
    confermata: 3
  },
  tipo_media: {
    sottodominio_richiesta_firmata: 'comunicazione_ostativa_istruttore',
    sottodominio_controdeduzioni_firmata: 'comunicazione_ostativa_controdeduzioni'
  },

  tipo_media_controdeduzioni: {
    sottodominio_verbale_controdeduzioni: 'verbale_controdeduzioni'
  },

  getStatoLabel: (comunicazione) => {
    let label = 'In corso';
    switch (comunicazione.stato) {
      case 0: break;
      case 1: label = 'Da firmare'; break;
      case 2: label = 'Firmata'; break;
      case 3: label = 'Confermata'; break;
      default: break;
    }
    return label;
  },
  getStatoControdeduzioneLabel: (controdeduzione) => {
    let _label = 'In corso';
    switch (controdeduzione.stato) {
      case 0: break;
      case 1: _label = 'Da firmare'; break;
      case 2: _label = 'Firmata'; break;
      case 3: _label = 'Confermata'; break;
      case 4: _label = 'Scaduta'; break;
      default: break;
    }
    return _label;
  }
}