import React from 'react';
import { connect } from "react-redux";

import { Layout } from 'antd';

import SidebarLayout from '../layout/Sidebar';
import HeaderLayout from '../layout/Header';
import FooterLayout from '../layout/Footer';

// importo container, topbar e sidebar sidebar
const { Content } = Layout;

// in questo modo wrappo le route e formatto in base al fatto che l'utente sia loggato o meno
export default (args) => (WrappedComponent) => {
   return connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore })) (class extends React.Component {

         verifyRoute(newProps = null) {

            let pr = newProps || this.props;

            let logged = pr.UserStore.loggedIn && localStorage.getItem('token') ? true : false;

            // se non loggato su route privata vai al login
            if (
                  !logged
                  && this.getPublicRoutes().indexOf(args.route) === -1
                  && this.getEveryTimePublicRoutes().indexOf(args.route) === -1
                  && !args.public
               )
               return pr.history.push('/login')

            // se loggato con password temporanea vai a cambiarla
            if (logged && pr.UserStore.user.is_temp_password === 1 && args.route !== 'ChangeTempPassword')
               return pr.history.push('/cambia-password-temporanea');


            // se loggato in route pubblica o in cambio password temporanea vai alla dashboard
            if (logged &&
               (
                  this.getPublicRoutes().indexOf(args.route) !== -1 ||
                  (pr.UserStore.user.is_temp_password === 0 && args.route === 'ChangeTempPassword')
               )
            )
               return pr.history.push('/dashboard')


         }

         setLayout() {
            if (!args.hasSidebar) {
               this.props.dispatch({
                  type: 'NO_SIDEBAR'
               })
            }
         }

         componentDidMount() {
            this.verifyRoute();
            //this.setLayout();
         }

         UNSAFE_componentWillReceiveProps(newProps) {
            if (newProps.UserStore.loggedIn !== this.props.UserStore.loggedIn) this.verifyRoute(newProps);
         }

         verifyPermissions() {

            if (this.getPublicRoutes().indexOf(this.props.route) === -1) {
               // verifica permessi
               if (args.requiredPermission && this.props.UserStore.user.permissions.indexOf(args.requiredPermission) === -1) {
                  return false
               }
            }
            //console.log('ritorno ok route ' + args.route, _logged)
            return true

            /*
            if (this.getPublicRoutes().indexOf(this.props.route) === -1) {
               // verifica permessi
               if (args.requiredPermission && this.props.UserStore.user.permissions.indexOf(args.requiredPermission) === -1) {
                  return false
               }
            }

            return true*/
         }

         getPublicRoutes() {
            return [
               'Login', 'AskReset', 'Reset', 'Base'
            ]
         }

         getEveryTimePublicRoutes() {
            return [
               'Privacy', 'NoteLegali', 'Contatti', 'DocumentiUtili'
            ]
         }

         openRightDrawer() {
            this.props.dispatch({
               type: (this.props.GlobalStore.open_right_drawer) ? 'CLOSE_RIGHT_DRAWER' : 'OPEN_RIGHT_DRAWER'
            })
         }

         render() {
            return this.verifyPermissions() &&
               <Layout className="main-layout" style={{ minHeight: "100vh"}}>
                  {
                     (args.hasHeader) ?
                        <HeaderLayout {...this.props} /> : null
                  }
                  <Layout>
                     {
                        (args.hasSidebar) ? <SidebarLayout {...this.props} /> : null
                     }
                     <Content aria-label='Contenuto principale di Silf'  className="mainContent">
                        {/* {
                           (!args.hideBtnRightDrawer) &&
                           <Button className="btnDrawer" type="primary" onClick={() => this.openRightDrawer()}>
                              {this.props.GlobalStore.open_right_drawer ? <Icon type="arrow-right" /> : <Icon type="arrow-left" />}
                           </Button>
                        } */}
                        <WrappedComponent {...this.props} />
                     </Content>
                  </Layout>
                  {
                     (args.hasFooter) ?
                        <FooterLayout {...this.props} /> : null
                  }
               </Layout>
         }

      })
};

