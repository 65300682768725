import React, { Component } from 'react';

import { Icon } from 'antd';
import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Via',
                type_string: 'text',
                name: 'via',
                props: {
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'N',
                type_string: 'text',
                name: 'ncivico',
                props: {
                  placeholder: 'N.',
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Nazione',
                name: 'stato',
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'nazioni',
                onChange: 'selectedStato',
              },
            ],
          },


        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text',
                name: 'cap',
                props: {
                  placeholder: 'Cap',
                  maxLength: 10,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9a-zA-Z]{4,10}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '!=',
                    value: 1,
                  },
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '!=',
                    value: null,
                  },
                ],
              },
            ],
          },
        ]
      },
      {
        cols: [


          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                name: 'provincia',
                props: {
                  allowClear: true,
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  },

                ],
                options_props: 'province',
                onChange: 'selectedProvincia',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Comune',
                name: 'comune',
                props: {
                  allowClear: true,
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },

                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  },
                  {
                    type: 'field_validation',
                    field: 'provincia',
                    operator: '!=',
                    value: null,
                  },
                ],
                options_props: 'comuni',
                onChange: 'selectedComune',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text',
                name: 'cap',
                props: {
                  placeholder: 'Cap',
                  maxLength: 5,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9]{5,5}',
                    message: 'Cap non valido',
                  },
                  {
                    validator: 'valid_cap'
                  }

                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  },
                  {
                    type: 'field_validation',
                    field: 'provincia',
                    operator: '!=',
                    value: null,
                  },
                  {
                    type: 'field_validation',
                    field: 'comune',
                    operator: '!=',
                    value: null,
                  },
                ],
              },
            ],
          },
        ],
      },

    ],
    onSubmit: 'submitted',
  }
}

export default class DatiIndirizzo extends Component {
  state = {
    loading: false,
    loaded: false,
    comuni: [],
  };

  async componentDidMount() {
    this.loadComuni(this.props)
  }

  loadComuni(props) {
    if (props.dati && props.dati.provincia) {
      this.selectedProvincia(props.dati.provincia)
    } else {
      this.setState({ loading: false });
    }
  }

  selectedProvincia(provincia) {
    this.setState({ loading: true });
    ComuniService.all({ id_provincia: provincia },
      res => {
        this.setState({
          loading: false,
          comuni: res.data.data || [],
          selected_provincia: provincia
        });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  checkCap(value, cb) {
    if (!value || value.length < 5) {
      cb();
    } else {
      let comune_cap = this.state.comuni.find(el => el.id === this.formRef.props.form.getFieldsValue()['comune']).cap
      if (comune_cap.substring(3, 5) === 'xx') {
        if (comune_cap.substring(0, 3) !== value.substring(0, 3)) cb("Cap non valido")
        else cb();
      } else {
        if (comune_cap !== value) cb("Cap non valido")
        else cb();
      }
    }
  }

  render() {

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={this.props.dati}
          subform={this.props.subform}

          nazioni={this.props.GlobalStore.stubs.nazione}
          province={this.props.GlobalStore.stubs.provincia}

          readOnly={this.props.readOnly}

          selectedStato={() => {
            this.setState({
              comuni: [],
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune: null, provincia: null, cap: null });
            });
          }}

          selectedProvincia={data => {
            this.setState({
              selected_provincia: data,
              comuni: [],
              loading: true
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune: null, cap: null });
              this.selectedProvincia(data);
            });
          }}

          comuni={this.state.comuni.map(e => {
            return {
              key: 'residenza_comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}

          selectedComuni={data => {
            this.formRef.props.form.setFieldsValue({ cap: null })
          }
          }

          valid_cap={(rule, value, cb) => {
            this.checkCap(value, cb)
          }}
        />
      </div>
    );
  }
}