import React, { Component } from 'react';

import { message } from 'antd';
import CommonForm from '@common/components/form';


const formLayout = (component) => {
  return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Nome',
                                name: 'nome_aula',
                                input_type: 'string',
                                props: {},
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ]
                            },
                        ],
                    },
                ],
            },/*
            {
                cols: component.props.accreditamento.N_anpal == null ? [
                  {
                    span: 24,
                    items: [
                      {
                        type: "select",
                        label: "Tipo struttura",
                        name: "tipo_struttura",
                        onChange: "setTipoStruttura",
                        props: {
                          disabled: component.props.readOnly,
                          // size: 'large',
                          showSearch: true,
                          filterOption: (input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0,
                        },
                        validations: [
                          {
                            type: "required",
                            error_message: "Campo obbligatorio",
                          },
                        ],
                        options_props: "tipo_struttura",
                      },
                    ],
                  },
                ] : [
                  {
                    span: 24,
                    items: [
                      {
                        type: "select",
                        label: "Tipo struttura",
                        name: "tipo_struttura",
                        onChange: "setTipoStruttura",
                        props: {
                          disabled: component.props.readOnly,
                          // size: 'large',
                          showSearch: true,
                          filterOption: (input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0,
                        },
                        validations: [
                          {
                            type: "required",
                            error_message: "Campo obbligatorio",
                          },
                        ],
                        options_props: "tipo_struttura_filtered",
                      },
                    ],
                  },
                ],
              },*/
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'select',
                                label: 'Tipologia',
                                name: 'id_tab_tipo_aula',
                                onChange: 'setTipoAula',
                                props: {
                                    // size: 'large',
                                    showSearch: true,
                                    filterOption: (input, option) => 
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                options_props: 'tipo_aula'
                            },
                        ],
                    },
                ],
            },
            {
                cols: [
                  {
                    span: 24,
                    items: [
                      {
                        type: "select",
                        label: "Tipo Laboratorio",
                        name: "id_tipo_laboratorio",
                        onChange: "setTipoLaboratorio",
                        props: {
                          disabled: component.props.readOnly,
                          // size: 'large',
                          showSearch: true,
                          filterOption: (input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0,
                        },
                        validations: [
                          {
                            type: "required",
                            error_message: "Campo obbligatorio",
                          },
                        ],
                        showIf: [
                          {
                            type: "field_validation",
                            field: "id_tab_tipo_aula",
                            operator: "==",
                            value: 8,
                          },
                        ],
                        options_props: "tipo_Laboratorio_rest",
                      },
                    ],
                  },
                ],
              },
            /*  JayBee_bugfix_#2977: rimozione tipo laboratorio
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'select',
                                label: 'Tipo laboratorio',
                                name: 'id_tab_tipo_laboratorio',
                                props: {
                                    // size: 'large',
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                showIf: [
                                    {
                                      type: 'prop_func',
                                      func: 'isLaboratorio'
                                    }
                                ],
                                options_props: 'tipo_laboratorio'
                            },
                        ],
                    },
                ],
            },
            */
            {
                cols: [
                    component.state.tipo_struttura == 2
                      ? {
                          span: 12,
                          items: [
                            {
                              type: "decimal",
                              label: "Mq",
                              name: "metri_quadrati",
                              props: {
                                disabled: component.props.readOnly,
                                min: 0,
                              },
                              validations: [],
                            },
                          ],
                        }
                      : {
                          span: 12,
                          items: [
                            {
                              type: "decimal",
                              label: "Mq",
                              name: "metri_quadrati",
                              props: {
                                disabled: component.props.readOnly,
                                min: 0,
                              },
                              validations: [
                                {
                                  type: "required",
                                  error_message: "Campo obbligatorio",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          span: 12,
                          items: component.state.aula == 27 ? [
                            {
                              type: "integerwithzero",
                              label: "N. Pc",
                              name: "numero_pc",
                              min: 1,
                              props: {
                                disabled: component.props.readOnly,
                                // size: 'large',
                                // prefix: (
                                //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                                // ),
                              },
                              validations: [
                                {
                                  type: "required",
                                  error_message: "Campo obbligatorio",
                                },
                              ],
                            },
                          ] : [
                            {
                              type: "integerwithzero",
                              label: "N. Pc",
                              name: "numero_pc",
                              defaultValue: 0,
                              min: 0,
                              props: {
                                disabled: component.props.readOnly,
                                // size: 'large',
                                // prefix: (
                                //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                                // ),
                              },
                            },
                          ] ,
                        },
                        {
                          span: 12,
                          items: component.state.aula == 27 ? [
                            {
                              type: "integerwithzero",
                              label: "Di cui collegati ad Internet",
                              name: "numero_pc_collegati_internet",
                              min: 0,
                              props: {
                                disabled: component.props.readOnly,
                                // // size: 'large',
                                // prefix: (
                                //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                                // ),
                              },
                              validations: [
                                {
                                  type: "required",
                                  error_message: "Campo obbligatorio",
                                },
                              ],
                            },
                          ] : [
                            {
                              type: "integerwithzero",
                              label: "Di cui collegati ad Internet",
                              name: "numero_pc_collegati_internet",
                              defaultValue: 0,
                              min: 0,
                              props: {
                                disabled: component.props.readOnly,
                                // size: 'large',
                                // prefix: (
                                //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                                // ),
                              },
                            },
                          ] ,
                        },
                        {
                          span: 12,
                          items: [
                            {
                              type: "integer",
                              label: "N. Allievi",
                              name: "numero_allievi",
              
                              props: {
                                disabled: component.props.readOnly,
                                min: 0,
                                // size: 'large',
                                // prefix: (
                                //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                                // ),
                              },
                              validations: component.state.aula == 3 || component.state.aula == 8  ? [
                                {
                                  type: "required",
                                  error_message: "Campo obbligatorio",
                                },
                              ] : [],
                              showIf: [
                                {
                                  type: "field_validation",
                                  field: "id_tab_tipo_aula",
                                  operator: "==",
                                  value: "8,3",
                                },
                              ],
                            },
                          ],
                        },
                ],
            },
            {
                cols: [
                    {
                        items: [
                            {
                                type: 'submit',
                                name: 'btn_submit_aula',
                                text: 'Salva',
                                //onClick: 'showDrawer',
                                props: {
                                    icon: 'save',

                                    className: 'bg-green'
                                }
                            }
                        ],
                    },
                ],
            },
        ],
        onSubmit: 'onSubmit',
    }
}

class UpdateAula extends Component {
  state = {
    loading: false,
    loaded: false,
    tipo_struttura: null,
    is_laboratorio: false,
    tipo_Laboratorio_rest: null,
    aula: null
  };

  async componentDidMount() {
    //this.setState({tipo_struttura: this.props.values.tipo_struttura});
    this.setState({ tipo_struttura: this.props.accreditamento.accreditamentotipologie[0] && this.props.accreditamento.accreditamentotipologie[0].id_tipologia == 3 ? 2 : 1 });

    let t = this.props.GlobalStore.stubs.tipo_aula.find(e=>e.value === this.props.values.id_tab_tipo_aula);
    if(t && t.label.toLowerCase() === 'laboratorio' ){
        this.setState({is_laboratorio: true})
    }
  }



    changeClausoleRisolutorie(e, field) {
        let value = (e.target.value === "1") ? true : false;
        this.props.onChangeClausole(value)
    }

    changedTitoloUsoLocali(value) {
        if(value !== 3 && value !== 2) {
            this.changeClausoleRisolutorie({target: {value: "0"}},'')
                this.formRef.props.form.setFields(
                {
                  contratto_clausole_risolutorie: {
                    value: "0"
                }
            });
        }
    }

    submitFormData(data) {
      data = {
        ...data,
        tipo_struttura:  this.props.GlobalStore.stubs.tipo_aula.find((el) => parseInt(el.value) === parseInt(data.id_tab_tipo_aula)).id_tab_tipo_aula_struttura
      }
      let tipo_aula_obj =
        this.props.GlobalStore.stubs.tipo_aula
          .map((a) => a.value === data.id_tab_tipo_aula)
        if(parseFloat(data.metri_quadrati) < parseFloat(tipo_aula_obj.min_mq)) {
            message.error('Minimo ' + tipo_aula_obj.min_mq + ' mq');
            return;
        }

        if(parseInt(data.numero_pc) < parseInt(tipo_aula_obj.min_pc)) {
            message.error('Minimo ' + tipo_aula_obj.min_pc + ' pc');
            return;
        }

        if(parseInt(data.numero_pc_collegati_internet) < parseInt(tipo_aula_obj.min_pc_internet)) {
            message.error('Almeno ' + tipo_aula_obj.min_pc_internet + ' pc collegati a internet');
            return;
        }

        if (parseInt(data.numero_pc) > 0 && parseInt(data.numero_pc_collegati_internet) == 0) {
          message.error("Se sono presenti PC in aula almeno 1 deve essere connesso a internet");
          return;
        }

        if(parseInt(data.numero_pc) < parseInt(data.numero_pc_collegati_internet)) {
            message.error('Hai più pc collegati a internet di quanti presenti in totale?');
            return;
        }

        // https://cb.schema31.it/cb/issue/446289

        let numero_minimo_allievi = 10;
    
        if ( (data.id_tab_tipo_aula == 3 || data.id_tab_tipo_aula == 8) && parseInt(data.numero_allievi) < parseInt(numero_minimo_allievi)
        ) {
          message.error("Il numero di minimo di allievi è di " + numero_minimo_allievi);
          return;
        }
        
        // let capacitaMinima = (parseInt(data.numero_allievi) + 1) * 2;
        // https://cb.schema31.it/cb/issue/447043
        let capacitaMinima =  Math.round(parseInt(data.numero_allievi) * 1.96 * 100) / 100;

        if ( (data.id_tab_tipo_aula == 3 || data.id_tab_tipo_aula == 8) && (parseFloat(data.metri_quadrati) < parseFloat(capacitaMinima)) ) {
          let err_msg_struttura = data.id_tab_tipo_aula == 3 ? "L'aula" : "Il laboratorio";
          message.error(err_msg_struttura + " non ha la quadratura adeguata, la quadratura minima è: " + capacitaMinima + " mq");
          return;
        }
    
        /*
        else {
          if(data.tipo_struttura == 1 && data.metri_quadrati < parseInt(capacitaMinima)) {
            message.error('L\'aula non ha la quadratura adeguata, la quadratura minima è: ' + capacitaMinima + 'mq');
            return;
          }
        }
        */

        this.props.onSubmit(data)
    }

    /**
     * Crea la mappatura per vedere quali laboratori possono essere inseriti
     * @return {[type]} [description]
     */
    getAvaibleLaboratori(){
        try{
            let avaible_macrotipologie = this.props.GlobalStore.stubs
            .mappatura_attivita_formativa
            .macrotipologie.filter(
                el => this.props.sede.tipoAccreditamento.find(t=>t.id_tipologia === el.id)
                );
            //console.log('macro', avaible_macrotipologie)
            let settori = [];

            avaible_macrotipologie.forEach(el => {
                el.settori.filter(s =>
                    this.props.sede.settore.find(ss => ss.id_settore === s.id)
                ).forEach(ss => settori.push(ss))
            } );
            //console.log('settori', settori)
            let id_laboratori = [];
            settori.forEach(s => {
                s.laboratori.forEach(l => {
                    if(id_laboratori.indexOf(l.id) === -1) id_laboratori.push(l.id);
                })
            })
            //console.log('laboratori', id_laboratori)

            return this.props.GlobalStore.stubs.tipo_laboratorio.filter(t => id_laboratori.indexOf(t.value) !== -1 )
        } catch(e) {
            //console.log('errore',e);
            return [];
        }
    }

  render() {

    return (
      <div>
        <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.props.loading || false}
            form_model={formLayout(this)}
            values={
                this.props.values
            }
            readOnly={this.props.readOnly}
            onSubmit={(data)=>this.submitFormData(data)}

            setTipoStruttura={(value)=>this.setState({tipo_struttura: value})}
            setTipoLaboratorio={(value) => this.setState({ tipo_Laboratorio_rest: value })}
            setTipoAula={(value) => {
              this.setState({ aula: value });
              //this.setState({tipo_struttura: this.props.GlobalStore.stubs.tipo_aula.find((el) => parseInt(el.value) === parseInt(value)).id_tab_tipo_aula_struttura});
              let t = this.props.GlobalStore.stubs.tipo_aula.find(
                (e) => e.value === value
              );
              if (t && t.label.toLowerCase() === "laboratorio") {
                this.setState({ is_laboratorio: true });
              } else {
                this.formRef.props.form.setFields({
                  id_tab_tipo_laboratorio: {
                    value: null,
                  },
                });
                this.setState({ is_laboratorio: false });
              }
            }}
            tipo_aula={this.props.GlobalStore.stubs.tipo_aula.filter(m=> m.id_tab_tipo_aula_struttura == this.state.tipo_struttura)}
            tipo_struttura={this.props.GlobalStore.stubs.tipo_aula_struttura}
            tipo_struttura_filtered={this.props.GlobalStore.stubs.tipo_aula_struttura.filter(m => m.value == 2)}
            tipo_Laboratorio_rest={this.props.GlobalStore.stubs.tipo_laboratorio_rest}

            isLaboratorio={(value, form, field_name) => {
                return this.state.is_laboratorio;
            }}
        />
      </div>
    );
  }
}
export default UpdateAula;