import React, { Component } from 'react';
import Wrapper from '../decorators/Wrapper';


@Wrapper({ route: 'Contatti', hasSidebar: false, hasHeader: true, hasFooter: true })
class ContattiPageRoute extends Component {


  render() {

    return (
      <div>

        <h3>CONTATTI</h3>
        <p><strong>Dipartimento Istruzione, Formazione e Pari Opportunità </strong></p>
        <p><strong>Settore 4 Accreditamento e Servizi Ispettivi </strong></p>
        <p>
          <strong>Dirigente Di Settore: </strong> Menotti Lucchetta <strong>e-mail:</strong> m.lucchetta@regione.calabria.it<br />
          <strong>Funzionario responsabile: </strong> Laura Zumbo <strong>e-mail:</strong> l.zumbo@regione.calabria.it<br /><br />
        Viale Europa - Cittadella Regionale - Località Germaneto 88100 Catanzaro - Italia <br />
        Apertura al pubblico: lunedì e venerdì dalle 9:30 alle 13:00.<br />
        </p>
        <p>Tel.: +39 0961 8511 - P.E.C.: accreditamento.formazione@pec.regione.campania.it</p>
        <p><strong>Helpdesk</strong></p>
        <p><strong>Tel.:</strong> +39 346 317 0821 </p>
        <p><strong>e-mail:</strong> silf@regione.campania.it </p>
        <p>Il servizio è attivo dal lunedì al venerdì dalle 8:30 alle 13:30.</p>
        <p>Per segnalare la anomalie all'Help Desk bisogna utilizzare una casella di posta elettronica ordinaria (NON PEC)</p>
      </div>
    );
  }
}

export default ContattiPageRoute;