import React, { Component } from 'react';
import { message, Row, Col, Select } from 'antd';
import formatter from '@common/utils/formatters/_';
import mappatura from '@common/utils/formatters/mappatura_attivita_formativa';

const Option = Select.Option;

class Settori extends Component {
  state = {
    loading: false,
    loaded: false,
    id_settore: [],
    col_settori: {}
  };

  async componentDidMount() {
    this.setSettori(this.props.id_settore)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.id_settore !== this.props.id_settore) this.setSettori(newProps.id_settore)
  }

  setSettori(settori) {
    this.setState({id_settore: settori})
  }



  getSettoreColSelected() {
    return this.state.id_settore
  }

  handleChange(value, col) {
    /**
     * Gestiamo le colonne multiple
     * @type {[type]}
     */
    // prendo i settori di questa tipologia
    let col_settori = this.getSettori(col);
    // qui metto i settori delle altre tipologie
    let values_to_not_change = this.state.id_settore.filter(e => parseInt(e.id_tipo_accreditamento) !== parseInt(col) );

    let values = col_settori.filter(s => value.indexOf(""+s.value) !== -1 ).map(ss => {
      return {
        id_settore: ss.value.toString(),
        id_tipo_accreditamento: col
      }
    });
    //console.log('values', value, values, col_settori)

    let new_state = [...values_to_not_change, ...values];
    //console.log('new state', new_state)
    this.setState({ id_settore: new_state });
  }

  getFormData() {
    return this.formRef.props.form.getFieldsValue();
  }

  validate() {
    let valid = true;
    //Settore non più obbligatorio
    //this.props.tipi_accreditamento.forEach(col => {
    //  if(this.getSettoreColSelected().length <= 0) valid = false;
    //});
  
    if(!valid) message.error('Seleziona almeno un settore per tipologia di attività formativa');
    return valid;
  }

  getColNumber() {
     return parseInt(24/this.props.tipi_accreditamento.length)
  }

  getName(id) {
    return formatter.replaceStub(id, 'tipo_accreditamento', this.props.GlobalStore);
  }

  getSettori(id_tipologia) {

    try {
      return mappatura.getSettoreByMacrotipologia(this.props.GlobalStore.stubs.mappatura_attivita_formativa, id_tipologia).map(el => {
        return {
          key: 'settore_macro_' + id_tipologia + "_" + el.id,
          value: ""+el.id,
          label: el.nome_settore
        }
      })
    } catch(e){
      return [];
    }
  }

  filterSettori(settori, id_tipologia){
    return settori.filter(el => parseInt(el.id_tipo_accreditamento) === parseInt(id_tipologia) ).map(el => ""+el.id_settore )
  }

  getValues(){
    return this.state.id_settore.filter(s => this.props.tipi_accreditamento.find(t => parseInt(t) === parseInt(s.id_tipo_accreditamento)) )
  }

  render() {

    return (
      <div>
        <Row gutter={8}>
          <h3>Settori</h3>
          <p>Seleziona almeno un settore per ciascuna macrotipologia selezionata</p>
          {
            this.props.tipi_accreditamento.map((col,i) => {
              return <Col
              key={"col__" + i}
                lg={24}
                style={{marginBottom: 16}}
              >
                <h4>{this.getName(col)}</h4>
                <Select
                  mode="multiple"
                  disabled={this.props.readOnly}
                  placeholder="Seleziona i settori"
                  value={this.filterSettori(this.state.id_settore, col)}
                  onChange={(value) => this.handleChange(value, col)}
                /*  onDeselect={value => {
                    let settore = this.props.id_settore.find(e => e.id_settore === parseInt(value));
                    if (settore) {
                      notification.warn({
                        message: "Attenzione",
                        description: `Al salvataggio verranno rimossi eventuali laboratori associati al settore "${settore.settore.nome_settore}"`,
                      });
                    }
                  }}*/
                  style={{ width: '100%' }}
                >
                  {this.getSettori(col).map(el=>{
                    return <Option key={col + "_" + el.key} value={el.value}>
                              {el.label}
                            </Option>
                  })}
                </Select>
              </Col>
            })
          }
        </Row>
      </div>
    );
  }
}

export default Settori;