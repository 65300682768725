import { Breadcrumb, Button, Card, Col, Progress, Row, message } from "antd";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import SpinnerLoading from "@common/components/SpinnerLoading";
import CheckPermissions from '@common/decorators/CheckPermissions';
import eventBroker from '@common/utils/services/EventBroker';
import Wrapper from '@common/decorators/Wrapper';
import checkPermission from '@common/utils/check/permissions';
import TableIscrizioni from "../components/TableIscrizioni";
import progettoService from '../services/Progetto';
import iscrizioneService from '../services/Iscrizione';
import ConvocaDiscenti from "../components/Actions/ConvocaDiscenti";
import affidamentoStatic from '@common/utils/static/affidamento';
import moment from 'moment';

const getStato = (progetto, component) => {
  let stato = 'ente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['discentePermissions'])) stato = 'discente';
  return progetto.stato_flusso_description['stato_' + stato];
}

const renderDescriptionText = (record, component) => {
  if (!record.stato_flusso_description) return ''
  let user = 'ente';

  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) user = 'ispettore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['discentePermissions'])) user = 'discente';

  return record.stato_flusso_description['descrizione_' + user];
}

@Wrapper({ route: 'DomandePartecipazione', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewProgetto', 'viewOwnProgetto'])
class DomandePartecipazioneRoute extends Component {

  state = {
    loading: false,
    loaded: true,
    calculating_progress: false,
    sendind_invitation: false,
    loading_contratti: false,
    loading_contratto: false,
    show_convoca_discenti: false,
    loading_convoca_discenti: false,
    dati: null,
    registration_info: {},
    progress_obj: {
      progressContratti: 0
    }
  }

  saveZip(blob) {
    const _blob = new Blob([blob], { type: 'application/zip' });
    FileSaver.saveAs(_blob, "contratti_discenti.zip");

    this.refresh(false);
    //this.refreshInline(data)
  }

  componentDidMount() {
    this.setState({ loaded: false }, () => {
      if (this.props.match.params.id) {
        this.loadData(this.props);
      }
    });
  }

  loadData(props) {
    this.loadContratti(props);
  }

  loadContratti(props) {
    progettoService.detail({
      expand: [
        'progressContratti',
      ].join(',')
    },
      props.match.params.id,
      res => {
        this.setState({
          dati: res.data.data,
          progress_obj: res.data.data.progressContratti,
          loaded: true
        });
      },
      err => this.setState({
        loaded: true
      })
    );
  }

  saveBlob(blob, type, filename) {
    const _blob = new Blob([blob], { type: type });
    FileSaver.saveAs(_blob, filename);
  }

  editableState() {
    //TODO return editable in base a chi sei e in base allo stato
    let editable = this.isEnte();
    return editable;
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']);
  }

  enableManageLearner() {
    return this.isEnte()
      &&
      (
        (this.state.dati?.stato === affidamentoStatic.stato_progetto.avvio_progetto
          && (
            this.state.dati.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_in_preparazione
            || this.state.dati.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_pronto_all_avvio
          )
        )
      )
  }

  inviaInviti() {
    if (!this.state.sendind_invitation) {
      this.setState({ sendind_invitation: true },
        () => {
          iscrizioneService.inviaInviti(this.props.match.params.id,
            res => {
              message.success('Inviti inviati');
              eventBroker.trigger('refreshIscrizioni');
              this.setState({
                sendind_invitation: false
              });
            },
            err => this.setState({
              sendind_invitation: false
            })
          );
        }
      );
    }
  }

  downloadContratti() {
    if (!this.state.loading_contratti) {
      this.setState({ loading_contratti: true },
        () => {
          iscrizioneService.downloadContratti(this.props.match.params.id,
            res => {
              this.saveZip(res.data);
              this.setState({
                loading_contratti: false
              });
            },
            err => this.setState({
              loading_contratti: false
            })
          );
        }
      );
    }
  }

  downloadContratto(idIscrizione, type, filename) {
    if (!this.state.loading_contratto) {
      this.setState({ loading_contratto: true },
        () => {
          iscrizioneService.downloadContratto(
            this.props.match.params.id,
            idIscrizione,
            res => {
              this.saveBlob(res.data, type, filename);
              this.setState({
                loading_contratto: false
              });
            },
            err => this.setState({
              loading_contratto: false
            })
          );
        }
      );
    }
  }

  uploadContratto(idIscrizione, file) {
    if (!this.state.loading_contratto) {
      this.setState({ loading_contratto: true },
        () => {
          iscrizioneService.uploadContratto(
            this.props.match.params.id,
            idIscrizione,
            {
              filename: 'file'
            },
            {
              file: file,
              chiave: 'file'
            },
            res => {
              message.success('Contratto caricato');
              eventBroker.trigger('refreshIscrizioni');
              this.setState({
                dati: res.data.data,
                progress_obj: res.data.data.progressContratti,
                loading_contratto: false
              });
            },
            err => this.setState({
              loading_contratto: false
            })
          );
        }
      );
    }
  }

  convocaDiscenti(data) {
    let data_appuntamento =  moment(data.data_appuntamento).format('DD/MM/YYYY HH:mm')
    
    data = {...data, data_appuntamento:data_appuntamento };
    
    if (!this.state.loading_convoca_discenti) {
      this.setState({ loading_convoca_discenti: true });
      iscrizioneService.convocaDiscenti(this.props.match.params.id, data,
        res => {
          message.success("Discenti convocati")
          this.setState({
            loading_convoca_discenti: false,
            show_convoca_discenti: false
          });
          eventBroker.trigger('refreshIscrizioni');
        },
        err => this.setState({
          loading_convoca_discenti: false,
        })
      );
    }
  }

  downloadGraduatoria() {
    if (!this.state.loading_graduatoria) {
      this.setState({ loading_graduatoria: true },
        () => {
          iscrizioneService.downloadGraduatoria(this.props.match.params.id,
            res => {
              this.saveExcel(res.data);
              this.setState({ loading_graduatoria: false });
            },
            err => this.setState({ loading_contratti: false })
          );
        }
      );
    }
  }

  saveExcel(blob) {
    const _blob = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(_blob, "graduatoria.xlsx");
  }

  refresh(reset = true) {
    if (reset) {
      this.setState({
        loaded: false,
      })
    }
    this.loadData(this.props);
  }

  refreshInline(data) {
    this.setState({
      dati: { ...this.state.dati, ...data }
    });
  }

  updateProgress(progress) {
    this.setState({
      progress_obj: progress,
    });
  }

  render() {
    return (
      <div className="scheda">
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          <h3 className="sectionTitle">Domande di partecipazione</h3>
          <p>
            Elenco delle domande di partecipazione del corso {this.state.dati?.titolo_progetto}
          </p>
        </Card>

        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/affidamento/progetti">Progetti formativi</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Domande di partecipazione
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
          <Col style={{ flex: "1 1 70%" }} >
            <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >

              <Card style={{ flex: "1 1 auto", marginBottom: 12 }}
                bordered={false}>
                {this.state.dati?.ente ? <h4><span style={{ color: '#000' }}>ENTE DI FORMAZIONE:</span> {this.state.dati.ente.denominazione}</h4> : ''}
                {this.state.dati && checkPermission.hasPermission(this.props.UserStore.user, ['viewDomandePartecipazionePerProgetto']) && 
                (
                  this.state.dati.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_in_preparazione
                  || this.state.dati.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_pronto_all_avvio
                ) &&
                  <Button icon="file-zip" className="m5h m5w" type="primary" loading={this.state.loading_contratti} onClick={() => this.downloadContratti()}>Scarica Contratti</Button>
                }
              </Card>
              <Card style={{ flex: "1 1 auto" }}
                bordered={false}
                className="shadow">
                <Row>
                  <Col lg={24} sm={24} xs={24}>
                    <div id="actions">
                      <h4 className="p10h">Stato</h4>
                      <p>Stato: <strong>{this.state.dati ? getStato(this.state.dati, this) : ''}</strong></p>
                      <p>{this.state.dati
                        && this.state.registration_info.stato < affidamentoStatic.stato_iscrizione.inviata
                        ? renderDescriptionText(this.state.dati, this) : ''}
                      </p>
                      {
                        this.enableManageLearner()
                        &&
                        <Button icon="mail" className="m5h m5w" type="primary"
                          loading={this.state.sendind_invitation}
                          onClick={() => this.inviaInviti()}>
                          Invia invito di partecipazione
                          </Button>
                      }
                      {
                        this.enableManageLearner()
                        &&
                        <Button icon="ordered-list" className="m5h m5w" type="primary"
                          loading={this.state.loading_graduatoria}
                          onClick={() => this.downloadGraduatoria()}>
                          Pubblica graduatoria
                          </Button>
                      }
                      {
                        this.enableManageLearner()
                        &&
                        <Button icon="notification" className="m5h m5w" type="primary"
                          loading={this.state.loading_convocazione}
                          onClick={
                            () => {
                              this.setState({
                                show_convoca_discenti: !this.state.show_convoca_discenti
                              })
                            }}>
                          Convoca discenti
                          </Button>
                      }
                    </div>
                    {this.state.show_convoca_discenti && <div style={{ marginTop: 20, backgroundColor: '#f9f9f9', padding: 10 }}><ConvocaDiscenti
                      {...this.props}
                      convocaDiscenti={(data) => this.convocaDiscenti(data)}
                      loading_convoca_discenti={this.state.loading_convoca_discenti}
                    ></ConvocaDiscenti></div>}
                  </Col>
                </Row>
              </Card>
            </Row>
          </Col>

          {this.editableState() ?
            <Col style={{ flex: "1 1 30%" }} >
              <Card
                style={{ height: "100%" }}
                bordered={false}>

                <div style={{ textAlign: 'center' }}>
                  <h4 className="p10h">Percentuale acquisizione dei contratti</h4>

                  <Progress width={100} type="circle" percent={this.state.progress_obj.progressContratti} />
                  <p className="p30h p20w text-justify">
                    {this.state.progress_obj.progressContratti !== 100 ? "" : "Congratulazioni, l'acquisizione dei contratti è completa"}
                  </p>
                </div>
              </Card>
            </Col> : null
          }
        </Row>
        <Row>
          {!this.state.loaded || !this.state.dati
            ?
            <SpinnerLoading message='Caricamento dati...' />
            :
            <TableIscrizioni
              ref="listTable"
              downloadContratto={(idIscrizione, type, name) => this.downloadContratto(idIscrizione, type, name)}
              uploadContratto={(idIscrizione, file) => this.uploadContratto(idIscrizione, file)}
              loadingContratto={this.state.loading_contratto}
              {...this.props}
            />
          }
        </Row>

      </div>
    );
  }
}

export default DomandePartecipazioneRoute;