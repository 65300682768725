import React, { Component } from 'react';
import Wrapper from '../decorators/Wrapper';
import Casellario from "../../assets/casellario.odt";
import CentriDiCosto from "../../assets/centri-di-costo.odt";
import StatoFallimento from "../../assets/stato-fallimento.odt";
import ImposteTasse from "../../assets/imposte-e-tasse.odt";
import Legge68 from "../../assets/legge-68.odt";
import PublicizzazioneCorsi from "../../assets/publicizzazione-corsi.odt";
import PianoAnnuale from "../../assets/piano-annuale.odt";
import SuddivisioneOre from "../../assets/suddivisione-ore.odt";
import Organigramma from "../../assets/organigramma.odt";
import ResponsabileDirezione from "../../assets/responsabile-direzione.odt";
import ErogazioneServizi from "../../assets/erogazione-servizi.odt";
import ObbligoFormativo from "../../assets/obbligo-formativo.odt";
import Esclusivita from "../../assets/esclusivita.odt";



@Wrapper({ route: 'DocumentiUtili', hasSidebar: false, hasHeader: true, hasFooter: true })
class DocumentiUtiliPageRoute extends Component {


  render() {

    return (
      <div>

        <h3>Documenti utili</h3>
        <a href={Casellario} target="_blank" rel="noopener noreferrer">autocert. casellario e carichi pendenti.odt</a><br />
        <a href={CentriDiCosto} target="_blank" rel="noopener noreferrer">autocertif. centri di costo.odt</a><br />
        <a href={StatoFallimento} target="_blank" rel="noopener noreferrer">autocertif. stato fallimento.odt</a><br />
        <a href={ImposteTasse} target="_blank" rel="noopener noreferrer">autocertif. imposte e tasse.odt</a><br />
        <a href={Legge68} target="_blank" rel="noopener noreferrer">autocertif. legge 68.odt</a><br />
        <a href={PublicizzazioneCorsi} target="_blank" rel="noopener noreferrer">autocertif. publicizzazione corsi.odt</a><br />
        <a href={PianoAnnuale} target="_blank" rel="noopener noreferrer">autocertif. piano annuale formazione.odt</a><br />
        <a href={SuddivisioneOre} target="_blank" rel="noopener noreferrer">autocertif. suddivisione ore.odt</a><br />
        <a href={Organigramma} target="_blank" rel="noopener noreferrer">autocertif. organigramma.odt</a><br />
        <a href={ResponsabileDirezione} target="_blank" rel="noopener noreferrer">autocertif. responsabile direzione.odt</a><br />
        <a href={ErogazioneServizi} target="_blank" rel="noopener noreferrer">autocertif. responsabile erogazione servizi.odt</a><br />
        <a href={ObbligoFormativo} target="_blank" rel="noopener noreferrer">autocertif. obbligo formativo.doc26.odt</a><br />
        <a href={Esclusivita} target="_blank" rel="noopener noreferrer">autocertif. esclusività.odt</a><br />
      </div>
    );
  }
}

export default DocumentiUtiliPageRoute;