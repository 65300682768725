import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';

import List from '../components/List';


@Wrapper({route: 'Notifiche', hasSidebar: true, hasHeader: true, hasFooter: true })
class NotificheRoute extends Component {

  state = {
    loading: false,
    asked: false,
  }



  render() {
    return (
      <div>
        <Card
        style={{ marginBottom: '12px', backgroundColor: '#fff' }}
        bordered={false}
        >
        <h1 className="page-title">Notifiche
        </h1>
        </Card>
        <div style={{padding: '12px'}}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/notifiche">Notifiche</Link></Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <List ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default NotificheRoute;