import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Badge, Button, Drawer, Spin, message } from 'antd';
import ModelServices from '@affidamento/services/Lezioni';
import CorsiServices from '@affidamento/services/Corsi';

import checkPermission from '@common/utils/check/permissions';
import CommonForm from '@common/components/form';
import moment from 'moment';

import eventBroker from '@common/utils/services/EventBroker';

const prefixCls = 'ant-fullcalendar';
function zerofixed(v) {
  if (v < 10) {
    return `0${v}`;
  }
  return `${v}`;
}

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                label: 'Dalle',
                name: 'dalle',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                label: 'Alle',
                name: 'alle',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Materia',
                name: 'id_materia',
                options_props: '_contenuto_formativo',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Docente',
                name: 'id_docente',
                showIf: [
                  {
                      type: 'field_validation',
                      field: 'id_materia',
                      operator: '!==',
                      value: -1
                  }
              ],
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'docenti_corso',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Azienda',
                name: 'azienda',
                showIf: [
                  {
                      type: 'field_validation',
                      field: 'id_materia',
                      operator: '===',
                      value: -1
                  }
              ],
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'aziende_corso',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione breve',
                name: 'descrizione_programma',
              }
            ]
          }
        ],
      },
      {
        //props: {
        //  style: { float: 'right' }
        //},
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                }
              }
            ],
          },
          {
            items: [
              {
                type: 'button',
                name: 'btn_remove',
                text: 'Cancella lezione',
                onClick: 'deleteLesson',
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'isEdit',
                  },
                ],
                props: {
                  icon: 'delete',
                  type: 'danger'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
};


export default class CalendarioCorsi extends Component {

  static propTypes = {
    studente: PropTypes.object,
  }

  state = {
    data: null,
    //start: null,
    //end: null,
    ...CalendarioCorsi.getDateRange(moment()),
    loading: true,
    open_drawer: false,
    docenti_corso: null,
    validStart: moment(),
    validEnd: moment(),
    selectedDay: moment()
    
  };

  constructor(props){
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    eventBroker.on('teacherReplaced', this.componentDidMount);
  }

  componentDidMount() {
    let validStart = this.props.corso.data_effettiva_avvio ? moment(this.props.corso.data_effettiva_avvio) : this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection && this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.startDate && this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.endDate !== "" ? moment(this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.startDate, 'YYYY-MM-DD') : moment(this.props.corso.created_at * 1000);
    let validEnd = this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection && this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.endDate && this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.endDate !== "" ? moment(this.props.corso.scheda_progetto_json.generalData.AfcCourseTimeSection.endDate, 'YYYY-MM-DD') : validStart.clone().add(2, 'year');
    let selectedDay = validStart.isAfter(moment()) || validEnd.isBefore(moment()) ? validStart : moment();

    this.setState({validStart: validStart, validEnd: validEnd, ...CalendarioCorsi.getDateRange(selectedDay)},
      () => {
        this.load(this.props.id_corso, this.state.start, this.state.end);
        this.loadDocenti();
      })
   
  }

  load(id, start, end) {
    this.setState({
      loading: true,
    });

    let extraParam = {};
    if (this.props.studente) {
      extraParam.id_iscrizione = this.props.studente.id;
      extraParam.with = 'presenze';
    }
    ModelServices.list({ id_progetto: id, ...extraParam, dal: start.format('YYYY-MM-DD'), al: end.format('YYYY-MM-DD') }, id, res => {
      this.setState({
        data: res.data.data.list.map(l => {
          return {
            ...l,
            dalle: moment(l.dalle, 'HH:mm:ss').format('HH:mm'),
            alle: moment(l.alle, 'HH:mm:ss').format('HH:mm'),
            type: !l.presenze ? null :
              l.presenze.length === 1 ?
                (l.presenze[0].presente ? 'success' : 'error') :
                !moment(l.data).isAfter(moment()) ? 'warning' : null
          }
        }),
        loading: false,
      });
    }, () => {
      this.setState({
        loading: false,
      });
    });
  }

  canEditLesson() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['editLezione']) && this.props.isEditableState;
  }

  canAddLesson() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['addLezione']) && this.props.isEditableState;
  }

  loadDocenti() {
    CorsiServices.listDocenti({}, this.props.id_corso, res => {
      this.setState({
        docenti_corso: res.data.data.list.map(d => {
          return {
            ...d,
            label: d.anagrafica.nome + ' ' + d.anagrafica.cognome,
            key: 'docente_' + d.id,
            value: d.id,
          };
        })
      });
    });
  }

  addLesson(date) {
    this.setState({
      open_drawer: true, editLesson:
      {
        data: date,
        id_progetto: this.props.id_corso,
      }
    });
  }

  editLesson(lesson) {
    this.setState({
      open_drawer: true, editLesson: {
        ...lesson,
        data: moment.utc(lesson.data),
      }
    });
  }

  gotoLesson(lesson) {
    this.props.history.push('/affidamento/registro/' + this.props.id_corso + '/lezione/' + lesson.id)
  }

  dateCellRender(value) {
    const listData = this.getListData(value);
    return (
      <ul className="events" style={{ listStyleType: 'none', paddingInlineStart: 10 }}>
        {listData.map(item => (
          <li key={item.id}>
            {checkPermission.hasPermission(this.props.UserStore.user, ['editLezione']) &&
             <Button size="small" type="link" shape="circle"
              icon={this.canEditLesson()? "edit" : "eye"}
               onClick={() => this.editLesson(item)} />}
            {item.type && <Badge status={item.type} count={item.hours} />}
            <Button size="small" type="link" style={{ paddingLeft: 0, paddingRight: 5 }} onClick={() => this.gotoLesson(item)}> {item.dalle} - {item.alle}</Button>
            {item.docente ? item.docente.cognome : item.id_materia ? this.props.GlobalStore.stubs.contenuto_formativo.find(c => c.value === item.id_materia)?.label : item.area}
            {item.azienda}
            {/*
            <Tooltip placement="top" title={item.shortDescription} trigger="click">
              <Button size="small" type="link" style={{paddingLeft: 0, paddingRight: 0}} >
                <Icon type="info-circle" />
              </Button>
            </Tooltip>
            */}
          </li>
        ))}
      </ul>
    );
  }

  getContenutoFormativo() {
    let list = this.props.GlobalStore.stubs.contenuto_formativo;
    if (this.props.corso.scheda_progetto_json.teachingProgramming.AfcCourseArticulationFormSection.courseArticulationList.find(el => el.stageHours > 0) 
    && list[0].value !== -1) {
      list.unshift({
        key: 'contenuto_formativo_0',
        label: 'Stage',
        value: -1
      })
    }
    return list;
  }

  getListData(value) {
    return this.state.data?.filter(l => value.isSame(l.data, 'day')) || [];
  }

  static getDateRange(date) {
    let selectedDay = date.month() === moment().month() ? moment() : date.clone().startOf('month');
  
    let start = date.clone().startOf('month').startOf('week')
    //let end = date.clone().endOf('month').endOf('week');
    let end = start.clone().add(6, 'week').add(-1, 'day');
    return { start: start, end: end, selectedDay: selectedDay};
  }

  onPanelChange(date, mode) {
    this.setState(CalendarioCorsi.getDateRange(date), () => {
      this.load(this.props.id_corso, this.state.start, this.state.end);
    });
  }

  dateFullCellRender(value) {
    return (
      <div className={`${prefixCls}-date`}>
        {this.canAddLesson() && <Button className='date-fab' type="primary" ghost shape="circle" icon="plus" onClick={() => this.addLesson(value)} />}
        <div className={`${prefixCls}-value`}>{zerofixed(value.date())}</div>
        <div className={`${prefixCls}-content scrollbar`}>{this.dateCellRender(value)}</div>
      </div>
    );
  }

  render() {
    if (!this.props.corso)
      return (<div></div>);

    return (
      <div className="no-margin">
        {this.state.loading && <Spin className="spin-overlay" />}
        <Calendar
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
          dateCellRender={this.dateCellRender}
          dateFullCellRender={this.dateFullCellRender.bind(this)}
          mode="month"
          onPanelChange={this.onPanelChange.bind(this)}
          validRange={[
            this.state.validStart,
            this.state.validEnd
          ]}
          value={this.state.selectedDay}
        />,
        {this.state.open_drawer &&
          <Drawer
            title={this.state.editLesson?.id && this.props.isEditableState? "Modifica lezione del " + this.state.editLesson?.data.format('DD/MM/YYYY') 
            : this.state.editLesson?.id && !this.props.isEditableState? "Lezione del " + this.state.editLesson?.data.format('DD/MM/YYYY') 
            : "Aggiungi lezione del " + this.state.editLesson?.data.format('DD/MM/YYYY')}
            placement="right"
            closable={true}
            onClose={() => this.setState({ open_drawer: false })}
            visible={this.state.open_drawer}
            width={1024}
            destroyOnClose={true}
          >
            <CommonForm
              wrappedComponentRef={ref => this.formRef = ref}
              loading={this.state.loading || false}
              form_model={formLayout(this)}
              values={{...this.state.editLesson, id_materia: this.state.editLesson.stage? -1 : this.state.editLesson.id_materia}}
              readOnly={!this.props.isEditableState}
              isEdit={() => this.state.editLesson?.id && this.props.isEditableState}
              deleteLesson={value => {
                if (this.state.editLesson?.id) {
                  this.setState({ loading: true });
                  ModelServices.delete(this.state.editLesson.id,
                    res => {
                      this.setState({
                        open_drawer: false,
                        loading: false,
                      }, () => {
                        this.load(this.props.id_corso, this.state.start, this.state.end);
                      })
                      message.success("La lezione è stata cancellata correttamente");
                    }, () => {this.setState({ loading: false })});
                } else {
                  this.setState({ open_drawer: false });
                }
              }}
              onSubmit={data => {
                let stage = data.id_materia === -1
                data = {
                  ...this.state.editLesson,
                  ...data,
                  data: this.state.editLesson.data.format('YYYY-MM-DD'),
                  dalle: data.dalle.format('HH:mm'),
                  alle: data.alle.format('HH:mm'),
                };

                if (stage) {
                  data.id_docente = null
                  data.stage = true
                  data.id_materia = null
                } else {
                  data.stage = false
                  data.azienda = null
                }
                this.setState({ loading: true });
                if (data.id) {
                  ModelServices.update(data, data.id,
                    res => {
                      this.setState({
                        editLesson: res,
                        open_drawer: false,
                        loading: false
                      }, () => {
                        this.load(this.props.id_corso, this.state.start, this.state.end);
                      })
                      message.success("La lezione è stata modificata correttamente");
                    }, () => {this.setState({ loading: false })});
                } else {
                  ModelServices.create(data,
                    res => {
                      this.setState({
                        editLesson: res,
                        open_drawer: false,
                      }, () => {
                        this.load(this.props.id_corso, this.state.start, this.state.end);
                        this.props.updateCallback();
                      })
                      message.success("La lezione è stata aggiunta correttamente");
                    }, () => {this.setState({ loading: false })});
                }
              }}
              docenti_corso={this.state.docenti_corso}
              aziende_corso={this.props.corso.scheda_progetto_json.teachingProgramming.AfcStageCompanyListFormSection.stageCompanyList.map(el => {
                return {
                  key: el.name,
                  label: el.name,
                  value: el.name
                }})}
              _contenuto_formativo={this.getContenutoFormativo()}
            />
          </Drawer>
        }
      </div>
    )
  }
}

