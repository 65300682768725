import React, { Component } from 'react';
import eventBroker from '@common/utils/services/EventBroker';
import { Table } from 'antd';
import moment from 'moment';
import DocSpan from '@common/components/DocSpan';
import CommonForm from '@common/components/form';
import RegistrationServices from '@/modules/affidamento/services/Iscrizione';

const THISTAB = '1';
const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'simple_upload',
                name: 'dragger',
                accept: '.pdf',
                action: '/', // url upload
                listType: 'text', // text,picture,picture-card
                multiple: false,
                fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
                customRequest: 'customUploadRequest', // sovrascrive upload
                onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
                text: "Carica pdf",
                props: {
                  showUploadList: false,
                  disabled: component.props.loading
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.uploadEnabled && component.props.is_discente }
                  }
                ]
              },
            ],
          },
        ],
      },
    ],
  }
};

class RegistrationDocumentsList extends Component {

  constructor(props) {
    super(props);
    this.refreshDocumenti = this.refreshDocumenti.bind(this);
    eventBroker.on('refreshDocumenti', this.refreshDocumenti);
  }


   model = (component) => [
    {
      title: "Data Upload",
      dataIndex: 'created_at',
      key: 'created_at',
      render: element => <div style={{ width: 100 }}>
        {
          moment.unix(element).format('DD/MM/YYYY')
        }
      </div>
    },
    {
      title: "Nome file",
      dataIndex: '',
      sorter: false,
      render: element => <div><DocSpan key={element.id} file={element} canRemove={component.props.deleteEnabled && component.props.is_discente} onRemove={(element)=>component.removeDocument(element.id, element)}/></div>
    },
  ];

  state = {
    documents: [],
    loading: false,
  }

  componentDidMount() {
    this.load(this.props)
  }

  componentDidUpdate(prevProps, prevState) {
   /* if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      })
    }*/

    if (prevProps.tab !== this.props.tab && this.props.tab === THISTAB) {
      this.load(this.props)
    }
  }

  removeDocument(documentId, file) {
   /* this.setState({ loading: true }, () => {
      let documents = this.state.documents;
      documents = documents.filter(d => { return d.id !== documentId });
      this.setState({
        documents: documents,
        loading: false
      }, () => this.props.removeDocument(file));
    }
    ); */


    this.props.removeDocument(file);


    
  }

  refreshDocumenti(){
    this.load(this.props);
  }

  load(props) {
    this.setState({ loading: true }, () =>
      RegistrationServices.getRegistrationDocuments(
        props.registration_id,
        (res) => {
          this.setState({
            documents: res.data.data,
            loading: false
          });
        },
        () => this.setState({ loading: false }))
    );
  }

  uploadDocument(file) {
    this.props.uploadDocument(file);
  }

  render() {

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          readOnly={this.props.readOnly}
          form_model={formLayout(this)}
          disabled={true}

          fileListProp={() => []}
          onRemove={data => {
            //this.setState({
            //  fileList: this.state.fileList.filter(f => f.uid !== data.uid),
            //});
          }}
          customUploadRequest={obj => {
            this.uploadDocument(obj.file);
          }}

        />
        <Table
          columns={this.model(this)}
          dataSource={this.state.documents}
          loading={this.state.loading || this.props.loading || false}
        />
      </div>
    );
  }

}
export default RegistrationDocumentsList;

