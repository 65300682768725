import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button ,Upload, Icon } from 'antd';
import CommonForm from '@common/components/form';

const formLayout = (component) => { return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          { 
            items: [
              {
                name: 'download',
                type: 'child',
                child: <div>
                  <Button
                    loading={component.props.downloading || false}
                    disabled={component.props.downloading || component.props.uploading || false}
                    type="primary" icon="download" onClick={() => {
                      component.props.download()
                    }}
                  >{(component.props.downText) ? component.props.downText : "Scarica"}</Button>

                  <Upload
                    accept={component.props.accept || '*'}
                    multiple={false}
                    fileList={null}
                    customRequest={obj => {
                      component.props.upload(obj.file);
                    }}
                    {...component.props} >
                    <Button className="m5w"
                      disabled={component.props.downloading || component.props.uploading || component.props.disabled_upload || false}
                      loading={component.props.uploading || false} >
                      <Icon type="upload" />{(component.props.upText) ? component.props.upText : "Carica"}
                    </Button>
                  </Upload>
                </div>
              }
            ],
          },  
        ],
      },
    ],
    onSubmit: 'submitted',
}};

class DownloadUpload extends Component {

  state = {

  }
    render() {
      return  <div className="m10h">
                <CommonForm
                    wrappedComponentRef={inst => (this.formRef = inst)}
                    loading={this.props.uploading}
                    form_model={formLayout(this)}
                    values={{
                    }}
                    fileListProp={() => []}
                    customUploadRequest={obj => {
                        this.props.upload(obj.file);
                    }}
                />
            </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(DownloadUpload);
