import React, { Component } from 'react';
import {connect, ReactReduxContext} from 'react-redux';

import FileSaver from 'file-saver';

import { Row, Col, Button, Table, Tooltip, Popconfirm,
Modal } from 'antd';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';


import format from '@common/utils/formatters/_';


import InfrastrutturaServices from '@accreditamento/services/Infrastruttura';
import CreateAttrezzatura from '@accreditamento/forms/completeRequest/infrastruttura/attrezzature/Create';
import UpdateAttrezzatura from '@accreditamento/forms/completeRequest/infrastruttura/attrezzature/Update';


const getTipoAula = (component, record) => {
  try {
    let type = format.replaceStub(record.id_tab_tipo_aula, 'tipo_aula', component.props.GlobalStore)

    return type;
  } catch(e) {
    console.error(e)
    return 'errore'
  }
}
const columns = (component) => {
  return [{
        title: 'Nome',
        dataIndex: 'aula',
        render: (text, record) => (<div style={{width: 150}}>
          {record.nome_aula}
        
        </div>),
    }, {
        title: 'Attrezzatura',
        dataIndex: 'tipologia',
        render: (text, record) => (<div style={{width: 400}}>{record.attrezzature.map((route) => (
          <div key={route.tipologia}>
              {format.replaceStub(route.tipologia, 'tipo_attrezzatura_rest', component.props.GlobalStore )}
              </div>
        ))}</div>)
    }, {
        title: 'Quantità',
        dataIndex: 'quantita',
        //key: 'quantita',
        render: (text, record) => (<div style={{width: 80}}>{record.attrezzature.map((route) => (
          <div key={route.id}>
              {route.quantita}
              </div>
        ))}</div>)
    }, /*{
        title: 'Mesi di disponibilità',
        dataIndex: 'mesi_disponibilita',
        //key: 'mesi_disponibilita',
        render: (text, record) => (<div style={{width: 80}}>{record.mesi_disponibilita}</div>)

    },*/
    {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
            <div style={{width: 80}}>
                <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                  <Button type={component.props.accreditamento.progress && component.props.accreditamento.progress.errors.attrezzature && component.props.accreditamento.progress.errors.attrezzature.find(el => el === record.id) ? 'danger' : 'default'} 
                  className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.editAttrezzatura(record)} />
                </Tooltip>
                <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questa aula?"
                onConfirm={() => component.deleteAttrezzatura(record.id)} okText="Si" cancelText="No">
                  <Button disabled={component.props.readOnly} loading={component.state.deleting_id === record.id} className="m2w" size="small" shape="circle" icon="delete" />
                </Popconfirm>
            </div>
        ),
    }]
}


const uploadFormLayout = (component) =>{
return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'simple_upload',
                name: 'dragger',
                accept: '.p7m,.pdf',
                action: '/', // url upload
                listType: 'text', // text,picture,picture-card
                multiple: false,
                fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
                customRequest: 'customUploadRequest', // sovrascrive upload
                onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
                text: "Carica lista p7m o pdf firmato",
                props: {
                  disabled: component.props.readOnly,
                  showUploadList: false
                }
              },
            ],
          },
        ],
      },
    ],
    onSubmit: 'submitted',
  };
}

class Attrezzature extends Component {

  state = {
    loading: false,
    attrezzature: [],
    show_add: false,
    adding: false,
    show_edit: false,
    editing: false,
    editing_element: null,
    deleting: false,
    deleting_id: null,
    media_file: null
  };

  componentDidMount() {
    this.setState({loading: true, media_file: this.props.sede.media.find(el => el.tipo.sottodominio === 'autocertificazione_attrezzature') });
    setTimeout(()=>{
      InfrastrutturaServices.listAttrezzatura(
        this.props.accreditamento.id, this.props.sede.enteSede.id, (res) => {
          console.error("dati",res.data.data)
        this.setState({loading: false, attrezzature: res.data.data })
      }, () => this.setState({loading: false}) )
      
    }, 200)
  }

  createAttrezzatura(data, file){
    if(!this.state.adding) {
      //console.log('aggiungo', data, file)
      this.setState({loading: true, adding: true});

      InfrastrutturaServices.addAttrezzatura(
        this.props.accreditamento.id,
        this.props.sede.enteSede.id,
        data,
        file,
        (res) => {
          //console.warn("suzuki2", res.data.data)
          //console.warn("suzuki", data)          
          if(this.props.onAdd) this.props.onAdd(res.data.data);
          this.setState({
            loading: false,
            adding: false,
            show_add: false,
            //attrezzature: [...this.state.attrezzature, res.data.data]
            attrezzature: res.data.data
          })
      }, () => this.setState({loading: false, adding: false}) )

    }
  }

  editAttrezzatura(element) {

    console.warn('editAttrezzatura', element)

    this.setState({
      show_edit: true,
      editing_element: element
    })
  }

  updateAttrezzatura(data, file){
    if(!this.state.editing) {
      //console.log('aggiorno', data, file)
      this.setState({loading: true, editing: true});

      InfrastrutturaServices.editAttrezzatura(
        this.props.accreditamento.id,
        this.props.sede.enteSede.id,
        this.state.editing_element.id,
        data,
        file,
        (res) => {
          if(this.props.onEdit) this.props.onEdit(res.data.data);
          this.setState({loading: false, editing: false, show_edit: false,
            attrezzature: this.state.attrezzature.map(el => {
              for (const id in res.data.data.attrezzature) {
                console.warn('id', id, 'ccc', res.data.data.attrezzature[id])
                if(el.id == id){
                  el.quantita = res.data.data.attrezzature[id].quantita;
                  el.id_tab_attrezzature_possesso = res.data.data.attrezzature[id].titolo;
                }                
              }

            return el;
          })
        })
      }, () => this.setState({editing: false, loading: false}) )

    }
  }

  deleteAttrezzatura(id) {
    if(!this.state.deleting) {

      this.setState({loading: true, deleting: true, deleting_id: id});
      InfrastrutturaServices.deleteAttrezzatura( this.props.accreditamento.id, this.props.sede.enteSede.id, id, () => {
        if(this.props.onDelete) this.props.onDelete(id);
        this.setState({loading: false, deleting: false, deleting_id: null, attrezzature: this.state.attrezzature.filter(el=>el.id_aula != id)})
      }, () => this.setState({loading: false, deleting: false, deleting_id: null, }) )

    }

  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minu = ('0' + today.getMinutes()).slice(-2);
    let nome_file = `attrezzature_${date}${month}${year}_${hour}${minu}.pdf`;
    FileSaver.saveAs(_blob, nome_file);

    this.setState({loading: false, downloading: false});

  }

  uploadAttrezzature(file) {
    if(!this.state.loading) {
      this.setState({
        loading: true
      }, () => {
        InfrastrutturaServices.uploadAttrezzature(
          this.props.accreditamento.id,
          this.props.sede.id_ente_sede, {
          filename: 'file'
        }, { file: file, name: file.name, chiave: 'file'}, (res) => {

          if(this.props.onAdd) this.props.onAdd({})
          this.setState({ loading: false, media_file: res.data.data });

        }, () => this.setState({ loading: false }))
      })

    }
  }

  render() {

    let aule = [];
    let id_aule_aggiunte = [];

    this.state.attrezzature.map(attrezzatura => {

      if(!id_aule_aggiunte.includes(attrezzatura.aula.id)){
        aule.push(attrezzatura.aula);
        id_aule_aggiunte.push(attrezzatura.aula.id);
      }
      // decommentare quando dataSource={this.state.attrezzature} diventa dataSource={aule} e il rendering si appoggia al nuovo datasource
      //delete attrezzatura.aula;

      let current_aula = aule.find(aula => aula.id === attrezzatura.aula.id);

      if(!current_aula.attrezzature){
        current_aula.attrezzature = [];
      }

      if(!current_aula.attrezzature.find(attrezzatura_inserita => attrezzatura_inserita.id === attrezzatura.id)){
        current_aula.attrezzature.push(attrezzatura);
      }

    });


    return (
      <div>
        <Table
        columns={columns(this)}
        loading={this.state.loading}
        dataSource={aule}
        rowKey={(record)=>record.id}
        />

        <Button
          style={{marginTop: 16}}
          onClick={()=>this.setState({show_add: !this.state.show_add})}
          type='primary'
          disabled={this.props.readOnly}
        >
          Inserisci una nuova attrezzatura
        </Button>
        <Modal
          visible={this.state.show_add}
          onOk={() => this.setState({show_add: false})}
          okButtonProps={{style: {display: 'none'}}}
          width={800}
          destroyOnClose={true}
          onCancel={()=>this.setState({show_add: false})}
        >
          <CreateAttrezzatura
            loading={this.state.adding}
            GlobalStore={this.props.GlobalStore}
            accreditamento={this.props.accreditamento}
            sede={this.props.sede}
            onSubmit={(data, file)=>this.createAttrezzatura(data, file)}
            readOnly={this.props.readOnly}
            aule={aule}
          />
        </Modal>
        <Modal
          title={this.state.editing_element? "Aula: " + this.state.editing_element.nome_aula : null}
          visible={this.state.show_edit}
          onOk={() => this.setState({show_edit: false})}
          okButtonProps={{style: {display: 'none'}}}
          width={800}
          destroyOnClose={true}
          onCancel={()=>this.setState({show_edit: false})}
        >
          <UpdateAttrezzatura
            loading={this.state.editing}
            GlobalStore={this.props.GlobalStore}
            values={this.state.editing_element}
            sede={this.props.sede}
            accreditamento={this.props.accreditamento}
            onSubmit={(data, file)=>this.updateAttrezzatura(data, file)}
            readOnly={this.props.readOnly}
          />
        </Modal>
      </div>
    );
  }
}
export default connect(({GlobalStore})=>({GlobalStore}))(Attrezzature);