/*
const STATO_NOMINA_ISPETTORE = 0;
const STATO_DOWNLOAD_DIRIGENTE = 1;
const STATO_FIRMATA_DIRIGENTE = 2;
const STATO_ATTESA_CONFERMA = 3;
const STATO_ACCETTATA = 4;
const STATO_IN_ISTRUTTORIA = 5; // conferma da parte dell'istruttore
const STATO_RIFIUTATA = 6;
const STATO_RIFIUTATO_CONFERMATO = 7;
const STATO_VERBALE_TRASMESSO = 8;

const STATO_IN_COMPILAZIONE = 0;
const STATO_FIRMATO = 1;
const STATO_CONFERMATO = 2;
*/
export default {
  stato: {
    nomina_ispettore: 0,
    download_dirigente: 1,
    firmata_dirigente: 2,
    attesa_conferma: 3,
    accettata: 4,
    in_istruttoria: 5,
    rifiutata: 6,
    rifiutato_confermato: 7,
    verbale_trasmesso: 8
  },
  stato_verbale: {
    in_compilazione: 0,
    compilato: 1,
    scaricato: 2,
    firmato: 3,
    confermato: 4,
    controdeduzioni_sent: 5,
    terminato: 6
  },
  stato_controdeduzioni: {
    in_corso: 0,
    da_firmare: 1,
    firmata: 2,
    confermata: 3
  },
  stato_verbale_ispezione: {
    in_compilazione: 0,
    terminato: 1,
    da_firmare: 2,
    firmato: 3,
    confermato: 4,
    controfirmato: 5
  },
  stato_ispezione:{
    in_corso: 0,
    ispettori_nominati: 1,
    verbali_inseriti: 2,
    verbali_firmati: 3,
    verbali_confermati: 4
  },

  stato_can_nomina: [7],
  stato_end_nomina: [8,7],
  tipo_media: {
    sottodominio_richiesta_firmata_dirigente: 'nomina_firmata_da_dirigente',
    sottodominio_feedback_ispettore: 'nomina_ispettore',
    sottodominio_verbale_firmato: 'verbale_ispettore',
    sottodominio_verbale_controfirmato: 'verbale_ispettore_controfirmato',
    sottodominio_controdeduzioni_firmata: 'verbale_controdeduzioni',
    dichiarazione_disponibilita_ispettore: 'dichiarazione_disponibilita_ispettore',
    autocertificazione_ispettore: 'autocertificazione_ispettore'
  },
  pallini: {
    dirigente: {
      green: [0,1,2],
      red: [],
      orange: [3,4,5]
    },
    istruttore: {
      green: [],
      red: [],
      orange: []
    },
    ente: {
      green: [],
      red: [],
      orange: []
    },
    ispettore: {
      green: [3,4,5,9],
      red: [],
      orange: [0,1,2]
    }
  },
  getStatoLabel: (nomina) => {
    let label = '';
    switch(nomina.stato) {
      case 0: label = 'Nomina Addetto al controllo'; break;
      case 1: label = 'Nomina Addetto al controllo'; break;
      case 2: label = 'Firmata dal dirigente'; break;
      case 3: label = 'In attesa di conferma'; break;
      case 4: label = 'Accettata'; break;
      case 5: label = 'In istruttoria'; break;
      case 6: label = 'Rifiutata'; break;
      case 7: label = 'Rifiuto confermato'; break;
      case 8: label = 'Verbale trasmesso'; break;
      default: break;
    }
    return label;
  },
  getStatoVerbaleLabel: (stato) => {
    let label = '';
    switch(stato) {
      case 0: label = 'In compilazione'; break;
      case 1: label = 'Compilato'; break;
      case 2: label = 'Scaricato'; break;
      case 3: label = 'Firmato'; break;
      case 4: label = 'Confermato'; break;
      case 5: label = 'Controdeduzioni inviate'; break;
      case 6: label = 'Terminato';  break;
      default: break;
    }
    return label;
  },
  getStatoControdeduzioneLabel: (controdeduzione) => {
    let _label = 'In corso';
    switch(controdeduzione.stato) {
      case 0: break;
      case 1: _label = 'Da firmare'; break;
      case 2: _label = 'Firmata'; break;
      case 3: _label = 'Confermata'; break;
      default: break;
    }
    return _label;
  }
}