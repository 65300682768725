import BaseRequest from '@common/services/_baseRequest';


export default {

  list: (params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'repertorio/qualifica-professionale',
      cb,
      ecb
    );
  },

  detail: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'repertorio/qualifica-professionale/' + id,
      cb,
      ecb
    );
  },

  update: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'put',
      'repertorio/qualifica-professionale/' + id,
      cb,
      ecb
    );
  },

  create: (params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      'repertorio/qualifica-professionale',
      cb,
      ecb
    );
  },

  delete: (id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'repertorio/qualifica-professionale/' + id,
      cb,
      ecb
    );
  },

  changeStatus: (id = null, status, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { status: status },
      null,
      'post',
      'repertorio/qualifica-professionale/' + id + '/change-status',
      cb,
      ecb
    );
  },
  
  storico: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'repertorio/qualifica-professionale/' + id + '/storico',
      cb,
      ecb
    );
  },

  storicoById: (params = {}, id = null, idStorico = null,  cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'repertorio/qualifica-professionale/' + id + '/storico/' + idStorico,
      cb,
      ecb
    );
  },

  getBySettore: (id_settore, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'repertorio/qualifica-professionale/get-by-settore/' + id_settore,
      cb,
      ecb
    );
  },

  exportRepertorio: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			null,
			null,
			'get_file',
			'repertorio/qualifica-professionale/export',
			cb,
			ecb || null,
			null
		);
  },
  
  printScheda: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
        {},
        {},
        null,
        "get_file",
        'repertorio/qualifica-professionale/' + id + '/download',
        cb,
        ecb
    );
},

}
