import React, { Component } from 'react';
import {connect} from 'react-redux';

import { Row, Col, message,
Input, Checkbox, Icon, Button, Tooltip
} from 'antd';

import InterrelazioniServices from '@accreditamento/services/Interrelazioni';

class RequisitiForm extends Component {

  state = {
    loaded: false,
    checked: [],
    macro_checked: [],
    attori: [],
    oggetto: ''
  }

  async submit() {
    let valid = true;

    if(this.state.oggetto === ''){
      valid = false;
      message.error('Inserisci "Relazione con il sistema sociale, economico e territoriale"');
      return;
    }

    if(this.state.checked.length === 0){
      valid = false;
      message.error('Seleziona almeno una macrotipologia');
      return;
    }

    let errors = [];



    this.state.checked.forEach(el => {
      if(this.state.macro_checked.filter(c => c.id_macro === el).length === 0 ){
        valid = false;
        if(errors.indexOf('Seleziona almeno un tipo di attore per ogni macrotipologia') === -1) {
          errors.push('Seleziona almeno un tipo di attore per ogni macrotipologia');
        }
      } else {
        this.state.macro_checked.filter(c => c.id_macro === el)
        .forEach(c=> {
          if(
            this.state.attori.filter(a=>a.id_macro === el && a.id_proto === c.id_proto).length === 0 ){
            valid = false;
            if(errors.indexOf('Seleziona almeno un attore per ogni tipo di attore') === -1) {
              errors.push('Seleziona almeno un attore per ogni tipo di attore');
            }
          }

          if(
            this.state.attori.filter(a=>a.id_macro === el && a.id_proto === c.id_proto && a.attore === '').length > 0){
            valid = false;
            if(errors.indexOf('Inserisci tutti gli attori') === -1) {
              errors.push('Inserisci tutti gli attori');
            }
          }
        })

      }
    })

    if(!valid) {
      errors.map(e=>message.error(e));
      return;
    }

    let data = {
      oggetto: this.state.oggetto,
      attori: this.state.attori
    }

    if(!this.state.loading) {
      await this.setState({loading: true});
      InterrelazioniServices.addStudioTerritorio(this.props.accreditamento.id, data, () => {
        this.props.onCreate();
        this.setState({loading: false})
      }, () => this.setState({loading: false}))
    }

  }

  componentDidMount() {
    this.setState({
      loaded: true
    });
  }

  onChange(e,id) {
    //console.log('check', e, id);
    if(e.target.checked){
      this.setState({
        checked: [...this.state.checked, id]
      })
    } else {
      this.setState({
        checked: this.state.checked.filter(e2=>e2 !== id),
        macro_checked: this.state.macro_checked
            .filter(e2=> e2.id_macro !== id),
        attori: this.state.attori.filter(a => a.id_macro !== id)
      })
    }
  }

  isChecked(id) {
    //console.log('verifico checked', this.state.checked, id, this.state.checked.indexOf(id))
    return this.state.checked.indexOf(id) !== -1 ? true : false;
  }

  onChangeProto(e,id_macro, id_proto) {
    //console.log('check', e, id_macro, id_proto);
    if(e.target.checked){
      this.setState({
        macro_checked: [...this.state.macro_checked, {id_macro: id_macro, id_proto: id_proto}]
      },()=>this.addAttore(id_macro, id_proto))
    } else {
      this.setState({
        macro_checked: this.state.macro_checked
            .filter(e2=> e2.id_macro !== id_macro || e2.id_proto !== id_proto ),
        attori: this.state.attori.filter(a => a.id_macro !== id_macro || a.id_proto !== id_proto)
      })
    }
  }

  isCheckedProto(id_macro, id_proto) {
    return this.state.macro_checked.find( e => e.id_macro === id_macro && e.id_proto === id_proto) ? true : false;
  }

  getMacrotipologieSelected() {
    return this.state.checked.map((el)=> {
      return this.props.tipo_accreditamento.find(t=>t.tipologia.id === el)
    })
  }

  getNomeTipologia(id){
    return this.props.tipo_accreditamento.find(t=>t.tipologia.id === id).tipologia.tipologia;
  }

  getNomeTipoAttore(id){
    return this.props.GlobalStore.stubs.tipo_attore.find(t=>t.value === id).label;
  }

  addAttore(id_macro, id_proto) {
    //let id =((Date.now() % 1000) / 1000) + "_" + (Math.random());
    let id = window.performance.now();
    this.setState({attori: [
      ...this.state.attori,
      {
        id: id,
        id_macro: id_macro,
        id_proto: id_proto,
        attore: ''
      }]
    })
  }

  removeAttore(id) {
    let el = this.state.attori.find(e=>e.id === id);
    if(this.state.attori.filter(e=>e.id_macro === el.id_macro && e.id_proto === el.id_proto).length === 1) return;
    this.setState({attori: this.state.attori.filter(e=>e.id !== id)
    })
  }

  changeAttoreValue(e, id) {
    //console.log('cambio id')
    this.setState({
      attori: [
      ...this.state.attori.filter(e2=>e2.id !== id),
        {
          ...this.state.attori.find(e2=>e2.id === id),
          attore: e.target.value
        }
      ]
    })
  }

  render() {
    return (
      <div>
        { this.state.loaded ?
        <Row gutter={16}>
          <Col md={8} sm={24}>
            <label for="oggetto" title="Oggetto"><span style={{color: 'red'}}>*</span> Relazione con il sistema sociale, economico e territoriale</label>
            <Input.TextArea
            id="oggetto"
            onChange={(e)=>this.setState({oggetto: e.target.value})}
            value={this.state.oggetto}
            autoSize />
            {
              this.props.tipo_accreditamento.map( (el, i) => {
                return <div style={{marginTop: 8}}>
                  <Checkbox
                    key={"ck_tipo_"+i}
                    onChange={ (e) => this.onChange(e, el.tipologia.id) }
                    checked={this.isChecked(el.tipologia.id)}
                  >
                    {el.tipologia.tipologia}
                  </Checkbox>
                </div>
              })
            }
            <Button
              type="primary"
              block
              style={{marginTop: 16}}
              loading={this.state.loading}
              onClick={()=>this.submit()}
              >
              Conferma
            </Button>
          </Col>
          <Col md={8} sm={24}>
            <h4>Tipi di Attori</h4>
            {
              this.getMacrotipologieSelected().map( (sel,i) => {
                return <div key={"sel_"+i} style={{marginBottom: 16}}>
                  <p><strong>{sel.tipologia.tipologia}</strong></p>
                  {
                    this.props.GlobalStore.stubs.mappatura_interrelazioni.studio_territorio.map(p=>{
                      return <div style={{marginTop: 8}}>
                        <Checkbox
                          key={"ck_tipo_"+i}
                          onChange={ (e) => this.onChangeProto(e, sel.tipologia.id, p.id) }
                          checked={this.isCheckedProto(sel.tipologia.id, p.id)}
                        >
                          {p.attore}
                        </Checkbox>
                      </div>
                    })
                  }
                </div>
              })
            }
          </Col>
          <Col md={8} sm={24}>
            <h4>Attori</h4>
            {
              this.state.macro_checked.map((c,i)=>{
                return <div key={"sel_m_"+i}>
                  <p><strong>{this.getNomeTipologia(c.id_macro) + " - " + this.getNomeTipoAttore(c.id_proto)}</strong>
                  <Button
                  style={{marginLeft: 8}}
                  shape="circle"
                  size="small"
                  onClick={()=>this.addAttore(c.id_macro, c.id_proto)}
                  >
                    <Icon
                    type="plus"
                    shape="circle"
                     />
                     </Button></p>
                  {
                    this.state.attori
                    .filter(e => e.id_macro === c.id_macro && e.id_proto === c.id_proto)
                    .sort((a,b)=> {
                      return a.id < b.id ? 1 : -1;
                    })
                    .map( (attore)=>{
                      return <p key={"attore_"+attore.id}><Input
                      onChange={(e)=>this.changeAttoreValue(e, attore.id)}
                      value={this.state.attori.find(e=>e.id === attore.id).attore}
                      suffix={
                        <Tooltip title="Rimuovi">
                          <Button
                          shape="circle"
                          size="small"
                          type="danger"
                          ghost
                          onClick={()=>this.removeAttore(attore.id)}
                          >
                          <Icon type="close" />
                          </Button>
                        </Tooltip>
                      }
                      /></p>
                    })
                  }
                </div>
              })
            }
          </Col>
        </Row> : null}
      </div>
    );
  }
}

export default connect(({GlobalStore})=>({GlobalStore}))(RequisitiForm);
