export default {
  utente_generico: {
    'Impresa': 'Impresa',
    'ParteSociale': 'Parte sociale e datoriale',
    'Associazione': 'Associazione',
    'OrganizzazioneTerzoSettore': 'Organizzazione del terzo settore e della società civile',
    'AmministrazionePubblica': 'Amministrazione pubblica e istituzione',
    'DipendenteRegionale': 'Dipendente regionale appartenente a Dipartimenti / Settori dell’Amministrazione regionale',
  },

  userExtraInfo:[
    'Impresa', 
    'ParteSociale', 
    'Associazione', 
    'OrganizzazioneTerzoSettore', 
    'AmministrazionePubblica', 
    'DipendenteRegionale',
    'IspettoreAccreditamento',
    'Valutatore',
    'TutorAziendale'
  ]
}