import React, { Component } from 'react';
import { Button, Card, notification } from 'antd';
import { Link } from 'react-router-dom';
import Wrapper from '@common/decorators/Wrapper';

import ProfileForm from '../forms/request/ProfileLearner';

import ProvinceService from '@common/services/locations/Province';
import TipoMediaServices from '@common/services/media/TipoMedia';

import AccreditamentoRequest from '@accreditamento/services/Request';

import '../less/Accreditamento.less';

import moment from 'moment';

@Wrapper({ route: 'AccreditamentoRequest', hasSidebar: false, hasHeader: true, hasFooter: true, public: true })
class RegistrazioneDiscente extends Component {

  state = {
    loading: false,
    mounted: false,
    tipi_media: [],
    tipi_organizzazione: [],
    province: [],
    loaded: false,
    stato: [
      { key: 1, value: 1, label: "Italia" },
      { key: 2, value: 2, label: "Estero" }
    ],
    values: {},
    profile: {
      nome: "",
      cf: "",
      cognome: "",
      civicolegale: "",
      caplegale: "",
      provincialegale: null,
      comunelegale: null,
      comune_nascita: null,
      stato_nascita: 1,
      id_nazione: 1,
      statolegale: 1,
      vialegale: "",
      nato_a: "",
      nato_il: null,
      telefonolegale: "",
      email: "",
      pec: "",
      id_tipo_ragione_sociale: "1",
      universita_scuole: "1",
      id_documento: 1,
      needsTutor: null,
      medias: [],
      nome_tutore: "",
      cognome_tutore: "",
      stato_nascita_tutore: 1,
      provincia_nascita_tutore: null,
      comune_nascita_tutore: null,
      nato_il_tutore: null,
      cf_tutore: "",
      telefonolegale_tutore: "",
      medias_tutore: []
    },
  };

  componentDidMount() {
    this.setState({ mounted: true }, () => {

      TipoMediaServices.all((res) => {
        this.setState({ tipo_media: res.data.data })
      }, err => console.error(err));


      ProvinceService.all(res => {
        this.setState({
          province: res.data.data.map(h => {
            return { key: h.id, value: h.id, label: h.provincia + ' ' + h.sigla };
          }),
        });
      });

      this.setState({ loaded: true });
    });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  async submitRequest() {

    let valid = await this.refs.profile_form.validate();

    if (valid)
      var valori_profilo = this.refs.profile_form.getValues(true);
    if (valori_profilo) {
      this.setState({
        profile: valori_profilo
      });
      valori_profilo.nato_il = moment(
        valori_profilo.nato_il
      ).format('YYYY-MM-DD')
      valori_profilo.nato_il_tutore = moment(
        valori_profilo.nato_il_tutore
      ).format('YYYY-MM-DD')
      let values = {
        profile: this.state.profile,
      };
      this.sendRequest(values);
    }
  }

  sendRequest(values) {

    var val_to_send = Object.assign({}, { ...values });
    if (!this.state.loading) {
      this.setState({ loading: true });

      val_to_send.profile.nato_il = moment(values.profile.nato_il).format('YYYY-MM-DD');
      val_to_send.profile.nato_il_tutore = moment(values.profile.nato_il_tutore).format('YYYY-MM-DD');

      let medias = [...values.profile.medias];

      AccreditamentoRequest.registrazioneDiscente(
        {
          ...val_to_send.profile,
          medias: medias,
          base_url: this.props.GlobalStore.BASE_URL,
        },
        res => {
          this.setState({ loading: false, loaded: false });
          notification.destroy();
        },
        err => {
          this.setState({ loading: false});
        }
      );
    }
  }

  render() {
    return (
      <div style={{ maxWidth: 800, margin: '20px auto' }}>
        {/* <LogoForm /> */}
        <h3 className="sectionTitle" style={{ textAlign: 'center' }}>REGISTRAZIONE DISCENTE</h3>
        <p style={{ textAlign: 'center' }}>
          <span><em className="color-red">*</em> I campi contrassegnati da asterisco sono obbligatori</span>
        </p>
        <Card className="shadow" bordered={false}>

          {this.state.loaded
            ? <div className="steps-content">
              {
                 <ProfileForm
                  values={this.state.profile}
                  province={this.state.province}
                  stato={this.state.stato}
                  nazione_estera={this.state.stato}
                  tipo_media={this.props.GlobalStore.stubs.tipo_media}
                  comunelegale={(this.state.profile && this.state.profile.comunelegale) ? this.state.profile.comunelegale : null}
                  comune_nascita={(this.state.profile && this.state.profile.comune_nascita) ? this.state.profile.comune_nascita : null}
                  ref="profile_form"
                  onUpload={(media) => {
                    try {

                      this.setState({
                        profile: {
                          ...this.state.profile,
                          medias: [...this.state.profile.medias, media]
                        }
                      })
                    } catch (e) {
                      console.error(e);
                    }
                  }}

                  onDeleteMedia={id => {
                    try {

                      this.setState({
                        profile: {
                          ...this.state.profile,
                          medias: this.state.profile.medias.filter(el => el.id !== id)
                        }
                      })
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  {...this.props}
                />}
            </div>
            : <p
              style={{
                margin: '40px auto',
                width: '320px',
                textAlign: 'center',
                fontSize: 18,
              }}
            >
              Hai inoltrato una richiesta di registrazione, verifica di aver ricevuto la mail di conferma all'indirizzo fornito
              </p>
          }

          <Link
            to="/login"
            className="login-form-forgot"
            style={{ float: 'right' }}
            onClick={() => {
            }}
          >
            Annulla
          </Link>
        </Card>
        {
          this.state.loaded &&
            <div className="steps-action m10h">
              {
                <Button
                  type="primary"
                  loading={this.state.loading}
                  size="large"
                  onClick={() => this.submitRequest()}
                >
                  Conferma i dati
              </Button>
              }

            </div>
        }
      </div>
    );
  }
}

export default RegistrazioneDiscente;