import BaseRequest from "@common/services/_baseRequest";


export default {

	login: (data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{ expand: 'anagrafica' },
			{
				username: data.username,
				password: data.password
			},
			null,
			"post",
			'auth/auth/login',
			cb,
			ecb || null,
			null
		);
	},

	logout: (cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'auth/auth/logout',
			cb,
			ecb || null,
			null
		);
	},

	reset: (data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{
				new_pwd: data.new_pwd,
				confirm_password: data.confirm_password,
				token: data.token
			},
			null,
			"post",
			'auth/reset/reset',
			cb,
			ecb || null,
			null
		);
	},

	askreset: (data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{
				email: data.email,
				base_url: data.base_url
			},
			null,
			"post",
			'auth/reset/ask-reset',
			cb,
			ecb || null,
			null
		);
	},


	changePassword: (data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{
				'UserChangePassword': {
					old_password: data.old_password,
					new_password: data.new_password,
					repeat_password: data.repeat_password
				}
			},
			null,
			"post",
			'user/change-password',
			cb,
			ecb || null,
			null
		);
	},

	verifySamlData: (samlDataEncripted, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{samlDataEncripted: samlDataEncripted},
			{},
			null,
			"get",
			'auth/saml/verifySamlData',
			cb,
			ecb || null,
			null,
			false, 
			true
		);
	},

	openIdConnectUrlLogin: (cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'auth/open-id-connect/url-login',
			cb,
			ecb || null,
			null,
			false, 
			true
		);
	},

}
