import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import ListUtentiEnti from '../components/ListUtentiEnti';

@Wrapper({ route: 'enti', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['entePermissions'])
class UtenteEnti extends Component {

  render() {
    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          <h3 className="sectionTitle">I miei enti</h3>

        </Card>
        <div style={{ padding: '12px' }}>
        <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>I miei enti</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <ListUtentiEnti ref="listTable" {...this.props} />

      </div>
    );
  }
}


export default UtenteEnti;