import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import IEApp from './IEApp';
import './App.less';

function isIE() {
  let ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

  return is_ie;
}

if (!isIE()) {
	ReactDOM.render(<App />, document.getElementById('root'));
} else {
	ReactDOM.render(<IEApp />, document.getElementById('root'));
}
//registerServiceWorker();
