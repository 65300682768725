import React, { Component } from 'react';
import { Button, Card, Tooltip } from 'antd';
import TableComponent from '@common/components/table';
import checkPermission from '@common/utils/check/permissions';
import ModelServices from '../services/Accreditamento';
import moment from 'moment';
import accreditamentoStatic from '@common/utils/static/accreditamento';
import queryString from 'query-string';

import JoinLongArray from '@common/components/JoinLongArray';

import _ from '@common/utils/formatters/_';

const model = (component) => {
    let cols = {
        with_server_pagination: true,
        columns: [
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'actions',
                //width: "300px",
                render: element => <div style={{ width: 140 }}>
                    {
                        //  renderPallino(element, component)
                    }
                    {
                        renderActions(element, component)
                    }
                </div>,
            },
            {
                title: 'N. prot.',
                dataIndex: '',
                sorter: false,
                headerSort: false,
                key: 'numero_protocollo',
                render: element => <div style={{ width: 80 }}>
                    {<strong>{(element.numero_protocollo) ? element.numero_protocollo : ''}</strong>}
                </div>
            },
            {
                title: 'Acquisita il',
                dataIndex: '',
                sorter: true,
                key: 'data_acquisizione',
                render: element => <div style={{ width: 140 }}>
                    {(element.data_acquisizione) ? moment(element.data_acquisizione, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
                </div>,
            },
            checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']) ? {
                title: 'Ultimo aggiornamento',
                dataIndex: 'updated_at',
                sorter: true,
                key: 'updated_at',
                render: element => <div style={{ width: 140 }}>
                    {moment.unix(element).format('DD/MM/YYYY HH:mm')}
                </div>
            } : {},
            {
                title: 'Tipo Acc.',
                dataIndex: '',
                sorter: false,
                //key: 'tipo_accreditamento',
                render: element => <div style={{ width: 100 }}>
                    {accreditamentoStatic.getTipoLabel(element)}<br />
                </div>
            },
            {
                title: 'Stato',
                dataIndex: '',
                sorter: true,
                key: 'stato',
                render: element => <div>
                    {getStato(element, component)}
                </div>
            },
            {
                title: 'Tipologie',
                dataIndex: '',
                sorter: false,
                //key: 'tipologia_accreditamento',
                render: element => <div style={{ width: 140 }}>
                    {
                        JoinLongArray(
                            ", ",
                            element.tipologie && element.tipologie.map((obj) => obj.tipologia),
                            50
                        )
                    }
                </div>
            },
            !checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']) ? {
                title: 'Ente',
                dataIndex: '',
                sorter: true,
                key: 'ente',
                render: element => <div style={{ width: 140 }}>
                    {element.ente.denominazione}
                </div>
            } : {},
            {
                title: 'Responsabile Acc.',
                dataIndex: '',
                sorter: false,
                //key: 'ana_nome',
                render: element => <div style={{ width: 140 }}>
                    {element.istruttore[0] &&
                        <p><strong>Istruttore</strong><br /> {element.istruttore[0] && element.istruttore[0].anagrafica ? element.istruttore[0].anagrafica.nome : ''} {element.istruttore[0] && element.istruttore[0].anagrafica ? element.istruttore[0].anagrafica.cognome : ''}</p>
                    }
                </div>
            },
            {
                title: 'Settori',
                dataIndex: '',
                sorter: false,
                //key: 'settori',
                render: element => <div style={{ width: 160 }}>
                    {element.settori ?
                        JoinLongArray(
                            ", ",
                            element.settori.map((obj) => obj.nome_settore),
                            50
                        ) : null
                    }
                    {/*element.settori && element.settori.map((obj, i) => {
              return <p style={{ paddingBottom: '3px' }} key={i}>{obj.nome_settore}</p>;
            })*/}
                </div>
            },
            {
                title: 'Sedi',
                dataIndex: '',
                sorter: false,
                //key: 'settori',
                render: element => <div style={{ width: 160 }}>
                    {element.sedi
                        ?
                        JoinLongArray(
                            ", ",
                            element.sedi.map((obj) => {
                                return obj.indirizzo.full_address;
                            }),
                            50
                        ) : null
              /*&& element.sedi.map((obj, i) => {
              return <p style={{ paddingBottom: '3px' }} key={i}>{obj.indirizzo.full_address}</p>;
            })*/}
                </div>
            },
            {
                title: 'Ore annue di formazione',
                dataIndex: '',
                sorter: false,
                //key: 'settori',
                render: element => <div style={{ width: 80 }}>
                   {260 * 8 * (element.auleaggregation.map(el => el.counted).reduce((a, b) => a + b, 0) || 0)}
                </div>
            }
        ]
    }

    if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) {
        cols.columns.push({
            title: 'Data assegnazione',
            dataIndex: '',
            sorter: true,
            key: 'data_timer_audit',
            render: element => <div style={{ width: 140 }}>
                {(element.data_timer_audit) ? moment(element.data_timer_audit, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
            </div>,
        })
    }

    return cols;
}

const getStato = (record, component) => {
    let stato = 'ente';
    if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
    if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
    if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';


    return <div>
        {record.stato_flusso_formatted['stato_' + stato]}
        {checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions']) ? getScadenzaAccreditamento(record, component) : null}
        {checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions']) ? getScadenzaAudit(record, component) : null}
    </div>
}

const getScadenzaAccreditamento = (record, component) => {
    return (record.blocked_timer === 1) ? <div> <strong>Timer bloccato</strong></div> : (record.data_acquisizione && !record.data_termine_accreditamento) ? <div>
        <Tooltip placement="topRight" title="Giorni alla scadenza">
            <strong>{60 + record.integration_added_days - moment().diff(moment(record.data_acquisizione, 'YYYY-MM-DD'), 'days')}</strong>
        </Tooltip>
    </div> : ''
}


const getScadenzaAudit = (record, component) => {
    return (record.blocked_timer === 1) ? <div> <strong>Timer bloccato</strong></div> : (record.data_timer_audit && !record.data_termine_audit) ? <div>
        <Tooltip placement="topRight" title="Giorni alla scadenza dell'Istruttoria Amministrativa">
            <strong>{90 + record.integration_added_days - moment().diff(moment(record.data_timer_audit, 'YYYY-MM-DD'), 'days')}</strong>
        </Tooltip>
    </div> : ''
}

const renderActions = (element, component) => {
    return <div style={{ display: 'inline-block' }}>
        <Tooltip placement="top" title="Visualizza scheda">
            <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/accreditamento/scheda/" + element.id)} />
        </Tooltip>
    </div>
}

class ListRevocheEnte extends Component {
    base_url = '/enti/' + this.props.match.params.id + '/accreditamenti';

    state = {
        data: {
            total: 0,
            current: 1,
            data: []
        }
    }

    componentDidMount() {
        this.callAndUpdate(this.props)
    }

    callAndUpdate(props) {
        let { params, pager } = _.getFilterParam(props.location.search, this.default_order);

        this.setState({ loading: true });
        ModelServices.list_by_ente(props.match.params.id, params, (res) => {
            let data = _.getResponse(res);
            this.setState({
                loading: false,
                data: data
            }, () => this.refs.table_component.updateTableData(
                data.data,
                data.current,
                data.total,
                pager)
            );

        }, (e) => {
            this.setState({ loading: false });
        })

    }

    updateQueryStr(obj) {
        try {
            let q_s = queryString.parse(this.props.location.search);
            // brutto forte
            if (obj.sort) {
                if (q_s.sort && obj.sort) {
                    if (q_s.sort === obj.sort) {
                        if (q_s.sort[0] === "") {
                            q_s.sort = q_s.sort.replace("-", "");
                        } else {
                            q_s.sort = "-" + q_s.sort
                        }
                    } else {
                        q_s = { ...q_s, ...obj }
                    }
                } else {
                    q_s = { ...q_s, ...obj }
                }
            } else {
                q_s = { ...q_s, ...obj }
            }



            this.props.history.push(this.base_url + '?' + queryString.stringify(q_s))
        } catch (e) {
            //console.log('errore cambio url',e);
        }

    }

    render() {
        return (
            <div>
                <Card
                    className="shadow no-pad"
                    bordered={false}>
                    <TableComponent
                        data={this.state.data}
                        model={model(this)}
                        table_props={{ rowKey: 'id' }}
                        size="medium"
                        ref="table_component"
                        loading={this.state.loading}
                        with_server_pagination={true}
                        updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                        updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
                    />
                </Card>
            </div>
        )
    }
}

export default ListRevocheEnte;