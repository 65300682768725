import { Input, Radio, Table } from 'antd';
import React, { Component } from "react";
import DocList from '@common/components/DocList';
import format from '@common/utils/formatters/_';

const getFunzioneProfessione = (record, component) => {
  try{
    return (record.id_tipo_funzione)
    ? format.replaceStub( record.id_tipo_funzione, 'tipo_funzioni', component.props.GlobalStore )
    : format.replaceStub( record.id_professione, 'professioni', component.props.GlobalStore)
  } catch(e) {
    return ""
  }
}

const getCv = (record, component) => {
  let c = component.hasCurriculum(record);
  return (c) ?
  <DocList
    key={"file_"+c.id}
    file={c}
    canRemove={false}
    /> : null;
}

const table_columns = (component) => [
  {
      title: 'Conforme',
      dataIndex: '',
      render: (text, record) => (<div>
        <Radio.Group
          disabled={component.props.readOnly}
          onChange={(e)=>component.checkFunzione(e, record)}
          value={component.validFunzione(record)}>
          <Radio.Button value={1}>Ok</Radio.Button>
          <Radio.Button value={0}>Ko</Radio.Button>
        </Radio.Group>
        {!component.validFunzione(record) ?
          <Input.TextArea
          style={{marginTop: 8}}
            value={component.getNoteFunzione(record)}
            onChange={(e)=>component.changeNoteFunzione(e, record)}
            disabled={component.props.readOnly}
            placeholder="Osservazioni"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
          : null
        }
      </div>),
  }, {
    title: 'Dati anagrafici',
    dataIndex: 'nome',
    key: 'nome',
    render: (text, record) => (<div style={{width: 150}}>{record.anagrafica.nome + " " + record.anagrafica.cognome}<br />{record.anagrafica.cf}</div>)
  }, {
    title: 'Funzione/Professione',
    dataIndex: 'cognome',
    key: 'cognome',
    render: (text, record) => (<span>{getFunzioneProfessione(record, component)}</span>)
  },
  {
    title: 'Rapporto Lavoro',
    dataIndex: 'rapporto_lavoro',
    key: 'rapporto_lavoro',
    render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).id_tipo_rapporto_lavoro, 'tipo_rapporto_lavoro', component.props.GlobalStore ) }</div>)
  },
  {
    title: 'Titolo di studio',
    dataIndex: 'titolo_studio',
    key: 'titolo_studio',
    render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).titolo_studio, 'tipo_titolo_studio', component.props.GlobalStore ) }</div>)
  }, {
    title: 'Curriculum',
    dataIndex: 'curriculum',
    key: 'curriculum',
    render: (text, record) => (<span>{getCv(record, component)}</span>)
  }];


const utenza_columns = (component) => [
  {
      title: 'Conforme',
      dataIndex: '',
      render: (text, record) => (<div>
        <Radio.Group
          disabled={component.props.readOnly}
          onChange={(e)=>component.checkUtenzaSpeciale(e, record)}
          value={component.validUtenzaSpeciale(record)}>
          <Radio.Button value={1}>Ok</Radio.Button>
          <Radio.Button value={0}>Ko</Radio.Button>
        </Radio.Group>
        {!component.validUtenzaSpeciale(record) ?
          <Input.TextArea
          style={{marginTop: 8}}
            value={component.getNoteUtenzaSpeciale(record)}
            onChange={(e)=>component.changeNoteUtenzaSpeciale(e, record)}
            disabled={component.props.readOnly}
            placeholder="Osservazioni"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
          : null
        }
      </div>),
  }, {
    title: 'Dati anagrafici',
    dataIndex: 'nome',
    key: 'nome',
    render: (text, record) => (<div style={{width: 150}}>{record.anagrafica.nome + " " + record.anagrafica.cognome}<br />{record.anagrafica.cf}</div>)
  }, {
    title: 'Funzione/Professione',
    dataIndex: 'cognome',
    key: 'cognome',
    render: (text, record) => (<span>{getFunzioneProfessione(record, component)}</span>)
  },
  {
    title: 'Rapporto Lavoro',
    dataIndex: 'rapporto_lavoro',
    key: 'rapporto_lavoro',
    render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).id_tipo_rapporto_lavoro, 'tipo_rapporto_lavoro', component.props.GlobalStore ) }</div>)
  },
  {
    title: 'Titolo di studio',
    dataIndex: 'titolo_studio',
    key: 'titolo_studio',
    render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).titolo_studio, 'tipo_titolo_studio', component.props.GlobalStore ) }</div>)
  }, {
    title: 'Curriculum',
    dataIndex: 'curriculum',
    key: 'curriculum',
    render: (text, record) => (<span>{getCv(record, component)}</span>)
  }];


class PersonaleNonDocente extends Component {

  state = {
    mounted: false,
    loading: false,
    personale_non_docente: [],
    validFunzioni: {},
    noteFunzioni: {},
    validUtenzeSpeciali: {},
    noteUtenzeSpeciali: {},
  };



  componentDidMount() {
    if(this.props.values && this.props.values.validFunzioni) this.setState({validFunzioni: this.props.values.validFunzioni})

    if(this.props.values && this.props.values.noteFunzioni) this.setState({noteFunzioni: this.props.values.noteFunzioni})

    if(this.props.values && this.props.values.validUtenzeSpeciali) this.setState({validUtenzeSpeciali: this.props.values.validUtenzeSpeciali})

    if(this.props.values && this.props.values.noteUtenzeSpeciali) this.setState({noteUtenzeSpeciali: this.props.values.noteUtenzeSpeciali})
  }


  getRapportoLavoro(record) {
    try {
      return record.anagrafica.rapportoLavoro.find(el => el.id_ente === this.props.id_ente)
    } catch(e) {
      return {}
    }
  }

  hasCurriculum(record) {
    try {
      return this.getRapportoLavoro(record).media.find(m => m.tipo.tipo_media.toUpperCase() === 'CURRICULUM')
    } catch(e) {
      return {}
    }
  }


  getValues() {
    let obj = {
      validFunzioni: this.state.validFunzioni,
      noteFunzioni: this.state.noteFunzioni,
      validUtenzeSpeciali: this.state.validUtenzeSpeciali,
      noteUtenzeSpeciali: this.state.noteUtenzeSpeciali
    };
    return obj;
  }

  checkFunzione(e, record) {
    let f = this.state.validFunzioni;
    f[record.id] = e.target.value;
    this.setState({validFunzioni: f});

    if(e.target.value === 1){
      this.setState(
        {
          noteFunzioni: {
            ...this.state.noteFunzioni,
            [record.id]: ''
          }
        }
      )
    }
  }

  validFunzione(record) {
    return this.state.validFunzioni[record.id] || 0;
  }

  getNoteFunzione(record) {
    return this.state.noteFunzioni[record.id] || '';
  }

  changeNoteFunzione(e, record) {
    this.setState({
      noteFunzioni: {
        ...this.state.noteFunzioni,
        [record.id]: e.target.value
      }
    })
  }

  validUtenzaSpeciale(record) {
    return this.state.validUtenzeSpeciali[record.id] || 0;
  }

  checkUtenzaSpeciale(e, record) {
    let f = this.state.validUtenzeSpeciali;
    f[record.id] = e.target.value;
    this.setState({validUtenzeSpeciali: f});

    if(e.target.value === 1){
      this.setState(
        {
          noteUtenzeSpeciali: {
            ...this.state.noteUtenzeSpeciali,
            [record.id]: ''
          }
        }
      )
    }
  }

  getNoteUtenzaSpeciale(record) {
    return this.state.noteUtenzeSpeciali[record.id] || '';
  }

  changeNoteUtenzaSpeciale(e, record) {
    this.setState({
      noteUtenzeSpeciali: {
        ...this.state.noteUtenzeSpeciali,
        [record.id]: e.target.value
      }
    })
  }








  render() {
    return <div style={{marginTop: 16}}>
            <h4>Organigramma aziendale</h4>
            <Table
            rowKey={(r)=>"org_"+r.id}
            columns={table_columns(this)}
            dataSource={this.props.funzioni} />
            <h4>Utenze speciali</h4>
            <Table
            rowKey={(r)=>"ut_"+r.id}
            columns={utenza_columns(this)}
            dataSource={this.props.utenze_speciali} />
        </div>
  }
}


export default PersonaleNonDocente;