import React, { Component } from "react";
import Wrapper from "@common/decorators/Wrapper";
import CheckPermissions from "@common/decorators/CheckPermissions";
import { Link } from "react-router-dom";

import { Divider, Card, Row, Col } from "antd";

import NominaServices from '@accreditamento/services/Nomina';
import NominaActions from '@accreditamento/components/nomina/NominaActions';
import DownloadAutocertificazioneIspettore from '@accreditamento/components/nomina/DownloadAutocertificazioneIspettore';

import nominaStatic from '@common/utils/static/nomina';
import auditStatic from '@common/utils/static/audit';

import checkPermission from '@common/utils/check/permissions';

import Documenti from '@common/components/Documenti';

import VerbaleControdeduzioni from '@accreditamento/components/nomina/VerbaleControdeduzioni';

import IspezioneVerbali from '@accreditamento/components/ispezione/IspezioneVerbali';
import DownloadDichiarazioneDisponibilitaIspettore from "@accreditamento/components/nomina/DownloadDichiarazioneDisponibilitaIspettore";

@Wrapper({
  route: "NominaRoute",
  hasSidebar: true,
  hasHeader: true
})
@CheckPermissions(["ispettorePermissions", "dirigentePermissions", "istruttorePermissions", "adminPermissions", "HelpdeskPermissions"])
class Nomina extends Component {

  state = {
    mounted: false,
    loading: false,
    loaded: false,
    accreditamento: {},
    nomina: {
      media: [],
      ispezione: {
        accreditamento: {

        }
      }
    },
    tipo_media: []
  };

  async componentDidMount() {

    await this.setState({ mounted: true });
    this.loadNomina(this.props);

  }


  UNSAFE_componentWillReceiveProps(newProps) {
    try {
      if (this.props.match.params.id !== newProps.match.params.id ||
        this.props.match.params.id_accreditamento !== newProps.match.params.id_accreditamento) this.loadNomina(newProps);
    } catch (e) {
      //console.log(e);
    }
  }

  loadNomina(props) {
    NominaServices.view(props.match.params.id, (res) => {
      this.setState({nomina: res.data.data, loaded: true})
    }, (err) => console.error(err) )
  }


  async componentWillUnmount() {
    await this.setState({ mounted: false });
  }


  update(obj) {

  }

  refresh() {
    this.loadNomina(this.props);
  }

  refreshInline(data) {

    this.setState({
      nomina: {
        ...data
      }
    });
  }

  /**
   * Sui verbali ricarico solo l'ispezione
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  reloadIspezioneInline(data) {

    this.setState({
      nomina: {
        ...this.state.nomina,
        ispezione: {...data}
      }
    });
  }

  canEdit() {
    return true
  }

  cannotAddDoument() {
    return (this.state.nomina &&
      this.state.nomina.stato
      && this.state.nomina.stato === nominaStatic.stato.attesa_conferma
      && checkPermission.hasPermission(this.props.UserStore.user, ['ispettorePermissions', 'adminPermissions'])
      && this.props.UserStore.user.id === this.state.nomina.id_ispettore)
      ? false
      : true
  }

  canDeleteDocument() {
    return (this.state.nomina &&
        this.state.nomina.stato === nominaStatic.stato.attesa_conferma
      )
      ? true
      : false
  }

  showVerbale() {
    return (
      this.state.nomina.verbale &&
      (
        (
          this.state.accreditamento.audit.filter(a => a.id_user === this.props.UserStore.user.id).length > 0 &&
          this.state.accreditamento.audit.filter(a => a.id_user === this.props.UserStore.user.id)[0].stato === auditStatic.stato.success
        ) || checkPermission.hasPermission(this.props.UserStore.user, 'dirigentePermissions')
      )
    ) ? true : false
  }

  /*
  isValidVerbale() {
    return true;
    return this.state.nomina.verbale && this.state.nomina.verbale.stato !== nominaStatic.stato_verbale.in_compilazione ? true : false
  }
  */

  renderControdeduzioni() {
    return <VerbaleControdeduzioni
      accreditamento={this.state.accreditamento}
      nomina={this.state.nomina}
      controdeduzioni={this.state.nomina.verbale.controdeduzioni}
      refreshInline={(data)=>this.refreshInline(data)}
    />
  }

  render() {
    return (
      <div>
        <Card
          style={{
            marginBottom: "12px",
            backgroundColor: "#fff"
          }}
          bordered={false}
        >
          <h3 className="sectionTitle">
            Nomina accreditamento
          </h3>
          {(this.state.nomina.id) ?
            <React.Fragment>
              <p>Numero protocollo: #{this.state.nomina.numero_protocollo}</p>
              <p>Protocollo accreditamento: #{this.state.nomina.ispezione.accreditamento.numero_protocollo}</p>
              <Link to={"/accreditamento/scheda/" + this.state.nomina.ispezione.accreditamento.id}
                className="login-form-forgot" >Scheda richiesta di accreditamento</Link>
              {(this.state.nomina) ? <div>Stato: <strong>{nominaStatic.getStatoLabel(this.state.nomina)}</strong></div> : null}
            </React.Fragment>
            : null
          }

          <Divider />

          {
            this.state.nomina.ispezione.accreditamento.id
            ?
            <React.Fragment>
              <h4>Sedi</h4>
              {
                this.state.nomina.ispezione.accreditamento.sediIspezione.map(sede => {
                  return <p key="{sede.indirizzo.id}">{sede.indirizzo.full_address}</p>
                })
              }
            </React.Fragment>
            : null
          }
          <div id="actions">
            <NominaActions
              loading={this.state.loading}
              accreditamento={this.state.nomina.ispezione.accreditamento}
              nomina={this.state.nomina}
              refreshInline={(data) => this.refreshInline(data)}
              refresh={() => this.refresh()} />
          </div>
        </Card>
        {(this.state.loaded) &&
          <div>
            {
              this.state.nomina && this.state.nomina.ispezione && this.state.nomina.ispezione && this.state.nomina.ispezione.verbali.length > 0
              ?
              <IspezioneVerbali
                ispezione={this.state.nomina.ispezione}
                accreditamento={this.state.nomina.ispezione.accreditamento}
                refresh={()=>this.refresh()}
                refreshAll={()=>this.refresh()}
                refreshInline={(data) => this.reloadIspezioneInline(data)}
              />
              : null
            }

            {
              (this.state.nomina.stato === nominaStatic.stato.in_istruttoria ||
                this.state.nomina.stato === nominaStatic.stato.verbale_trasmesso)
                ?
                <Row gutter={16}>
                  <Col span={(this.showVerbale()) ? 12 : 0}>
                    {/*verbale*/}
                    {/*
                      this.showVerbale()
                        ?
                        <Card
                          bordered={false}
                        >
                        <h3>Verbale</h3>
                        {this.state.nomina && this.state.nomina.verbale ?
                          <p>Stato: <strong>{nominaStatic.getStatoVerbaleLabel(this.state.nomina.verbale.stato)}</strong></p>
                          : null
                        }
                        {
                          this.state.accreditamento.sediIspezione.map(sede => {
                            return <div style={{marginBottom: 8}}>
                              <p style={{marginBottom: 0}}>Sede: <strong>{sede.indirizzo.via} {sede.indirizzo.ncivico} {sede.indirizzo.locComune.comune} {sede.indirizzo.locComune.provincia_sigla}</strong></p>
                              <Link className="color-green" to={"/accreditamento/" + this.state.accreditamento.id + "/nomina/" + this.state.nomina.id + "/verbale-ispezione/sede/" + sede.id} className="login-form-forgot" >
                                <strong>Verbale ispezione -></strong>
                              </Link>
                              <NominaVerbale
                                accreditamento={this.state.accreditamento}
                                nomina={this.state.nomina}
                                verbale={this.state.nomina.verbale.verbaleIspezione.find(v => v.id_sede === sede.id)}
                                refresh={() => this.refresh()}
                                refreshInline={(data) => this.refreshInline(data)}
                              />
                              <Divider />
                            </div>
                          })
                        }
                        {this.renderControdeduzioni()}
                        </Card>
                        : null
                    */}
                  </Col>
                  <Col span={12}>
                    {/*audit*/}
                    {/*<Card
                      bordered={false}
                    >
                      <Audit
                        level={auditStatic.level.ispettore}
                        accreditamento={this.state.accreditamento}
                        nomina={this.state.nomina}
                        refreshInline={(data) => this.refreshInline(data)}
                        refresh={() => this.refresh()}
                      />
                    </Card>*/}
                  </Col>
                </Row>
                : null
            }
            <Row>
              <Col span={24}>
                <div id="actions" style={{marginTop: 16}}>
                  {checkPermission.hasPermission(this.props.UserStore.user, ['ispettorePermissions']) ?
                  <React.Fragment>
                    <Documenti
                      accreditation_id={this.state.nomina.ispezione.accreditamento.id}
                      canDelete={this.canDeleteDocument()}
                      canAddNote={false}
                      onUpload={(data) => this.refresh()}
                      onDelete={(data) => this.refresh()}
                      cannotAdd={this.cannotAddDoument()}
                      media_types={this.props.GlobalStore.stubs.tipo_media}
                      medias={this.state.nomina.media}
                      current_domain="nomina"
                      current_subdomain="autocertificazione_ispettore"
                      required_types={[]}
                      ref_id={this.state.nomina.id}
                    >

                      {
                        this.state.nomina.media.filter(
                          m =>
                            m.tipo.sottodominio === 'autocertificazione_ispettore' && m.deleted !== 1 && m.tipo.tipo_media.toUpperCase() === 'AUTOCERTIFICAZIONE ASSENZA CONFLITTO DI INTERESSI'
                        ).length === 0 && !this.cannotAddDoument()
                          ?
                          <DownloadAutocertificazioneIspettore
                            nomina={this.state.nomina}
                            accreditamento={this.state.nomina.ispezione.accreditamento}
                          />
                          : null
                      }
                    </Documenti>

                    <Documenti
                    accreditation_id={this.state.nomina.ispezione.accreditamento.id}
                      canDelete={this.canDeleteDocument()}
                      canAddNote={false}
                      onUpload={(data) => this.refresh()}
                      onDelete={(data) => this.refresh()}
                      cannotAdd={this.cannotAddDoument()}
                      media_types={this.props.GlobalStore.stubs.tipo_media}
                      medias={this.state.nomina.media}
                      current_domain="nomina"
                      current_subdomain="dichiarazione_disponibilita_ispettore"
                      required_types={[]}
                      ref_id={this.state.nomina.id}
                    >
                      {
                        this.state.nomina.media.filter(
                          m =>
                            m.tipo.sottodominio === 'dichiarazione_disponibilita_ispettore' && m.deleted !== 1 && m.tipo.tipo_media.toUpperCase() === 'DICHIARAZIONE DISPONIBLITÀ'
                        ).length === 0 && !this.cannotAddDoument()
                          ?
                          <DownloadDichiarazioneDisponibilitaIspettore
                            nomina={this.state.nomina}
                            accreditamento={this.state.nomina.ispezione.accreditamento}
                          />
                          : null
                      }
                    </Documenti>
                  </React.Fragment>
                    : null
                  }
                </div>
              </Col>
            </Row>

          </div>
        }
      </div>
    );
  }
}


export default Nomina;
