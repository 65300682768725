import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import ProceduraService from '../../services/Procedura';
import SpinnerLoading from '@common/components/SpinnerLoading';

import format from '@common/utils/formatters/_';
import eventBroker from '@common/utils/services/EventBroker';
import { message } from 'antd';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'dati_bando',
                type: 'title',
                element: 'h4',
                text: 'Dati bando',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Finanziamento',
                type_string: 'text',
                name: 'bando.estremi_finanziamento',
                props: {
                  disabled: component.props.readOnly || !component.isNew(),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Asse',
                type_string: 'text',
                name: 'bando.asse',
                props: {
                  disabled: component.props.readOnly || !component.isNew(),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Obiettivo specifico',
                type_string: 'text',
                name: 'bando.obiettivo_specifico',
                props: {
                  disabled: component.props.readOnly || !component.isNew(),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Titolo',
                type_string: 'text', // field type
                name: 'bando.titolo_bando',
                props: {
                  disabled: component.props.readOnly || !component.isNew(),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'dati_procedura',
                type: 'title',
                element: 'h4',
                text: 'Dati procedura',
                props: {
                  disabled: component.props.readOnly || !component.props.isNew,
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },

              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'radio',
                label: 'Candidature aperte fino ad esaurimento risorse',
                name: 'fino_ad_esaurimento',
                option_type: 'button',
                props: {
                  disabled: component.props.readOnly,
                },
                options: [
                  {
                    value: '1',
                    label: 'Si'
                  },
                  {
                    value: '0',
                    label: 'No'
                  }
                ]
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data apertura',
                name: 'data_apertura',
                //format: 'DD/MM/YYYY',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  //TODO
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data chiusura',
                name: 'data_chiusura',
                //format: 'DD/MM/YYYY',
                defaultValue: '',
                props: {
                  style: { width: '100%' },
                  defaultValue: '',

                },
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    field: 'fino_ad_esaurimento',
                    operator: '==',
                    value: 0,
                    },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            offset: 22,
            span: 2,
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
};

export default class DatiIdentificativiForm extends Component {

  state = {
    data: null,
    loading: true,
  };

  componentDidMount() {
    this.load(this.props.id_procedura);
  }

  load(id) {
    ProceduraService.detail({}, id, res => {
      this.setState({
        data: res.data.data,
        loading: false,
      });
    });
  }

  onSubmit(data) {
    data = format.replaceFormDatesOut([
      'data_apertura',
      'data_chiusura'
    ], data, 'YYYY-MM-DD');
    if (!this.state.loading) {
      this.setState({ loading: true }, () => {
        let save = this.state.data.id ? ProceduraService.update : ProceduraService.create
        save(data, this.state.data.id, res => {
          message.success("Formulario salvato correttamente");
          this.setState({ data: res.data.data, loading: false });
          eventBroker.trigger('loadProcedura');
          }, () => this.setState({loading: false }) );
      });
    }
  }

  getValues() {
    return format.convertBoolean([
      'fino_ad_esaurimento'
    ], this.state.data);
  }

  isNew() {
    return !this.state.data?.bando;
  }

  render() {
    return (!this.state.data ? <SpinnerLoading message='Caricamento dati...' /> :
    <div style={{opacity: this.state.loading ? 0.5 : 1}}>
      <CommonForm
        loading={this.state.loading || false}
        wrappedComponentRef={inst => (this.formSelRef = inst)}
        form_model={formLayout(this)}
        values={this.getValues()}
        readOnly={this.props.readOnly}
        onSubmit={this.onSubmit.bind(this)}
      />
      </div>
    )
  }
}
