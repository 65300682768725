import BaseRequest from "../_baseRequest";


export default {

  list: (get_vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      get_vars,
      {},
      null,
      "get",
      'locations/province',
      cb,
      ecb || null,
      null,
      true
    );
  },

  all: (cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "get",
      'locations/province/all',
      cb,
      ecb || null,
      null,
      true
    );
  }

}
