import React, { Component } from 'react';
//import { connect } from "react-redux";

import { Icon } from 'antd';

import ModelServices from '../services/User';

import CommonForm from '@common/components/form';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Username',
              type_string: 'text', // field type
              name: 'username',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Email',
              type_string: 'text', // field type
              name: 'email',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'btn',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
}

class CreateModel extends Component {

  state = {
    loading: false,

  }



  componentDidMount() {

    ModelServices.view({}, this.props.id, (res) => {
      this.setState({ model: res.data.data })
    }, () => null);


  }




  updateQueryStr() {

  }




  async submit(data) {

    if (!this.state.loading) {
      await this.setState({ loading: true });

      ModelServices.update(data, this.props.id, () => {
        this.setState({ loading: false }, () => {
          this.props.updateRecord();
        });

      }, () => {
        this.setState({ loading: false })
      })


    }
  }

  render() {
    return (
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.state.loading || false}
        form_model={formLayout}
        values={{
          ...this.state.model
        }}

        submitted={(data) => this.submit(data)}
      />
    );
  }
}
export default CreateModel;