import React, { Component } from 'react';
import { Button,Row, Col, notification } from 'antd';
import Wrapper from '@common/decorators/Wrapper';
import AuthServices from '../services/Auth';
import '../script/actions';
//import '../script/spid-sp-access-button.min';
import '../../../spid_css/spid.css'

@Wrapper({ route: 'Login', hasSidebar: false, hasHeader: true, hasFooter: true })

class openIdConnectResponse extends Component {

  state = {
    loginerror: null
  }

  componentDidMount() {
    try {

      const search = this.props.location.search;

      const token = new URLSearchParams(search).get("token");

      /* https://cb.schema31.it/cb/issue/412764
       * se mandassi su anche lo user per via delle permissions così granulari, 
       * la query string risulterà > 24 Kbyte e i sistemi di Regione Campania darebbero un 502.
       * Allora mando solo il token e poi refresho la pagina che con il token si tira su i permessi per altre vie.
       */
      //const user  = new URLSearchParams(search).get("user");

      const error = new URLSearchParams(search).get("error");

      if(error){
        this.setState({loginerror: error});
        notification.error({
          placement: 'topLeft',
          duration: 5,
          message: 'Errore in fase di login',
          description: error,
        })        
      }

      //const user_obj = JSON.parse(user);

      if(token){

        console.warn('TOKEN: ', token);
        localStorage.setItem('token', token);

        this.props.dispatch({
            type: 'SET_USER_STORE',
            obj: {
              jwt: token,
              loggedIn: true,
              //user: user_obj,
              //role: user_obj.role.name
            }
          });

          this.props.history.push('/dashboard');
          window.location.reload(false);

      }

    } catch (err) {
      console.warn(err)
    }
  }

  handleLoginError(){
    return <React.Fragment>
      <h3 className="sectionTitle" style={{ textAlign: 'center' }}>Errore in fase di login</h3>
      <Row type="flex" justify="center">
        <Col lg={8} md={24} xs={24} sm={24} style={{ margin: '40px auto' }}>
        <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/login')}>Accedi</Button>
        </Col>
      </Row>      
    </React.Fragment>
  }

  render() {

    return (
      <Row type="flex" justify="center">
        <Col lg={24} md={24} xs={24} sm={24}>
          {
            this.state.loginerror === null ?
              <h3 className="sectionTitle" style={{ textAlign: 'center' }}>Accesso in corso ...</h3>
            :              
              this.handleLoginError()            
          }
        </Col>
      </Row>
    );
  }
}

export default openIdConnectResponse;