import CommonForm from '@common/components/form';
import { message, Spin } from 'antd';
import 'leaflet/dist/leaflet.css';
import React, { Component, useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import ProgettoServices from '@affidamento/services/Progetto';


const center = [39.202560, 16.318307]
const L = require('leaflet');

const myIcon = L.icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: null,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
});

function locationToArry(location) {
  const parsed = /\((-*[0-9][0-9.]*),(-*[0-9][0-9.]*)\)/g.exec(location);
  return [parsed[1], parsed[2]];
}

function DraggableMarker({value, onMove}) {
  value = value ? locationToArry(value) : null;
  const [position, setPosition] = useState(value || center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
          onMove && onMove(marker.getLatLng());
        }
      },
    }),
    [onMove],
  );

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={myIcon}
      >
    </Marker>
  );
}

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Luogo',
                type_string: 'text',
                name: 'location',
                props: {
                  disabled: true
                },
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'mappa',
                type: 'child',
                child: <div style={{ marginBottom: 16 }}>
                  <MapContainer center={center} zoom={9} scrollWheelZoom={false} style={{ height: "100vh" }}>
                    <TileLayer
                      attribution='&copy; SILF'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <DraggableMarker onMove={location => component.updateField('location', `(${location.lat},${location.lng})`)} value={component.state.data?.location}/>
                  </MapContainer >
                </div>
              }
            ],
          },
        ],
      },


      {
        cols: [
          {
            items: [
              {
                name: 'btn_submit_corso',
                type: 'submit',
                text: 'Salva',
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ]
          }
        ]
      },
    ],
    onSubmit: 'submit',
  }
};

export default class EditLocation extends Component {

  state = {
    loading: false,
    data: this.props.corso,
  }

  submit(data) {
    data.id_ente = this.props.UserStore.user.ente.id;
    this.setState({ loading: true })

    if (this.props.corso) {
      ProgettoServices.update(
        data,
        this.props.corso.id,
        (res) => {
          message.success("Posizione modificata con successo");
          this.setState({ loading: false })
          this.props.close();
        },
        () => this.setState({ loading: false }))
      }

  }

  getValues() {
    return this.state.data;
  }

  updateField(field, value) {
    let data = {};
    data[field] = value;
    this.formSelRef.props.form.setFieldsValue(data);
  }


  render() {
    return (
      <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>
        {
          this.state.loading ? <Spin /> : null
        }
        <CommonForm
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          submit={(data) => this.submit(data)}
        />
      </div>
    );
  }
}
