
export default {
	getSettoreByMacrotipologia: (mappatura, id_macrotipologia) => {
		try {
			const stubResponse = mappatura.macrotipologie.find(t => t.id === parseInt(id_macrotipologia));
			if(typeof stubResponse !== 'undefined'){
				return stubResponse.settori
			  }else{
				return "";
			  }
		} catch(e) {
			console.error(e)
			return [];
		}
	},

	getTipoAulaByTipoAulaStruttura: (mappatura, id_tipo_aula_struttura) => {
		try {
			return mappatura.tipo_aula.find(t => t.id === parseInt(id_tipo_aula_struttura)).tipoAula;
		} catch(e) {
			console.error(e)
			return [];
		}
	},

	getAttrezzatureByTipoAula: (mappatura, id_tipo_laboratorio) => {
		try {
			let attrezzature = []
			mappatura.map(t => {
				if(t.value === parseInt(id_tipo_laboratorio)) attrezzature = t.tipo_attrezzatura
				return attrezzature
			});

			return attrezzature;
		} catch(e) {
			console.error(e)
			return [];
		}
	},

	getLaboratoriBySettore: (mappatura, id_settore) => {
		try {
			let laboratori = []
			mappatura.macrotipologie.map(t => {
				if(t.settori.find(st => st.id === parseInt(id_settore))) laboratori = t.settori.find(st => st.id === parseInt(id_settore)).laboratori

				return t
			});
			return laboratori;
		} catch(e) {
			console.error(e)
			return [];
		}
	},

	getAttrezzatureByLaboratorio: (mappatura, id_laboratorio) => {
		try {
			let _attrezzature = []
			mappatura.macrotipologie.forEach(t => {
				t.settori.map(st => {
					if(st.laboratori.find(l => l.id === parseInt(id_laboratorio))) {
						_attrezzature = st.laboratori.find(l => l.id === parseInt(id_laboratorio)).attrezzature
					}
					return st;
				});
			});
			return _attrezzature;
		} catch(e) {
			console.error(e)
			return [];
		}

	}
}
