import { Checkbox, Table } from 'antd';
import React, { Component } from 'react';



const columns = (component) => {
    let ret = [
        {
            title: 'Denominazione',
            dataIndex: 'nome_aula',
            key: 'denominazione',
            render: (text, record) => (<div>{record.nome_aula}</div>)
        },
        {
            title: 'Tipologia',
            dataIndex: 'tabTipoAula.descrizione',
            key: 'tipologia',
        },
        {
            title: 'Metri quadri',
            dataIndex: 'metri_quadrati',
            key: 'metri_quadrati',
        },
        {
            title: 'N° allievi',
            dataIndex: 'numero_allievi',
            key: 'n_allievi',
        },
        {
            title: 'Disponibilità del locale',
            dataIndex: 'tipologiaUsoLocali',
            key: 'disponibilità',
        },
        {
            title: '',
            dataIndex: 'azione',
            key: 'azione',
            render: (text, record) => (
                <div style={{ width: 70 }}>
                    <Checkbox
                        disabled={component.props.readOnly}
                        onChange={(e) => component.props.checkAula(e.target.checked, record)}
                        checked={record.checked}
                    ></Checkbox>
                </div>
            ),
        }

    ];

    return ret;
}

class AuleTable extends Component {

    render() {
        return <div>
            <div style={{ color: 'rgb(73 73 73)' }}>Ambienti didattici della sede di svolgimento dell'attività formativa</div>
            <div style={{ marginTop: 8 }}>
                <Table
                    rowKey={(record) => record.id}
                    loading={this.props.loading}
                    columns={columns(this)}
                    dataSource={this.props.auleList}
                />
            </div>
        </div>
    }


}

export default AuleTable