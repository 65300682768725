import React, { Component } from 'react';

import { Table, Checkbox } from 'antd';


const columnsOrganigramma = (component) => {
  return [
    {
      title: 'Seleziona',
      dataIndex: 'selezione',
      key: 'selezione',
      render: (text, record) => (
        <div>
          <Checkbox
            disabled={component.props.readOnly}
            onChange={(e) => component.props.checkOrganigramma(e, record)}
            checked={component.props.metodologie.organogramList?.find(el => el.cf === record.cf && el.id_funzione === record.id_funzione)}
          ></Checkbox>
        </div>
      ),
    },
    {
      title: 'Funzione',
      dataIndex: 'funzione',
      key: 'funzione',
      render: (text, record) => (<div>{record.funzione}</div>),
    }, {
      title: 'Dati anagrafici',
      dataIndex: 'nome',
      key: 'nome',
      render: (text, record) => (<div>{record.nome + " " + record.cognome}<br />{record.cf}</div>)
    },

  ];
}

const columnsDocenti = (component) => {
  return [
    /*{
      title: 'Funzione',
      dataIndex: 'funzione',
      key: 'funzione',
      render: (text, record) => (<div style={{ width: 120 }}>{record.funzione}</div>),
    },*/
    {
      title: 'Seleziona',
      dataIndex: 'selezione',
      key: 'selezione',
      render: (text, record) => (
        <div>
          <Checkbox
            disabled={component.props.readOnly}
            onChange={(e) => component.props.checkDocente(e, record)}
            checked={component.props.metodologie.teacherList?.find(el => el.cf === record.cf)}
          ></Checkbox>
        </div>
      ),
    },
    {
      title: 'Dati anagrafici',
      dataIndex: 'nome',
      key: 'nome',
      render: (text, record) => (<div>{record.nome + " " + record.cognome}<br />{record.cf}</div>)
    },
  ];
}

const columnsUtenzeSpeciali = (component) => {
  return [
    {
      title: 'Seleziona',
      dataIndex: 'selezione',
      key: 'selezione',
      render: (text, record) => (
        <div >
          <Checkbox
            disabled={component.props.readOnly}
            onChange={(e) => component.props.checkUtenzaSpeciale(e, record)}
            checked={component.props.metodologie.specialUserList?.find(el => el.cf === record.cf && el.id_funzione === record.id_funzione)}
          ></Checkbox>
        </div>
      ),
    },
    {
      title: 'Professione',
      dataIndex: 'professione',
      key: 'professione',
      render: (text, record) => (<div >{record.funzione}</div>),
    }, {
      title: 'Dati anagrafici',
      dataIndex: 'nome',
      key: 'nome',
      render: (text, record) => (<div >{record.nome + " " + record.cognome}<br />{record.cf}</div>)
    },
  ];
}

class RisorseUmaneTable extends Component {

  render() {
    return (
      <div>
        <h4 className="m20h">Organigramma aziendale</h4>
        <Table
          locale={{ emptyText: this.props.domanda_json.relatedSubjects?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.decreto ? '' : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali" }}
          loading={this.props.loading}
          columns={columnsOrganigramma(this)}
          dataSource={this.props.professionalita?.organigramma}
          rowKey="id" />
        <h4 className="m20h">Personale docente</h4>
        <Table
          locale={{ emptyText: this.props.domanda_json.relatedSubjects?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.decreto ? '' : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali" }}
          loading={this.props.loading}
          columns={columnsDocenti(this)}
          dataSource={this.props.professionalita?.docenti}
          rowKey="id" />
        <h4 className="m20h">Utenze speciali</h4>
        <Table
          locale={{ emptyText: this.props.domanda_json.relatedSubjects?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.decreto ? '' : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali" }}
          loading={this.props.loading}
          columns={columnsUtenzeSpeciali(this)}
          dataSource={this.props.professionalita?.utenzeSpeciali}
          rowKey="id" />
      </div>

    );
  }
}
export default RisorseUmaneTable;