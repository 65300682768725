import React, { Component } from "react";

import { Divider } from "antd";
import NotaOperativaUpload from "../../../assets/Linee_guida_allegati.pdf";

import Wrapper from "@common/decorators/Wrapper";

@Wrapper({
  route: "Dashboard",
  hasSidebar: true,
  hasHeader: true,
  hasFooter: true,
  public: true,
})
class Dashboard extends Component {
  state = {
    loading: false,
  };

  render() {
    return (
      <div>
        <h2 style={{ color: '#444' }}>SILF - Accreditamento</h2>
        <h3>
          Sistema Accreditamento organismi che erogano servizi di istruzione e
          formazione e servizi per il lavoro
        </h3>

        <br></br>

        <h4>
        <a target={"_blank"} href={NotaOperativaUpload} >Note operative per il caricamento di allegati</a>
        </h4>
        
        <br></br>

        <h4>
          Si comunica che con DGR n. 270 del 30-5-2024 sono stati prorogati al 31-7.2024 i termini per il completamento della presentazione della domanda di accreditamento sulla nuova piattaforma, di cui alla DGR 136/2022 e alla nota prot. N. 37175 del 22/01/2024.
        </h4>

        {/* <DashboardLayout /> */}
        {/* {this.returnComponent()} */}
      </div>
    );
  }
}

export default Dashboard;
