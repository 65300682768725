import React, { Component } from "react";

import {Table} from 'antd';
/*
const table_columns = (component) => [{
    title: 'N. corsi',
    dataIndex: 'numero',
    key: 'numero',
    render: (text, record) => (<span>{record.numero}</span>),
  }, {
    title: 'Qualifica',
    dataIndex: 'qualifica',
    key: 'qualifica',
    render: (text, record) => (<span>{record.qualifica}</span>)
  }, {
    title: 'Durata (ore)',
    dataIndex: 'durata',
    key: 'durata',
    render: (text, record) => (<span>{record.durata}</span>)
  }, {
    title: 'N. allievi',
    dataIndex: 'n_allievi',
    key: 'n_allievi',
    render: (text, record) => (<span>{record.n_allievi}</span>)
  }, {
    title: 'Azioni',
    dataIndex: 'azione',
    key: 'azione',
    render: (text, record) => (
      <div>
        <span className="p5w">
          <a onClick={(e) => {
            component.deleteDettaglio(record)
          }} disabled={!component.canEdit()}>Cancella</a>
        </span>
      </div>
    ),
  }];



const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Numero corsi',
              type_string: 'number', // field type
              name: 'numero',
              props: {
                //size: 'large',
                //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Qualifica',
              type_string: 'text', // field type
              name: 'qualifica',
              props: {
                //size: 'large',
                //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [

              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Durata (ore)',
              type_string: 'number', // field type
              name: 'durata',
              props: {
                //size: 'large',
                //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'N. allievi',
              type_string: 'number', // field type
              name: 'n_allievi',
              props: {
                //size: 'large',
                //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'btn_submit',
              text: 'Inserisci dettaglio',
              props: {
                className: 'm10h',
                icon: 'save'
              },
              //onClick: 'showDrawer'
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'onSubmit',
};

class DettaglioCorsi extends Component {

  state = {
    mounted: false,
    loading: false,
    corsi: []
  };

  componentDidMount() {
    this.loadCorsi(this.props);

  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.verbale.id != this.props.verbale.id) this.loadCorsi(newProps);
  }

  loadCorsi(props) {
    this.setState({
      corsi: props.verbale.corsi
    })
  }

  addDettaglio( data ) {
    VerbaleIspezioneServices.addCorso(this.props.verbale.id, data, (res) => {
      this.setState({
        corsi: [...this.state.corsi, res.data.data]
      })
    })
  }

  deleteDettaglio( record ) {
    VerbaleIspezioneServices.removeCorso(this.props.verbale.id, record.id, (res) => {
      this.setState({
        corsi: this.state.corsi.filter(c => c.id != record.id)
      })
    });
  }

  canEdit() {
    return true;
  }

	render() {
		return <div style={{marginTop: 16}}>
            <h4>Dettaglio corsi</h4>
            {
              this.canEdit()
              ?
              <CommonForm
                  wrappedComponentRef={inst => (this.formSelRef = inst)}
                  loading={this.state.loading || false}
                  form_model={formLayout}
                  values={{}}
                  onSubmit={(data) => this.addDettaglio(data)}
                  tipicorsi={[]}
              />
              : null
            }
            <Table
            canEdit={this.canEdit()}
            columns={table_columns(this)}
            dataSource={this.state.corsi} />
        </div>
	}
}
*/

/**
 * Qui è il top, avevo fatto le modifiche, nessuno sapeva niente dei corsi così gli ho implementato l'inserimento, poi devo scoprire che non serviva
 */

const table_columns = () => [{
    title: 'Tipologia di corso',// (finanziato / autofinanziato e per i corsi autofinanziati se Art.40/ Art. 41)',
    dataIndex: 'tipologia',
    key: 'tipologia',
    render: (text, record) => (<span>{record.tipologia}</span>),
  }, {
    title: 'Codice corso/titolo corso',
    dataIndex: 'numero',
    key: 'numero',
    render: (text, record) => (<span>{record.numero}</span>),
  }, {
    title: 'Settore/Qualifica professionale',
    dataIndex: 'qualifica',
    key: 'qualifica',
    render: (text, record) => (<span>{record.qualifica}</span>)
  }, {
    title: 'Periodo svolgimento del corso',
    dataIndex: 'periodo',
    key: 'periodo',
    render: (text, record) => (<span>{record.periodo}</span>)
  }, {
    title: 'Ore settimanali di lezione',
    dataIndex: 'durata',
    key: 'durata',
    render: (text, record) => (<span>{record.durata}</span>)
  }, {
    title: 'N. allievi',
    dataIndex: 'n_allievi',
    key: 'n_allievi',
    render: (text, record) => (<span>{record.n_allievi}</span>)
  }];


class DettaglioCorsi extends Component {

  state = {
    mounted: false,
    loading: false,
    corsi: []
  };



  render() {
    return <div style={{marginTop: 16}}>
            <h4>Corsi in fase di erogazione</h4>
            <Table
            columns={table_columns()}
            dataSource={[]} />
        </div>
  }
}

export default DettaglioCorsi;